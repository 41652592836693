import { useCallback } from 'react';
import { FileInfo } from 'components/MediaUploader/types';
import { useModalsActions } from 'state/modal';
import { useIsBasicOrFreeTier } from 'state/pricing';
import { MAX_FILE_DURATION_SEC, MAX_FILE_SIZE_MB } from './constants';
import { HandleExceededUploadLimitProps } from './types';
import useTrackFileUploadErrors from './useTrackFileUploadErrors';
import { notifyFileRejectionError } from './utils';

export interface UseValidateUploadLimits {
  onExceededUploadLimits: (props: HandleExceededUploadLimitProps) => void;
}

export default function useValidateUploadLimits(): UseValidateUploadLimits {
  const { data: isBasicOrFreeTier } = useIsBasicOrFreeTier();
  const { trackFileErrors } = useTrackFileUploadErrors();
  const { pushModal } = useModalsActions();

  const handleMaxLimitsErrors = useCallback(
    ({ sizeMb, durationSec }: FileInfo): boolean => {
      if (sizeMb > MAX_FILE_SIZE_MB) {
        notifyFileRejectionError({ reason: 'TOO_LARGE' });

        return true;
      }

      if (durationSec > MAX_FILE_DURATION_SEC) {
        notifyFileRejectionError({ reason: 'TOO_LONG' });

        return true;
      }

      return false;
    },
    [],
  );

  const handleExceededUploadLimit = useCallback(
    ({
      sizeMb,
      durationSec,
      onUpsellSuccess,
    }: HandleExceededUploadLimitProps): void => {
      trackFileErrors({ sizeMb, durationSec });

      const hasMaxLimitsErrors = handleMaxLimitsErrors({ sizeMb, durationSec });

      if (!isBasicOrFreeTier || hasMaxLimitsErrors) {
        return;
      }

      return pushModal({
        name: 'Upsell',
        params: {
          context: 'uploadLimitsError',
          onSuccess: onUpsellSuccess,
        },
      });
    },
    [handleMaxLimitsErrors, isBasicOrFreeTier, pushModal, trackFileErrors],
  );

  return {
    onExceededUploadLimits: handleExceededUploadLimit,
  };
}
