import { ZoomLogo } from '@sparemin/blockhead';
import React from 'react';
import * as S from './styles';
import UploadButton from './UploadButton';

interface ZoomUploadButtonProps {
  onPress: () => void;
}

const ZoomUploadButton: React.FC<ZoomUploadButtonProps> = ({ onPress }) => (
  <UploadButton
    aria-label="Upload files from Zoom"
    label="Zoom"
    logo={<S.IntegratorIcon as={ZoomLogo} />}
    onPress={onPress}
  />
);

export default ZoomUploadButton;
