import { useCallback, useEffect, useRef, useState } from 'react';
import { MutateOptions, QueryStatus, useMutation } from 'react-query';

import {
  AudioExport,
  exportAudio,
  ExportAudio,
  ExportAudioArgs as ApiExportAudioArgs,
} from 'api';
import notifyError from 'components/notification';
import { queryStatusResolver } from 'state/utils';
import { downloadLink } from 'utils/download';
import useGetAudioExport from './useGetAudioExport';

type ErrorHandler = () => void;
type SuccessHandler = (data: AudioExport) => void;

type ReactQueryMutateOptions = MutateOptions<
  ExportAudio,
  unknown,
  ApiExportAudioArgs,
  unknown
>;
interface ExportAudioOptions
  extends Omit<ReactQueryMutateOptions, 'onError' | 'onSuccess'> {
  onError?: ErrorHandler;
  onSuccess?: SuccessHandler;
}

interface ExportOnlyAudioArgs extends ApiExportAudioArgs {
  download?: false;
}

interface ExportAndDownloadAudioArgs extends ApiExportAudioArgs {
  download: true;
  projectName: string;
}

type ExportAudioArgs = ExportOnlyAudioArgs | ExportAndDownloadAudioArgs;

const useExportAudio = () => {
  const onErrorRef = useRef<ErrorHandler>();
  const onSuccessRef = useRef<SuccessHandler>();
  const projectNameRef = useRef<string>();

  const [audioExportId, setAudioExportId] = useState<number>(-1);
  const [downloadHasStarted, setDownloadHasStarted] = useState(false);

  const { status: initiateExportStatus, mutate } = useMutation<
    ExportAudio,
    unknown,
    ApiExportAudioArgs,
    unknown
  >(exportAudio);

  const { data: audioExport, status: exportStatus } =
    useGetAudioExport(audioExportId);

  useEffect(() => {
    if (audioExportId >= 0) {
      if (exportStatus === 'error' || audioExport?.status === 'error') {
        onErrorRef.current?.();
        setAudioExportId(-1);
      } else if (
        exportStatus === 'success' &&
        audioExport?.status === 'completed'
      ) {
        onSuccessRef.current?.(audioExport);
        setAudioExportId(-1);

        if (projectNameRef.current) {
          downloadLink(
            projectNameRef.current,
            audioExport.audioUrl,
            () => setDownloadHasStarted(true),
            audioExport.fileExtension,
          );
        }
      }
    }
  }, [audioExport, audioExportId, exportStatus]);

  const handleExportAudio = useCallback(
    (args: ExportAudioArgs, opts?: ExportAudioOptions) => {
      const { projectCuid, selection } = args;
      const { onError, onSuccess, ...restOpts } = opts ?? {};

      projectNameRef.current = undefined;
      onErrorRef.current = onError;
      onSuccessRef.current = onSuccess;

      if (args.download) {
        projectNameRef.current = args.projectName;
      }

      mutate(
        { projectCuid, selection },
        {
          ...restOpts,
          onError() {
            onError?.();
            notifyError({
              heading: 'Error exporting audio',
              errorCode: { code: 'ER005', type: 'audio' },
            });
          },
          onSuccess(data, variables, context) {
            setAudioExportId(data.audioExportId);
          },
        },
      );
    },
    [mutate],
  );

  const isPolling = exportStatus !== 'idle';
  const statusList = [
    isPolling ? 'loading' : null,
    exportStatus,
    initiateExportStatus,
  ].filter(Boolean) as QueryStatus[];

  const status = queryStatusResolver(statusList);

  return {
    data: audioExport,
    exportAudio: handleExportAudio,
    status: status === 'success' && !downloadHasStarted ? 'loading' : status,
  };
};

export default useExportAudio;
