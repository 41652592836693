import { Spacer } from '@sparemin/blockhead';
import styled from 'styled-components';

export const Root = styled(Spacer)`
  width: 100%;
  margin: 40px 0;
`;

export const ScrollElement = styled.div`
  position: relative;
  width: 100%;
`;

export const VirtualWindow = styled.div`
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;
