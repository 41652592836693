import { useCallback } from 'react';
import { useQuery } from 'react-query';

import {
  ProjectNameSuggestionResult,
  getProjectNameSuggestion,
} from 'api/services';
import { QueryOptions } from 'types';

import { eddyProjectNameSuggestionsQueryKeys } from './queryKeys';
import { firstNProjectNamesSuggestionsSelector } from './selectors';

type QueryKey = ReturnType<
  typeof eddyProjectNameSuggestionsQueryKeys.projectNameSuggestions
>;

export interface UseGetProjectNameSuggestionQuery<
  TData = ProjectNameSuggestionResult,
> extends QueryOptions<ProjectNameSuggestionResult, TData, QueryKey> {}

const useGetProjectNameSuggestion = <TData = ProjectNameSuggestionResult>(
  cuid?: string,
  opts?: UseGetProjectNameSuggestionQuery<TData>,
) => {
  return useQuery<ProjectNameSuggestionResult, unknown, TData, QueryKey>(
    eddyProjectNameSuggestionsQueryKeys.projectNameSuggestions(cuid),
    ({ queryKey: [{ projectCuid }] }) =>
      getProjectNameSuggestion({ projectCuid: projectCuid as string }),
    {
      ...opts,
      enabled: !!cuid && (opts?.enabled ?? true),
    },
  );
};

export const useGetFirstNProjectNameSuggestions = (
  resultsAmount: number,
  cuid?: string,
  enabled?: boolean,
) => {
  const selector = useCallback(
    (d: ProjectNameSuggestionResult) =>
      firstNProjectNamesSuggestionsSelector(d, resultsAmount),
    [resultsAmount],
  );

  return useGetProjectNameSuggestion(cuid, {
    enabled,
    select: selector,
  });
};

export default useGetProjectNameSuggestion;
