import { once } from 'lodash-es';
import React, { useCallback } from 'react';
import ActionButton from 'pages/TranscriptEditorPage/EditorContextMenu/ActionButton';
import {
  useCanInsertSegment,
  useEditTranscript,
} from 'pages/TranscriptEditorPage/state';
import {
  useContextMenuActions,
  useWordsContextMenuConfig,
} from 'pages/TranscriptEditorPage/state/contextMenu';

export interface InsertSpeakerButtonProps {}

const InsertSpeakerButton: React.FC<InsertSpeakerButtonProps> = () => {
  const config = useWordsContextMenuConfig();

  const firstSelectedWordId = config?.words[0];

  const { data: canInsertSegment } = useCanInsertSegment(firstSelectedWordId);
  const { createSegment } = useEditTranscript();
  const { onAction } = useContextMenuActions();

  const handleInsertSegement = useCallback(() => {
    if (firstSelectedWordId) {
      createSegment(firstSelectedWordId, {
        // usage of `once` here allows the callback to be fired when the command
        // first executes and not when it is repeatedly done and undone.  after the
        // user creates a segment for the first time, they can name it - we shouldn't
        // continue to show the add-segment context menu every time the command is
        // re-executed
        onSegmentCreated: once((newSegmentId) => {
          onAction({
            config,
            newSegmentId,
            type: 'add-segment',
          });
        }),
      });
    }
  }, [config, createSegment, firstSelectedWordId, onAction]);

  return (
    <ActionButton
      isDisabled={!canInsertSegment}
      onPress={handleInsertSegement}
      tooltip={!canInsertSegment ? 'A speaker already exists' : undefined}
    >
      insert speaker
    </ActionButton>
  );
};

export default InsertSpeakerButton;
