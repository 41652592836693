import { ProjectTranscript, TranscriptSpeaker } from 'api';
import { placeholderSegmentIds } from '../..';
import AsyncUndoableTranscriptCommand from '../AsyncUndoableTranscriptCommand';
import TranscriptOriginator from '../TranscriptOriginator';
import { CommandOptions } from '../types';
import { createSegment, deleteSegment } from './optimisticUpdate';
import { CreateSegmentCommitter, DeleteSegmentCommitter } from './types';

type DeleteSegmentCommandArgs = {
  segmentId: number;
};

/**
 * An undoable command to delete a segment.
 *
 * This gets a little complicated because the id of the deleted segment keeps
 * changing as the command is done/undone.  Consider the following:
 *
 * The command first runs and deletes the segment.  When an undo operation is
 * issued, the command rolls back the deletion by using the first word in the
 * deleted segment to create a new segment, generating a new id.
 *
 * When the undo is committed, the committer will return yet another id.  If a
 * redo is issued before the command is uncommitted, the placeholder id will be used,
 * otherwise the id returned by uncommitter will be used.
 *
 * When a redo command is issued, the segment to be deleted will have a completely
 * different id than it originally had before the deletion took place.
 *
 * Like CreateSegmentCommand, immer patches aren't used for undo/redo because of
 * the complications with segment ids getting generated both locally and on the back
 * end.
 */
export default class DeleteSegmentCommand extends AsyncUndoableTranscriptCommand {
  // the opposite of deleteSegment is createSegment and for creation, we need to
  // know the id of the first word in the segment to be created
  private deletedSegmentStartWordId: number | undefined = undefined;

  private localSegmentId: number;

  private remoteSegmentId: number;

  private originalSpeaker: TranscriptSpeaker | undefined;

  constructor(
    args: DeleteSegmentCommandArgs,
    originator: TranscriptOriginator,
    private deleteSegmentCommitter: DeleteSegmentCommitter,
    private createSegmentCommitter: CreateSegmentCommitter,
    opts?: CommandOptions,
  ) {
    super(originator, opts);

    // when the command is first created, the local and remote segment ids will be
    // the same - meaning to update the local transcript or the remote transcript,
    // the same id is used
    this.localSegmentId = args.segmentId;
    this.remoteSegmentId = args.segmentId;
  }

  protected executeTranscript(
    transcript: ProjectTranscript,
  ): ProjectTranscript | undefined {
    const [newTranscript, deletedSegmentStartWordId, originalSpeaker] =
      deleteSegment(transcript, this.localSegmentId);

    this.deletedSegmentStartWordId = deletedSegmentStartWordId;
    this.originalSpeaker = originalSpeaker;

    return newTranscript;
  }

  protected unexecuteTranscript(
    transcript: ProjectTranscript,
  ): ProjectTranscript | undefined {
    if (this.deletedSegmentStartWordId) {
      const [newTranscript, newSegmentId] = createSegment(
        transcript,
        this.deletedSegmentStartWordId,
        this.originalSpeaker,
      );

      // save the locally created segment id
      this.localSegmentId = newSegmentId;

      return newTranscript;
    }

    return undefined;
  }

  public async commit(): Promise<void> {
    try {
      await this.deleteSegmentCommitter({
        segmentId: this.remoteSegmentId,
      });
    } catch (err) {
      this.onError('commit');
      throw err;
    }
  }

  public async uncommit(): Promise<void> {
    if (this.deletedSegmentStartWordId) {
      try {
        const newSegmentId = await this.createSegmentCommitter({
          newSegmentStartWordId: this.deletedSegmentStartWordId,
          newSegmentSpeakerId: this.originalSpeaker?.id,
        });

        this.remoteSegmentId = newSegmentId;

        placeholderSegmentIds.setPlaceholderId(
          this.localSegmentId,
          newSegmentId,
        );
      } catch (err) {
        this.onError('uncommit');
        throw err;
      }
    }
  }
}
