import { ProjectTranscript, TranscriptWord } from 'api';
import { TimeInterval } from 'types';
import { getIntervalDuration } from 'utils/time';

export interface EditedInterval
  extends Pick<TranscriptWord, 'startMillis' | 'endMillis' | 'isDeleted'> {
  /**
   * This number should be added to the original audio's currentTime to map it to
   * the edited audio's run time
   */
  adjustmentMillis: number;
}

/**
 * Collapse intervals while prioritizing deletions. Contiguous intervals get merged
 * into a single interval spanning the entire range.  The resulting intervals should
 * leave no gaps in the range.
 *
 * Based on the rules we've created about how users are allowed to modify transcripts,
 * the boudnaries of deleted segments can extend into adjacent words. Deleted segments
 * are considered as-is, without any modifications and all undeleted words/segments
 * are left to fill in the remaining undeleted time.
 *
 * The algorithm works by keeping an array of segments, the last element of which
 * is always an undeleted segment. When we find a deleted segment, it must lie within
 * the segment at the end of the array, so that segment is modified, the deleted segment
 * is added, and a new undeleted segment which represents the remaining time is pushed
 * onto the array.
 *
 *  * Consider a time range as follows:
 *
 * |---------------------------------------------------------------------------|
 * 0                                                                          100
 *
 * Consider the following deleted segments (words, in our case):
 *
 * |----|----------|-----------------------------------|----------|------------|
 * 0    |xxxxxxxxxx|                                   |xxxxxxxxxx|           100
 *     10         20                                  70         80
 *
 * The "edited" timeline, as displayed to the user, is reduced by the size of the
 * deletions:
 *
 *    100 - ((20 - 10) + (80 - 70)) = 80
 *
 * When reading `currentTime` from the audio player, we need to be able to map that
 * value to the edited timeline.  Similarly, when the user seeks on the edited timeline,
 * we need to be able to map that back to the original audio in order to issue the
 * seek command.
 *
 * The general formula is that for any time `t` in the original audio file, the
 * corresponding time can be found in the edited runtime by subtracting the sum
 * of all deleted intervals that come before it.
 *
 * As the intervals are processed, contiguous intervals are combined and the adjustment
 * value (sum of deleted ranges up to that point) is maintained.  The intervals
 * above would produce the following result:
 *
 * ```
 * [
 *  { start: 0, end: 10, adj: 0, deleted: false },
 *  { start: 10, end: 20, adj: 10, deleted: true },
 *  { start: 20, end: 70, adj: 10, deleted: false},
 *  { start: 70, end: 80, adj 20, deleted: true },
 *  { start: 80, end: 100, adj: 20, deleted: false }
 * ]
 * ```
 *
 * To map the original audio time to the edited timeline:
 *  - find the range that the time falls in
 *  - if the time falls in a deleted range, that's invalid and the audio should
 *    be seeked to skip that range
 *  - if the time falls in an undeleted range, take the currentTime value and subtract
 *    the adjustment value for tha tinterval
 *
 * To map the edited time to the original audio:
 *  - find the range that the time falls in.  To do this, when looking through the
 *    intervals, shift them to the edited timeline by subtracting the adjustment
 *    from the start and end time
 *  - add the adjustment from the interval found in the first step to the currentTime
 */
export default function collapseIntervals(
  transcript: Pick<ProjectTranscript, 'segments'>,
) {
  const { segments } = transcript;

  // this is the list of intervals that will get returned.  It should alternate
  // between deleted and undeleted segments with no gaps in between
  const intervals: EditedInterval[] = [
    {
      adjustmentMillis: 0,
      isDeleted: false,
      startMillis: 0,
      endMillis: Infinity,
    },
  ];

  let deletedInterval: TimeInterval | undefined = undefined;

  // sum of deleted time.  we have to iterate it, the parent needs it, so might
  // as well return it rather than iterating to calculate it later
  let deletedMillis = 0;

  // NB: good old loops used to squeeze out whatever performance we can, given
  // this can run often and the dataset can be large (thousands of segments)
  for (let segIndex = 0; segIndex < segments.length; segIndex += 1) {
    const { words } = segments[segIndex];

    for (let wordIndex = 0; wordIndex < words.length; wordIndex += 1) {
      const word = words[wordIndex];

      // adding a deleted segment to the segments array depends on discovering the
      // first undeleted word after that segment, however if the transcript ends
      // with a deleted segment, there will be no undeleted word after it yet it
      // will still need to be pushed onto the intervals list
      const isLastWordInTranscript =
        segIndex === segments.length - 1 && wordIndex === words.length - 1;

      // extend deleted interval or create a new one if it doesn't exist
      if (word.isDeleted) {
        if (deletedInterval) {
          deletedInterval.endMillis = word.endMillis;
        } else {
          deletedInterval = {
            startMillis: word.startMillis,
            endMillis: word.endMillis,
          };
        }
      }

      if ((!word.isDeleted || isLastWordInTranscript) && deletedInterval) {
        const lastUndeletedInterval = intervals[intervals.length - 1];
        const endMillis = lastUndeletedInterval.endMillis;

        // end the undeleted interval where the deletion begins
        lastUndeletedInterval.endMillis = deletedInterval.startMillis;

        // if the modified interval is reduced to nothing, remove it.  this is generally
        // a good rule to follow but is really designed to solve for when the audio
        // begins at time 0 with a deleted segment.  In that case, the intervals array
        // will have a single interval spanning [0, Infinity] and when the deleted interval
        // is added, will get cut to [0, 0]
        if (getIntervalDuration(lastUndeletedInterval) <= 0) {
          intervals.pop();
        }

        const deletedIntervalDuration = getIntervalDuration(deletedInterval);

        const adjustmentMillis =
          lastUndeletedInterval.adjustmentMillis + deletedIntervalDuration;

        deletedMillis += deletedIntervalDuration;

        // previous interval was modified in place.  add new deleted interval and
        // undeleted interval representing the remaining time
        intervals.push(
          {
            ...deletedInterval,
            adjustmentMillis,
            isDeleted: true,
          },
          {
            isDeleted: false,
            startMillis: deletedInterval.endMillis,
            endMillis,
            adjustmentMillis,
          },
        );

        deletedInterval = undefined;
      }
    }
  }

  return { intervals, deletedMillis };
}
