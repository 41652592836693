import { ButtonProps } from '@sparemin/blockhead';
import React from 'react';
import * as S from './styles';

export type SuggestedClipsTriggerProps = Pick<ButtonProps, 'onPress'> & {
  clipCount: number;
  isOpen: boolean;
};

const SuggestedClipsTrigger: React.FC<SuggestedClipsTriggerProps> = ({
  clipCount,
  isOpen,
  onPress,
}) => {
  return (
    <S.Trigger
      aria-hidden={isOpen ? true : undefined}
      onPress={onPress}
      startIcon={<S.TriggerIcon />}
      tabIndex={isOpen ? -1 : undefined}
    >
      we found {clipCount} {clipCount >= 2 ? 'clips' : 'clip'}
    </S.Trigger>
  );
};

export default SuggestedClipsTrigger;
