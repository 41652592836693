import { deleteSegment } from 'api';
import { useTranscriptId } from './useEditorTranscript';
import useUpdateTranscript from './useUpdateTranscript';
import { placeholderSegmentIds } from '.';

type UseDeleteTranscriptSegmentVariables = {
  segmentId: number;
};

export default function useDeleteTranscriptSegment() {
  const { data: transcriptId } = useTranscriptId();

  return useUpdateTranscript<void, UseDeleteTranscriptSegmentVariables>({
    mutationFn: ({ segmentId }) => {
      if (!transcriptId) {
        throw new Error('Error deleting segment');
      }

      const actualSegmentId = placeholderSegmentIds.getActualId(segmentId);
      return deleteSegment(transcriptId, actualSegmentId);
    },
  });
}
