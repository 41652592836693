import React, { useCallback } from 'react';
import notifyError from 'components/notification';
import useDislikeClipSuggestion from 'pages/TranscriptEditorPage/state/useDislikeSuggestedClip';
import useSuggestionPopoverTracking from 'pages/TranscriptEditorPage/Transcript/SpeakerSegment/SuggestionContainer/SuggestedClipTooltip/useSuggestionPopoverTracking';
import { useClipSuggestionContext } from '../../../ClipSuggestionContext';
import * as S from './styles';
import { DislikeClipFeedbackValue } from './types';
import { getSubmitValueFromButtonValue } from './utils';

type DislikeButtonProps = {
  value: DislikeClipFeedbackValue;
};

const DislikeButton: React.FC<DislikeButtonProps> = ({ value }) => {
  const { suggestionId } = useClipSuggestionContext();
  const { mutateAsync } = useDislikeClipSuggestion();
  const { onDislikeClip } = useSuggestionPopoverTracking();

  const handleDislike = useCallback(async () => {
    const feedbackType = getSubmitValueFromButtonValue(value);
    onDislikeClip(feedbackType);
    try {
      await mutateAsync({ suggestionId, feedbackType });
    } catch (err) {
      notifyError({ heading: 'Error disliking suggested clip' });
    }
  }, [mutateAsync, suggestionId, value, onDislikeClip]);

  return <S.Button onClick={handleDislike}>{value}</S.Button>;
};

export default DislikeButton;
