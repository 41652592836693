import { Virtualizer } from '@tanstack/react-virtual';
import { useCallback } from 'react';
import { useAudioPlayer } from 'pages/TranscriptEditorPage/AudioPlayerContext';
import {
  useSegmentsById,
  useWordsById,
} from 'pages/TranscriptEditorPage/state';
import {
  EditorUiStore,
  useEditorUiSubscription,
} from 'pages/TranscriptEditorPage/state/editorUi';
import { getWordElement } from 'pages/TranscriptEditorPage/utils';
import { getDistanceFromCenterOfScreen, isInViewport } from 'utils/dom';
import { shouldRecenterWord } from './utils';

export interface UseScrollIntoViewConfig {
  virtualizer: Virtualizer<Window, Element>;
}

export default function useScrollIntoView({
  virtualizer,
}: UseScrollIntoViewConfig) {
  const { player } = useAudioPlayer();
  const { data: wordsById } = useWordsById();
  const { data: segmentsById } = useSegmentsById();

  useEditorUiSubscription(
    useCallback(
      (current: EditorUiStore, prev: EditorUiStore) => {
        // dependencies that we need for all of the logic below.  if we don't have them
        // bail early so that we don't need lots of conditional code to access them
        if (!wordsById || !segmentsById) {
          return;
        }

        const hasNewScrollWord = current.scrollToWordId !== prev.scrollToWordId;

        const finishedScrollingSegment =
          current.scrollingSegmentWordId === undefined &&
          prev.scrollingSegmentWordId !== undefined;

        if (hasNewScrollWord || finishedScrollingSegment) {
          // we either need to scroll the new word into view or the word whose segment
          // just finished scrolling into view.  they could both change at the same time,
          // in which case we prioritize scrolling the new word into view
          const wordId =
            (hasNewScrollWord
              ? current.scrollToWordId
              : prev.scrollingSegmentWordId) ?? -1;

          const word = wordsById[wordId];

          if (!word) {
            return;
          }

          const segmentId = word.segmentId;
          const segment = segmentsById[segmentId];

          const { startIndex, endIndex } = virtualizer.range;

          // if the scroll word's segment is not rendered, bring it in.
          // that's all we have to do here.  once the segment is in, we state will
          // update indicating that and we should be able to find the scroll element
          // (the else block, below)
          if (segment.index < startIndex || segment.index > endIndex) {
            current.actions.scrollToSegment(word.id);
            virtualizer.scrollToIndex(segment.index);
          } else {
            // word should be on the screen somewhere
            const scrollToElement = getWordElement(word.id);

            // but anything could happen, so check
            if (scrollToElement) {
              const scrollTargetRect = scrollToElement.getBoundingClientRect();
              const scrollTargetCenteringDelta =
                getDistanceFromCenterOfScreen(scrollTargetRect);

              // if word is not in the viewport, bring it in
              if (!isInViewport(scrollTargetRect) || finishedScrollingSegment) {
                virtualizer.scrollBy(scrollTargetCenteringDelta);
              } else if (!player.paused) {
                // else - if it is in the viewport, do nothing unless audio is playing.
                // during playback the word has to remain cenetered
                virtualizer.scrollBy(scrollTargetCenteringDelta, {
                  behavior: 'smooth',
                });
              } else if (shouldRecenterWord(scrollTargetRect)) {
                // finally if the word is not in viewport, the player is not playing
                // and the word is 20% off of the screen center it will be recentered
                virtualizer.scrollBy(scrollTargetCenteringDelta);
              }
            }
          }
        }
      },
      [player.paused, segmentsById, virtualizer, wordsById],
    ),
  );
}
