import { generatePath } from 'react-router-dom';

const EDITOR_PATH = '/transcript/:projectCuid';

interface PathConfiguration {
  value: string;
  // we want to enforce that `create` is a function that returns a string, but we
  // want each individual route to be able to define the parameters it needs to
  // build the route.  eslint doesn't want us to use any but it seems to be the
  // most appriopriate type for this.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  create?: (...args: any) => string;
}

const paths = {
  dashboard: { value: '/dashboard' },
  login: { value: '/login' },
  registration: { value: '/signup' },
  resetPassword: { value: '/password-reset' },
  editor: {
    value: EDITOR_PATH,
    create(projectCuid: string) {
      return generatePath(EDITOR_PATH, { projectCuid });
    },
  },
  projectCreate: { value: '/project-create' },
} satisfies Readonly<Record<string, PathConfiguration>>;

export default paths;
