import { isExpired } from '@sparemin/auth';
import { ACCESS_TOKEN_VALIDATION_ERRORS } from './constants';
import { AccessTokenValidationError } from './types';

export function validateAccessToken(
  accessToken: string | undefined | null,
): accessToken is string {
  if (!accessToken) {
    throw new Error('token-missing');
  }

  try {
    if (isExpired(accessToken)) {
      throw new Error('token-expired');
    }
  } catch {
    throw new Error('token-invalid');
  }

  return true;
}

export function isValidationError(
  err: unknown,
): err is AccessTokenValidationError {
  return (
    err instanceof Error &&
    (ACCESS_TOKEN_VALIDATION_ERRORS as Readonly<string[]>).includes(err.message)
  );
}
