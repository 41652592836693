import { Patch, applyPatches, produceWithPatches } from 'immer';
import { ProjectTranscript, UpdateProjectTranscriptChapterArgs } from 'api';
import notifyError from 'components/notification';
import AsyncUndoableTranscriptCommand from '../AsyncUndoableTranscriptCommand';
import TranscriptOriginator from '../TranscriptOriginator';
import { CommandAction, CommandOptions, Committer } from '../types';

type UpdateChapterArgs = Pick<
  UpdateProjectTranscriptChapterArgs,
  'chapterId' | 'title' | 'url' | 'imageUrl'
>;

type UpdateChapterOptions = Pick<
  CommandOptions,
  'onExecuteSuccess' | 'onUnexecuteSuccess'
>;

export default class UpdateChapterCommand extends AsyncUndoableTranscriptCommand {
  private patches: Patch[] | undefined;

  private inversePatches: Patch[] | undefined;

  private originalChapterTitle: string | undefined = undefined;

  private originalChapterUrl: string | undefined = undefined;

  private originalChapterImageUrl: string | undefined = undefined;

  constructor(
    private args: UpdateChapterArgs,
    originator: TranscriptOriginator,
    private chapterUpdateCommitter: Committer<UpdateChapterArgs>,
    opts?: UpdateChapterOptions,
  ) {
    super(originator, opts);
  }

  protected onError(action: CommandAction) {
    if (action === 'commit') {
      notifyError({
        heading: this.args.title
          ? 'Error updating chapter'
          : 'Error deleting chapter',
        errorCode: this.args.title ? 'ER012' : 'ER013',
      });
    }
  }

  protected executeTranscript(
    transcript: ProjectTranscript,
  ): ProjectTranscript | undefined {
    if (!this.patches || !this.inversePatches) {
      const [nextTranscript, patches, inversePatches] = produceWithPatches(
        transcript,
        (draft) => {
          const chapter = draft.chapters.find(
            (c) => c.id === this.args.chapterId,
          );

          if (!chapter) {
            return;
          }

          this.originalChapterTitle = chapter.title;
          this.originalChapterUrl = chapter.url;
          this.originalChapterImageUrl = chapter.imageUrl;

          chapter.title = this.args.title;
          chapter.url = this.args.url;
          chapter.imageUrl = this.args.imageUrl;
        },
      );

      this.patches = patches;
      this.inversePatches = inversePatches;

      return nextTranscript;
    }
    return applyPatches(transcript, this.patches);
  }

  protected unexecuteTranscript(
    transcript: ProjectTranscript,
  ): ProjectTranscript | undefined {
    if (!this.inversePatches) {
      return undefined;
    }

    return applyPatches(transcript, this.inversePatches);
  }

  async commit(): Promise<void> {
    try {
      return await this.chapterUpdateCommitter({
        chapterId: this.args.chapterId,
        title: this.args.title,
        url: this.args.url,
        imageUrl: this.args.imageUrl,
      });
    } catch (err) {
      this.onError('commit');

      throw err;
    }
  }

  async uncommit(): Promise<void> {
    if (this.originalChapterTitle) {
      try {
        this.chapterUpdateCommitter({
          chapterId: this.args.chapterId,
          title: this.originalChapterTitle,
          url: this.originalChapterUrl,
          imageUrl: this.originalChapterImageUrl,
        });
      } catch (err) {
        this.onError('uncommit');

        throw err;
      }
    }
  }
}
