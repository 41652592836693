import { userQueryKey } from 'state/queryKeyUtils';

const pricingQueryKeys = {
  all: [{ scope: 'pricing' }] as const,
};

export const subscriptionQueryKeys = {
  all: [{ ...pricingQueryKeys.all[0], entity: 'subscription' }] as const,
  mySubscription: (userId?: number) =>
    [{ ...subscriptionQueryKeys.all[0], ...userQueryKey(userId) }] as const,
};
