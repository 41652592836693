import { FadingContainer as BaseFadingContainer } from '@sparemin/blockhead';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { TOP_BAR_TRANSITION_DURATION_SEC } from './constants';
import { BaseTabPanelContainerStyleProps, MainStyleProps } from './types';

export const Root = styled(motion.div)<BaseTabPanelContainerStyleProps>`
  width: 100%;
  display: contents;
  position: relative;
  top: 0;

  &::before {
    content: '';
    height: ${({ $height }) => $height}px;
    position: absolute;
    left: 0;
    right: 15px;
    margin: 0 auto;
    background-image: ${({ theme }) =>
      `linear-gradient(
        to bottom,
        ${theme.backgroundColorMain},
        ${theme.backgroundColorMain} 50%,
        rgba(250, 250, 251, 0.95) 90%,
        rgba(250, 250, 251, 0)
      )`};
    transition: all ${TOP_BAR_TRANSITION_DURATION_SEC}s ease-in-out;
    z-index: 1;
  }
`;

export const FadingContainer = styled(BaseFadingContainer).attrs({
  axis: 'y',
  backgroundColor: 'white',
})`
  &:after {
    height: 0;
  }
`;

export const Contents = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: auto;
`;

export const TabPanelContentsWrapper = styled(motion.div)`
  width: 100%;
  display: flex;
`;

export const BaseTabPanelContentsRoot = styled(motion.div)<MainStyleProps>`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  top: ${({ $top }) => $top}px;
`;

export const ContentsWrapper = styled.div`
  padding-bottom: 56px;
`;
