import { userQueryKey } from 'state';

export const eddyAudioExportQueryKeys = {
  all: [{ scope: 'audioExport' }] as const,
  audioDownload: (audioExportId: number) =>
    [{ ...eddyAudioExportQueryKeys.all[0], audioExportId }] as const,
};

export const eddyVideoExportQueryKeys = {
  all: [{ scope: 'videoExport' }] as const,
  videoDownload: (videoExportId: number) =>
    [{ ...eddyVideoExportQueryKeys.all[0], videoExportId }] as const,
};

export const eddyProjectsQueryKeys = {
  all: [{ scope: 'projects' }] as const,
  myProjects: (userId?: number) =>
    [{ ...eddyProjectsQueryKeys.all[0], ...userQueryKey(userId) }] as const,
  myProject: (userId?: number, cuid?: string) =>
    [
      { ...eddyProjectsQueryKeys.myProjects(userId)[0], projectCuid: cuid },
    ] as const,
  publicAsset: (userId?: number, projectCuid?: string) =>
    [
      {
        ...eddyProjectsQueryKeys.myProject(userId, projectCuid)[0],
        entity: 'publicAsset',
      },
    ] as const,
};

export const eddyTranscriptQueryKeys = {
  all: [{ scope: 'transcript' }] as const,
  myTranscripts: (userId?: number) =>
    [{ ...eddyTranscriptQueryKeys.all[0], ...userQueryKey(userId) }] as const,
  transcript: (userId?: number, transcriptId?: number) =>
    [
      {
        ...eddyTranscriptQueryKeys.myTranscripts(userId)[0],
        transcriptId,
      },
    ] as const,
  clipSuggestions: (userId?: number, transcriptId?: number) =>
    [
      {
        ...eddyTranscriptQueryKeys.transcript(userId, transcriptId)[0],
        entity: 'clips',
      },
    ] as const,
  showNoteSuggestions: (userId?: number, transcriptId?: number) => [
    {
      ...eddyTranscriptQueryKeys.transcript(userId, transcriptId)[0],
      entity: 'notes',
    },
  ],
};

export const eddyWaveformQueryKeys = {
  all: [{ scope: 'waveform' }] as const,
  myWaveforms: (userId?: number) =>
    [{ ...eddyWaveformQueryKeys.all[0], ...userQueryKey(userId) }] as const,
  assetWaveform: (userId?: number, url?: string) =>
    [{ ...eddyWaveformQueryKeys.myWaveforms(userId)[0], url }] as const,
};

export const eddyProjectNameSuggestionsQueryKeys = {
  all: [{ scope: 'projectNameSuggestions' }] as const,
  projectNameSuggestions: (projectCuid?: string) =>
    [{ ...eddyProjectNameSuggestionsQueryKeys.all[0], projectCuid }] as const,
};

export const eddyPromoPacksQueryKeys = {
  all: [{ scope: 'promoPacks' }] as const,
  promoPacks: (transcriptId?: number) =>
    [{ ...eddyPromoPacksQueryKeys.all[0], transcriptId }] as const,
  promoPackContents: (transcriptId?: number) =>
    [
      {
        ...eddyPromoPacksQueryKeys.all[0],
        entity: 'promoPackContents',
        transcriptId,
      },
    ] as const,
};

export const eddySpeakersQueryKeys = {
  all: [{ scope: 'speakers' }] as const,
  speakers: () => [{ ...eddySpeakersQueryKeys.all[0] }] as const,
};

export const projectAssetRegenerationQueryKeys = {
  all: [{ scope: 'projectAssetRegeneration' }] as const,
  status: (regenerationJobId?: number) =>
    [
      {
        ...projectAssetRegenerationQueryKeys.all[0],
        regenerationJobId,
      },
    ] as const,
};

export const colorPaletteQueryKeys = {
  all: [{ scope: 'colorPalette' }] as const,
  project: (projectCuid?: string) =>
    [{ ...colorPaletteQueryKeys.all[0], projectCuid }] as const,
};

export const edgeAssetQueryKeys = {
  all: [{ scope: 'edgeAsset' }] as const,
  project: (projectCuid?: string) =>
    [{ ...edgeAssetQueryKeys.all[0], projectCuid }] as const,
  asset: (edgeAssetJobId?: number, projectCuid?: string) =>
    [
      {
        ...edgeAssetQueryKeys.project(projectCuid)[0],
        edgeAssetJobId,
      },
    ] as const,
};
