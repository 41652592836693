import { FileDropzoneAccept } from '@sparemin/blockhead';

export const ACCEPTED_AUDIO_FILE_EXTENSIONS = [
  // mp3 files
  // https://en.wikipedia.org/wiki/MP3
  '.mp3',

  // aac files inside of various containers
  // https://en.wikipedia.org/wiki/Advanced_Audio_Coding
  '.aac',

  // Apple container formats
  '.m4a',
  '.m4b',
  '.m4p',
  '.m4r',
  '.mpeg',
  '.x-m4a',

  // wav files
  // https://en.wikipedia.org/wiki/WAV
  '.wav',
  '.wave',
];

export const ACCEPTED_VIDEO_FILE_EXTENSIONS = [
  // webm files
  '.webm',

  // QuickTime files
  '.mov',
  '.qt',
  '.quicktime',

  // other
  '.mp4',
  '.3gp',
  '.m4v',
];

export const DROPZONE_ACCEPT: FileDropzoneAccept = {
  'audio/*': ACCEPTED_AUDIO_FILE_EXTENSIONS,
  'video/*': ACCEPTED_VIDEO_FILE_EXTENSIONS,
};

export const DROPZONE_ACCEPT_AUDIO: FileDropzoneAccept = {
  'audio/*': ACCEPTED_AUDIO_FILE_EXTENSIONS,
};

export const DROPZONE_ACCEPT_VIDEO: FileDropzoneAccept = {
  'video/*': ACCEPTED_VIDEO_FILE_EXTENSIONS,
};
