import { useStaticCallback } from '@sparemin/blockhead';
import { produce } from 'immer';
import { useEffect, useLayoutEffect } from 'react';
import { create } from 'zustand';
import { actionsSelector } from './selectors';
import {
  HandleKeyPress,
  HotkeyStore,
  UseRegisterKeybindingOptions,
} from './types';
import { formatKeys } from './utils';

const useHotkeyStore = create<HotkeyStore>()((set, get) => ({
  bindings: {},
  actions: {
    registerKeyBinding: (key, handler) => {
      set(
        produce((state: HotkeyStore) => {
          const keys = Array.isArray(key) ? key : [key];
          const formattedKeys = formatKeys(keys);

          formattedKeys.forEach((k) => {
            const currentHandlers = state.bindings[k] ?? [];

            if (currentHandlers.length === 0) {
              state.bindings[k] = currentHandlers;
            }

            const index = currentHandlers.indexOf(handler);

            if (index < 0) {
              currentHandlers.unshift(handler);
            }
          });
        }),
      );
      return () => get().actions.unregisterKeyBinding(key, handler);
    },
    unregisterKeyBinding: (key, handler) =>
      set(
        produce((state: HotkeyStore) => {
          const keys = Array.isArray(key) ? key : [key];
          const formattedKeys = formatKeys(keys);

          formattedKeys.forEach((k) => {
            const currentHandlers = state.bindings[k] ?? [];

            const index = currentHandlers.indexOf(handler);

            if (index >= 0) {
              currentHandlers.splice(index, 1);
            }
          });
        }),
      ),
  },
}));

export const useHotkeyActions = () => useHotkeyStore(actionsSelector);

export function useHotKeySubscription(
  cb: (current: HotkeyStore, prev: HotkeyStore) => void,
) {
  useLayoutEffect(() => useHotkeyStore.subscribe(cb), [cb]);
}

/**
 * Registers a keybinding using a stable reference for the handler.
 * Automatically unregisters the handler when the component unmounts.
 *
 * A "key" could be either a single key or a combination of a key and a modifier.
 * For example, a hotkey could be "z" or "ctrl+z".  Supported modifiers can be found
 * by checking the ModifierKey type.
 *
 * When using a modifier, the modifier must come first, followed by a "+", followed
 * by the key.
 *
 * Note that all keys should be specified in lowercase as using a capital letter
 * implies that "shift" must also be pressed along with that letter.  For example,
 * the browser will report a keypress of "z" if only that key is pressed, but
 * "Z" if shift is held while pressing "z".
 */
export function useRegisterKeybinding(
  key: string | string[],
  handler: HandleKeyPress,
  opts?: UseRegisterKeybindingOptions,
) {
  const { disabled = false } = opts ?? {};
  const staticHandler = useStaticCallback(handler);
  const { registerKeyBinding } = useHotkeyActions();

  useEffect(() => {
    if (!disabled) {
      const unregister = registerKeyBinding(key, staticHandler);

      return () => {
        unregister();
      };
    }
  }, [disabled, key, registerKeyBinding, staticHandler]);
}
