import { useEffect } from 'react';

export interface UsePeaksConfig {
  peaks: number[];
  wavesurfer: WaveSurfer | undefined;
}

// redraw the waveform when peaks changes
export default function usePeaks({ peaks, wavesurfer }: UsePeaksConfig) {
  useEffect(() => {
    if (!wavesurfer) {
      return;
    }

    // `wavesurfer.backend.peaks` is private so the types are correct here, but it's
    // a hack we're using to load the data in place after rendering.  this is the
    // same workaround we use in Headliner
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (peaks !== wavesurfer.backend.peaks) {
      const redraw = () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        wavesurfer.backend.peaks = peaks;
        wavesurfer.drawBuffer();
      };

      if (wavesurfer.isReady) {
        redraw();
      } else {
        wavesurfer.once('ready', redraw);
      }

      return () => {
        wavesurfer.un('ready', redraw);
      };
    }
  }, [peaks, wavesurfer]);
}
