import { Heading, Spacer, useTheme } from '@sparemin/blockhead';
import { useCallback, useState } from 'react';
import MediaUploader from 'components/MediaUploader';
import { Option } from 'components/MediaUploader/types';
import notifyError from 'components/notification';
import useCreateProjectFromMedia from 'pages/ProjectsPage/state/useCreateProjectFromMedia';
import { useModalsActions } from 'state/modal';
import { usePlanUploadMaxValues } from 'state/pricing';
import { useEddyPrefsOverride } from 'state/userPref';
import { getMediaTypeFromFileType } from 'utils/fileUpload';
import { megabytesToBytes } from 'utils/math';
import { hoursToSec } from 'utils/time';
import useFileDropped from '../useFileDropped';
import useOnCreateProject from '../useOnCreateProject';
import * as S from './styles';
import useTrackUploadStatus from './useTrackUploadStatus';

export interface ProjectMediaUploaderProps {}

const ProjectMediaUploader: React.FC<ProjectMediaUploaderProps> = () => {
  const { onUploadStart, onUploadComplete } = useTrackUploadStatus();

  const { pushModal, hideModal } = useModalsActions();
  const theme = useTheme();
  const [progress, setProgress] = useState<number>(0);
  const { data: userEddyPrefs } = useEddyPrefsOverride();
  const { isLoading, mutate } = useCreateProjectFromMedia();
  const { onSuccess } = useOnCreateProject({ replace: false });
  const { data: uploadLimits } = usePlanUploadMaxValues();
  const maxMediaDurationHr = uploadLimits?.maxMediaDurationHr;
  const maxSizeMB = uploadLimits?.maxUploadSizeMB;

  const handleSourceClick = useCallback(
    (source: Option) => {
      if (source === Option.COMPUTER) {
        return;
      }
      pushModal({
        name: 'ProjectMediaUpload',
        params: {
          defaultSelectedTab: source,
        },
      });
    },
    [pushModal],
  );

  const handleFilesAccepted = useCallback(
    (files: File[]) => {
      const handleUploadComplete = (
        file: File,
        status: 'success' | 'failure',
      ) => {
        onUploadComplete(file, status);

        if (status === 'failure') {
          notifyError({
            heading: 'Error uploading file',
            errorCode: {
              code: 'ER001',
              type: getMediaTypeFromFileType(file.type),
            },
          });
        }
      };

      pushModal({
        name: 'ProjectLanguageSelector',
        params: {
          onLanguageSelected(language) {
            hideModal();

            mutate(
              {
                files,
                language,
                onUploadProgress(value) {
                  setProgress(value);
                },
                userEddyPrefs,
                onUpload: onUploadStart,
                onUploadComplete: handleUploadComplete,
              },
              {
                // this MediaUploader is specifically for the projects page, which redirects
                // to the editor after file upload.  The progress value only needs to be
                // reset if the upload fails because otherwise the user will get redirected
                // and we actually don't want the progress state to reset in that case as
                // it will cause the "zero" state of the uploader to flash on screen before
                // the redirect
                onError() {
                  setProgress(0);
                },
                onSuccess: (data) => {
                  onSuccess(data, language);
                },
              },
            );
          },
        },
      });
    },
    [
      hideModal,
      mutate,
      onSuccess,
      onUploadComplete,
      onUploadStart,
      pushModal,
      userEddyPrefs,
    ],
  );

  const { onFileAccepted, onFileRejected } = useFileDropped({
    onDropAccepted: (files) => {
      hideModal();
      handleFilesAccepted(files);
    },
  });

  return (
    <MediaUploader
      maxSize={megabytesToBytes(maxSizeMB)}
      maxDuration={hoursToSec(maxMediaDurationHr)}
      onDropAccepted={onFileAccepted}
      onDropRejected={onFileRejected}
    >
      {isLoading ? (
        <Spacer space={1} orientation="vertical" align="center">
          <Heading align="center" level={1}>
            Uploading file...
          </Heading>
          <S.Progress
            aria-label="file upload progress"
            isIndeterminate={progress >= 100 && isLoading}
            fillColor={theme.palette.actionPrimary.main}
            fillWidth={5}
            trackWidth={5}
            value={Math.floor(progress)}
          />
        </Spacer>
      ) : (
        <MediaUploader.DefaultBody onSourceClick={handleSourceClick} />
      )}
    </MediaUploader>
  );
};

export default ProjectMediaUploader;
