import { Heading, Spacer } from '@sparemin/blockhead';
import * as S from './styles';

export interface DisclaimerProps {}

const Disclaimer: React.FC<DisclaimerProps> = () => {
  return (
    <S.Plate>
      <Spacer orientation="horizontal" align="center" space="16px">
        <S.DisclaimerIcon />

        <Heading level={2}>
          Once you place these URLs into your RSS feed, any changes made in your
          project will be public.
        </Heading>
      </Spacer>
    </S.Plate>
  );
};

export default Disclaimer;
