import { TranscriptWord } from 'api';
import { search } from 'utils/arrays';
import { inRange } from 'utils/math';

export function findActiveWord(
  words: TranscriptWord[],
  millis: number,
): TranscriptWord | undefined {
  const index = search(words, millis, (word) => {
    if (inRange(millis, word.startMillis, word.endMillis)) {
      return 0;
    }

    return millis < word.startMillis ? -1 : 1;
  });

  return words[index];
}
