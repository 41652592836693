import { ProjectListResult } from 'api';

export const getProjectNameFromCuid = (
  cuid: string,
  pages?: ProjectListResult[],
) => {
  const matchingProject = pages
    ?.flatMap((page) => page.projects)
    ?.find((project) => project.projectCuid === cuid);
  if (matchingProject) return matchingProject.name;
};
