import { PromoPackAssetType } from 'api';
import {
  usePromoPackAssetDisplayHtml,
  usePromoPackAssetDisplayText,
  usePromoPackAssetTextContent,
  usePromoPackContentType,
} from 'pages/TranscriptEditorPage/state/useGetPromoPackContents';

export interface UseTextContentConfig {
  assetType?: PromoPackAssetType;
}

export interface UseTextContentResult {
  textToDisplay: string;
  textToCopy: string;
}

/**
 * Depending on the contentType, the original text and display content come in
 * different formats:
 *
 * * plainText: For simple text assets.
 *   - displayText: How it looks on the screen.
 *   - text: What gets copied to the clipboard.
 * * html: For assets containing HTML formatting.
 *   - displayHtml: How it looks on the screen (with HTML rendered).
 *   - text: What gets copied to the clipboard.
 *
 * This hook selects the appropriate text fields (textToDisplay, textToCopy) for
 * an asset based on its contentType (plainText or html). This ensures correct
 * display and clipboard behavior.
 */
export default function useTextContent({
  assetType,
}: UseTextContentConfig): UseTextContentResult {
  const { data: contentType = 'plainText' } =
    usePromoPackContentType(assetType);

  const { data: textDisplayContent = '' } =
    usePromoPackAssetDisplayText(assetType);
  const { data: textContent = '' } = usePromoPackAssetTextContent(assetType);

  const { data: htmlDisplayContent = '' } =
    usePromoPackAssetDisplayHtml(assetType);

  return {
    ...{
      plainText: {
        textToDisplay: textDisplayContent,
        textToCopy: textContent,
      },
      html: {
        textToDisplay: htmlDisplayContent,
        textToCopy: textContent,
      },
    }[contentType],
  };
}
