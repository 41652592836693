import { MediaRejectedEvent } from '@sparemin/media-import';
import { useCallback } from 'react';
import { IntroOutroFileRejectionReason } from './types';
import { notifyFileRejectionError } from './utils';

export interface UseHandleMediaRejectedConfig {}

export interface UseHandleMediaRejectedResult {
  onMediaRejected: (args: MediaRejectedEvent) => void;
}

export default function useHandleMediaRejected(): UseHandleMediaRejectedResult {
  const handleMediaRejected = useCallback((args: MediaRejectedEvent) => {
    const { reason } = args.payload;

    notifyFileRejectionError({
      reason: reason as IntroOutroFileRejectionReason,
    });
  }, []);

  return { onMediaRejected: handleMediaRejected };
}
