import { useContext } from 'react';
import TopBarContext from './TopBarContext';

export default function useTopBar() {
  const context = useContext(TopBarContext);

  if (context === undefined) {
    throw new Error('useTopBar must be used within TopBarProvider');
  }

  return context;
}
