import { Spacer, breakpointDown } from '@sparemin/blockhead';
import styled from 'styled-components';
import Modal from 'components/Modal';

export const Root = styled(Modal)`
  width: 570px;

  ${breakpointDown('md')`
    width: 100%;
  `}
`;

export const Loader = styled(Spacer).attrs({
  align: 'center',
  justify: 'center',
})`
  width: 100%;
`;
