import { TranscriptFormat } from 'api/services/transcript';
import {
  TIMESTAMPS_SUPPORTED_EXPORT_FORMATS,
  transcriptFormatList,
  YOUTUBE_FORMATTING_UNSUPPORTED_EXPORT_FORMATS,
} from './constants';

export function checkIsFileTypeUnsupportedForYouTubeFormatting(
  fileType: TranscriptFormat,
): boolean {
  return YOUTUBE_FORMATTING_UNSUPPORTED_EXPORT_FORMATS.includes(fileType);
}

export function checkIsFileTypeSupportedForIncludeTimestamps(
  fileType: TranscriptFormat,
): boolean {
  return TIMESTAMPS_SUPPORTED_EXPORT_FORMATS.includes(fileType);
}

export function getTranscriptFormatLabelByValue(
  value: TranscriptFormat,
): string {
  const item = transcriptFormatList.find((format) => format.value === value);
  return item ? item.label : '';
}
