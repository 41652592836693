import { isMobile, isTablet } from 'react-device-detect';
import { Outlet } from 'react-router-dom';
import MobileComingSoon from './MobileComingSoon';

export interface ResponsiveLayoutSwitcherProps {}

const ResponsiveLayoutSwitcher: React.FC<
  ResponsiveLayoutSwitcherProps
> = () => {
  if (isMobile && !isTablet) {
    return <MobileComingSoon />;
  }

  return <Outlet />;
};

export default ResponsiveLayoutSwitcher;
