import BiMap from 'utils/BiMap';

/**
 * When transcript elements get created (either via the UI or by undoing an action),
 * new ids get created. First, a placeholder id is created locally so that the UI
 * can be updated immediately.  Later, once the operation is "committed", the API
 * returns the actual id for the new element.
 *
 * The placeholder id gets used for the element for the duration of the session
 * for a few reasons. One reason is to cut down on re-renders - rendering once with
 * the placeholder id and then again  with the actual id, along with recomputing
 * a lot of cached selectors.  Another reason is that it acts more predictably -
 * for example, the edit-speaker menu has to be opened after the new segment is created
 * and it would be difficult to do that if there's no way of knowing whether the
 * new segment is using a placeholder id or the actual id.
 */

export default class PlaceholderIdMap {
  private bimap: BiMap<number, number>;

  constructor() {
    this.bimap = new BiMap();
  }

  public setPlaceholderId(placeholderId: number, actualId: number) {
    this.bimap.set(placeholderId, actualId);
  }

  public getPlaceholderId(actualId: number) {
    return this.bimap.getKey(actualId);
  }

  /**
   * Useful when iterating over objects when unsure if the id in the object is the
   * actual id or the placeholder.  If a value isn't found for the id passed into
   * this function, the function argument is returned.
   */
  public getActualId(placeholderOrActualId: number) {
    return this.bimap.get(placeholderOrActualId) ?? placeholderOrActualId;
  }

  public clear() {
    return this.bimap.clear();
  }

  public hasPlaceholderId(actualId: number) {
    return this.bimap.hasValue(actualId);
  }
}
