import { Button, VideoStars } from '@sparemin/blockhead';
import styled from 'styled-components';
import { Root } from '../styles';
import { SUGGESTED_CLIPS_TRIGGER_WIDTH } from './constants';

export const Trigger = styled(Button).attrs({
  variant: 'special',
})`
  display: flex;
  flex-shrink: 0;
  height: 50px;
  justify-content: center;
  // shift to the left by 1px to cover the border of the root explorer bar element
  left: -1px;
  overflow: hidden;
  padding: 17px 16px;
  position: absolute;
  transition: ${({ theme }) => theme.transitions.create('all', '300ms')};
  white-space: nowrap;
  width: ${SUGGESTED_CLIPS_TRIGGER_WIDTH};
  z-index: 2;

  ${Root}[data-isopen='true'] & {
    height: 30px;
    justify-content: flex-start;
    padding: 7px 8px;
    pointer-events: none;
    transform: translateX(10px);
    width: 41px;
  }
`;

export const TriggerIcon = styled(VideoStars)`
  height: 16px;
  width: 25px;
`;
