import { getThemeColor, StatusTag } from '@sparemin/blockhead';
import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
`;

export const Badge = styled(StatusTag)`
  background-color: ${getThemeColor('l4')};
  color: ${getThemeColor('d1')};
  letter-spacing: normal;
  position: relative;
  text-transform: capitalize;
  transition: ${({ theme }) =>
    theme.transitions.create(['background-color', 'color'])};
  user-select: text !important;

  &[data-hovered='true'] {
    background-color: ${getThemeColor('actionPrimary')};
    color: ${getThemeColor('white')};
  }
`;
