import { VirtualizerOptions, windowScroll } from '@tanstack/react-virtual';
import { useCallback, useRef } from 'react';

export const DEFAULT_SMOOTH_SCROLL_DURATION_MILLIS = 250;

export interface UseSmoothScrollConfig {
  durationMillis?: number;
}

// easeInOutQuit https://easings.net/#easeInOutQuint
function ease(t: number) {
  return t < 0.5 ? 16 * Math.pow(t, 5) : 1 - Math.pow(-2 * t + 2, 5) / 2;
}

/**
 * Adapted from https://github.com/TanStack/virtual/blob/623ac63988f16e6ea6755d8b2e190c123134501c/examples/react/smooth-scroll/src/main.tsx
 */
export default function useSmoothScroll(config?: UseSmoothScrollConfig) {
  const { durationMillis = DEFAULT_SMOOTH_SCROLL_DURATION_MILLIS } =
    config ?? {};

  const scrollingRef = useRef<number>();

  // react-virtual estimates the size of rows before they get measured.  once it knows
  // the actual size, it calls the scrolling function with "adjusments" used to correct
  // the scroll position after reading the actual size of rows
  const adjustmentsRef = useRef(0);

  const scrollToFn = useCallback<
    VirtualizerOptions<Window, Element>['scrollToFn']
  >(
    (offset, opts, instance) => {
      const adjustments = opts.adjustments ?? 0;

      // as the smooth scrolling animation is running, react-query will call the
      // `scrollToFn` to adjust the scroll position as it reads the actual size of
      // rows.  We want to account for these adjustments in our smooth scrolling
      // calculations, so save them
      adjustmentsRef.current += adjustments;

      // if not smooth scrolling, defer to window scroll
      if (opts.behavior !== 'smooth') {
        windowScroll(offset, opts, instance);
        return;
      }

      // initialize smooth scrolling
      const startOffset = instance.scrollOffset ?? 0;
      const startTime = Date.now();
      adjustmentsRef.current = adjustments;
      scrollingRef.current = startTime;

      const run = () => {
        if (scrollingRef.current !== startTime) {
          // smooth scroll operation was interrupted by another one
          return;
        }

        const now = Date.now();
        const elapsed = now - startTime;
        const progress = ease(Math.min(elapsed / durationMillis, 1));

        // offset accounting for measurement adjustments that have been accummulating
        // in adjustmentsRef.current
        const adjustedOffset = offset + adjustmentsRef.current;

        // the distance to travel from the initial startOffset based on the progress
        // of the animation
        const deltaOffset = (adjustedOffset - startOffset) * progress;

        // the final "scroll-to" position, which is the original position plus
        // the current delta based on how far we have to travel and the progress
        // of the animation
        const interpolatedOffset = startOffset + deltaOffset;

        windowScroll(interpolatedOffset, {}, instance);

        if (elapsed < durationMillis) {
          requestAnimationFrame(run);
        } else {
          // smooth scroll complete
          scrollingRef.current = undefined;
        }
      };

      requestAnimationFrame(run);
    },
    [durationMillis],
  );

  return { scrollToFn };
}
