import { produce } from 'immer';
import { TranscriptWord } from 'api';
import { TimeInterval } from 'types';
import { WordsDictionary } from '../state';
import { getWordMaxEndMillis, getWordMinStartMillis } from '../utils';
import {
  SelectionAdjustmentBoundaries,
  WordAdjustmentSelection,
} from './types';

export function getSelectionAdjustmentBoundary(
  words: WordAdjustmentSelection,
): SelectionAdjustmentBoundaries {
  const boundaries: SelectionAdjustmentBoundaries = [];

  if (words.filter(Boolean).length === 0) {
    return boundaries;
  }

  if (!words[0]?.isDeleted) {
    boundaries.push('start');
  }

  if (!words[2]?.isDeleted) {
    boundaries.push('end');
  }

  return boundaries;
}

export function getWaveformInterval(
  boundaries: SelectionAdjustmentBoundaries,
  word: TranscriptWord | undefined,
  audioDurationMillis: number,
) {
  if (!word) {
    return { startMillis: 0, endMillis: 0 };
  }

  const start = boundaries.includes('start')
    ? word.startMillis - 1000
    : word.startMillis;
  const end = boundaries.includes('end')
    ? word.endMillis + 1000
    : word.endMillis;

  return {
    waveStartMillis: Math.max(0, start),
    waveEndMillis: Math.min(audioDurationMillis, end),
  };
}

export function getAdjustmentWords(
  wordId: number,
  wordsById: WordsDictionary | undefined,
  words: TranscriptWord[] | undefined,
): WordAdjustmentSelection {
  if (!wordsById || !words) {
    return [undefined, undefined, undefined];
  }

  const word = wordsById[wordId];
  const prevIndex = word.index - 1;
  const nextIndex = word.index + 1;

  return [words[prevIndex], word, words[nextIndex]];
}

export function getMaxInterval(
  adjustmentWords: WordAdjustmentSelection,
  boundaries: SelectionAdjustmentBoundaries,
): Partial<TimeInterval> {
  const prevWord = adjustmentWords[0];
  const nextWord = adjustmentWords[2];

  const minStartMillis =
    !prevWord || !boundaries.includes('start')
      ? undefined
      : getWordMinStartMillis(prevWord);

  const maxEndMillis =
    !nextWord || !boundaries.includes('end')
      ? undefined
      : getWordMaxEndMillis(nextWord);

  return { startMillis: minStartMillis, endMillis: maxEndMillis };
}

export function calculateFinalAdjustments(
  adjustment: TimeInterval,
  selectionWords: WordAdjustmentSelection,
  maxInterval: Partial<TimeInterval>,
  boundaries: SelectionAdjustmentBoundaries,
  audioDurationMillis: number,
) {
  const selectedWord = selectionWords[1];

  if (!selectedWord) {
    return adjustment;
  }

  return produce(adjustment, (draft) => {
    const startMillis = draft.startMillis;
    const endMillis = draft.endMillis;

    if (!boundaries.includes('start')) {
      draft.startMillis = selectedWord.startMillis;
    } else {
      draft.startMillis = Math.max(
        0,
        startMillis,
        maxInterval.startMillis ?? -Infinity,
      );
    }

    if (!boundaries.includes('end')) {
      draft.endMillis = selectedWord.endMillis;
    } else {
      draft.endMillis = Math.min(
        audioDurationMillis,
        endMillis,
        maxInterval.endMillis ?? Infinity,
      );
    }
  });
}

export function getSurroundingWords(
  wordId: number,
  segmentStartMillis: number,
  segmentEndMillis: number,
  wordsById: WordsDictionary | undefined,
  words: TranscriptWord[] | undefined,
) {
  if (!wordsById || !words) {
    return undefined;
  }

  const targetWord = wordsById[wordId];

  const surroundingWords = [];
  for (let i = targetWord.index - 1; i > 0; i -= 1) {
    const word = wordsById[words[i].id];

    if (word.endMillis > segmentStartMillis) {
      surroundingWords.unshift(word);
    } else {
      break;
    }
  }

  for (let i = targetWord.index + 1; i < words.length; i += 1) {
    const word = wordsById[words[i].id];

    if (word.startMillis < segmentEndMillis) {
      surroundingWords.push(word);
    } else {
      break;
    }
  }

  return surroundingWords;
}

export function calculateDurationPercentageChange(
  oldDuration: number,
  newDuration: number,
): number {
  if (!oldDuration || !newDuration) {
    return 0;
  }

  return (newDuration - oldDuration) / oldDuration;
}
