import { eddyProjectsQueryKeys } from 'pages/TranscriptEditorPage/state';
import { userQueryKey } from 'state';

export const projectsQueryKeys = {
  all: [{ scope: 'projects' }] as const,
  myProjects: (userId?: number) =>
    [{ ...projectsQueryKeys.all[0], ...userQueryKey(userId) }] as const,
  myProject: (userId?: number, cuid?: string) =>
    [
      { ...eddyProjectsQueryKeys.myProjects(userId)[0], projectCuid: cuid },
    ] as const,
};

export const languageQueryKeys = {
  all: [{ scope: 'languages' }] as const,
};
