import React, { useCallback, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';

import * as S from './styles';
import SuggestionItem from './SuggestionItem';
import { getPopoverWidthMode } from './utils';

interface SuggestionsPopoverProps {
  enabled: boolean;
  onSelectSuggestion?: (suggestion: string) => void;
  suggestions?: string[];
  title?: string;
  tooltipText?: string;
  className?: string;
  triggerElementRef?: React.RefObject<HTMLElement>;
}

const SuggestionsPopover: React.FunctionComponent<SuggestionsPopoverProps> = (
  props,
) => {
  const {
    enabled,
    onSelectSuggestion,
    suggestions,
    title = 'Here are some alternate title suggestions:',
    tooltipText = 'Replace current title with this one',
    className,
    triggerElementRef,
  } = props;

  const [triggerWidth, setTriggerWidth] = useState(0);

  const isOpen = enabled && !!suggestions?.length;

  const handleSelectSuggestion = useCallback(
    (suggestion: string) => {
      onSelectSuggestion?.(suggestion);
    },
    [onSelectSuggestion],
  );

  const handleResizeTrigger = useCallback((width = 0) => {
    setTriggerWidth(width);
  }, []);

  useResizeDetector({
    onResize: handleResizeTrigger,
    targetRef: triggerElementRef,
  });

  return (
    <S.Popover
      offset={12}
      isOpen={isOpen}
      placement="bottom"
      size="large"
      triggerElement={triggerElementRef?.current}
      updatePositionOnTriggerResize
      width={getPopoverWidthMode(triggerWidth)}
    >
      <S.PopoverContent
        onMouseDown={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <div className={className}>
          <S.MenuHeader>
            <S.HeadyGradientIcon />
            <S.MenuHeading level={2}>{title}</S.MenuHeading>
          </S.MenuHeader>
          <S.List>
            {suggestions?.map((suggestion) => (
              <SuggestionItem
                key={suggestion}
                onClick={handleSelectSuggestion}
                suggestion={suggestion}
                tooltipText={tooltipText}
              />
            ))}
          </S.List>
        </div>
      </S.PopoverContent>
    </S.Popover>
  );
};

export default SuggestionsPopover;
