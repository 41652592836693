import { useCollapsedIntervals } from '../../../state';
import { EditedInterval } from './types';
import useEditedToOriginalTime from './useEditedToOriginalTime';
import useOriginalToEditedTime from './useOriginalToEditedTime';

const DEFAULT_INTERVALS: EditedInterval[] = [];

export default function useEditedIntervals() {
  const { data } = useCollapsedIntervals();
  const { intervals = DEFAULT_INTERVALS, deletedMillis = 0 } = data ?? {};

  const editedToOriginalTime = useEditedToOriginalTime({ intervals });
  const originalToEditedTime = useOriginalToEditedTime({ intervals });

  return {
    editedToOriginalTime,
    deletedMillis,
    originalToEditedTime,
  };
}
