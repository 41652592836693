import { useCallback } from 'react';
import { Query, useQuery } from 'react-query';
import { getPromoPacks, PromoPacksResult } from 'api/services';
import { DEFAULT_POLLING_INTERVAL_MILLIS } from 'config';
import { QueryOptions } from 'types/react-query';
import { eddyPromoPacksQueryKeys } from './queryKeys';
import {
  hasPromoPacksSelector,
  promoPackIsProcessingSelector,
  promoPackStatusSelector,
} from './selectors';
import { useTranscriptId } from './useEditorTranscript';

type QueryKey = ReturnType<typeof eddyPromoPacksQueryKeys.promoPacks>;

export interface UseGetPromoPacksOptions<TData = PromoPacksResult>
  extends QueryOptions<PromoPacksResult, TData, QueryKey> {
  pollingInterval?: number;
}

export default function useGetPromoPacks<TData = PromoPacksResult>(
  opts?: UseGetPromoPacksOptions<TData>,
) {
  const { pollingInterval = DEFAULT_POLLING_INTERVAL_MILLIS, ...queryOptions } =
    opts ?? {};

  const { data: transcriptId } = useTranscriptId();

  const refetchInterval = useCallback(
    (
      _: TData | undefined,
      query: Query<PromoPacksResult, unknown, PromoPacksResult, QueryKey>,
    ) =>
      promoPackIsProcessingSelector(query.state.data) ? pollingInterval : false,
    [pollingInterval],
  );

  return useQuery<PromoPacksResult, unknown, TData, QueryKey>(
    eddyPromoPacksQueryKeys.promoPacks(transcriptId),
    ({ queryKey: [{ transcriptId: id }] }) => getPromoPacks(id as number),
    {
      ...queryOptions,
      enabled: !!transcriptId && (opts?.enabled ?? true),
      meta: {
        errorMessage: 'Error loading promo packs',
      },
      refetchInterval,
    },
  );
}

export const useHasPromoPacks = () =>
  useGetPromoPacks({
    select: hasPromoPacksSelector,
  });

export const usePromoPackStatus = () =>
  useGetPromoPacks({
    select: promoPackStatusSelector,
  });
