import { useEffect } from 'react';
import Region from 'wavesurfer.js/src/plugin/regions';
import WaveSurfer from 'wavesurfer.js/src/wavesurfer';
import { TimeInterval } from 'types';
import { millisToSec } from 'utils/time';
import RegionResizeEventPlugin, {
  RegionResizeEventData,
} from 'utils/wavesurfer/RegionResizeEventPlugin';
import { CLIP_REGION_ID } from './constants';

export interface UseConstrainClipConfig {
  maxInterval?: Partial<TimeInterval>;
  wavesurfer: WaveSurfer | undefined;
}

export default function useConstrainClip({
  maxInterval,
  wavesurfer,
}: UseConstrainClipConfig) {
  const { startMillis: minClipStartMillis, endMillis: maxClipEndMillis } =
    maxInterval ?? {};

  useEffect(() => {
    if (
      !wavesurfer ||
      (maxClipEndMillis === undefined && minClipStartMillis === undefined)
    ) {
      return;
    }

    let maxLength: number | undefined;

    const updateMaxLength = (region: Region, len: number) => {
      if (region.maxLength === undefined || len < region.maxLength) {
        maxLength = region.maxLength;
        region.update({ maxLength: len });
      }
    };

    const handleClipResizeStart = ({
      handle,
      region,
    }: RegionResizeEventData) => {
      if (region.id !== CLIP_REGION_ID) {
        return;
      }

      const durationSec = region.end - region.start;

      if (handle === 'left' && minClipStartMillis !== undefined) {
        const minClipStartSec = millisToSec(minClipStartMillis);
        const limitDeltaSec = region.start - minClipStartSec;
        updateMaxLength(region, durationSec + limitDeltaSec);
      } else if (handle === 'right' && maxClipEndMillis !== undefined) {
        const maxClipEndSec = millisToSec(maxClipEndMillis);
        const limitDeltaSec = maxClipEndSec - region.end;
        updateMaxLength(region, durationSec + limitDeltaSec);
      }
    };

    const handleClipResizeEnd = ({ region }: RegionResizeEventData) => {
      if (region.id !== CLIP_REGION_ID) {
        return;
      }

      if (maxLength !== undefined) {
        region.update({ maxLength });
        maxLength = undefined;
      }
    };

    wavesurfer.on(
      RegionResizeEventPlugin.RESIZE_START_EVENT_NAME,
      handleClipResizeStart,
    );

    wavesurfer.on(
      RegionResizeEventPlugin.RESIZE_END_EVENT_NAME,
      handleClipResizeEnd,
    );

    return () => {
      wavesurfer.un(
        RegionResizeEventPlugin.RESIZE_START_EVENT_NAME,
        handleClipResizeStart,
      );

      wavesurfer.un(
        RegionResizeEventPlugin.RESIZE_END_EVENT_NAME,
        handleClipResizeEnd,
      );
    };
  }, [maxClipEndMillis, minClipStartMillis, wavesurfer]);
}
