import TimelinePlugin, {
  TimelinePluginParams,
} from 'wavesurfer.js/src/plugin/timeline';
import { PluginDefinition } from 'wavesurfer.js/types/plugin';

interface InternalTimelinePluginParams extends Required<TimelinePluginParams> {
  height: number;
}

/**
 * A custom WaveSurfer Timeline plugin to render only the timecodes the user can
 * see.
 *
 * The default Timeline plugin that shipts with WaveSurfer is horribly inefficient
 * and creates a lot of lag when initializing wavesurfer.  This plugin aims to
 * help with performance by rendering only those timecodes that the user can see.
 * The plugin also omits some functionality that's unnecessary for our implementation
 * (e.g. tick marks).
 */
export default class TimelineSegmentPlugin extends TimelinePlugin {
  // the underlying TimelinePlugin sets defaults for all optional parameters. without
  // this declaration, TS will complain about accessing nearly every param property
  // because most are optional and might be undefined.
  declare params: InternalTimelinePluginParams;

  static create(params: TimelinePluginParams): PluginDefinition {
    return {
      deferInit: !!params.deferInit,
      params,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      instance: TimelineSegmentPlugin,
      name: 'timeline',
    };
  }

  /**
   * @override
   *
   * In the TimelinePlugin, `_onScroll` is used to syncrhonize the scroll offset
   * of the timeline canvases with that of the waveform canvases.  For this timeline
   * plugin, there is only a single canvas with no overflow, so scroll offset is
   * always zero and nothing needs to be handled in this function
   */
  _onScroll = () => {};

  /**
   * @override
   */
  updateCanvases(): void {
    if (this.canvases.length < 1) {
      this.addCanvas();
    }
  }

  /**
   * @override
   *
   * In the base Timeline plugin, this method places all of the canvases in the
   * correct location.  In this implementation, there is a single canvas that has
   * to be positioned.
   */
  updateCanvasesPositioning(): void {
    const canvas = this.canvases[0];
    const viewportWidth = this.wavesurfer.drawer.getWidth();

    canvas.width = viewportWidth;
    canvas.height = this.params.height * 1 * this.wavesurfer.params.pixelRatio;

    this.util.style(canvas, {
      width: `${viewportWidth / this.wavesurfer.params.pixelRatio}px`,
      height: `${this.params.height}px`,
      left: '0',
    });
  }

  /**
   * @override
   */
  renderCanvases(): void {
    const wsParams = this.wavesurfer.params;

    const pixelScale = wsParams.minPxPerSec * wsParams.pixelRatio;
    const duration = this.wavesurfer.drawer.getWidth() / wsParams.minPxPerSec;
    const fontSize = this.params.fontSize * wsParams.pixelRatio;
    const totalSeconds = Math.ceil(duration);
    const height = this.params.height * wsParams.pixelRatio;

    // typedefs have a typo and call this param "formatTimecallback" (notice
    // lowercase "c").  This is why there's a ts-ignore  comment below
    const formatTime = this.params.formatTimeCallback;

    const intervalFnOrVal = (option: number | ((pxPerSec: number) => number)) =>
      typeof option === 'function' ? option(wsParams.minPxPerSec) : option;

    const timeInterval = intervalFnOrVal(this.params.timeInterval);

    const curSeconds =
      (this.wavesurfer.drawer.wrapper.scrollLeft * wsParams.pixelRatio) /
      wsParams.minPxPerSec;

    this.setFillStyles(this.params.primaryColor);
    this.setFonts(`${fontSize}px ${this.params.fontFamily}`);
    this.setFillStyles(this.params.primaryFontColor);
    for (
      let i = 0, curPixel = 0;
      i < totalSeconds / timeInterval;
      i += 1, curPixel += timeInterval * pixelScale
    ) {
      this.fillText(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        formatTime(curSeconds + i * timeInterval, pixelScale),
        curPixel / wsParams.pixelRatio,
        height,
      );
    }
  }
}
