import { useHover } from '@sparemin/blockhead';
import { memo, useRef } from 'react';
import { WHITESPACE_WORD } from 'pages/TranscriptEditorPage/constants';
import { useWord } from '../../state';
import { useIsWordActive, useIsWordSelected } from '../../state/editorUi';
import { WORD_ID_DATA_ATTRIBUTE } from './constants';
import * as S from './styles';
import { WordProps } from './types';

const Word: React.FC<WordProps> = memo(({ className, id, separator }) => {
  const ref = useRef<HTMLButtonElement>(null);
  const isActive = useIsWordActive(id);
  const isSelected = useIsWordSelected(id);

  const { isHovered, hoverProps } = useHover({});

  const { data: word } = useWord(id);

  return !word ? null : (
    <S.Root
      {...hoverProps}
      $isActive={isActive}
      $isDeleted={!!word.isDeleted}
      $isSelected={isSelected}
      $isHovered={isHovered}
      className={className}
      ref={ref}
      {...{
        [WORD_ID_DATA_ATTRIBUTE]: word.id,
      }}
    >
      {word.isSpace ? WHITESPACE_WORD : word.text}
      {
        /*
         * A note on the &#8203;  See https://stackoverflow.com/a/26972530
         *
         * For the best UI/UX, the space should be wrapped in an HTML element.  Doing
         * so allows us to style it and, for example, make sure it has the same z-index
         * as other elements in the same layer.
         *
         * We have a lot of dom nodes in this app, so wrapping each space in its own
         * span will double the number of dom elements.
         *
         * Rather than doing that, the space is place directly within the Word. The Word
         * has display inline-block to support various editor styles.  inline-block seems
         * to strip trailing whitespace from its contents.  Adding an invisible character
         * after the whitespace seems to prevent the whitespace from being removed as
         * whitespace "inside" of the element's contents isn't removed.
         */
        separator && <> &#8203;</>
      }
    </S.Root>
  );
});

export default Word;
