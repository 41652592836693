export default function getDuration(src: string | File | Blob) {
  const result = new Promise<number>((resolve, reject) => {
    const audio = new Audio();
    audio.volume = 0;

    const loadSource = () => {
      audio.src =
        src instanceof File || src instanceof Blob
          ? URL.createObjectURL(src)
          : src;
    };

    audio.addEventListener('loadedmetadata', function getAudioDuration() {
      resolve(audio.duration);
      audio.removeEventListener('loadedmetadata', getAudioDuration);
    });
    audio.addEventListener('error', function getError() {
      reject(audio.error);
      audio.removeEventListener('error', getError);
    });
    loadSource();
  });

  return result;
}
