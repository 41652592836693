export const EVENTS = {
  copyShowNotes: 'CopyShowNotes',
  copyEddyProjectURL: 'CopyEddyProjectURL',
  promoPackModalAction: 'PromoPackModalAction',
  regenerateAIOutput: 'RegenerateAIOutput',
  downloadPromoPack: 'DownloadPromoPack',
  promoPackPopperShown: 'PromoPackPopperShown',
  promoPackPopperClicked: 'PromoPackPopperClicked',
  integratorModalSelection: 'IntegratorModalSelection',
  dismissSuggestedClip: 'DismissSuggestedClip',
  signup: 'CompleteSignUp',
  signupPageLoaded: 'SignUpPageLoaded',
  signupError: 'SignUpError',
  login: 'Login',
  loginPageLoaded: 'LoginPageLoaded',
  loginError: 'LoginError',
  forgotPassword: 'ForgotPassword',
  uploadBegins: 'UploadAdd',
  uploadCompleted: 'UploadCompleted',
  openProject: 'OpenProject',
  replaceTitleWithSuggestion: 'ReplaceTitleWithSuggestion',
  transcriptPageTranscribing: 'TranscriptPageTranscribing',
  transcriptPageLoading: 'TranscriptPageLoading',
  transcriptPageLoaded: 'TranscriptPageLoaded',
  trackTranscriptPageGatedLoaded: 'TranscriptPageGated',
  highlightPopperShown: 'HighlightPopperShown',
  highlightPopperClicked: 'HighlightPopperClicked',
  speakerLabelClick: 'SpeakerLabelClick',
  speakerLabelSave: 'SpeakerLabelSave',
  exportButtonClicked: 'ExportButtonClicked',
  export: 'Export',
  errorNotification: 'ErrorNotification',
  useSuggestedClip: 'UseSuggestedClip',
  waveformRevealed: 'WaveformRevealed',
  waveformPercentageChange: 'WaveformPercentChange',
  unlockThisFeature: 'UnlockThisFeature',
  saveChangesToChapter: 'SaveChangesToChapter',
  trackClickUploadLimitBanner: 'ClickUploadLimitBanner',
  trackClickPodcastSearch: 'ClickPodcastSearch',
  trackClickPodcastName: 'ClickPodcastName',
  trackClickEpisodeName: 'ClickEpisodeName',
} as const;
