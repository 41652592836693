import { URL_GENERATOR_SERVICE_URL } from 'config';

export const REDIRECT_WIDGET_KEY = 'n2SHDRKKRJzx7Dcvj8qGQXWzm';

export const URL_GENERATOR_SERVICE_V1_URL = `${URL_GENERATOR_SERVICE_URL}/api/v1/url-generator`;
export const URL_GENERATOR_V1_AUDIO_WIZARD_URL = `${URL_GENERATOR_SERVICE_V1_URL}/audio-wizard`;
export const URL_GENERATOR_V1_VIDEO_WIZARD_URL = `${URL_GENERATOR_SERVICE_V1_URL}/video-wizard`;
export const URL_GENERATOR_V1_TRACE = `${URL_GENERATOR_SERVICE_V1_URL}/trace`;
export const URL_GENERATOR_V1_AUDIO_WIZARD_REDIRECT = `${URL_GENERATOR_V1_AUDIO_WIZARD_URL}/redirect`;
export const URL_GENERATOR_V1_VIDEO_WIZARD_REDIRECT = `${URL_GENERATOR_V1_VIDEO_WIZARD_URL}/redirect`;
