import { Button, Checkbox } from '@sparemin/blockhead';
import React, { FormEvent } from 'react';
import ContextMenuInput from '../ContextMenuInput';
import * as S from './styles';

export interface TextCorrectionInputProps {
  label: string;
  placeholder?: string;
  width?: string | number;
  value: string;
  showUpdateAllButton?: boolean;
  updateAll?: boolean;
  submitButtonText?: string;
  onChange: (value: string) => void;
  onUpdateAll?: (isSelected: boolean) => void;
  onSubmit: (e: FormEvent) => void;
}

const TextCorrectionInput = React.forwardRef<
  HTMLInputElement,
  TextCorrectionInputProps
>(
  (
    {
      label,
      placeholder,
      width = '350px',
      value,
      showUpdateAllButton,
      updateAll,
      submitButtonText = 'correct',
      onChange,
      onUpdateAll,
      onSubmit,
    },
    ref,
  ) => {
    return (
      <S.InputMenuContainer onSubmit={onSubmit} $width={width}>
        <ContextMenuInput
          aria-label={label}
          onChange={onChange}
          ref={ref}
          value={value}
          placeholder={placeholder}
        />
        {showUpdateAllButton && (
          <Checkbox onChange={onUpdateAll} isSelected={updateAll}>
            Update all
          </Checkbox>
        )}
        <Button size="small" type="submit">
          {submitButtonText}
        </Button>
      </S.InputMenuContainer>
    );
  },
);

export default TextCorrectionInput;
