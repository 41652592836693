import { useCallback } from 'react';
import { Query, useQuery, useQueryClient } from 'react-query';

import {
  ProjectAssetRegeneration,
  getProjectAssetRegenerationStatus,
} from 'api/services';
import { DEFAULT_POLLING_INTERVAL_MILLIS } from 'config';
import { QueryOptions } from 'types';

import {
  eddyPromoPacksQueryKeys,
  projectAssetRegenerationQueryKeys,
} from './queryKeys';
import { useTranscriptId } from './useEditorTranscript';

type QueryKey = ReturnType<typeof projectAssetRegenerationQueryKeys.status>;

export interface UseGetProjectAssetRegenerationStatusQuery<
  TData = ProjectAssetRegeneration,
> extends QueryOptions<ProjectAssetRegeneration, TData, QueryKey> {
  pollingInterval?: number;
}

function useGetProjectAssetRegenerationStatus<TData = ProjectAssetRegeneration>(
  regenerationId?: number,
  opts?: UseGetProjectAssetRegenerationStatusQuery<TData>,
) {
  const { data: projectTranscriptId } = useTranscriptId();

  const {
    enabled = true,
    pollingInterval = DEFAULT_POLLING_INTERVAL_MILLIS,
    ...queryOptions
  } = opts ?? {};

  const refetchInterval = useCallback(
    (
      _: TData | undefined,
      query: Query<
        ProjectAssetRegeneration,
        unknown,
        ProjectAssetRegeneration,
        QueryKey
      >,
    ) => {
      return query?.state?.data?.status === 'processing'
        ? pollingInterval
        : false;
    },
    [pollingInterval],
  );

  return useQuery<ProjectAssetRegeneration, unknown, TData, QueryKey>(
    projectAssetRegenerationQueryKeys.status(regenerationId),
    async ({ queryKey: [{ regenerationJobId }] }) => {
      if (!projectTranscriptId || !regenerationJobId) {
        throw new Error('Error getting project asset regeneration status');
      }

      const response = await getProjectAssetRegenerationStatus({
        projectTranscriptId,
        regenerationJobId,
      });

      return response;
    },
    {
      enabled: !!regenerationId && enabled,
      refetchInterval: refetchInterval,
      ...queryOptions,
    },
  );
}

export const usePollForProjectAssetRegenerationStatus = (
  regenerationId?: number,
) => {
  const { data: transcriptId } = useTranscriptId();
  const queryClient = useQueryClient();

  return useGetProjectAssetRegenerationStatus(regenerationId, {
    enabled: !!regenerationId,
    onSuccess: (response) => {
      if (!response || response.status !== 'completed') {
        return;
      }

      if (response.status === 'completed') {
        queryClient.invalidateQueries(
          eddyPromoPacksQueryKeys.promoPackContents(transcriptId),
        );
      }
    },
  });
};
