import { useMemo } from 'react';
import AudioPlayerContext from './AudioPlayerContext';
import { AudioPlayerProviderProps } from './types';

const AudioPlayerProvider: React.FC<AudioPlayerProviderProps> = ({
  children,
  player,
}) => (
  <AudioPlayerContext.Provider value={useMemo(() => ({ player }), [player])}>
    {children}
  </AudioPlayerContext.Provider>
);

export default AudioPlayerProvider;
