import {
  Heading,
  IconButton,
  Spacer,
  StatusTag,
  Text,
  breakpointDown,
  getThemeColor,
  Thumbnail,
} from '@sparemin/blockhead';
import Skeleton from 'react-loading-skeleton';
import styled, { css } from 'styled-components';

const MAX_STATUS_TAG_WIDTH = 72;
const ACTION_BUTTON_PADDING = 5;
const GRID_COLUMN_GAP = 24;

export const Root = styled.div`
  display: grid;
  grid-template-areas: 'image . desc . status . actions .';
  grid-template-rows: auto;

  grid-template-columns:
    // The thumbnail image which has a 15px gap between it and the project description.

    max-content 15px
    // The project description fills most of the area with the standard gap next to it.
    minmax(10px, 1fr) ${GRID_COLUMN_GAP}px
    // The status tags. Each one might have a different width depending on the value
    // of the text, but the max width any of them will be is used to align the columns
    // of different rows which might have differen tags of different widths.
    // The gap next to this button is the standard size, but the design doesn't account
    // for any padding around the icon, so that has to be subtracted from the gutter value.
    ${MAX_STATUS_TAG_WIDTH}px ${GRID_COLUMN_GAP - ACTION_BUTTON_PADDING}px
    // Action buttons take up as much space as they need with a 20px gutter betwen them and
    // the end of the row.
    max-content 20px;
  color: ${getThemeColor('d1')};

  ${breakpointDown('md')`
    grid-template-areas:
      'image desc actions'
      '. status .';
    grid-template-rows: auto auto;
    grid-template-columns: max-content minmax(10px, 1fr) max-content 20px;
  `}
`;

const disableable = (opacity = 0.3) => css`
  ${Root}[data-disabled='true'] & {
    opacity: ${opacity};
  }
`;

export const ImageContainer = styled.div`
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-area: image;
`;

export const Image = styled(Thumbnail)`
  ${disableable()};
`;

export const Description = styled(Spacer).attrs({
  orientation: 'vertical',
  space: '9px',
})`
  ${disableable()};
  grid-area: desc;

  ${breakpointDown('md')`
    margin: 0 16px;
  `}
`;

export const Meta = styled(Text).attrs({
  variant: 'button',
})`
  font-size: 11px;
  font-weight: normal;
`;

export const ProjectTitle = styled(Heading).attrs({
  level: 3,
  lineHeight: 1.13,
})`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${breakpointDown('md')`
    white-space: normal;
    font-size: 16px;
  `}
`;

export const Status = styled(StatusTag)`
  place-self: center end;
  grid-area: status;
  text-transform: capitalize;

  ${breakpointDown('md')`
    width: min-content;
    place-self: start;
    margin-top: 8px;
    margin-left: 16px;
  `}
`;

export const ActionButton = styled(IconButton).attrs({
  color: 'tertiary',
})`
  width: 13px;
  height: 15px;
  grid-area: actions;
  place-self: center;

  ${breakpointDown('md')`
    place-self: start;
  `}
`;

export const ImageSkeleton = styled(Skeleton)`
  grid-area: image;

  width: 45px;
  height: 45px;
`;

export const SkeletonContainer = styled(Spacer).attrs({
  orientation: 'vertical',
})`
  width: 100%;

  & > * {
    width: 100%;
  }
`;

export const ProjectRowSkeleton = styled(Skeleton)`
  max-width: 100%;
`;
