import { Anchor, PulseLoader, Spacer } from '@sparemin/blockhead';
import Modal from 'components/Modal';
import { ExportURLsModalConfig } from 'state/modal/modals';
import { useProjectPublicAssetsUrls } from '../state/useGetProjectPublicAsset';
import Disclaimer from './Disclaimer';
import * as S from './styles';
import URLDisplay from './URLDisplay';

export interface ExportURLsModalContentsProps {
  params?: ExportURLsModalConfig['params'];
}

const ExportURLsModalContents: React.FC<ExportURLsModalContentsProps> = ({
  params,
}) => {
  const { onGoBack } = params || {};

  const {
    data: projectPublicAssetsUrls,
    isLoading: isProjectPublicAssetsUrls,
  } = useProjectPublicAssetsUrls();

  return (
    <S.Root
      showBackButton
      title="URL export"
      subtitle="You can place these links into your RSS feed."
      icon={
        <Modal.Icon backgroundColor="#68d3cf">
          <Anchor width="38px" />
        </Modal.Icon>
      }
      onBack={onGoBack}
    >
      <Modal.Body>
        <Spacer orientation="vertical" align="center" space="32px">
          <Disclaimer />

          {isProjectPublicAssetsUrls ? (
            <PulseLoader />
          ) : (
            <>
              <URLDisplay
                label="Transcript URL"
                url={projectPublicAssetsUrls?.transcripts}
                asset="Transcript"
              />

              <URLDisplay
                label="Chapters URL"
                url={projectPublicAssetsUrls?.chapters}
                asset="Chapters"
              />
            </>
          )}
        </Spacer>
      </Modal.Body>
    </S.Root>
  );
};

export default ExportURLsModalContents;
