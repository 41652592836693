import { useQuery } from 'react-query';
import {
  getUserIntegrators,
  GetUserIntegratorsResult,
} from 'api/services/user';
import { useAuth } from 'state/auth';
import { QueryOptions } from 'types';
import { userQueryKeys } from './queryKeys';
import {
  integratorDisplayNameSelector,
  isIntegratorSelector,
} from './selectors';

type QueryKey = ReturnType<typeof userQueryKeys.userIntegrators>;

export type UseGetUserIntegratorsOptions<TData = GetUserIntegratorsResult> =
  QueryOptions<GetUserIntegratorsResult, TData, QueryKey>;

function useGetUserIntegrators<TData = GetUserIntegratorsResult>(
  opts?: UseGetUserIntegratorsOptions<TData>,
) {
  const { userId } = useAuth();

  return useQuery(
    userQueryKeys.userIntegrators(userId),
    ({ queryKey: [{ userId: id }] }) =>
      getUserIntegrators({ userId: id as number }),
    {
      ...opts,
      enabled: !!userId && (opts?.enabled ?? true),
      meta: {
        errorMessage: 'Error loading user information.',
      },
    },
  );
}

export const useIsIntegrator = () =>
  useGetUserIntegrators({ select: isIntegratorSelector });

export const useIntegratorDisplayName = () =>
  useGetUserIntegrators({ select: integratorDisplayNameSelector });
