import { AnimatePresence } from 'framer-motion';
import React, { useCallback } from 'react';
import { PromoPackAsset } from 'api';
import ActionsBar from '../ActionsBar';
import { TOP_BAR_TRANSITION_DURATION_SEC } from '../BaseTabPanel/constants';
import { BaseTabPanelActionButton } from '../BaseTabPanel/types';
import { usePromoPackModal } from '../PromoPackModalContext';
import RegeneratingView from '../RegeneratingView';
import * as S from './styles';
import { TopBarProvider } from './TopBarContext';

export interface TopBarProps {
  asset: PromoPackAsset;
  actionButtons: BaseTabPanelActionButton[];
  isAdjusting: boolean;
  isRegenerating?: boolean;
  onTopBarHeightChange: (newHeight: number) => void;
  onRegenerate: () => void;
  toggleIsAdjusting: (isAdjusting?: boolean) => void;
}

const TopBar: React.FC<TopBarProps> = ({
  asset,
  actionButtons,
  isAdjusting,
  isRegenerating,
  onTopBarHeightChange,
  onRegenerate,
  toggleIsAdjusting,
}) => {
  const { promoPackTabs } = usePromoPackModal();
  const { assetType } = asset;
  const topBarContent = promoPackTabs[assetType]?.topBarContent;

  const actionsContainerRef = useCallback(
    (node: HTMLDivElement): void => {
      const element = node?.firstChild;

      if (element instanceof HTMLDivElement) {
        onTopBarHeightChange(element.offsetHeight);
      }
    },
    [onTopBarHeightChange],
  );

  return (
    <TopBarProvider
      {...{ assetType, onRegenerate }}
      onCancel={() => toggleIsAdjusting()}
    >
      <S.Root transition={{ duration: TOP_BAR_TRANSITION_DURATION_SEC }}>
        <AnimatePresence initial={false}>
          {!isRegenerating ? (
            <S.Contents
              layout
              key={`top-bar-${isAdjusting ? 'adjustments' : 'actions'}`}
              transition={{ duration: TOP_BAR_TRANSITION_DURATION_SEC }}
              ref={actionsContainerRef}
            >
              {isAdjusting ? (
                topBarContent
              ) : (
                <ActionsBar
                  {...{
                    asset,
                    actionButtons,
                  }}
                />
              )}
            </S.Contents>
          ) : (
            <RegeneratingView />
          )}
        </AnimatePresence>
      </S.Root>
    </TopBarProvider>
  );
};

export default TopBar;
