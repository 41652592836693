import { useContext } from 'react';
import TabPanelContext from './TabPanelContext';

export default function useTabPanel() {
  const context = useContext(TabPanelContext);

  if (context === undefined) {
    throw new Error('useTabPanel must be used within TabPanelProvider');
  }

  return context;
}
