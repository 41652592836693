import { useCallback } from 'react';
import { useEventTracking } from 'state/eventTracking';

export default function useSuggestionPopoverTracking() {
  const { trackDismissSuggestedClip, trackUseSuggestedClip } =
    useEventTracking();

  return {
    onDislikeClip: trackDismissSuggestedClip,
    onCreateClip: useCallback(
      () => trackUseSuggestedClip('CreateClip'),
      [trackUseSuggestedClip],
    ),
    onDownloadClip: useCallback(
      () => trackUseSuggestedClip('Download'),
      [trackUseSuggestedClip],
    ),
  };
}
