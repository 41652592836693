import { useActiveClipIndex, useIsExplorerActive } from '../state/explorerBar';
import { useClipSuggestionIds } from '../state/useClipSuggestionGroup';

export default function useIsSuggestionActive(suggestionId: number) {
  const activeClipIndex = useActiveClipIndex();
  const isExplorerActive = useIsExplorerActive('clips');
  const { data: suggestionIds } = useClipSuggestionIds();

  return isExplorerActive && suggestionIds?.[activeClipIndex] === suggestionId;
}
