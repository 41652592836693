import {
  MediaImporter,
  useEpisodeSearchPlugin,
  usePodcastSearchPlugin,
  useGoogleDrivePlugin,
  useZoomPlugin,
  GOOGLE_DRIVE_TAB_KEY,
  YOUTUBE_URL_TAB_KEY,
  ZOOM_TAB_KEY,
  PODCAST_SEARCH_TAB_KEY,
  useYoutubeUrlPlugin,
} from '@sparemin/media-import';
import React, { useCallback } from 'react';
import {
  DROPZONE_ACCEPT,
  formatAcceptedFileTypes,
} from 'components/MediaUploader';
import Null from 'components/Null';
import useIsMobileViewport from 'hooks/useIsMobileViewport';
import { useEventTracking } from 'state/eventTracking';
import { useModalParams } from 'state/modal';
import { megabytesToBytes } from 'utils/math';
import { hoursToMillis } from 'utils/time';
import useProjectFileUploadLimits from '../useProjectFileUploadLimits';
import * as S from './styles';
import useHandleImportedMedia from './useHandleImportedMedia';

const acceptList = formatAcceptedFileTypes(DROPZONE_ACCEPT);

export interface MediaUploadModalContentsProps {}

const MediaUploadModalContents: React.FC<
  MediaUploadModalContentsProps
> = () => {
  const { defaultSelectedTab = 'podcastSearch' } =
    useModalParams('ProjectMediaUpload') ?? {};
  const { isLoading, onMediaImported, onMediaAccepted, onMediaRejected } =
    useHandleImportedMedia();
  const { trackClickPodcastSearch } = useEventTracking();
  const { maxMediaDurationHr, maxSizeMB } = useProjectFileUploadLimits();
  const isMobile = useIsMobileViewport();

  const handleSubmitPodcastSearch = useCallback(
    (query: string) => {
      if (query !== '') {
        trackClickPodcastSearch();
      }
    },
    [trackClickPodcastSearch],
  );

  const layoutProps = isMobile
    ? { orientation: 'horizontal', position: 'top' }
    : { orientation: 'vertical', position: 'left' };

  return (
    <S.Modal footer={<Null />} fullScreen gutter={isMobile ? 0 : 24}>
      <MediaImporter
        isDisabled={isLoading}
        defaultSelectedTabKey={
          {
            podcastSearch: PODCAST_SEARCH_TAB_KEY,
            googleDrive: GOOGLE_DRIVE_TAB_KEY,
            zoom: ZOOM_TAB_KEY,
            youtube: YOUTUBE_URL_TAB_KEY,
          }[defaultSelectedTab]
        }
        plugins={[
          usePodcastSearchPlugin({
            onSubmit: handleSubmitPodcastSearch,
          }),
          useGoogleDrivePlugin(),
          useZoomPlugin(),
          useYoutubeUrlPlugin(),
          useEpisodeSearchPlugin(),
        ]}
        accept={acceptList}
        maxDurationMillis={hoursToMillis(maxMediaDurationHr)}
        maxSizeBytes={megabytesToBytes(maxSizeMB)}
        {...{ onMediaImported, onMediaAccepted, onMediaRejected }}
        {...layoutProps}
      />
    </S.Modal>
  );
};

export default MediaUploadModalContents;
