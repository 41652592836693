import { find, isEmpty, omitBy } from 'lodash-es';
import {
  SuggestedClipTooltipActions,
  SuggestedClipTooltipStore,
} from './types';

export const tooltipsSelector = (store: SuggestedClipTooltipStore) =>
  store.tooltips;

export const actionsSelector = (store: SuggestedClipTooltipStore) =>
  store.actions;

export const openDelayTimerIdsSelector = (store: SuggestedClipTooltipStore) =>
  store.openDelayIds;

export const closeDelayTimerIdsSelector = (store: SuggestedClipTooltipStore) =>
  store.closeDelayIds;

export const nextTooltipIdSelector = (store: SuggestedClipTooltipStore) =>
  store.nextTooltipId;

export const suggestionTooltipSelector = (
  store: SuggestedClipTooltipStore,
  suggestionId: number,
) => {
  const tooltips = tooltipsSelector(store);
  return find(tooltips, (val) => val.suggestionId === suggestionId);
};

export const isAnyTooltipOpenSelector = (store: SuggestedClipTooltipStore) => {
  const tooltips = tooltipsSelector(store);
  return !isEmpty(tooltips);
};

export const isTooltipOpenSelector = (
  store: SuggestedClipTooltipStore,
  tooltipId: number,
) => {
  const tooltips = tooltipsSelector(store);
  return !!tooltips[tooltipId];
};

export const openDelayTimerIdSelector = (
  store: SuggestedClipTooltipStore,
  tooltipId: number,
) => {
  const ids = openDelayTimerIdsSelector(store);
  return ids[tooltipId];
};

export const closeDelayTimerIdSelector = (
  store: SuggestedClipTooltipStore,
  tooltipId: number,
) => {
  const ids = closeDelayTimerIdsSelector(store);
  return ids[tooltipId];
};

export const publicActionsSelector = (store: SuggestedClipTooltipStore) => {
  const actions = actionsSelector(store);
  return omitBy(actions, (_, key) =>
    key.startsWith('_'),
  ) as SuggestedClipTooltipActions;
};
