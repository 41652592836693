import { FileRejection } from '@sparemin/blockhead';
import { useCallback } from 'react';
import notifyError from 'components/notification';
import { secToMin } from 'utils/time';
import {
  INTRO_OUTRO_MAX_SIZE_MB,
  INTRO_OUTRO_MAX_LENGTH_SECS,
} from '../constants';
import { useProjectMediaType } from '../state';

export interface UseFileDroppedConfig {}

export interface UseFileDropped {
  onFileRejected: (rejections: FileRejection[]) => void;
}

export default function useFileDropped(): UseFileDropped {
  const { data: projectType } = useProjectMediaType();
  const supportedFileTypes =
    projectType === 'audio' ? ' MP3, AAC, WAV' : 'WEBM, QuickTime';

  const maxLength = secToMin(INTRO_OUTRO_MAX_LENGTH_SECS);
  const lengthType = maxLength === 1 ? 'minute' : 'minutes';

  const onFileRejected = useCallback(
    (rejections: FileRejection[]): void => {
      const { file, reason } = rejections[0];
      const { size, duration } = file;

      if (
        (reason === 'TOO_LARGE' || reason === 'TOO_LONG') &&
        size &&
        duration
      ) {
        notifyError({
          heading: 'Sorry, that clip exceeds our limits',
          subHeading: `Make sure it's not greater than ${INTRO_OUTRO_MAX_SIZE_MB}MB or ${maxLength} ${lengthType}`,
        });
      }
      if (reason === 'WRONG_TYPE') {
        notifyError({
          heading:
            'Unfortunately, the uploaded file is not a supported file type',
          subHeading: `Please make sure the file format is one of the following ${projectType} files: ${supportedFileTypes}. If you encounter further issues, please contact our support team for further assistance.`,
        });
      }
    },
    [maxLength, lengthType, projectType, supportedFileTypes],
  );

  return {
    onFileRejected,
  };
}
