import {
  Query as ReactQuery,
  QueryCache,
  QueryClient,
  QueryClientProvider as ReactQueryClientProvider,
} from 'react-query';
import notifyError from 'components/notification';
import { ErrorMeta } from './types';

export interface QueryClientProviderProps {
  children?: React.ReactNode;
}

type Query = Omit<ReactQuery, 'meta'> & {
  meta?: ErrorMeta;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      notifyOnChangeProps: 'tracked',
      refetchOnWindowFocus: false,
    },
  },
  queryCache: new QueryCache({
    onError: (err, query: Query) => {
      const errorMessage =
        typeof query.meta?.errorMessage === 'function'
          ? query.meta.errorMessage(err)
          : query.meta?.errorMessage;

      if (
        errorMessage === false ||
        (errorMessage !== undefined && typeof errorMessage !== 'string')
      ) {
        return;
      }
      notifyError({ heading: errorMessage });
    },
  }),
});

const QueryClientProvider: React.FC<QueryClientProviderProps> = ({
  children,
}) => (
  <ReactQueryClientProvider client={queryClient}>
    {children}
  </ReactQueryClientProvider>
);

export default QueryClientProvider;
