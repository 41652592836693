import { Banner, Button, ShareGradientIcon, Spacer } from '@sparemin/blockhead';
import { useCallback } from 'react';
import {
  PRO_TIER_MAX_MEDIA_DURATION_HOURS,
  PRO_TIER_MAX_MEDIA_SIZE_GB,
} from 'config';
import { useEventTracking } from 'state/eventTracking';
import { useModalsActions } from 'state/modal';
import { usePlanUploadMaxValues } from 'state/pricing';

export interface IncreaseUploadLimitsBannerProps {}

const IncreaseUploadLimitsBanner: React.FC<
  IncreaseUploadLimitsBannerProps
> = () => {
  const { trackClickUploadLimitBanner } = useEventTracking();
  const { pushModal } = useModalsActions();
  const { data: uploadLimits } = usePlanUploadMaxValues();

  const maxMediaDurationHr = uploadLimits?.maxMediaDurationHr;
  const maxSizeMB = uploadLimits?.maxUploadSizeMB;

  const handleUpgradeClick = useCallback((): void => {
    trackClickUploadLimitBanner();

    pushModal({
      name: 'Upsell',
      params: {
        context: 'uploadLimits',
      },
    });
  }, [pushModal, trackClickUploadLimitBanner]);

  return (
    <Banner icon={<ShareGradientIcon width={32} height={32} />}>
      <Spacer orientation="vertical" align="flex-start" space={0.5}>
        <Banner.Title>Increase your upload limits</Banner.Title>

        <Banner.Content>
          Your current plan only permits files up to {maxSizeMB}MB and{' '}
          {maxMediaDurationHr} hours long. Upgrade today to upload files up to{' '}
          {PRO_TIER_MAX_MEDIA_SIZE_GB}GB and {PRO_TIER_MAX_MEDIA_DURATION_HOURS}{' '}
          hours long.
        </Banner.Content>
      </Spacer>

      <Banner.Actions>
        <Button variant="special" onPress={handleUpgradeClick}>
          Upgrade now
        </Button>
      </Banner.Actions>
    </Banner>
  );
};
export default IncreaseUploadLimitsBanner;
