import { produce } from 'immer';
import { SearchAction, SearchState } from './types';

export const defaultState: SearchState = {
  query: '',
  foundWords: [],
  activeWordIndex: 0,
  isSearching: false,
  showFillerWords: false,
};

export default function reducer(state: SearchState, action: SearchAction) {
  switch (action.type) {
    case 'SEARCH_BEGIN': {
      const { payload: query } = action;

      return produce(state, (d) => {
        d.query = query;
        d.isSearching = !!query;
      });
    }

    case 'SEARCH_FINISH': {
      const { payload: foundWords } = action;

      return produce(state, (d) => {
        d.isSearching = false;
        d.foundWords = foundWords;
      });
    }

    case 'SEARCH_UPDATE': {
      const {
        payload: { query, foundWords },
      } = action;

      return produce(state, (d) => {
        d.query = query;
        d.foundWords = foundWords;
        d.activeWordIndex = 0;
      });
    }

    case 'WORD_ACTIVATED': {
      const { payload: nextActiveWordIndex } = action;

      return produce(state, (d) => {
        d.activeWordIndex = nextActiveWordIndex;
      });
    }

    case 'TOGGLE_SHOW_FILLER_WORDS': {
      const { payload: showFillerWords } = action;

      return produce(state, (d) => {
        d.showFillerWords = showFillerWords;

        if (!showFillerWords) {
          d.foundWords = [];
          d.query = '';
          d.isSearching = false;
          d.activeWordIndex = 0;
        }
      });
    }

    default:
      return state;
  }
}
