import React from 'react';
import { QueryStringAuthenticatorProps } from './types';
import useQueryStringAuth from './useQueryStringAuth';

/**
 * This component is a wrapper around useQueryStringAuth() to help rendering
 * the UI in different scenarios.
 *
 * A few examples:
 *
 *  - Optional query string auth that will pass the user along to the underlying
 *    component whether they are authenticated or not
 *
 * ```
 *    <QueryStringAuth><MyComponent /></QueryStringAuth>
 * ```
 *
 *  - Required query string auth that will redirect to a different route if the
 *    access token is not in the search string
 *
 * ```
 *    <QueryStringAuthenticator fallback={<Navigate to={home} replace />}>
 *      <MyComponent />
 *    </QueryStringAuthenticator>
 * ```
 *
 *  - Optional query string auth as a layout route
 *
 * ```
 *    <Route element={
 *      <QueryStringAuthenticator>
 *        <Outlet />
 *      </QueryStringAuthenticator>}
 *    >
 *      <Route path="/my-path" element={<MyComponent />} />
 *    </Route>
 * ```
 *
 * - Optional query string that defers routing to the AuthenticatedRoute
 *   component
 *
 * ```
 *    <Route element={
 *      <QueryStringAuthenticator>
 *        <Outlet />
 *      </QueryStringAuthenticator>}
 *    >
 *      <Route element={<AuthenticatedRoute />}>
 *        <Route path="/my-path" element={<MyComponent />} />
 *      </Route>
 *    </Route>
 * ```
 */
const QueryStringAuthenticator: React.FC<QueryStringAuthenticatorProps> = ({
  children,
  fallback,
}) => {
  const { error, isResolved, status } = useQueryStringAuth();

  if (typeof children === 'function') {
    return children({ authStatus: status, error });
  }

  if (status === 'failure' && fallback !== undefined) {
    return <>{fallback}</>;
  }

  if (isResolved) {
    return <>{children}</>;
  }

  return null;
};

export default QueryStringAuthenticator;
