import { decodeSpareminToken } from '@sparemin/auth';
import { useStaticCallback } from '@sparemin/blockhead';
import { useCallback, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import useAuthSuccess from 'hooks/useAuthSuccess';

import { ACCESS_TOKEN_PARAM_NAME } from './constants';
import { QueryStringAuthErrorReason } from './types';
import useQueryStringAuthState from './useQueryStringAuthState';
import { isValidationError, validateAccessToken } from './utils';

export interface UseQueryStringAuthConfig {
  onError?: (reason: QueryStringAuthErrorReason) => void;
}

/**
 * Reads an access token from the query string, authenticating the user if it's
 * valid.
 */
export default function useQueryStringAuth(config?: UseQueryStringAuthConfig) {
  const { onError } = config ?? {};

  const { onAuthSuccess } = useAuthSuccess();

  const [state, dispatch] = useQueryStringAuthState();

  const [searchParams, setSearchParams] = useSearchParams();
  const evaluatedRef = useRef(false);

  const staticOnSuccess = useStaticCallback(onAuthSuccess);
  const staticOnError = useStaticCallback(onError);

  const handleError = useCallback(
    (err: Error) => {
      const reason = isValidationError(err) ? err.message : 'unknown';
      staticOnError?.(reason);
      dispatch({ type: 'AUTH_FAILURE', payload: err });
    },
    [dispatch, staticOnError],
  );

  useEffect(() => {
    const authenticate = async () => {
      dispatch({ type: 'AUTH_BEGIN' });

      const accessToken = searchParams.get(ACCESS_TOKEN_PARAM_NAME);

      // NB: there is a minor inefficiency here where the access token will be decoded
      // twice - once inside of `validateAccessToken` when `isExpired` is called, then
      // again below to get the `sub` property.  This should be a relatively cheap
      // operation.
      try {
        if (!validateAccessToken(accessToken)) {
          // failsafe - the validation function should never return false
          handleError(new Error('token-invalid'));
          return;
        }

        searchParams.delete(ACCESS_TOKEN_PARAM_NAME);
        setSearchParams(searchParams);

        // will throw if the token is invalid
        const { sub } = decodeSpareminToken(accessToken);

        await staticOnSuccess?.({
          eventType: 'login',
          spareminToken: accessToken,
          userId: sub,
        });

        dispatch({ type: 'AUTH_SUCCESS' });
      } catch (err) {
        const error =
          err instanceof Error ? err : new Error('Error authenticating user');
        handleError(error);
      }
    };

    if (evaluatedRef.current) {
      return;
    }

    evaluatedRef.current = true;
    authenticate();
  }, [dispatch, handleError, searchParams, setSearchParams, staticOnSuccess]);

  return {
    error: state.error,
    isAuthenticating: state.status === 'loading',
    isError: state.status === 'failure',
    isResolved: state.status === 'failure' || state.status === 'success',
    status: state.status,
  };
}
