import { CSSReset as BlockheadCSSReset } from '@sparemin/blockhead';
import React from 'react';

import * as S from './styles';

export interface CSSResetProps {}

const CSSReset: React.FC<CSSResetProps> = () => (
  <>
    <BlockheadCSSReset />
    <S.CSSReset />
  </>
);

export default CSSReset;
