import { PodcastEpisodeSearchResult } from '@sparemin/api-sdk';
import {
  EPISODE_SEARCH_PLUGIN_ID,
  PODCAST_SEARCH_PLUGIN_ID,
  MediaImportedEvent,
} from '@sparemin/media-import';
import { useCallback } from 'react';
import { useEventTracking } from 'state/eventTracking';
import { useModalsActions } from 'state/modal';
import useCreateProjectFromPodcast from '../state/useCreateProjectFromPodcast';
import useOnCreateProject from '../useOnCreateProject';

export interface UseHandleMediaImportedResult {
  isLoading: boolean;
  onMediaImported: (args: MediaImportedEvent) => void;
}

export default function useHandleMediaImported(): UseHandleMediaImportedResult {
  const { trackClickEpisodeName, trackClickPodcastName } = useEventTracking();
  const { mutate: createProjectFromPodcast, isLoading } =
    useCreateProjectFromPodcast();
  const { onSuccess, onError } = useOnCreateProject({ replace: true });
  const { pushModal, hideModal } = useModalsActions();

  const handlePodcastSearch = useCallback((): void => {
    trackClickPodcastName();
  }, [trackClickPodcastName]);

  const handlePodcastProjectCreation = useCallback(
    (episode: PodcastEpisodeSearchResult): void => {
      trackClickEpisodeName();

      // This modal (MediaUploadModal)
      hideModal();

      pushModal({
        name: 'ProjectLanguageSelector',
        params: {
          onLanguageSelected(language) {
            // The language modal (ProjectLanguageSelectorModal) - it doesn't close itself 🤷🏻‍♂️
            hideModal();

            createProjectFromPodcast(
              {
                episodeName: episode.title,
                language,
                podcastId: episode.podcast.podcastId,
                remoteEpisodeId: episode.episodeId,
              },
              {
                onError,
                onSuccess(data) {
                  onSuccess(data);
                },
              },
            );
          },
        },
      });
    },
    [
      createProjectFromPodcast,
      hideModal,
      onError,
      onSuccess,
      pushModal,
      trackClickEpisodeName,
    ],
  );

  const handleMediaImported = useCallback(
    (args: MediaImportedEvent): void => {
      const { pluginId, payload } = args;

      switch (pluginId) {
        case PODCAST_SEARCH_PLUGIN_ID: {
          return handlePodcastSearch();
        }

        case EPISODE_SEARCH_PLUGIN_ID: {
          return handlePodcastProjectCreation(payload);
        }

        default: {
          return undefined;
        }
      }
    },
    [handlePodcastProjectCreation, handlePodcastSearch],
  );

  return {
    isLoading,
    onMediaImported: handleMediaImported,
  };
}
