import { useMutation, useQueryClient } from 'react-query';
import {
  directUpload,
  EdgeAssetType,
  request,
  updateEdgeAsset,
  UpdateProjectEdgeAssetResult,
} from 'api';
import { useAuth } from 'state/auth';
import { MutationOptions } from 'types';
import { getMediaTypeFromFileType } from 'utils/fileUpload';
import { eddyProjectsQueryKeys } from './queryKeys';
import { useProjectCuid } from './useEditorProject';

export interface AddEdgeAssetFromMediaArgs {
  file: File;
  assetType: EdgeAssetType;
  projectCuid?: string;
}

async function addEdgeAssetFromMedia({
  file,
  assetType,
  projectCuid,
}: AddEdgeAssetFromMediaArgs): Promise<UpdateProjectEdgeAssetResult> {
  const result = await directUpload({
    contentType: file.type,
    filename: file.name,
    fileType: getMediaTypeFromFileType(file.type) || 'audio',
  });

  const url = result.upload?.url;

  if (url) {
    const uploaded = await request.postForm(
      url,
      { ...result.upload?.fields, file },
      { disableAuthHeaderInterceptor: true },
    );

    if (uploaded.status === 204 && projectCuid) {
      return updateEdgeAsset({
        edgeAssetType: assetType,
        projectCuid,
        directUploadBucket: result.bucket,
        directUploadKey: result.key,
        mediaType: getMediaTypeFromFileType(file.type) || 'audio',
      });
    }
  }
  throw Error(`Error uploaded ${assetType}`);
}

export default function useAddEdgeAssetFromMedia(
  opts?: MutationOptions<
    UpdateProjectEdgeAssetResult,
    AddEdgeAssetFromMediaArgs
  >,
) {
  const { data: projectCuid } = useProjectCuid();
  const queryClient = useQueryClient();
  const { userId } = useAuth();

  return useMutation<
    UpdateProjectEdgeAssetResult,
    unknown,
    Omit<AddEdgeAssetFromMediaArgs, 'projectCuid'>,
    unknown
  >(
    async (args) => {
      if (!projectCuid) {
        throw new Error('Error submitting asset');
      }

      return addEdgeAssetFromMedia({ ...args, projectCuid });
    },
    {
      ...opts,
      onSettled: (data, error, variables, context) => {
        queryClient.invalidateQueries(
          eddyProjectsQueryKeys.myProject(userId, projectCuid),
        );
        if (opts?.onSettled) {
          opts.onSettled(data, error, variables, context);
        }
      },
    },
  );
}
