import { useMediaQuery } from '@sparemin/blockhead';
import theme from 'theme';

export default function useIsMobileViewport(): boolean {
  const {
    breakpoints: { down },
  } = theme;

  return useMediaQuery(down('md'));
}
