import { ProjectListResult } from 'api';
import notifyError from 'components/notification';
import { MAX_FILE_DURATION_HR, MAX_FILE_SIZE_MB } from './constants';
import { getErrorByReason } from './ProjectMediaUploader/utils';
import { NotifyFileRejectionErrorProps } from './types';

export function findPageIndexByProjectCuid(
  projectCuid: string,
  pages: ProjectListResult[],
): number {
  const pageWithProject = pages.find((page) =>
    page.projects.find((p) => p.projectCuid === projectCuid),
  );

  if (pageWithProject) {
    return pageWithProject.page.number - 1;
  }

  return 0;
}

export function notifyFileRejectionError({
  reason,
  maxSize = MAX_FILE_SIZE_MB,
  maxDuration = MAX_FILE_DURATION_HR,
}: NotifyFileRejectionErrorProps): void {
  notifyError(
    maxSize && maxDuration
      ? getErrorByReason(maxSize, maxDuration)[reason]
      : undefined,
  );
}

export function isEnglishDialect(language?: string) {
  if (!language) return false;
  return /^en-/.test(language);
}
