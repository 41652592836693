import styled from 'styled-components';

const FadingFooter = styled.div`
  background-image: ${({ theme }) => `
    linear-gradient(
      to top,
      ${theme.backgroundColorMain},
      rgba(250, 250, 251, 0)
    );
  `};
  bottom: 0;
  height: ${({ theme }) => theme.footerFadeHeight}px;
  left: 0;
  position: fixed;
  width: 100vw;
  pointer-events: none;
`;

export default FadingFooter;
