import React from 'react';
import * as S from './styles';
import { UploadButtonProps } from './types';

const UploadButton: React.FC<UploadButtonProps> = ({
  iconBackgroundColor,
  label,
  logo,
  ...props
}) => (
  <S.RootUploadButton
    {...props}
    logo={
      <S.IconWrapper $iconBackgroundColor={iconBackgroundColor}>
        {logo}
      </S.IconWrapper>
    }
  >
    <S.Label>{label}</S.Label>
  </S.RootUploadButton>
);

export default UploadButton;
