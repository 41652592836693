import { LoaderViewId } from './types';

export function getViewId(
  isFreeTier: boolean | undefined,
  showProgress: boolean,
  aiFeaturesApplied: boolean,
  isLoadingComplete: boolean,
): LoaderViewId {
  if (
    isFreeTier === undefined ||
    !showProgress ||
    (isLoadingComplete && !aiFeaturesApplied)
  ) {
    return 'spinner';
  }

  return isFreeTier ? 'loaderFree' : 'loaderPaid';
}
