import { Spacer } from '@sparemin/blockhead';
import styled from 'styled-components';
import { selectableTranscriptText } from '../styles';
import { SPEAKER_SEGMENT_TOP_SPACING_MULTIPLIER } from './constants';

export const Root = styled(Spacer)`
  &:not(:first-child) {
    padding-top: ${({ theme }) =>
      theme.spacing.base * SPEAKER_SEGMENT_TOP_SPACING_MULTIPLIER}px;
  }
`;

export const Paragraph = styled.p`
  margin-bottom: 0;
  width: 100%;
  position: relative;
  z-index: 0;

  &,
  & * {
    ${selectableTranscriptText}
  }
`;

export const SkeletonContainer = styled.div`
  width: 100%;
`;
