import { useCallback, useEffect, useRef, useState } from 'react';
import notifyError from 'components/notification';
import { WHITESPACE_WORD } from 'pages/TranscriptEditorPage/constants';
import useSearchInTranscript from 'pages/TranscriptEditorPage/hooks/useSearchInTranscript';
import { useEditTranscript } from 'pages/TranscriptEditorPage/state/commands';
import { getActiveRange, selectRange } from 'utils/selection';
import { useWordsById } from '../../../state';
import {
  ContextMenuStore,
  useContextMenuActions,
  useContextMenuSubscription,
  useWordsContextMenuConfig,
} from '../../../state/contextMenu';
import { useEditorUiActions } from '../../../state/editorUi';
import EditContentContainer from '../../EditContentContainer';
import * as S from './styles';
import { createWordEdits } from './utils';

export interface TextEditMenuContentsProps {
  onSubmit?: () => void;
}

const TextEditMenuContents: React.FC<TextEditMenuContentsProps> = ({
  onSubmit,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const contextMenuStateRef = useRef<ContextMenuStore>();
  const config = useWordsContextMenuConfig();
  const { onAction } = useContextMenuActions();
  const { data: wordsById } = useWordsById();
  const { updateWords } = useEditTranscript();
  const { selectWords } = useEditorUiActions();
  const { searchInTranscript } = useSearchInTranscript();

  const wordsSelection =
    config?.words
      .map((id) => {
        const word = wordsById?.[id];

        if (!word) {
          return '';
        }

        return word.isSpace ? WHITESPACE_WORD : word.text;
      })
      .join(' ') ?? '';

  const [value, setValue] = useState(wordsSelection);
  const [updateAll, setUpdateAll] = useState(false);

  useContextMenuSubscription((state) => {
    contextMenuStateRef.current = state;
  });

  useEffect(() => {
    const range = getActiveRange();
    inputRef.current?.select();
    selectWords(config?.words ?? []);

    return () => {
      selectWords([]);

      if (range && contextMenuStateRef.current?.isOpen) {
        selectRange(range);
      }
    };
  }, [config?.words, selectWords]);

  const handleSubmit = useCallback(() => {
    if (!config?.words || !wordsById) {
      return;
    }

    const edits = updateAll
      ? searchInTranscript(wordsSelection).flatMap((wordIds) =>
          createWordEdits(wordIds, value),
        )
      : createWordEdits(config.words, value);

    updateWords(edits, () => {
      notifyError({
        heading: 'Error updating transcript',
        errorCode: 'ER006',
      });
    });

    onAction({ type: 'edit-word', config });
    onSubmit?.();
  }, [
    config,
    wordsSelection,
    onAction,
    onSubmit,
    searchInTranscript,
    updateAll,
    updateWords,
    value,
    wordsById,
  ]);

  return (
    <S.Root
      onMouseUp={(e) => {
        e.stopPropagation();
      }}
      onSubmit={handleSubmit}
    >
      <EditContentContainer
        title="Correct text"
        showUpdateAllButton
        updateAll={updateAll}
        onUpdateAll={setUpdateAll}
      >
        <S.Input
          label="update text"
          value={value}
          onChange={setValue}
          ref={inputRef}
        />
      </EditContentContainer>
    </S.Root>
  );
};

export default TextEditMenuContents;
