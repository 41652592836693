import { useCallback, useRef, useState } from 'react';
import { intervalComparator, search } from 'utils/arrays';
import { inRange } from 'utils/math';
import { EditedInterval } from './types';

export interface UseOriginalToEditedTimeConfig {
  intervals: EditedInterval[];
}

export default function useOriginalToEditedTime({
  intervals,
}: UseOriginalToEditedTimeConfig) {
  const [prevIntervals, setPrevIntervals] = useState(intervals);

  // save the last interval returned by `originalToEditedTime` as an optimization
  // so that we don't search for intervals more often than necessary
  const lastIntervalRef = useRef<EditedInterval>();

  // when intervals change, clear out the last interval ref since it might no longer
  // reference a valid interval in the intervals array
  if (prevIntervals !== intervals) {
    setPrevIntervals(intervals);
    lastIntervalRef.current = undefined;
  }

  return useCallback(
    (editedMillis: number) => {
      const getInterval = () => {
        if (
          lastIntervalRef.current &&
          inRange(
            editedMillis,
            lastIntervalRef.current.startMillis,
            lastIntervalRef.current.endMillis,
          )
        ) {
          return lastIntervalRef.current;
        }

        const index = search(intervals, editedMillis, intervalComparator);
        const interval = intervals[index];
        lastIntervalRef.current = interval;

        return interval;
      };

      const interval = getInterval();
      return {
        adjustedMillis: editedMillis - interval.adjustmentMillis,
        interval,
      };
    },
    [intervals],
  );
}
