import styled from 'styled-components';
import { fade, TransitionProps } from 'styles';
import ProjectLoaderFreeTier from './ProjectLoaderFreeTier';
import { TRANSITION_DURATION_MILLIS } from './transition';

export const TransitionedElement = styled(
  ProjectLoaderFreeTier,
)<TransitionProps>`
  ${fade({
    durationMillis: TRANSITION_DURATION_MILLIS,
  })}
`;
