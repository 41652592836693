import React from 'react';
import * as S from './styles';
import UploadButton from './UploadButton';

interface PodcastSearchUploadButtonProps {
  onPress: () => void;
}

const PodcastSearchUploadButton: React.FC<PodcastSearchUploadButtonProps> = ({
  onPress,
}) => (
  <UploadButton
    aria-label="Search for a podcast episode"
    iconBackgroundColor="actionSecondary"
    label="Podcasts"
    logo={<S.PodcastSearchIcon />}
    onPress={onPress}
  />
);

export default PodcastSearchUploadButton;
