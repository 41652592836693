export const DELETE_WORDS_REDO_MESSAGE = 'Restore text deletion';

export const REMOVE_SEARCH_RESULTS_MESSAGE = 'Removed search results';
export const CORRECT_SEARCH_RESULTS_MESSAGE = 'Corrected search results';

export const UPDATE_WORD_TEXT_MESSAGE_UNDO = 'Corrected text';
export const UPDATE_WORD_TEXT_MESSAGE_REDO = 'Reverted text correction';

export const UPDATE_SPEAKER_LABEL_MESSAGE_UNDO = 'Updated speaker label';
export const UPDATE_SPEAKER_LABEL_MESSAGE_REDO = 'Reverted speaker label';

export const DELETION_ADJUSTMENT_MESSAGE_UNDO = 'Modified deletion';
export const DELETION_ADJUSTMENT_MESSAGE_REDO = 'Revert deletion modification';

export const SPEAKER_LABEL_MESSAGE_UNDO = 'Updated speaker label';
export const SPEAKER_LABEL_MESSAGE_REDO = 'Reverted speaker label';

export const UPDATE_CHAPTER_MESSAGE_UNDO = 'Chapter updated';
export const UPDATE_CHAPTER_MESSAGE_REDO = 'Reverted chapter update';

export const DELETION_CHAPTER_MESSAGE_UNDO = 'Chapter deleted';
export const DELETION_CHAPTER_MESSAGE_REDO = 'Reverted chapter deletion';
