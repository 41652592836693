import { useEffect, useState } from 'react';

import {
  useAIOptionsActions,
  useGetAiNameAppliedProjectCuid,
} from 'state/aiOptions';

import { useProjectCuid } from '../state';

const useProjectTitleAutofocus = () => {
  const { clearAINameAppliedProjectCuid } = useAIOptionsActions();
  const aiNameAppliedProjectCuid = useGetAiNameAppliedProjectCuid();
  const { data: projectCuid } = useProjectCuid();

  const [shouldAutoFocus, setShouldAutoFocus] = useState(false);

  useEffect(() => {
    // when the current project cuid matches the store ai name applied project cuid
    // the autofocus is enabled and the the ai store is value is cleared.
    // projectCuid is checked in case of a corner case where both ai name one and projectCuid
    // are undefined or empty.
    if (projectCuid && aiNameAppliedProjectCuid === projectCuid) {
      setShouldAutoFocus(true);
      clearAINameAppliedProjectCuid();
    }
  }, [aiNameAppliedProjectCuid, clearAINameAppliedProjectCuid, projectCuid]);

  return {
    shouldAutoFocus,
  };
};

export default useProjectTitleAutofocus;
