import React from 'react';
import ConnectedModal from 'components/ConnectedModal';
import { useModalParams } from 'state/modal';
import { UPSELL_MODAL_CONTEXTS } from './UpsellModalContents';

const MODAL_NAME = 'Upsell';

export interface UpsellModalProps {}

const UpsellModal: React.FC<UpsellModalProps> = () => {
  const params = useModalParams(MODAL_NAME);

  const { context = 'default', onSuccess } = params || {};

  const ContextContents = UPSELL_MODAL_CONTEXTS[context];

  return (
    <ConnectedModal name={MODAL_NAME}>
      <ContextContents onSuccess={onSuccess} />
    </ConnectedModal>
  );
};

export default UpsellModal;
