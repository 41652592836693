import { useCallback, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { notifyTranscriptEdit } from 'components/notification/showNotification';
import { useAuth } from 'state/auth';
import { eddyTranscriptQueryKeys } from '../../queryKeys';
import useCreateTranscriptChapter, {
  UseCreateTranscriptChapterVariables,
} from '../../useCreateTranscriptChapter';
import useCreateTranscriptSegment, {
  UseCreateTranscriptSegmentVariables,
} from '../../useCreateTranscriptSegment';
import useDeleteTranscriptChapter from '../../useDeleteTranscriptChapter';
import useDeleteTranscriptSegment from '../../useDeleteTranscriptSegment';
import { useTranscriptId } from '../../useEditorTranscript';
import useEditTranscriptSegmentSpeaker from '../../useEditTranscriptSegmentSpeaker';
import useUpdateTranscriptChapter from '../../useUpdateTranscriptChapter';
import useUpdateTranscriptSpeaker from '../../useUpdateTranscriptSpeaker';
import useUpdateTranscriptText from '../../useUpdateTranscriptText';
import { UpdateChapterCommand } from '../chapterCommands';
import CreateChapterCommand from '../chapterCommands/CreateChapterCommand';
import DeleteChapterCommand from '../chapterCommands/DeleteChapterCommand';
import {
  CORRECT_SEARCH_RESULTS_MESSAGE,
  DELETE_WORDS_REDO_MESSAGE,
  DELETION_ADJUSTMENT_MESSAGE_REDO,
  DELETION_ADJUSTMENT_MESSAGE_UNDO,
  DELETION_CHAPTER_MESSAGE_REDO,
  DELETION_CHAPTER_MESSAGE_UNDO,
  REMOVE_SEARCH_RESULTS_MESSAGE,
  SPEAKER_LABEL_MESSAGE_REDO,
  SPEAKER_LABEL_MESSAGE_UNDO,
  UPDATE_CHAPTER_MESSAGE_REDO,
  UPDATE_CHAPTER_MESSAGE_UNDO,
  UPDATE_WORD_TEXT_MESSAGE_REDO,
  UPDATE_WORD_TEXT_MESSAGE_UNDO,
} from '../constants';
import CreateSegmentCommand from '../segmentCommands/CreateSegmentCommand';
import DeleteSegmentCommand from '../segmentCommands/DeleteSegmentCommand';
import TranscriptOperationExecutor from '../TranscriptOperationExecutor';
import TranscriptOriginator from '../TranscriptOriginator';
import { CommitErrorHandler, WordEdit } from '../types';
import UpdateSpeakerCommand from '../UpdateSpeakerCommand';
import { UndeleteWordsCommand } from '../wordCommands';
import { DeleteWordsCommand } from '../wordCommands';
import UpdateWordsCommand from '../wordCommands/UpdateWordsCommand';
import {
  CreateChapterArgs,
  CreateChapterConfig,
  CreateSegmentConfig,
  EditTranscriptContextType,
  UpdateChapterArgs,
} from './types';

export default function useEditTranscriptContextValue(): EditTranscriptContextType {
  const queryClient = useQueryClient();
  const { userId } = useAuth();
  const { data: transcriptId } = useTranscriptId();

  const { mutateAsync: updateTranscriptText } = useUpdateTranscriptText();
  const { mutateAsync: updateTranscriptSpeaker } = useUpdateTranscriptSpeaker();
  const { mutateAsync: updateTranscriptChapter } = useUpdateTranscriptChapter();
  const { mutateAsync: createTranscriptSegment } = useCreateTranscriptSegment();
  const { mutateAsync: deleteTranscriptSegment } = useDeleteTranscriptSegment();
  const { mutateAsync: createTranscriptChapter } = useCreateTranscriptChapter();
  const { mutateAsync: deleteTranscriptChapter } = useDeleteTranscriptChapter();
  const { mutateAsync: editTranscriptSegmentSpeaker } =
    useEditTranscriptSegmentSpeaker();

  // useCreateTranscriptSegment is kept generic enough so that if we need the full
  // API response somewhere else in the app, we can get it easily.  For the create-
  // segment committer, we just want the new segment id.
  const createSegmentCommitter = useCallback(
    async (args: UseCreateTranscriptSegmentVariables) => {
      const result = await createTranscriptSegment(args);
      return result.newSegment.id;
    },
    [createTranscriptSegment],
  );

  const createChapterCommitter = useCallback(
    async (args: UseCreateTranscriptChapterVariables) => {
      const result = await createTranscriptChapter(args);
      return result.newChapter.id;
    },
    [createTranscriptChapter],
  );

  const originator = useMemo(
    () =>
      new TranscriptOriginator(
        queryClient,
        eddyTranscriptQueryKeys.transcript(userId, transcriptId),
      ),
    [queryClient, transcriptId, userId],
  );

  const [executor] = useState(new TranscriptOperationExecutor());

  const deleteWords = useCallback(
    (deletionWordIds: number[]) => {
      const command = new DeleteWordsCommand(
        deletionWordIds,
        originator,
        updateTranscriptText,
        {
          onUnexecuteSuccess() {
            notifyTranscriptEdit({
              actionLabel: 'redo',
              heading: DELETE_WORDS_REDO_MESSAGE,
              onAction() {
                executor.redo();
              },
            });
          },
        },
      );
      return executor.executeCommand(command);
    },
    [executor, updateTranscriptText, originator],
  );

  const undeleteWords = useCallback(
    (undeletionWordIds: number[]) => {
      const command = new UndeleteWordsCommand(
        undeletionWordIds,
        originator,
        updateTranscriptText,
      );
      return executor.executeCommand(command);
    },
    [executor, updateTranscriptText, originator],
  );

  const deleteSearchResults = useCallback(
    (searchResultsIds: number[]) => {
      const command = new DeleteWordsCommand(
        searchResultsIds,
        originator,
        updateTranscriptText,
        {
          onExecuteSuccess() {
            notifyTranscriptEdit({
              actionLabel: 'undo',
              heading: REMOVE_SEARCH_RESULTS_MESSAGE,
              onAction() {
                executor.undo();
              },
            });
          },
          onUnexecuteSuccess() {
            notifyTranscriptEdit({
              actionLabel: 'redo',
              heading: DELETE_WORDS_REDO_MESSAGE,
              onAction() {
                executor.redo();
              },
            });
          },
        },
      );
      return executor.executeCommand(command);
    },
    [executor, originator, updateTranscriptText],
  );

  const updateWords = useCallback(
    (wordEdits: WordEdit[], onError: CommitErrorHandler) => {
      const textEdit = wordEdits.find((e) => e.text !== undefined);

      const command = new UpdateWordsCommand(
        wordEdits,
        originator,
        updateTranscriptText,
        {
          onCommitError: onError,
          onExecuteSuccess() {
            notifyTranscriptEdit({
              actionLabel: 'undo',
              heading: textEdit
                ? UPDATE_WORD_TEXT_MESSAGE_UNDO
                : DELETION_ADJUSTMENT_MESSAGE_UNDO,
              onAction: () => executor.undo(),
            });
          },
          onUnexecuteSuccess() {
            notifyTranscriptEdit({
              actionLabel: 'redo',
              heading: textEdit
                ? UPDATE_WORD_TEXT_MESSAGE_REDO
                : DELETION_ADJUSTMENT_MESSAGE_REDO,
              onAction: () => executor.redo(),
            });
          },
        },
      );
      return executor.executeCommand(command);
    },
    [executor, updateTranscriptText, originator],
  );

  const updateSearchResults = useCallback(
    (wordEdits: WordEdit[], onError: CommitErrorHandler) => {
      const command = new UpdateWordsCommand(
        wordEdits,
        originator,
        updateTranscriptText,
        {
          onCommitError: onError,
          onExecuteSuccess() {
            notifyTranscriptEdit({
              actionLabel: 'undo',
              heading: CORRECT_SEARCH_RESULTS_MESSAGE,
              onAction: () => executor.undo(),
            });
          },
          onUnexecuteSuccess() {
            notifyTranscriptEdit({
              actionLabel: 'redo',
              heading: UPDATE_WORD_TEXT_MESSAGE_REDO,
              onAction: () => executor.redo(),
            });
          },
        },
      );
      return executor.executeCommand(command);
    },
    [executor, updateTranscriptText, originator],
  );

  const updateSpeaker = useCallback(
    (name: string, speakerId: number, segmentId?: number) => {
      const command = new UpdateSpeakerCommand(
        {
          name,
          speakerId,
          segmentId,
        },
        originator,
        updateTranscriptSpeaker,
        editTranscriptSegmentSpeaker,
        {
          onExecuteSuccess() {
            notifyTranscriptEdit({
              actionLabel: 'undo',
              heading: SPEAKER_LABEL_MESSAGE_UNDO,
              onAction() {
                executor.undo();
              },
            });
          },
          onUnexecuteSuccess() {
            notifyTranscriptEdit({
              actionLabel: 'redo',
              heading: SPEAKER_LABEL_MESSAGE_REDO,
              onAction() {
                executor.redo();
              },
            });
          },
        },
      );

      return executor.executeCommand(command);
    },
    [
      editTranscriptSegmentSpeaker,
      executor,
      originator,
      updateTranscriptSpeaker,
    ],
  );

  const updateChapter = useCallback(
    (args: UpdateChapterArgs) => {
      const { title } = args;

      const command = new UpdateChapterCommand(
        args,
        originator,
        updateTranscriptChapter,
        {
          onExecuteSuccess() {
            notifyTranscriptEdit({
              actionLabel: 'undo',
              heading: title
                ? UPDATE_CHAPTER_MESSAGE_UNDO
                : DELETION_CHAPTER_MESSAGE_UNDO,
              onAction() {
                executor.undo();
              },
            });
          },
          onUnexecuteSuccess() {
            notifyTranscriptEdit({
              actionLabel: 'redo',
              heading: title
                ? UPDATE_CHAPTER_MESSAGE_REDO
                : DELETION_CHAPTER_MESSAGE_REDO,
              onAction() {
                executor.redo();
              },
            });
          },
        },
      );

      return executor.executeCommand(command);
    },
    [executor, originator, updateTranscriptChapter],
  );

  const createSegment = useCallback(
    (newSegmentStartWordId: number, config?: CreateSegmentConfig) => {
      const { onSegmentCreated } = config ?? {};

      const command = new CreateSegmentCommand(
        { newSegmentStartWordId },
        originator,
        createSegmentCommitter,
        deleteTranscriptSegment,
        {
          onExecuteSuccess: onSegmentCreated,
        },
      );

      return executor.executeCommand(command);
    },
    [createSegmentCommitter, deleteTranscriptSegment, executor, originator],
  );

  const deleteSegment = useCallback(
    (segmentId: number) => {
      const command = new DeleteSegmentCommand(
        { segmentId },
        originator,
        deleteTranscriptSegment,
        createSegmentCommitter,
      );

      return executor.executeCommand(command);
    },
    [createSegmentCommitter, deleteTranscriptSegment, executor, originator],
  );

  const createChapter = useCallback(
    (args: CreateChapterArgs, config?: CreateChapterConfig) => {
      const { onChapterCreated } = config ?? {};

      const command = new CreateChapterCommand(
        args,
        originator,
        createChapterCommitter,
        deleteTranscriptChapter,
        {
          onExecuteSuccess: onChapterCreated,
        },
      );

      return executor.executeCommand(command);
    },
    [createChapterCommitter, deleteTranscriptChapter, executor, originator],
  );

  const deleteChapter = useCallback(
    (chapterId: number) => {
      const command = new DeleteChapterCommand(
        { chapterId },
        originator,
        deleteTranscriptChapter,
        createChapterCommitter,
      );

      return executor.executeCommand(command);
    },
    [createChapterCommitter, deleteTranscriptChapter, executor, originator],
  );

  const undo = useCallback(() => {
    return executor.undo();
  }, [executor]);

  const redo = useCallback(() => {
    return executor.redo();
  }, [executor]);

  return useMemo(
    () => ({
      createChapter,
      createSegment,
      deleteChapter,
      deleteSegment,
      deleteWords,
      redo,
      undeleteWords,
      deleteSearchResults,
      undo,
      updateSpeaker,
      updateChapter,
      updateWords,
      updateSearchResults,
    }),
    [
      createChapter,
      createSegment,
      deleteChapter,
      deleteSearchResults,
      deleteSegment,
      deleteWords,
      redo,
      undeleteWords,
      undo,
      updateChapter,
      updateSearchResults,
      updateSpeaker,
      updateWords,
    ],
  );
}
