import { PulseLoader } from '@sparemin/blockhead';
import React from 'react';
import { useIntroOutro } from 'pages/TranscriptEditorPage/IntroOutroContext';
import IntroOutroUploader from 'pages/TranscriptEditorPage/IntroOutroUploader';
import AddIntroOutroButton from './AddIntroOutroButton';
import IntroOutroPopulated from './IntroOutroPopulated';
import * as S from './styles';
export interface IntroOutroProps {
  className?: string;
}

const IntroOutro: React.FC<IntroOutroProps> = ({ className }) => {
  const { isLoading, status, onAddIntroOutro, variant } = useIntroOutro();

  if (status === 'completed') {
    return <IntroOutroPopulated variant={variant} />;
  }
  return (
    <IntroOutroUploader
      className={className}
      disabled={isLoading}
      onDropAccepted={onAddIntroOutro}
      variant={variant}
    >
      {isLoading ? (
        <S.LoadingContainer>
          <PulseLoader />
        </S.LoadingContainer>
      ) : (
        <AddIntroOutroButton />
      )}
    </IntroOutroUploader>
  );
};
export default IntroOutro;
