import React from 'react';
import { useContextMenuTarget } from '../state/contextMenu';
import EditorContextMenuContents from './EditorContextMenuContents';

export interface EditorContextMenuProps {}

const EditorContextMenu: React.FC<EditorContextMenuProps> = () => {
  const trigger = useContextMenuTarget();

  return !trigger ? null : (
    <EditorContextMenuContents triggerElement={trigger} />
  );
};

export default EditorContextMenu;
