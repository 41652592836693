import { DislikeClipFeedbackValue } from './types';

export function getSubmitValueFromButtonValue(val: DislikeClipFeedbackValue) {
  switch (val) {
    case "It's an advertisement":
      return 'itsAnAdvertisement';
    case 'It cuts off mid sentence':
      return 'itCutsOffMidSentence';
    case "It's not interesting":
      return 'itsNotInteresting';
    case 'other reason':
      return 'otherReason';
  }
}
