import { PulseLoader } from '@sparemin/blockhead';
import Modal from 'components/Modal';

export interface ShareExportingBodyProps {
  className?: string;
}

const ShareExportingBody: React.FC<ShareExportingBodyProps> = ({
  className,
}) => {
  return (
    <Modal.Body className={className}>
      <PulseLoader />
    </Modal.Body>
  );
};

export default ShareExportingBody;
