import { List, ListProps, Text } from '@sparemin/blockhead';
import React, { useCallback } from 'react';
import Option from './Option';
import * as S from './styles';
import { OptionItem } from './types';

export interface OptionsListProps extends Pick<ListProps, 'aria-label'> {
  label?: string;
  items: OptionItem[];
  onChange: (key: string, isSelected: boolean) => void;
}

const OptionsListComponent: React.FC<OptionsListProps> = ({
  'aria-label': ariaLabel,
  label,
  items,
  onChange,
}) => {
  const createHandleOnChange = (key: string) => (isSelected: boolean) => {
    onChange(key, isSelected);
  };

  // handleAction handles clicks on the row, but if the toggle is clicked, onAction
  // isn't fired - those clicks are handled by createHandleOnChange
  const handleAction = useCallback(
    (key: string | number) => {
      const item = items.find((i) => i.key === key);

      if (item) {
        onChange(item.key, !item.value);
      }
    },
    [items, onChange],
  );

  return (
    <S.Root>
      {label && <Text variant="label">{label}</Text>}

      <List
        aria-label={ariaLabel}
        aria-labelledby={label}
        items={items}
        onAction={handleAction}
      >
        {({
          key,
          value,
          label: optionLabel,
          isDisabled,
          tooltipContents,
          render,
        }) => {
          const optionProps = {
            label: optionLabel,
            isSelected: value,
            isDisabled: isDisabled,
            tooltipContents: tooltipContents,
            onChange: createHandleOnChange(key),
          };

          return (
            <List.Item key={key} textValue={optionLabel}>
              {render ? render(optionProps) : <Option {...optionProps} />}
            </List.Item>
          );
        }}
      </List>
    </S.Root>
  );
};

const OptionsList = Object.assign(OptionsListComponent, {
  Option,
});

export default OptionsList;
