import { CheckCircle, Toast } from '@sparemin/blockhead';
import React from 'react';
import { Bounce, toast, ToastOptions as ToastifyOptions } from 'react-toastify';
import { SetRequired } from 'type-fest';
import ActionableToast from './ActionableToast';
import {
  SUCCESS_TOAST_AUTO_CLOSE_MILLIS,
  TRANSCRIPT_EDIT_TOAST_AUTOCLOSE_MILLIS,
  TRANSCRIPT_EDIT_TOAST_ID,
} from './constants';
import ErrorToast, { ErrorToastProps } from './ErrorToast';
import {
  ToastOptions,
  NotifyTranscriptEditOptions,
  NotifySuccessOptions,
} from './types';

const defaultToastOptions: ToastifyOptions = {
  hideProgressBar: true,
  closeOnClick: true,
  autoClose: 5000,
  closeButton: false,
};

const showNotification = (content: React.ReactNode, opts?: ToastOptions) => {
  toast(content, {
    ...defaultToastOptions,
    ...opts,
  });
};

/**
 * Replace a toast with the same id.
 *
 * If no toast is currently displayed or a toast with a different id is displayed,
 * then the new toast will be shown immediately.  If a toast with the same id is
 * displayed, that toast will be dismissed and the new one will be shown.
 */
function replaceToast(
  content: React.ReactNode,
  opts: SetRequired<ToastOptions, 'toastId'>,
) {
  const displayToast = () => showNotification(content, opts);

  // showing a single toast is not so straightforward.
  //
  // if toast() is called with an id that matches an active toast, then the new
  // toast will be queued to display after the current toast is dismissed.  This
  // is not what we want - the active toast should immediately be dismissed so that
  // the new one can be displayed.
  //
  // calling toast.dismiss() followed by toast() introduces a race condition since
  // dismissing a toast has to wait for an animation to complete before the toast is
  // considered to be dismissed.  This results in a delay before the new toast is
  // displayed.
  //
  // in order to remove the delay, the active toast is updated to skip the exit
  // animation, making the dismissal much faster than it is by default
  if (toast.isActive(opts.toastId)) {
    const unsubscribe = toast.onChange((payload) => {
      if (payload.id === opts.toastId) {
        if (payload.status === 'removed') {
          displayToast();
          unsubscribe();
        } else if (payload.status === 'updated') {
          toast.dismiss(opts.toastId);
        }
      }
    });

    toast.update(opts.toastId, {
      transition: (props) => Bounce({ ...props, preventExitTransition: true }),
    });
  } else {
    displayToast();
  }
}

function notifyError(args?: ErrorToastProps) {
  showNotification(
    <ErrorToast
      heading={args?.heading}
      subHeading={args?.subHeading}
      errorCode={args?.errorCode}
    />,
  );
}

/**
 * Shows an undo/redo toast related to transcript edits.
 *
 * Since undo/redo operations have to occure in a particular order, only one
 * transcript-edit toast is shown at a time (to prevent the user from trying to
 * undo/redo an operation out of order by clicking the CTA in a stale toast that
 * is still on screen).
 *
 * If this function is called when a toast is already active, the active toast will
 * be dismissed so that the new one can be displayed.
 */
export function notifyTranscriptEdit(props: NotifyTranscriptEditOptions) {
  replaceToast(<ActionableToast {...props} />, {
    autoClose: TRANSCRIPT_EDIT_TOAST_AUTOCLOSE_MILLIS,
    toastId: TRANSCRIPT_EDIT_TOAST_ID,
  });
}

export function notifySuccess({
  toastId,
  ...toastProps
}: NotifySuccessOptions) {
  replaceToast(<Toast icon={<CheckCircle />} {...toastProps} />, {
    autoClose: SUCCESS_TOAST_AUTO_CLOSE_MILLIS,
    toastId,
  });
}

export { notifyError };
