import { useStaticCallback } from '@sparemin/blockhead';
import { useCallback, useEffect, useRef } from 'react';

export interface BaseUsePostMessageHandlerConfig {
  listenOnMount?: boolean;
  origin?: string;
}

export interface UseRawPostMessageHandlerConfig<T = unknown>
  extends BaseUsePostMessageHandlerConfig {
  onMessageReceived?: (event: MessageEvent<T>) => void;
  validator?: undefined;
}

export interface UseValidatedPostMessageHandlerConfig<T = unknown>
  extends BaseUsePostMessageHandlerConfig {
  validator: (event: MessageEvent) => event is MessageEvent<T>;
  onMessageReceived?: (event: MessageEvent<T>) => void;
}

type UsePostMessageHandlerConfig<T = unknown> =
  | UseRawPostMessageHandlerConfig<T>
  | UseValidatedPostMessageHandlerConfig<T>;

export default function usePostMessageHandler<T = unknown>(
  config: UsePostMessageHandlerConfig<T>,
) {
  const { listenOnMount, onMessageReceived, origin, validator } = config;

  const listenOnMountRef = useRef(listenOnMount);

  const handleMessage = useStaticCallback((event: MessageEvent) => {
    const hasValidOrigin = !origin || event.origin === origin;
    const isValidMessage = !validator || validator(event);

    if (hasValidOrigin && isValidMessage) {
      onMessageReceived?.(event);
    }
  });

  const attachHandler = useCallback(() => {
    window.addEventListener('message', handleMessage);
  }, [handleMessage]);

  const removeHandler = useCallback(() => {
    window.removeEventListener('message', handleMessage);
  }, [handleMessage]);

  useEffect(() => {
    if (listenOnMountRef.current) {
      attachHandler();
    }

    return () => {
      removeHandler();
    };
  }, [attachHandler, removeHandler]);

  return [attachHandler, removeHandler];
}
