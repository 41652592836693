import { ProjectTranscript } from 'api';
import { placeholderChapterIds } from '../../placeholderIds';
import AsyncUndoableTranscriptCommand from '../AsyncUndoableTranscriptCommand';
import TranscriptOriginator from '../TranscriptOriginator';
import { CommandOptions } from '../types';
import { createChapter, deleteChapter } from './optimisticUpdate';
import { CreateChapterCommitter, DeleteChapterCommitter } from './types';

type DeleteChapterCommandArgs = {
  chapterId: number;
};

export default class DeleteChapterCommand extends AsyncUndoableTranscriptCommand {
  private deletedChapterStartWordId: number | undefined;

  private localChapterId: number;

  private remoteChapterId: number;

  private originalChapterTitle: string | undefined;

  private originalChapterUrl: string | undefined;

  private originalChapterImageUrl: string | undefined;

  constructor(
    args: DeleteChapterCommandArgs,
    originator: TranscriptOriginator,
    private deleteChapterCommitter: DeleteChapterCommitter,
    private createChapterCommitter: CreateChapterCommitter,
    opts?: CommandOptions,
  ) {
    super(originator, opts);

    this.localChapterId = args.chapterId;
    this.remoteChapterId = args.chapterId;
  }

  protected executeTranscript(
    transcript: ProjectTranscript,
  ): ProjectTranscript | undefined {
    const [
      newTranscript,
      deletedChapterStartWordId,
      originalChapterTitle,
      originalChapterUrl,
      originalChapterImageUrl,
    ] = deleteChapter(transcript, this.localChapterId);

    this.deletedChapterStartWordId = deletedChapterStartWordId;
    this.originalChapterTitle = originalChapterTitle;
    this.originalChapterUrl = originalChapterUrl;
    this.originalChapterImageUrl = originalChapterImageUrl;

    return newTranscript;
  }

  protected unexecuteTranscript(
    transcript: ProjectTranscript,
  ): ProjectTranscript | undefined {
    if (this.deletedChapterStartWordId) {
      const [newTranscript, newChapterId] = createChapter(
        transcript,
        this.deletedChapterStartWordId,
        this.originalChapterTitle,
        this.originalChapterUrl,
        this.originalChapterImageUrl,
      );

      this.localChapterId = newChapterId;

      return newTranscript;
    }

    return undefined;
  }

  public async commit(): Promise<void> {
    try {
      await this.deleteChapterCommitter({
        chapterId: this.remoteChapterId,
      });
    } catch (err) {
      this.onError('commit');
      throw err;
    }
  }

  public async uncommit(): Promise<void> {
    if (this.deletedChapterStartWordId) {
      try {
        const newChapterId = await this.createChapterCommitter({
          newChapterStartWordId: this.deletedChapterStartWordId,
          newChapterTitle: this.originalChapterTitle,
          newChapterUrl: this.originalChapterUrl,
          newChapterImageUrl: this.originalChapterImageUrl,
        });

        this.remoteChapterId = newChapterId;

        placeholderChapterIds.setPlaceholderId(
          this.localChapterId,
          newChapterId,
        );
      } catch (err) {
        this.onError('uncommit');
        throw err;
      }
    }
  }
}
