import { Spacer, useDelay } from '@sparemin/blockhead';
import React, { useEffect } from 'react';
import usePrevious from 'hooks/usePrevious';
import { useProjectCreationProgress } from '../state';
import { useEditorLoadingStatus } from '../state/useEditorLoadingStatus';
import ProjectLoaderMessage from './ProjectLoaderMessage';
import ProjectLoaderProgress from './ProjectLoaderProgress';
import ProjectLoaderTakeoverView from './ProjectLoaderTakeoverView';

export interface ProjectLoaderPaidTierProps {
  onLoadingComplete?: () => void;
}

const ProjectLoaderPaidTier = React.forwardRef<
  HTMLDivElement,
  ProjectLoaderPaidTierProps
>(({ onLoadingComplete }, ref) => {
  const editorStatus = useEditorLoadingStatus();
  const { data: percentMilli = 0 } = useProjectCreationProgress();
  const prevEditorStatus = usePrevious(editorStatus);

  const [delayedOnLoadingComplete] = useDelay(
    () => onLoadingComplete?.(),
    1000,
  );

  useEffect(() => {
    if (
      editorStatus !== prevEditorStatus &&
      editorStatus === 'completed' &&
      percentMilli >= 1000
    ) {
      delayedOnLoadingComplete();
    }
  }, [delayedOnLoadingComplete, editorStatus, percentMilli, prevEditorStatus]);

  return (
    <ProjectLoaderTakeoverView justify="center" ref={ref}>
      <Spacer align="center" orientation="vertical" space="50px">
        <ProjectLoaderMessage />
        <ProjectLoaderProgress size="large" />
      </Spacer>
    </ProjectLoaderTakeoverView>
  );
});

export default ProjectLoaderPaidTier;
