import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { paths } from 'components/App';
import { useAuth } from 'state/auth';
import { getRedirectSearchString } from './utils';

export interface AuthenticatedRouteProps {
  redirectTo?: string;
}

/**
 * If user is authenticated, render <Outlet />, otherwise redirect to another route.
 * When redirecting, the URL that the user was trying to access gets appended to
 * the URL as a query parameter.
 */
const AuthenticatedRoute: React.FC<AuthenticatedRouteProps> = ({
  redirectTo = paths.login.value,
}) => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();

  if (!isAuthenticated) {
    return (
      <Navigate
        to={{
          pathname: redirectTo,
          search: getRedirectSearchString(location),
        }}
        replace
      />
    );
  }

  return <Outlet />;
};

export default AuthenticatedRoute;
