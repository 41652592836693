import { useCallback, useEffect, useRef } from 'react';
import { QueryStatus } from 'react-query';
import { createHeadlinerAudioWizardRedirect } from 'api';
import { useAuth } from 'state/auth';
import { AudioSelection } from 'types';
import useExportAudio from './useExportAudio';

export interface UseExportAudioToHeadliner {
  exportAudioToHeadliner: (Args: ExportAudioToHeadlinerArgs) => void;
  status: QueryStatus;
}

export interface ExportAudioToHeadlinerArgs {
  projectCuid: string;
  selection: AudioSelection;
  isClip: boolean;
  onSuccess?: (redirectUrl: string) => void;
}

export default function useExportAudioToHeadliner() {
  const { accessToken } = useAuth();
  const argsRef = useRef<ExportAudioToHeadlinerArgs>();
  const successRef = useRef(false);
  const { data: audioExport, exportAudio, status } = useExportAudio();

  useEffect(() => {
    if (
      audioExport?.audioUrl &&
      argsRef.current &&
      accessToken &&
      !successRef.current
    ) {
      const { projectCuid, selection, isClip } = argsRef.current;

      const redirectUrl = createHeadlinerAudioWizardRedirect({
        accessToken,
        projectCuid,
        audioUrl: audioExport.audioUrl,
        endWordId: selection.endWordId,
        startWordId: selection.startWordId,
        isClip,
      });

      argsRef.current.onSuccess?.(redirectUrl);
      successRef.current = true;
    }
  }, [accessToken, audioExport?.audioUrl]);

  const exportAudioToHeadliner = useCallback(
    (args: ExportAudioToHeadlinerArgs) => {
      const { projectCuid, selection } = args;
      argsRef.current = args;
      successRef.current = false;

      exportAudio({ projectCuid, selection });
    },
    [exportAudio],
  );

  return { exportAudioToHeadliner, status };
}
