import React from 'react';
import InsertChapterButton from './InsertChapterButton';
import InsertSpeakerButton from './InsertSpeakerButton';

export type InsertElementPopoverContentsProps = Record<string, never>;

const InsertElementPopoverContents: React.FC<
  InsertElementPopoverContentsProps
> = () => (
  <>
    <InsertSpeakerButton />
    <InsertChapterButton />
  </>
);

export default InsertElementPopoverContents;
