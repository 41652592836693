import { QueryStatus } from 'react-query';
import {
  useExportAudio,
  useExportVideo,
} from 'pages/TranscriptEditorPage/state';
import useExportTranscript from 'pages/TranscriptEditorPage/state/useExportTranscript';
import { queryStatusResolver } from 'state/utils';

export interface UseShare {
  exportAudio: ReturnType<typeof useExportAudio>['exportAudio'];
  exportVideo: ReturnType<typeof useExportVideo>['exportVideo'];
  exportTranscript: ReturnType<typeof useExportTranscript>['exportTranscript'];
  status: QueryStatus;
}

const useShare = (): UseShare => {
  const { exportAudio, status: audioStatus } = useExportAudio();
  const { exportVideo, status: videoStatus } = useExportVideo();
  const { exportTranscript, status: transcriptStatus } = useExportTranscript();

  return {
    exportAudio,
    exportVideo,
    exportTranscript,
    status: queryStatusResolver([audioStatus, videoStatus, transcriptStatus]),
  };
};

export default useShare;
