import { useMemo } from 'react';
import SearchActionMenuContext from './SearchActionMenuContext';
import { SearchActionMenuProviderProps } from './types';

const SearchActionMenuProvider: React.FC<SearchActionMenuProviderProps> = ({
  children,
  foundWords,
  activeWordIndex,
  onDelete,
  onUpdate,
}) => (
  <SearchActionMenuContext.Provider
    value={useMemo(
      () => ({ foundWords, activeWordIndex, onDelete, onUpdate }),
      [activeWordIndex, foundWords, onDelete, onUpdate],
    )}
  >
    {children}
  </SearchActionMenuContext.Provider>
);

export default SearchActionMenuProvider;
