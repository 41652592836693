import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { createProject, CreateProjectResult, getTraceById } from 'api';
import { urlTraceQueryKeys } from 'state/urlTrace';
import { useEddyPrefsOverride } from 'state/userPref';
import { MutationOptions } from 'types';

export interface CreateProjectFromTraceArgs {
  traceId: string;
}

export default function useCreateProjectFromTrace(
  opts?: MutationOptions<CreateProjectResult, CreateProjectFromTraceArgs>,
) {
  const queryClient = useQueryClient();
  const { data: userEddyPrefs } = useEddyPrefsOverride();

  const mutationFn = useCallback(
    async ({ traceId }: CreateProjectFromTraceArgs) => {
      const trace = await getTraceById(traceId);
      queryClient.setQueryData(urlTraceQueryKeys.trace(traceId), trace);

      const audioUrl = trace.properties?.audioUrl;
      const projectName = trace.properties?.clipTitle;
      const language = trace.properties?.audioLanguage;

      if (!audioUrl) {
        throw new Error(
          'Configuration is missing audio URL.  Cannot create project',
        );
      }

      if (!projectName) {
        throw new Error(
          'Configuration is missing project name. Cannot create project',
        );
      }

      const project = await createProject({
        associatedMedias: [
          {
            language,
            assetUrl: audioUrl,
            mediaType: 'audio',
          },
        ],
        name: projectName,
        traceId,
        ...userEddyPrefs,
      });

      return project;
    },
    [queryClient, userEddyPrefs],
  );

  return useMutation<
    CreateProjectResult,
    unknown,
    CreateProjectFromTraceArgs,
    unknown
  >(mutationFn, opts);
}
