import { AxiosResponse } from 'axios';
import { request } from 'api/http';
import { IMAGE_SERVICE_V1_BACKGROUND_BLOB_URL } from './constants';
import { UploadBlobImageArgs, UploadBlobImageResult } from './types';

export async function uploadBlobImage({
  imageFile,
  imageType,
  userId,
}: UploadBlobImageArgs) {
  const { data } = await request.put<
    UploadBlobImageResult,
    AxiosResponse<UploadBlobImageResult>,
    FormData
  >(`${IMAGE_SERVICE_V1_BACKGROUND_BLOB_URL}`, imageFile, {
    omitBearerPrefix: true,
    params: {
      imageType,
      userId,
    },
  });

  return data;
}
