import { useReducer } from 'react';
import reducer from './reducer';
import { QueryStringAuthState } from './types';

const defaultState: QueryStringAuthState = {
  error: undefined,
  status: 'idle',
};

export default function useQueryStringAuthState() {
  return useReducer(reducer, defaultState);
}
