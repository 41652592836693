import { FileDropzoneAccept } from '@sparemin/blockhead';

export const CHAPTER_ART_MAX_FILE_SIZE_MB = 10;
export const CHAPTER_ART_MIN_DIMENSION_PX = 1400;
export const CHAPTER_ART_MAX_DIMENSION_PX = 3000;

export const ACCEPTED_IMAGE_FILE_EXTENSIONS = ['.jpg', '.jpeg', '.png'];

export const DROPZONE_ACCEPT: FileDropzoneAccept = {
  'image/*': ACCEPTED_IMAGE_FILE_EXTENSIONS,
};
