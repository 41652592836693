import React from 'react';
import { Outlet } from 'react-router-dom';
import QueryStringAuthenticator from './QueryStringAuthenticator';
import { QueryStringAuthenticatorProps } from './types';

export type QueryStringAuthRouteProps = Omit<
  QueryStringAuthenticatorProps,
  'children'
>;

const QueryStringAuthRoute: React.FC<QueryStringAuthRouteProps> = ({
  fallback,
  ...props
}) => (
  <QueryStringAuthenticator {...props}>
    <Outlet />
  </QueryStringAuthenticator>
);

export default QueryStringAuthRoute;
