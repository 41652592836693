import { DOMAttributes, FocusableElement } from '@react-types/shared';
import { PopoverContainer } from '@sparemin/blockhead';
import React, { useMemo, useState } from 'react';
import SuggestedClipTooltipContents, {
  SuggestedClipTooltipContentsProps,
} from './SuggestedClipTooltipContents';
import useSuggestedClipTooltipOrchestrator from './useSuggestedClipTooltipOrchestrator';

interface RenderProps extends DOMAttributes<FocusableElement> {
  ref: React.RefCallback<Element>;
}

type RenderFunction = (props: RenderProps) => void;

export interface SuggestedClipTooltipProps
  extends Omit<SuggestedClipTooltipContentsProps, 'isOpen'> {
  children: RenderFunction;
}

const SuggestedClipTooltip: React.FC<SuggestedClipTooltipProps> = ({
  children,
  ...props
}) => {
  const [triggerEl, setTriggerEl] = useState<Element | null>(null);
  const {
    isOpen,
    triggerProps = {},
    tooltipProps,
  } = useSuggestedClipTooltipOrchestrator();

  const { onPointerEnter, onPointerLeave } = triggerProps;

  const childrenNode = useMemo(
    () =>
      children({
        onPointerEnter,
        onPointerLeave,
        ref: setTriggerEl,
      }),
    [children, onPointerEnter, onPointerLeave],
  );

  return (
    <>
      {childrenNode}
      <PopoverContainer
        isOpen={isOpen}
        keepMounted
        offset={18}
        placement="right"
        triggerElement={triggerEl}
      >
        <SuggestedClipTooltipContents
          isOpen={isOpen}
          {...tooltipProps}
          {...props}
        />
      </PopoverContainer>
    </>
  );
};

export default SuggestedClipTooltip;
