import { Doc, breakpointDown } from '@sparemin/blockhead';
import styled from 'styled-components';
import { ShareExportingBody } from 'components/ExportModalContents';
import BaseModal from 'components/Modal';

export const Modal = styled(BaseModal)`
  width: 570px;
  text-align: start;

  ${breakpointDown('md')`
    width: 100%;
  `}
`;

export const Icon = styled(Doc)`
  width: 60px;
  padding-top: 8px;
`;

export const Loader = styled(ShareExportingBody)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const Body = styled(Modal.Body)`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;
