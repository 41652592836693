import React from 'react';

import ConnectedModal from 'components/ConnectedModal';
import { useModalParams } from 'state/modal';

import ShareProjectModalContents from './ShareProjectModalContents';

const ShareProjectModal: React.FunctionComponent = () => {
  const params = useModalParams('ShareProject');

  return (
    <ConnectedModal name="ShareProject">
      <ShareProjectModalContents params={params} />
    </ConnectedModal>
  );
};

export default ShareProjectModal;
