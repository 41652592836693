import { getThemeColor, Search } from '@sparemin/blockhead';
import styled from 'styled-components';
import { PodcastContainer } from '../styles';

export const PodcastTargetContainer = styled(PodcastContainer)`
  align-items: center;
  background-color: ${getThemeColor('l2')};
  border: 2px dashed ${getThemeColor('l4')};
  border-radius: 8px;
  color: ${getThemeColor('actionPrimary')};
  display: flex;
  justify-content: center;
`;

export const PodcastTargetContainerIcon = styled(Search)`
  height: 12px;
  margin-right: 8px;
`;
