export function getActiveRange() {
  const selection = window.getSelection && window.getSelection();
  // fixes a webkit only issue: https://stackoverflow.com/questions/22935320/uncaught-indexsizeerror-failed-to-execute-getrangeat-on-selection-0-is-not
  if (selection && selection.rangeCount > 0) {
    return selection?.getRangeAt(0);
  }
}

export function clearSelection() {
  document.getSelection()?.removeAllRanges();
}

export function selectRange(range: Range) {
  clearSelection();
  document.getSelection()?.addRange(range);
}

export function getElementsInRange(
  range: Range | undefined,
  filter: (el: HTMLElement) => boolean,
) {
  function getElementsFromChildren(children: HTMLCollection) {
    const elements: HTMLElement[] = [];

    // empty children - bail
    if (!children || children.length === 0) {
      return elements;
    }

    for (const child of children) {
      if (child instanceof HTMLElement && filter(child)) {
        elements.push(child);
      }

      elements.push(...getElementsFromChildren(child.children));
    }

    return elements;
  }

  // if range is empty, so it's basically a click rather than a select - bail
  if (
    !range ||
    (range.startContainer === range.endContainer &&
      range.endOffset - range.startOffset === 0)
  ) {
    return [];
  }

  // characters within a single element selected.  return the element
  if (
    range.startContainer === range.endContainer &&
    range.endOffset - range.startOffset > 0
  ) {
    const el = range.startContainer.parentElement;

    return !el || !filter(el) ? [] : [el];
  }

  return getElementsFromChildren(range.cloneContents().children);
}

export function selectElements(
  first: Element | null,
  last: Element | null = first,
) {
  if (!first || !last) {
    return undefined;
  }

  const range = document.createRange();
  range.setStart(first, 0);
  range.setEnd(last, 1);
  selectRange(range);

  return range;
}
