import {
  ProBadgeGradient,
  Replace,
  Spacer,
  Tooltip,
  TooltipProps,
} from '@sparemin/blockhead';
import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import notifyError from 'components/notification';
import { DEFAULT_UPSELL_PLAN } from 'config';
import useCheckoutPopup from 'hooks/useCheckoutPopup';
import { useAuth } from 'state/auth';
import { useEventTracking } from 'state/eventTracking';
import { subscriptionQueryKeys, useIsBasicOrFreeTier } from 'state/pricing';
import { useTopBar } from '../TopBar/TopBarContext';
import { baseAdjustmentsBarAnimationProps } from './animation';
import * as S from './styles';

export interface BaseAdjustmentsBarProps {
  title?: React.ReactNode;
  description?: React.ReactNode;
  children?: React.ReactNode;
}

const BaseAdjustmentsBar: React.FC<BaseAdjustmentsBarProps> = ({
  title,
  description = 'This will overwrite previous assets, so make sure to download any that you want to keep first.',
  children,
}) => {
  const { trackUnlockThisFeature } = useEventTracking();
  const { data: isBasicOrFreeTier } = useIsBasicOrFreeTier();
  const { userId } = useAuth();
  const { assetType, onRegenerate, onCancel } = useTopBar();
  const queryClient = useQueryClient();

  const handleCheckout = useCallback((): void => {
    queryClient.invalidateQueries(subscriptionQueryKeys.mySubscription(userId));
  }, [queryClient, userId]);

  const { anchorProps } = useCheckoutPopup({
    onOpen() {
      trackUnlockThisFeature({
        modal: 'EddyPromoPackRegenerateAsset',
        assetType,
      });
    },
    onSuccess: handleCheckout,
    onError() {
      notifyError({
        heading: 'Error processing checkout',
      });

      handleCheckout();
    },
    targetPlan: DEFAULT_UPSELL_PLAN,
  });

  // The Tooltip component interferes with click handling inside its contents,
  // especially for inputs. Typically, wrapping the contents inside a `div`
  // resolves this issue, but it was ineffective in this case. To solve this
  // problem, the wrapper for the contents is conditionally chosen so that the
  // tooltip only acts as a wrapper when necessary. Since the tooltip is only
  // needed when the contents should be disabled (e.g., when user access is
  // gated), there are no issues with click handling in this scenario.
  const ContentsWrapper = isBasicOrFreeTier ? Tooltip : 'div';

  const contentsWrapperProps: TooltipProps = isBasicOrFreeTier
    ? {
        arrow: true,
        isDisabled: !isBasicOrFreeTier,
        placement: 'top',
        contents: 'PRO feature: upgrade to unlock',
      }
    : ({} as TooltipProps);

  return (
    <S.Root {...baseAdjustmentsBarAnimationProps}>
      <Spacer orientation="vertical" space="24px">
        <Spacer orientation="vertical" space="4px">
          {title && (
            <S.Title>
              {title}{' '}
              {isBasicOrFreeTier && <ProBadgeGradient width={28} height={15} />}
            </S.Title>
          )}

          {description && <S.Description>{description}</S.Description>}
        </Spacer>

        <S.Container>
          <ContentsWrapper {...contentsWrapperProps}>
            <S.Contents $isGated={Boolean(isBasicOrFreeTier)}>
              {children}
            </S.Contents>
          </ContentsWrapper>
        </S.Container>

        <S.Footer>
          <S.FooterButton variant="ghost" onPress={onCancel}>
            Cancel
          </S.FooterButton>

          {isBasicOrFreeTier ? (
            <S.FooterLinkButton fluid variant="special" {...anchorProps}>
              unlock this feature
            </S.FooterLinkButton>
          ) : (
            <S.FooterButton startIcon={<Replace />} onPress={onRegenerate}>
              Regenerate
            </S.FooterButton>
          )}
        </S.Footer>
      </Spacer>
    </S.Root>
  );
};

export default BaseAdjustmentsBar;
