import { useQuery } from 'react-query';
import { UserPreferences, getMyUserPrefs } from 'api/services/headlinerUser';
import { useAuth } from 'state/auth';
import { QueryOptions } from 'types';
import { userPrefQueryKeys } from './queryKeys';
import {
  eddyPrefsSelector,
  isAutoProjectNameEnabledSelector,
  shouldRemoveFillerWordsSelector,
} from './selectors';
type QueryKey = ReturnType<typeof userPrefQueryKeys.myUserPrefs>;

export type UseMyUserPrefsOptions<TData = UserPreferences> = QueryOptions<
  UserPreferences,
  TData,
  QueryKey
>;

function useMyUserPrefs<TData = UserPreferences>(
  opts?: UseMyUserPrefsOptions<TData>,
) {
  const { enabled = true } = opts ?? {};
  const { userId } = useAuth();

  return useQuery(userPrefQueryKeys.myUserPrefs(userId), getMyUserPrefs, {
    ...opts,
    enabled: !!userId && enabled,
  });
}

export const useEddyPrefsOverride = () =>
  useMyUserPrefs({ select: eddyPrefsSelector });

export const useIsAutoProjectNameEnabled = () =>
  useMyUserPrefs({
    select: isAutoProjectNameEnabledSelector,
  });

export const useShouldRemoveFillerWordsEnabled = () =>
  useMyUserPrefs({
    select: shouldRemoveFillerWordsSelector,
  });
