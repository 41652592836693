import { useCallback } from 'react';
import useActivateWord from '../hooks/useActivateWord';
import { useClipSuggestions, useSuggestionCount, useWordsById } from '../state';
import {
  useActiveClipIndex,
  useExplorerBarActions,
} from '../state/explorerBar';

export default function useBrowseSuggestedClips() {
  const clipIndex = useActiveClipIndex();
  const { activateClipIndex: activateSuggestionIndex } =
    useExplorerBarActions();
  const { data: suggestions = [] } = useClipSuggestions();
  const { data: suggestionCount = 0 } = useSuggestionCount();
  const activateWord = useActivateWord();
  const { data: wordsById } = useWordsById();

  const onNextClip = useCallback(() => {
    if (!wordsById) {
      return;
    }

    const nextClipIndex = (clipIndex + 1) % suggestionCount;
    activateSuggestionIndex(nextClipIndex);
    activateWord(wordsById[suggestions[nextClipIndex].startWordId]);
  }, [
    activateSuggestionIndex,
    activateWord,
    clipIndex,
    suggestionCount,
    suggestions,
    wordsById,
  ]);

  const onPrevClip = useCallback(() => {
    if (!wordsById) {
      return;
    }

    const prevClipIndex = (clipIndex - 1 + suggestionCount) % suggestionCount;
    activateSuggestionIndex(prevClipIndex);
    activateWord(wordsById[suggestions[prevClipIndex].startWordId]);
  }, [
    activateSuggestionIndex,
    activateWord,
    clipIndex,
    suggestionCount,
    suggestions,
    wordsById,
  ]);

  const focusCurrentClip = useCallback(() => {
    if (!wordsById) {
      return;
    }

    activateWord(wordsById[suggestions[clipIndex].startWordId]);
  }, [activateWord, clipIndex, suggestions, wordsById]);

  return {
    clipCount: suggestionCount,
    clipIndex,
    focusCurrentClip,
    onNextClip,
    onPrevClip,
  };
}
