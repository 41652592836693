import { useEffect } from 'react';
import { CONTEXT_MENU_CONTAINER_CLASSNAME } from 'pages/TranscriptEditorPage/EditorContextMenu/constants';
import useActivateWord from 'pages/TranscriptEditorPage/hooks/useActivateWord';
import { getActiveRange } from 'utils/selection';
import { useAudioPlayer } from '../../AudioPlayerContext';
import { useWordList, useWordsById } from '../../state';
import { useContextMenuActions } from '../../state/contextMenu';
import {
  getWordIdFromElement,
  getWordIdsInRange,
  isWordElement,
  selectFullWordsInRange,
} from '../../utils';

export default function useRangeSelection() {
  const { player } = useAudioPlayer();
  const { data: wordsById } = useWordsById();
  const { data: words } = useWordList();
  const { closeContextMenu, openContextMenu } = useContextMenuActions();
  const activateWord = useActivateWord();

  useEffect(() => {
    const handleMouseUp = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      const range = getActiveRange();
      const wordIds = getWordIdsInRange(range);

      if (wordIds.length > 0) {
        // in here, a range was selected.  it could be a range that spans multiple
        // words or just a few characters in a single word
        const adjustedRange = selectFullWordsInRange(wordIds);
        openContextMenu(adjustedRange, { words: wordIds });
      } else if (isWordElement(target)) {
        // in here, no _range_ was selected - meaning not a single character in
        // any word is highlighted, however the user might have clicked on a word
        const wordId = getWordIdFromElement(target);
        const word = wordsById?.[wordId];

        if (word) {
          activateWord(word);
          openContextMenu(target, { words: [wordId] });
        }
      } else {
        const contextMenu = document.getElementsByClassName(
          CONTEXT_MENU_CONTAINER_CLASSNAME,
        )[0];

        if (!contextMenu || !contextMenu.contains(target)) {
          closeContextMenu();
        }
      }
    };

    // capture phase is used to circumvent react-aria preventing bubbling on
    // certain mouse/pointer events
    document.addEventListener('pointerup', handleMouseUp, { capture: true });

    return () => {
      document.removeEventListener('pointerup', handleMouseUp, {
        capture: true,
      });
    };
  }, [
    activateWord,
    closeContextMenu,
    openContextMenu,
    player,
    words,
    wordsById,
  ]);
}
