import { ButtonBase } from '@sparemin/blockhead';
import styled, { DefaultTheme, ThemedStyledProps } from 'styled-components';
import Modal from 'components/Modal';
import { EdgebarIconContainerStyleProps } from './types';

function getEdgebarIconBackground({
  theme,
  $type,
}: ThemedStyledProps<EdgebarIconContainerStyleProps, DefaultTheme>) {
  switch ($type) {
    case 'welcome': {
      return `linear-gradient(to bottom, rgba(255, 255, 255, 0.85), rgba(255, 255, 255, 0.85)), linear-gradient(135deg, #5dbefc, #dd92fe)`;
    }

    case 'showNotes': {
      return `radial-gradient(circle at 50% 50%, #11dbf1, ${theme.palette.actionPrimary.main} 71%)`;
    }

    case 'episodeArt': {
      return `radial-gradient(circle at 50% 50%, #8bb8f3, ${theme.legacyPalette.secondary.main} 71%)`;
    }

    case 'keywords': {
      return `radial-gradient(circle at 50% 50%, #ffb0e5, ${theme.palette.s2} 71%)`;
    }

    case 'socialCaptions': {
      return `radial-gradient(circle at 50% 50%, #1eede3, ${theme.palette.s3} 71%)`;
    }

    case 'quotes': {
      return `radial-gradient(circle at 50% 50%, #ffe791, #ffba4d 71%)`;
    }

    case 'references': {
      return `radial-gradient(circle at 50% 50%, #ffbe5c, #ff7d19 71%)`;
    }

    case 'newsletter': {
      return `radial-gradient(circle at 50% 50%, #ff9d82, #f55b41 71%)`;
    }

    case 'blogPost': {
      return `radial-gradient(circle at 50% 50%, #f779af, #f99393 71%)`;
    }

    default: {
      return;
    }
  }
}

export const Root = styled(Modal)`
  overflow: hidden;

  & > :first-child {
    padding: 0;
  }
`;

export const IconButton = styled(ButtonBase)`
  width: 100%;
  height: 100%;
  border-radius: 16px;
`;

export const EdgebarIconContainer = styled.div<EdgebarIconContainerStyleProps>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  font-size: 48.5px;
  background-image: ${getEdgebarIconBackground};
`;
