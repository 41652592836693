import React, { useCallback } from 'react';
import { EdgeAssetType } from 'api';
import MediaUploader from 'components/MediaUploader';
import {
  DROPZONE_ACCEPT_AUDIO,
  DROPZONE_ACCEPT_VIDEO,
} from 'components/MediaUploader/constants';
import { UploadOptions } from 'components/MediaUploader/types';

import { useModalsActions } from 'state/modal';
import { useIsBasicOrFreeTier } from 'state/pricing';
import { megabytesToBytes } from 'utils/math';
import {
  INTRO_OUTRO_MAX_LENGTH_SECS,
  INTRO_OUTRO_MAX_SIZE_MB,
} from '../constants';
import { useProjectMediaType } from '../state';
import useFileDropped from './useFileDropped';

interface IntroOutroUploaderProps {
  children?: React.ReactElement;
  uploadOptions?: UploadOptions;
  className?: string;
  onDropAccepted?: (file: File) => void;
  disabled?: boolean;
  variant: EdgeAssetType;
}

const IntroOutroUploader: React.FC<IntroOutroUploaderProps> = ({
  children,
  className,
  onDropAccepted,
  disabled = false,
  variant,
}) => {
  const { data: isBasicOrFreeTier } = useIsBasicOrFreeTier();

  const { pushModal } = useModalsActions();

  const handleGatedUser = useCallback(() => {
    pushModal({ name: 'Upsell', params: { context: 'introOutro' } });
  }, [pushModal]);

  const { data: projectType } = useProjectMediaType();

  const handleFileAccepted = useCallback(
    (files: File[]) => {
      return onDropAccepted?.(files[0]);
    },

    [onDropAccepted],
  );

  const { onFileRejected } = useFileDropped();

  const acceptedFileTypes =
    projectType === 'audio' ? DROPZONE_ACCEPT_AUDIO : DROPZONE_ACCEPT_VIDEO;

  return (
    <MediaUploader
      className={className}
      maxSize={megabytesToBytes(INTRO_OUTRO_MAX_SIZE_MB)}
      maxDuration={INTRO_OUTRO_MAX_LENGTH_SECS}
      accept={acceptedFileTypes}
      onDropAccepted={isBasicOrFreeTier ? handleGatedUser : handleFileAccepted}
      onDropRejected={isBasicOrFreeTier ? handleGatedUser : onFileRejected}
      disabled={disabled}
    >
      {children}
    </MediaUploader>
  );
};
export default IntroOutroUploader;
