import { useQuery } from 'react-query';
import {
  EddyProjectLanguageResult,
  getEddyProjectLanguages,
  Language,
} from 'api';
import { QueryOptions } from 'types';
import { languageQueryKeys } from './queryKeys';
import { supportedLanguageSelector } from './selectors';

type QueryKey = typeof languageQueryKeys.all;

export type UseEddyProjectSupportedLanguagesOptions<
  TData = EddyProjectLanguageResult,
> = QueryOptions<EddyProjectLanguageResult, TData, QueryKey>;

function useEddyProjectSupportedLanguages<TData = EddyProjectLanguageResult>(
  opts?: UseEddyProjectSupportedLanguagesOptions<TData>,
) {
  return useQuery(languageQueryKeys.all, getEddyProjectLanguages, {
    ...opts,
  });
}

export function useSupportedLanguages() {
  return useEddyProjectSupportedLanguages<Language[]>({
    select: supportedLanguageSelector,
  });
}
