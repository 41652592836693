import { Spacer } from '@sparemin/blockhead';
import styled from 'styled-components';
import { InputMenuContainerStyleProps } from './types';

export const InputMenuContainer = styled(
  Spacer,
).attrs<InputMenuContainerStyleProps>({
  forwardedAs: 'form',
  align: 'center',
  onMouseUp: (e: MouseEvent) => e.stopPropagation(),
})`
  width: ${({ $width = '350px' }) =>
    typeof $width === 'number' ? `${$width}px` : $width};
`;
