import { Heading, Spacer } from '@sparemin/blockhead';
import React from 'react';
import * as S from './styles';

type MediaUploaderBodyContainerProps = {
  className?: string;
  children: React.ReactNode;
};

const MediaUploaderBodyContainer: React.FC<MediaUploaderBodyContainerProps> = ({
  className,
  children,
}) => (
  <S.Container
    align="center"
    orientation="vertical"
    space={3}
    className={className}
  >
    <Heading level={3} align="center" letterSpacing="normal">
      Drag & drop your file, or browse...
    </Heading>
    <Spacer space={2}>{children}</Spacer>
  </S.Container>
);

export default MediaUploaderBodyContainer;
