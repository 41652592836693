import { HeadyGradientOutline, Spacer } from '@sparemin/blockhead';
import AppHeader from 'components/AppHeader';
import paths from 'paths';
import * as S from './styles';

export interface HeaderProps {}

const Header: React.FC<HeaderProps> = () => (
  <AppHeader>
    <S.HeaderLink href={paths.dashboard.value}>
      <HeadyGradientOutline height="30px" width="30px" />
    </S.HeaderLink>

    <AppHeader.Item align="center" as={Spacer} pull="right" space={2}>
      <AppHeader.HelpMenu />
    </AppHeader.Item>
  </AppHeader>
);

export default Header;
