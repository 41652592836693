import { useSuggestionsStatus } from './useClipSuggestionGroup';
import { useProjectStatus } from './useEditorProject';

export function useEditorLoadingStatus() {
  const { data: projectStatus, isError: isProjectError } = useProjectStatus();
  const { data: suggestionsStatus } = useSuggestionsStatus();

  if (!projectStatus) {
    return undefined;
  }

  // if the project has an error we can't proceed so return 'error'
  if (isProjectError || projectStatus === 'error') {
    return 'error';
  }

  // if either of the statuses are 'processing', the overall status is the same.
  // the suggestions will return `notFound` before the job has been kicked off, but
  // should eventually resolve - so in that case, return `processing`
  if (
    projectStatus === 'processing' ||
    suggestionsStatus === 'processing' ||
    suggestionsStatus === 'notFound'
  ) {
    return 'processing';
  }

  // at this point, projectStatus must be 'completed'.  suggestionStatus can still
  // be one of 'error', or 'completed'.
  //
  // if suggestions are not available for some reason ('error'),
  // just return 'completed' as suggestions aren't critical for the application
  // to operate.
  return 'completed';
}
