import {
  HeadySparkle,
  Spacer,
  breakpointDown,
  getThemeColor,
} from '@sparemin/blockhead';
import styled from 'styled-components';
import { WordHighlightStyleProps } from './types';

export const Root = styled(Spacer).attrs({
  orientation: 'horizontal',
  align: 'center',
  space: 2,
})`
  margin-bottom: 48px;

  ${breakpointDown('md')`
    margin-bottom: 16px;

    & > :not(:first-child) {
      margin-left: 0;
    }
  `}
`;

export const Icon = styled(HeadySparkle)`
  width: 98px;
  height: 80px;
  position: relative;
  bottom: 6px;

  ${breakpointDown('md')`
    display: none;
  `}
`;

export const WordHighlight = styled.span<WordHighlightStyleProps>`
  font-weight: bold;
  color: ${({ $color }) => getThemeColor($color)};
`;
