import React from 'react';
import * as S from './styles';
import useProjectLoaderFreeTierAnimation from './useProjectLoaderFreeTierAnimation';

export interface EnjoyClipsMessageProps {}

const EnjoyClipsMessage = React.forwardRef<HTMLElement, EnjoyClipsMessageProps>(
  (_, ref) => {
    const { enjoyClipsMessage } = useProjectLoaderFreeTierAnimation();

    return (
      <S.EnjoyClipsMessage ref={ref} {...enjoyClipsMessage}>
        Enjoy these clips while you wait!
      </S.EnjoyClipsMessage>
    );
  },
);

export default EnjoyClipsMessage;
