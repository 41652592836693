import { UserPreferences } from 'api/services/headlinerUser';

export const isAutoChaptersEnabledSelector = (d: UserPreferences) =>
  d.eddyPrefs?.isAutoChaptersEnabled?.default ?? true;

export const isAutoProjectNameEnabledSelector = (d: UserPreferences) =>
  d.eddyPrefs?.isAutoProjectNameEnabled?.default ?? true;

export const shouldRemoveFillerWordsSelector = (d: UserPreferences) =>
  d.eddyPrefs?.shouldRemoveFillerWords?.default ?? true;

export const isPodcastPersonalizationEnabledSelector = (d: UserPreferences) =>
  d.eddyPrefs?.isPodcastPersonalizationEnabled?.default ?? true;

export const eddyPrefsSelector = (d: UserPreferences) => {
  return {
    isAutoChaptersEnabled: isAutoChaptersEnabledSelector(d),
    isAutoProjectNameEnabled: isAutoProjectNameEnabledSelector(d),
    shouldRemoveFillerWords: shouldRemoveFillerWordsSelector(d),
    isPodcastPersonalizationEnabled: isPodcastPersonalizationEnabledSelector(d),
  };
};
