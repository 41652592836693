import { GetUserProfileResult } from 'api/services/user';
import { useAuth } from 'state/auth';
import useGetUserProfile, {
  UseGetUserProfileOptions,
} from './useGetUserProfile';

export default function useGetAuthenticatedUserProfile<
  TData = GetUserProfileResult,
>(opts?: UseGetUserProfileOptions<TData>) {
  const { userId } = useAuth();

  return useGetUserProfile<TData>(userId, opts);
}
