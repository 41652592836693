import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { CreateProjectResult } from 'api';
import notifyError from 'components/notification';
import paths from 'paths';
import { useAIOptionsActions } from 'state/aiOptions';
import { isEnglishDialect } from './utils';

export interface UseOnCreateProjectConfig {
  replace: boolean;
  shouldCheckAIOptions?: boolean;
}

export interface UseOnCreateProject {
  onSuccess: (result: CreateProjectResult, language?: string) => void;
  onError: () => void;
}

export default function useOnCreateProject({
  replace,
  shouldCheckAIOptions = true,
}: UseOnCreateProjectConfig): UseOnCreateProject {
  const { checkAIOptions } = useAIOptionsActions();
  const navigate = useNavigate();

  const handleSuccess = useCallback(
    (result: CreateProjectResult, language?: string) => {
      if (shouldCheckAIOptions && isEnglishDialect(language)) {
        checkAIOptions();
      }

      navigate(paths.editor.create(result.projectCuid), {
        replace: replace,
      });
    },
    [checkAIOptions, navigate, replace, shouldCheckAIOptions],
  );

  const handleError = useCallback(() => {
    notifyError({ heading: 'Error creating project' });

    navigate(paths.dashboard.value, { replace });
  }, [navigate, replace]);

  return { onSuccess: handleSuccess, onError: handleError };
}
