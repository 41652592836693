import {
  Container as BlockheadContainer,
  ButtonBase,
  Heading,
  Text,
  getThemeColor,
} from '@sparemin/blockhead';
import styled from 'styled-components';

export const Container = styled.div`
  text-align: left;
  width: 100%;
`;

export const CopyLabel = styled(Text)`
  margin-left: 8px;
`;

export const Header = styled.div`
  margin-bottom: 8px;
`;

export const SummaryContainer = styled(BlockheadContainer).attrs({
  borderWeight: 1,
  gutter: 0,
})`
  position: relative;
  padding: 16px;
  border-radius: 8px;
  overflow: hidden;
`;

export const Title = styled(Heading)`
  font-weight: bold;
  color: ${getThemeColor('d1')};
`;

export const Subtitle = styled(Text)`
  color: ${getThemeColor('d1')};
`;

export const SummaryText = styled(Text)`
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  letter-spacing: normal;
  color: ${getThemeColor('d1')};
`;

export const Paragraph = styled.p`
  margin: 0;
  min-height: 22px;
`;

export const Overlay = styled(ButtonBase).attrs({
  forwardedAs: 'div',
})`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: ${getThemeColor('l3')};
  opacity: 0;
  transition: opacity 300ms linear;
  color: ${getThemeColor('d1')};

  &[data-hovered='true'] {
    opacity: 0.9;
  }
`;
