import { Popover, PopoverProps } from '@sparemin/blockhead';
import React from 'react';
import InsertElementPopoverContents from './InsertElementPopoverContents';

export type InsertElementPopoverProps = Pick<
  PopoverProps,
  'isOpen' | 'triggerElement'
>;

const InsertElementPopover: React.FC<InsertElementPopoverProps> = ({
  isOpen,
  triggerElement,
}) => (
  <Popover {...{ isOpen, triggerElement }} placement="bottom" size="large">
    <InsertElementPopoverContents />
  </Popover>
);

export default InsertElementPopover;
