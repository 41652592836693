import {
  ProgressRing,
  ShareIcon,
  Spacer,
  SocialButton,
  getThemeColor,
  FileDropzone,
  breakpointDown,
  Mic,
} from '@sparemin/blockhead';
import styled from 'styled-components';

type IconWrapperStyleProps = {
  $backgroundColor: Parameters<typeof getThemeColor>[0];
};

export const Root = styled(FileDropzone)`
  ${breakpointDown('md')`
    padding: 40px 23px 40px 24px;
  `}
`;

export const DropzoneBody = styled(Spacer)`
  color: ${getThemeColor('d1')};
`;

export const UploadButton = styled(SocialButton)`
  min-width: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding-right: 24px;

  & > div {
    height: 16px;
    position: relative;
    left: 0;
  }

  ${breakpointDown('md')`
    width: 50px;
    height: 50px;
    padding: 14px 17px;
  `}
`;

export const ButtonText = styled.span`
  ${breakpointDown('md')`
    display: none;
  `}
`;

export const IconWrapper = styled(Spacer).attrs({
  align: 'center',
  justify: 'center',
})<IconWrapperStyleProps>`
  height: 28px;
  width: 28px;
  position: relative;
  bottom: 6px;
  border-radius: 50%;
  background-color: ${({ $backgroundColor = 'white' }) =>
    getThemeColor($backgroundColor)};
`;

export const IntegratorIcon = styled(ShareIcon)`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  color: ${getThemeColor('d5')};

  ${breakpointDown('md')`
    width: 28px;
    height: 28px;
  `}
`;

export const PodcastSearchIcon = styled(Mic)`
  color: ${getThemeColor('white')};
  height: 16px;
  width: 10px;
`;

export const Progress = styled(ProgressRing)`
  height: 50px;
  width: 50px;
`;
