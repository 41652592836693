import { useHover } from '@sparemin/blockhead';
import { useCallback, useEffect } from 'react';
import usePrevious from 'hooks/usePrevious';
import { usePlaybackState } from 'pages/TranscriptEditorPage/hooks';
import useIsSuggestionActive from 'pages/TranscriptEditorPage/hooks/useIsSuggestionActive';
import { useContextMenuIsOpen } from 'pages/TranscriptEditorPage/state/contextMenu';
import { useIsExplorerActive } from 'pages/TranscriptEditorPage/state/explorerBar';
import { useClipSuggestionContext } from 'pages/TranscriptEditorPage/Transcript/SpeakerSegment/SuggestionContainer/ClipSuggestionContext';
import { useSuggestedClipTooltip } from './state';

export interface UseSuggestedClipTooltipOrchestratorConfig {}

export default function useSuggestedClipTooltipOrchestrator() {
  const { suggestionId } = useClipSuggestionContext();

  const isSuggestionActive = useIsSuggestionActive(suggestionId);
  const { closeTooltip, isOpen, openTooltip } = useSuggestedClipTooltip({
    suggestionId,
  });

  const isContextMenuOpen = useContextMenuIsOpen();
  const isClipsExplorerOpen = useIsExplorerActive('clips');
  const { paused } = usePlaybackState();

  const isDisabled =
    !isClipsExplorerOpen || isContextMenuOpen || !paused || !isSuggestionActive;
  const prevIsDisabled = usePrevious(isDisabled);

  // closeTooltip() is wrapped in this function to force not passing the `immediate`
  // argument.  If the `closeTooltip` function reference were passed directly to and
  // event handler, the event object would be passed as the first argument and
  // becuase it's truthy, would cause close to happen immediately
  const handleHoverEnd = useCallback(() => {
    closeTooltip();
  }, [closeTooltip]);

  const { isHovered, hoverProps } = useHover({
    onHoverStart: openTooltip,
    onHoverEnd: handleHoverEnd,
  });

  useEffect(() => {
    if (!isDisabled && prevIsDisabled && isHovered) {
      openTooltip();
    } else if (isDisabled && !prevIsDisabled) {
      closeTooltip(true);
    }
  }, [closeTooltip, isDisabled, isHovered, openTooltip, prevIsDisabled]);

  return {
    isOpen,
    triggerProps: isDisabled ? undefined : hoverProps,
    tooltipProps: isDisabled
      ? undefined
      : {
          onHoverEnd: handleHoverEnd,
          onHoverStart: openTooltip,
        },
  };
}
