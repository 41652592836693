import {
  ErrorToast as BlockheadErrorToast,
  ErrorToastProps as BlockheadErrorToastProps,
} from '@sparemin/blockhead';
import React, { useEffect } from 'react';
import { ErrorCode, useEventTracking } from 'state/eventTracking';
import { DEFAULT_ERROR_MESSAGE } from './constants';

export type ErrorToastProps = Omit<BlockheadErrorToastProps, 'heading'> & {
  heading?: string;
  errorCode?: ErrorCode;
};

const ErrorToast: React.FC<ErrorToastProps> = ({
  heading = DEFAULT_ERROR_MESSAGE,
  subHeading,
  errorCode,
}) => {
  const { trackErrorNotification } = useEventTracking();

  useEffect(() => {
    if (errorCode) {
      trackErrorNotification(errorCode);
    }
  }, [trackErrorNotification, errorCode]);

  return <BlockheadErrorToast heading={heading} subHeading={subHeading} />;
};
export default ErrorToast;
