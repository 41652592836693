import { Waveform } from 'api';
import { peaksSelector } from './selectors';
import { useProjectWaveformUrl } from './useEditorProject';
import useWaveformData from './useWaveformData';

export type UseEditorWaveformSelector<TData = Waveform> = (
  waveform: Waveform,
) => TData;

export default function useEditorWaveform<TData = Waveform>(
  select?: UseEditorWaveformSelector<TData>,
) {
  const { data: waveformUrl } = useProjectWaveformUrl();
  return useWaveformData<TData>(waveformUrl, { select });
}

export const useEditorWaveformData = () => useEditorWaveform(peaksSelector);
