import { useCallback } from 'react';
import { Query, useQuery } from 'react-query';
import {
  getMyPlanSubscription,
  PlanTier,
  UserPlanSubscription,
} from 'api/services/plan';
import { DEFAULT_POLLING_INTERVAL_MILLIS } from 'config';
import { useAuth } from 'state/auth';
import { QueryOptions } from 'types';
import { subscriptionQueryKeys } from './queryKeys';
import {
  isBasicOrFreeTierSelector,
  isFreeTierSelector,
  planMaxUploadValuesSelector,
  planNameSelector,
} from './selectors';

type QueryKey = ReturnType<typeof subscriptionQueryKeys.mySubscription>;

export interface UsePricingOptions<TData = UserPlanSubscription>
  extends QueryOptions<UserPlanSubscription, TData, QueryKey> {
  pollingInterval?: number;
  waitForTier?: PlanTier;
}

function usePlanSubscription<TData = UserPlanSubscription>(
  opts?: UsePricingOptions<TData>,
) {
  const {
    enabled = true,
    pollingInterval = DEFAULT_POLLING_INTERVAL_MILLIS,
    waitForTier,
  } = opts ?? {};
  const { userId } = useAuth();

  const refetchInterval = useCallback(
    (
      _: TData | undefined,
      query: Query<
        UserPlanSubscription,
        unknown,
        UserPlanSubscription,
        QueryKey
      >,
    ) => {
      const tier = query.state.data?.subscribedProduct.tier;
      return tier !== waitForTier ? pollingInterval : false;
    },
    [pollingInterval, waitForTier],
  );

  return useQuery(
    subscriptionQueryKeys.mySubscription(userId),
    useCallback(() => getMyPlanSubscription(), []),
    {
      ...opts,
      enabled: !!userId && enabled,
      refetchInterval: waitForTier !== undefined ? refetchInterval : undefined,
    },
  );
}

export const useIsFreeTier = () =>
  usePlanSubscription({ select: isFreeTierSelector });

export const useIsBasicOrFreeTier = () =>
  usePlanSubscription({ select: isBasicOrFreeTierSelector });

export const usePlanName = () =>
  usePlanSubscription({ select: planNameSelector });

export const usePollForNewTier = (tier?: PlanTier) =>
  usePlanSubscription({
    enabled: !!tier,
    waitForTier: tier,
  });

export const usePlanUploadMaxValues = () =>
  usePlanSubscription({ select: planMaxUploadValuesSelector });
