import { useCallback } from 'react';
import { useMutation } from 'react-query';
import { CreateProjectResult, ProviderProjectMedia, createProject } from 'api';
import { MutationOptions } from 'types';

export interface CreateProjectFromIntegratorArgs {
  name: string;
  providerProjectMedia: ProviderProjectMedia;
}

export default function useCreateProjectFromIntegrator(
  opts?: MutationOptions<CreateProjectResult, CreateProjectFromIntegratorArgs>,
) {
  const mutationFn = useCallback(
    async ({ name, providerProjectMedia }: CreateProjectFromIntegratorArgs) => {
      if (!name) {
        throw new Error(
          'Configuration is missing project name. Cannot create project',
        );
      }

      const project = await createProject({
        associatedMedias: [providerProjectMedia],
        name,
      });

      return project;
    },
    [],
  );

  return useMutation<
    CreateProjectResult,
    unknown,
    CreateProjectFromIntegratorArgs,
    unknown
  >(mutationFn, opts);
}
