import { PopoverMenu } from '@sparemin/blockhead';
import styled from 'styled-components';
import { CONTEXT_MENU_CONTAINER_CLASSNAME } from './constants';

export const Menu = styled(PopoverMenu).attrs({
  className: CONTEXT_MENU_CONTAINER_CLASSNAME,
})`
  display: flex;
  pointer-events: auto;
  border-radius: 16px;
  border-width: 2px;
`;
