import { Spacer } from '@sparemin/blockhead';
import styled from 'styled-components';
import BasePage from 'components/Page';

export const Page = styled(BasePage)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Contents = styled(Spacer).attrs({
  orientation: 'vertical',
  align: 'center',
  space: '30px',
})`
  width: 500px;
  text-align: center;
`;
