import { Dialog } from '@sparemin/blockhead';
import styled from 'styled-components';

export const CloseButtonContainer = styled.div`
  position: absolute;
  top: 56px;
  right: 56px;
  z-index: 10;
`;

export const ModalWrapper = styled(Dialog)`
  position: relative;
  padding: 40px;

  &:focus-visible {
    outline: none;
  }
`;
