import { ProjectTranscript } from 'api/services';
import { WordsDictionary } from './types';

export default function createWordDictionary(transcript: ProjectTranscript) {
  const { segments } = transcript;
  const result: WordsDictionary = {};

  let globalWordIndex = 0;

  for (let segIndex = 0; segIndex < segments.length; segIndex += 1) {
    const { id, words } = segments[segIndex];

    for (let wordIndex = 0; wordIndex < words.length; wordIndex += 1) {
      const word = words[wordIndex];

      result[word.id] = {
        ...word,
        index: globalWordIndex,
        segmentId: id,
      };

      globalWordIndex += 1;
    }
  }

  return result;
}
