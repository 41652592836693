import React from 'react';
import ConnectedModal from 'components/ConnectedModal';
import IntroOutroMediaUploadModalContents from './IntroOutroMediaUploadModalContents';

export interface IntroOutroMediaUploadModalProps {}

const IntroOutroMediaUploadModal: React.FC<
  IntroOutroMediaUploadModalProps
> = () => (
  <ConnectedModal name="IntroOutroMediaUpload">
    <IntroOutroMediaUploadModalContents />
  </ConnectedModal>
);

export default IntroOutroMediaUploadModal;
