import styled from 'styled-components';

export interface HeaderItemProps {
  /**
   * This prop allows the element to sit flush against another element.  It
   * effectively removes the padding/spacing on the side specified by the prop
   */
  flush?: 'right' | 'left' | 'both';
  /**
   * Different header elements need different padding values because....well, I
   * have no idea...
   */
  padding?: string;
  /**
   * Aligns the element to the right or left side of the header
   */
  pull?: 'right' | 'left';
}

const HeaderItem = styled.div<HeaderItemProps>`
  padding: ${({ padding = '20px' }) => padding};

  ${({ pull }) => {
    if (!pull) {
      return undefined;
    }

    return pull === 'left' ? 'margin-right: auto' : 'margin-left: auto';
  }};

  ${({ flush }) => {
    if (!flush) {
      return undefined;
    }

    return flush === 'left' ? 'padding-left: 0' : 'padding-right: 0';
  }};
`;

export default HeaderItem;
