import { HotkeyStore } from './types';
import { createHotkeyIdentifier } from './utils';

export const actionsSelector = (state: HotkeyStore) => state.actions;

export const bindingsSelector = (state: HotkeyStore) => state.bindings;

export const keyHandlerSelector = (
  event: KeyboardEvent,
  state: HotkeyStore,
) => {
  const bindings = bindingsSelector(state);
  const hotkey = createHotkeyIdentifier(event);
  const handlers = bindings[hotkey] ?? [];
  return handlers[0];
};
