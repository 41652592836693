import { PromoPackImageType } from 'api';
import { AspectRatioName } from 'types';
import { RegenerationOptionsListItem } from '../StructuredAdjustmentsBar/types';

export const aspectRatioFeedbackOptions: RegenerationOptionsListItem<AspectRatioName>[] =
  [
    {
      label: '🖼️ Square (1:1)',
      value: 'square',
    },
    {
      label: '🏞️ Landscape (16:9)',
      value: 'landscape',
    },
    {
      label: '📱 Portrait (9:16)',
      value: 'portrait',
    },
  ];

export const imageTypeFeedbackOptions: RegenerationOptionsListItem<
  PromoPackImageType | ''
>[] = [
  {
    label: '🎨 None specified',
    value: '',
  },
  {
    label: '🎥 Cinematic',
    value: 'stabilityCinematic',
  },
  {
    label: '👾 Digital art',
    value: 'stabilityDigitalArt',
  },
  {
    label: '🧱 Isometric',
    value: 'stabilityIsometric',
  },
  {
    label: '📷 Photographic',
    value: 'stabilityPhotographic',
  },
];
