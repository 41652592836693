import { Heading, Spacer } from '@sparemin/blockhead';
import { AlertIcon } from 'components/UpsellModal/UpsellModalContents/UploadLimitsErrorContents/styles';
import * as S from './styles';

export interface FailedGenerationMessageProps {}

const FailedGenerationMessage: React.FC<FailedGenerationMessageProps> = () => {
  return (
    <S.Root>
      <AlertIcon width="100px" height="100px" />

      <Spacer orientation="vertical" align="center" space="13px">
        <Heading level={1}>Sorry, something went wrong</Heading>

        <Heading level={2}>
          We encountered an error while trying to generate this asset.
        </Heading>
      </Spacer>
    </S.Root>
  );
};

export default FailedGenerationMessage;
