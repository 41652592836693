import { useQuery } from 'react-query';
import { getTraceById, UrlGeneratorTrace } from 'api';
import { QueryOptions } from 'types';
import { urlTraceQueryKeys } from './queryKeys';

type QueryKey = ReturnType<typeof urlTraceQueryKeys.trace>;

export type UseUrlTraceOptions<TData = UrlGeneratorTrace> = QueryOptions<
  UrlGeneratorTrace,
  TData,
  QueryKey
>;

export default function useUrlTrace<TData = UrlGeneratorTrace>(
  traceId?: string,
  opts?: UseUrlTraceOptions<TData>,
) {
  const { enabled = true } = opts ?? {};

  return useQuery(
    urlTraceQueryKeys.trace(traceId),
    ({ queryKey: [{ traceId: id }] }) => getTraceById(id as string),
    { ...opts, enabled: !!traceId && enabled },
  );
}
