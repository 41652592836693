import { createBreakpoints, defaultTheme } from '@sparemin/blockhead';
import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  ...defaultTheme,
  backgroundColorMain: '#fafafb',
  footerFadeHeight: 120,
  headerHeightDesktop: 70,
  breakpoints: createBreakpoints({
    ...defaultTheme.breakpoints.values,
    md: 900,
  }),
};

export default theme;
