import { MotionProps } from 'framer-motion';
import { useState } from 'react';
import useIsMobileViewport from 'hooks/useIsMobileViewport';
import {
  DEFAULT_ANIMATION_DURATION_SECS,
  DEFAULT_FADE_OUT_DURATION_SECS,
  LAST_ANIMATION_DELAY_SECS,
  TRANSITION_DURATION_SECS,
} from '../transition';
import { EnjoyClipsMessageVariant } from './types';

export default function useProjectLoaderFreeTierAnimation() {
  const isMobile = useIsMobileViewport();
  const [enjoyClipsMessageVariant, setEnjoyClipsMessageVariant] =
    useState<EnjoyClipsMessageVariant>('fadeIn');

  const progressSection: MotionProps = {
    initial: {
      padding: '50px 0',
    },
    animate: {
      padding: '20px',
    },
    transition: {
      padding: {
        duration: DEFAULT_FADE_OUT_DURATION_SECS,
      },
      delay: DEFAULT_ANIMATION_DURATION_SECS + DEFAULT_FADE_OUT_DURATION_SECS,
    },
  };

  const progressRing: MotionProps = {
    style: {
      width: '100%',
      position: 'relative',
    },
    initial: {
      width: 200,
      height: 200,
      left: 0,
      marginLeft: 0,
      marginTop: 50,
    },
    animate: {
      width: 50,
      height: 50,
      left: '-50%',
      marginLeft: 50,
      marginTop: 0,
    },
    transition: {
      width: {
        duration: TRANSITION_DURATION_SECS,
      },
      height: {
        duration: TRANSITION_DURATION_SECS,
      },
      left: {
        delay: LAST_ANIMATION_DELAY_SECS,
        duration: TRANSITION_DURATION_SECS,
      },
      marginLeft: {
        delay: LAST_ANIMATION_DELAY_SECS,
        duration: TRANSITION_DURATION_SECS,
      },
      marginTop: {
        duration: TRANSITION_DURATION_SECS,
      },
      delay: DEFAULT_ANIMATION_DURATION_SECS,
    },
  };

  const progressMessage: MotionProps = {
    initial: {
      opacity: DEFAULT_ANIMATION_DURATION_SECS,
    },
    animate: {
      height: 0,
      opacity: 0,
    },
    transition: {
      height: {
        delay: DEFAULT_ANIMATION_DURATION_SECS + DEFAULT_FADE_OUT_DURATION_SECS,
      },
      opacity: {
        duration: DEFAULT_FADE_OUT_DURATION_SECS,
      },
      delay: DEFAULT_ANIMATION_DURATION_SECS,
    },
  };

  const promoteMessage: MotionProps = {
    initial: {
      x: '100%',
      opacity: 0,
    },
    animate: {
      x: 0,
      opacity: DEFAULT_ANIMATION_DURATION_SECS,
    },
    transition: {
      x: {
        duration: TRANSITION_DURATION_SECS,
      },
      opacity: {
        duration: TRANSITION_DURATION_SECS,
      },
      delay: LAST_ANIMATION_DELAY_SECS,
    },
  };

  const enjoyClipsMessage: MotionProps = {
    initial: {
      opacity: 0,
      marginTop: 200,
    },
    variants: {
      fadeIn: {
        opacity: 1,
        marginTop: 0,
        transition: {
          delay: DEFAULT_ANIMATION_DURATION_SECS,
          duration: TRANSITION_DURATION_SECS,
        },
      },
      fadeOut: {
        opacity: 0,
        marginTop: 0,
        transition: {
          delay: DEFAULT_ANIMATION_DURATION_SECS,
          duration: DEFAULT_FADE_OUT_DURATION_SECS,
        },
      },
    },
    animate: enjoyClipsMessageVariant,
    onAnimationComplete: (completedVariant: EnjoyClipsMessageVariant) => {
      if (completedVariant === 'fadeIn') {
        setEnjoyClipsMessageVariant('fadeOut');
      }
    },
  };

  return !isMobile
    ? {}
    : {
        progressSection,
        progressRing,
        progressMessage,
        promoteMessage,
        enjoyClipsMessage,
      };
}
