import ConnectedModal from 'components/ConnectedModal';
import PromoPackModalContents from './PromoPackModalContents';

export interface PromoPackModalProps {}

const PromoPackModal: React.FC<PromoPackModalProps> = () => {
  return (
    <ConnectedModal name="PromoPack">
      <PromoPackModalContents />
    </ConnectedModal>
  );
};

export default PromoPackModal;
