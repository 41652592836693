import styled, { DefaultTheme, StyledComponent } from 'styled-components';
import AccountMenu from './AccountMenu';
import HeaderItem from './HeaderItem';
import HelpMenu from './HelpMenu';
import LogoItem from './LogoItem';

type AppHeaderComponent = StyledComponent<'div', DefaultTheme> & {
  AccountMenu: typeof AccountMenu;
  HelpMenu: typeof HelpMenu;
  Item: typeof HeaderItem;
  LogoItem: typeof LogoItem;
};

/**
 * A fixed-position header that remains in-view as the page scrolls.  Content on
 * the page needs to account for the header and can do so by using `theme.headerHeight*`
 * or the `AppBody` component.
 *
 * The AppHeader exports subcomponents that can be used to build the header.
 * Components using the "item" designation in their name include padding and / or
 * margins to create space between themselves and other components.  Components without
 * "item" in their name should be wrapped in another "item" component.
 */
const AppHeader = styled.div`
  display: flex;
  // setting a height isn't strictly necessary, however other components might need to
  // know the height of the header in order to account for it in the layout
  height: ${({ theme }) => theme.headerHeightDesktop}px;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1;

  background-image: ${({ theme }) =>
    `linear-gradient(
      to bottom,
      ${theme.backgroundColorMain},
      ${theme.backgroundColorMain} 35%,
      rgba(250, 250, 251, 0.5) 70%,
      rgba(250, 250, 251, 0)
    )`};
` as AppHeaderComponent;

AppHeader.AccountMenu = AccountMenu;
AppHeader.HelpMenu = HelpMenu;
AppHeader.Item = HeaderItem;
AppHeader.LogoItem = LogoItem;

export default AppHeader;
