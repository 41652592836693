import { partialRight } from 'lodash-es';
import { useCallback, useMemo, useRef } from 'react';
import { Query, useQuery } from 'react-query';
import { ClipSuggestionGroup, getClipSuggestions } from 'api';
import notifyError from 'components/notification';
import { DEFAULT_POLLING_INTERVAL_MILLIS } from 'config';
import { useAuth } from 'state/auth';
import { useIsClipSuggestionsEnabled } from 'state/displayPref';
import { QueryOptions, QueryPollingOptions } from 'types';
import { eddyTranscriptQueryKeys } from './queryKeys';
import {
  suggestionCountSelector,
  suggestionSelector,
  suggestionsSelector,
  suggestionsStatusSelector,
} from './selectors';
import { useProjectStatus } from './useEditorProject';
import {
  useIndexedClipSuggestions,
  useOrderedClipSuggestionIds,
  useTranscriptId,
} from './useEditorTranscript';

const DEFAULT_ABORT_IF_NOT_FOUND_AFTER_MILLIS = 5 * 60 * 1000; // Five minutes.

type QueryKey = ReturnType<typeof eddyTranscriptQueryKeys.clipSuggestions>;

export type UseClipSuggestionsOptions<TData = ClipSuggestionGroup> =
  QueryOptions<ClipSuggestionGroup, TData, QueryKey> &
    QueryPollingOptions & {
      abortIfNotFoundAfterMillis?: number;
    };

export default function useClipSuggestionGroup<TData = ClipSuggestionGroup>(
  opts?: UseClipSuggestionsOptions<TData>,
) {
  const {
    abortIfNotFoundAfterMillis = DEFAULT_ABORT_IF_NOT_FOUND_AFTER_MILLIS,
    enabled = true,
    pollingInterval = DEFAULT_POLLING_INTERVAL_MILLIS,
    waitForAssets,
  } = opts ?? {};

  const { userId } = useAuth();
  const { data: transcriptId } = useTranscriptId();
  const { data: projectStatus } = useProjectStatus();
  const { data: isClipSuggestionsEnabled } = useIsClipSuggestionsEnabled();
  const firstSeenNotFoundRef = useRef<number>();

  const refetchInterval = useCallback(
    (
      _: TData | undefined,
      query: Query<ClipSuggestionGroup, unknown, ClipSuggestionGroup, QueryKey>,
    ) => {
      const status = query.state.data?.status;

      const isProcessing = status === 'processing' || status === 'notFound';

      if (!firstSeenNotFoundRef.current && status === 'notFound') {
        firstSeenNotFoundRef.current = Date.now();
      }

      if (status !== 'notFound') {
        firstSeenNotFoundRef.current = undefined;
      }

      if (
        firstSeenNotFoundRef.current &&
        Date.now() - firstSeenNotFoundRef.current >= abortIfNotFoundAfterMillis
      ) {
        notifyError();

        return false;
      }

      return isProcessing ? pollingInterval : false;
    },
    [abortIfNotFoundAfterMillis, pollingInterval],
  );

  return useQuery(
    eddyTranscriptQueryKeys.clipSuggestions(userId, transcriptId),
    ({ queryKey: [{ transcriptId: id }] }) => getClipSuggestions(id as number),
    {
      ...opts,
      // from ticket:
      //  > if project is not finished (and we haven’t even kicked off the suggestion job)
      //  > the status will return notFound.
      // to avoid trying to figure out whether `notFound` means "job hasn't been submitted"
      // or that there's actually no suggestions, wait until the transcript is done before
      // polling for suggestions
      enabled:
        !!transcriptId &&
        projectStatus === 'completed' &&
        isClipSuggestionsEnabled &&
        enabled,
      meta: {
        errorMessage: 'Error loading clip suggestions',
      },
      refetchInterval: waitForAssets ? refetchInterval : undefined,
      staleTime: Infinity,
    },
  );
}

export const useSuggestionCount = () =>
  useClipSuggestionGroup({ select: suggestionCountSelector });

export const useSuggestionsStatus = () =>
  useClipSuggestionGroup({ select: suggestionsStatusSelector });

export const useClipSuggestions = () => {
  const { data: suggestions } = useClipSuggestionGroup({
    select: suggestionsSelector,
  });
  return useIndexedClipSuggestions(suggestions);
};

export const useClipSuggestionIds = () => {
  const { data: suggestions } = useClipSuggestionGroup({
    select: suggestionsSelector,
  });
  return useOrderedClipSuggestionIds(suggestions);
};

export const useClipSuggestion = (suggestionId: number) =>
  useClipSuggestionGroup({
    select: useMemo(
      () => partialRight(suggestionSelector, suggestionId),
      [suggestionId],
    ),
  });
