import * as S from './styles';
import { ArticleProps } from './types';

const Article: React.FC<ArticleProps> = ({
  children,
  className,
  width = '1080px',
}) => (
  <S.Root className={className} $width={width}>
    {children}
  </S.Root>
);

export default Article;
