import { BackArrowCircle } from '@sparemin/blockhead';
import * as S from './styles';

export interface BackButtonProps {
  onBack?: () => void;
}
const BackButton: React.FC<BackButtonProps> = ({ onBack }) => {
  return (
    <S.BackButton onPress={onBack}>
      <BackArrowCircle />
    </S.BackButton>
  );
};
export default BackButton;
