import { ColorPaletteState } from '@sparemin/blockhead';
import { isNil, omitBy } from 'lodash-es';
import { useCallback, useState } from 'react';
import { useQueryClient } from 'react-query';
import { PromoPackAssetType, RegenerateProjectAssetRequest } from 'api';
import notifyError from 'components/notification';
import { ASPECT_RATIOS_DIMENSIONS_BY_NAME } from 'config';
import {
  eddyPromoPacksQueryKeys,
  useTranscriptId,
  useUpdateColorPalette,
} from 'pages/TranscriptEditorPage/state';
import { usePollForProjectAssetRegenerationStatus } from 'pages/TranscriptEditorPage/state/useGetAssetRegenerationStatus';
import { usePromoPackAsset } from 'pages/TranscriptEditorPage/state/useGetPromoPackContents';
import useRegenerateProjectAsset from 'pages/TranscriptEditorPage/state/useRegenerateProjectAsset';
import { AdjustmentValues } from './types';
import useTrackRegenerationAIOutput from './useTrackRegenerationAIOutput';

export interface UseRegenerateAssetConfig {
  assetType?: PromoPackAssetType;
  adjustmentValues: AdjustmentValues;
  colorPaletteState: ColorPaletteState;
}

export default function useRegenerateAsset({
  adjustmentValues,
  assetType,
  colorPaletteState,
}: UseRegenerateAssetConfig) {
  // `isRegenerating` aims to represent the overall status of asset regeneration.
  // It starts as soon as the user clicks on the regenerate button and finishes
  // when the asset status equals `completed`.
  const [isRegenerating, setIsRegenerating] = useState(false);
  // `isProcessing` represents the asset status during the process. This helps determine
  // whether the regeneration process has completed or not. After the regeneration
  // process starts, we poll for the asset status (which is when this value changes).
  // When the status is `completed`, that means that the regeneration process has finished.
  const [isProcessing, setIsProcessing] = useState(false);

  const { mutateAsync: regenerateProjectAsset } = useRegenerateProjectAsset();
  const { mutateAsync: updateColorPalette } = useUpdateColorPalette();
  const { data: transcriptId } = useTranscriptId();
  const { data: asset } = usePromoPackAsset(assetType);
  const { trackRegenerationAIOutput } = useTrackRegenerationAIOutput();
  const queryClient = useQueryClient();

  usePollForProjectAssetRegenerationStatus(asset?.regenerationJobId);

  const handleRegenerateProjectAsset = useCallback(async (): Promise<void> => {
    if (!assetType) {
      return;
    }

    setIsRegenerating(true);

    try {
      if (assetType === 'episodeArt') {
        await updateColorPalette({
          colors: colorPaletteState.colors('hex'),
        });
      }

      const { aspectRatio } = adjustmentValues;

      await regenerateProjectAsset(
        omitBy(
          {
            ...adjustmentValues,
            assetType,
            ...(assetType === 'episodeArt' &&
              aspectRatio && {
                imageDimensionWidth:
                  ASPECT_RATIOS_DIMENSIONS_BY_NAME[aspectRatio].width,
                imageDimensionHeight:
                  ASPECT_RATIOS_DIMENSIONS_BY_NAME[aspectRatio].height,
              }),
          },
          (value) =>
            isNil(value) || (typeof value === 'string' && !value.length),
        ) as RegenerateProjectAssetRequest,
      );

      trackRegenerationAIOutput({
        asset,
        adjustmentValues,
        colorPaletteState,
      });

      queryClient.invalidateQueries(
        eddyPromoPacksQueryKeys.promoPackContents(transcriptId),
      );
    } catch {
      notifyError();
    }
  }, [
    adjustmentValues,
    asset,
    assetType,
    colorPaletteState,
    queryClient,
    regenerateProjectAsset,
    trackRegenerationAIOutput,
    transcriptId,
    updateColorPalette,
  ]);

  if (!isProcessing && asset?.status === 'processing') {
    setIsProcessing(true);
  }

  if (isProcessing && asset?.status === 'completed') {
    setIsRegenerating(false);
    setIsProcessing(false);
  }

  if (!isRegenerating && asset?.isRegenerating) {
    setIsRegenerating(true);
  }

  return {
    isRegenerating,
    onRegenerate: handleRegenerateProjectAsset,
  };
}
