import { omitBy } from 'lodash-es';
import { useCallback, useState } from 'react';
import { useEventTracking } from 'state/eventTracking';
import { useModalsActions } from 'state/modal';
import {
  CreateChapterArgs,
  UpdateChapterArgs,
  useEditTranscript,
} from '../state';

export interface UseChapterInfoModalConfig {
  chapterId?: number;
  newChapterStartWordId?: number;
}

export interface UseChapterInfoModalResult {
  isSaving: boolean;
  onSave: (props: Omit<UpdateChapterArgs, 'chapterId'>) => void;
  onDelete: () => void;
}

export default function useChapterInfoModal({
  chapterId,
  newChapterStartWordId,
}: UseChapterInfoModalConfig): UseChapterInfoModalResult {
  // The saving status is tracked separately to ensure no actions can be performed
  // in the modal once the user hits the save button. This prevents premature
  // interactions by keeping the save state true until the modal is fully closed,
  // avoiding issues where the query status might become false before the modal
  // closure, allowing unintended actions.
  const [isSaving, setIsSaving] = useState(false);

  const { createChapter, updateChapter } = useEditTranscript();
  const { pushModal, hideModal } = useModalsActions();
  const { trackSaveChangesToChapter } = useEventTracking();

  const handleCreateChapter = useCallback(
    ({ title, url, imageUrl }: Omit<UpdateChapterArgs, 'chapterId'>): void => {
      if (!newChapterStartWordId) {
        return;
      }

      setIsSaving(true);

      createChapter(
        omitBy(
          {
            newChapterStartWordId,
            newChapterTitle: title,
            newChapterUrl: url,
            newChapterImageUrl: imageUrl,
          },
          (value) => typeof value === 'string' && !value.length,
        ) as CreateChapterArgs,
        {
          onChapterCreated: hideModal,
        },
      );
    },
    [createChapter, newChapterStartWordId, hideModal],
  );

  const handleUpdateChapter = useCallback(
    (props: Omit<UpdateChapterArgs, 'chapterId'>): void => {
      if (!chapterId) {
        return;
      }

      setIsSaving(true);

      updateChapter(
        omitBy(
          { chapterId, ...props },
          (value) => typeof value === 'string' && !value.length,
        ) as UpdateChapterArgs,
      );
      hideModal();
    },
    [chapterId, hideModal, updateChapter],
  );

  const handleSaveChanges = useCallback(
    (props: Omit<UpdateChapterArgs, 'chapterId'>): void => {
      trackSaveChangesToChapter({
        linkAdded: Boolean(props.url),
        artAdded: Boolean(props.imageUrl),
      });

      if (chapterId) {
        return handleUpdateChapter(props);
      }

      return handleCreateChapter(props);
    },
    [
      chapterId,
      handleCreateChapter,
      handleUpdateChapter,
      trackSaveChangesToChapter,
    ],
  );

  const handleOpenDeleteChapterModal = useCallback((): void => {
    if (!chapterId) {
      return;
    }

    pushModal({
      name: 'DeleteChapter',
      params: {
        chapterId,
        onCancel: () => {
          pushModal({
            name: 'ChapterInfo',
            params: {
              chapterId,
            },
          });
        },
      },
    });
  }, [chapterId, pushModal]);

  return {
    isSaving,
    onSave: handleSaveChanges,
    onDelete: handleOpenDeleteChapterModal,
  };
}
