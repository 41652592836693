import { first, last } from 'lodash-es';
import IdentifiableCommand from './IdentifiableCommand';
import { AsyncUndoableCommand, AsyncUndoableExecutor } from './types';

type CommitAction = 'commit' | 'uncommit';

type CommitOperation = [CommitAction, IdentifiableCommand];

export default class TranscriptOperationExecutor
  implements AsyncUndoableExecutor
{
  private undoStack: IdentifiableCommand[] = [];

  private redoStack: IdentifiableCommand[] = [];

  private commitQueue: Array<CommitOperation> = [];

  private isCommitting = false;

  /**
   * Iterates through the commit queue, removing each pending commit operation along
   * withs its corresponding command in the undo/redo queue.
   *
   * This is used to roll back state in the event of an error.  If 10 commands are
   * executed, there will be 10 commits waiting to be executed.  If the 6th commit
   * fails, it will query the API for the latest transcript state, which will show
   * that only 5 operations have been committed.  In terms of the application state,
   * that means that operations 5-10 have not been commited and are no longer reflected
   * in the UI (they are reverted by way of the commit operation querying for the
   * transcript state on failure).  Since those operations were rolled back in the UI,
   * their corresponding commits must be removed from the commit queue.
   */
  private drainCommitQueue() {
    while (this.commitQueue.length > 0) {
      const [operation, command] = this.commitQueue.pop() as CommitOperation;

      if (operation === 'commit' && last(this.undoStack) === command) {
        this.undoStack.pop();
      } else if (operation === 'uncommit' && last(this.redoStack) === command) {
        this.redoStack.pop();
      }
    }
  }

  private async commitNextCommand() {
    if (this.isCommitting) {
      return;
    }

    const commitOperation = first(this.commitQueue);

    if (commitOperation) {
      const [operation, command] = commitOperation;

      try {
        this.isCommitting = true;
        await command.command[operation]();
        this.commitQueue.shift();
        this.isCommitting = false;
        this.commitNextCommand();
      } catch {
        this.isCommitting = false;
        this.drainCommitQueue();
      }
    }
  }

  executeCommand(command: AsyncUndoableCommand): number {
    const identifiableCommand = new IdentifiableCommand(command);

    this.undoStack.push(identifiableCommand);
    this.redoStack = [];
    this.commitQueue.push(['commit', identifiableCommand]);

    identifiableCommand.command.execute();
    this.commitNextCommand();

    return identifiableCommand.id;
  }

  undo(): number {
    const command = this.undoStack.pop();

    if (command) {
      this.redoStack.push(command);
      this.commitQueue.push(['uncommit', command]);

      command.command.unexecute();
      this.commitNextCommand();

      return command.id;
    }

    return -1;
  }

  redo(): number {
    const command = this.redoStack.pop();

    if (command) {
      this.undoStack.push(command);
      this.commitQueue.push(['commit', command]);

      command.command.execute();
      this.commitNextCommand();

      return command.id;
    }

    return -1;
  }
}
