import { useMergedRefs } from '@sparemin/blockhead';
import React, { useEffect, useRef, KeyboardEvent, useCallback } from 'react';
import { useUncontrolledProp } from 'uncontrollable';
import SuggestionsPopover from 'components/EditableInput/SuggestionsPopover';
import { useClickOutside } from 'hooks/useClickOutside';
import * as S from './styles';
import { EditableInputProps } from './types';

export interface ModalComponent
  extends React.ForwardRefExoticComponent<
    EditableInputProps & React.RefAttributes<HTMLTextAreaElement>
  > {
  SuggestionsPopoverComponent: typeof SuggestionsPopover;
}

const EditableInput = React.forwardRef<
  HTMLTextAreaElement | null,
  EditableInputProps
>((props, ref) => {
  const {
    autoFocus,
    className,
    defaultIsEditable,
    isEditable: isEditableProp,
    onCancel,
    onChange,
    onEdit,
    onIsEditableChange: onIsEditableChangeProp,
    onSelectSuggestion,
    onSubmit,
    renderSuggestionsPopover,
    value,
    clickOutsideIgnoreSelector,
  } = props;

  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const mergedRefs = useMergedRefs([ref, inputRef]);

  const [isEditable, onIsEditableChange] = useUncontrolledProp<boolean>(
    isEditableProp,
    defaultIsEditable,
    onIsEditableChangeProp,
  );

  const hideInputElement = useCallback(() => {
    onIsEditableChange(false);
  }, [onIsEditableChange]);

  const showInputElement = useCallback(() => {
    onIsEditableChange(true);
  }, [onIsEditableChange]);

  useEffect(() => {
    if (isEditable) {
      inputRef.current?.select();
    }
  }, [isEditable, ref]);

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Escape' && isEditable) {
      onCancel();
      hideInputElement();
    }
    if (e.key === 'Enter') {
      onSubmit();
      hideInputElement();
    }
  };

  const handleBlur = () => {
    onSubmit();
    hideInputElement();
  };

  useClickOutside(containerRef, handleBlur, {
    ignoreSelector: clickOutsideIgnoreSelector,
  });

  const handlePress = useCallback(() => {
    showInputElement();
    onEdit();
  }, [onEdit, showInputElement]);

  const handleSelectSuggestion = useCallback(
    (suggestion: string) => {
      hideInputElement();
      onSelectSuggestion?.(suggestion);
    },
    [hideInputElement, onSelectSuggestion],
  );

  useEffect(() => {
    if (autoFocus) {
      showInputElement();
    }
  }, [autoFocus, showInputElement]);

  return (
    <>
      <div ref={containerRef}>
        {isEditable ? (
          <S.TextArea
            aria-label={props['aria-label']}
            autoResize
            borderless
            value={value}
            className={className}
            onChange={onChange}
            onKeyDown={handleKeyPress}
            ref={mergedRefs}
          />
        ) : (
          <S.Button aria-label="project title" onPress={handlePress}>
            {value}
          </S.Button>
        )}
      </div>
      {renderSuggestionsPopover?.({
        enabled: !!isEditable,
        onSelectSuggestion: handleSelectSuggestion,
        triggerElementRef: containerRef,
      })}
    </>
  );
});

const CompoundEditableInput = Object.assign(EditableInput, {
  SuggestionsPopover: SuggestionsPopover,
});

export default CompoundEditableInput;
