import { PulseLoader } from '@sparemin/blockhead';

import FailedGenerationMessage from '../FailedGenerationMessage';

import { TOP_BAR_TRANSITION_DURATION_SEC } from './constants';
import * as S from './styles';

export interface BaseTabPanelContentsProps {
  topBarHeightWithPadding: number;
  isLoading: boolean;
  isError: boolean;
  isDisabled: boolean;
  children: React.ReactNode;
}

const BaseTabPanelContents: React.FC<BaseTabPanelContentsProps> = ({
  topBarHeightWithPadding,
  isLoading,
  isError,
  isDisabled,
  children,
}) => {
  return (
    <S.BaseTabPanelContentsRoot
      layout="position"
      transition={{ duration: TOP_BAR_TRANSITION_DURATION_SEC }}
      $top={topBarHeightWithPadding}
    >
      <S.ContentsWrapper>
        {isLoading && <PulseLoader />}

        {isError && <FailedGenerationMessage />}

        {!isDisabled && children}
      </S.ContentsWrapper>
    </S.BaseTabPanelContentsRoot>
  );
};

export default BaseTabPanelContents;
