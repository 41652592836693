import { Download } from '@sparemin/blockhead';
import { useCallback } from 'react';
import {
  useProjectCuid,
  useProjectName,
  useProjectMediaType,
} from 'pages/TranscriptEditorPage/state';
import { useEventTracking } from 'state/eventTracking';
import { useModalsActions } from 'state/modal';
import { useWordsContextMenuConfig } from '../../../state/contextMenu';
import ActionButton from '../../ActionButton';

export interface DownloadClipActionButtonProps {}

const DownloadClipActionButton: React.FC<
  DownloadClipActionButtonProps
> = () => {
  const config = useWordsContextMenuConfig();
  const { data: projectCuid } = useProjectCuid();
  const { data: projectName } = useProjectName();
  const { data: projectType } = useProjectMediaType();
  const { pushModal } = useModalsActions();
  const { trackHighlightPopperClicked } = useEventTracking();

  const handleDownload = useCallback(() => {
    if (!projectCuid || !projectName || !projectType || !config) {
      return;
    }
    trackHighlightPopperClicked({ buttonClick: 'Download' });
    const { words } = config;

    pushModal({
      name: 'ShareAudio',
      params: {
        words,
        projectType,
      },
    });
  }, [
    config,
    projectCuid,
    projectName,
    pushModal,
    trackHighlightPopperClicked,
    projectType,
  ]);

  return (
    <ActionButton
      aria-label="download clip"
      onPress={handleDownload}
      tooltip="Download clip"
    >
      <ActionButton.Icon as={Download} />
    </ActionButton>
  );
};

export default DownloadClipActionButton;
