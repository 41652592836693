import { Text, UnstyledButton, getThemeColor } from '@sparemin/blockhead';
import styled from 'styled-components';

export const TextContainer = styled.div`
  width: 850px;
  padding: 78px 80px;
  border-radius: 24px;
  background-color: ${getThemeColor('white')};
`;

export const TextContent = styled(Text).attrs({
  variant: 'p',
})`
  margin: 0;
  text-align: start;
  font-size: 16px;
  font-weight: 500;
  line-height: 2;
  color: ${getThemeColor('black')};
`;

export const Paragraph = styled(UnstyledButton).attrs({
  as: 'p',
})`
  min-height: 22px;
  margin: 0;

  a {
    font-weight: bold;
    text-decoration: none;
    transition: ${({ theme }) => theme.transitions.create(['color'])};

    &,
    &:visited {
      color: ${getThemeColor('actionPrimary')};
    }

    &:hover {
      cursor: pointer;
      color: ${getThemeColor('actionPrimary', 'dark')};
    }
  }
`;
