import { TranscriptFormat } from 'api/services/transcript';
import { TranscriptFormatItem } from './types';

export const transcriptFormatList: TranscriptFormatItem[] = [
  {
    label: 'TXT',
    value: 'txt',
  },
  {
    label: 'PDF',
    value: 'pdf',
  },
  {
    label: 'Doc',
    value: 'docx',
  },
  {
    label: 'VTT',
    value: 'vtt',
  },
  {
    label: 'SRT',
    value: 'srt',
  },
];

export const YOUTUBE_FORMATTING_UNSUPPORTED_EXPORT_FORMATS: TranscriptFormat[] =
  ['pdf', 'docx'];

export const TIMESTAMPS_SUPPORTED_EXPORT_FORMATS: TranscriptFormat[] = [
  'pdf',
  'docx',
];
