import styled, { css } from 'styled-components';
import { whileOpen } from 'components/ExplorerBar';
import BaseSearchBar from '../../SearchBar';
import SuggestedClipsExplorer from '../../SuggestedClipsExplorer';
import {
  TranscriptExplorerBarStyleProps,
  TranscriptSearchBarStyleProps,
} from './types';

export const ELEMENT_SPACING_PX = 8 / 2;
export const EXPLORER_BAR_BREAKPOINT = '1040px';

const animationStyle = css<TranscriptExplorerBarStyleProps>`
  opacity: ${({ $isHidden }) => ($isHidden ? 0 : 1)};
  transition-property: all;
  z-index: 1;

  // when the component is going from hidden to unhidden, it should be delayed
  // a bit so that the other component is almost completely closed before it
  // fades back in, otherwise it will fade in on top of the component that is
  // closing
  ${({ $isHidden }) =>
    !$isHidden &&
    `
    transition-property: all, opacity;
    transition-delay: 0ms, 100ms;
  `}

  ${whileOpen`
    z-index: 2;
  `}
`;

export const TranscriptExplorerBar = styled.div`
  display: flex;
  height: 50px;
  justify-content: center;
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 10px;
  width: 100vw;
  z-index: 1;

  @media (max-width: ${EXPLORER_BAR_BREAKPOINT}) {
    padding: 0 10px;
  }
`;

export const Container = styled.div`
  position: relative;
  width: 700px;

  > * {
    pointer-events: auto;
  }

  @media (max-width: ${EXPLORER_BAR_BREAKPOINT}) {
    width: 100%;
  }
`;

const searchOnlyStyle = css`
  right: 50%;
  transform: translateX(50%);

  ${whileOpen`
    right: 0;
    transform: translateX(0);
  `}
`;

const searchWithClipsStyle = css`
  right: calc(50% + ${ELEMENT_SPACING_PX}px);

  ${whileOpen`
    right: 0;
  `}
`;

export const SearchBar = styled(BaseSearchBar)<TranscriptSearchBarStyleProps>`
  position: absolute;
  ${animationStyle}

  ${({ $hasClips }) => ($hasClips ? searchWithClipsStyle : searchOnlyStyle)}
`;

export const ClipsExplorer = styled(
  SuggestedClipsExplorer,
)<TranscriptExplorerBarStyleProps>`
  // add one px here to account for the border and keep the gap consistent
  left: calc(50% + ${ELEMENT_SPACING_PX}px + 1px);
  position: absolute;

  ${animationStyle};

  ${whileOpen`
    left: 0;
  `}
`;
