import { Button } from '@sparemin/blockhead';
import { useEditorTranscript, useProjectCuid } from '../../state';
import useOpenExportModal from './useOpenExportModal';

export interface ExportProjectButtonProps {
  isDisabled?: boolean;
}

const ExportProjectButton: React.FC<ExportProjectButtonProps> = ({
  isDisabled,
}) => {
  const { isLoading: projectIsLoading } = useProjectCuid();
  const { isLoading: transcriptIsLoading } = useEditorTranscript();
  const { canOpenExportModal, openExportModal } = useOpenExportModal();

  const shareButtonDisabled = projectIsLoading || transcriptIsLoading;

  return (
    <Button
      isDisabled={isDisabled ?? (shareButtonDisabled || !canOpenExportModal)}
      onPress={openExportModal}
    >
      export
    </Button>
  );
};

export default ExportProjectButton;
