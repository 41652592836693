import { selectElements } from 'utils/selection';
import { getFirstUndeletedWord, isWordDeleted } from '../.././Transcript/utils';
import { useAudioPlayer } from '../../AudioPlayerContext';
import { useWordList, useWordsById } from '../../state';
import {
  isWordsContextMenuConfig,
  useContextMenuActions,
  useContextMenuSubscription,
} from '../../state/contextMenu';
import { useActiveWordId } from '../../state/editorUi';
import { getWordElement, seekToWord } from '../../utils';

export default function useAfterContextAction() {
  const { player } = useAudioPlayer();

  const { data: wordsById } = useWordsById();
  const { data: words } = useWordList();
  const activeWordId = useActiveWordId();

  const { closeContextMenu, openContextMenu } = useContextMenuActions();

  // this hook subscribes to the last successful action of the context menu so that
  // it can adjust the text selection and the item which the context menu is targeting
  useContextMenuSubscription((current, prev) => {
    if (
      current.lastSuccessfulAction &&
      current.lastSuccessfulAction !== prev.lastSuccessfulAction
    ) {
      const { type, config } = current.lastSuccessfulAction;

      if (type === 'delete-word' && isWordsContextMenuConfig(config)) {
        if (!words || !wordsById) return;

        const nextActiveWord = getFirstUndeletedWord(
          [config.words[0], config.words[config.words.length - 1]],
          words,
          wordsById,
        );

        const isActiveWordDeleted =
          !!activeWordId &&
          config.words.includes(activeWordId) &&
          !isWordDeleted(activeWordId, wordsById);

        if (!nextActiveWord) {
          closeContextMenu();
          return;
        }

        if (isActiveWordDeleted) {
          // if the active word was deleted, change the active word to the first undeleted
          // word behind the deleted one
          seekToWord(player, nextActiveWord?.startMillis);
        } else if (nextActiveWord.id !== activeWordId) {
          // if the active word wasn't deleted, don't move the active word, just set
          // the text selection to the first undeleted word behind the deleted one
          selectElements(getWordElement(nextActiveWord.id));
        } else {
          // clearSelection();
        }

        // regardless how how the previous word was selected/activated, we want to
        // reposition the context menu over it
        openContextMenu(getWordElement(nextActiveWord.id), {
          words: [nextActiveWord.id],
        });
      } else if (
        type === 'edit-speaker' ||
        type === 'add-segment' ||
        type === 'add-chapter'
      ) {
        closeContextMenu();
      }
    }
  });
}
