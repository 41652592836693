import { useQuery } from 'react-query';
import {
  Project,
  ProjectPublicAsset,
  getProjectPublicAsset,
} from 'api/services';
import { useAuth } from 'state/auth';
import { QueryOptions, QueryPollingOptions } from 'types';
import { eddyProjectsQueryKeys } from './queryKeys';
import { projectPublicAssetsUrlsSelector } from './selectors';
import { useProjectCuid } from './useEditorProject';

type QueryKey = ReturnType<typeof eddyProjectsQueryKeys.myProject>;

export type UseGetProjectPublicAssetOptions<TData = Project> = QueryOptions<
  ProjectPublicAsset,
  TData,
  QueryKey
> &
  QueryPollingOptions;

export default function useGetProjectPublicAsset<TData = ProjectPublicAsset>(
  opts?: UseGetProjectPublicAssetOptions<TData>,
) {
  const { data: cuid } = useProjectCuid();
  const { userId } = useAuth();

  return useQuery<ProjectPublicAsset, unknown, TData, QueryKey>(
    eddyProjectsQueryKeys.publicAsset(userId, cuid),
    ({ queryKey: [{ projectCuid }] }) =>
      getProjectPublicAsset({
        projectCuid: projectCuid as string,
      }),
    {
      ...opts,
      enabled: !!cuid,
    },
  );
}

export const useProjectPublicAssetsUrls = () =>
  useGetProjectPublicAsset({
    select: projectPublicAssetsUrlsSelector,
  });
