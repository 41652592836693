import { intervalComparator } from 'utils/arrays';
import { EditedInterval } from './types';

export function editedIntervalComparator(
  interval: EditedInterval,
  millis: number,
) {
  return intervalComparator(
    {
      // we effectively want to skip deleted intervals because an "edited" time cannot
      // fall in a deleted interval. rather than removing them, adjust them during
      // the binary search.  if the start and end times are set to the value of
      // the interval end, then binary search will work as expected
      endMillis: interval.endMillis - interval.adjustmentMillis,
      startMillis: !interval.isDeleted
        ? interval.startMillis - interval.adjustmentMillis
        : interval.endMillis - interval.adjustmentMillis,
    },
    millis,
  );
}
