import axios from 'axios';
import {
  authHeaderRequestInterceptor,
  autoLogoutResponseInterceptor,
  tokenRenewalRequestInterceptor,
} from './interceptors';

let authHeaderInterceptorId: number;
let tokenRenewalInterceptorId: number;
let autoLogoutInterceptorId: number;

const axiosInstance = axios.create();

interface SetApiTokenConfig {
  token: string | undefined;
  onTokenRenewed: (token: string) => void;
}

export function setApiToken({ token, onTokenRenewed }: SetApiTokenConfig) {
  [authHeaderInterceptorId, tokenRenewalInterceptorId].forEach(
    (interceptor) => {
      if (interceptor !== undefined) {
        axiosInstance.interceptors.request.eject(interceptor);
      }
    },
  );

  tokenRenewalInterceptorId = axiosInstance.interceptors.request.use(
    ...tokenRenewalRequestInterceptor(onTokenRenewed),
  );

  authHeaderInterceptorId = axiosInstance.interceptors.request.use(
    ...authHeaderRequestInterceptor(token),
  );
}

export function configure(logout: () => void) {
  if (autoLogoutInterceptorId !== undefined) {
    axiosInstance.interceptors.response.eject(autoLogoutInterceptorId);
  }
  autoLogoutInterceptorId = axiosInstance.interceptors.response.use(
    ...autoLogoutResponseInterceptor(logout),
  );
}

export { axiosInstance as request };
