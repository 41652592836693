import React from 'react';

import CreateAudiogramModal from 'components/CreateAudiogramModal';
import UpsellModal from 'components/UpsellModal/UpsellModal';

const Modals: React.FunctionComponent = () => {
  return (
    <>
      {/* Place any application-wide ConnectedModal here. Modals available on
          specific pages only can be placed either here on at the root of that page
       */}
      <CreateAudiogramModal />
      <UpsellModal />
    </>
  );
};

export default Modals;
