import { useStaticCallback } from '@sparemin/blockhead';
import { useEffect } from 'react';

export interface UseEventListenerConfig<TTarget = undefined> {
  disabled?: boolean;
  options?: AddEventListenerOptions | boolean;
  target?: TTarget;
}

function useEventListener<
  TEvent extends keyof HTMLElementEventMap,
  TTarget extends HTMLElement = HTMLElement,
>(
  event: TEvent,
  handler: (event: HTMLElementEventMap[TEvent]) => void,
  config?: UseEventListenerConfig<TTarget>,
): void;
function useEventListener<TEvent extends keyof DocumentEventMap>(
  event: TEvent,
  handler: (event: DocumentEventMap[TEvent]) => void,
  config?: UseEventListenerConfig<Document>,
): void;
function useEventListener<TEvent extends keyof WindowEventMap>(
  event: TEvent,
  handler: (event: WindowEventMap[TEvent]) => void,
  config?: UseEventListenerConfig,
): void;
function useEventListener<
  TEE extends keyof HTMLElementEventMap,
  TDE extends keyof DocumentEventMap,
  TWE extends keyof WindowEventMap,
  T extends HTMLElement | Document | Window = Window,
>(
  event: TEE | TDE | TWE,
  handler: (
    e:
      | HTMLElementEventMap[TEE]
      | DocumentEventMap[TDE]
      | WindowEventMap[TWE]
      | Event,
  ) => void,
  config?: UseEventListenerConfig<T>,
): void {
  const { disabled = false, options, target } = config ?? {};
  const eventTarget: T | Window = target ?? window;

  const staticHandler = useStaticCallback(handler);

  useEffect(() => {
    if (!disabled) {
      const handleEvent = (
        e:
          | HTMLElementEventMap[TEE]
          | DocumentEventMap[TDE]
          | WindowEventMap[TWE]
          | Event,
      ) => {
        staticHandler(e);
      };

      eventTarget.addEventListener(
        event,
        handleEvent as EventListener,
        options as AddEventListenerOptions,
      );

      return () => {
        eventTarget.removeEventListener(
          event,
          handleEvent as EventListener,
          options as AddEventListenerOptions,
        );
      };
    }
  }, [disabled, event, options, staticHandler, eventTarget]);
}

export { useEventListener };
