import qs from 'query-string';
import { AUTH_REDIRECT_QUERY_PARAM } from 'config/constants';

export function getRedirectPath(search: string, fallback: string): string {
  const query = qs.parse(search);
  const path = query[AUTH_REDIRECT_QUERY_PARAM];
  const resolvedPath = Array.isArray(path) ? path[0] : path;

  return resolvedPath ?? fallback;
}
