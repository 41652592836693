import { produce } from 'immer';
import { property, sumBy } from 'lodash-es';
import { InfiniteData, useMutation, useQueryClient } from 'react-query';
import { ProjectListResult, deleteProject, getMyProjects } from 'api/services';
import notifyError from 'components/notification';
import { eddyProjectsQueryKeys } from 'pages/TranscriptEditorPage/state';
import { useAuth } from 'state/auth';
import { findPageIndexByProjectCuid } from '../utils';
import useGetMyProjects from './useGetMyProjects';

interface ProjectUpdateContext {
  previousProjects: InfiniteData<ProjectListResult> | undefined;
  pageIndex: number;
}

export default function useDeleteProject() {
  const queryClient = useQueryClient();
  const { userId } = useAuth();

  const { hasNextPage, data } = useGetMyProjects({
    enabled: false,
  });

  return useMutation<void, unknown, string, ProjectUpdateContext>({
    mutationFn: deleteProject,
    onMutate: async (cuid: string) => {
      await queryClient.cancelQueries({
        queryKey: eddyProjectsQueryKeys.myProjects(userId),
      });

      const previousProjects: InfiniteData<ProjectListResult> | undefined =
        queryClient.getQueryData(eddyProjectsQueryKeys.myProjects(userId));

      if (!previousProjects) {
        return;
      }

      const pageIndex = findPageIndexByProjectCuid(
        cuid,
        previousProjects.pages,
      );

      queryClient.setQueryData(
        eddyProjectsQueryKeys.myProjects(userId),
        produce(previousProjects, (draft) => {
          const projects = draft.pages[pageIndex].projects;
          const projectIndex = projects.findIndex(
            (p) => p.projectCuid === cuid,
          );

          projects.splice(projectIndex, 1);
        }),
      );

      return { previousProjects, pageIndex };
    },

    // When a project is deleted, a record will be skipped when the next page is
    // fetched if the page number and page size are not modified.
    // Imagine you fetched 10 pages, the next record to fetch is the first one on the 11th page.
    // If the user deletes a record, it’s as if every record above “moves up” by 1 to fill its
    // place - so from the backend’s perspective, the first record on page 11 is now the last
    // record on page 10 and when we fetch page 11, it will be missing that record.
    // onSettled is being used to resolve this issue and not cause any weird behavior for the
    // way that the project list is rendered.
    onSettled: async () => {
      // If all pages were fetched, there's no need to search
      // for a missing record as everything is already in the list.
      if (!hasNextPage) {
        return;
      }

      // Getting the previously saved projects list.
      const previousProjects: InfiniteData<ProjectListResult> | undefined =
        queryClient.getQueryData(eddyProjectsQueryKeys.myProjects(userId));

      if (!previousProjects) {
        return;
      }

      const fetchedPagesAmount = data?.pages?.length || 0;
      const pageSize = sumBy(data?.pages, property('page.size'));

      // Firing a one-off query with page size set to 1 and the
      // page number set to the default page size times the amount of
      // pages already fetched which returns the missing record.
      const missingRecord = await getMyProjects({
        page: pageSize,
        size: 1,
      });

      // Adding the missing record back to the list.
      queryClient.setQueryData(
        eddyProjectsQueryKeys.myProjects(userId),
        produce(previousProjects, (draft) => {
          draft.pages[
            fetchedPagesAmount ? fetchedPagesAmount - 1 : 0
          ].projects.push(missingRecord.projects[0]);
        }),
      );
    },

    onError: (_err, _variables, context) => {
      queryClient.setQueryData(
        eddyProjectsQueryKeys.myProjects(userId),
        context?.previousProjects,
      );

      notifyError({ heading: 'Error deleting project' });
    },
  });
}
