import {
  AlertCircle,
  Heading,
  Spacer,
  breakpointDown,
} from '@sparemin/blockhead';
import styled from 'styled-components';
import BaseModal from 'components/Modal';
import BasePlate from 'components/Plate';

export const Root = styled(BaseModal)`
  width: 570px;
  text-align: start;

  ${breakpointDown('md')`
    width: 100%;
  `}
`;

export const Plate = styled(BasePlate)`
  width: 100%;
  position: relative;
  padding: 24px;
  border-radius: 24px;
`;

export const DisclaimerIcon = styled(AlertCircle)`
  min-width: 40px;
  min-height: 40px;
  color: ${({ theme }) => theme.palette.s3};
`;

export const URLDisplay = styled(Spacer).attrs({
  orientation: 'vertical',
  align: 'flex-start',
  space: '8px',
})`
  width: 100%;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  overflow: hidden;
`;

export const URLPlate = styled(Plate)`
  height: 72px;
`;

export const URL = styled(Heading).attrs({
  level: 2,
})`
  width: 100%;
  white-space: nowrap;
  font-weight: bold;
  line-height: 1.71;
  overflow: hidden;
`;

export const CopyButtonContainer = styled.div`
  width: 116px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
  background-image: ${({ theme }) => `
    linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      ${theme.palette.common.white} 15%,
      ${theme.palette.common.white} 100%
    );
  `};
`;
