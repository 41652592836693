import { breakpointDown, Heading } from '@sparemin/blockhead';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { slide, TransitionProps } from 'styles';
import ProjectLoaderTakeoverView from '../ProjectLoaderTakeoverView';
import {
  DEFAULT_ANIMATION_DURATION_SECS,
  DEFAULT_FADE_OUT_DURATION_SECS,
  LOADER_HANGTIME_MILLIS,
  TOTAL_LOADER_TRANSITION_TIME_MILLIS,
  TRANSITION_DURATION_MILLIS,
} from '../transition';
import DiscoSection from './DiscoSection';
import BaseProgressSection from './ProgressSection';

export const Root = styled(ProjectLoaderTakeoverView)`
  height: 100vh;
  overflow: hidden auto;
`;

export const ProgressSection = styled(
  motion(BaseProgressSection),
)<TransitionProps>`
  ${slide({
    delayMillis: LOADER_HANGTIME_MILLIS,
    durationMillis: TRANSITION_DURATION_MILLIS,
    startPosition: { y: 'calc(100vh / 2 - 50%)' },
    endPosition: { y: '0' },
  })}

  ${breakpointDown('md')`
    ${slide({
      delayMillis: DEFAULT_ANIMATION_DURATION_SECS * 1000,
      durationMillis: TRANSITION_DURATION_MILLIS,
      startPosition: { y: 'calc(100vh / 2 - 50%)' },
      endPosition: { y: '0' },
    })}
  `}
`;

export const EnjoyClipsMessage = styled(motion(Heading)).attrs({
  level: 3,
})<TransitionProps>`
  width: 100%;
  position: fixed;
  top: 50%;
  padding: 0 20px;
  text-align: center;
`;

export const Disco = styled(DiscoSection)<TransitionProps>`
  ${slide({
    delayMillis: LOADER_HANGTIME_MILLIS,
    durationMillis: TRANSITION_DURATION_MILLIS,
    startPosition: { y: '100%' },
    endPosition: { y: '0' },
  })}

  ${breakpointDown('md')`
    ${slide({
      delayMillis:
        TOTAL_LOADER_TRANSITION_TIME_MILLIS + DEFAULT_FADE_OUT_DURATION_SECS,
      durationMillis: TRANSITION_DURATION_MILLIS,
      startPosition: { y: '100%' },
      endPosition: { y: '0' },
    })}
  `}
`;
