import { CardContents, CardFooter, CardProps } from '@sparemin/blockhead';
import React from 'react';
import useIsMobileViewport from 'hooks/useIsMobileViewport';
import BackButton from './BackButton';
import ModalHeader from './ModalHeader';
import ModalIcon from './ModalIcon';
import * as S from './styles';

const ModalBody = CardContents;
const ModalContainer = S.ModalContainer;
const ModalFooter = CardFooter;

export interface ModalProps extends Pick<CardProps, 'footer'> {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  icon?: React.ReactNode;
  fullScreen?: boolean;
  /* Allow you to customize how much space there is in pixels
   * between the edge of the modal and the edge of the viewport.
   */
  gutter?: number;
  showBackButton?: boolean;
  onBack?: () => void;
}

export interface ModalComponent extends React.FC<ModalProps> {
  Container: typeof ModalContainer;
  Header: typeof ModalHeader;
  Icon: typeof ModalIcon;
  Footer: typeof ModalFooter;
  Body: typeof ModalBody;
}

const Modal: ModalComponent = ({
  children,
  style,
  className,
  footer,
  title,
  subtitle,
  icon,
  fullScreen,
  gutter,
  showBackButton = false,
  onBack,
}) => {
  const isMobile = useIsMobileViewport();

  return (
    <ModalContainer
      {...{ style, className, footer }}
      fullScreen={fullScreen || isMobile}
      header={<ModalHeader title={title} subtitle={subtitle} icon={icon} />}
      $gutter={gutter}
    >
      {showBackButton && <BackButton onBack={onBack} />}
      {children}
    </ModalContainer>
  );
};

Modal.Body = ModalBody;
Modal.Container = ModalContainer;
Modal.Header = ModalHeader;
Modal.Footer = ModalFooter;
Modal.Icon = ModalIcon;

export default Modal;
