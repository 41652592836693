import { TranscriptWord } from 'api/services';
import { WordsDictionary } from '../state';

export function getPreviousUndeletedWord(
  wordId: number,
  words: TranscriptWord[],
  wordsById: WordsDictionary,
) {
  const targetWordIndex = wordsById[wordId].index;

  // TODO SPAR-19568:  flagging as potential performance bottleneck. If it becomes problematic,
  // consider the following fixes:
  //  - store words by id for fast lookup (include index so we can jump to that
  //    point in the words array and begin iterating)
  //  - add metadata to Word elements (data-* properties) to make searching easier
  //  - lookup word by current time to take advantage of bin search
  for (let i = targetWordIndex - 1; i >= 0; i -= 1) {
    const word = words[i];
    if (!word.isDeleted) {
      return word;
    }
  }

  return undefined;
}

export function getNextUndeletedWord(
  wordId: number,
  words: TranscriptWord[],
  wordsById: WordsDictionary,
) {
  const targetWordIndex = wordsById[wordId].index;

  for (let i = targetWordIndex + 1; i < words.length; i += 1) {
    const word = words[i];
    if (!word.isDeleted) {
      return word;
    }
  }

  return undefined;
}

export function getFirstUndeletedWord(
  wordId: number | [number, number],
  words: TranscriptWord[],
  wordsById: WordsDictionary,
) {
  const [firstWordId, lastWordId] = Array.isArray(wordId)
    ? wordId
    : [wordId, wordId];
  return (
    getPreviousUndeletedWord(firstWordId, words, wordsById) ??
    getNextUndeletedWord(lastWordId, words, wordsById)
  );
}

export function getFirstDeletedWord(
  wordIds: number[],
  wordsById: WordsDictionary | undefined,
) {
  if (!wordsById) {
    return undefined;
  }

  const wordId = wordIds.find((id) => wordsById?.[id].isDeleted);

  return !wordId ? undefined : wordsById?.[wordId];
}

export function isWordDeleted(
  wordId: number,
  wordsById: WordsDictionary | undefined,
) {
  return !!wordsById?.[wordId].isDeleted;
}
