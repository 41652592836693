import { motion } from 'framer-motion';
import styled from 'styled-components';
import { TAB_PANEL_MAX_WIDTH_PX } from '../BaseTabPanel/constants';

export const Root = styled(motion.div)`
  width: 100%;
  max-width: ${TAB_PANEL_MAX_WIDTH_PX}px;
  position: sticky;
  top: 0;
  z-index: 1;
`;

export const Contents = styled(motion.div)`
  width: 100%;
  position: relative;
  top: 0;
`;
