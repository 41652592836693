import ConnectedModal from 'components/ConnectedModal';
import { useModalParams } from 'state/modal';
import IntegratorExportModalContents from './IntegratorExportModalContents';

const MODAL_NAME = 'IntegratorExport';

export interface IntegratorExportModalProps {}

const IntegratorExportModal: React.FC<IntegratorExportModalProps> = () => {
  const params = useModalParams(MODAL_NAME);

  return (
    <ConnectedModal name={MODAL_NAME}>
      {params && <IntegratorExportModalContents {...params} />}
    </ConnectedModal>
  );
};

export default IntegratorExportModal;
