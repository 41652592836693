import { useEffect, useRef } from 'react';
import useSearchInTranscript from 'pages/TranscriptEditorPage/hooks/useSearchInTranscript';
import { useShouldRemoveFillerWordsEnabled } from 'state/userPref';
import { queryStatusResolver } from 'state/utils';
import { FILLER_WORDS } from '../../constants';
import useDeleteWords from '../useDeleteWords';
import { useIsFillerWordsRemoved, useProjectStatus } from '../useEditorProject';
import useEditorTranscript from '../useEditorTranscript';

export default function useRemoveFillerWords(onSuccess: () => void) {
  const { data: projectStatus } = useProjectStatus();
  const { status: editorTranscriptStatus } = useEditorTranscript();
  const { data: isFillerWordsRemoved, status: isFillerWordsRemovedStatus } =
    useIsFillerWordsRemoved();
  const {
    data: shouldRemoveFillerWords,
    status: shouldRemoveFillerWordsStatus,
  } = useShouldRemoveFillerWordsEnabled();

  const { mutateAsync: removeFillerWords } = useDeleteWords();
  const { searchInTranscript } = useSearchInTranscript();

  const isRemoved = useRef(false);

  const fetchStatus = queryStatusResolver([
    shouldRemoveFillerWordsStatus,
    isFillerWordsRemovedStatus,
    editorTranscriptStatus,
  ]);

  useEffect(() => {
    const execute = async () => {
      if (
        isRemoved.current ||
        projectStatus !== 'completed' ||
        fetchStatus !== 'success'
      ) {
        return;
      }

      isRemoved.current = true;

      if (shouldRemoveFillerWords && !isFillerWordsRemoved) {
        try {
          const wordIds = searchInTranscript(FILLER_WORDS).flat();

          if (!wordIds.length) {
            return;
          }

          await removeFillerWords({ wordIds, isFillerWordsRemoval: true });
        } finally {
          onSuccess();
        }
      } else {
        onSuccess();
      }
    };

    execute();
  }, [
    fetchStatus,
    isFillerWordsRemoved,
    onSuccess,
    projectStatus,
    removeFillerWords,
    searchInTranscript,
    shouldRemoveFillerWords,
  ]);
}
