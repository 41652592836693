import React from 'react';
import Helmet from 'components/Helmet';
import { FaviconProps as FaviconHelmetProps } from './types';
import { resolveIconConfig } from './utils';

const FaviconHelmet: React.FC<FaviconHelmetProps> = (props) => {
  const { largeIcon, smallIcon } = resolveIconConfig(props);

  return (
    <Helmet>
      <link
        id="favicon-large"
        rel="icon"
        sizes="32x32"
        type={largeIcon.type}
        href={largeIcon.url}
      />
      <link
        id="favicon-small"
        rel="icon"
        sizes="16x16"
        type={smallIcon.type}
        href={smallIcon.url}
      />
    </Helmet>
  );
};

export default FaviconHelmet;
