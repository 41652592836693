import React from 'react';
import {
  ENABLE_GOOGLE_DRIVE_INTEGRATOR,
  ENABLE_ZOOM_INTEGRATOR,
  ENABLE_YOUTUBE_INTEGRATOR,
} from 'config';

import MediaUploaderBodyContainer from './MediaUploaderBodyContainer';
import { Option, UploadOptions } from './types';
import {
  ComputerUploadButton,
  PodcastSearchUploadButton,
  GoogleDriveUploadButton,
  ZoomUploadButton,
  YouTubeUploadButton,
} from './UploadButton';

interface DefaultMediaUploaderBodyProps {
  className?: string;
  sources?: UploadOptions;
  onSourceClick?: (source: Option) => void;
}

const DefaultMediaUploaderBody: React.FC<DefaultMediaUploaderBodyProps> = ({
  className,
  sources = [
    Option.COMPUTER,
    Option.ZOOM,
    Option.PODCAST_SEARCH,
    Option.GOOGLE,
    Option.YOUTUBE,
  ],
  onSourceClick,
}) => {
  return (
    <MediaUploaderBodyContainer className={className}>
      {sources.includes(Option.COMPUTER) && <ComputerUploadButton />}
      {sources.includes(Option.PODCAST_SEARCH) && (
        <PodcastSearchUploadButton
          onPress={() => onSourceClick?.(Option.PODCAST_SEARCH)}
        />
      )}
      {ENABLE_GOOGLE_DRIVE_INTEGRATOR && sources.includes(Option.GOOGLE) && (
        <GoogleDriveUploadButton
          onPress={() => onSourceClick?.(Option.GOOGLE)}
        />
      )}
      {ENABLE_ZOOM_INTEGRATOR && sources.includes(Option.ZOOM) && (
        <ZoomUploadButton onPress={() => onSourceClick?.(Option.ZOOM)} />
      )}
      {ENABLE_YOUTUBE_INTEGRATOR && sources.includes(Option.YOUTUBE) && (
        <YouTubeUploadButton onPress={() => onSourceClick?.(Option.YOUTUBE)} />
      )}
    </MediaUploaderBodyContainer>
  );
};
export default DefaultMediaUploaderBody;
