export enum Option {
  ZOOM = 'zoom',
  GOOGLE = 'googleDrive',
  YOUTUBE = 'youtube',
  COMPUTER = 'computer',
  PODCAST_SEARCH = 'podcastSearch',
}

export type UploadOptions = Option[];

export interface FileInfo {
  sizeMb: number;
  durationSec: number;
}
