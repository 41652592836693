import { IconButtonProps } from '@sparemin/blockhead';
import React from 'react';
import Tooltip from '../../Tooltip';
import * as S from './styles';

export interface ActionButtonProps
  extends Pick<IconButtonProps, 'aria-label' | 'isDisabled' | 'onPress'> {
  children?: React.ReactNode;
  tooltip?: React.ReactNode;
}

const ForwardedActionButton = React.forwardRef<
  HTMLButtonElement,
  ActionButtonProps
>(({ children, tooltip, ...buttonProps }, ref) => {
  // note - button is wrapped in a div because the Tooltip component attaches
  // standard DOM callbacks like `onClick`, but using a react-aria button means
  // that we should prefer `onPress`.  `onClick` still works, but some of these
  // callback generate react-aria warnings in the console
  return (
    <Tooltip contents={tooltip} isDisabled={!tooltip} offset={0}>
      <div>
        <S.Button {...buttonProps} ref={ref}>
          {children}
        </S.Button>
      </div>
    </Tooltip>
  );
});

const ActionButton = Object.assign(ForwardedActionButton, {
  Icon: S.ActionButtonIcon,
});

export default ActionButton;
