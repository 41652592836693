import {
  ComboBox,
  Spacer,
  breakpointDown,
  getThemeColor,
} from '@sparemin/blockhead';
import styled from 'styled-components';
import { Menu } from '../styles';

const POPOVER_MOBILE_MARGIN = 12;

export const Root = styled(Menu)`
  width: 350px;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  gap: 10px;
  border-radius: 16px;
  border: 2px solid ${getThemeColor('l4')};

  ${breakpointDown('md')`
    /* Ensures that there is a margin on both sides of the popover. */
    width: calc(100% - ${POPOVER_MOBILE_MARGIN * 2}px);
    margin: 0 ${POPOVER_MOBILE_MARGIN}px;
  `}
`;

export const LoaderContainer = styled(Spacer).attrs({
  orientation: 'horizontal',
  align: 'center',
  justify: 'center',
})`
  width: 100%;
  height: 48px;
`;

export const CustomList = styled(ComboBox.Menu)`
  &::after {
    content: '';
    // Compensates for 14px of padding (6px for the list
    // and 8px for the list item) on each side.
    width: calc(100% - 28px);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    border-bottom: 1px solid ${getThemeColor('l4')};
  }
`;

export const SpeakerIcon = styled(Spacer).attrs({
  orientation: 'horizontal',
  align: 'center',
  justify: 'center',
})`
  width: 30px;
  height: 30px;
  padding: 7px 7px 7px 78x;
  border-radius: 50%;
  background-color: ${getThemeColor('actionSecondary')};
  color: ${getThemeColor('white')};
`;
