import { useRef } from 'react';
import useApplyNameSuggestion from './useApplyNameSuggestion';
import useRemoveFillerWords from './useRemoveFillerWords';

export interface UseApplyAIFeaturesConfig {
  onAIFeatureApplied: () => void;
}

export default function useApplyAIFeatures({
  onAIFeatureApplied,
}: UseApplyAIFeaturesConfig) {
  const successfulFeaturesRef = useRef(0);

  // Callback to be executed after each AI feature.
  const handleSuccess = () => {
    successfulFeaturesRef.current += 1;

    // If all features are applied executed.
    if (successfulFeaturesRef.current === aiFeatures.length) {
      onAIFeatureApplied();
    }
  };

  const aiFeatures = [
    useApplyNameSuggestion(handleSuccess),
    useRemoveFillerWords(handleSuccess),
  ];
}
