import { QueryStatus } from 'react-query';
import { SetOptional } from 'type-fest';
import { ModalProps } from 'components/Modal/Modal';
import { ExportFormat } from 'types';
import { EXPORT_MODAL_SUBTITLES_BY_STATUS } from './constants';
import * as S from './styles';
import { getModalTitleByStatus } from './utils';

export interface ExportModalContentsProps
  extends SetOptional<ModalProps, 'title' | 'subtitle'> {
  exportFormat?: ExportFormat;
  status: QueryStatus;
}

const ExportModalContents: React.FC<ExportModalContentsProps> = ({
  children,
  exportFormat,
  status,
  subtitle,
  title,
  ...modalProps
}) => {
  return (
    <S.Modal
      {...modalProps}
      subtitle={
        subtitle ?? (
          <S.Subtitle>{EXPORT_MODAL_SUBTITLES_BY_STATUS[status]}</S.Subtitle>
        )
      }
      title={title ?? getModalTitleByStatus(status, exportFormat)}
    >
      {children}
    </S.Modal>
  );
};

export default ExportModalContents;
