import { Heading, Spacer } from '@sparemin/blockhead';
import { PromoPackAsset } from 'api';
import { BaseTabPanelActionButton } from '../BaseTabPanel/types';
import { usePromoPackModal } from '../PromoPackModalContext';
import { actionsBarAnimationProps } from './animation';
import * as S from './styles';

export interface ActionsBarProps {
  asset: PromoPackAsset;
  actionButtons: BaseTabPanelActionButton[];
}

const ActionsBar: React.FC<ActionsBarProps> = ({ asset, actionButtons }) => {
  const { promoPackTabs } = usePromoPackModal();

  const tabConfig = promoPackTabs[asset.assetType];

  return (
    <S.Root {...actionsBarAnimationProps}>
      <S.Contents>
        <Spacer align="center" space="16px">
          <S.IconContainer>{tabConfig?.icon}</S.IconContainer>

          <Heading level={1}>{tabConfig?.title}</Heading>
        </Spacer>

        <Spacer align="center" space="16px">
          {actionButtons.map(
            ({ key, label, startIcon, isDisabled = false, onPress }) => (
              <S.ActionButton {...{ key, isDisabled, onPress }}>
                <Spacer align="center">
                  {startIcon} <span>{label}</span>
                </Spacer>
              </S.ActionButton>
            ),
          )}
        </Spacer>
      </S.Contents>
    </S.Root>
  );
};

export default ActionsBar;
