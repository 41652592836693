import { useEffect } from 'react';
import { useAudioPlayer } from '../AudioPlayerContext';

interface UseSyncVideoWithAudioOptions {
  videoRef: React.MutableRefObject<HTMLVideoElement | undefined>;
}

export default function useSyncVideoWithAudio({
  videoRef,
}: UseSyncVideoWithAudioOptions) {
  const { player } = useAudioPlayer();

  useEffect(() => {
    const handlePlay = () => {
      if (videoRef.current) {
        videoRef.current.play();
        videoRef.current.currentTime = player.currentTime;
      }
    };

    const handlePause = () => {
      if (videoRef.current) {
        videoRef.current.pause();
      }
    };

    const handleSeeking = () => {
      if (videoRef.current) {
        videoRef.current.currentTime = player.currentTime;
      }
    };

    player.on('play', handlePlay);
    player.on('pause', handlePause);
    player.on('seeking', handleSeeking);

    return () => {
      player.off('play', handlePlay);
      player.off('pause', handlePause);
      player.off('seeking', handleSeeking);
    };
  }, [player, videoRef]);
}
