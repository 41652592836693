import { useCallback, useEffect } from 'react';
import { WordsDictionary } from 'pages/TranscriptEditorPage/state';
import {
  ContextMenuConfig,
  isWordsContextMenuConfig,
  WordsContextMenuConfig,
} from 'pages/TranscriptEditorPage/state/contextMenu';
import { useEventTracking } from 'state/eventTracking';

export function useTrackWordContextMenu(
  wordsById: WordsDictionary | undefined,
  config: ContextMenuConfig | undefined,
) {
  const { trackHighlightPopperShown } = useEventTracking();

  const getSelectedWordsValue = useCallback(
    (wordConfig: WordsContextMenuConfig, words: WordsDictionary) => {
      const { deleted, undeleted } = wordConfig.words.reduce(
        (acc, id) => {
          const word = words[id];

          if (word.isDeleted) {
            return {
              ...acc,
              deleted: acc.deleted + 1,
            };
          } else {
            return {
              ...acc,
              undeleted: acc.undeleted + 1,
            };
          }
        },
        { deleted: 0, undeleted: 0 },
      );
      if (deleted + undeleted === 1) {
        if (deleted === 1) {
          return 'Deleted';
        }
        return 'SingleWord';
      }
      if (deleted === 0) {
        return 'MultipleWord';
      }
      if (undeleted === 0) {
        return 'Deleted';
      }
      return 'Mixed';
    },
    [],
  );

  useEffect(() => {
    if (config && isWordsContextMenuConfig(config) && wordsById) {
      const selectedValue = getSelectedWordsValue(config, wordsById);
      trackHighlightPopperShown({ selection: selectedValue });
    }
  }, [config, trackHighlightPopperShown, getSelectedWordsValue, wordsById]);
}
