import { FileRejectionReason } from '@sparemin/blockhead';
import { DEFAULT_ERROR_MESSAGE } from 'components/notification/constants';
import { ErrorToastProps } from 'components/notification/ErrorToast';

export const getErrorByReason = (
  maxUploadSizeMB: number,
  maxMediaDurationHr: number,
): Record<FileRejectionReason, ErrorToastProps> => ({
  WRONG_TYPE: {
    heading: 'Unfortunately, the uploaded file is not a supported file type',
    subHeading:
      'Please make sure the file format is one of the following: MP3, AAC, WEBM, WAV or QuickTime. If you encounter further issues, please contact our support team for further assistance.',
    errorCode: 'ER002',
  },
  TOO_LARGE: {
    heading: `Upload size exceeds ${maxUploadSizeMB}MB`,
    errorCode: 'ER011',
  },
  TOO_SMALL: {
    heading: DEFAULT_ERROR_MESSAGE,
  },
  TOO_LONG: {
    heading: `Upload length exceeds ${
      maxMediaDurationHr === 1
        ? `${maxMediaDurationHr} hour`
        : `${maxMediaDurationHr} hours`
    } max`,
    errorCode: 'ER010',
  },
  TOO_SHORT: {
    heading: DEFAULT_ERROR_MESSAGE,
  },
  WIDTH_TOO_SMALL: {
    heading: DEFAULT_ERROR_MESSAGE,
  },
  WIDTH_TOO_LARGE: {
    heading: DEFAULT_ERROR_MESSAGE,
  },
  HEIGHT_TOO_SMALL: {
    heading: DEFAULT_ERROR_MESSAGE,
  },
  HEIGHT_TOO_LARGE: {
    heading: DEFAULT_ERROR_MESSAGE,
  },
  ERROR: {
    heading: DEFAULT_ERROR_MESSAGE,
  },
});
