import { useToggle } from '@sparemin/blockhead';
import { useCallback, useState } from 'react';
import { PromoPackAssetType } from 'api';
import { usePromoPackAsset } from 'pages/TranscriptEditorPage/state/useGetPromoPackContents';
import { ACTIONS_BAR_PADDING_TOP } from '../ActionsBar/constants';
import { BASE_ADJUSTMENTS_BAR_PADDING_TOP } from '../BaseAdjustmentsBar/constants';

import { MIN_ACTIONS_BAR_HEIGHT } from './constants';

export default function useBaseTabPanel(assetType?: PromoPackAssetType) {
  const [topBarHeightWithPadding, setTopBarHeightWithPadding] = useState(
    MIN_ACTIONS_BAR_HEIGHT,
  );

  const { value: isAdjusting, toggle: toggleIsAdjusting } = useToggle(false);

  const {
    data: asset,
    isLoading: isPromoPackAssetLoading,
    isError: isPromoPackAssetError,
  } = usePromoPackAsset(assetType);

  const { status: assetStatus } = asset || {};

  const isLoading = isPromoPackAssetLoading || assetStatus === 'processing';
  const isError =
    assetStatus === 'error' ||
    assetStatus === 'notFound' ||
    isPromoPackAssetError;
  // The buttons are considered disabled if:
  // - The asset content is still loading;
  // - There is an error, since the asset content will be null,
  //   which means that there is nothing to download.
  const isDisabled = isLoading || isError;

  const handleTopBarHeightChange = useCallback(
    (newHeight: number): void => {
      setTopBarHeightWithPadding(
        newHeight +
          (isAdjusting
            ? BASE_ADJUSTMENTS_BAR_PADDING_TOP
            : ACTIONS_BAR_PADDING_TOP),
      );
    },
    [isAdjusting],
  );

  return {
    asset,
    isLoading,
    isError,
    isDisabled,
    isAdjusting,
    topBarHeightWithPadding,
    onTopBarHeightChange: handleTopBarHeightChange,
    toggleIsAdjusting,
  };
}
