import { AuthPageLayout } from '@sparemin/auth';
import { Outlet } from 'react-router-dom';
import SpareminAuthProvider from './SpareminAuthProvider';

export interface AuthenticationPageProps {}

const AuthenticationPage: React.FC<AuthenticationPageProps> = () => (
  <SpareminAuthProvider>
    <AuthPageLayout>
      <Outlet />
    </AuthPageLayout>
  </SpareminAuthProvider>
);

export default AuthenticationPage;
