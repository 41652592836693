import React from 'react';
import { EdgeAssetType } from 'api';
import { IntroOutroProvider } from 'pages/TranscriptEditorPage/IntroOutroContext';
import IntroOutro, { IntroOutroProps } from './IntroOutro';

interface IntroOutroWrapperProps extends IntroOutroProps {
  variant: EdgeAssetType;
}

const IntroOutroWrapper: React.FC<IntroOutroWrapperProps> = ({
  variant,
  ...rest
}) => (
  <IntroOutroProvider variant={variant}>
    <IntroOutro {...rest} />
  </IntroOutroProvider>
);
export default IntroOutroWrapper;
