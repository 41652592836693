import { useMemo } from 'react';
import PromoPackModalContext from './PromoPackModalContext';
import { PromoPackModalProviderProps } from './types';

const PromoPackModalProvider: React.FC<PromoPackModalProviderProps> = ({
  promoPackTabs,

  children,
}) => {
  return (
    <PromoPackModalContext.Provider
      value={useMemo(
        () => ({
          promoPackTabs,
        }),
        [promoPackTabs],
      )}
    >
      {children}
    </PromoPackModalContext.Provider>
  );
};

export default PromoPackModalProvider;
