import { HelmetTags } from 'react-helmet-async';
import { FaviconProps, MultipleFaviconProps } from './types';

/**
 * react-helmet-async uses [`isEqualNode`](https://developer.mozilla.org/en-US/docs/Web/API/Node/isEqualNode)
 * to determine if two nodes are the same.  Sometimes, this doesn't work.  For example,
 * using react-helmet-async to try and replace the page's favicon results in many
 * different favicon links in `head`.  react-helmet-async sees every favicon link,
 * even with the same value for properties like `id`, as a new element.
 *
 * This function will dedupe elements by their id so that only one element with
 * that id exists in the header.  Additionally, for every element that is deduped,
 * the correct value will be re-mounted to clear the cache for elements like `favicon`
 */
function dedupeTagsById(
  addedEls: HTMLElement[] = [],
  removedEls: HTMLElement[] = [],
) {
  const idsAdded = addedEls.map((el) => el.id);

  const idsManuallyRemoved = idsAdded.reduce((acc, id) => {
    const elements = document.querySelectorAll(`#${id}`);

    if (elements.length > 1) {
      acc.push(id);

      // remove all elements with the id except for the last one
      elements.forEach((el, i, ary) => {
        if (i < ary.length - 1) {
          el.parentNode?.removeChild(el);
        }
      });
    }

    return acc;
  }, [] as string[]);

  idsManuallyRemoved.forEach((id) => {
    const element = addedEls.find((el) => el.id === id);
    const parent = element?.parentNode;

    // remount to clear cache
    if (element && parent) {
      parent.removeChild(element);
      parent.appendChild(element);
    }
  });
}

export function dedupeById(
  _: unknown,
  addedTags: HelmetTags,
  removedTags: HelmetTags,
) {
  dedupeTagsById(addedTags.linkTags, removedTags.linkTags);
}

function isMultipleFaviconProps(props: unknown): props is MultipleFaviconProps {
  return (props as MultipleFaviconProps)?.smallIcon !== undefined;
}

export function resolveIconConfig(props: FaviconProps): MultipleFaviconProps {
  if (isMultipleFaviconProps(props)) {
    return props;
  }

  return {
    largeIcon: {
      url: props.url,
      type: props.type,
    },
    smallIcon: {
      url: props.url,
      type: props.type,
    },
  };
}
