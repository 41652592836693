import { GradientIcon } from '@sparemin/blockhead';
import React from 'react';
import useDefaultUploadLimitMessage from 'hooks/useDefaultUploadLimitMessage';
import BaseUpsellModal from '../BaseUpsellModal';
import BaseUpsellModalFooter from '../BaseUpsellModalFooter';
import { BaseUpsellModalProps } from '../types';
import * as S from './styles';

interface UploadLimitsContentsProps extends BaseUpsellModalProps {}

const UploadLimitsContents: React.FC<UploadLimitsContentsProps> = () => {
  const defaultUploadLimitMessage = useDefaultUploadLimitMessage();

  return (
    <BaseUpsellModal
      title="Increase your upload limits"
      subtitle={defaultUploadLimitMessage}
      icon={
        <GradientIcon>
          <S.Upload />
        </GradientIcon>
      }
      footer={<BaseUpsellModalFooter modalName="EddyUploadLimitGeneral" />}
    />
  );
};

export default UploadLimitsContents;
