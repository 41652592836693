import { Spacer } from '@sparemin/blockhead';
import React, { useCallback } from 'react';
import { useIntroOutro } from 'pages/TranscriptEditorPage/IntroOutroContext';
import { useModalsActions } from 'state/modal';
import { useIsBasicOrFreeTier } from 'state/pricing';
import * as S from './styles';

interface AddIntroOutroButtonProps {}

const AddIntroOutroButton: React.FC<AddIntroOutroButtonProps> = () => {
  const { data: isBasicOrFreeTier } = useIsBasicOrFreeTier();
  const { status, onAddIntroOutro, variant, onAddIntroOutroFromIntegrator } =
    useIntroOutro();

  const isError = status === 'error';

  const { pushModal, hideModal } = useModalsActions();

  const handleGatedUser = useCallback(() => {
    pushModal({ name: 'Upsell', params: { context: 'introOutro' } });
  }, [pushModal]);

  const handleAddEdgeAsset = useCallback(
    (file: File) => {
      onAddIntroOutro?.(file);
      hideModal();
    },
    [onAddIntroOutro, hideModal],
  );

  const handleClick = () => {
    pushModal({
      name: 'AddIntroOutro',
      params: {
        variant,
        onAddEdgeAsset: handleAddEdgeAsset,
        onAddEdgeAssetFromIntegrator: onAddIntroOutroFromIntegrator,
      },
    });
  };

  return (
    <S.Button onPress={isBasicOrFreeTier ? handleGatedUser : handleClick}>
      <Spacer space="8px" align="center">
        {isError && (
          <Spacer space="4px" align="center">
            <S.AlertIcon />
            <S.ErrorText>
              {`Sorry, we couldn't add your ${variant}, please try again:`}
            </S.ErrorText>
          </Spacer>
        )}
        <S.PlusIcon />
        <S.Text>{`add ${variant}`}</S.Text>
      </Spacer>
    </S.Button>
  );
};
export default AddIntroOutroButton;
