import { useMutation } from 'react-query';
import { PromoPackAssetType, PromoPackType, downloadPromoPackAsset } from 'api';
import { downloadBlob } from 'utils/download';
import { capitalize } from 'utils/string';
import { useProjectName } from './useEditorProject';
import { useTranscriptId } from './useEditorTranscript';

export interface DownloadPromoPackSpecificAssetArgs {
  promoPackType?: PromoPackType;
  assetType: PromoPackAssetType;
}

export const DEFAULT_PROMO_PACK_FILE_EXTENSION = '.zip';

export default function useDownloadPromoPackAsset() {
  const { data: transcriptId } = useTranscriptId();
  const { data: projectName } = useProjectName();

  return useMutation(
    async (args: DownloadPromoPackSpecificAssetArgs): Promise<void> => {
      const { promoPackType = 'main', assetType } = args;

      const { file, fileName } = await downloadPromoPackAsset({
        transcriptId,
        promoPackType,
        assetType,
      });

      downloadBlob(
        fileName ??
          `${capitalize(
            assetType,
          )}_${projectName}${DEFAULT_PROMO_PACK_FILE_EXTENSION}`,
        file,
      );
    },
  );
}
