import { useQuery } from 'react-query';
import {
  DisplayPreferences,
  getMyDisplayPrefs,
} from 'api/services/headlinerUser';
import { useAuth } from 'state/auth';
import { QueryOptions } from 'types';
import { displayPrefQueryKeys } from './queryKeys';
import {
  expressExportFormatsOverrideSelector,
  faviconSelector,
  logoTargetOverrideSelector,
  helpButtonSelector,
  logoUrlSelector,
  primaryColorSelector,
  themeOverrideSelector,
  titleSelector,
  isClipSuggestionsEnabledSelector,
  eddyFeaturePrefOverrideSelector,
} from './selectors';

type QueryKey = ReturnType<typeof displayPrefQueryKeys.myDisplayPrefs>;

export type UseMyDisplayPrefsOptions<TData = DisplayPreferences> = QueryOptions<
  DisplayPreferences,
  TData,
  QueryKey
>;

function useMyDisplayPrefs<TData = DisplayPreferences>(
  opts?: UseMyDisplayPrefsOptions<TData>,
) {
  const { enabled = true } = opts ?? {};
  const { userId } = useAuth();

  return useQuery(
    displayPrefQueryKeys.myDisplayPrefs(userId),
    getMyDisplayPrefs,
    { ...opts, enabled: !!userId && enabled, refetchOnMount: false },
  );
}

export const useLogoUrlOverride = () =>
  useMyDisplayPrefs({ select: logoUrlSelector });

export const useLogoTargetOverride = () =>
  useMyDisplayPrefs({ select: logoTargetOverrideSelector });

export const useFaviconOverride = () =>
  useMyDisplayPrefs({ select: faviconSelector });

export const useTitleOverride = () =>
  useMyDisplayPrefs({ select: titleSelector });

export const usePrimaryColorOverride = () =>
  useMyDisplayPrefs({ select: primaryColorSelector });

export const useHelpButtonOverride = () =>
  useMyDisplayPrefs({ select: helpButtonSelector });

export const useThemeOverride = () =>
  useMyDisplayPrefs({ select: themeOverrideSelector });

export const useExpressExportFormatsOverride = () =>
  useMyDisplayPrefs({ select: expressExportFormatsOverrideSelector });

export const useIsClipSuggestionsEnabled = () =>
  useMyDisplayPrefs({ select: isClipSuggestionsEnabledSelector });

export const useEddyFeaturesDisplayPrefOverride = () =>
  useMyDisplayPrefs({ select: eddyFeaturePrefOverrideSelector });
