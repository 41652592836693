import { getThemeColor, Heading, IconButton } from '@sparemin/blockhead';
import { Thumbnail as BaseThumbnail } from '@sparemin/blockhead';
import styled from 'styled-components';
import { PodcastContainer } from '../styles';
import { DefaultPodcastContainerStyleProps } from './types';

export const DefaultPodcastContainer = styled(
  PodcastContainer,
)<DefaultPodcastContainerStyleProps>`
  align-items: center;
  display: flex;
  gap: 15px;
  transition: ${({ theme }) => theme.transitions.create('opacity')};

  ${({ $isDisabled }) =>
    $isDisabled &&
    `
    opacity: 0.3;
    pointer-events: none;
  `}
`;

export const Thumbnail = styled(BaseThumbnail)`
  flex: 0 0 45px;
`;

export const PodcastTitle = styled(Heading).attrs({
  level: 3,
})`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const SearchButton = styled(IconButton)`
  background-color: ${getThemeColor('actionPrimary')};
  flex: 0 0 30px;
  margin-left: auto;
  height: 30px;

  &,
  &[disabled] {
    // disabled state opacity is handled by the container
    opacity: 1;
  }

  &,
  &[data-hovered='true']:not([disabled]) {
    color: ${getThemeColor('l2')};
  }

  > * {
    height: 12px;
    width: 12px;
  }
`;
