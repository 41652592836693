import React from 'react';

import ConnectedModal from 'components/ConnectedModal';
import { useModalParams } from 'state/modal';

import AudiogramExportModalContents from './AudiogramExportModalContents';

const AudiogramExportModal: React.FunctionComponent = () => {
  const params = useModalParams('AudiogramExport');

  return (
    <ConnectedModal name="AudiogramExport">
      <AudiogramExportModalContents params={params} />
    </ConnectedModal>
  );
};

export default AudiogramExportModal;
