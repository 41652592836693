import { ProjectTranscript, TranscriptEdit } from 'api';
import notifyError from 'components/notification';
import { omitNil } from 'utils/objects';
import { wordDictionarySelector } from '../../selectors';
import TranscriptOriginator from '../TranscriptOriginator';
import { Committer } from '../types';
import AsyncUndoableWordCommand, {
  WordCommitterArgs,
} from './AsyncUndoableWordCommand';

export default class UndeleteWordsCommand extends AsyncUndoableWordCommand {
  constructor(
    private undeletionWordIds: number[],
    originator: TranscriptOriginator,
    committer: Committer<WordCommitterArgs>,
  ) {
    super(originator, committer);
  }

  protected onError(): void {
    notifyError({
      heading: 'Error updating transcript',
      errorCode: 'ER009',
    });
  }

  protected createEdits(
    transcript: ProjectTranscript | undefined,
  ): TranscriptEdit[] {
    if (!transcript) {
      return [];
    }

    const wordsById = wordDictionarySelector(transcript);

    return this.undeletionWordIds.reduce((acc, wordId) => {
      const word = wordsById?.[wordId];

      if (word?.isDeleted) {
        acc.push({ action: 'undelete', wordId, actionDetail: {} });

        if (
          word.originalStartMillis !== undefined ||
          word.originalEndMillis !== undefined
        ) {
          acc.push({
            action: 'update',
            wordId,
            actionDetail: omitNil({
              endMillis: word.originalEndMillis,
              startMillis: word.originalStartMillis,
            }),
          });
        }
      }

      return acc;
    }, [] as TranscriptEdit[]);
  }
}
