import qs from 'query-string';
import { AUTH_REDIRECT_QUERY_PARAM } from 'config/constants';

interface PartialLocation {
  pathname: string;
  search: string;
  hash: string;
}

// PartialLocation makes this utility compatible with both react-router's Location
// and the browser's native history.location
export function getRedirectSearchString(location: PartialLocation) {
  return qs.stringify({
    // borrowed from an old friend https://github.com/mjrussell/redux-auth-wrapper/blob/master/src/history4/locationHelper.js#L28
    [AUTH_REDIRECT_QUERY_PARAM]: `${location.pathname}${location.search}${location.hash}`,
  });
}
