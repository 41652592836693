import { produce } from 'immer';
import { useMutation, useQueryClient } from 'react-query';
import {
  ClipSuggestionGroup,
  DislikeClipSuggestionArgs,
  dislikeSuggestedClip,
} from 'api';
import { useAuth } from 'state/auth';
import { eddyTranscriptQueryKeys } from './queryKeys';
import { useTranscriptId } from './useEditorTranscript';

interface UseDislikeClipSuggestionContext {
  prevSuggestionGroup: ClipSuggestionGroup | undefined;
}

export default function useDislikeClipSuggestion() {
  const queryClient = useQueryClient();
  const { userId } = useAuth();
  const { data: transcriptId } = useTranscriptId();

  const queryKey = eddyTranscriptQueryKeys.clipSuggestions(
    userId,
    transcriptId,
  );

  return useMutation<
    void,
    unknown,
    Omit<DislikeClipSuggestionArgs, 'projectTranscriptId'>,
    UseDislikeClipSuggestionContext
  >({
    mutationFn: ({ suggestionId, feedbackType }) =>
      dislikeSuggestedClip({
        projectTranscriptId: transcriptId as number,
        suggestionId,
        feedbackType,
      }),
    onMutate: async ({ suggestionId }) => {
      await queryClient.cancelQueries(queryKey);

      const prevSuggestionGroup =
        queryClient.getQueryData<ClipSuggestionGroup>(queryKey);

      queryClient.setQueryData<ClipSuggestionGroup | undefined>(
        queryKey,
        (group) => {
          if (!group) {
            return undefined;
          }

          return produce(group, (draft) => {
            const index =
              draft.suggestions?.findIndex((s) => s.id === suggestionId) ?? -1;

            if (index > -1) {
              draft.suggestions?.splice(index, 1);
            }
          });
        },
      );

      return { prevSuggestionGroup };
    },
    onError: (err, args, context) => {
      queryClient.setQueryData(queryKey, context?.prevSuggestionGroup);
    },
    onSettled: () => {
      queryClient.invalidateQueries(queryKey);
    },
  });
}
