import { breakpointDown } from '@sparemin/blockhead';
import styled from 'styled-components';
import ContextMenuInput from '../../ContextMenuInput';

export const Root = styled.form`
  width: 350px;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  gap: 10px;

  ${breakpointDown('md')`
    width: 100%;
  `}
`;

export const Input = styled(ContextMenuInput)`
  height: 50px;
  padding: 18px 17px;
  border-radius: 8px;
  border: solid 1px #ccc;
`;
