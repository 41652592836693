import { flow } from 'lodash-es';
import mixpanel, { Dict } from 'mixpanel-browser';
import { PlanName } from 'api/services/plan';
import {
  getCurrentSessionURL,
  fullstoryIdentify,
  fullstoryAnonymize,
} from 'utils/fullstory';
import { pascalKeys } from 'utils/object';
import {
  ClearUserTrackingInfo,
  ErrorCode,
  FileTransferErrorCode,
  SetUserTrackingInfo,
} from '../types';
import { FSMixpanelProps, AppNameProps } from './types';

function addFullstoryURLToProperties<T extends Dict | undefined>(
  properties: T,
): T & FSMixpanelProps {
  const fullstoryUrl = getCurrentSessionURL(true);

  const fsProperties = {
    'FS Link': fullstoryUrl ?? 'FullStory not ready. Cannot get link',
  };

  return {
    ...properties,
    ...fsProperties,
  };
}

// Eddy sends data to the same Mixpanel Project as Make. In order to identify the source of events
// the AppName property should be sent with every event, rather than relying on the property being set as a super property,
// which does not reliably get overwritten when users' switch application.
function addAppNameToProperties<T extends Dict | undefined>(
  properties: T,
): T & AppNameProps {
  const appName = { appName: 'Eddy' };
  return { ...properties, ...appName };
}

export const track: typeof mixpanel.track = (
  eventName,
  properties,
  optionsOrCallback,
  callback,
) => {
  const allProperties = flow(
    addFullstoryURLToProperties,
    addAppNameToProperties,
  )(properties);

  const transformedProperties = !allProperties
    ? undefined
    : pascalKeys(allProperties, ['FS Link']);

  mixpanel.track(eventName, transformedProperties, optionsOrCallback, callback);
};

export const setUserTrackingInfo: SetUserTrackingInfo = (
  email: string,
  userId: number,
  planType: PlanName,
) => {
  mixpanel.identify(email);
  mixpanel.people.set({
    $email: email,
    last_login: new Date(),
    user_id: userId,
    $planType: planType,
  });

  mixpanel.people.set_once('firstLogin', new Date().toISOString());
  fullstoryIdentify({ userId, email });
  mixpanel.register({ planType });
};

export const clearUserTrackingInfo: ClearUserTrackingInfo = () => {
  mixpanel.reset();
  fullstoryAnonymize();
};

export function isFileError(val: ErrorCode): val is FileTransferErrorCode {
  return Boolean((val as FileTransferErrorCode).type);
}
