import { useCallback, useRef } from 'react';

import { useWordList, useWordsById } from 'pages/TranscriptEditorPage/state';
import { inRange } from 'utils/math';
import { secToMillis } from 'utils/time';
import {
  EditorUiStore,
  useEditorUiActions,
  useEditorUiSubscription,
} from '../../state/editorUi';
import { findActiveWord } from './utils';

export default function useSetActiveWordByTime() {
  const editorUiState = useRef<EditorUiStore>();
  const { activateWord } = useEditorUiActions();

  const { data: words } = useWordList();
  const { data: wordsById } = useWordsById();

  useEditorUiSubscription((state) => {
    editorUiState.current = state;
  });

  return useCallback(
    (sec: number) => {
      if (!words || !wordsById) {
        return;
      }

      const currentActiveWordId = editorUiState.current?.activeWordId;
      const currentActiveWord = !currentActiveWordId
        ? undefined
        : wordsById[currentActiveWordId];

      const millis = secToMillis(sec);

      // optimization to prevent `findActiveWord`, which is potentially expensive
      // depending on the number of words, from running more often than necessary.
      if (
        currentActiveWord &&
        inRange(
          millis,
          currentActiveWord.startMillis,
          currentActiveWord.endMillis,
        )
      ) {
        return;
      }

      const activeWord = findActiveWord(words, millis);
      activateWord(activeWord?.id);
    },
    [activateWord, words, wordsById],
  );
}
