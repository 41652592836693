import ConnectedModal from 'components/ConnectedModal';
import { useModalParams } from 'state/modal';
import ExportTranscriptModalContents from './ExportTranscriptModalContents';

export interface ExportTranscriptModalProps {}

const ExportTranscriptModal: React.FC<ExportTranscriptModalProps> = () => {
  const params = useModalParams('ExportTranscript');

  return (
    <ConnectedModal name="ExportTranscript">
      <ExportTranscriptModalContents params={params} />
    </ConnectedModal>
  );
};
export default ExportTranscriptModal;
