import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { getProjectNameSuggestion, updateProjectSettings } from 'api';
import { eddyProjectNameSuggestionsQueryKeys } from './queryKeys';
import { useProjectCuid } from './useEditorProject';
import useUpdateProjectSettings, {
  UseUpdateProjectSettingsArgs,
} from './useUpdateProjectSettings';

export type UseUpdateAIProjectSettingsVariables = Pick<
  UseUpdateProjectSettingsArgs,
  'isNameSuggestionApplied'
>;

export default function useUpdateAIProjectSettings() {
  const { data: projectCuid } = useProjectCuid();
  const queryClient = useQueryClient();

  const mutationFn = useCallback(
    async ({
      isNameSuggestionApplied,
    }: UseUpdateAIProjectSettingsVariables) => {
      if (!projectCuid || !isNameSuggestionApplied) {
        return;
      }

      const result = await getProjectNameSuggestion({ projectCuid });
      queryClient.setQueryData(
        eddyProjectNameSuggestionsQueryKeys.projectNameSuggestions(projectCuid),
        result,
      );

      const suggestion = result.suggestions[0]?.name;

      if (!suggestion) {
        return;
      }

      await updateProjectSettings({
        projectCuid,
        isNameSuggestionApplied: true,
        name: suggestion,
      });
    },
    [projectCuid, queryClient],
  );

  return useUpdateProjectSettings({ mutationFn });
}
