import {
  Button,
  Container,
  getThemeColor,
  HeadyGradient,
  Spacer,
  whileHovering,
} from '@sparemin/blockhead';
import styled from 'styled-components';
import TextButton from 'components/TextButton';

export const PopoverRoot = styled(Container).attrs({
  cornerRadius: 2,
  forwardedAs: Spacer,
  gutter: 3,
  orientation: 'vertical',
  space: 'medium',
})`
  box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.15);
  color: ${getThemeColor('d1')};
  width: 282px;
`;

export const Icon = styled(HeadyGradient)`
  color: ${getThemeColor('black')};
`;

export const Footer = styled(Spacer).attrs({
  align: 'center',
  justify: 'space-between',
  space: 0,
})`
  width: 100%;
`;

export const CreateClipButton = styled(Button).attrs({
  size: 'small',
})`
  width: 154px;
`;

export const DownloadButton = styled(Button)`
  width: 30px;
  height: 30px;
  padding: 0;
  border-radius: 50%;
`;

export const Dismiss = styled(TextButton)`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${getThemeColor('actionTertiary')};
  color: ${getThemeColor('white')};
  transition: ${({ theme }) => theme.transitions.create('background-color')};
  /* Flipping the icon so that the like icon becomes dislike. */
  transform: scale(1, -1);

  ${whileHovering`
    background-color: ${getThemeColor('actionTertiary', 'dark')};
    color: ${getThemeColor('white')};
  `}
`;
