import { Select, ColorPaletteField } from '@sparemin/blockhead';
import { PromoPackImageType } from 'api';
import { useIsBasicOrFreeTier } from 'state/pricing';
import { AspectRatioName } from 'types';
import { useTabPanel } from '../BaseTabPanel';
import {
  aspectRatioFeedbackOptions,
  imageTypeFeedbackOptions,
} from './constants';
import * as S from './styles';

export interface EpisodeArtRegenerationOptionsProps {}

const EpisodeArtRegenerationOptions: React.FC<
  EpisodeArtRegenerationOptionsProps
> = () => {
  const { adjustmentValues, colorPaletteState, onAdjustmentValueChange } =
    useTabPanel();

  const { data: isBasicOrFreeTier } = useIsBasicOrFreeTier();

  return (
    <>
      <Select
        fluid
        label="Aspect ratio"
        isDisabled={isBasicOrFreeTier}
        selectedKey={adjustmentValues?.aspectRatio}
        onSelectionChange={(value) => {
          onAdjustmentValueChange({
            aspectRatio: value as AspectRatioName,
          });
        }}
      >
        {aspectRatioFeedbackOptions.map(({ value, label }) => (
          <Select.Option key={value} textValue={label}>
            {label}
          </Select.Option>
        ))}
      </Select>

      <Select
        fluid
        label="Style"
        isDisabled={isBasicOrFreeTier}
        selectedKey={adjustmentValues?.imageType}
        onSelectionChange={(value) =>
          onAdjustmentValueChange({
            imageType: value as PromoPackImageType,
          })
        }
      >
        {imageTypeFeedbackOptions.map(({ value, label }) => (
          <Select.Option key={value} textValue={label}>
            {label}
          </Select.Option>
        ))}
      </Select>

      <S.ColorPaletteContainer
        onMouseDown={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <ColorPaletteField
          disableAlpha
          label="Color palette"
          aria-label="Color palette"
          state={colorPaletteState}
        />
      </S.ColorPaletteContainer>
    </>
  );
};

export default EpisodeArtRegenerationOptions;
