import { ProjectTranscript, TranscriptWord } from 'api';
import { TimeInterval, WordSelection } from 'types';
import {
  DELETION_TIME_SHIFT_MILLIS,
  EDITED_WORD_MIN_DURATION_MILLIS,
} from '../constants';
import { createWordEdits } from '../EditorContextMenu/WordContextMenu/TextEditMenuContents/utils';
import { WordEdit, WordsDictionary } from '../state';

export function forEachWord(
  transcript: ProjectTranscript,
  fn: (word: TranscriptWord) => void,
) {
  const { segments } = transcript;

  for (let i = 0; i < segments.length; i += 1) {
    const words = segments[i].words;

    for (let j = 0; j < words.length; j += 1) {
      fn(words[j]);
    }
  }
}

export function getWordMinStartMillis(prevWord: TranscriptWord | undefined) {
  if (!prevWord) {
    return 0;
  }

  return Math.min(
    prevWord.startMillis + EDITED_WORD_MIN_DURATION_MILLIS,
    prevWord.endMillis,
  );
}

export function getWordMaxEndMillis(nextWord: TranscriptWord | undefined) {
  if (!nextWord) {
    return Infinity;
  }

  return Math.max(
    nextWord.endMillis - EDITED_WORD_MIN_DURATION_MILLIS,
    nextWord.startMillis,
  );
}

export function adjustDeletionStartTime(
  deletedWord: TranscriptWord,
  prevWord: TranscriptWord | undefined,
) {
  const minStartMillis = getWordMinStartMillis(prevWord);
  return Math.max(
    minStartMillis,
    deletedWord.startMillis - DELETION_TIME_SHIFT_MILLIS,
  );
}

export function getSelectionStartEndWords(
  wordsById?: WordsDictionary,
  wordsIds?: number[],
): WordSelection | undefined {
  if (!wordsById || !wordsIds) {
    return;
  }

  const startWordId = wordsIds[0];
  const endWordId = wordsIds[wordsIds.length - 1];

  return {
    startWord: wordsById[startWordId],
    endWord: wordsById[endWordId],
  };
}

export function getSelectionStartEndMillis(
  wordsById?: WordsDictionary,
  wordsIds?: number[],
): TimeInterval | undefined {
  if (!wordsById || !wordsIds) {
    return;
  }

  const startWordId = wordsIds[0];
  const endWordId = wordsIds[wordsIds.length - 1];

  return {
    startMillis: wordsById[startWordId].startMillis,
    endMillis: wordsById[endWordId].endMillis,
  };
}

export function createFoundWordsEdits(
  foundWordsGroups: number[][],
  wordsById: WordsDictionary,
  value: string,
): WordEdit[] {
  // Use flatMap to process each wordGroup in foundWordsGroups and flatten the result.
  return foundWordsGroups.flatMap((wordGroup) => {
    // Generate the WordEdit objects using the createWordEdits function.
    const wordEdits = createWordEdits(wordGroup, value);

    // Map the generated WordEdit objects to include the properties from wordsById.
    return wordEdits.map((wordEdit, index) => {
      const wordId = wordGroup[index];
      const originalWordEdit = wordsById?.[wordId];

      // Merge the properties from wordsById with the generated WordEdit object.
      return { ...originalWordEdit, ...wordEdit, text: wordEdit.text || '' };
    });
  });
}
