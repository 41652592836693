import { InlineTextField } from '@sparemin/blockhead';
import styled from 'styled-components';
import Plate from 'components/Plate';

export const FeebackInputContainer = styled(Plate)`
  width: 100%;
  height: 82px;
  border-radius: 20px;
  padding: 16px;
  box-shadow: none;
`;

export const CustomFeedbackInput = styled(InlineTextField)`
  width: 100%;
  padding: 16px 18px;
  font-size: 14px;
  font-weight: normal;
`;
