import { useCallback } from 'react';
import { useMutation } from 'react-query';
import {
  UploadBlobImageArgs as ApiUploadBlobImageArgs,
  UploadBlobImageResult,
  uploadBlobImage,
} from 'api';
import { useAuth } from 'state/auth';
import { MutationOptions } from 'types';

export interface UploadBlobImageArgs
  extends Omit<ApiUploadBlobImageArgs, 'userId' | 'imageFile'> {
  image: File;
}

export default function useUploadBlobImage(
  opts?: MutationOptions<
    UploadBlobImageResult | undefined,
    UploadBlobImageArgs
  >,
) {
  const { userId } = useAuth();

  const mutationFn = useCallback(
    async ({ image, imageType }: UploadBlobImageArgs) => {
      if (!userId) {
        return;
      }

      const imageBlob = new Blob([image], { type: image.type });
      const imageFile = new FormData();

      imageFile.append('imageFile', imageBlob);

      const results = await uploadBlobImage({
        imageFile,
        imageType,
        userId,
      });

      return results;
    },
    [userId],
  );

  return useMutation<
    UploadBlobImageResult | undefined,
    unknown,
    UploadBlobImageArgs,
    unknown
  >(mutationFn, opts);
}
