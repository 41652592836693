import { useEffect, useRef } from 'react';
import { keyHandlerSelector, useHotKeySubscription } from 'state/hotkey';
import { HotkeyStore } from 'state/hotkey/types';

export default function useHotkeyManager() {
  const hotkeyState = useRef<HotkeyStore>();

  useHotKeySubscription((state) => {
    hotkeyState.current = state;
  });

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      const target = e.target as HTMLElement;

      if (
        !hotkeyState.current ||
        target instanceof HTMLInputElement ||
        target instanceof HTMLTextAreaElement
      ) {
        return;
      }

      const keyHandler = keyHandlerSelector(e, hotkeyState.current);

      if (keyHandler) {
        e.preventDefault();
        e.stopPropagation();
        keyHandler?.();
      }
    };

    document.addEventListener('keydown', handler, { capture: true });

    return () => {
      document.removeEventListener('keydown', handler, { capture: true });
    };
  }, []);
}
