import { Spacer, TRACK_CONTAINER_CLASSNAME } from '@sparemin/blockhead';
import styled from 'styled-components';
import Plate from 'components/Plate';

const PLAYER_GUTTER = 20;

export const Container = styled(Plate)`
  display: flex;
  position: fixed;
  left: ${PLAYER_GUTTER}px;
  bottom: ${PLAYER_GUTTER}px;
  z-index: 1;
  width: calc(100vw - 40px);

  & .${TRACK_CONTAINER_CLASSNAME} {
    width: auto;
    flex-grow: 1;
  }
`;

export const Content = styled(Spacer).attrs({
  align: 'center',
  space: 3,
})`
  width: 100%;
`;
