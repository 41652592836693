import {
  MediaImporter,
  useGoogleDrivePlugin,
  GOOGLE_DRIVE_TAB_KEY,
} from '@sparemin/media-import';
import React from 'react';
import { formatAcceptedFileTypes } from 'components/MediaUploader';
import {
  DROPZONE_ACCEPT_AUDIO,
  DROPZONE_ACCEPT_VIDEO,
} from 'components/MediaUploader/constants';
import Null from 'components/Null';
import useIsMobileViewport from 'hooks/useIsMobileViewport';
import {
  INTRO_OUTRO_MAX_LENGTH_SECS,
  INTRO_OUTRO_MAX_SIZE_MB,
} from 'pages/TranscriptEditorPage/constants';
import { useModalParams } from 'state/modal';
import { megabytesToBytes } from 'utils/math';
import { secToMillis } from 'utils/time';
import { useProjectMediaType } from '../state';
import * as S from './styles';
import useHandleMediaAccepted from './useHandleMediaAccepted';
import useHandleMediaRejected from './useHandleMediaRejected';

interface IntroOutroMediaUploadModalContentsProps {}

const IntroOutroMediaUploadModalContents: React.FC<
  IntroOutroMediaUploadModalContentsProps
> = () => {
  const isMobile = useIsMobileViewport();

  const { data: projectType } = useProjectMediaType();

  const acceptList = formatAcceptedFileTypes(
    projectType === 'audio' ? DROPZONE_ACCEPT_AUDIO : DROPZONE_ACCEPT_VIDEO,
  );

  const { onAddEdgeAssetFromIntegrator } =
    useModalParams('IntroOutroMediaUpload') ?? {};

  const { onMediaAccepted } = useHandleMediaAccepted(
    onAddEdgeAssetFromIntegrator,
  );
  const { onMediaRejected } = useHandleMediaRejected();

  const layoutProps = isMobile
    ? { orientation: 'horizontal', position: 'top' }
    : { orientation: 'vertical', position: 'left' };

  return (
    <S.Modal footer={<Null />} fullScreen gutter={isMobile ? 0 : 24}>
      <MediaImporter
        accept={acceptList}
        defaultSelectedTabKey={GOOGLE_DRIVE_TAB_KEY}
        plugins={[useGoogleDrivePlugin()]}
        maxDurationMillis={secToMillis(INTRO_OUTRO_MAX_LENGTH_SECS)}
        maxSizeBytes={megabytesToBytes(INTRO_OUTRO_MAX_SIZE_MB)}
        {...{ onMediaAccepted, onMediaRejected }}
        {...layoutProps}
      />
    </S.Modal>
  );
};
export default IntroOutroMediaUploadModalContents;
