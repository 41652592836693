import { DialogProps } from '@sparemin/blockhead';

import ContentsWrapper from './ContentsWrapper';
import * as S from './styles';

interface ConnectedModalComponentProps extends React.PropsWithChildren {
  className?: string;
  destroyOnHide: boolean;
  isInStack: boolean;
  onExited: () => void;
  onHide: () => void;
  position?: DialogProps['position'];
  show: boolean;
}

const ConnectedModalComponent: React.FunctionComponent<
  ConnectedModalComponentProps
> = (props) => {
  const {
    children,
    className,
    destroyOnHide,
    isInStack,
    onExited,
    onHide,
    position,
    show,
  } = props;

  const Content = destroyOnHide && !isInStack ? null : children;
  return (
    <S.ModalWrapper
      className={className}
      isOpen={show}
      onClose={onHide}
      onExited={onExited}
      position={position}
    >
      <ContentsWrapper>{Content}</ContentsWrapper>
    </S.ModalWrapper>
  );
};

export default ConnectedModalComponent;
