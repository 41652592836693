import { AxiosError, AxiosResponse } from 'axios';
import { identity } from 'lodash-es';
import { Interceptor } from './types';

export default function autoLogoutResponseInterceptor(
  logout: () => void,
): Interceptor<AxiosResponse> {
  return [
    identity,
    (error: AxiosError) => {
      if (error?.response?.status === 401) {
        logout();
      }
      return Promise.reject(error);
    },
  ];
}
