import { AxiosClient, configure as configureApi } from '@sparemin/api-sdk';
import { configure as configureAuth, sso } from '@sparemin/auth';
import { configure as configureMediaImport } from '@sparemin/media-import';
import { enablePatches } from 'immer';
import { request } from 'api';
import { APPLE_AUTH_REDIRECT_URI } from 'config';

// can't use blockhead's `loadFonts()` because that function uses `require`
// which isn't supported by vite
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/montserrat/700.css';
import '@fontsource/montserrat/800.css';
import '@fontsource/courier-prime/400.css';
import { APPLICATION_NAME, MOCK_API } from 'config';

const environment = (() => {
  if (import.meta.env.MODE === 'production') {
    return 'prod';
  }

  if (
    import.meta.env.MODE === 'development' &&
    process.env.NODE_ENV === 'development'
  ) {
    return 'local';
  }

  return 'dev';
})();

configureApi({
  getSpareminToken: sso.getToken,
  client: new AxiosClient(request),
  environment,
});

// NB: import.meta.env.MODE can be though of as the deployment environment while
// process.env.NODE_ENV can be though of as the build environment. Building for
// a production build envioronment means minification, etc..  Building for a
// production depolyment environment means using the production .env file.
configureAuth({
  application: APPLICATION_NAME,
  appleRedirectURI: APPLE_AUTH_REDIRECT_URI,
  environment,
});

configureMediaImport({
  environment,
});

// enable immer patches
enablePatches();

// when mocking the API, handlers can be added in src/mocks/handlers.ts
if (process.env.NODE_ENV === 'development' && MOCK_API) {
  const { worker } = await import('mocks');
  worker.start({
    onUnhandledRequest: 'bypass',
  });
}
