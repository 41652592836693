import { findKey, floor } from 'lodash-es';
import { ASPECT_RATIOS_DIMENSIONS_BY_NAME } from 'config';
import { AspectRatioName } from 'types';

export function getAspectRatioNameFromDimensions(
  width?: number,
  height?: number,
): AspectRatioName | undefined {
  if (!width || !height) {
    return undefined;
  }

  return findKey(ASPECT_RATIOS_DIMENSIONS_BY_NAME, ({ ratio }) => {
    return ratio === floor(width / height, 4);
  }) as AspectRatioName;
}
