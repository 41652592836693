import { Spacer } from '@sparemin/blockhead';
import React from 'react';

import AppHeader from 'components/AppHeader';

import ExportProjectButton from './ExportProjectButton';

export interface HeaderProps {
  exportButtonIsDisabled?: boolean;
}

const Header: React.FC<HeaderProps> = ({ exportButtonIsDisabled }) => (
  <AppHeader>
    <AppHeader.LogoItem variant="full" />
    <AppHeader.Item align="center" as={Spacer} pull="right" space={2}>
      <AppHeader.HelpMenu />
      <ExportProjectButton isDisabled={exportButtonIsDisabled} />
    </AppHeader.Item>
  </AppHeader>
);

export default Header;
