import { Outlet } from 'react-router-dom';
import { useCanOpenProject } from 'pages/TranscriptEditorPage/state';
import NonOwnerView from './NonOwnerView';

export interface TranscriptOwnerRouteProps {}

/**
 * If the user is the owner of the transcript, render <Outlet />. Otherwise, display
 * non-owner view informing the user that they lack access to the current transcript.
 */
const TranscriptOwnerRoute: React.FC<TranscriptOwnerRouteProps> = () => {
  const { data: authorized } = useCanOpenProject();

  if (authorized === false) {
    return <NonOwnerView />;
  }

  return <Outlet />;
};

export default TranscriptOwnerRoute;
