import { useCallback } from 'react';
import { useIsBasicOrFreeTier } from 'state/pricing';
import IncreaseUploadLimitsBanner from './IncreaseUploadLimitsBanner';
import * as S from './styles';
import { ProjectsPageBannerProps } from './types';

const ProjectsPageBanner: React.FC<ProjectsPageBannerProps> = () => {
  const { data: isBasicOrFreeTier } = useIsBasicOrFreeTier();

  const getBanner = useCallback((): React.ReactNode => {
    if (isBasicOrFreeTier) {
      return <IncreaseUploadLimitsBanner />;
    }

    return null;
  }, [isBasicOrFreeTier]);

  const banner = getBanner();

  return banner ? <S.Root>{banner}</S.Root> : null;
};

export default ProjectsPageBanner;
