import { debounce, isFinite } from 'lodash-es';
import { useEffect } from 'react';
import { secToMillis } from 'utils/time';
import { SegmentWaveSurfer } from 'utils/wavesurfer';
import {
  getClipRegion,
  getPlaybackStartSec,
  getPlaybackStartSecFromTime,
} from './utils';

export interface UseWaveSurferPlayback {
  audioDurationSec: number;
  clipEndSec: number;
  onPlaybackChange?: (isPlaying: boolean) => void;
  onTimeupdate?: (currentTimeMillis: number) => void;
  onSeeked?: (currentTimeMillis: number) => void;
  playing?: boolean;
  clipStartSec: number;
  waveEndSec: number;
  waveStartSec: number;
  wavesurfer: SegmentWaveSurfer | undefined;
}

export default function useWaveSurferPlayback({
  audioDurationSec,
  clipEndSec,
  clipStartSec,
  onPlaybackChange,
  onSeeked,
  onTimeupdate,
  playing,
  waveEndSec,
  waveStartSec,
  wavesurfer,
}: UseWaveSurferPlayback) {
  // toggle playback
  useEffect(() => {
    if (!wavesurfer) {
      return;
    }

    if (playing && !wavesurfer.isPlaying()) {
      const startSec = getPlaybackStartSecFromTime(
        wavesurfer.getCurrentTime(),
        clipStartSec,
        clipEndSec,
        waveStartSec,
        waveEndSec,
      );

      if (isFinite(startSec)) {
        wavesurfer.seekTo(startSec / audioDurationSec);
        wavesurfer.play();
      } else {
        onPlaybackChange?.(false);
      }
    } else if (!playing && wavesurfer.isPlaying()) {
      return wavesurfer.pause();
    }
  }, [
    audioDurationSec,
    clipEndSec,
    clipStartSec,
    onPlaybackChange,
    playing,
    waveEndSec,
    waveStartSec,
    wavesurfer,
  ]);

  // monitor playback time to pause/skip region
  useEffect(() => {
    if (!wavesurfer) {
      return;
    }

    const handlePlayback = () => {
      const currentTime = wavesurfer.getCurrentTime();

      if (currentTime >= clipStartSec && currentTime <= clipEndSec) {
        if (clipEndSec < waveEndSec) {
          wavesurfer.seekTo(clipEndSec / audioDurationSec);
        } else {
          // wavesurfer.pause();
          onPlaybackChange?.(false);
          wavesurfer.seekTo(
            getPlaybackStartSec(
              clipStartSec,
              clipEndSec,
              waveStartSec,
              waveEndSec,
            ) / audioDurationSec,
          );
        }
      } else if (currentTime > waveEndSec) {
        onPlaybackChange?.(false);
        wavesurfer.seekTo(
          getPlaybackStartSec(
            clipStartSec,
            clipEndSec,
            waveStartSec,
            waveEndSec,
          ) / audioDurationSec,
        );
      }
    };

    wavesurfer.on('audioprocess', handlePlayback);

    return () => {
      wavesurfer.un('audioprocess', handlePlayback);
    };
  }, [
    audioDurationSec,
    clipEndSec,
    clipStartSec,
    onPlaybackChange,
    waveEndSec,
    waveStartSec,
    wavesurfer,
  ]);

  // prevent region from being modified during playback
  useEffect(() => {
    if (!wavesurfer) {
      return;
    }
    const handlePlay = () => {
      const region = getClipRegion(wavesurfer);
      region.update({ resize: false });
    };

    const handlePause = () => {
      const region = getClipRegion(wavesurfer);
      region.update({ resize: true });
    };

    wavesurfer.on('play', handlePlay);
    wavesurfer.on('pause', handlePause);

    return () => {
      wavesurfer.un('play', handlePlay);
      wavesurfer.un('pause', handlePause);
    };
  }, [wavesurfer]);

  // handle seek events
  useEffect(() => {
    if (!wavesurfer) {
      return;
    }

    const handleSeek = debounce(() => {
      onSeeked?.(secToMillis(wavesurfer.getCurrentTime()));
    }, 100);

    wavesurfer.on('seek', handleSeek);

    return () => {
      wavesurfer.un('seek', handleSeek);
    };
  }, [onSeeked, wavesurfer]);

  // send timeupdate events to the parent
  useEffect(() => {
    if (!wavesurfer) {
      return;
    }

    const handleTimeupdate = () => {
      onTimeupdate?.(secToMillis(wavesurfer.getCurrentTime()));
    };

    wavesurfer.on('audioprocess', handleTimeupdate);

    return () => {
      wavesurfer.un('audioprocess', handleTimeupdate);
    };
  }, [onTimeupdate, wavesurfer]);
}
