import { decodeSpareminToken, sso } from '@sparemin/auth';
import { AuthState } from './types';

function getUserIdFromToken(token: string) {
  const decodedToken = decodeSpareminToken(token);
  return decodedToken.sub;
}

export function persistToken(token: string) {
  sso.storeToken(token);
}

export function rehydrateToken() {
  return sso.getToken();
}

export function deleteToken() {
  sso.clearToken();
}

export function createAuthStateFromToken(token: string): AuthState {
  return {
    accessToken: token,
    userId: getUserIdFromToken(token),
  };
}
