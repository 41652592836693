import { useCallback } from 'react';
import { useMutation } from 'react-query';
import { MutationOptions } from 'types';
import { createWordsDeletionEdits } from './commands/utils';
import useEditorTranscript from './useEditorTranscript';
import useUpdateTranscriptText from './useUpdateTranscriptText';

export interface DeleteWordsArgs {
  wordIds: number[];
  isFillerWordsRemoval?: boolean;
}

export default function useDeleteWords(
  opts?: MutationOptions<void, DeleteWordsArgs>,
) {
  const { data: transcript } = useEditorTranscript();
  const { mutateAsync } = useUpdateTranscriptText();

  const mutationFn = useCallback(
    async ({ wordIds, isFillerWordsRemoval }: DeleteWordsArgs) => {
      if (!transcript) {
        return undefined;
      }

      const edits = createWordsDeletionEdits(transcript, wordIds);

      await mutateAsync({ edits, isFillerWordsRemoval });
    },
    [mutateAsync, transcript],
  );

  return useMutation<void, unknown, DeleteWordsArgs, unknown>(mutationFn, opts);
}
