import { SliderProps, useStaticCallback } from '@sparemin/blockhead';
import { useCallback, useEffect, useState } from 'react';
import { useAudioPlayer } from 'pages/TranscriptEditorPage/AudioPlayerContext';
import { millisToSec } from 'utils/time';
import useEditedIntervals from './useEditedIntervals';

export interface UseSeekOptions {
  onTimeupdate: (sec: number) => void;
}

export type UseSeekSliderProps = Pick<SliderProps, 'onChange' | 'onChangeEnd'>;

export interface UseSeekResult {
  seekMillis: number | undefined;
  sliderProps: UseSeekSliderProps;
}

/**
 * A hook to handle seeking for the player progress bar.
 *
 * Seeking can get complicated.  The user seeks, we tell the player to seek, the
 * player tells us that it seeked - there are a lot of ways to get into hard-to-debug
 * states.
 *
 * The `seekMillis` returned by this hook will be a number if the user is seeking
 * or undefined otherwise.
 */
export default function useSeek({
  onTimeupdate,
}: UseSeekOptions): UseSeekResult {
  const { player } = useAudioPlayer();
  const [seekDraftTime, setSeekDraftTime] = useState<number>();
  const staticOnTimeupdate = useStaticCallback(onTimeupdate);

  const { editedToOriginalTime } = useEditedIntervals();

  useEffect(() => {
    // the "seeked" event indicates that the player has completed the seek
    // it was asked to perform and the current playback time has been updated.
    // at this point, we can clear the seek draft time because it has been "committed"
    // to the player.  We also want to set the new currentTime here to prevent the
    // player from incorrectly rendering the wrong timecode while our UI catches up
    // with the player state.
    const handleSeeked = () => {
      setSeekDraftTime(undefined);
      staticOnTimeupdate(player.currentTime);
    };

    player.on('seeked', handleSeeked);

    return () => {
      player.off('seeked', handleSeeked);
    };
  }, [player, staticOnTimeupdate]);

  const handleChangeEnd = useCallback(
    (millis: number) => {
      // might not strictly be necessary but this could be a different value from
      // the draft time recorded in the last call to onChange
      setSeekDraftTime(millis);
      const adjustedTime = editedToOriginalTime(millis);
      player.seek(millisToSec(adjustedTime.adjustedMillis));
    },
    [editedToOriginalTime, player],
  );

  return {
    seekMillis: seekDraftTime,
    sliderProps: {
      onChange: setSeekDraftTime,
      onChangeEnd: handleChangeEnd,
    },
  };
}
