import { Link, Menu, HelpCircle } from '@sparemin/blockhead';
import { FAQ_URL } from 'config';
import { useZendesk } from 'state/zendesk';
import * as S from './styles';
import { HelpMenuProps } from './types';

const HelpMenuContents: React.FC<HelpMenuProps> = ({ className }) => {
  const { openZendeskWidget } = useZendesk();

  return (
    <Menu
      button={
        <S.HelpButton className={className}>
          <HelpCircle />
        </S.HelpButton>
      }
      offset={15}
      onAction={(key) => {
        if (key === 'support') {
          openZendeskWidget();
        }
      }}
    >
      <Menu.LinkItem
        href={FAQ_URL}
        inheritColor
        linkComponent={Link}
        target="_blank"
        variant="inherit"
      >
        See our FAQs
      </Menu.LinkItem>
      <Menu.Item key="support">Contact support</Menu.Item>
    </Menu>
  );
};

export default HelpMenuContents;
