import {
  GOOGLE_DRIVE_PLUGIN_ID,
  ZOOM_PLUGIN_ID,
  YOUTUBE_URL_PLUGIN_ID,
  MediaImportedEvent,
} from '@sparemin/media-import';
import { useCallback } from 'react';
import { ProviderProjectMedia } from 'api';
import { useEventTracking } from 'state/eventTracking';
import { useModalsActions } from 'state/modal';
import { getMediaTypeFromFileType } from 'utils/fileUpload';
import { useCreateProjectFromIntegrator } from '../state';
import useOnCreateProject from '../useOnCreateProject';

export interface UseHandleMediaAcceptedResult {
  isLoading: boolean;
  onMediaAccepted: (args: MediaImportedEvent) => void;
}

export default function useHandleMediaAccepted(): UseHandleMediaAcceptedResult {
  const { mutate: createProjectFromIntegrator, isLoading } =
    useCreateProjectFromIntegrator();
  const { trackIntegratorModalSelection } = useEventTracking();
  const { pushModal, hideModal } = useModalsActions();
  const { onSuccess, onError } = useOnCreateProject({ replace: true });

  const handleIntegratorProjectCreation = useCallback(
    (name: string, providerProjectMedia: ProviderProjectMedia): void => {
      // This modal (MediaUploadModal)
      hideModal();

      pushModal({
        name: 'ProjectLanguageSelector',
        params: {
          onLanguageSelected(language) {
            // The language modal (ProjectLanguageSelectorModal) - it doesn't close itself 🤷🏻‍♂️
            hideModal();

            createProjectFromIntegrator(
              {
                name,
                providerProjectMedia: {
                  language,
                  ...providerProjectMedia,
                },
              },
              {
                onError,
                onSuccess(data) {
                  onSuccess(data);
                },
              },
            );
          },
        },
      });
    },
    [createProjectFromIntegrator, hideModal, onError, onSuccess, pushModal],
  );

  const handleMediaAccepted = useCallback(
    (args: MediaImportedEvent): void => {
      const { pluginId, payload } = args;

      switch (pluginId) {
        case GOOGLE_DRIVE_PLUGIN_ID: {
          const { file, user } = payload;
          const { name, id, mimeType } = file;
          const { providerUserId } = user;

          trackIntegratorModalSelection('googleDrive');

          return handleIntegratorProjectCreation(name, {
            providerUserId,
            googleDriveFileId: id,
            mediaType: getMediaTypeFromFileType(mimeType) || 'audio',
          });
        }

        case ZOOM_PLUGIN_ID: {
          const { recording, user } = payload;
          const { id, meetingId, mimeType, meetingTopic } = recording;
          const { providerUserId } = user;

          trackIntegratorModalSelection('zoom');

          return handleIntegratorProjectCreation(meetingTopic, {
            providerUserId,
            zoomMeetingId: meetingId,
            zoomRecordingFileId: id,
            mediaType: getMediaTypeFromFileType(mimeType) || 'audio',
          });
        }

        case YOUTUBE_URL_PLUGIN_ID: {
          const { url, title = '' } = payload;

          trackIntegratorModalSelection('youtube');

          return handleIntegratorProjectCreation(title, {
            youtubeUrl: url,
            mediaType: 'video',
          });
        }

        default: {
          return undefined;
        }
      }
    },
    [handleIntegratorProjectCreation, trackIntegratorModalSelection],
  );

  return {
    isLoading,
    onMediaAccepted: handleMediaAccepted,
  };
}
