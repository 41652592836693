import { CheckoutUrlTargetPlan } from 'api/services/plan';
import { AspectRatioName, Dimensions } from 'types';

// name of the application primarily used to configure @spraemin/auth
export const APPLICATION_NAME = 'eddy';

// in the event that a user is unauthenticated and tires to access an authenticated page,
// this is the query param used to indicate the original protected URL the user was
// trying to access so that they can be redirected after authentication
export const AUTH_REDIRECT_QUERY_PARAM = 'redirect';

export const FAQ_URL =
  'https://learn.headliner.app/hc/en-us/sections/14306649172375-Eddy';

// NB: might have to change this if we need to reuse the url with a different utm_content
export const DISCO_BROCHURE_URL =
  'https://www.headliner.app/promoteyourpodcast/?utm_medium=website&utm_source=eddy_desktop&utm_content=disco_promo_unit';

export const MAIL_TO_SUPPORT_LINK = 'support@headliner.app';

export const DEFAULT_POLLING_INTERVAL_MILLIS = 2000;

export const GOOGLE_API_POLICY =
  'https://developers.google.com/terms/api-services-user-data-policy';

// 1 hour
export const TOKEN_CHECK_INTERVAL_MILLIS = 60 * 60 * 1000;

export const DEFAULT_TEXT_ENTRY_COMPLETION_DELAY = 250;

export const DEFAULT_UPSELL_PLAN: CheckoutUrlTargetPlan = 'pro20211112Monthly';

export const PRO_TIER_MAX_MEDIA_DURATION_HOURS = 4;

export const PRO_TIER_MAX_MEDIA_SIZE_GB = 2;

export const ONE_HOUR_IN_MILLIS = 3600000;

export const ASPECT_RATIOS_DIMENSIONS_BY_NAME: Record<
  AspectRatioName,
  Dimensions
> = {
  portrait: {
    width: 9,
    height: 16,
    ratio: 0.5625,
  },
  square: {
    width: 1,
    height: 1,
    ratio: 1,
  },
  landscape: {
    width: 16,
    height: 9,
    ratio: 1.7777,
  },
};
