import { useMergedRefs } from '@sparemin/blockhead';
import { memo } from 'react';
import { useResizeDetector } from 'react-resize-detector';

import { useSegmentIds } from '../../state';
import SpeakerSegment from '../SpeakerSegment';
import * as S from './styles';
import useScrollIntoView from './useScrollIntoView';
import useVirtualization from './useVirtualization/useVirtualization';

export interface SegmentsProps {}

const Segments: React.FC<SegmentsProps> = memo(() => {
  const { data: segmentIds } = useSegmentIds();

  const { ref: resizeRef, width } = useResizeDetector({ handleHeight: false });

  const { ref: virtualizationRef, virtualizer } = useVirtualization({
    listWidth: width,
  });

  useScrollIntoView({ virtualizer });

  const items = virtualizer.getVirtualItems();

  const ref = useMergedRefs([resizeRef, virtualizationRef]);

  return (
    <S.Root orientation="vertical" ref={ref} space={0}>
      <S.ScrollElement style={{ height: virtualizer.getTotalSize() }}>
        <S.VirtualWindow
          style={{
            transform: `translateY(${
              items[0].start - virtualizer.options.scrollMargin
            }px)`,
          }}
        >
          {items.map((virtualRow) => (
            <SpeakerSegment
              data-index={virtualRow.index}
              key={virtualRow.key}
              ref={virtualizer.measureElement}
              segmentId={segmentIds?.[virtualRow.index]}
            />
          ))}
        </S.VirtualWindow>
      </S.ScrollElement>
    </S.Root>
  );
});

export default Segments;
