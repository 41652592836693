import { BlockheadProvider } from '@sparemin/blockhead';
import React from 'react';
import { useThemeOverride } from 'state/displayPref';

export interface ThemeProviderProps {
  children?: React.ReactNode;
}

const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const { data: theme } = useThemeOverride();

  return (
    <BlockheadProvider theme={theme ?? 'light'}>{children}</BlockheadProvider>
  );
};

export default ThemeProvider;
