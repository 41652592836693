import { useRegisterKeybinding } from 'state/hotkey';
import { useHasVisibleModal } from 'state/modal';
import { useEditTranscript } from '../state/commands';

export default function useUndoRedo() {
  const hasVisibleModal = useHasVisibleModal();

  const { redo, undo } = useEditTranscript();

  useRegisterKeybinding(['meta+z', 'ctrl+z'], undo, {
    disabled: hasVisibleModal,
  });
  useRegisterKeybinding(['meta+y', 'ctrl+y'], redo, {
    disabled: hasVisibleModal,
  });
}
