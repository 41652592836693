import {
  breakpointDown,
  getThemeColor,
  Mic,
  ShareIcon,
  SocialButton,
  SOCIAL_BUTTON_LOGO_CONTAINER_CLASSNAME,
} from '@sparemin/blockhead';
import styled from 'styled-components';
import { UploadButtonStyleProps } from './types';

export const RootUploadButton = styled(SocialButton)`
  align-items: center;
  display: flex;
  gap: 5px;
  justify-content: center;
  min-width: 0;
  padding-right: 24px;

  .${SOCIAL_BUTTON_LOGO_CONTAINER_CLASSNAME} {
    height: 16px;
    position: relative;
    left: 0;
  }

  ${breakpointDown('md')`
    width: 50px;
    height: 50px;
    padding: 14px; 17px;
  `}
`;

export const IconWrapper = styled.div<UploadButtonStyleProps>`
  align-items: center;
  background-color: ${({ $iconBackgroundColor = 'white' }) =>
    getThemeColor($iconBackgroundColor)};
  border-radius: 50%;
  bottom: 6px;
  display: flex;
  height: 28px;
  justify-content: center;
  position: relative;
  width: 28px;
`;

export const Label = styled.span`
  white-space: nowrap;
  ${breakpointDown('md')`
    display: none;
  `}
`;

export const IntegratorIcon = styled(ShareIcon)`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  color: ${getThemeColor('d5')};

  ${breakpointDown('md')`
    width: 28px;
    height: 28px;
  `}
`;

export const UploadIcon = styled(ShareIcon)`
  width: 17px;
  height: 17px;
  color: ${getThemeColor('d5')};
`;

export const PodcastSearchIcon = styled(Mic)`
  color: ${getThemeColor('white')};
  height: 16px;
  width: 10px;
`;
