import qs from 'query-string';
import { request } from 'api/http';
import { createTranscriptUrl } from '../transcript';
import {
  REDIRECT_WIDGET_KEY,
  URL_GENERATOR_V1_AUDIO_WIZARD_REDIRECT,
  URL_GENERATOR_V1_TRACE,
  URL_GENERATOR_V1_VIDEO_WIZARD_REDIRECT,
} from './constants';
import {
  AudioWizardRedirectRequest,
  UrlGeneratorTrace,
  VideoWizardRedirectRequest,
} from './types';

export function createHeadlinerAudioWizardRedirect({
  accessToken,
  audioUrl,
  clipTitle,
  color,
  customTraceId,
  endWordId,
  imageUrl,
  projectCuid,
  startWordId,
  isClip,
}: AudioWizardRedirectRequest) {
  const transcriptUrl = createTranscriptUrl({
    accessToken,
    endWordId,
    projectCuid,
    startWordId,
  });

  return qs.stringifyUrl({
    url: URL_GENERATOR_V1_AUDIO_WIZARD_REDIRECT,
    query: {
      audioUrl,
      clipTitle,
      color,
      customTraceId,
      imageUrl,
      transcriptUrl,
      widgetKey: REDIRECT_WIDGET_KEY,
      useAudioClipper: false,
      includeEdgeAssetShift: !isClip,
    },
  });
}

export function createHeadlinerVideoWizardRedirect({
  accessToken,
  videoUrl,
  clipTitle,
  customTraceId,
  endWordId,
  projectCuid,
  startWordId,
  isClip,
}: VideoWizardRedirectRequest) {
  const transcriptUrl = createTranscriptUrl({
    accessToken,
    endWordId,
    projectCuid,
    startWordId,
  });

  return qs.stringifyUrl({
    url: URL_GENERATOR_V1_VIDEO_WIZARD_REDIRECT,
    query: {
      videoUrl,
      clipTitle,
      customTraceId,
      transcriptUrl,
      widgetKey: REDIRECT_WIDGET_KEY,
      includeEdgeAssetShift: !isClip,
    },
  });
}

export async function getTraceById(traceId: string) {
  const { data } = await request.get<UrlGeneratorTrace>(
    `${URL_GENERATOR_V1_TRACE}/${traceId}`,
  );

  return data;
}
