import { Spacer, UnstyledButton, getThemeColor } from '@sparemin/blockhead';
import styled from 'styled-components';
import { CardStyleProps } from './types';

export const Root = styled.div`
  width: 100%;
  padding: 48px;
`;

export const Banner = styled.div`
  width: 100%;
  padding: 67px 0;
  border-radius: 24px;
  background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.85),
      rgba(255, 255, 255, 0.85)
    ),
    linear-gradient(135deg, #5dbefc, #dd92fe);
`;

export const BannerContent = styled(Spacer).attrs({
  orientation: 'vertical',
  align: 'center',
  justify: 'center',
})`
  width: 100%;
  margin-top: 24px;
  margin-bottom: 32px;
`;

export const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 32px;
  margin-top: 32px;
`;

export const Card = styled(UnstyledButton)<CardStyleProps>`
  height: 200px;
  grid-column: span 2;
  border-radius: 24px;
  border: 2px solid ${getThemeColor('l4')};
  font-family: ${({ theme }) => theme.typography.fontFamily.secondary};
  font-size: 24px;
  font-weight: bold;
  color: ${getThemeColor('white')};
  background-image: ${({ $backgroundImage }) => `url(${$backgroundImage})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: ${({ theme }) => theme.transitions.create('all', '300ms')};

  &:last-child:nth-child(3n - 1) {
    grid-column-end: -2;
  }

  &:nth-last-child(2):nth-child(3n + 1) {
    grid-column-end: 4;
  }

  &[data-hovered='true'] {
    border: 2px solid ${getThemeColor('l5')};
    cursor: pointer;
  }
`;
