export default class BiMap<K, V> {
  private map = new Map<K, V>();

  private inverseMap = new Map<V, K>();

  public get(key: K) {
    return this.map.get(key);
  }

  public getKey(value: V) {
    return this.inverseMap.get(value);
  }

  public has(key: K) {
    return this.map.has(key);
  }

  public hasValue(value: V) {
    return this.inverseMap.has(value);
  }

  public set(key: K, value: V) {
    this.map.set(key, value);
    this.inverseMap.set(value, key);
  }

  public clear() {
    this.map.clear();
    this.inverseMap.clear();
  }
}
