import { useCallback } from 'react';
import { useQuery } from 'react-query';
import {
  getPromoPackContents,
  PromoPackAssetType,
  PromoPackContentsResult,
} from 'api/services';
import { QueryOptions } from 'types/react-query';
import { eddyPromoPacksQueryKeys } from './queryKeys';

import {
  episodeArtAspectRatioNameSelector,
  promoPackAssetDisplayHtmlSelector,
  promoPackAssetDisplayTextSelector,
  promoPackAssetHTMLContentSelector,
  promoPackAssetSelector,
  promoPackAssetTextContentSelector,
  promoPackContentsByTypeSelector,
  promoPackContentTypeSelector,
} from './selectors';
import { useTranscriptId } from './useEditorTranscript';

type QueryKey = ReturnType<typeof eddyPromoPacksQueryKeys.promoPacks>;

export interface UseGetPromoPackContentsOptions<TData = PromoPackContentsResult>
  extends QueryOptions<PromoPackContentsResult, TData, QueryKey> {}

function useGetPromoPackContents<TData = PromoPackContentsResult>(
  opts?: UseGetPromoPackContentsOptions<TData>,
) {
  const { data: transcriptId } = useTranscriptId();

  return useQuery<PromoPackContentsResult, unknown, TData, QueryKey>(
    eddyPromoPacksQueryKeys.promoPackContents(transcriptId),
    ({ queryKey: [{ transcriptId: id }] }) =>
      getPromoPackContents({
        transcriptId: id,
        promoPackType: 'main',
      }),
    {
      ...opts,
      enabled: !!transcriptId && (opts?.enabled ?? true),
      meta: {
        errorMessage: 'Error loading promo pack contents',
      },
    },
  );
}

export const usePromoPackContentsByType = () =>
  useGetPromoPackContents({
    select: promoPackContentsByTypeSelector,
  });

export const usePromoPackAsset = (assetType?: PromoPackAssetType) =>
  useGetPromoPackContents({
    select: useCallback(
      (d: PromoPackContentsResult) => promoPackAssetSelector(d, assetType),
      [assetType],
    ),
  });

export const usePromoPackContentType = (assetType?: PromoPackAssetType) =>
  useGetPromoPackContents({
    select: useCallback(
      (d: PromoPackContentsResult) =>
        promoPackContentTypeSelector(d, assetType),
      [assetType],
    ),
  });

export const usePromoPackAssetTextContent = (assetType?: PromoPackAssetType) =>
  useGetPromoPackContents({
    select: useCallback(
      (d: PromoPackContentsResult) =>
        promoPackAssetTextContentSelector(d, assetType),
      [assetType],
    ),
  });

export const usePromoPackAssetDisplayText = (assetType?: PromoPackAssetType) =>
  useGetPromoPackContents({
    select: useCallback(
      (d: PromoPackContentsResult) =>
        promoPackAssetDisplayTextSelector(d, assetType),
      [assetType],
    ),
  });

export const usePromoPackAssetHTMLContent = (assetType?: PromoPackAssetType) =>
  useGetPromoPackContents({
    select: useCallback(
      (d: PromoPackContentsResult) =>
        promoPackAssetHTMLContentSelector(d, assetType),
      [assetType],
    ),
  });

export const usePromoPackAssetDisplayHtml = (assetType?: PromoPackAssetType) =>
  useGetPromoPackContents({
    select: useCallback(
      (d: PromoPackContentsResult) =>
        promoPackAssetDisplayHtmlSelector(d, assetType),
      [assetType],
    ),
  });

export const useEpisodeArtAspectRatioName = () =>
  useGetPromoPackContents({
    select: episodeArtAspectRatioNameSelector,
  });
