import { applyPatches, Patch, produceWithPatches } from 'immer';
import {
  ProjectTranscript,
  TranscriptSegmentSpeakerEdit,
  UpdateProjectTranscriptSpeakerArgs,
} from 'api';
import notifyError from 'components/notification';
import AsyncUndoableTranscriptCommand from './AsyncUndoableTranscriptCommand';
import TranscriptOriginator from './TranscriptOriginator';
import { CommandOptions, CommandAction, Committer } from './types';

type UpdateSpeakerArgs = Pick<
  UpdateProjectTranscriptSpeakerArgs,
  'name' | 'segmentId' | 'speakerId'
>;

type UpdateSpeakerCommandOptions = Pick<
  CommandOptions,
  'onExecuteSuccess' | 'onUnexecuteSuccess'
>;

export default class UpdateSpeakerCommand extends AsyncUndoableTranscriptCommand {
  private patches: Patch[] | undefined;

  private inversePatches: Patch[] | undefined;

  private inverseEdits: TranscriptSegmentSpeakerEdit[] | undefined;

  private originalSpeakerName: string | undefined = undefined;

  constructor(
    private args: UpdateSpeakerArgs,
    originator: TranscriptOriginator,
    private speakerUpdateCommitter: Committer<UpdateProjectTranscriptSpeakerArgs>,
    private speakerIdReassignmentCommitter: Committer<{
      edits: TranscriptSegmentSpeakerEdit[];
    }>,
    opts?: UpdateSpeakerCommandOptions,
  ) {
    super(originator, opts);
  }

  protected onError(action: CommandAction) {
    if (action === 'commit') {
      notifyError({
        heading: 'Error updating speaker',
        errorCode: 'ER008',
      });
    }
  }

  /**
   * The speaker updates are performed as follows (note that these steps are only
   * for the optimistic update, as all updates are committed using the same
   * API endpoint):
   *
   * 1 - Updating a single instance of a speaker in a given "segmentId":
   *   a. Update the speaker in "segmentId" to "newName".
   *   b. Look for another speaker in the transcript with "newName" and note the speaker id
   *   c. Assign speakerId from 1.b to the speaker in "segmentId".
   *
   * 2 - Updating all instances of a speaker to a new, unused name:
   *   a. Replace all instances of "oldName" with "newName".
   *      All of these speakers should already have the same id.
   *
   * 3 - Updating all instances of a speaker to a name already in use:
   *   a. Look for an existing speaker with "newName"  and note the speakerId.
   *   b. Update all speakers with "oldName" to "newName".
   *   c. Update all speaker ids in 3.b to speakerId found in 3.a.
   *
   * The above cases are "undone" as follows (note that these steps are only for the
   * "uncommit" action as applying inversePatches will roll back the optimistic
   * update):
   * 1 - Undo the update of a single speaker
   *   a. Use the bulk-update endpoint to assign the original speaker id back to
   *      the segment that was updated
   *
   * 2- Undo updating all instances of a speaker to a new, unused name
   *   a. Use the speaker-update endpoint to "update all" instances of the speaker
   *      id to the original speaker name
   *
   * 3 - Undo updating all instances of a speaker to a name already in use:
   *   a. Use the bulk-update endpoint to assign each affected segment to its original
   *      speaker id
   */
  protected executeTranscript(
    transcript: ProjectTranscript,
  ): ProjectTranscript | undefined {
    if (!this.patches || !this.inversePatches || !this.inverseEdits) {
      // inverseEdits are needed for "undo" operations 1 and 3 above.  Basically
      // if a new speaker id gets assigned to a segment, we need to keep track of
      // the operation needed to revert that change.  This information can be
      // obtained by inspecting the immer patch created by the optimistic update,
      // but the type for that is somewhat opaque, so assumptions need to be made
      // to process it correctly
      this.inverseEdits = [];

      const [nextTranscript, patches, inversePatches] = produceWithPatches(
        transcript,
        (draft) => {
          // #1.b, #3.a
          const matchingCurrentSpeakerId = draft.segments.find(
            (s) => s.speaker?.name === this.args.name,
          )?.speaker?.id;

          // #1
          if (this.args.segmentId !== undefined) {
            const segment = draft.segments.find(
              (s) => s.id === this.args.segmentId,
            );

            // sanity check - this should always be true
            if (segment?.speaker?.id === this.args.speakerId) {
              // #1.a
              segment.speaker.name = this.args.name;

              // the backend will either create a new speakerId for the speaker
              // name or it will reuse an id already mapped to that name.  either way,
              // the operation is undone by reassigning the original speakerId
              this.inverseEdits?.push({
                segmentId: segment.id,
                speakerId: segment.speaker.id,
              });

              if (matchingCurrentSpeakerId) {
                // #1.b
                segment.speaker.id = matchingCurrentSpeakerId;
              }
            }

            return;
          } else {
            // #2, #3
            draft.segments.forEach((segment) => {
              const speaker = segment.speaker;

              if (speaker?.id === this.args.speakerId) {
                this.originalSpeakerName = speaker.name;

                // #2.a, #3.b
                speaker.name = this.args.name;

                if (matchingCurrentSpeakerId) {
                  this.originalSpeakerName = undefined;

                  this.inverseEdits?.push({
                    segmentId: segment.id,
                    speakerId: speaker.id,
                  });

                  // #3.c
                  speaker.id = matchingCurrentSpeakerId;
                }
              }
            });
          }
        },
      );

      this.patches = patches;
      this.inversePatches = inversePatches;

      return nextTranscript;
    }

    return applyPatches(transcript, this.patches);
  }

  protected unexecuteTranscript(
    transcript: ProjectTranscript,
  ): ProjectTranscript | undefined {
    if (!this.inversePatches) {
      return undefined;
    }

    return applyPatches(transcript, this.inversePatches);
  }

  async commit(): Promise<void> {
    const transcriptId = this.originator.createMemento()?.id;

    if (transcriptId) {
      try {
        return await this.speakerUpdateCommitter({
          name: this.args.name,
          segmentId: this.args.segmentId,
          speakerId: this.args.speakerId,
          transcriptId,
        });
      } catch (err) {
        this.onError('commit');
        throw err;
      }
    }
  }

  async uncommit(): Promise<void> {
    const transcriptId = this.originator.createMemento()?.id;

    if (transcriptId) {
      try {
        if (this.inverseEdits?.length) {
          this.speakerIdReassignmentCommitter({ edits: this.inverseEdits });
        } else if (this.originalSpeakerName) {
          this.speakerUpdateCommitter({
            transcriptId,
            name: this.originalSpeakerName,
            segmentId: this.args.segmentId,
            speakerId: this.args.speakerId,
          });
        }
      } catch (err) {
        this.onError('uncommit');
        throw err;
      }
    }
  }
}
