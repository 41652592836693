import { Tooltip as BlockheadTooltip, TooltipProps } from '@sparemin/blockhead';
import React from 'react';
import styled from 'styled-components';

const Root = styled(BlockheadTooltip).attrs({
  arrow: true,
})`
  width: max-content;
`;

const Container = styled.div`
  user-select: auto !important;
`;

const Tooltip: React.FC<Omit<TooltipProps, 'arrow'>> = ({
  children,
  ...props
}) => (
  <Root {...props}>
    <Container>{children}</Container>
  </Root>
);

export default Tooltip;
