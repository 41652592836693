import { SearchArrowControlsProps, SearchField } from '@sparemin/blockhead';
import React from 'react';
import { SetOptional } from 'type-fest';

export type ArrowControlsProps = SetOptional<
  SearchArrowControlsProps,
  'prevTooltipProps' | 'nextTooltipProps'
>;

const ArrowControls: React.FC<ArrowControlsProps> = (props) => (
  <SearchField.ArrowControl
    prevTooltipProps={{
      contents: 'Previous ⇧ + ↵',
      placement: 'bottom',
      arrow: true,
    }}
    nextTooltipProps={{
      contents: 'Next ↵',
      placement: 'bottom',
      arrow: true,
    }}
    {...props}
  />
);

export default ArrowControls;
