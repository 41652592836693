import {
  LogoutIcon,
  Menu,
  AccountMenu as BlockheadAccountMenu,
} from '@sparemin/blockhead';
import { useCallback } from 'react';
import { useAuth } from 'state/auth';
import useEmailAddress from 'state/user/useEmailAddress';

export interface AccountMenuProps {}

const AccountMenu: React.FC<AccountMenuProps> = () => {
  const { logout } = useAuth();
  const { data: emailAddress } = useEmailAddress();

  const handleMenuAction = useCallback(
    (key: string | number) => {
      if (key === 'logout') {
        logout();
      }
    },
    [logout],
  );

  return !emailAddress ? null : (
    <BlockheadAccountMenu
      emailAddress={emailAddress}
      onAction={handleMenuAction}
    >
      <Menu.Item icon={<LogoutIcon />} key="logout">
        Log out
      </Menu.Item>
    </BlockheadAccountMenu>
  );
};

export default AccountMenu;
