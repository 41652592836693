import DeletedWordsVisibilityButton from './DeletedWordsVisibilityButton';
import PlayButton from './PlayButton';
import ProgressBar from './ProgressBar';
import * as S from './styles';

export interface PlaybarProps {}

const Playbar: React.FC<PlaybarProps> = () => (
  <S.Container>
    <S.Content>
      <PlayButton />
      <ProgressBar />
      <DeletedWordsVisibilityButton />
    </S.Content>
  </S.Container>
);

export default Playbar;
