import { useQuery } from 'react-query';
import { FavoritePodcastsResponse, getFavoritePodcasts } from 'api';
import { useAuth } from 'state/auth';
import { QueryOptions } from 'types';
import { podcastQueryKeys } from './queryKeys';
import {
  hasDefaulPodcastSelector,
  myDefaultPodcastSelector,
} from './selectors';

type QueryKey = ReturnType<typeof podcastQueryKeys.myFavoritePodcasts>;

type UseFavoritePodcastsOptions<TData = FavoritePodcastsResponse> =
  QueryOptions<FavoritePodcastsResponse, TData, QueryKey>;

function useFavoritePodcasts<TData = FavoritePodcastsResponse>(
  opts?: UseFavoritePodcastsOptions<TData>,
) {
  const { enabled = true } = opts ?? {};
  const { userId } = useAuth();

  return useQuery({
    queryKey: podcastQueryKeys.myFavoritePodcasts(userId),
    queryFn: getFavoritePodcasts,
    ...opts,
    enabled: !!userId && enabled,
  });
}

export const useMyDefaultPodcast = () =>
  useFavoritePodcasts({
    select: myDefaultPodcastSelector,
  });

export const useHasDefaultPodcast = () =>
  useFavoritePodcasts({
    select: hasDefaulPodcastSelector,
  });
