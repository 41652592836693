import { ContainerProps, SearchField } from '@sparemin/blockhead';
import React, { useRef } from 'react';
import { useClickOutside } from 'hooks/useClickOutside';
import ArrowControls from './ArrowControls';
import * as S from './styles';

export type ExplorerBarProps = React.PropsWithChildren<{
  className?: string;
  clickOutsideIgnoreSelector?: string;
  isOpen: boolean;
  onClose: () => void;
}> &
  Pick<ContainerProps, 'gutter' | 'onTransitionEnd'>;

interface ExplorerBarComponent extends React.FC<ExplorerBarProps> {
  ArrowControls: typeof ArrowControls;
  ClearButton: typeof SearchField.ClearButton;
  SearchField: typeof SearchField;
  Input: typeof SearchField.Input;
  ResultsCount: typeof SearchField.ResultsCount;
}

const ExplorerBar: ExplorerBarComponent = ({
  children,
  className,
  clickOutsideIgnoreSelector,
  gutter,
  isOpen,
  onClose,
  onTransitionEnd,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useClickOutside(ref, onClose, {
    disabled: !isOpen,
    ignoreSelector: clickOutsideIgnoreSelector,
  });

  return (
    <S.Root
      data-isopen={isOpen}
      {...{ className, gutter, ref, onTransitionEnd }}
    >
      {children}
    </S.Root>
  );
};

ExplorerBar.ArrowControls = ArrowControls;
ExplorerBar.ClearButton = SearchField.ClearButton;
ExplorerBar.SearchField = SearchField;
ExplorerBar.Input = SearchField.Input;
ExplorerBar.ResultsCount = SearchField.ResultsCount;

export default ExplorerBar;
