import { Heading } from '@sparemin/blockhead';
import { useCallback } from 'react';
import MailToSupport from 'components/MailToSupport';
import Header from './Header';
import * as S from './styles';

export interface ErrorPageProps {}

const ErrorPage: React.FC<ErrorPageProps> = () => {
  const handleRefreshPage = useCallback((): void => {
    window.location.reload();
  }, []);

  return (
    <S.Page>
      <Header />

      <S.Contents>
        <Heading level={1}>We need a refresh</Heading>

        <S.Description>
          Something’s wrong, please refresh this page so we can fix it. If the
          issue persists, please <MailToSupport /> for further assistance.
        </S.Description>

        <S.RefreshButton onPress={handleRefreshPage}>
          Refresh this page
        </S.RefreshButton>
      </S.Contents>
    </S.Page>
  );
};

export default ErrorPage;
