import MediaUpload from './MediaUploadModal';
import ProjectLanguageSelectorModal from './ProjectLanguageSelectorModal';

export interface ModalsProps {}

const Modals: React.FC<ModalsProps> = () => (
  <>
    <ProjectLanguageSelectorModal />
    <MediaUpload />
  </>
);

export default Modals;
