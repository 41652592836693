import { useMemo } from 'react';
import TabPanelContext from './TabPanelContext';
import { TabPanelProviderProps } from './types';

const TabPanelProvider: React.FC<TabPanelProviderProps> = ({
  adjustmentValues,
  colorPaletteState,
  onAdjustmentValueChange,
  children,
}) => (
  <TabPanelContext.Provider
    value={useMemo(
      () => ({
        adjustmentValues,
        colorPaletteState,
        onAdjustmentValueChange,
      }),
      [adjustmentValues, colorPaletteState, onAdjustmentValueChange],
    )}
  >
    {children}
  </TabPanelContext.Provider>
);

export default TabPanelProvider;
