import { useContext } from 'react';
import { EditTranscriptContext } from './EditTranscriptContext';

export default function useEditTranscript() {
  const context = useContext(EditTranscriptContext);

  if (context === undefined) {
    throw new Error(
      'useEditTranscript must be used within EditTranscriptProvider',
    );
  }

  return context;
}
