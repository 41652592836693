import { DeleteOutline } from '@sparemin/blockhead';
import React, { useCallback } from 'react';
import { useEditTranscript } from 'pages/TranscriptEditorPage/state';
import { useEventTracking } from 'state/eventTracking';
import { useRegisterKeybinding } from 'state/hotkey';
import {
  isWordsContextMenuConfig,
  useContextMenuActions,
  useContextMenuConfig,
} from '../../../state/contextMenu';
import ActionButton from '../../ActionButton';

export interface DeleteActionButtonProps {}

const DeleteActionButton: React.FC<DeleteActionButtonProps> = () => {
  const config = useContextMenuConfig();
  const { onAction } = useContextMenuActions();
  const { trackHighlightPopperClicked } = useEventTracking();

  const { deleteWords } = useEditTranscript();

  const handleDelete = useCallback(() => {
    if (!isWordsContextMenuConfig(config)) {
      // only words can be deleted, so if we don't have a configuration with
      // words, bail
      return;
    }
    trackHighlightPopperClicked({ buttonClick: 'Delete' });
    deleteWords(config.words);
    onAction({ type: 'delete-word', config });
  }, [config, deleteWords, onAction, trackHighlightPopperClicked]);

  useRegisterKeybinding(['Backspace', 'Delete'], handleDelete);

  return (
    <ActionButton
      aria-label="delete"
      onPress={handleDelete}
      tooltip={'Delete [backspace]'}
    >
      <ActionButton.Icon as={DeleteOutline} />
    </ActionButton>
  );
};

export default DeleteActionButton;
