import { MotionProps } from 'framer-motion';
import { TOP_BAR_TRANSITION_DURATION_SEC } from '../BaseTabPanel/constants';
import { BASE_ADJUSTMENTS_BAR_INITIAL_Y_AXIS_POSITION } from './constants';

const DEFAULT_ANIMATION_STATE = {
  y: BASE_ADJUSTMENTS_BAR_INITIAL_Y_AXIS_POSITION,
  opacity: 0,
};

export const baseAdjustmentsBarAnimationProps: MotionProps = {
  transition: {
    duration: TOP_BAR_TRANSITION_DURATION_SEC,
  },
  initial: DEFAULT_ANIMATION_STATE,
  animate: {
    y: 0,
    opacity: 1,
  },
  exit: DEFAULT_ANIMATION_STATE,
};
