import { PulseLoader } from '@sparemin/blockhead';
import { useTranscriptId } from 'pages/TranscriptEditorPage/state';
import { useHasPromoPacks } from 'pages/TranscriptEditorPage/state/useGetPromoPacks';
import { useGetFirstSuggestedShowNotes } from 'pages/TranscriptEditorPage/state/useGetTranscriptNoteSuggestions';
import { useIsIntegrator } from 'state/user';
import { queryStatusResolver } from 'state/utils';
import PromoPack from '../PromoPack';
import ShareAutogeneratedSummary from '../ShareAutogeneratedSummary';
import ShareInitialActionButtonsLayout from './ShareInitialActionButtonsLayout';
import * as S from './styles';
import { ActionButtonsProps } from './types';
import useActionButtons from './useActionButtons';

export type ShareInitialBodyProps = ActionButtonsProps;

const ShareInitialBody = (props: ShareInitialBodyProps) => {
  const { data: transcriptId, status: transcriptIdStatus } = useTranscriptId();
  const { data: hasPromoPacks, status: promoPacksStatus } = useHasPromoPacks();
  const { data: isIntegrator, status: isIntegratorStatus } = useIsIntegrator();
  const { status: firstSuggestedShowNotesStatus } =
    useGetFirstSuggestedShowNotes(transcriptId);

  const actionButtons = useActionButtons(props);

  const fetchStatus = queryStatusResolver([
    transcriptIdStatus,
    promoPacksStatus,
    isIntegratorStatus,
    firstSuggestedShowNotesStatus,
  ]);

  return (
    <S.Body>
      <S.Content>
        <S.ActionsGrid>
          <ShareInitialActionButtonsLayout buttons={actionButtons} />
        </S.ActionsGrid>

        <S.NotesContainer>
          {fetchStatus === 'loading' && <PulseLoader />}

          {fetchStatus === 'success' && !isIntegrator && hasPromoPacks ? (
            <PromoPack />
          ) : (
            <ShareAutogeneratedSummary />
          )}
        </S.NotesContainer>
      </S.Content>
    </S.Body>
  );
};

export default ShareInitialBody;
