import { TimeInterval } from 'types';

/**
 * Comparator should return:
 *  0 if t == x
 *  a negative number if x < t
 *  a positive number if x > t
 */
export type Comparator<T, X> = (t: T, x: X) => number;

export function search<T, X>(
  arr: T[],
  x: X,
  compare: Comparator<T, X>,
  lo = 0,
  hi = arr.length - 1,
): number {
  if (lo > hi) {
    return -1;
  }

  const mid = Math.floor((lo + hi) / 2);
  const comparison = compare(arr[mid], x);

  if (comparison === 0) {
    return mid;
  }

  if (comparison < 0) {
    return search(arr, x, compare, lo, mid - 1);
  }

  return search(arr, x, compare, mid + 1, hi);
}

export function intervalComparator(interval: TimeInterval, value: number) {
  if (value < interval.startMillis) {
    return -1;
  }

  if (value > interval.endMillis) {
    return 1;
  }

  return 0;
}
