import { RouterProvider as ReactRouterProvider } from 'react-router-dom';
import router from './router';

export interface RouterProviderProps {}

const RouterProvider: React.FC<RouterProviderProps> = () => (
  <ReactRouterProvider router={router} />
);

export default RouterProvider;
