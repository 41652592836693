import React from 'react';
import { dislikeClipFeedbackValue } from './constants';
import DislikeButton from './DislikeButton';
import * as S from './styles';

interface SuggestedClipDislikePopoverContentsProps {}

const SuggestedClipDislikePopoverContents: React.FC<
  SuggestedClipDislikePopoverContentsProps
> = () => (
  <S.Container>
    {dislikeClipFeedbackValue.map((reason) => {
      return <DislikeButton key={reason} value={reason} />;
    })}
  </S.Container>
);

export default SuggestedClipDislikePopoverContents;
