import {
  FileDropzone,
  breakpointDown,
  Spacer,
  getThemeColor,
} from '@sparemin/blockhead';
import styled from 'styled-components';

export const Root = styled(FileDropzone)`
  ${breakpointDown('md')`
    padding: 40px 23px 40px 24px;
  `}
`;

export const Container = styled(Spacer)`
  color: ${getThemeColor('d1')};
`;
