import * as S from './styles';
import { ShowFillerWordsButtonProps } from './types';

const ShowFillerWordsButton: React.FC<ShowFillerWordsButtonProps> = (props) => {
  const { onPress } = props;

  return (
    <S.Root typography="text" variant="button" onPress={onPress}>
      Show filler words
    </S.Root>
  );
};

export default ShowFillerWordsButton;
