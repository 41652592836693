import React from 'react';

import ChapterArtDropzone from './ChapterArtDropzone';
import ChapterArtPreview from './ChapterArtPreview';
import * as S from './styles';

interface ChapterArtProps {
  imageUrl?: string;
  isDisabled?: boolean;
  onChapterArtUploaded: (imageUrl: string) => void;
  onDeleteChapterArt: () => void;
}

const ChapterArt: React.FunctionComponent<ChapterArtProps> = ({
  imageUrl,
  isDisabled,
  onChapterArtUploaded,
  onDeleteChapterArt,
}) => {
  return (
    <S.ChapterArtContainer $isDisabled={isDisabled}>
      {imageUrl ? (
        <ChapterArtPreview {...{ imageUrl, onDeleteChapterArt }} />
      ) : (
        <ChapterArtDropzone onChapterArtUploaded={onChapterArtUploaded} />
      )}
    </S.ChapterArtContainer>
  );
};

export default ChapterArt;
