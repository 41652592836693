import { PulseLoader } from '@sparemin/blockhead';
import React, { useState } from 'react';
import { DISCO_BROCHURE_URL } from 'config';
import useIsMobileViewport from 'hooks/useIsMobileViewport';
import * as S from './styles';
import UpgradeButton from './UpgradeButton';
import useDisco from './useDisco';

export interface DiscoSectionProps {
  className?: string;
}

const DiscoSection = React.forwardRef<HTMLElement, DiscoSectionProps>(
  ({ className }, ref) => {
    const isMobile = useIsMobileViewport();

    const [widgetContainer, setWidgetContainer] =
      useState<HTMLDivElement | null>(null);

    useDisco({
      injectionPoint: widgetContainer,
    });

    return (
      <S.Root className={className} ref={ref}>
        <S.Contents>
          {!isMobile && (
            <>
              <S.Heading>Enjoy these clips while you wait!</S.Heading>

              <S.Subheading>
                These clips keep Headliner free & support the podcast community.
              </S.Subheading>

              <div>
                <S.Link
                  href={DISCO_BROCHURE_URL}
                  target="_blank"
                  rel="noopener"
                >
                  Promote your episode here
                </S.Link>

                <S.LinksDivider />

                <UpgradeButton />
              </div>
            </>
          )}

          <S.WidgetContainer ref={setWidgetContainer}>
            <PulseLoader />
          </S.WidgetContainer>

          {isMobile && widgetContainer && <UpgradeButton />}
        </S.Contents>
      </S.Root>
    );
  },
);

export default DiscoSection;
