import { Heading } from '@sparemin/blockhead';
import styled from 'styled-components';

export const Container = styled(Heading).attrs({
  level: 3,
})`
  letter-spacing: normal;
  line-height: 1;
  min-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 24px;
`;
