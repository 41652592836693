import styled from 'styled-components';
import Plate from 'components/Plate';

export const whileOpen = (style: TemplateStringsArray | string) => `
  &[data-isopen='true'] {
    ${style};
  }
`;

export const Root = styled(Plate)`
  display: flex;
  height: 50px;
  justify-content: flex-start;
  transition: ${({ theme }) => theme.transitions.create(['width'], '300ms')};

  ${whileOpen`
    width: 100%;
  `}
`;
