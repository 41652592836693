import { QueryClient, QueryKey } from 'react-query';
import { ProjectTranscript } from 'api';
import { Originator } from './types';

export default class TranscriptOriginator
  implements Originator<ProjectTranscript | undefined>
{
  constructor(private queryClient: QueryClient, private queryKey: QueryKey) {}

  public createMemento(): ProjectTranscript | undefined {
    return this.queryClient.getQueryData(this.queryKey);
  }

  public setMemento(value: ProjectTranscript | undefined): void {
    // if query state is going to be replaced, cancel pending queries so that they
    // don't overwrite the state being set here
    this.queryClient.cancelQueries({ queryKey: this.queryKey });
    this.queryClient.setQueryData<ProjectTranscript | undefined>(
      this.queryKey,
      value,
    );
  }
}
