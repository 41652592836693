import { useMutation } from 'react-query';
import {
  regenerateProjectAsset,
  RegenerateProjectAssetRequest,
  RegenerateProjectAssetResponse,
} from 'api';
import { MutationOptions } from 'types';
import { useTranscriptId } from './useEditorTranscript';

export default function useRegenerateProjectAsset(
  opts?: MutationOptions<
    RegenerateProjectAssetResponse,
    RegenerateProjectAssetRequest
  >,
) {
  const { data: projectTranscriptId } = useTranscriptId();

  return useMutation<
    RegenerateProjectAssetResponse,
    unknown,
    RegenerateProjectAssetRequest,
    unknown
  >(async (args) => {
    if (!projectTranscriptId) {
      throw new Error('Error regenerating asset');
    }

    const response = await regenerateProjectAsset({
      ...args,
      projectTranscriptId,
    });

    return response;
  }, opts);
}
