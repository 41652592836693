// this is the minimum duration of any word in the transcript whose duration is
// modified by an edit.  some words might have a smaller duration than this if
// they were originally transcribed with a shorter duration
export const EDITED_WORD_MIN_DURATION_MILLIS = 50;

// when deleting a word, the times are shifted slightly so that portions of the deleted
// word don't leak into the undeleted audio
export const DELETION_TIME_SHIFT_MILLIS = 50;

// how often to sample the current time during audio playback
export const TIMEUPDATE_POLLING_INTERVAL_MILLIS = 20;

export const SEEK_EPSILON_MILLIS = 1;

export const TRANSCRIPT_CONTAINER_WIDTH = 850;

export const WHITESPACE_WORD = '•';

export const EMPTY_TRANSCRIPT_ERROR_MESSAGE = {
  title: 'Unfortunately, the uploaded file is not a supported file type',
  description:
    'Please make sure the file format is one of the following: MP3, AAC, or WAV. If you encounter further issues, please contact our support team for further assistance.',
};

export const FILLER_WORDS = ['um', 'uh', 'hmm', 'mhm', 'huh', 'uh huh'];

export const INTRO_OUTRO_MAX_SIZE_MB = 25;

export const INTRO_OUTRO_MAX_LENGTH_SECS = 60;
