export const TRANSITION_DURATION_MILLIS = 600;

export const TRANSITION_DURATION_SECS = TRANSITION_DURATION_MILLIS / 1000;

export const LOADER_HANGTIME_MILLIS = 2000;

export const TOTAL_LOADER_TRANSITION_TIME_MILLIS =
  LOADER_HANGTIME_MILLIS + TRANSITION_DURATION_MILLIS;

export const DEFAULT_ANIMATION_DURATION_SECS = 1;

export const DEFAULT_FADE_OUT_DURATION_SECS = TRANSITION_DURATION_SECS / 2;

export const LAST_ANIMATION_DELAY_SECS = 2.9;
