import { EditorUiStore } from './types';

export const actionsSelector = (s: EditorUiStore) => s.actions;

export const activeWordIdSelector = (s: EditorUiStore) => s.activeWordId;

export const scrollToWordIdSelector = (s: EditorUiStore) => s.scrollToWordId;

export const showDeletedWordsSelector = (s: EditorUiStore) =>
  s.showDeletedWords;
