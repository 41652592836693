import {
  AuthStep,
  LoginCard as SpareminLoginCard,
  LoginProvider,
  UserAuthenticationModal,
  UserAuthResult,
} from '@sparemin/auth';
import { useCallback, useEffect } from 'react';
import useAuthSuccess from 'hooks/useAuthSuccess';
import useToggle from 'hooks/useToggle';
import { useEventTracking } from 'state/eventTracking';

const AUTH_MODAL_FEATURES: AuthStep[] = ['forgot-password'];

export interface LoginCardProps {}

const LoginCard: React.FC<LoginCardProps> = () => {
  const { onAuthSuccess } = useAuthSuccess();
  const { trackForgotPasswordClick, trackLoginPageLoaded, trackLoginError } =
    useEventTracking();

  useEffect(() => {
    trackLoginPageLoaded();
  }, [trackLoginPageLoaded]);

  const {
    value: isOpen,
    toggleOn: openModal,
    toggleOff: closeModal,
  } = useToggle(false);

  const handleForgotPasswordClick = () => {
    trackForgotPasswordClick();
    openModal();
  };

  return (
    <>
      <SpareminLoginCard
        onForgotPasswordPress={handleForgotPasswordClick}
        onAuthenticateSuccess={useCallback(
          async (_: LoginProvider, result: UserAuthResult) => {
            onAuthSuccess(result);
          },
          [onAuthSuccess],
        )}
        onError={trackLoginError}
      />
      <UserAuthenticationModal
        features={AUTH_MODAL_FEATURES}
        initialStep="forgot-password"
        isOpen={isOpen}
        onClose={closeModal}
      />
    </>
  );
};

export default LoginCard;
