import { useCallback } from 'react';
import { TranscriptFormat } from 'api/services/transcript';
import useExportAudio from './useExportAudio';
import useExportTranscript from './useExportTranscript';

export interface UseBulkExportOptions {
  exportAudio?: boolean;
  onError?: () => void;
  onSuccess?: () => void;
  projectCuid: string;
  transcriptFormats?: TranscriptFormat[];
}

export default function useBulkExport() {
  const { exportTranscriptAsync } = useExportTranscript();
  const { exportAudio } = useExportAudio();

  const bulkExport = useCallback(
    async ({
      exportAudio: isExportAudio,
      onError,
      onSuccess,
      projectCuid,
      transcriptFormats = [],
    }: UseBulkExportOptions) => {
      try {
        for (const format of transcriptFormats) {
          await exportTranscriptAsync({ format, projectCuid });
        }

        if (isExportAudio) {
          exportAudio({ projectCuid }, { onError, onSuccess });
        } else {
          onSuccess?.();
        }
      } catch {
        onError?.();
      }
    },
    [exportAudio, exportTranscriptAsync],
  );

  return { bulkExport };
}
