import { FileDropzoneAccept } from '@sparemin/blockhead';

export const formatAcceptedFileTypes = (
  acceptedFileTypes: FileDropzoneAccept,
) => {
  return Object.entries(acceptedFileTypes).flatMap(([mimeType, extensions]) =>
    extensions.map(
      (extension) => `${mimeType.split('/')[0]}/${extension.slice(1)}`,
    ),
  );
};
