import {
  ProjectAssetFormalityFeedback,
  ProjectAssetLengthFeedback,
  ProjectAssetToneFeedback,
} from 'api';
import { RegenerationOptionsListItem } from './types';

export const lengthFeedbackOptions: RegenerationOptionsListItem<ProjectAssetLengthFeedback>[] =
  [
    {
      value: 'keepAsIs',
      label: '👍 Keep as is',
    },
    {
      value: 'shorter',
      label: '✂️ Shorter',
    },
    {
      value: 'longer',
      label: '📚 Longer',
    },
  ];

export const formalityFeedbackOptions: RegenerationOptionsListItem<ProjectAssetFormalityFeedback>[] =
  [
    {
      value: 'neutral',
      label: '🙂 Neutral',
    },
    {
      value: 'casual',
      label: '😎 Casual',
    },
    {
      value: 'formal',
      label: '🧐 Formal',
    },
  ];

export const toneFeedbackOptions: RegenerationOptionsListItem<ProjectAssetToneFeedback>[] =
  [
    {
      value: 'direct',
      label: '🎯 Direct',
    },
    {
      value: 'empathetic',
      label: '💖 Empathetic',
    },
    {
      value: 'witty',
      label: '🧠 Witty',
    },
  ];
