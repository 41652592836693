import { useMutation } from 'react-query';
import { RequireAtLeastOne } from 'type-fest';
import { createProject, CreateProjectResult, PodcastProjectMedia } from 'api';
import { MutationOptions } from 'types';

export type CreateProjectFromPodcastArgs = Omit<
  PodcastProjectMedia,
  'mediaType'
> &
  RequireAtLeastOne<{
    projectName?: string;
    episodeName?: string;
  }>;

function mutationFn({
  podcastId,
  remoteEpisodeId,
  projectName,
  episodeName,
  language,
}: CreateProjectFromPodcastArgs) {
  return createProject({
    associatedMedias: [
      {
        language,
        podcastId,
        remoteEpisodeId,
        mediaType: 'audio',
      },
    ],
    name: projectName ?? episodeName,
  });
}

export default function useCreateProjectFromPodcast(
  opts?: MutationOptions<CreateProjectResult, CreateProjectFromPodcastArgs>,
) {
  return useMutation<
    CreateProjectResult,
    unknown,
    CreateProjectFromPodcastArgs,
    unknown
  >(mutationFn, opts);
}
