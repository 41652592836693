import { Trash } from '@sparemin/blockhead';
import React, { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { EdgeAssetType } from 'api';
import {
  eddyProjectsQueryKeys,
  useProjectCuid,
} from 'pages/TranscriptEditorPage/state';
import useDeleteEdgeAsset from 'pages/TranscriptEditorPage/state/useDeleteEdgeAsset';
import { useAuth } from 'state/auth';
import * as S from './styles';

interface DeleteAssetButtonProps {
  variant: EdgeAssetType;
}

const DeleteAssetButton: React.FC<DeleteAssetButtonProps> = ({ variant }) => {
  const { mutate: deleteAsset } = useDeleteEdgeAsset();
  const queryClient = useQueryClient();
  const { userId } = useAuth();
  const { data: projectCuid } = useProjectCuid();

  const handleDelete = useCallback(() => {
    deleteAsset(
      { edgeAssetType: variant },
      {
        onSettled: () => {
          queryClient.invalidateQueries(
            eddyProjectsQueryKeys.myProject(userId, projectCuid),
          );
        },
      },
    );
  }, [deleteAsset, variant, queryClient, userId, projectCuid]);

  return (
    <S.ActionButton
      onPress={handleDelete}
      aria-label={`delete-${variant}-asset`}
    >
      <Trash />
    </S.ActionButton>
  );
};
export default DeleteAssetButton;
