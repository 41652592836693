import {
  CheckoutPostMessagePayload,
  CHECKOUT_POSTMESSAGE_SOURCE,
} from 'api/services/plan';

export function isCheckoutMessage(
  event: MessageEvent,
): event is MessageEvent<CheckoutPostMessagePayload> {
  return event?.data.source === CHECKOUT_POSTMESSAGE_SOURCE;
}
