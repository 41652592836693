import React from 'react';
import AIPodcastSearchModalContents from './AIPodcastSearchModalContents';
import * as S from './styles';

export interface AIPodcastSearchModalProps {}

const AIPodcastSearchModal: React.FC<AIPodcastSearchModalProps> = () => (
  <S.ConnectedModal name="AIPodcastSearch">
    <AIPodcastSearchModalContents />
  </S.ConnectedModal>
);

export default AIPodcastSearchModal;
