import { useCallback } from 'react';
import { TranscriptWord } from 'api';
import { useAudioPlayer } from '../AudioPlayerContext';
import { useWordList, useWordsById } from '../state';
import { useEditorUiActions, useWithDisabledWordSync } from '../state/editorUi';
import { getFirstUndeletedWord } from '../Transcript/utils';
import { seekToWord } from '../utils';

export default function useActivateWord() {
  const { data: wordsById } = useWordsById();
  const { data: words } = useWordList();
  const { activateWord } = useEditorUiActions();
  const { player } = useAudioPlayer();

  const withDisabledWordSync = useWithDisabledWordSync();

  return useCallback(
    (word: TranscriptWord): void => {
      if (!word || !words || !wordsById) {
        return;
      }

      const wordId = word.id;

      if (word.isDeleted) {
        const nextActiveWord = getFirstUndeletedWord(wordId, words, wordsById);

        withDisabledWordSync(() => {
          activateWord(wordId);
          seekToWord(player, nextActiveWord?.startMillis ?? 0);
        });

        return;
      }

      seekToWord(player, word.startMillis);
    },
    [activateWord, player, withDisabledWordSync, words, wordsById],
  );
}
