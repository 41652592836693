import {
  Button,
  Heading,
  HeadySparkle,
  Spacer,
  UnstyledLink,
} from '@sparemin/blockhead';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from 'pages/ProjectsPage/Header';
import paths from 'paths';
import { useEventTracking } from 'state/eventTracking';
import * as S from './styles';

export interface NonOwnerViewProps {}

const NonOwnerView: React.FC<NonOwnerViewProps> = () => {
  const { trackTranscriptPageGatedLoaded } = useEventTracking();

  useEffect(() => {
    trackTranscriptPageGatedLoaded();
  }, [trackTranscriptPageGatedLoaded]);

  return (
    <S.Page>
      <Header />

      <S.Contents>
        <HeadySparkle width="123px" height="100px" />

        <Spacer orientation="vertical" align="center" space="13px">
          <Heading level={1}>You don’t have access to this project</Heading>

          <Heading level={2}>
            We’re working on a more collaborative experience so if you’d like to
            provide feedback on your ideal workflow please take our quick
            survey.
          </Heading>
        </Spacer>

        <Spacer orientation="vertical" align="center" space="16px">
          <Button
            as={UnstyledLink}
            href="https://forms.gle/PwjwLDtnVYWYy4pv9"
            target="_blank"
          >
            take our collaboration survey
          </Button>

          <Button as={Link} variant="ghost" to={paths.dashboard.value}>
            return home
          </Button>
        </Spacer>
      </S.Contents>
    </S.Page>
  );
};

export default NonOwnerView;
