import { OnSelectPodcastArgs, PodcastSearch } from '@sparemin/media-import';
import React, { useCallback, useEffect, useRef } from 'react';
import { useQueryClient } from 'react-query';
import Modal from 'components/Modal';
import { useAuth } from 'state/auth';
import { useModalParams, useModalsActions } from 'state/modal';
import { podcastQueryKeys, useAddFavoritePodcast } from 'state/podcast';
import { useZendesk } from 'state/zendesk';
import * as S from './styles';

export interface AIPodcastSearchModalContentsProps {}

const AIPodcastSearchModalContents: React.FC<
  AIPodcastSearchModalContentsProps
> = () => {
  const { userId } = useAuth();
  const queryClient = useQueryClient();
  const { hideModal } = useModalsActions();
  const addDefaultPodcast = useAddFavoritePodcast();
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const { onSelectPodcast } = useModalParams('AIPodcastSearch') ?? {};

  const { openZendeskWidget } = useZendesk();

  const handleSelectPodcast = useCallback(
    ({ podcast }: OnSelectPodcastArgs) => {
      onSelectPodcast?.(podcast);
      addDefaultPodcast(podcast);
      hideModal();
    },
    [addDefaultPodcast, hideModal, onSelectPodcast],
  );

  const handleSaveFavoritePodcast = useCallback(() => {
    queryClient.invalidateQueries(podcastQueryKeys.myFavoritePodcasts(userId));
  }, [queryClient, userId]);

  return (
    <S.Modal
      $gutter={0}
      header={<Modal.Header title={<S.Title>Search for a podcast</S.Title>} />}
    >
      <S.Body>
        <PodcastSearch
          inputRef={inputRef}
          onHelpPress={openZendeskWidget}
          onSelectPodcast={handleSelectPodcast}
          onSaveFavoritePodcast={handleSaveFavoritePodcast}
        />
      </S.Body>
    </S.Modal>
  );
};

export default AIPodcastSearchModalContents;
