import { Heading, breakpointDown } from '@sparemin/blockhead';
import styled from 'styled-components';

import BaseModal from 'components/Modal';

export const Modal = styled(BaseModal)`
  width: 570px;
  height: fit-content;
  margin: 0 auto;

  ${breakpointDown('md')`
    width: 100%;
    height: 100%;
  `}
`;

export const Subtitle = styled(Heading).attrs({
  level: 2,
})`
  max-width: 290px;
`;
