import { useRegisterKeybinding } from 'state/hotkey';

export interface UseSuggestedClipsExplorerKeybindingsConfig {
  isOpen: boolean;
  onClose: () => void;
  onNextClip: () => void;
  onPrevClip: () => void;
}

export default function useSuggestedClipsExplorerKeybindings({
  isOpen,
  onClose,
  onNextClip,
  onPrevClip,
}: UseSuggestedClipsExplorerKeybindingsConfig) {
  const keybindingOptions = { disabled: !isOpen };

  useRegisterKeybinding('escape', onClose, keybindingOptions);
  useRegisterKeybinding('enter', onNextClip, keybindingOptions);
  useRegisterKeybinding('shift+enter', onPrevClip, keybindingOptions);
}
