import { Tooltip, Trash } from '@sparemin/blockhead';
import React from 'react';

import * as S from './styles';

interface ChapterArtPreviewProps {
  imageUrl?: string;
  onDeleteChapterArt: () => void;
}

const ChapterArtPreview: React.FunctionComponent<ChapterArtPreviewProps> = ({
  imageUrl,
  onDeleteChapterArt,
}) => {
  return (
    <Tooltip arrow contents="Delete chapter art">
      <S.ChapterArtButton $imageUrl={imageUrl} onPress={onDeleteChapterArt}>
        <S.TrashIconOverlay>
          <Trash width={29} />
        </S.TrashIconOverlay>
      </S.ChapterArtButton>
    </Tooltip>
  );
};

export default ChapterArtPreview;
