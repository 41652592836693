import {
  Heading,
  HeadyGradient,
  Popover as BasePopover,
} from '@sparemin/blockhead';
import styled from 'styled-components';

export const SUGGESTION_POPOVER_BREAKPOINT_PX = 380;

export const HeadyGradientIcon = styled(HeadyGradient)`
  height: 32px;
  width: 32px;
`;

export const MenuHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`;

export const MenuHeading = styled(Heading)`
  margin: 8px;
  white-space: pre-wrap;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding-left: 32px;
`;

export const ListItem = styled.div`
  width: 100%;
  padding: 3px 8px;
  border-radius: 8px;
  white-space: pre-wrap;
`;

export const ListItemWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 300ms ease-in-out;
  border-radius: 8px;

  :hover {
    background-color: ${({ theme }) => theme.palette.l3};
  }
`;

export const ListText = styled(Heading)`
  font-size: 14px;
  line-height: 26px;
`;

export const Popover = styled(BasePopover)`
  padding: 24px;
`;

export const PopoverContent = styled.div`
  max-width: 85vw;
`;

export const Trigger = styled.div`
  width: 100%;
`;
