export function isInViewport(rect: DOMRect) {
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= window.innerHeight &&
    rect.right <= window.innerWidth
  );
}

export function getDistanceFromCenterOfScreen(rect: DOMRect) {
  return rect.top - window.innerHeight / 2;
}
