import { GradientIcon } from '@sparemin/blockhead';
import React from 'react';
import BaseUpsellModal from '../BaseUpsellModal';
import BaseUpsellModalFooter from '../BaseUpsellModalFooter';
import { BaseUpsellModalProps } from '../types';
import * as S from './styles';

interface DefaultContentsProps extends BaseUpsellModalProps {}

const DefaultContents: React.FC<DefaultContentsProps> = () => {
  return (
    <BaseUpsellModal
      title="Take things to the next level"
      subtitle="You’re currently on Headliner’s Forever Free plan. Upgrade today to take advantage of new features across all of Headliner."
      icon={
        <GradientIcon>
          <S.GiftIcon />
        </GradientIcon>
      }
      footer={<BaseUpsellModalFooter modalName="EddyRemoveAds" />}
    />
  );
};

export default DefaultContents;
