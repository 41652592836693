import { FileDropzoneProps, FileRejection } from '@sparemin/blockhead';
import { useCallback } from 'react';
import { kiloBytesToMegabytes } from 'utils/math';
import useValidateUploadLimits from './useValidateUploadLimits';
import { notifyFileRejectionError } from './utils';

export interface UseFileDroppedConfig
  extends Pick<FileDropzoneProps, 'onDropAccepted' | 'onDropRejected'> {}

export interface UseFileDropped {
  onFileAccepted: (files: File[]) => void;
  onFileRejected: (rejections: FileRejection[]) => void;
}

export default function useFileDropped({
  onDropAccepted,
  onDropRejected,
}: UseFileDroppedConfig): UseFileDropped {
  const { onExceededUploadLimits } = useValidateUploadLimits();

  const onFileAccepted = useCallback(
    (files: File[]): void => {
      onDropAccepted?.(files);
    },
    [onDropAccepted],
  );

  const onFileRejected = useCallback(
    async (rejections: FileRejection[]): Promise<void> => {
      onDropRejected?.(rejections);

      const { file, reason } = rejections[0];
      const { size, duration } = file;

      if (
        (reason === 'TOO_LARGE' || reason === 'TOO_LONG') &&
        size &&
        duration
      ) {
        return onExceededUploadLimits({
          sizeMb: kiloBytesToMegabytes(size),
          durationSec: duration,
          onUpsellSuccess: () =>
            onDropAccepted?.(rejections.map((rejection) => rejection.file)),
        });
      }

      return notifyFileRejectionError({ reason });
    },
    [onExceededUploadLimits, onDropAccepted, onDropRejected],
  );

  return {
    onFileAccepted,
    onFileRejected,
  };
}
