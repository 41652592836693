import {
  createSegment,
  CreateSegmentArgs,
  TranscriptCreateSegmentResponse,
} from 'api';
import { useTranscriptId } from './useEditorTranscript';
import useUpdateTranscript from './useUpdateTranscript';

export type UseCreateTranscriptSegmentVariables = Omit<
  CreateSegmentArgs,
  'transcriptId'
>;

export default function useCreateTranscriptSegment() {
  const { data: transcriptId } = useTranscriptId();

  return useUpdateTranscript<
    TranscriptCreateSegmentResponse,
    UseCreateTranscriptSegmentVariables
  >({
    mutationFn: ({ newSegmentStartWordId, newSegmentSpeakerId }) => {
      if (!transcriptId) {
        throw new Error('Error creating segment');
      }

      return createSegment({
        newSegmentSpeakerId,
        newSegmentStartWordId,
        transcriptId,
      });
    },
  });
}
