import { UserPlanSubscription } from 'api/services/plan';

export const isFreeTierSelector = (d: UserPlanSubscription) =>
  d.subscribedProduct.tier === 'free';

export const isBasicTierSelector = (d: UserPlanSubscription) =>
  d.subscribedProduct.tier === 'basic';

export const isBasicOrFreeTierSelector = (d: UserPlanSubscription) =>
  isFreeTierSelector(d) || isBasicTierSelector(d);

export const planNameSelector = (d: UserPlanSubscription) =>
  d.subscribedPlan.name;

export const planMaxUploadValuesSelector = (d: UserPlanSubscription) => ({
  maxUploadSizeMB: d.planLimit?.eddyUploadMB,
  maxMediaDurationHr: d.planLimit?.eddyDurationLimitHours,
});
