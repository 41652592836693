import { isFinite } from 'lodash-es';
import { useQuery } from 'react-query';
import {
  getTranscriptShowNoteSuggestions,
  TranscriptShowNoteSuggestions,
} from 'api/services';
import { useAuth } from 'state/auth';
import { QueryOptions } from 'types/react-query';
import { eddyTranscriptQueryKeys } from './queryKeys';
import { firtSuggestedShowNoteSelector } from './selectors';

type QueryKey = ReturnType<typeof eddyTranscriptQueryKeys.showNoteSuggestions>;

export type UseGetTranscriptShowNoteSuggestions<
  TData = TranscriptShowNoteSuggestions,
> = QueryOptions<TranscriptShowNoteSuggestions, TData, QueryKey>;

export default function useGetTranscriptShowNoteSuggestions<
  TData = TranscriptShowNoteSuggestions,
>(transcriptId?: number, opts?: UseGetTranscriptShowNoteSuggestions<TData>) {
  const { userId } = useAuth();

  return useQuery<TranscriptShowNoteSuggestions, unknown, TData, QueryKey>(
    eddyTranscriptQueryKeys.showNoteSuggestions(userId, transcriptId),
    ({ queryKey: [{ transcriptId: id }] }) =>
      getTranscriptShowNoteSuggestions(id as number),
    {
      ...opts,
      enabled: isFinite(transcriptId) && (opts?.enabled ?? true),
      meta: {
        errorMessage: 'Error loading transcript',
      },
    },
  );
}

export const useGetFirstSuggestedShowNotes = (transcriptId?: number) =>
  useGetTranscriptShowNoteSuggestions(transcriptId, {
    select: firtSuggestedShowNoteSelector,
  });
