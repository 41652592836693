import {
  HeadlinerGradientLogo,
  HeadyGradientOutline,
} from '@sparemin/blockhead';
import paths from 'paths';
import { useLogoTargetOverride, useLogoUrlOverride } from 'state/displayPref';
import HeaderItem from '../HeaderItem';
import * as S from './styles';

export type LogoVariant = 'full' | 'condensed';

export interface LogoItemProps {
  variant?: LogoVariant;
}

const LogoItem: React.FC<LogoItemProps> = ({ variant = 'full' }) => {
  const { data: logoUrl } = useLogoUrlOverride();
  const { data: logoTarget } = useLogoTargetOverride();

  const getLogo = () => {
    if (logoUrl) {
      return <S.LogoImage src={logoUrl} alt="logo" />;
    }

    return variant === 'full' ? (
      <HeadlinerGradientLogo height="100%" width="141px" />
    ) : (
      <HeadyGradientOutline height="30px" width="30px" />
    );
  };

  return (
    <HeaderItem as={S.HeaderLink} to={logoTarget ?? paths.dashboard.value}>
      {getLogo()}
    </HeaderItem>
  );
};

export default LogoItem;
