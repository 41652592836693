import React from 'react';

import ConnectedModal from 'components/ConnectedModal';
import { useModalParams } from 'state/modal';

import ShareAudioModalContents from './ShareAudioModalContents';

const ShareAudioModal: React.FunctionComponent = () => {
  const params = useModalParams('ShareAudio');

  return (
    <ConnectedModal name="ShareAudio">
      <ShareAudioModalContents params={params} />
    </ConnectedModal>
  );
};

export default ShareAudioModal;
