import { ProjectTranscript, TranscriptCreateChapterRequest } from 'api';
import { placeholderChapterIds } from '../../placeholderIds';
import AsyncUndoableTranscriptCommand from '../AsyncUndoableTranscriptCommand';
import TranscriptOriginator from '../TranscriptOriginator';
import { CommandOptions } from '../types';
import { createChapter, deleteChapter } from './optimisticUpdate';
import { CreateChapterCommitter, DeleteChapterCommitter } from './types';

type CreateChapterCommandArgs = Pick<
  TranscriptCreateChapterRequest,
  | 'newChapterStartWordId'
  | 'newChapterTitle'
  | 'newChapterUrl'
  | 'newChapterImageUrl'
>;

export default class CreateChapterCommand extends AsyncUndoableTranscriptCommand<number> {
  private localChapterId: number | undefined;

  private remoteChapterId: number | undefined;

  constructor(
    private args: CreateChapterCommandArgs,
    originator: TranscriptOriginator,
    private createChapterCommitter: CreateChapterCommitter,
    private deleteChapterComitter: DeleteChapterCommitter,
    opts?: CommandOptions<number>,
  ) {
    super(originator, opts);
  }

  protected executeTranscript(
    transcript: ProjectTranscript,
  ): [ProjectTranscript, number] | undefined {
    const [newTranscript, newChapterId] = createChapter(
      transcript,
      this.args.newChapterStartWordId,
      this.args.newChapterTitle,
      this.args.newChapterUrl,
      this.args.newChapterImageUrl,
    );

    this.localChapterId = newChapterId;

    return [newTranscript, newChapterId];
  }

  protected unexecuteTranscript(
    transcript: ProjectTranscript,
  ): ProjectTranscript | undefined {
    if (this.localChapterId) {
      const [newTranscript] = deleteChapter(transcript, this.localChapterId);
      return newTranscript;
    }

    return undefined;
  }

  public async commit(): Promise<void> {
    try {
      const newChapterId = await this.createChapterCommitter({
        newChapterStartWordId: this.args.newChapterStartWordId,
        newChapterTitle: this.args.newChapterTitle,
        newChapterUrl: this.args.newChapterUrl,
        newChapterImageUrl: this.args.newChapterImageUrl,
      });

      if (this.localChapterId) {
        placeholderChapterIds.setPlaceholderId(
          this.localChapterId,
          newChapterId,
        );
      }

      this.remoteChapterId = newChapterId;
    } catch (err) {
      this.onError('commit');
      throw err;
    }
  }

  public async uncommit(): Promise<void> {
    if (this.remoteChapterId) {
      try {
        await this.deleteChapterComitter({
          chapterId: this.remoteChapterId,
        });
      } catch (err) {
        this.onError('uncommit');
        throw err;
      }
    }
  }
}
