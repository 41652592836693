import {
  editProjectTranscriptSegmentSpeaker,
  EditProjectTranscriptSegmentSpeakerArgs,
} from 'api';
import { useTranscriptId } from './useEditorTranscript';
import useUpdateTranscript from './useUpdateTranscript';
import { placeholderSegmentIds } from '.';

export type UseEditTranscriptSegmentSpeakerArgs = Pick<
  EditProjectTranscriptSegmentSpeakerArgs,
  'edits'
>;

export default function useEditTranscriptSegmentSpeaker() {
  const { data: transcriptId } = useTranscriptId();

  return useUpdateTranscript<void, UseEditTranscriptSegmentSpeakerArgs>({
    mutationFn: ({ edits }) =>
      editProjectTranscriptSegmentSpeaker({
        edits: edits.map((edit) => ({
          ...edit,
          segmentId: placeholderSegmentIds.getActualId(edit.segmentId),
        })),
        transcriptId: transcriptId as number,
      }),
  });
}
