import { uniqueId } from 'lodash-es';
import PlaceholderIdMap from './PlaceholderIdMap';

export const placeholderSegmentIds = new PlaceholderIdMap();

export const placeholderChapterIds = new PlaceholderIdMap();

/**
 * Ids generated by this function are negative for two reasons:
 *  1) when looking at the transcript object, it's clear which ids were created
 *     locally by this application and which were created by the API
 *
 *  2) since the API only generates positive ids, it eliminates the possibility
 *     of the rare edge case where these ids would conflict with segment ids
 *     already assigned to the transcript
 */
export function generatePlaceholderId() {
  return -1 * Number(uniqueId());
}
