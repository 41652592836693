import EventEmitter from 'eventemitter3';
import { clamp } from 'utils/math';
import { millisToSec } from 'utils/time';
import { AudioReadyState, AudioPlayer } from './types';

const DEFAULT_TIMEUPDATE_POLLING_INTERVAL_MILLIS = 100;

export default class MediaElementAudioPlayer implements AudioPlayer {
  private emitter: EventEmitter;

  constructor(
    public element: HTMLMediaElement,
    private timeupdateIntervalMillis: number = DEFAULT_TIMEUPDATE_POLLING_INTERVAL_MILLIS,
    private disabled: boolean = false,
  ) {
    this.emitter = new EventEmitter();
    this.registerTimeupdateEmitter();
  }

  private registerTimeupdateEmitter() {
    if (this.timeupdateIntervalMillis <= 20) {
      this.registerRAFTimeupdateEmitter();
    } else {
      this.registerSetTimeoutTimeupdateEmitter();
    }
  }

  private registerSetTimeoutTimeupdateEmitter() {
    let timeupdateIntervalId: number | undefined;

    const pollCurrentTime = () => {
      timeupdateIntervalId = window.setTimeout(() => {
        this.emitter.emit('timeupdate', this.element.currentTime);
        pollCurrentTime();
      }, this.timeupdateIntervalMillis);
    };

    this.on('play', pollCurrentTime);

    this.on('pause', () => {
      window.clearInterval(timeupdateIntervalId);
      timeupdateIntervalId = undefined;
    });
  }

  private registerRAFTimeupdateEmitter() {
    let rafId: number | undefined;

    const pollCurrentTime = () => {
      rafId = window.requestAnimationFrame(() => {
        this.emitter.emit('timeupdate', this.element.currentTime);
        pollCurrentTime();
      });
    };

    this.on('play', pollCurrentTime);

    this.on('pause', () => {
      if (rafId) {
        window.cancelAnimationFrame(rafId);
        rafId = undefined;
      }
    });
  }

  public pause() {
    this.element.pause();
  }

  public play() {
    if (this.disabled) {
      return;
    }

    return this.element.play();
  }

  public seek(seconds: number) {
    this.element.currentTime = clamp(seconds, 0, this.duration);
  }

  public async togglePlay() {
    if (this.paused) {
      return this.play();
    } else {
      this.pause();
    }
  }

  public enablePlayer(): void {
    this.disabled = false;
  }

  public disablePlayer(): void {
    this.pause();
    this.disabled = true;
  }

  public on(
    type: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    listener: (...args: any[]) => void,
    opts?: boolean | object,
  ) {
    if (type === 'timeupdate') {
      this.emitter.on('timeupdate', listener);
    } else {
      this.element.addEventListener(type, listener, opts);
    }
  }

  public off<T extends keyof HTMLMediaElementEventMap>(
    type: T,
    listener: (this: HTMLMediaElement, ev: HTMLMediaElementEventMap[T]) => void,
    opts?: boolean | AddEventListenerOptions,
  ) {
    if (type === 'timeupdate') {
      this.emitter.off('timeupdate', listener);
    } else {
      this.element.removeEventListener(type, listener, opts);
    }
  }

  public get paused() {
    return this.element.paused;
  }

  public get currentTime() {
    return this.element.currentTime;
  }

  public get isDisabled() {
    return this.disabled;
  }

  /**
   * NB - returns NaN before duration is available
   */
  public get duration() {
    return this.element.duration;
  }

  public get ready() {
    return this.element.readyState >= AudioReadyState.HAVE_FUTURE_DATA;
  }

  public get sampleRate() {
    return millisToSec(this.timeupdateIntervalMillis);
  }
}
