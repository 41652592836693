import { useCallback } from 'react';
import { QueryStatus } from 'react-query';
import {
  useExportAudio,
  useExportVideo,
  useProjectCuid,
  useProjectMediaType,
  useProjectName,
} from '../state';

export interface UseDownloadClipResult {
  status: QueryStatus;
  downloadClip: (startWordId: number, endWordId: number) => void;
}

export default function useDownloadClip(): UseDownloadClipResult {
  const { status: audioStatus, exportAudio } = useExportAudio();
  const { status: videoStatus, exportVideo } = useExportVideo();
  const { data: projectType } = useProjectMediaType();
  const { data: projectName } = useProjectName();
  const { data: projectCuid } = useProjectCuid();

  const downloadClip = useCallback(
    (startWordId: number, endWordId: number): void => {
      if (!projectCuid || !projectName) {
        return;
      }

      (projectType === 'audio' ? exportAudio : exportVideo)({
        projectCuid,
        projectName,
        download: true,
        selection: {
          startWordId,
          endWordId,
        },
      });
    },
    [exportAudio, exportVideo, projectCuid, projectName, projectType],
  );

  return {
    status: projectType === 'audio' ? audioStatus : videoStatus,
    downloadClip,
  };
}
