import { Spacer } from '@sparemin/blockhead';
import styled from 'styled-components';

export const Wrapper = styled(Spacer).attrs({
  align: 'center',
  justify: 'center',
  orientation: 'vertical',
})`
  margin: 0 40px;
  position: fixed;
  top: ${({ theme }) => theme.headerHeightDesktop + theme.spacing.base * 2}px;
  bottom: ${({ theme }) => theme.footerFadeHeight + 20}px;
`;

export const Video = styled.video`
  width: 100%;
  max-height: 100%;
  border-radius: 8px;
`;
