export const showZendeskWidget = (email?: string) => {
  if (window.zE) {
    window.zE('webWidget', 'show');
    window.zE('webWidget', 'open');
    if (email) {
      window.zE('webWidget', 'prefill', {
        name: {
          value: email,
        },
        email: {
          value: email,
        },
      });
    }
  }
};
