import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import notifyError from 'components/notification';
import useIsMobileViewport from 'hooks/useIsMobileViewport';
import paths from 'paths';
import AIOptOut from './AIOptOut';
import { EMPTY_TRANSCRIPT_ERROR_MESSAGE } from './constants';
import Header from './Header';
import PageMetadata from './PageMetadata';
import ProjectLoader from './ProjectLoader';
import {
  useClipSuggestionGroup,
  useEditorProject,
  useHasSubmittedRelatedPodcast,
  useSegmentIds,
  useTranscriptId,
} from './state';
import useEditorWaveform from './state/useEditorWaveform';
import * as S from './styles';
import TranscriptEditor from './TranscriptEditor/TranscriptEditor';
import useOnUnmountEditor from './useOnUnmountEditor';

export interface TranscriptEditorPageProps {}

// TODO SPAR-20208: create a tech-debt ticket.  Too many concerns here:
// - rendering the correct state based on project status
// - handling project load errors
const TranscriptEditorPage: React.FC<TranscriptEditorPageProps> = () => {
  const navigate = useNavigate();
  const isMobile = useIsMobileViewport();

  const { data: hasSubmittedRelatedPodcast } = useHasSubmittedRelatedPodcast();

  const { data: project, isError: isProjectError } = useEditorProject({
    waitForAssets: true,
  });

  // NB - suggestions aren't critical, so the isError flag is not handled in the
  // same way it is for the project
  useClipSuggestionGroup({ waitForAssets: true });

  useEditorWaveform();

  const { isError: isTranscriptError } = useTranscriptId();
  const { data: segmentIds } = useSegmentIds();

  const isTranscriptEmpty = segmentIds?.length === 0;
  const isError = isProjectError || isTranscriptError || isTranscriptEmpty;

  useOnUnmountEditor();

  useEffect(() => {
    if (isTranscriptEmpty) {
      notifyError({
        heading: EMPTY_TRANSCRIPT_ERROR_MESSAGE.title,
        subHeading: EMPTY_TRANSCRIPT_ERROR_MESSAGE.description,
        errorCode: 'ER003',
      });
    }
  }, [isTranscriptEmpty]);

  useEffect(() => {
    if (isError) {
      navigate(paths.dashboard.value, { replace: true });
    }
  }, [isError, navigate]);

  const [isLoadingComplete, setIsLoadingComplete] = useState(false);

  const handleLoadingComplete = useCallback(() => {
    setIsLoadingComplete(true);
  }, []);

  const showHeader = isMobile
    ? hasSubmittedRelatedPodcast && isLoadingComplete
    : true;

  return (
    <S.Page>
      <PageMetadata title={project?.name} />

      {showHeader && (
        <Header
          exportButtonIsDisabled={
            !isLoadingComplete || !hasSubmittedRelatedPodcast
          }
        />
      )}

      {hasSubmittedRelatedPodcast === false && <AIOptOut />}

      {hasSubmittedRelatedPodcast &&
        // check for isError because the effect that redirects on error will run
        // after render, which means that without the check TranscriptEditor would
        // render when we know there's an error and that we are going to redirect.
        // some code in TranscriptEditor might expect certain data to be there and if
        // there's an error, it's likely something is missing.
        (isLoadingComplete && !isError ? (
          <TranscriptEditor />
        ) : (
          <ProjectLoader onLoadingComplete={handleLoadingComplete} />
        ))}
    </S.Page>
  );
};

export default TranscriptEditorPage;
