import WaveSurfer from 'wavesurfer.js/src/wavesurfer';

export function getPlaybackStartSec(
  clipStartSec: number,
  clipEndSec: number,
  waveStartSec: number,
  waveEndSec: number,
) {
  if (clipStartSec > waveStartSec) {
    return waveStartSec;
  }

  if (clipEndSec < waveEndSec) {
    return clipEndSec;
  }

  return NaN;
}

export function getPlaybackStartSecFromTime(
  currentTime: number,
  clipStartSec: number,
  clipEndSec: number,
  waveStartSec: number,
  waveEndSec: number,
) {
  if (
    currentTime > waveStartSec &&
    currentTime < waveEndSec &&
    (currentTime < clipStartSec || currentTime > clipEndSec)
  ) {
    return currentTime;
  }

  return getPlaybackStartSec(
    clipStartSec,
    clipEndSec,
    waveStartSec,
    waveEndSec,
  );
}

export function getClipRegion(wavesurfer: WaveSurfer) {
  return wavesurfer.regions.list.clip;
}

export const pxToSec = (px: number, pixelsPerSecond: number): number => {
  return px / pixelsPerSecond;
};
