import { Button, Spacer, Text } from '@sparemin/blockhead';
import styled from 'styled-components';

export const Page = styled(Spacer).attrs({
  align: 'center',
  justify: 'center',
})`
  height: 100vh;
  width: 100vw;

  & > :not(:first-child) {
    margin-left: 0;
  }
`;

export const Contents = styled(Spacer).attrs({
  align: 'center',
  justify: 'center',
  orientation: 'vertical',
})`
  text-align: center;
`;

export const Description = styled(Text).attrs({
  variant: 'p',
})`
  width: 100%;
  max-width: 470px;
  position: relative;
  top: 2px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.86;
  letter-spacing: 0.5px;

  & a {
    font-weight: normal;
  }
`;

export const RefreshButton = styled(Button)`
  position: relative;
  top: 18px;
`;
