import { useContext } from 'react';
import AudioPlayerContext from './AudioPlayerContext';

export default function useAudioPlayer() {
  const context = useContext(AudioPlayerContext);

  if (context === undefined) {
    throw new Error('useAudioPlayer must be used within AudioPlayerProvider');
  }

  return context;
}
