import { Button } from '@sparemin/blockhead';
import styled from 'styled-components';

import Modal from 'components/Modal';
import BasePlaybackTime from 'components/PlaybackTime';

export const Footer = styled(Modal.Footer)`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const UpdateButton = styled(Button)`
  margin-left: auto;
`;

export const PlaybackTime = styled(BasePlaybackTime)`
  margin-left: 16px;
`;
