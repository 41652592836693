import { Text } from '@sparemin/blockhead';
import React from 'react';
import * as S from './styles';

export interface AIOptionsAddPodcastProps {}

const AIOptionsAddPodcast: React.FC<AIOptionsAddPodcastProps> = () => {
  return (
    <S.PodcastTargetContainer>
      <S.PodcastTargetContainerIcon />
      <Text variant="button">ADD YOUR PODCAST</Text>
    </S.PodcastTargetContainer>
  );
};

export default AIOptionsAddPodcast;
