import { Text } from '@sparemin/blockhead';
import { useCallback, useEffect, useState } from 'react';
import { PlayButton } from 'components/MediaControlButton';
import Modal from 'components/Modal';
import notifyError from 'components/notification';
import { DEBUG } from 'config';
import useToggle from 'hooks/useToggle';
import { useEventTracking } from 'state/eventTracking';
import { useRegisterKeybinding } from 'state/hotkey';
import { useModalParams, useModalsActions } from 'state/modal';
import { TimeInterval } from 'types';
import { getIntervalDuration, secToMillis } from 'utils/time';
import AudioSelectionAdjuster from '../AudioSelectionAdjuster';
import usePlaybackState from '../hooks/usePlaybackState';
import { useWordList, useWordsById } from '../state';
import { useEditTranscript } from '../state/commands';
import { useEditorWaveformData } from '../state/useEditorWaveform';
import * as S from './styles';
import {
  calculateDurationPercentageChange,
  calculateFinalAdjustments,
  getAdjustmentWords,
  getMaxInterval,
  getSelectionAdjustmentBoundary,
  getSurroundingWords,
  getWaveformInterval,
} from './utils';

export interface AdjustSelectionModalContentsProps {}

const AdjustSelectionModalContents: React.FC<
  AdjustSelectionModalContentsProps
> = () => {
  const [currentTime, setCurrentTime] = useState(0);
  const { data: wordsById } = useWordsById();
  const { data: words } = useWordList();
  const params = useModalParams('AdjustSelection');
  const { value: playing, toggle: togglePlayback } = useToggle(false);
  const { duration } = usePlaybackState();
  const { hideModal } = useModalsActions();
  const { data: waveform } = useEditorWaveformData();
  const { trackWaveformRevealed, trackWaveformPercentageChange } =
    useEventTracking();

  // NB: modal unmounts immediately.  `mutate` will not run callbacks if component
  // is not mounted, so use `mutateAsync`
  const { updateWords } = useEditTranscript();

  const wordId = params?.wordId ?? -1;
  const word = wordsById?.[wordId];

  const [selection, setSelection] = useState<TimeInterval>({
    startMillis: word?.startMillis ?? 0,
    endMillis: word?.endMillis ?? 0,
  });

  const selectionWords = getAdjustmentWords(wordId, wordsById, words);
  const adjustableBoundaries = getSelectionAdjustmentBoundary(selectionWords);
  const maxInterval = getMaxInterval(selectionWords, adjustableBoundaries);

  const { waveStartMillis, waveEndMillis } = getWaveformInterval(
    adjustableBoundaries,
    word,
    secToMillis(duration),
  );

  const handleChangeEnd = useCallback(
    (startMillis: number, endMillis: number) => {
      setSelection({
        endMillis: adjustableBoundaries.includes('end')
          ? endMillis
          : selection.endMillis,
        startMillis: adjustableBoundaries.includes('start')
          ? startMillis
          : selection.startMillis,
      });
    },
    [adjustableBoundaries, selection.endMillis, selection.startMillis],
  );

  const handleSubmit = useCallback(async () => {
    hideModal();

    if (!word) {
      return;
    }

    const { startMillis: newStartMillis, endMillis: newEndMillis } =
      calculateFinalAdjustments(
        selection,
        selectionWords,
        maxInterval,
        adjustableBoundaries,
        secToMillis(duration),
      );

    const newDuration = getIntervalDuration({
      startMillis: newStartMillis,
      endMillis: newEndMillis,
    });
    const oldDuration = getIntervalDuration({
      startMillis: word.startMillis,
      endMillis: word.endMillis,
    });

    trackWaveformPercentageChange(
      calculateDurationPercentageChange(oldDuration, newDuration),
    );

    if (
      newStartMillis !== word.startMillis ||
      newEndMillis !== word.endMillis
    ) {
      updateWords(
        [
          {
            id: word.id,
            startMillis: newStartMillis,
            endMillis: newEndMillis,
          },
        ],
        () => {
          notifyError({ heading: 'Error updating word' });
        },
      );
    }
  }, [
    adjustableBoundaries,
    duration,
    hideModal,
    maxInterval,
    selection,
    selectionWords,
    trackWaveformPercentageChange,
    updateWords,
    word,
  ]);

  useRegisterKeybinding(' ', togglePlayback);

  useEffect(() => {
    trackWaveformRevealed();
  }, [trackWaveformRevealed]);

  return (
    <Modal
      footer={
        <S.Footer>
          <PlayButton paused={!playing} onPress={() => togglePlayback()} />
          <S.PlaybackTime currentTimeMillis={currentTime} />
          <S.UpdateButton onPress={handleSubmit}>update</S.UpdateButton>
        </S.Footer>
      }
      subtitle={
        <Text variant="p" weight="normal">
          Drag the {adjustableBoundaries.length >= 2 ? 'handles' : 'handle'} in
          the trimming bar to adjust the selection that you want to remove.
        </Text>
      }
      title="Modify deletion"
    >
      <Modal.Body>
        {word &&
          waveStartMillis !== undefined &&
          waveEndMillis !== undefined && (
            <AudioSelectionAdjuster
              maxInterval={maxInterval}
              onPlaybackChange={togglePlayback}
              onSelectionChangeEnd={handleChangeEnd}
              onSeeked={setCurrentTime}
              onTimeupdate={setCurrentTime}
              playing={playing}
              selectionInterval={selection}
              surroundingSelections={
                !DEBUG
                  ? undefined
                  : getSurroundingWords(
                      wordId,
                      waveStartMillis,
                      waveEndMillis,
                      wordsById,
                      words,
                    )
              }
              waveform={waveform}
              waveInterval={{
                startMillis: waveStartMillis,
                endMillis: waveEndMillis,
              }}
            />
          )}
      </Modal.Body>
    </Modal>
  );
};

export default AdjustSelectionModalContents;
