import {
  getThemeColor,
  IconButton as BaseIconButton,
  Trash,
} from '@sparemin/blockhead';
import styled from 'styled-components';

export const IconButton = styled(BaseIconButton)`
  height: auto;
  width: auto;

  &:active:not([disabled]) {
    box-shadow: none;
  }
`;

export const Icon = styled(Trash)`
  color: ${getThemeColor('danger')};
  height: 16px;
  width: 15px;
`;
