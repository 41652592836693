import { GoogleDriveLogo } from '@sparemin/blockhead';
import React from 'react';
import * as S from './styles';
import UploadButton from './UploadButton';

interface GoogleDriveUploadButtonProps {
  onPress?: () => void;
}

const GoogleDriveUploadButton: React.FC<GoogleDriveUploadButtonProps> = ({
  onPress,
}) => {
  return (
    <UploadButton
      aria-label="Upload files from Google Drive"
      label="Google Drive"
      logo={<S.IntegratorIcon as={GoogleDriveLogo} />}
      onPress={onPress}
    />
  );
};

export default GoogleDriveUploadButton;
