import WaveSurfer from 'wavesurfer.js';
import { PluginDefinition, WaveSurferPlugin } from 'wavesurfer.js/types/plugin';

import styles from './ClipRegionPlugin.module.css';
import { PLUGIN_NAME } from './constants';
import { ClipRegionPluginParams } from './types';
import { createCSSVar } from './utils';

/**
 * This plugin customizes a WaveSurfer region (provided by the RegionsPlugin) to
 * match the design of our waveform audio clipper.
 *
 * The plugin just adds some CSS to the region and must be used along with the
 * Regions plugin.
 */
export default class ClipRegionPlugin implements WaveSurferPlugin {
  static HANDLE_WIDTH = 22;

  public static CSS_VAR_NAMES = {
    backgroundColor: createCSSVar('color'),
    regionHandleWidth: createCSSVar('handle-width'),
  };

  static create(params: ClipRegionPluginParams): PluginDefinition {
    return {
      params,
      deferInit: !!params.deferInit,
      name: PLUGIN_NAME,
      // the types are incorrect here and there's no easy way to override them
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      instance: ClipRegionPlugin,
    };
  }

  constructor(
    private params: ClipRegionPluginParams,
    private wavesurfer: WaveSurfer,
  ) {}

  private setCSSVars() {
    this.wavesurfer.drawer.wrapper.style.setProperty(
      ClipRegionPlugin.CSS_VAR_NAMES.backgroundColor,
      this.params.color,
    );

    this.wavesurfer.drawer.wrapper.style.setProperty(
      ClipRegionPlugin.CSS_VAR_NAMES.regionHandleWidth,
      `${ClipRegionPlugin.HANDLE_WIDTH}px`,
    );
  }

  private unsetCSSVars() {
    this.wavesurfer.drawer.wrapper.style.removeProperty(
      ClipRegionPlugin.CSS_VAR_NAMES.backgroundColor,
    );

    this.wavesurfer.drawer.wrapper.style.removeProperty(
      ClipRegionPlugin.CSS_VAR_NAMES.regionHandleWidth,
    );
  }

  private onReady = () => {
    const region = this.getRegion();
    region.element.classList.add(styles.clipRegion);

    const progressWave = this.wavesurfer.drawer.progressWave;

    if (progressWave) {
      WaveSurfer.util.style(progressWave, {
        zIndex: '4',
      });
    }

    this.setCSSVars();
  };

  private getRegion() {
    return this.wavesurfer.regions.list[this.params.clipRegionId];
  }

  init(): void {
    if (this.wavesurfer.isReady) {
      this.onReady();
    } else {
      this.wavesurfer.once('ready', this.onReady);
    }
  }

  destroy(): void {
    const region = this.getRegion();

    if (region) {
      region.element.classList.remove(styles.clipRegion);
    }
    this.unsetCSSVars();
  }
}
