import { useCallback } from 'react';
import { PromoPackAssetType } from 'api';
import notifyError from 'components/notification';
import { useDownloadPromoPackAsset } from '../state';

export interface UseDownloadPromoPackSpecificAssetResult {
  isLoading: boolean;
  downloadAsset: (assetType: PromoPackAssetType) => void;
}

export default function useDownloadPromoPackSpecificAsset(): UseDownloadPromoPackSpecificAssetResult {
  const { mutate: downloadPromoPackSpecificAsset, isLoading } =
    useDownloadPromoPackAsset();

  const downloadAsset = useCallback(
    (assetType: PromoPackAssetType): void => {
      downloadPromoPackSpecificAsset(
        {
          assetType,
        },
        {
          onError: () => {
            notifyError();
          },
        },
      );
    },
    [downloadPromoPackSpecificAsset],
  );

  return {
    isLoading,
    downloadAsset,
  };
}
