import { PopoverContainerProps } from '@sparemin/blockhead';

import { SUGGESTION_POPOVER_BREAKPOINT_PX } from './styles';

/**
 * gets the popover width mode based on if the trigger width is greater than the
 * breakpoint value in px
 */
export const getPopoverWidthMode = (
  triggerWidth: number,
): PopoverContainerProps['width'] => {
  return triggerWidth > SUGGESTION_POPOVER_BREAKPOINT_PX ? 'target' : 'auto';
};
