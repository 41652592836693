import { Button, Heading, Link, Spacer, Text } from '@sparemin/blockhead';
import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import { ContentsStyleProps } from './types';

export const Root = styled(motion.div)`
  width: 100%;
  position: absolute;
  margin-top: 40px;
  margin-bottom: 48px;
`;

export const Title = styled(Heading).attrs({
  level: 3,
})`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Description = styled(Text).attrs({
  variant: 'label',
})`
  line-height: 1.5;
  letter-spacing: 0.5px;
  font-size: 14px;
`;

export const Container = styled.div`
  width: 100%;
`;

export const Contents = styled.div<ContentsStyleProps>`
  ${({ $isGated }) =>
    $isGated &&
    css`
      opacity: 0.5;

      // This prevents pointer events for all children while maintaining the
      // behavior for the parent, ensuring the tooltip is triggered correctly.
      & > * {
        pointer-events: none;
      }
    `}
`;

export const Footer = styled(Spacer).attrs({
  orientation: 'horizontal',
  align: 'center',
  justify: 'center',
})`
  width: 100%;
`;

export const FooterButton = styled(Button)`
  width: 220px;
`;

export const FooterLinkButton = styled(Link).attrs({
  as: Button,
})`
  width: 220px;
`;
