import { isNumber } from 'lodash-es';
import { useCallback } from 'react';
import { Query, useQuery } from 'react-query';

import { VideoExport, getVideoExport } from 'api/services';
import { DEFAULT_POLLING_INTERVAL_MILLIS } from 'config';
import { QueryOptions } from 'types';

import { eddyVideoExportQueryKeys } from './queryKeys';

type QueryKey = ReturnType<typeof eddyVideoExportQueryKeys.videoDownload>;

export interface UseGetVideoExportQuery<TData = VideoExport>
  extends QueryOptions<VideoExport, TData, QueryKey> {
  pollingInterval?: number;
}

const useGetVideoExport = <TData = VideoExport>(
  exportId: number,
  opts?: UseGetVideoExportQuery<TData>,
) => {
  const { pollingInterval = DEFAULT_POLLING_INTERVAL_MILLIS, ...queryOptions } =
    opts ?? {};

  const refetchInterval = useCallback(
    (
      _: TData | undefined,
      query: Query<VideoExport, unknown, VideoExport, QueryKey>,
    ) => {
      return query.state.data?.status === 'processing'
        ? pollingInterval
        : false;
    },
    [pollingInterval],
  );

  const enabled =
    isNumber(exportId) && exportId !== -1 && (opts?.enabled ?? true);

  return useQuery<VideoExport, unknown, TData, QueryKey>(
    eddyVideoExportQueryKeys.videoDownload(exportId),
    ({ queryKey: [{ videoExportId }] }) => getVideoExport({ videoExportId }),
    {
      ...queryOptions,
      enabled,
      meta: {
        errorMessage: 'Error exporting video',
      },
      refetchInterval: refetchInterval,
    },
  );
};

export default useGetVideoExport;
