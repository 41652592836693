import {
  GetUserIntegratorsResult,
  GetUserProfileResult,
} from 'api/services/user';

export const emailAddressSelector = (d: GetUserProfileResult) => d.email;

export const isIntegratorSelector = (d: GetUserIntegratorsResult) =>
  !!d.integrators?.length;

// most users are only assigned to at most one integrator
export const integratorSelector = (d: GetUserIntegratorsResult) =>
  d.integrators?.[0];

export const integratorDisplayNameSelector = (d: GetUserIntegratorsResult) =>
  integratorSelector(d)?.displayName;
