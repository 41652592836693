import { produce } from 'immer';
import {
  ProjectTranscript,
  ProjectTranscriptSegment,
  TranscriptSpeaker,
  TranscriptWord,
} from 'api';
import { generatePlaceholderId } from '../../placeholderIds';
import {
  segmentDictionarySelector,
  wordDictionarySelector,
} from '../../selectors';

function getWordStartMillis(word: TranscriptWord) {
  return word.originalStartMillis ?? word.startMillis;
}

function getWordEndMillis(word: TranscriptWord) {
  return word.originalEndMillis ?? word.endMillis;
}

/**
 * Creates a new segment from a word id.
 *
 * The new segment is created as follows -
 * The word indicated by the wordId will be in a segment and it's the segment
 * that's split to create a new one.  All words in the segment before, but not including,
 * the target word will be left in the original segment. All words starting from
 * the new word, through the end of the segment, will get added to a new segment.
 *
 * All times are adjusted.  The original segment will have an earlier end time,
 * corresponding to the end time of the word immediately before the target word.
 * The new segment will have start and end times according to the words that were
 * sliced out of the original segment in order to create it.
 *
 * The speaker from the original segment is set as the speaker of the new segment.
 *
 * @param transcript the editable immer transcript.  this object should be modified
 *   in place to reflect the command's changes
 * @param newSegmentStartWordId the id of the word that should be the first word
 *   in the new segment.  The last word in the new segment will be the last word
 *   of the segment in which the target word lives before the split.
 *
 * @returns a tuple containing the updated transcript and the id of the segment
 *   that was created
 */
export function createSegment(
  transcript: ProjectTranscript,
  newSegmentStartWordId: number,
  speaker?: TranscriptSpeaker,
): [ProjectTranscript, number] {
  // NB: "global" indexes are word indexes across all words in the transcript.
  // "local" indexes are word indexes within a segment

  const wordsById = wordDictionarySelector(transcript);
  const segmentsById = segmentDictionarySelector(transcript);
  const newSegmentId = generatePlaceholderId();

  // find the segment to which the target word belongs and the target word's
  // index within that segment
  const { index: newSegmentStartWordGlobalIndex, segmentId } =
    wordsById[newSegmentStartWordId];

  const { index: segmentIndex, words: segmentWords } = segmentsById[segmentId];

  const segmentFirstWordGlobalIndex = wordsById[segmentWords[0].id].index;

  const newSegmentFirstWordLocalIndex =
    newSegmentStartWordGlobalIndex - segmentFirstWordGlobalIndex;

  // per business logic, a segment cannot be inserted before the first word of a
  // segment as that would result in the original segment being empty
  if (newSegmentFirstWordLocalIndex === 0) {
    throw new Error(
      'Cannot insert new segment before first word of an existing segment',
    );
  }

  const newTranscript = produce(transcript, (draft) => {
    // splice the new segment's words out of the current segment.  splice conveniently
    // returns all the words that will go into the next segment
    const originalSegment = draft.segments[segmentIndex];
    const newSegmentWords = originalSegment.words.splice(
      newSegmentFirstWordLocalIndex,
    );

    // adjust the original segment's end time. start time won't change as the beginning
    // of the segment is left untouched
    const originalSegmentNewLastWord =
      originalSegment.words[originalSegment.words.length - 1];

    originalSegment.endMillis = getWordEndMillis(originalSegmentNewLastWord);

    // create the new segment
    const newSegmentFirstWord = newSegmentWords[0];
    const newSegmentLastWord = newSegmentWords[newSegmentWords.length - 1];

    const newSegment: ProjectTranscriptSegment = {
      id: newSegmentId,
      startMillis: getWordStartMillis(newSegmentFirstWord),
      endMillis: getWordEndMillis(newSegmentLastWord),
      words: newSegmentWords,
      speaker: speaker ?? originalSegment.speaker,
    };

    // insert the new segment immediately after the segment containing the target word
    draft.segments.splice(segmentIndex + 1, 0, newSegment);
  });

  return [newTranscript, newSegmentId];
}

export function deleteSegment(
  transcript: ProjectTranscript,
  segmentId: number,
): [ProjectTranscript, number, TranscriptSpeaker?] {
  const segmentsById = segmentDictionarySelector(transcript);

  const { index: targetSegmentIndex, speaker, words } = segmentsById[segmentId];
  const targetSegmentFirstWordId = words[0].id;
  const originalSpeaker = speaker;

  // per business logic, the first segment cannot be deleted.  each deleted segment
  // causes that segments words to get added to the previous segment - but the first
  // segment has no previous segment
  if (targetSegmentIndex === 0) {
    throw new Error('Cannot delete the first segment of the transcript');
  }

  const newTranscript = produce(transcript, (draft) => {
    // get the words from the deleted segment
    const { words: targetSegmentWords } = draft.segments[targetSegmentIndex];

    // add the words to the end of the previous segment
    const prevSegment = draft.segments[targetSegmentIndex - 1];
    prevSegment.words.push(...targetSegmentWords);

    // adjust the previous segment's end time
    const prevSegmentLastWord = prevSegment.words[prevSegment.words.length - 1];
    prevSegment.endMillis = getWordEndMillis(prevSegmentLastWord);

    // delete the segment
    draft.segments.splice(targetSegmentIndex, 1);
  });

  return [newTranscript, targetSegmentFirstWordId, originalSpeaker];
}
