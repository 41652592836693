import { Undo } from '@sparemin/blockhead';
import { useCallback } from 'react';
import { useEditTranscript } from 'pages/TranscriptEditorPage/state';
import { useEventTracking } from 'state/eventTracking';
import {
  isWordsContextMenuConfig,
  useContextMenuActions,
  useContextMenuConfig,
} from '../../../state/contextMenu';
import ActionButton from '../../ActionButton';

export interface UndeleteActionButtonProps {}

const UndeleteActionButton: React.FC<UndeleteActionButtonProps> = () => {
  const config = useContextMenuConfig();
  const { onAction } = useContextMenuActions();
  const { undeleteWords } = useEditTranscript();
  const { trackHighlightPopperClicked } = useEventTracking();

  const handleUndelete = useCallback(() => {
    if (!isWordsContextMenuConfig(config)) {
      return;
    }
    trackHighlightPopperClicked({ buttonClick: 'Undelete' });
    undeleteWords(config.words);
    onAction({ type: 'undelete-word', config });
  }, [config, onAction, undeleteWords, trackHighlightPopperClicked]);

  return (
    <ActionButton
      aria-label="undo deletion"
      onPress={handleUndelete}
      tooltip="Undo deletion"
    >
      <ActionButton.Icon as={Undo} />
    </ActionButton>
  );
};

export default UndeleteActionButton;
