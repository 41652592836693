import { useCallback } from 'react';
import useActivateWord from '../hooks/useActivateWord';
import { useWordsById } from '../state';
import { useEditorUiActions } from '../state/editorUi';
import { SearchAction, SearchState } from './useSearchState/types';

interface UserSearchControlsConfig {
  state: SearchState;
  dispatch: React.Dispatch<SearchAction>;
}

interface UseSearchControls {
  onToggleFillerWords: (showFillerWords: boolean) => void;
  onPrev: () => void;
  onNext: () => void;
}

/**
 * Handles the arrow controls (up and down) of the search field.
 *
 * @returns
 * `onHandleToggleSearch`: Controls the search bar state.
 * `onToggleFillerWords`: Controls the filler words display state.
 * `onPrev': Navigates to the previous result.
 * `onNext': Navigates to the next result.
 */
export function useSearchControls({
  state,
  dispatch,
}: UserSearchControlsConfig): UseSearchControls {
  const { data: wordsById } = useWordsById();
  const { selectWords } = useEditorUiActions();

  const activateWord = useActivateWord();

  const handleNextWord = useCallback(
    (nextPositionIndex: number): void => {
      if (!wordsById || isNaN(nextPositionIndex)) {
        return;
      }

      const nextWordsGroup = state.foundWords[nextPositionIndex];

      activateWord(wordsById?.[nextWordsGroup[0]]);

      if (nextWordsGroup.length > 1) {
        selectWords(nextWordsGroup);
      }

      dispatch({ type: 'WORD_ACTIVATED', payload: nextPositionIndex });
    },
    [activateWord, dispatch, selectWords, state.foundWords, wordsById],
  );

  const handlePrevClick = useCallback((): void => {
    handleNextWord(
      (state.activeWordIndex - 1 + state.foundWords.length) %
        state.foundWords.length,
    );
  }, [handleNextWord, state.activeWordIndex, state.foundWords]);

  const handleNextClick = useCallback((): void => {
    handleNextWord((state.activeWordIndex + 1) % state.foundWords.length);
  }, [handleNextWord, state.activeWordIndex, state.foundWords.length]);

  const handleToggleFillerWords = useCallback(
    (showFillerWords: boolean): void => {
      dispatch({
        type: 'TOGGLE_SHOW_FILLER_WORDS',
        payload: showFillerWords,
      });
    },
    [dispatch],
  );

  return {
    onToggleFillerWords: handleToggleFillerWords,
    onPrev: handlePrevClick,
    onNext: handleNextClick,
  };
}
