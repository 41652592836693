import { useStaticCallback } from '@sparemin/blockhead';
import { useEffect } from 'react';
import { useAudioPlayer } from '../AudioPlayerContext';

export interface UsePlaybackTimeOptions {
  onTimeupdate?: (currentTime: number) => void;
}

/**
 * The media element's onTimeupdate function fires about every 200-250ms which
 * might not be fast enough for the player's progress bar or to sync the audio
 * with other elements in editor.
 */
export default function useTimeupdate({
  onTimeupdate,
}: UsePlaybackTimeOptions) {
  const { player } = useAudioPlayer();
  const staticOnTimeupdate = useStaticCallback(onTimeupdate);

  // NB: a listener is added for pause in addition to timeupdate so that the
  // listener has an accurate time when the media pauses.  For example, without
  // this, there could be a gap at the end of the playback progress bar as large
  // as the currentTime polling interval
  useEffect(() => {
    const handlePause = () => {
      staticOnTimeupdate(player.currentTime);
    };

    player.on('timeupdate', staticOnTimeupdate);
    player.on('pause', handlePause);

    return () => {
      player.off('timeupdate', staticOnTimeupdate);
      player.off('pause', handlePause);
    };
  }, [player, staticOnTimeupdate]);
}
