import { request } from 'api/http';
import {
  HEADLINER_USER_SERVICE_V1_EDDY_PROJECT_LANGUAGE_URL,
  HEADLINER_USER_SERVICE_V1_USER_PREF_URL,
  HEADLINER_USER_SERVICE_V2_DISPLAY_PREF_URL,
} from './constants';
import {
  DisplayPreferences,
  EddyProjectLanguageResult,
  UpdateEddyPrefsArgs,
  UserPreferences,
} from './types';

export async function getMyDisplayPrefs() {
  const { data } = await request.get<DisplayPreferences>(
    `${HEADLINER_USER_SERVICE_V2_DISPLAY_PREF_URL}/me`,
  );

  return data;
}

export async function getMyUserPrefs() {
  const { data } = await request.get<UserPreferences>(
    `${HEADLINER_USER_SERVICE_V1_USER_PREF_URL}/me`,
  );

  return data;
}

export async function updateEddyPrefs({
  isAutoChaptersEnabled,
  isAutoProjectNameEnabled,
  shouldRemoveFillerWords,
  isPodcastPersonalizationEnabled,
}: UpdateEddyPrefsArgs): Promise<void> {
  await request.put(`${HEADLINER_USER_SERVICE_V1_USER_PREF_URL}/eddy/me`, {
    isAutoChaptersEnabled,
    isAutoProjectNameEnabled,
    isPodcastPersonalizationEnabled,
    shouldRemoveFillerWords,
  });

  return undefined;
}

export async function getEddyProjectLanguages(): Promise<EddyProjectLanguageResult> {
  const { data } = await request.get(
    HEADLINER_USER_SERVICE_V1_EDDY_PROJECT_LANGUAGE_URL,
  );

  return data;
}
