import {
  VisibilityCircleOutline,
  VisibilityOffCircleOutline,
} from '@sparemin/blockhead';
import { useCallback } from 'react';

import MediaControlButton from 'components/MediaControlButton';
import { useEditorUiActions, useShowDeletedWords } from '../state/editorUi';

export interface DeletedWordsVisibilityButtonProps {}

const DeletedWordsVisibilityButton: React.FC<
  DeletedWordsVisibilityButtonProps
> = () => {
  const showDeletedWords = useShowDeletedWords();
  const { toggleDeletedWordsVisibility } = useEditorUiActions();

  const handlePress = useCallback(() => {
    toggleDeletedWordsVisibility();
  }, [toggleDeletedWordsVisibility]);

  return (
    <MediaControlButton
      onPress={handlePress}
      tooltip={showDeletedWords ? 'Hide deleted' : 'Show deleted'}
    >
      {showDeletedWords ? (
        <VisibilityCircleOutline height="100%" width="100%" />
      ) : (
        <VisibilityOffCircleOutline height="100%" width="100%" />
      )}
    </MediaControlButton>
  );
};

export default DeletedWordsVisibilityButton;
