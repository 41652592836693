import { IndexedClipSuggestion } from 'pages/TranscriptEditorPage/state/types';
import SuggestionContainer from './SuggestionContainer';

/**
 * Takes a list of word elements and wraps groups of words to highlight suggested
 * clips.
 *
 * Note that the `wordElements` array is modified in place. The array is returned
 * as a convenience but the input argument will effectively be modified just by
 * calling this function
 *
 * @param wordElements all of the rendered words for this segment
 * @param segmentWordOffset the "global" index of the first word of the transcript
 *  across all words
 * @param segmentSuggestions only those suggestions which apply to this segment
 * @returns
 */
export function highlightSuggestions(
  wordElements: React.ReactElement[] | undefined,
  segmentWordOffset: number | undefined,
  segmentSuggestions: IndexedClipSuggestion[] = [],
) {
  if (!wordElements || segmentWordOffset === undefined) {
    return [];
  }

  // process suggestions in reverse since each segment will replace a group of
  // word elements with a single wrapper for the suggestion.  this operation messes
  // with the length and indexes of the array since many elements get replaced by
  // one.  starting from the end means less work figuring out indexes as the loop
  // progresses
  [...segmentSuggestions].reverse().forEach((suggestion) => {
    const nWordsInSuggestion =
      suggestion.endWordIndex - suggestion.startWordIndex + 1;

    const localStartIndex = suggestion.startWordIndex - segmentWordOffset;
    const localEndIndex = localStartIndex + nWordsInSuggestion - 1;

    const startIndex = Math.max(0, localStartIndex);
    const endIndex = Math.min(localEndIndex, wordElements.length - 1);

    if (startIndex <= endIndex) {
      const nWordsInFragment = endIndex - startIndex + 1;
      const suggestionWordElements = wordElements.splice(
        startIndex,
        nWordsInFragment,
      );

      wordElements.splice(
        startIndex,
        // NB: removing 0 elements because they were already spliced out above
        0,
        <SuggestionContainer suggestionId={suggestion.id} key={suggestion.id}>
          {suggestionWordElements}
        </SuggestionContainer>,
      );
    }
  });

  return wordElements;
}
