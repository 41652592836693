import { PulseLoader } from '@sparemin/blockhead';
import React, { useCallback, useMemo } from 'react';
import OptionsList from 'components/OptionsList';
import { OptionItem } from 'components/OptionsList/types';
import { EddyPref } from 'state/userPref/types';
import AIOptionsPodcastRow from './AIOptionsPodcastRow/AIOptionsPodcastRow';
import { DEFAULT_EDDY_PREFS } from './constants';
import useOnPodcastPersonalizationChange from './useOnPodcastPersonalizationChange';

export interface AIOptionsListProps {
  isLoading?: boolean;
  prefs: EddyPref | undefined;
  onChange: (value: EddyPref) => void;
}

const AIOptionsList: React.FC<AIOptionsListProps> = ({
  isLoading,
  prefs,
  onChange,
}) => {
  const items = useMemo<OptionItem[]>(() => {
    if (!prefs) {
      return [];
    }

    return [
      {
        key: 'isAutoProjectNameEnabled',
        value: prefs?.isAutoProjectNameEnabled,
        label: 'Name my project',
      },
      {
        key: 'isAutoChaptersEnabled',
        value: prefs.isAutoChaptersEnabled,
        label: 'Add chapters to my project',
      },
      {
        key: 'shouldRemoveFillerWords',
        value: prefs.shouldRemoveFillerWords,
        label: 'Remove filler words (uh, um, etc)',
      },
      {
        key: 'isPodcastPersonalizationEnabled',
        value: prefs.isPodcastPersonalizationEnabled,
        label: 'Personalize to my podcast',
        render: (optionProps) => (
          <AIOptionsPodcastRow optionProps={optionProps} />
        ),
        tooltipContents:
          "We'll match our AI generations to your existing brand, style, and tone",
      },
    ];
  }, [prefs]);

  const { onChange: handlePodcastPersonalizationChange } =
    useOnPodcastPersonalizationChange({
      onChange: useCallback(
        (isSelected: boolean) => {
          onChange({
            ...(prefs ?? DEFAULT_EDDY_PREFS),
            isPodcastPersonalizationEnabled: isSelected,
          });
        },
        [onChange, prefs],
      ),
    });

  const handleOptionChange = useCallback(
    async (key: string, isSelected: boolean) => {
      if (key === 'isPodcastPersonalizationEnabled') {
        handlePodcastPersonalizationChange(isSelected);
      } else {
        onChange({ ...(prefs ?? DEFAULT_EDDY_PREFS), [key]: isSelected });
      }
    },
    [handlePodcastPersonalizationChange, onChange, prefs],
  );

  if (isLoading) {
    return <PulseLoader />;
  }

  return (
    <OptionsList
      aria-label="AI Options"
      items={items}
      onChange={handleOptionChange}
    />
  );
};

export default AIOptionsList;
