import WaveSurfer from 'wavesurfer.js';
import MultiCanvas from 'wavesurfer.js/src/drawer.multicanvas';
import { Peaks } from 'wavesurfer.js/types/backend';
import { WaveSurferParams } from 'wavesurfer.js/types/params';
import { HTMLElementProxy } from './types';

type InternalParams = Required<WaveSurferParams>;

/**
 * A custom drawer that renders only a single canvas element
 *
 * By default, the wavesurfer MultiCanvas drawer renders a series of 4000px
 * canvases to accommodate the waveform. The longer the audio and the higher the
 * zoom value, the more canvases are needed.  This significantly impacts performance
 * for longer waveforms.
 *
 * This renderer extends MultiCanvas but actually only renders a single canvas
 * containing the portion of the waveform that can be seen.  This canvas is
 * narrowly scoped to be used as part of this app's "deletion adjustment" ui,
 * which renders bars in a somewhat small (~500px wide) container without scroll.
 *
 * The limitations of this component as currently written are:
 *    - does not support scrolling
 *    - only supports bars, not wave
 *    - does not support widths larger than 4000px
 *
 * The implementation works by creating a "sizer" element that is as wide as
 * MultiCanvas' series of canvases.  This allows the waveform to scroll programmatically
 * so that all of the other functions and plugins within wavesurfer work as expected.
 *
 * In addition to the sizer, a single canvas is drawn, which is the same width as
 * the waveform container.  As the waveform scrolls programattically, the scrollOffset
 * is applied to the single canvas element as a CSS translate, moving it into the
 * correct location so that it aligns with the waveform container.
 */
export default class SegmentDrawer extends MultiCanvas {
  declare params: InternalParams;

  private sizer: HTMLElementProxy | undefined;

  public init() {
    super.init();

    this.attachEventHandlers();
    this.createSizer();
  }

  /**
   * @override
   *
   * https://github.com/wavesurfer-js/wavesurfer.js/blob/2ae31ce9ade32c07392c593186a82e3669686864/src/drawer.multicanvas.js#L142
   */
  public updateSize() {
    const totalWidth = Math.round(this.width / this.params.pixelRatio);

    // set the maxCanvasWidth to the size of the only canvas being rendered.
    // the npm types define this as a readonly property, which is true if it's being
    // accessed from the outside, but we're extending the class and nothing about
    // the implementation makes it readonly.  In fact, it's just a standard public
    // property.
    //
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.maxCanvasWidth = this.getWidth();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.maxCanvasElementWidth = Math.round(
      this.maxCanvasWidth / this.params.pixelRatio,
    );

    if (this.canvases.length === 0) {
      this.addCanvas();
    }

    if (this.sizer) {
      WaveSurfer.util.style(this.sizer, {
        width: `${totalWidth}px`,
      });
    }

    const canvas = this.canvases[0];

    this.updateDimensions(canvas, this.getWidth(), this.height);

    canvas.clearWave();
  }

  /**
   * @override
   *
   * This is mostly the original function from the MultiCanvas renderer with one
   * small modification.
   *
   * The default MultiCanvas implementation correlates samples from the `peaks`
   * array with canvases from the `this.canvases` array - meaning that through some
   * math, it figures out that peak `x` belongs on canvas `y`.
   *
   * For our drawer implementation, there is only one canvas, so we need to
   * ensure that `this.fillRect` fills a rectangle on the one and only canvas.
   *
   * https://github.com/wavesurfer-js/wavesurfer.js/blob/2ae31ce9ade32c07392c593186a82e3669686864/src/drawer.multicanvas.js#L280
   */
  drawBars(
    wavePeaks: Peaks,
    channelIndex: number,
    start: number,
    end: number,
  ): void {
    return this.prepareDraw(
      wavePeaks,
      channelIndex,
      start,
      end,
      ({
        absmax,
        hasMinVals,
        height,
        offsetY,
        halfH,
        peaks,
        channelIndex: ch,
      }) => {
        // if drawBars was called within ws.empty we don't pass a start and
        // don't want anything to happen
        if (start === undefined) {
          return;
        }
        // Skip every other value if there are negatives.
        const peakIndexScale = hasMinVals ? 2 : 1;
        const length = peaks.length / peakIndexScale;
        const bar = this.params.barWidth * this.params.pixelRatio;
        const gap =
          this.params.barGap === null
            ? Math.max(this.params.pixelRatio, ~~(bar / 2))
            : Math.max(
                this.params.pixelRatio,
                this.params.barGap * this.params.pixelRatio,
              );
        const step = bar + gap;

        const scale = length / this.width;
        const first = start;
        const last = end;
        let peakIndex = first;
        for (peakIndex; peakIndex < last; peakIndex += step) {
          // search for the highest peak in the range this bar falls into
          let peak = 0;
          let peakIndexRange = Math.floor(peakIndex * scale) * peakIndexScale; // start index
          const peakIndexEnd =
            Math.floor((peakIndex + step) * scale) * peakIndexScale;
          do {
            // do..while makes sure at least one peak is always evaluated
            const newPeak = Math.abs(peaks[peakIndexRange]); // for arrays starting with negative values
            if (newPeak > peak) {
              peak = newPeak; // higher
            }
            peakIndexRange += peakIndexScale; // skip every other value for negatives
          } while (peakIndexRange < peakIndexEnd);

          // calculate the height of this bar according to the highest peak found
          let h = Math.round((peak / absmax) * halfH);

          // raise the bar height to the specified minimum height
          // Math.max is used to replace any value smaller than barMinHeight (not just 0) with barMinHeight
          if (this.params.barMinHeight) {
            h = Math.max(h, this.params.barMinHeight);
          }

          // *******************************************************************
          // * This is the only change
          // *
          // * The first parameter passed to fillRect is the x offset - basically
          // * the number of pixels from the beginning of the waveform where this bar
          // * should be drawn.  If you divide by the maxCanvasSize, you can figure out
          // * on which canvas this bar belongs and how many pixels into that canvas
          // * the bar should be drawn.
          // *
          // * We only have one canvas, so by subtracting the first peakIndex of
          // * the waveform segment, we get the offset into the one and only canvas
          // * where this bar should be drawn.
          // *******************************************************************
          this.fillRect(
            peakIndex + this.halfPixel - first,
            halfH - h + offsetY,
            bar + this.halfPixel,
            h * 2,
            this.barRadius,
            ch,
          );
        }
      },
    );
  }

  /**
   * @override
   */
  public destroy() {
    super.destroy();

    if (this.sizer) {
      this.sizer.parentNode?.removeChild(this.sizer.domElement);
      this.sizer = undefined;
    }

    this.removeEventHandlers();
  }

  private createSizer() {
    this.sizer = WaveSurfer.util.withOrientation(
      this.wrapper.appendChild(document.createElement('sizer')),
      this.params.vertical,
    ) as HTMLElementProxy;

    WaveSurfer.util.style(this.sizer, {
      position: 'absolute',
      left: '0',
      top: '0',
      height: '100%',
      pointerEvents: 'none',
    });
  }

  private handleScroll = () => {
    WaveSurfer.util.style(this.canvases[0].wave, {
      transform: `translateX(${this.wrapper.scrollLeft}px)`,
    });
  };

  private attachEventHandlers() {
    this.on('scroll', this.handleScroll);
  }

  private removeEventHandlers() {
    this.un('scroll', this.handleScroll);
  }
}
