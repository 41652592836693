import { noop, over } from 'lodash-es';
import React, { useMemo } from 'react';
import {
  Helmet as ReactHelmet,
  HelmetProps as ReactHelmetProps,
} from 'react-helmet-async';
import { dedupeById } from './utils';

export type HelmetProps = React.PropsWithChildren<ReactHelmetProps>;

/**
 * react-helmet-async considers all link tags with different properties to be
 * different elements, as defined by `isEqualNode`
 *
 * This component ensures that two elements with the same id don't get added to
 * the head at the same time.
 *
 * Inspired by https://github.com/nfl/react-helmet/issues/430#issuecomment-519312886,
 * which also contains a description of the issue
 */
const Helmet: React.FC<HelmetProps> = ({
  onChangeClientState = noop,
  ...props
}) => {
  const handleChangeClientState = useMemo(
    () => over(dedupeById, onChangeClientState),
    [onChangeClientState],
  );

  return (
    <ReactHelmet onChangeClientState={handleChangeClientState} {...props} />
  );
};

export default Helmet;
