import { Select } from '@sparemin/blockhead';
import {
  ProjectAssetFormalityFeedback,
  ProjectAssetLengthFeedback,
  ProjectAssetToneFeedback,
} from 'api';
import { useIsBasicOrFreeTier } from 'state/pricing';
import { useTabPanel } from '../BaseTabPanel';
import {
  formalityFeedbackOptions,
  lengthFeedbackOptions,
  toneFeedbackOptions,
} from './constants';

export interface DefaultRegenerationOptionsProps {}

const DefaultRegenerationOptions: React.FC<
  DefaultRegenerationOptionsProps
> = () => {
  const { adjustmentValues, onAdjustmentValueChange } = useTabPanel();
  const { data: isBasicOrFreeTier } = useIsBasicOrFreeTier();

  return (
    <>
      <Select
        fluid
        selectedKey={adjustmentValues?.lengthFeedback}
        defaultSelectedKey={lengthFeedbackOptions[0].value}
        onSelectionChange={(value) =>
          onAdjustmentValueChange({
            lengthFeedback: value as ProjectAssetLengthFeedback,
          })
        }
        label="Length"
        aria-label="supported regeneration length list"
        isDisabled={isBasicOrFreeTier}
      >
        {lengthFeedbackOptions.map(({ value, label }) => (
          <Select.Option key={value} textValue={label}>
            {label}
          </Select.Option>
        ))}
      </Select>

      <Select
        fluid
        label="Formality"
        selectedKey={adjustmentValues?.formalityFeedback}
        defaultSelectedKey={formalityFeedbackOptions[0].value}
        onSelectionChange={(value) =>
          onAdjustmentValueChange({
            formalityFeedback: value as ProjectAssetFormalityFeedback,
          })
        }
        aria-label="supported regeneration formality list"
        isDisabled={isBasicOrFreeTier}
      >
        {formalityFeedbackOptions.map(({ value, label }) => (
          <Select.Option key={value} textValue={label}>
            {label}
          </Select.Option>
        ))}
      </Select>

      <Select
        fluid
        selectedKey={adjustmentValues?.toneFeedback}
        defaultSelectedKey={toneFeedbackOptions[0].value}
        onSelectionChange={(value) =>
          onAdjustmentValueChange({
            toneFeedback: value as ProjectAssetToneFeedback,
          })
        }
        label="Tone"
        aria-label="supported regeneration tone list"
        isDisabled={isBasicOrFreeTier}
      >
        {toneFeedbackOptions.map(({ value, label }) => (
          <Select.Option key={value} textValue={label}>
            {label}
          </Select.Option>
        ))}
      </Select>
    </>
  );
};

export default DefaultRegenerationOptions;
