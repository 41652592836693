import { useCallback, useEffect, useRef, useState } from 'react';
import { SwitchTransition, Transition } from 'react-transition-group';
import { useEventTracking } from 'state/eventTracking';
import useApplyAIFeatures from '../state/aiFeatures';
import ProjectLoaderFreeTier from './ProjectLoaderFreeTier/ProjectLoaderFreeTier';
import ProjectLoaderPaidTier from './ProjectLoaderPaidTier';
import ProjectLoaderSpinner from './ProjectLoaderSpinner';
import * as S from './styles';
import { TRANSITION_DURATION_MILLIS } from './transition';
import useProjectLoaderState from './useProjectLoaderState';

export interface ProjectLoaderProps {
  isTranscribing?: boolean;
  onLoadingComplete?: () => void;
}

// TODO SPAR-20229 move event tracking to a dedicated hook
const ProjectLoader: React.FC<ProjectLoaderProps> = ({
  isTranscribing,
  onLoadingComplete,
}) => {
  const [aiFeaturesLoaded, setAiFeaturesLoaded] = useState(false);
  const [transitionElementLoaded, setTransitionElementLoaded] = useState(false);

  const { trackTranscriptPageLoading, trackTranscriptPageTranscribing } =
    useEventTracking();
  const viewRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isTranscribing) {
      trackTranscriptPageTranscribing();
    } else {
      trackTranscriptPageLoading();
    }
  }, [
    isTranscribing,
    trackTranscriptPageLoading,
    trackTranscriptPageTranscribing,
  ]);

  const handleProjectAiFeaturesLoadComplete = useCallback(() => {
    setAiFeaturesLoaded(true);
  }, []);

  const handleProjectLoadingComplete = useCallback(() => {
    setTransitionElementLoaded(true);
  }, []);

  useApplyAIFeatures({
    onAIFeatureApplied: handleProjectAiFeaturesLoadComplete,
  });

  const { showProgress, viewId } = useProjectLoaderState({
    aiFeaturesApplied: aiFeaturesLoaded,
    isLoadingComplete: transitionElementLoaded,
    onLoadingComplete: handleProjectLoadingComplete,
  });

  useEffect(() => {
    if (aiFeaturesLoaded && transitionElementLoaded) {
      onLoadingComplete?.();
    }
  }, [aiFeaturesLoaded, onLoadingComplete, transitionElementLoaded]);

  return (
    <SwitchTransition>
      <Transition
        key={viewId}
        enter={viewId !== 'spinner'}
        exit={viewId === 'spinner' ? showProgress : true}
        mountOnEnter
        nodeRef={viewRef}
        unmountOnExit
        timeout={TRANSITION_DURATION_MILLIS}
      >
        {(status) => {
          if (viewId === 'spinner') {
            return (
              <S.TransitionedElement
                as={ProjectLoaderSpinner}
                ref={viewRef}
                status={status}
              />
            );
          }
          if (viewId === 'loaderFree') {
            return (
              <S.TransitionedElement
                as={ProjectLoaderFreeTier}
                onLoadingComplete={handleProjectLoadingComplete}
                ref={viewRef}
                status={status}
              />
            );
          }
          if (viewId === 'loaderPaid') {
            return (
              <S.TransitionedElement
                as={ProjectLoaderPaidTier}
                onLoadingComplete={handleProjectLoadingComplete}
                ref={viewRef}
                status={status}
              />
            );
          }
        }}
      </Transition>
    </SwitchTransition>
  );
};

export default ProjectLoader;
