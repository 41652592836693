import { ContextMenuAction } from '../../../state/contextMenu';
import AdjustSelectionActionButton from './AdjustSelectionActionButton';
import DeleteActionButton from './DeleteActionButton';
import DownloadClipActionButton from './DownloadClipActionButton';
import EditTextActionButton from './EditTextActionButton';
import HeadlinerActionButton from './HeadlinerActionButton';
import InsertElementActionButton from './InsertElementActionButton/InsertElementActionButton';
import UndeleteActionButton from './UndeleteActionButton';

export interface ActionMenuContentsProps {
  actions: ContextMenuAction[];
  onEditPress: () => void;
}

const ActionMenuContents: React.FC<ActionMenuContentsProps> = ({
  actions,
  onEditPress,
}) => (
  <>
    {actions.includes('adjust-selection') && <AdjustSelectionActionButton />}
    {actions.includes('delete-word') && <DeleteActionButton />}
    {actions.includes('undelete-word') && <UndeleteActionButton />}
    <EditTextActionButton onPress={onEditPress} />
    {actions.includes('headliner') && <HeadlinerActionButton />}
    {actions.includes('download') && <DownloadClipActionButton />}
    <InsertElementActionButton />
  </>
);

export default ActionMenuContents;
