import { isEqual } from 'lodash-es';
import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { updateColorPalette } from 'api/services';
import { MutationOptions } from 'types';
import { colorPaletteQueryKeys } from './queryKeys';
import { useProjectCuid } from './useEditorProject';
import { useHexColorPaletteList } from './useGetColorPalette';

export type UseUpdateColorPaletteVariables = {
  colors: string[];
};

export default function useUpdateColorPalette(
  opts?: MutationOptions<undefined, UseUpdateColorPaletteVariables>,
) {
  const { data: currentHexColorPalette } = useHexColorPaletteList();
  const { data: projectCuid } = useProjectCuid();
  const queryClient = useQueryClient();

  const queryKey = colorPaletteQueryKeys.project(projectCuid);

  const mutationFn = useCallback(
    async ({ colors }: UseUpdateColorPaletteVariables): Promise<undefined> => {
      if (!projectCuid) {
        throw new Error('Error updating color palette');
      }

      if (isEqual(currentHexColorPalette, colors)) {
        return;
      }

      return updateColorPalette({
        projectCuid,
        colors: colors.map((color) => ({
          hexColorCode: color,
        })),
      });
    },
    [currentHexColorPalette, projectCuid],
  );

  return useMutation<
    undefined,
    unknown,
    UseUpdateColorPaletteVariables,
    unknown
  >({
    mutationFn,
    onSettled: () => {
      queryClient.invalidateQueries(queryKey);
    },
    ...opts,
  });
}
