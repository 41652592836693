import {
  Button,
  HeadyGradient,
  PromoPopper as Popper,
} from '@sparemin/blockhead';
import { useState } from 'react';
import TextButton from 'components/TextButton';
import { popperItems } from './constants';
import * as S from './styles';
import { usePromoPopper } from './usePromoPopper';

export interface PromoPopperProps {}

const PromoPopper: React.FC<PromoPopperProps> = () => {
  const [triggerElement, triggerRef] = useState(null);

  const {
    showPopper,
    isLoading,
    toggleShowPopper,
    onOpenPromoPackModal,
    onDismissPromoPopper,
  } = usePromoPopper();

  return (
    <S.Root>
      <Popper
        triggerElement={triggerElement}
        show={showPopper}
        bannerSrc="https://static.sparemin.com/static/eddy/promopack-popper-header-image-202312121904.jpg"
      >
        <Popper.Header
          title="We made you a promo pack"
          description="Headliner AI personalized assets to your podcast."
        />

        <Popper.ContentsList items={popperItems} />

        <Popper.Footer>
          <Button onPress={onOpenPromoPackModal} isDisabled={isLoading}>
            view promo pack
          </Button>

          <TextButton onPress={onDismissPromoPopper}>Dismiss</TextButton>
        </Popper.Footer>
      </Popper>

      <Popper.Trigger
        onPress={toggleShowPopper}
        onAnimationComplete={toggleShowPopper}
        ref={triggerRef}
      >
        <HeadyGradient width="48px" height="48px" />
      </Popper.Trigger>
    </S.Root>
  );
};

export default PromoPopper;
