import { useEffect, useRef } from 'react';
import ReactGA from 'react-ga4';
import { Outlet, useLocation } from 'react-router-dom';
import { useEventTracking } from 'state/eventTracking';
import { usePlanName } from 'state/pricing';
import { useGetAuthenticatedUserProfile } from 'state/user';

interface AppLayoutProps {}

const AppLayout: React.FC<AppLayoutProps> = () => {
  const location = useLocation();
  const { data: userData } = useGetAuthenticatedUserProfile();
  const { data: planName } = usePlanName();
  const { setUserTrackingInfo } = useEventTracking();
  const ref = useRef<number | undefined>(undefined);

  useEffect(() => {
    const page = `${location.pathname}${location.search}`;
    ReactGA.send({ hitType: 'pageview', page });
  }, [location]);

  useEffect(() => {
    if (
      planName &&
      userData?.userId &&
      userData?.email &&
      ref.current !== userData.userId
    ) {
      ref.current = userData.userId;
      setUserTrackingInfo(userData.email, userData.userId, planName);
    }
  }, [userData?.userId, userData?.email, setUserTrackingInfo, planName]);

  return <Outlet />;
};

export default AppLayout;
