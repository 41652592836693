import { VideoOutline } from '@sparemin/blockhead';
import { useCallback } from 'react';
import { useEventTracking } from 'state/eventTracking';
import { useModalsActions } from 'state/modal';
import { useProjectCuid } from '../../../state';
import { useWordsContextMenuConfig } from '../../../state/contextMenu';
import ActionButton from '../../ActionButton';

export interface HeadlinerActionButtonProps {}

const HeadlinerActionButton: React.FC<HeadlinerActionButtonProps> = () => {
  const config = useWordsContextMenuConfig();
  const { data: projectCuid } = useProjectCuid();
  const { pushModal } = useModalsActions();
  const { trackHighlightPopperClicked } = useEventTracking();

  const handleExport = useCallback(async () => {
    if (!config || !projectCuid) {
      return;
    }
    trackHighlightPopperClicked({ buttonClick: 'MakeAudiogram' });
    const { words } = config;

    pushModal({
      name: 'AudiogramExport',
      params: {
        words,
      },
    });
  }, [config, projectCuid, pushModal, trackHighlightPopperClicked]);

  return (
    <ActionButton
      aria-label="create clip"
      onPress={handleExport}
      tooltip="Create clip"
    >
      <ActionButton.Icon as={VideoOutline} />
    </ActionButton>
  );
};

export default HeadlinerActionButton;
