import { uniq } from 'lodash-es';
import { useCallback } from 'react';
import { useEditTranscript } from 'pages/TranscriptEditorPage/state';
import { useContextMenuActions } from 'pages/TranscriptEditorPage/state/contextMenu';
import { useSearchActionMenu } from '../SearchActionMenuContext';
import * as S from '../styles';

export interface TextDeleteMenuContentsProps {}

const TextDeleteMenuContents: React.FC<TextDeleteMenuContentsProps> = () => {
  const { foundWords, activeWordIndex, onDelete } = useSearchActionMenu();
  const { deleteSearchResults } = useEditTranscript();
  const { onAction } = useContextMenuActions();

  const handleDelete = useCallback(
    (words: number[]) => {
      deleteSearchResults(words);
      // TODO this isn't a context menu action, so onAction might not be the right
      // callback here.  If we need to know the "last completed action", we might
      // want to move this state outside of the context menu store or increase the
      // scope of the context menu store to include things like this
      onAction({ type: 'delete-word', config: { words } });
      onDelete?.();
    },
    [deleteSearchResults, onAction, onDelete],
  );

  const handleDeleteCurrent = useCallback(() => {
    handleDelete([foundWords[activeWordIndex][0]]);
  }, [activeWordIndex, foundWords, handleDelete]);

  const handleDeleteAll = useCallback(() => {
    handleDelete(uniq(foundWords.flat()));
  }, [foundWords, handleDelete]);

  return (
    <S.DeleteMenuContainer orientation="vertical" space={2}>
      <S.DeleteMenuItem onPress={handleDeleteCurrent}>
        Remove this result
      </S.DeleteMenuItem>
      <S.DeleteMenuItem onPress={handleDeleteAll}>
        Remove all results
      </S.DeleteMenuItem>
    </S.DeleteMenuContainer>
  );
};

export default TextDeleteMenuContents;
