import React from 'react';
import { useProjectCreationProgress } from 'pages/TranscriptEditorPage/state';
import * as S from './styles';
import { ProjectLoaderProgressProps } from './types';

const ProjectLoaderProgress = React.forwardRef<
  HTMLDivElement,
  ProjectLoaderProgressProps
>(({ size = 'small', ...props }, ref) => {
  const { data: percentMilli = 0 } = useProjectCreationProgress();

  return (
    <S.Progress
      ref={ref}
      aria-label="project creation progress"
      $size={size}
      value={Math.floor(percentMilli / 1000)}
      {...props}
    />
  );
});

export default ProjectLoaderProgress;
