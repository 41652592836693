import { useCallback } from 'react';
import { useEventTracking } from 'state/eventTracking';
import { getAspectRatioNameFromDimensions } from 'utils/aspectRatio';
import { getPromoPackArtStyle } from '../EpisodeArtTabPanel/utils';
import { DEFAULT_TRACK_PROPS_BY_ASSET_TYPE } from './constants';
import { HandleTrackRegenerationAIOutputArgs } from './types';

export interface UseTrackRegenerationAIOutputResult {
  trackRegenerationAIOutput: (
    args: HandleTrackRegenerationAIOutputArgs,
  ) => void;
}

export default function useTrackRegenerationAIOutput() {
  const { trackRegenerateAIOutput } = useEventTracking();

  const handleTrackRegenerationAIOutput = useCallback(
    ({
      asset,
      adjustmentValues,
      colorPaletteState,
    }: HandleTrackRegenerationAIOutputArgs): void => {
      if (!asset) {
        return;
      }

      const assetType = asset.assetType;
      const assetContents = asset?.contents?.[0];
      const defaultProps = DEFAULT_TRACK_PROPS_BY_ASSET_TYPE[assetType];

      const {
        lengthFeedback,
        formalityFeedback,
        toneFeedback,
        includeChapters,
        customFeedback,
        imageDimensionWidth,
        imageDimensionHeight,
        imageType,
      } = adjustmentValues || {};

      const { length, formality, tone, chapters, freeformInput } =
        defaultProps || {};

      trackRegenerateAIOutput({
        assetType,
        length: lengthFeedback ?? length,
        formality: formalityFeedback ?? formality,
        tone: toneFeedback ?? tone,
        chapters: includeChapters ?? chapters,
        freeformInput: customFeedback ?? freeformInput,
        aspectRatio:
          assetType === 'episodeArt'
            ? getAspectRatioNameFromDimensions(
                imageDimensionWidth || assetContents?.imageWidth,
                imageDimensionHeight || assetContents?.imageHeight,
              )
            : 'Not Set',
        style:
          assetType === 'episodeArt'
            ? getPromoPackArtStyle(imageType) || 'none'
            : 'Not Set',
        colors:
          assetType === 'episodeArt'
            ? colorPaletteState.colors().length
            : 'Not Set',
      });
    },
    [trackRegenerateAIOutput],
  );

  return {
    trackRegenerationAIOutput: handleTrackRegenerationAIOutput,
  };
}
