import {
  ProjectTranscript,
  TranscriptEdit,
  UpdateProjectTranscriptTextArgs,
} from 'api';
import AsyncUndoableTranscriptCommand from '../AsyncUndoableTranscriptCommand';
import TranscriptOriginator from '../TranscriptOriginator';
import { CommandOptions, Committer } from '../types';
import { createInverseEdits } from '../utils';
import optimisticUpdate from './optimisticUpdate';

export type WordCommitterArgs = Pick<UpdateProjectTranscriptTextArgs, 'edits'>;

export default abstract class AsyncUndoableWordCommand extends AsyncUndoableTranscriptCommand {
  private edits: TranscriptEdit[] | undefined;

  private inverseEdits: TranscriptEdit[] | undefined;

  constructor(
    originator: TranscriptOriginator,
    private committer: Committer<WordCommitterArgs>,
    opts?: CommandOptions,
  ) {
    super(originator, opts);
  }

  protected abstract createEdits(
    transcript: ProjectTranscript | undefined,
  ): TranscriptEdit[];

  protected executeTranscript(
    transcript: ProjectTranscript,
  ): ProjectTranscript | undefined {
    if (!this.edits) {
      this.edits = this.createEdits(transcript);
    }

    if (!this.inverseEdits) {
      this.inverseEdits = createInverseEdits(this.edits, transcript);
    }

    return optimisticUpdate(transcript, this.edits);
  }

  protected unexecuteTranscript(
    transcript: ProjectTranscript,
  ): ProjectTranscript | undefined {
    if (!this.inverseEdits) {
      return undefined;
    }

    return optimisticUpdate(transcript, this.inverseEdits);
  }

  async commit(): Promise<void> {
    if (this.edits) {
      try {
        return await this.committer({
          edits: this.edits,
        });
      } catch (err) {
        this.onError('commit');
        throw err;
      }
    }
  }

  async uncommit(): Promise<void> {
    if (this.inverseEdits) {
      try {
        return await this.committer({
          edits: this.inverseEdits,
        });
      } catch (err) {
        this.onError('uncommit');
        throw err;
      }
    }
  }
}
