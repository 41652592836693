import { RefObject, useCallback } from 'react';
import { useEventListener, UseEventListenerConfig } from './useEventListener';

export type UseClickOutsideOptions = Pick<
  UseEventListenerConfig,
  'disabled'
> & {
  ignoreSelector?: string;
};

/**
 * Runs a callback function everytime a click event occurs outside the current component.
 *
 * @param ref - RefObject<HTMLDivElement>
 * @param callback - The function to be called when a click event occurs outside the element.
 * @param {string[]} [elementIdsToIgnore] - An array of element IDs that needs to be ignored.
 */
export function useClickOutside(
  ref: RefObject<HTMLDivElement>,
  callback: () => void,
  options?: UseClickOutsideOptions,
): void {
  const { disabled, ignoreSelector } = options ?? {};

  const handleClickOutside = useCallback(
    (event?: MouseEvent) => {
      const elementsToIgnore = ignoreSelector
        ? Array.from(document.querySelectorAll(ignoreSelector))
        : [];

      const targetContainsIgnoredElement =
        elementsToIgnore.find((el) => {
          const target = event?.target as HTMLElement;
          return el.contains(target);
        }) !== undefined;

      if (
        ref.current &&
        !ref.current.contains(event?.target as HTMLElement) &&
        !targetContainsIgnoredElement
      ) {
        callback();
      }
    },
    [callback, ignoreSelector, ref],
  );

  useEventListener('pointerdown', handleClickOutside, {
    disabled,
    options: { capture: true },
  });
}
