import { deleteChapter } from 'api';
import { placeholderChapterIds } from './placeholderIds';
import { useTranscriptId } from './useEditorTranscript';
import useUpdateTranscript from './useUpdateTranscript';

type UseDeleteTranscriptChapterVariables = {
  chapterId: number;
};

export default function useDeleteTranscriptChapter() {
  const { data: transcriptId } = useTranscriptId();

  return useUpdateTranscript<void, UseDeleteTranscriptChapterVariables>({
    mutationFn: ({ chapterId }) => {
      if (!transcriptId) {
        throw new Error('Error deleting chapter');
      }

      const actualChapterId = placeholderChapterIds.getActualId(chapterId);
      return deleteChapter({ chapterId: actualChapterId, transcriptId });
    },
  });
}
