import { ButtonBase, getThemeColor } from '@sparemin/blockhead';
import styled from 'styled-components';

export const CHAPTER_TITLE_FONT_SIZE_PX = 18;
export const CHAPTER_TITLE_LINE_HEIGHT = 1.44;
export const CHAPTER_TITLE_MARGIN_BOTTOM_PX = 8;

export const ChapterTitleButton = styled(ButtonBase)`
  width: 100%;
  max-width: inherit;
  display: inline-block;
  margin: 0px;
  margin-bottom: 8px;
  font-family: ${({ theme }) => theme.typography.fontFamily.secondary};
  font-size: 18px;
  font-weight: 700;
  text-transform: none;
  letter-spacing: 0.5px;
  line-height: normal;
  text-align: left;
  text-overflow: clip;
  white-space: pre-wrap;
  word-break: break-word;
  background-color: transparent;
  transition: ${({ theme }) => theme.transitions.create(['color'], '300ms')};

  &[data-hovered='true'] {
    color: ${getThemeColor('d5')};
  }

  &:active:not([disabled]) {
    box-shadow: none;
  }
`;
