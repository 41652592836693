import {
  Button,
  Heading,
  HeadySparkle,
  UnstyledLink,
} from '@sparemin/blockhead';
import * as S from './styles';

export interface MobileComingSoonProps {}

const MobileComingSoon: React.FC<MobileComingSoonProps> = () => {
  return (
    <S.MobileComingSoonContainer>
      <HeadySparkle width="123px" height="100px" />

      <S.MobileComingSoonHeader>
        <Heading level={1}>
          Eddy isn’t <i>mobile</i> yet
        </Heading>

        <Heading level={2}>
          We’re still getting Eddy ready for mobile devices, so please visit us
          on desktop for now. In the meantime, please tell us how you will use
          it:
        </Heading>
      </S.MobileComingSoonHeader>

      <Button
        as={UnstyledLink}
        href="https://docs.google.com/forms/d/e/1FAIpQLSeOtY_8bpk1aXiGAO5iWG5CmOq9IKMvCD7NKaRsX6uc-PiN_A/viewform"
        target="_blank"
      >
        take our mobile survey
      </Button>
    </S.MobileComingSoonContainer>
  );
};

export default MobileComingSoon;
