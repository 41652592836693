import { Tooltip } from '@sparemin/blockhead';
import React from 'react';
import * as S from './styles';

export interface OptionProps {
  label: string;
  isSelected?: boolean;
  isDisabled?: boolean;
  tooltipContents?: React.ReactNode;
  onChange?: (isSelected: boolean) => void;
}

const Option: React.FC<OptionProps> = ({
  label,
  isSelected,
  isDisabled,
  tooltipContents,
  onChange,
}) => {
  return (
    <Tooltip arrow contents={tooltipContents} isDisabled={!tooltipContents}>
      <div>
        <S.Option {...{ isSelected, isDisabled, onChange }}>
          <S.OptionText>{label}</S.OptionText>
        </S.Option>
      </div>
    </Tooltip>
  );
};

export default Option;
