import { Button } from '@sparemin/blockhead';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import Plate from 'components/Plate';

export const Root = styled(motion.div).attrs({
  space: '24px',
})`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  margin-top: 28px;
  margin-bottom: 32px;
`;

export const Contents = styled(Plate).attrs({
  space: '24px',
})`
  max-width: max-content;
  height: 80px;
`;

export const IconContainer = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  background-image: linear-gradient(
    135deg,
    rgba(93, 190, 252, 0.1),
    rgba(221, 146, 254, 0.1)
  );
`;

export const ActionButton = styled(Button)`
  min-width: 150px;
`;
