import { AudioPlayer } from 'utils/audio';
import { millisToSec } from 'utils/time';
import { SEEK_EPSILON_MILLIS } from '../constants';

const WORD_SEEK_EPSILON_MILLIS = SEEK_EPSILON_MILLIS;

/*
  NB: This will probably be moved or maybe handled somewhere else.  Seeking to a word
  has issues sometimes because a word's start time might be the same as the previous
  word's end time, in which case seeking to a word will sometimes show the previous
  word as being selected.  In terms of the audio, technically not wrong but will
  seem incorrect to the user.
  this function sovles the problem by adding a small value to the start time before
  seeking.
*/
// TODO seekToWord should probably accept a word object and not a start time
export function seekToWord(
  player: AudioPlayer,
  startMillis: number,
  epsilon = WORD_SEEK_EPSILON_MILLIS,
) {
  player.seek(millisToSec(startMillis + epsilon));
}
