import React, { useCallback, useMemo, useState } from 'react';
import { QueryStatus } from 'react-query';

import { TranscriptFormat } from 'api/services/transcript';
import ExportModalContents, {
  ShareExportingBody,
  ShareInitialBody,
} from 'components/ExportModalContents';
import { useEventTracking } from 'state/eventTracking';
import { useModalsActions } from 'state/modal';
import { ShareProjectModalConfig } from 'state/modal/modals';
import { ExportFormat } from 'types';
import { useHasEdgeAsset } from '../state';

import useShare from './hooks/useShare';
interface ShareProjectModalContentsProps {
  params?: ShareProjectModalConfig['params'];
}

const ShareProjectModalContents: React.FunctionComponent<
  ShareProjectModalContentsProps
> = (props) => {
  const { trackExport } = useEventTracking();
  const { pushModal } = useModalsActions();
  const { params } = props;

  const [exportFormat, setExportFormat] = useState<ExportFormat>();

  const { status, exportAudio, exportVideo, exportTranscript } = useShare();

  const { data: hasIntroAsset = false } = useHasEdgeAsset('intro');
  const { data: hasOutroAsset = false } = useHasEdgeAsset('outro');

  const exportTranscriptFormat = useCallback(
    (format: TranscriptFormat) => {
      if (params) {
        exportTranscript({
          format,
          projectCuid: params?.projectCuid,
          projectName: params?.projectName,
          selection: params?.selection,
          download: true,
        });
      }
    },
    [exportTranscript, params],
  );

  const handleExportAudio = React.useCallback(() => {
    if (params) {
      setExportFormat('audio');
      trackExport({
        buttonClick: 'Audio',
        introAdded: hasIntroAsset,
        outroAdded: hasOutroAsset,
      });
      exportAudio({
        download: true,
        projectCuid: params.projectCuid,
        projectName: params.projectName,
        selection: params.selection,
      });
    }
  }, [exportAudio, hasIntroAsset, hasOutroAsset, params, trackExport]);

  const handleExportVideo = React.useCallback(() => {
    if (params) {
      setExportFormat('video');
      trackExport({
        buttonClick: 'Video',
        introAdded: hasIntroAsset,
        outroAdded: hasOutroAsset,
      });
      exportVideo({
        download: true,
        projectCuid: params.projectCuid,
        projectName: params.projectName,
        selection: params.selection,
      });
    }
  }, [exportVideo, hasIntroAsset, hasOutroAsset, params, trackExport]);

  const onExportAudiogram = React.useCallback(() => {
    trackExport({
      buttonClick: 'Audiogram',
      introAdded: hasIntroAsset,
      outroAdded: hasOutroAsset,
    });
    pushModal({
      name: 'AudiogramExport',
    });
  }, [hasIntroAsset, hasOutroAsset, pushModal, trackExport]);

  const handleExportDoc = React.useCallback(() => {
    if (params) {
      setExportFormat('docx');
      trackExport({ buttonClick: 'Doc' });
      exportTranscriptFormat('docx');
    }
  }, [exportTranscriptFormat, params, trackExport]);

  const handleExportVtt = React.useCallback(() => {
    if (params) {
      setExportFormat('vtt');
      trackExport({ buttonClick: 'VTT' });
      exportTranscriptFormat('vtt');
    }
  }, [exportTranscriptFormat, params, trackExport]);

  const handleExportSrt = React.useCallback(() => {
    if (params) {
      setExportFormat('srt');
      trackExport({ buttonClick: 'SRT' });
      exportTranscriptFormat('srt');
    }
  }, [exportTranscriptFormat, params, trackExport]);

  const handleExportPdf = React.useCallback(() => {
    if (params) {
      setExportFormat('pdf');
      trackExport({ buttonClick: 'PDF' });
      exportTranscriptFormat('pdf');
    }
  }, [exportTranscriptFormat, params, trackExport]);

  const handleExportTranscriptClick = React.useCallback(() => {
    if (!params) {
      return;
    }

    pushModal({
      name: 'ExportTranscript',
      params: {
        projectCuid: params?.projectCuid,
        projectName: params?.projectName,
        projectMediaType: params?.projectMediaType,
        selection: params?.selection,
      },
    });
  }, [params, pushModal]);

  const handleExportUrlsClick = React.useCallback(() => {
    if (!params) {
      return;
    }

    pushModal({
      name: 'ExportURLs',
      params: {
        onGoBack: () => {
          pushModal({
            name: 'ShareProject',
            params,
          });
        },
      },
    });
  }, [params, pushModal]);

  const buttonsDisabled = status === 'loading';

  const exportStatusViews = useMemo((): Record<
    QueryStatus,
    React.ReactNode
  > => {
    return {
      idle: (
        <ShareInitialBody
          {...{
            buttonsDisabled,
          }}
          projectMediaType={params?.projectMediaType}
          onExportAudio={handleExportAudio}
          onExportVideo={handleExportVideo}
          onExportAudiogram={onExportAudiogram}
          onExportDoc={handleExportDoc}
          onExportVtt={handleExportVtt}
          onExportSrt={handleExportSrt}
          onExportPdf={handleExportPdf}
          onExportTranscriptClick={handleExportTranscriptClick}
          onExportUrlsClick={handleExportUrlsClick}
        />
      ),
      loading: <ShareExportingBody />,
      success: null,
      error: null,
    };
  }, [
    buttonsDisabled,
    handleExportAudio,
    handleExportDoc,
    handleExportPdf,
    handleExportSrt,
    handleExportTranscriptClick,
    handleExportUrlsClick,
    handleExportVideo,
    handleExportVtt,
    onExportAudiogram,
    params?.projectMediaType,
  ]);

  return (
    <ExportModalContents exportFormat={exportFormat} status={status}>
      {exportStatusViews[status]}
    </ExportModalContents>
  );
};

export default ShareProjectModalContents;
