import { useToggle } from '@sparemin/blockhead';
import { useCallback, useEffect } from 'react';
import { useEventTracking } from 'state/eventTracking';
import { useModalsActions } from 'state/modal';
import useDownloadPromoPack from '../state/useDownloadPromoPack';

export interface UsePromoPopperResult {
  showPopper: boolean;
  isLoading: boolean;
  toggleShowPopper: () => void;
  onOpenPromoPackModal: () => void;
  onDismissPromoPopper: () => void;
}

export function usePromoPopper(): UsePromoPopperResult {
  const { trackPromoPackPopperShown, trackPromoPackPopperClicked } =
    useEventTracking();
  const { isLoading } = useDownloadPromoPack();
  const { pushModal } = useModalsActions();

  const { value: showPopper, toggle: toggleShowPopper } = useToggle(false);

  const handleToggleShowPopper = useCallback((): void => {
    toggleShowPopper();
  }, [toggleShowPopper]);

  const handleOpenPromoPackModal = useCallback((): void => {
    trackPromoPackPopperClicked({ buttonClick: 'Download' });

    pushModal({
      name: 'PromoPack',
    });
    handleToggleShowPopper();
  }, [handleToggleShowPopper, pushModal, trackPromoPackPopperClicked]);

  const handleDismissPromoPopper = useCallback((): void => {
    trackPromoPackPopperClicked({ buttonClick: 'Dismiss' });
    handleToggleShowPopper();
  }, [handleToggleShowPopper, trackPromoPackPopperClicked]);

  useEffect(() => {
    if (showPopper) {
      trackPromoPackPopperShown();
    }
  }, [showPopper, trackPromoPackPopperShown]);

  return {
    showPopper,
    isLoading,
    toggleShowPopper: handleToggleShowPopper,
    onOpenPromoPackModal: handleOpenPromoPackModal,
    onDismissPromoPopper: handleDismissPromoPopper,
  };
}
