import ConnectedModal from 'components/ConnectedModal';
import { useModalParams } from 'state/modal';
import ExportURLsModalContents from './ExportURLsModalContents';

export interface ExportURLsModalProps {}

const ExportURLsModal: React.FC<ExportURLsModalProps> = () => {
  const params = useModalParams('ExportURLs');

  return (
    <ConnectedModal name="ExportURLs">
      <ExportURLsModalContents params={params} />
    </ConnectedModal>
  );
};
export default ExportURLsModal;
