import { Button, Heading, Spacer, HeadySparkle } from '@sparemin/blockhead';
import React, { useCallback, useState } from 'react';
import notifyError from 'components/notification';
import AIPodcastSearchModal from '../AIPodcastSearchModal';
import AIOptionsList from './AIOptionsList';
import * as S from './style';
import useUpdateAIOptions from './useUpdateAIOptions';

export interface AIOptOutProps {}

const AIOptOut: React.FC<AIOptOutProps> = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { isLoading, onSubmit, ...aiOptionsListProps } = useUpdateAIOptions();

  const handleSubmit = useCallback(async () => {
    try {
      setIsSubmitting(true);

      await onSubmit();
    } catch {
      notifyError();
    } finally {
      setIsSubmitting(false);
    }
  }, [onSubmit]);

  return (
    <S.Container>
      <S.Content>
        <HeadySparkle width={151} height={118} />

        <Spacer orientation="vertical" align="center">
          <Heading level={1}>How can Headliner AI help?</Heading>
          <S.Description>
            We can automatically do the following work for you:
          </S.Description>
        </Spacer>

        <AIOptionsList isLoading={isLoading} {...aiOptionsListProps} />

        <Button
          fluid
          onPress={handleSubmit}
          disabled={isLoading || isSubmitting}
        >
          Continue with these settings
        </Button>
      </S.Content>
      <AIPodcastSearchModal />
    </S.Container>
  );
};

export default AIOptOut;
