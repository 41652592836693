import { Search } from '@sparemin/blockhead';
import React, { useCallback } from 'react';
import { useModalsActions } from 'state/modal';
import { useMyDefaultPodcast } from 'state/podcast';
import * as S from './styles';

export interface AIOptionsDefaultPodcastProps {
  isDisabled?: boolean;
}

const AIOptionsDefaultPodcast: React.FC<AIOptionsDefaultPodcastProps> = ({
  isDisabled,
}) => {
  const { pushModal } = useModalsActions();
  const { data: podcast } = useMyDefaultPodcast();

  const handleSearchPress = useCallback(() => {
    pushModal({ name: 'AIPodcastSearch' });
  }, [pushModal]);

  return (
    <S.DefaultPodcastContainer $isDisabled={isDisabled}>
      <S.Thumbnail
        alt="podcast artwork"
        basis="width"
        ratio={1}
        src={podcast?.thumbnailUrl}
      />
      <S.PodcastTitle>{podcast?.title}</S.PodcastTitle>
      <S.SearchButton disabled={isDisabled} onPress={handleSearchPress}>
        <Search />
      </S.SearchButton>
    </S.DefaultPodcastContainer>
  );
};

export default AIOptionsDefaultPodcast;
