import { Button, TextField } from '@sparemin/blockhead';
import React, { useCallback, useState } from 'react';

import Modal from 'components/Modal';
import useIsMobileViewport from 'hooks/useIsMobileViewport';
import { ChapterInfoModalConfig } from 'state/modal/modals';

import { useChapter } from '../state';
import ChapterArt from './ChapterArt';
import * as S from './styles';
import useChapterInfoModal from './useChapterInfoModal';

interface ChapterInfoModalContentsProps {
  params?: ChapterInfoModalConfig['params'];
}

const ChapterInfoModalContents: React.FunctionComponent<
  ChapterInfoModalContentsProps
> = ({ params }) => {
  const { chapterId, newChapterStartWordId } = params || {};
  const isMobile = useIsMobileViewport();

  const { isSaving, onSave, onDelete } = useChapterInfoModal({
    chapterId,
    newChapterStartWordId,
  });
  const { data: chapter } = useChapter(chapterId);

  const {
    title: chapterTitle = '',
    url: chapterUrl = '',
    imageUrl: chapterImageUrl = '',
  } = chapter || {};

  const [title, setTitle] = useState(chapterTitle);
  const [url, setUrl] = useState(chapterUrl);
  const [imageUrl, setImageUrl] = useState(chapterImageUrl);

  const handleDeleteChapterArt = useCallback((): void => {
    setImageUrl('');
  }, []);

  return (
    <S.Root
      icon={
        <ChapterArt
          imageUrl={imageUrl}
          isDisabled={isSaving}
          onChapterArtUploaded={setImageUrl}
          onDeleteChapterArt={handleDeleteChapterArt}
        />
      }
      fullScreen={isMobile}
      title="Edit chapter information"
      subtitle="This can be displayed in podcast apps that support chapters."
    >
      <Modal.Body>
        <S.Contents>
          <TextField
            fluid
            label="Chapter title"
            placeholder="Add a title"
            isDisabled={isSaving}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />

          <TextField
            fluid
            label="Chapter link (optional)"
            placeholder="Paste a relevant link"
            isDisabled={isSaving}
            value={url}
            onChange={(e) => setUrl(e.target.value)}
          />

          <Button
            fluid
            isDisabled={!title || isSaving}
            onPress={() =>
              onSave({
                title,
                url,
                imageUrl,
              })
            }
          >
            save changes
          </Button>

          <S.DeleteButton
            isDisabled={!chapterId || isSaving}
            onPress={onDelete}
          >
            delete chapter
          </S.DeleteButton>
        </S.Contents>
      </Modal.Body>
    </S.Root>
  );
};

export default ChapterInfoModalContents;
