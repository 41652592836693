import { request } from 'api/http';
import { PODCAST_SERVICE_V1_FAVORITES_URL } from './constants';
import { FavoritePodcastsResponse } from './types';

export async function getFavoritePodcasts() {
  const { data } = await request.get<FavoritePodcastsResponse>(
    PODCAST_SERVICE_V1_FAVORITES_URL,
  );

  return data;
}
