import { breakpointDown, Button, Spacer, Text } from '@sparemin/blockhead';
import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import { fade, TransitionProps } from 'styles';
import ProjectLoaderMessage from '../../ProjectLoaderMessage';
import ProjectLoaderProgress from '../../ProjectLoaderProgress';
import { TRANSITION_DURATION_MILLIS } from '../../transition';

const fadeStyle = css<TransitionProps>`
  ${fade({ durationMillis: TRANSITION_DURATION_MILLIS })}
`;

export const Root = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
`;

export const Progress = styled(Spacer).attrs({
  align: 'center',
  orientation: 'horizontal',
  space: '30px',
})`
  ${fadeStyle};

  text-align: center;

  ${breakpointDown('md')`
    width: 100%;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;

    & > :not(:first-child) {
      align-items: center;
      margin: 0;
    }
  `}
`;

export const ProgressRing = styled(motion(ProjectLoaderProgress))`
  width: 100px;

  ${breakpointDown('md')`
    width: inherit;
    height: inherit;
  `}
`;

export const ProgressMessage = styled(motion(ProjectLoaderMessage))`
  position: relative;
`;

export const PromoteMessage = styled(motion(Text)).attrs({
  variant: 'p',
})`
  position: absolute;
  left: 48px;
  margin-left: 32px;
  padding-right: 20px;
  opacity: 0;
  text-align: start;
`;

export const CTA = styled(Spacer).attrs({
  align: 'center',
  orientation: 'vertical',
  space: '20px',
})`
  ${fadeStyle};

  ${breakpointDown('md')`
    width: 100%;
  `};
`;

export const GoToProjectButton = styled(Button).attrs({
  color: 'primary',
})`
  ${breakpointDown('md')`
    width: 100%;
  `};
`;
