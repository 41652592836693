import { useCallback, useEffect, useRef } from 'react';
import { createHeadlinerVideoWizardRedirect } from 'api';
import { useAuth } from 'state/auth';
import { AudioSelection } from 'types';
import { formatDuration } from 'utils/time';
import useExportVideo from './useExportVideo';

export interface UseExportVideoToHeadlinerConfig {
  projectName?: string;
  startMillis?: number;
  endMillis?: number;
}

export interface ExportVideoToHeadlinerArgs {
  projectCuid: string;
  selection: AudioSelection;
  isClip: boolean;
  onSuccess?: (redirectUrl: string) => void;
}

export default function useExportVideoToHeadliner({
  projectName,
  startMillis = 0,
  endMillis = 0,
}: UseExportVideoToHeadlinerConfig) {
  const { accessToken } = useAuth();
  const argsRef = useRef<ExportVideoToHeadlinerArgs>();
  const successRef = useRef(false);
  const { data: videoExport, exportVideo, status } = useExportVideo();

  const formattedStartMillis = formatDuration(startMillis);
  const formattedEndMillist = formatDuration(endMillis);
  const clipTitle = `${projectName}, Clip (${formattedStartMillis} - ${formattedEndMillist})`;

  useEffect(() => {
    if (
      videoExport?.videoUrl &&
      argsRef.current &&
      accessToken &&
      !successRef.current
    ) {
      const { projectCuid, selection, isClip } = argsRef.current;

      const redirectUrl = createHeadlinerVideoWizardRedirect({
        accessToken,
        projectCuid,
        clipTitle,
        videoUrl: videoExport.videoUrl,
        endWordId: selection.endWordId,
        startWordId: selection.startWordId,
        isClip,
      });

      argsRef.current.onSuccess?.(redirectUrl);
      successRef.current = true;
    }
  }, [accessToken, clipTitle, videoExport?.videoUrl]);

  const exportVideoToHeadliner = useCallback(
    (args: ExportVideoToHeadlinerArgs) => {
      const { projectCuid, selection } = args;
      argsRef.current = args;
      successRef.current = false;

      exportVideo({ projectCuid, selection });
    },
    [exportVideo],
  );

  return { exportVideoToHeadliner, status };
}
