import React, { useCallback } from 'react';
import MediaUploader from 'components/MediaUploader';
import {
  ComputerUploadButton,
  GoogleDriveUploadButton,
} from 'components/MediaUploader/UploadButton';
import Modal from 'components/Modal/Modal';
import { ENABLE_GOOGLE_DRIVE_INTEGRATOR } from 'config';
import {
  INTRO_OUTRO_MAX_LENGTH_SECS,
  INTRO_OUTRO_MAX_SIZE_MB,
} from 'pages/TranscriptEditorPage/constants';
import { useProjectMediaType } from 'pages/TranscriptEditorPage/state';
import { useModalParams, useModalsActions } from 'state/modal';
import { secToMin } from 'utils/time';
import * as S from './styles';

interface AddIntroOutroModalContentsProps {
  dropzoneClassName?: string;
}

const AddIntroOutroModalContents: React.FunctionComponent<
  AddIntroOutroModalContentsProps
> = (props) => {
  const { dropzoneClassName } = props;
  const {
    onAddEdgeAssetFromIntegrator,
    onAddEdgeAsset,
    variant = 'intro',
  } = useModalParams('AddIntroOutro') ?? {};

  const isIntro = variant === 'intro';
  const maxLength = secToMin(INTRO_OUTRO_MAX_LENGTH_SECS);
  const lengthType = maxLength === 1 ? 'min' : 'mins';
  const { data: projectType } = useProjectMediaType();

  const { hideModal, pushModal } = useModalsActions();
  const handleGoogleDriveButtonPress = useCallback(() => {
    hideModal();
    pushModal({
      name: 'IntroOutroMediaUpload',
      params: { variant, onAddEdgeAssetFromIntegrator },
    });
  }, [hideModal, pushModal, variant, onAddEdgeAssetFromIntegrator]);

  return (
    <Modal
      icon={isIntro ? <S.IntroIcon /> : <S.OutroIcon />}
      style={{ width: '570px' }}
      title={`Add an ${variant}`}
      subtitle={`Upload ${
        projectType === 'audio' ? 'an' : 'a'
      } ${projectType} file up to ${INTRO_OUTRO_MAX_SIZE_MB}MB and ${maxLength} ${lengthType} long.`}
    >
      <Modal.Body>
        <S.IntroOutroUploader
          className={dropzoneClassName}
          onDropAccepted={onAddEdgeAsset}
          variant={variant}
        >
          <MediaUploader.Contents>
            <ComputerUploadButton />
            {ENABLE_GOOGLE_DRIVE_INTEGRATOR && (
              <GoogleDriveUploadButton onPress={handleGoogleDriveButtonPress} />
            )}
          </MediaUploader.Contents>
        </S.IntroOutroUploader>
      </Modal.Body>
    </Modal>
  );
};

export default AddIntroOutroModalContents;
