import { userQueryKey } from '../queryKeyUtils';

export const userQueryKeys = {
  all: [{ scope: 'user' }] as const,
  profiles: () => [{ ...userQueryKeys.all[0], entity: 'profile' }] as const,
  profile: (userId?: number) =>
    [{ ...userQueryKeys.profiles()[0], ...userQueryKey(userId) }] as const,
  users: () => [{ ...userQueryKeys.all[0], entity: 'user' }] as const,
  user: (userId?: number) =>
    [{ ...userQueryKeys.users()[0], ...userQueryKey(userId) }] as const,
  integrators: () => [{ ...userQueryKeys.all[0], entity: 'integrators' }],
  userIntegrators: (userId?: number) => [
    { ...userQueryKeys.integrators()[0], ...userQueryKey(userId) },
  ],
};
