export const ERRORS = {
  ER001: 'Error uploading file',
  ER002: 'Invalid file type',
  ER003: 'Error opening project',
  ER004: 'Error updating project title',
  ER005: 'Error exporting',
  ER006: 'Error correcting text',
  ER007: 'Error deleting text',
  ER008: 'Error saving speaker',
  ER009: 'Error undeleting text',
  ER010: 'Upload length exceeded',
  ER011: 'Upload size exceeded',
  ER012: 'Error updating chapter',
  ER013: 'Error deleting chapter',
} as const;
