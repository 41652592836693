import React from 'react';
import introOutroGradient from 'assets/intro-outro.png';
import introOutroGradient2x from 'assets/intro-outro@2x.png';
import introOutroGradient3x from 'assets/intro-outro@3x.png';
import BaseUpsellModal from '../BaseUpsellModal';
import BaseUpsellModalFooter from '../BaseUpsellModalFooter';
import { BaseUpsellModalProps } from '../types';

interface IntroAndOutroContentsProps extends BaseUpsellModalProps {}

const IntroAndOutroContents: React.FC<IntroAndOutroContentsProps> = () => {
  return (
    <BaseUpsellModal
      title="Add intros and outros"
      subtitle="Add an intro to hook viewers before your main content begins and then drive ideal outcomes by adding a call to action in your outro."
      icon={
        <img
          src={introOutroGradient}
          srcSet={`${introOutroGradient} 1x, ${introOutroGradient2x} 2x, ${introOutroGradient3x} 3x`}
          alt=""
        />
      }
      footer={
        <BaseUpsellModalFooter
          modalName="AddIntroOutroInEddy"
          cta="unlock this feature"
        />
      }
    />
  );
};

export default IntroAndOutroContents;
