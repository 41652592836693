import { useMemo } from 'react';
import { TimeInterval } from 'types';
import { millisToSec } from 'utils/time';
import { usePlaybackState } from '../hooks';
import { useProjectAssetUrl } from '../state';
import * as S from './styles';
import useConstrainClip, { UseConstrainClipConfig } from './useConstrainClip';
import usePeaks from './usePeaks';
import useWaveSurfer, { UseWaveSurferConfig } from './useWaveSurfer';
import useWaveSurferPlayback from './useWaveSurferPlayback';
import useWaveSurferRegion from './useWaveSurferRegion';

export interface AudioSelectionAdjusterProps
  extends Pick<UseWaveSurferConfig, 'surroundingSelections'>,
    Pick<UseConstrainClipConfig, 'maxInterval'> {
  onSelectionChangeEnd?: (startMillis: number, endMillis: number) => void;
  onPlaybackChange?: (isPlaying: boolean) => void;
  onSeeked?: (currentTimeMillis: number) => void;
  onTimeupdate?: (currentTimeMillis: number) => void;
  waveform?: number[];
  playing?: boolean;
  selectionInterval: TimeInterval;
  waveInterval: TimeInterval;
}

const AudioSelectionAdjuster: React.FC<AudioSelectionAdjusterProps> = ({
  playing,
  maxInterval,
  onPlaybackChange,
  onSeeked,
  onSelectionChangeEnd,
  onTimeupdate,
  selectionInterval,
  surroundingSelections,
  waveform,
  waveInterval,
}) => {
  const { startMillis, endMillis } = selectionInterval;
  const { startMillis: waveStartMillis, endMillis: waveEndMillis } =
    waveInterval;

  const { duration } = usePlaybackState();
  const startSec = millisToSec(startMillis);
  const endSec = millisToSec(endMillis);

  const { data: assetUrl } = useProjectAssetUrl();

  const waveStartSec = millisToSec(waveStartMillis);
  const waveEndSec = millisToSec(waveEndMillis);

  const defaultPeaks = useMemo(
    () => new Array(Math.ceil(waveEndSec - waveStartSec)).fill(0.03),
    [waveEndSec, waveStartSec],
  );

  const peaks = waveform || defaultPeaks;

  const { wavesurfer } = useWaveSurfer({
    assetUrl,
    audioDuration: duration,
    container: '#audio-selection-adjuster',
    defaultClipEndSec: endSec,
    defaultClipStartSec: startSec,
    defaultPeaks: peaks,
    surroundingSelections,
    timelineContainer: '#audio-selection-adjuster-timeline',
    waveEndSec: waveEndSec,
    waveStartSec: waveStartSec,
  });

  useWaveSurferPlayback({
    onPlaybackChange,
    onSeeked,
    onTimeupdate,
    playing,
    waveEndSec,
    waveStartSec,
    wavesurfer,
    audioDurationSec: duration,
    clipEndSec: endSec,
    clipStartSec: startSec,
  });

  useWaveSurferRegion({
    wavesurfer,
    onChangeEnd: onSelectionChangeEnd,
  });

  usePeaks({ wavesurfer, peaks });

  useConstrainClip({ maxInterval, wavesurfer });

  return (
    <div>
      <S.Timeline id="audio-selection-adjuster-timeline" />
      <div id="audio-selection-adjuster" />
    </div>
  );
};

export default AudioSelectionAdjuster;
