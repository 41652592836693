import React from 'react';
import ConnectedModal from 'components/ConnectedModal';
import AddIntroOutroModalContents from './AddIntroOutroModalContents/AddIntroOutroModalContents';

const MODAL_NAME = 'AddIntroOutro';

export interface AddIntroOutroModalProps {}

const AddIntroOutroModal: React.FC<AddIntroOutroModalProps> = () => {
  return (
    <ConnectedModal name={MODAL_NAME}>
      <AddIntroOutroModalContents />
    </ConnectedModal>
  );
};

export default AddIntroOutroModal;
