import { useMutation } from 'react-query';
import { PromoPackType, getPromoPackDownload } from 'api';
import { downloadBlob } from 'utils/download';
import { useProjectName } from './useEditorProject';
import { useTranscriptId } from './useEditorTranscript';

export interface DownloadPromoPackArgs {
  promoPackType?: PromoPackType;
}

export const DEFAULT_PROMO_PACK_FILE_PREFIX = 'HeadlinerPromoPack_';
export const DEFAULT_PROMO_PACK_FILE_EXTENSION = '.zip';

export default function useDownloadPromoPack() {
  const { data: transcriptId } = useTranscriptId();
  const { data: projectName } = useProjectName();

  return useMutation(async (args: DownloadPromoPackArgs): Promise<void> => {
    const { promoPackType = 'main' } = args;

    const { file, fileName } = await getPromoPackDownload({
      transcriptId,
      promoPackType,
    });

    downloadBlob(
      fileName ??
        `${DEFAULT_PROMO_PACK_FILE_PREFIX}${projectName}${DEFAULT_PROMO_PACK_FILE_EXTENSION}`,
      file,
    );
  });
}
