import { useMutation } from 'react-query';
import { deleteEdgeAsset, EdgeAssetType } from 'api';
import { useProjectCuid } from './useEditorProject';

type UseDeleteEdgeAssetConfig = {
  edgeAssetType: EdgeAssetType;
};

export default function useDeleteEdgeAsset() {
  const { data: projectCuid } = useProjectCuid();

  return useMutation<void, unknown, UseDeleteEdgeAssetConfig, unknown>({
    mutationFn: ({ edgeAssetType }) => {
      if (!projectCuid) {
        throw new Error('Error deleting asset');
      }

      return deleteEdgeAsset(projectCuid, edgeAssetType);
    },
  });
}
