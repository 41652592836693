import { useContext } from 'react';
import SearchActionMenuContext from './SearchActionMenuContext';

export default function useSearchActionMenu() {
  const context = useContext(SearchActionMenuContext);

  if (context === undefined) {
    throw new Error(
      'useSearchActionMenu must be used within SearchActionMenuProvider',
    );
  }

  return context;
}
