import { last } from 'lodash-es';
import { ModalNames } from './modals';
import { ModalsStore } from './types';

export const actionsSelector = (s: ModalsStore) => s.actions;

export const modalsStackSelector = (s: ModalsStore) => s.modalsStack;

export const hasModalSelector = (s: ModalsStore) => !!s.modalsStack.length;

export const isModalOpenSelector = (s: ModalsStore, name: ModalNames) => {
  const top = last(modalsStackSelector(s));
  return top?.name === name && top?.show;
};
