import { useClipSuggestion, useWordIds, useWordsById } from '../../../../state';
import { useClipSuggestionContext } from '../ClipSuggestionContext';

export default function useWordsInSuggestion() {
  const { suggestionId } = useClipSuggestionContext();
  const { data: suggestion } = useClipSuggestion(suggestionId);
  const { data: wordsById } = useWordsById();
  const { data: wordIds } = useWordIds();

  const startWord = wordsById?.[suggestion?.startWordId ?? -1];
  const endWord = wordsById?.[suggestion?.endWordId ?? -1];

  const startWordIndex = startWord?.index;
  const endWordIndex = endWord?.index;

  return startWordIndex === undefined || endWordIndex === undefined || !wordIds
    ? []
    : wordIds.slice(startWordIndex, endWordIndex + 1);
}
