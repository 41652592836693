import { SEARCH_PUNCTUATION_TO_IGNORE } from '../SearchBar/constants';

/**
 * Normalize words in the transcript to be used while searching.
 * It removes the unecessary punctuation, transforms the word to lower
 * case and removes the whitespace.
 */
export function normalizeTranscriptWord(word: string): string {
  if (!word) {
    return '';
  }

  return word
    .replace(SEARCH_PUNCTUATION_TO_IGNORE, '')
    .toLocaleLowerCase()
    .trim();
}

export function normalizeQuery(query: string): string {
  if (!query) {
    return '';
  }

  return query.toLocaleLowerCase().trim();
}
