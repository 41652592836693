import { Draft, produce } from 'immer';

import {
  ProjectTranscript,
  TranscriptEdit,
  TranscriptUpdateTextEdit,
  TranscriptWord,
} from 'api';
import { wordDictionarySelector, WordsDictionary } from '../../selectors';

function getDraftWord(
  transcript: Draft<ProjectTranscript>,
  edit: TranscriptEdit,
  wordsById: WordsDictionary | undefined = {},
) {
  const word = wordsById[edit.wordId];

  if (word) {
    const segment = transcript.segments.find((s) => s.id === word.segmentId);
    return segment?.words.find((w) => w.id === word.id);
  }

  for (let i = 0; i < transcript.segments.length; i += 1) {
    for (let j = 0; j < transcript.segments[i].words.length; j += 1) {
      const draftWord = transcript.segments[i].words[j];
      if (draftWord.id === edit.wordId) {
        return draftWord;
      }
    }
  }
}

function deleteWord(word: Draft<TranscriptWord>) {
  word.isDeleted = true;
}

function updateWord(
  word: Draft<TranscriptWord>,
  edit: TranscriptUpdateTextEdit,
) {
  const { text, startMillis, endMillis } = edit.actionDetail;

  if (text !== undefined) {
    word.text = text;
    word.isSpace = text === '';
    word.isUpdated = true;
  }

  if (startMillis !== undefined) {
    word.startMillis = startMillis;
    word.isUpdated = true;
  }

  if (endMillis !== undefined) {
    word.endMillis = endMillis;
    word.isUpdated = true;
  }
}

function undeleteWord(word: Draft<TranscriptWord>) {
  word.isDeleted = false;
}

export default function optimisticUpdate(
  transcript: ProjectTranscript,
  edits: TranscriptEdit[],
) {
  // NB: it's important to pass the original `transcript` to the wordDictionarySelector
  // because it's memoized.  If the "draft" transcript is passed into wordDictionarySelector,
  // the selector will see this as a different reference than the underlying `transcript`,
  // causing the function to re-run. Furthermore, subsequent calls to wordsDictionarySelector
  // using the original underlying transcript will then cause the function to run yet again
  const wordsById = wordDictionarySelector(transcript);

  return produce(transcript, (draft) => {
    edits.forEach((edit) => {
      const word = getDraftWord(draft, edit, wordsById);

      if (word) {
        switch (edit.action) {
          case 'delete':
            deleteWord(word);
            break;

          case 'undelete':
            undeleteWord(word);
            break;

          case 'update':
            updateWord(word, edit);
            break;

          default: {
            if (process.env.NODE_ENV === 'development') {
              console.warn(
                `No optimistic update defined for transcript edit action ${edit.action}`,
              );
            }
          }
        }
      }
    });
  });
}
