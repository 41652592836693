import { useEffect, useRef } from 'react';

import { useAudioPlayer } from '../../AudioPlayerContext';
import { useTimeupdate } from '../../hooks';
import { EditorUiStore, useEditorUiSubscription } from '../../state/editorUi';
import useSetActiveWordByTime from './useSetActiveWordByTime';

/**
 * Highlights the word that corresponds to the current playback time
 */
export default function useSyncTextWithAudio() {
  const { player } = useAudioPlayer();
  const editorUiState = useRef<EditorUiStore>();

  useEditorUiSubscription((state) => {
    editorUiState.current = state;
  });

  const setActiveWordByTime = useSetActiveWordByTime();

  // handle setting the active word during playback
  useTimeupdate({
    onTimeupdate: setActiveWordByTime,
  });

  // handle setting the active word during seeking
  useEffect(() => {
    const handleSeeked = () => {
      if (!editorUiState.current?.wordSyncDisabled) {
        setActiveWordByTime(player.currentTime);
      }
    };

    player.on('seeked', handleSeeked);

    return () => {
      player.off('seeked', handleSeeked);
    };
  }, [player, setActiveWordByTime]);
}
