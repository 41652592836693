import {
  getThemeColor,
  getThemeTypography,
  IconButton,
} from '@sparemin/blockhead';
import styled from 'styled-components';

export const Button = styled(IconButton)`
  ${getThemeTypography('button')}

  color: ${getThemeColor('actionTertiary')};
  height: unset;
  padding: 12px;
  width: max-content;

  &[data-hovered='true']:not([disabled]) {
    color: ${getThemeColor('actionPrimary')};
    transition: none;
  }

  &:active:not([disabled]) {
    box-shadow: none;
    color: ${getThemeColor('actionPrimary', 'dark')};
    transition: none;
  }
`;

export const ActionButtonIcon = styled.svg.attrs({
  // blockhead icons are exported with height and width set to 1em.  In order to size
  // these icons properly on all browsers, we need to handle it with CSS.  For
  // the CSS to work, we need to remove the height and width properties
  height: undefined,
  width: undefined,
})`
  height: 16px;
  width: auto;
`;
