import { AlertCircle, Spacer, getThemeColor } from '@sparemin/blockhead';
import styled from 'styled-components';
import BaseModal from 'components/Modal';

export const Root = styled(BaseModal)`
  width: 570px;
`;

export const AlertIcon = styled(AlertCircle)`
  color: ${getThemeColor('danger')};
`;

export const Contents = styled(Spacer).attrs({
  orientation: 'vertical',
  align: 'center',
  space: '16px',
})`
  width: 100%;
  margin-top: 30px;
  padding: 0 50px;
`;
