import { ProjectTranscript, TranscriptEdit } from 'api';
import notifyError from 'components/notification';
import TranscriptOriginator from '../TranscriptOriginator';
import { CommandOptions, CommandAction, Committer } from '../types';
import { createWordsDeletionEdits } from '../utils';
import AsyncUndoableWordCommand, {
  WordCommitterArgs,
} from './AsyncUndoableWordCommand';

export default class DeleteWordsCommand extends AsyncUndoableWordCommand {
  constructor(
    private deletionWordIds: number[],
    originator: TranscriptOriginator,
    committer: Committer<WordCommitterArgs>,
    opts?: CommandOptions,
  ) {
    super(originator, committer, opts);
  }

  protected onError(action: CommandAction): void {
    if (action === 'commit') {
      notifyError({
        heading: 'Error deleting words',
        errorCode: 'ER007',
      });
    } else {
      notifyError({
        heading: 'Error restoring deleted word',
      });
    }
  }

  protected createEdits(
    transcript: ProjectTranscript | undefined,
  ): TranscriptEdit[] {
    if (!transcript) {
      return [];
    }

    return createWordsDeletionEdits(transcript, this.deletionWordIds);
  }
}
