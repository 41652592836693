import { useEffect, useState } from 'react';
import { useIsFreeTier } from 'state/pricing';
import { useEditorLoadingStatus } from '../state/useEditorLoadingStatus';
import { getViewId } from './utils';

export interface UseProjectLoaderStateConfig {
  aiFeaturesApplied: boolean;
  isLoadingComplete: boolean;
  onLoadingComplete?: () => void;
}

export default function useProjectLoaderState(
  config: UseProjectLoaderStateConfig,
) {
  const { aiFeaturesApplied, isLoadingComplete, onLoadingComplete } = config;

  const { data: isFreeTier } = useIsFreeTier();
  const editorStatus = useEditorLoadingStatus();

  const [initialIsFreeTier, setInitialIsFreeTier] = useState<boolean>();
  const [showProgress, setShowProgress] = useState(false);

  // the user sees a particular loader based on their plan.  "freezing" the initial
  // value for isFreeTier prevents the loading screen from changing if the user
  // purchases pro
  if (isFreeTier !== undefined && initialIsFreeTier === undefined) {
    setInitialIsFreeTier(isFreeTier);
  }

  // the user should only see the "loader" if the project is transcribing, otherwise
  // they should just be let through to the project
  if (editorStatus === 'processing' && !showProgress) {
    setShowProgress(true);
  }

  // this hook will call onLoadingComplete when a project that has already been
  // transcribed is loaded.  in that case, the user will never see the free/paid
  // tier of the loading screen and so handleLoadingComplete will never be called
  // by those components
  useEffect(() => {
    if (editorStatus === 'completed' && !showProgress) {
      onLoadingComplete?.();
    }
  }, [editorStatus, onLoadingComplete, showProgress]);

  return {
    isFreeTier: initialIsFreeTier,
    showProgress,
    viewId: getViewId(
      initialIsFreeTier,
      showProgress,
      aiFeaturesApplied,
      isLoadingComplete,
    ),
  };
}
