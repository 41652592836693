import { getThemeColor } from '@sparemin/blockhead';
import { transparentize } from 'color2k';
import styled, { css } from 'styled-components';
import BaseIntroOutro from './IntroOutro';
export const Container = styled.div`
  align-items: flex-start;
  background-color: ${getThemeColor('white')};
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  padding: 70px 80px;
  width: 100%;
`;

export const selectableTranscriptText = css`
  &::selection {
    background-color: ${({ theme }) =>
      transparentize(theme.palette.actionPrimary.main, 0.75)};
  }
`;

export const ProjectTitleContainer = styled.div`
  width: 100%;
  margin-bottom: 40px;
`;

export const IntroOutro = styled(BaseIntroOutro)`
  display: flex;
  justify-content: center;
  border-radius: 8px;
  padding-top: 0px;
  padding-bottom: 0px;
`;
