import { Download, PulseLoader } from '@sparemin/blockhead';
import { useCallback } from 'react';
import { PromoPackContent } from 'api';
import { useHexColorPaletteList } from 'pages/TranscriptEditorPage/state/useGetColorPalette';
import { usePromoPackAsset } from 'pages/TranscriptEditorPage/state/useGetPromoPackContents';
import { useEventTracking } from 'state/eventTracking';
import { getAspectRatioNameFromDimensions } from 'utils/aspectRatio';
import { downloadLink } from 'utils/download';
import BaseTabPanel from '../BaseTabPanel';
import useDownloadPromoPackSpecificAsset from '../useDownloadPromoPackSpecificAsset';
import * as S from './styles';
import { getPromoPackArtStyle } from './utils';

export interface EpisodeArtTabPanelProps {}

const EpisodeArtTabPanel: React.FC<EpisodeArtTabPanelProps> = () => {
  const { downloadAsset, isLoading: isDownloadingPromoPack } =
    useDownloadPromoPackSpecificAsset();
  const { trackPromoPackModalAction } = useEventTracking();

  const { data: episodeArt } = usePromoPackAsset('episodeArt');
  const { isLoading: isColorPaletteListLoading } = useHexColorPaletteList();

  const createHandleDownloadSingleImage = (content: PromoPackContent) => () => {
    const { imageFileName, imageUrl, imageType } = content;

    if (!imageFileName || !imageUrl || !imageType) {
      return;
    }

    trackPromoPackModalAction(
      'DownloadSpecificAsset',
      'episodeArt',
      getPromoPackArtStyle(imageType),
    );

    downloadLink(imageFileName, imageUrl);
  };

  const handleDownloadPromoPack = useCallback((): void => {
    trackPromoPackModalAction('DownloadSpecificAsset', 'episodeArt', 'all');
    downloadAsset('episodeArt');
  }, [downloadAsset, trackPromoPackModalAction]);

  if (isColorPaletteListLoading) {
    return (
      <S.LoaderContainer>
        <PulseLoader />
      </S.LoaderContainer>
    );
  }

  return (
    <BaseTabPanel
      assetType="episodeArt"
      actionButtons={[
        {
          key: 'download',
          label: 'Download all images',
          startIcon: <Download />,
          isDisabled:
            isDownloadingPromoPack || episodeArt?.status !== 'completed',
          onPress: handleDownloadPromoPack,
        },
      ]}
    >
      <S.ImagesContainer>
        {episodeArt?.contents?.map((content) => {
          const { imageUrl, imageWidth = 0, imageHeight = 0 } = content;

          const aspectRatio = imageWidth / imageHeight;
          const aspectRatioName = getAspectRatioNameFromDimensions(
            imageWidth,
            imageHeight,
          );

          return (
            <S.ImageButton
              key={imageUrl}
              $imageUrl={imageUrl}
              $aspectRatio={aspectRatioName}
              onPress={createHandleDownloadSingleImage(content)}
            >
              <S.ImageContainer
                $basis={aspectRatio < 1 ? 'height' : 'width'}
                ratio={aspectRatio}
              />

              <S.DownloadIconOverlay>
                <Download />
              </S.DownloadIconOverlay>
            </S.ImageButton>
          );
        })}
      </S.ImagesContainer>
    </BaseTabPanel>
  );
};

export default EpisodeArtTabPanel;
