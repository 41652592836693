import { DefaultTheme } from 'styled-components';
import { ProjectTranscriptChapter, ProjectTranscriptSegment } from 'api';
import {
  CHAPTER_TITLE_FONT_SIZE_PX,
  CHAPTER_TITLE_LINE_HEIGHT,
  CHAPTER_TITLE_MARGIN_BOTTOM_PX,
} from 'pages/TranscriptEditorPage/ChapterTitle';
import { range } from 'utils/math';
import {
  SPEAKER_SEGMENT_SPACING_MULTIPLIER,
  SPEAKER_SEGMENT_TOP_SPACING_MULTIPLIER,
} from '../../SpeakerSegment';
import { WORD_FONT_SIZE_PX, WORD_LINE_HEIGHT } from '../../Word';

const LIST_WIDTH = 690;

export function estimateChaptersInSegmentHeight(
  chapters: ProjectTranscriptChapter[],
  containerWidth: number,
  segmentSpacingTop: number,
) {
  if (!chapters.length) {
    return 0;
  }

  // Calculated similarly to estimateSegmentHeight, but considering
  // an avarage of 15 words per line.
  const wordsPerLine = range(0, LIST_WIDTH, 0, containerWidth, 15);
  const lineHeight = CHAPTER_TITLE_FONT_SIZE_PX * CHAPTER_TITLE_LINE_HEIGHT;
  const chapterTitles = chapters.map((chapter) => chapter.title);
  const lines = chapterTitles.join().split(' ').length / wordsPerLine;
  const textHeight = lines * lineHeight;
  const chaptersSpacing = segmentSpacingTop + CHAPTER_TITLE_MARGIN_BOTTOM_PX;

  return textHeight + chaptersSpacing * chapters.length;
}

export function estimateSegmentHeight(
  segment: ProjectTranscriptSegment,
  chaptersInSegment: ProjectTranscriptChapter[],
  containerWidth: number,
  theme: DefaultTheme,
) {
  // wordsPerLine were counted and averaged over a few different lines at
  // a list width of 690, yielding 19.2 words per line.  Use that as the base
  // and scale accordingly based on the current value of listWidth
  const wordsPerLine = range(0, LIST_WIDTH, 0, containerWidth, 19.2);
  const lineHeight = WORD_FONT_SIZE_PX * WORD_LINE_HEIGHT;
  const textMarginTop = theme.spacing.base * SPEAKER_SEGMENT_SPACING_MULTIPLIER;

  // this is the height of the speaker badge/button.  no great way of measuring it.
  // might want to consider settting it on the badge for consistency, even though
  // it's not required for styling
  const speakerHeight = 22;
  const segmentSpacingTop =
    theme.spacing.base * SPEAKER_SEGMENT_TOP_SPACING_MULTIPLIER;

  const chaptersInSegmentHeight = estimateChaptersInSegmentHeight(
    chaptersInSegment,
    containerWidth,
    segmentSpacingTop,
  );
  const lines = segment.words.length / wordsPerLine;
  const textHeight = lines * lineHeight + textMarginTop;

  return (
    textHeight + speakerHeight + segmentSpacingTop + chaptersInSegmentHeight
  );
}
