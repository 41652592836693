import { ProjectTranscript, TranscriptCreateSegmentRequest } from 'api';
import { placeholderSegmentIds } from '../..';
import AsyncUndoableTranscriptCommand from '../AsyncUndoableTranscriptCommand';
import TranscriptOriginator from '../TranscriptOriginator';
import { CommandOptions } from '../types';
import { createSegment, deleteSegment } from './optimisticUpdate';
import { CreateSegmentCommitter, DeleteSegmentCommitter } from './types';

type CreateSegmentCommandArgs = Pick<
  TranscriptCreateSegmentRequest,
  'newSegmentStartWordId'
>;

/**
 * An undoable command to create a segment.
 *
 * As a creation command, this command has to manage both a placeholder id for the
 * new segment, created as a result of the optimistic update, and the actual id
 * of the segment as returned from the API upon creation.
 *
 * In order to cause the least disruption to the application logic (using segment ids,
 * re-renders, etc.) the placeholder segment id is used for the entire session (until
 * the page is reloaded).  Anytime an API request is made that needs the actual
 * transcript id, it is read from a map containing the placeholder/actual id pairs.
 */
export default class CreateSegmentCommand extends AsyncUndoableTranscriptCommand<number> {
  private localSegmentId: number | undefined;

  private remoteSegmentId: number | undefined;

  constructor(
    private args: CreateSegmentCommandArgs,
    originator: TranscriptOriginator,
    private createSegmentCommitter: CreateSegmentCommitter,
    private deleteSegmentCommitter: DeleteSegmentCommitter,
    opts?: CommandOptions<number>,
  ) {
    super(originator, opts);
  }

  protected executeTranscript(
    transcript: ProjectTranscript,
  ): [ProjectTranscript, number] | undefined {
    const [newTranscript, newSegmentId] = createSegment(
      transcript,
      this.args.newSegmentStartWordId,
    );

    // store the temporary segment id in order to undo before operation is committed
    this.localSegmentId = newSegmentId;

    return [newTranscript, newSegmentId];
  }

  protected unexecuteTranscript(
    transcript: ProjectTranscript,
  ): ProjectTranscript | undefined {
    if (this.localSegmentId) {
      const [newTranscript] = deleteSegment(transcript, this.localSegmentId);
      return newTranscript;
    }

    return undefined;
  }

  public async commit(): Promise<void> {
    try {
      // committer returns a new segment id from the API. from this point forward,
      // if we want to undo the creation, we have to use this segment id for deletion
      const newSegmentId = await this.createSegmentCommitter({
        newSegmentStartWordId: this.args.newSegmentStartWordId,
      });

      if (this.localSegmentId) {
        placeholderSegmentIds.setPlaceholderId(
          this.localSegmentId,
          newSegmentId,
        );
      }

      // replace the temporary segment id saved in `executeTranscript`
      this.remoteSegmentId = newSegmentId;
    } catch (err) {
      this.onError('commit');
      throw err;
    }
  }

  public async uncommit(): Promise<void> {
    if (this.remoteSegmentId) {
      try {
        await this.deleteSegmentCommitter({
          segmentId: this.remoteSegmentId,
        });
      } catch (err) {
        this.onError('uncommit');
        throw err;
      }
    }
  }
}
