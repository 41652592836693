import { useCallback, useState } from 'react';
import { useAudioPlayer } from 'pages/TranscriptEditorPage/AudioPlayerContext';
import { SEEK_EPSILON_MILLIS } from 'pages/TranscriptEditorPage/constants';
import { millisToSec, secToMillis } from 'utils/time';
import { usePlaybackState, useTimeupdate } from '../../hooks';
import * as S from './styles';
import useEditedIntervals from './useEditedIntervals';
import useSeek from './useSeek';

export interface ProgressBarProps {}

const ProgressBar: React.FC<ProgressBarProps> = () => {
  const { player } = useAudioPlayer();
  const [currentTime, setCurrentTime] = useState(player.currentTime);
  const { duration: originalDuration, ready } = usePlaybackState();

  const originalDurationMillis = secToMillis(originalDuration);
  const currentTimeMillis = secToMillis(currentTime);

  const { deletedMillis, originalToEditedTime } = useEditedIntervals();

  const handleTimeupdate = useCallback(
    (nextCurrentTime: number) => {
      const adjustment = originalToEditedTime(
        // add a small value to the nextCurrentTime to "look ahead" for deletions.
        //  The idea is that if we find a deleted segment that would be in the very
        // next interval, skip it now to prevent any of the deleted audio from
        // leaking into playback
        secToMillis(nextCurrentTime + player.sampleRate / 2),
      );

      // if interval is deleted, skip to the end of it
      if (adjustment.interval.isDeleted) {
        const adjustedCurrentTime = millisToSec(adjustment.interval.endMillis);

        // NB: some browsers seem to have different implementations for audio
        // playback and when the different calls fire.  It's important to note
        // that this timeupdate function is called by `useTimeudpate` (to set the
        // time during playback) and `useSeek` to set the time after seeking as
        // seeking does not fire timeupate.
        //
        // If, in this function, we seek to time 10s, a 'seeked' event will fire
        // and the `useSeek` hook will then call its `onTimeupdate` callback.
        // what happens next varies by browser:
        //  - Chrome will wind up seeking to 10.XXXs.  During playback, it's rarely
        //    the case that the "seeked" event fires with the player at the same
        //    timestamp it was seeked to
        //  - Firefox will seek to 10s, the exact same time that the `currentTime`
        //    was set to. This can create an infinite loop - this function keeps
        //    seeking to a time which then causes the `useSeek` hook to seek to
        //    the same exact time, etc. etc.
        //
        // If you think this is confusing and want to change this logic, make sure
        // to test:
        //   - seeking (both during playback and while paused)
        //   - playback, especially around segments that were deleted
        //   - seeking by clicking on words and by clicking the progress bar
        //   - scrubbing
        //
        // Look out for the progress bar's playhead briefly rendering in the
        // incorrect location before adjusting itself
        if (adjustedCurrentTime !== player.currentTime) {
          // "adjustment" regions are end-to-end.  If we are seeking to skip over
          // a deleted region, add a small epsilon value to the seek time so that
          // the time we seek to doesn't land on the boundary between a deleted
          // and undeleted region
          player.seek(adjustedCurrentTime + millisToSec(SEEK_EPSILON_MILLIS));
        }
      } else {
        // if interval isn't deleted, adjust it so that it maps to the edited
        // progress bar
        setCurrentTime(millisToSec(adjustment.adjustedMillis));
      }
    },
    [originalToEditedTime, player],
  );

  useTimeupdate({ onTimeupdate: handleTimeupdate });

  const { sliderProps, seekMillis } = useSeek({
    onTimeupdate: handleTimeupdate,
  });

  const durationMillis = originalDurationMillis - deletedMillis;

  return (
    <S.Progress
      {...sliderProps}
      aria-label="audio progress"
      isDisabled={!ready}
      minValue={0}
      maxValue={isFinite(durationMillis) ? durationMillis : 0}
      value={seekMillis ?? currentTimeMillis}
      output={({ value, ...outputProps }) => (
        <S.PlaybackTime
          {...outputProps}
          {...{ currentTimeMillis, durationMillis }}
        />
      )}
    />
  );
};

export default ProgressBar;
