import { TranscriptWord } from 'api';
import { TranscriptWordWithIndex, WordsDictionary } from '../../state';
import {
  ContextMenuAction,
  ContextMenuConfig,
  isWordsContextMenuConfig,
} from '../../state/contextMenu';

function doesWordBorderUndeletedWord(
  word: TranscriptWordWithIndex | undefined,
  wordList: TranscriptWord[] | undefined,
): boolean {
  if (!word || !wordList) {
    return false;
  }

  const prevWord = wordList[word.index - 1];
  const nextWord = wordList[word.index + 1];

  return (prevWord && !prevWord.isDeleted) || (nextWord && !nextWord.isDeleted);
}

export function getContextMenuActions(
  config: ContextMenuConfig | undefined,
  wordsById: WordsDictionary | undefined,
  wordList: TranscriptWord[] | undefined,
): ContextMenuAction[] {
  if (!isWordsContextMenuConfig(config)) {
    return [];
  }

  const actions: ContextMenuAction[] = [];

  const { deleted, undeleted } = config.words.reduce(
    (acc, id) => {
      const word = wordsById?.[id];

      if (word?.isDeleted) {
        return { ...acc, deleted: acc.deleted + 1 };
      } else {
        return { ...acc, undeleted: acc.undeleted + 1 };
      }
    },
    { deleted: 0, undeleted: 0 },
  );

  const hasDeletedWord = deleted > 0;
  const hasUndeletedWord = undeleted > 0;

  if (
    config.words.length === 1 &&
    hasDeletedWord &&
    doesWordBorderUndeletedWord(wordsById?.[config.words[0]], wordList)
  ) {
    actions.push('adjust-selection');
  }

  if (hasDeletedWord) {
    actions.push('undelete-word');
  }

  if (hasUndeletedWord) {
    actions.push('delete-word');
  }

  // need 2+ words for an audiogram
  if (undeleted >= 2) {
    actions.push('headliner', 'download');
  }

  return actions;
}
