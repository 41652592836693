import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { paths } from 'components/App';
import { useAuth } from 'state/auth';
import { getRedirectPath } from './utils';

export interface UnauthenticatedRouteProps {
  fallbackRedirectTo?: string;
}

/**
 * If user is not authenticated, render <Outlet />, otherwise redirect the user
 * to the path specified in the query string
 */
const UnauthenticatedRoute: React.FC<UnauthenticatedRouteProps> = ({
  fallbackRedirectTo = paths.dashboard.value,
}) => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();

  if (isAuthenticated) {
    return (
      <Navigate
        to={getRedirectPath(location.search, fallbackRedirectTo)}
        replace
      />
    );
  }

  return <Outlet />;
};

export default UnauthenticatedRoute;
