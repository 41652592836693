import { AsyncUndoableCommand } from './types';

export default class IdentifiableCommand {
  private static nextId = 0;

  public readonly id: number;

  constructor(public readonly command: AsyncUndoableCommand) {
    this.id = IdentifiableCommand.nextId;
    IdentifiableCommand.nextId += 1;
  }
}
