import { useQuery } from 'react-query';
import { SpeakersResult, getSpeakers } from 'api';
import { QueryOptions } from 'types';
import { eddySpeakersQueryKeys } from './queryKeys';
import { speakersNamesListSelector } from './selectors';

type QueryKey = ReturnType<typeof eddySpeakersQueryKeys.speakers>;

export interface UseGetSpeakersOptions<TData = SpeakersResult>
  extends QueryOptions<SpeakersResult, TData, QueryKey> {}

export default function useGetSpeakers<TData = SpeakersResult>(
  opts?: UseGetSpeakersOptions<TData>,
) {
  return useQuery(eddySpeakersQueryKeys.speakers(), getSpeakers, opts);
}

export const useGetSpeakersNamesList = () =>
  useGetSpeakers({
    select: speakersNamesListSelector,
  });
