import React, { useRef } from 'react';
import { Transition } from 'react-transition-group';
import useIsMobileViewport from 'hooks/useIsMobileViewport';
import { TOTAL_LOADER_TRANSITION_TIME_MILLIS } from '../transition';
import EnjoyClipsMessage from './EnjoyClipsMessage';
import * as S from './styles';
import useProjectLoaderFreeTierAnimation from './useProjectLoaderFreeTierAnimation';

export interface ProjectLoaderFreeTierProps {
  className?: string;
  onLoadingComplete?: () => void;
}

const ProjectLoaderFreeTier = React.forwardRef<
  HTMLDivElement,
  ProjectLoaderFreeTierProps
>(({ className, onLoadingComplete }, ref) => {
  const loaderContainerRef = useRef<HTMLDivElement>(null);
  const discoRef = useRef<HTMLElement>(null);

  const { progressSection } = useProjectLoaderFreeTierAnimation();
  const isMobile = useIsMobileViewport();

  return (
    <S.Root className={className} ref={ref}>
      <Transition
        appear
        exit={false}
        in
        mountOnEnter
        timeout={TOTAL_LOADER_TRANSITION_TIME_MILLIS}
        unmountOnExit
        nodeRef={loaderContainerRef}
      >
        {(status) => (
          <S.ProgressSection
            {...progressSection}
            status={status}
            onLoadingComplete={onLoadingComplete}
          />
        )}
      </Transition>

      {isMobile && <EnjoyClipsMessage />}

      <Transition
        appear
        exit={false}
        in
        mountOnEnter
        timeout={TOTAL_LOADER_TRANSITION_TIME_MILLIS}
        unmountOnExit
        nodeRef={discoRef}
      >
        {(status) => <S.Disco ref={discoRef} status={status} />}
      </Transition>
    </S.Root>
  );
});

export default ProjectLoaderFreeTier;
