import { FileDropzoneContextType, Tooltip } from '@sparemin/blockhead';
import React, { useCallback } from 'react';

import notifyError from 'components/notification';
import useUploadBlobImage from 'state/image/useUploadBlobImage';
import { megabytesToBytes } from 'utils/math';
import {
  CHAPTER_ART_MAX_DIMENSION_PX,
  CHAPTER_ART_MAX_FILE_SIZE_MB,
  CHAPTER_ART_MIN_DIMENSION_PX,
  DROPZONE_ACCEPT,
} from './constants';
import * as S from './styles';

interface ChapterArtDropzoneProps {
  onChapterArtUploaded: (imageUrl: string) => void;
}

const ChapterArtDropzone: React.FunctionComponent<ChapterArtDropzoneProps> = ({
  onChapterArtUploaded,
}) => {
  const { mutateAsync: uploadChapterArt, isLoading: isUploadingChapterArt } =
    useUploadBlobImage();

  const triggerRejectedImageError = useCallback((): void => {
    notifyError({
      heading: 'Image not supported',
      subHeading: `We support up to ${CHAPTER_ART_MAX_FILE_SIZE_MB}MB JPEGs or PNGs in a square aspect ratio. Make sure the height and width dimensions are between ${CHAPTER_ART_MIN_DIMENSION_PX}px and ${CHAPTER_ART_MAX_DIMENSION_PX}px.`,
    });
  }, []);

  const handleUploadChapterArt = useCallback(
    async (images: File[]): Promise<void> => {
      const result = await uploadChapterArt({
        image: images[0],
        imageType: 'eddy',
      });

      if (!result?.url) {
        return notifyError();
      }

      onChapterArtUploaded(result?.url);
    },
    [onChapterArtUploaded, uploadChapterArt],
  );

  return (
    <S.Dropzone
      resetInputOnReselect
      multiple={false}
      accept={DROPZONE_ACCEPT}
      maxSize={megabytesToBytes(CHAPTER_ART_MAX_FILE_SIZE_MB)}
      minDimensions={{
        width: CHAPTER_ART_MIN_DIMENSION_PX,
        height: CHAPTER_ART_MIN_DIMENSION_PX,
      }}
      maxDimensions={{
        width: CHAPTER_ART_MAX_DIMENSION_PX,
        height: CHAPTER_ART_MAX_DIMENSION_PX,
      }}
      onDropAccepted={handleUploadChapterArt}
      onDropRejected={triggerRejectedImageError}
    >
      {({ openFileDialog }: FileDropzoneContextType) =>
        isUploadingChapterArt ? (
          <S.LoaderContainer>
            <S.Loader />
          </S.LoaderContainer>
        ) : (
          <Tooltip arrow contents="Add chapter art">
            <S.ChapterArtButton onPress={openFileDialog} />
          </Tooltip>
        )
      }
    </S.Dropzone>
  );
};

export default ChapterArtDropzone;
