import { PromoPopperItem } from '@sparemin/blockhead';

export const popperItems: PromoPopperItem[] = [
  {
    icon: '🎨',
    label: 'Episode art',
  },
  {
    icon: '💬',
    label: 'Show notes',
  },
  {
    icon: '🔑',
    label: 'Keywords',
  },
  {
    icon: '📣',
    label: 'Social captions',
  },
  {
    icon: '🗣️',
    label: 'Quotes + references',
  },
];
