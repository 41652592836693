import { Heading } from '@sparemin/blockhead';
import styled from 'styled-components';
import BaseConnectedModal from 'components/ConnectedModal';
import BaseModal from 'components/Modal';

export const ConnectedModal = styled(BaseConnectedModal)`
  padding: 24px;
  height: 100vh;
  overflow: hidden;
  width: 100vw;
`;

export const Modal = styled(BaseModal.Container).attrs({
  gutter: 24,
})`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Title = styled(Heading).attrs({
  level: 1,
})`
  margin-right: auto;
`;

export const Body = styled(BaseModal.Body)`
  flex-grow: 1;
  overflow: hidden;
`;
