import { AuthProvider } from '@sparemin/auth';
import { paths } from 'components/App';
import AuthLink from './AuthLink';

export interface SpareminAuthProviderProps {
  children?: React.ReactNode;
}

export const SpareminAuthProvider: React.FC<SpareminAuthProviderProps> = ({
  children,
}) => (
  <AuthProvider
    navigation={{
      routes: {
        login: paths.login.value,
        'sign-up': paths.registration.value,
      },
      linkComponent: AuthLink,
    }}
  >
    {children}
  </AuthProvider>
);

export default SpareminAuthProvider;
