import {
  getThemeColor,
  getThemeTypography,
  SEARCH_INPUT_CLASSNAME,
} from '@sparemin/blockhead';
import { transparentize } from 'color2k';
import { flowRight, partialRight } from 'lodash-es';
import styled from 'styled-components';
import ExplorerBar from 'components/ExplorerBar';
import { SUGGESTED_CLIPS_TRIGGER_WIDTH } from './SuggestedClipsTrigger/constants';

const transparentWhite = flowRight(
  partialRight(transparentize, 0.05),
  getThemeColor('white'),
);

export const Root = styled(ExplorerBar).attrs({
  gutter: '10px',
})`
  background-image: linear-gradient(
      to bottom,
      ${transparentWhite},
      ${transparentWhite}
    ),
    linear-gradient(94deg, #5dbefc, #dd92fe);

  // the component sits behind the trigger and expands when clicked.  the trigger
  // should cover it completely but since the trigger is absolutely positioned,
  // its width will not affect the root component.
  width: ${SUGGESTED_CLIPS_TRIGGER_WIDTH};
`;

export const SearchField = styled(ExplorerBar.SearchField)`
  background-color: transparent;
`;

export const Input = styled(ExplorerBar.Input)`
  flex-grow: 1;
  width: unset;

  && {
    margin-left: 50px;
  }

  .${SEARCH_INPUT_CLASSNAME} {
    ${getThemeTypography('h2')};
    font-weight: bold;
    width: 100%;
  }
`;

export const ResultsCount = styled(ExplorerBar.ResultsCount)`
  flex-shrink: 0;
`;
