import { MediaImportedEvent, MediaRejectedEvent } from '@sparemin/media-import';
import useHandleMediaAccepted from './useHandleMediaAccepted';
import useHandleMediaImported from './useHandleMediaImported';
import useHandleMediaRejected from './useHandleMediaRejected';

export interface UseHandleImportedMediaResult {
  isLoading: boolean;
  onMediaImported: (args: MediaImportedEvent) => void;
  onMediaAccepted: (args: MediaImportedEvent) => void;
  onMediaRejected: (args: MediaRejectedEvent) => void;
}

export default function useHandleImportedMedia(): UseHandleImportedMediaResult {
  const { isLoading: isMediaAcceptedLoading, onMediaAccepted } =
    useHandleMediaAccepted();
  const { isLoading: isMediaImportedLoading, onMediaImported } =
    useHandleMediaImported();
  const { onMediaRejected } = useHandleMediaRejected({ onMediaAccepted });

  return {
    isLoading: isMediaAcceptedLoading || isMediaImportedLoading,
    onMediaImported,
    onMediaAccepted,
    onMediaRejected,
  };
}
