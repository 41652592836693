import notifyError, { ErrorToastProps } from 'components/notification';
import { DEFAULT_ERROR_MESSAGE } from 'components/notification/constants';
import {
  INTRO_OUTRO_MAX_LENGTH_SECS,
  INTRO_OUTRO_MAX_SIZE_MB,
} from '../constants';
import {
  IntroOutroFileRejectionReason,
  NotifyFileRejectionErrorProps,
} from './types';

function getErrorByReason(reason: IntroOutroFileRejectionReason) {
  const reasons = {
    WRONG_TYPE: {
      heading: 'Unfortunately, the uploaded file is not a supported file type',
      subHeading:
        'Please make sure the file format is one of the following: MP3, AAC, WEBM, WAV or QuickTime. If you encounter further issues, please contact our support team for further assistance.',
      errorCode: 'ER002',
    },
    TOO_LARGE: {
      heading: `Upload size exceeds ${INTRO_OUTRO_MAX_SIZE_MB}MB`,
      errorCode: 'ER011',
    },

    TOO_LONG: {
      heading: `Upload length exceeds ${INTRO_OUTRO_MAX_LENGTH_SECS} seconds`,
      errorCode: 'ER010',
    },

    ERROR: {
      heading: DEFAULT_ERROR_MESSAGE,
    },
  };
  return reasons[reason] as ErrorToastProps;
}

export function notifyFileRejectionError({
  reason,
}: NotifyFileRejectionErrorProps): void {
  notifyError(getErrorByReason(reason));
}
