import { useCallback } from 'react';
import { search } from 'utils/arrays';
import { EditedInterval, TimeAdjustmentResult } from './types';
import { editedIntervalComparator } from './utils';

export interface UseEditedToOriginalTimeConfig {
  intervals: EditedInterval[];
}

export default function useEditedToOriginalTime({
  intervals,
}: UseEditedToOriginalTimeConfig) {
  return useCallback(
    (originalMillis: number): TimeAdjustmentResult => {
      const index = search(intervals, originalMillis, editedIntervalComparator);
      const interval = intervals[index];

      return {
        adjustedMillis: originalMillis + interval.adjustmentMillis,
        interval,
      };
    },
    [intervals],
  );
}
