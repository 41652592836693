import { GoogleDriveFile } from '@sparemin/api-sdk';
import React, { useCallback, useMemo, useState } from 'react';
import notifyError from 'components/notification';
import { useEdgeAssetId, useEdgeAssetStatus } from '../state';
import useAddEdgeAssetFromIntegrator from '../state/useAddEdgeAssetFromIntegrator';
import useAddEdgeAssetFromMedia from '../state/useAddEdgeAssetFromMedia';
import { usePollForEdgeAssetStatus } from '../state/useGetEdgeAssetStatus';
import { IntroOutroContext } from './IntroOutroContext';
import { IntroOutroProviderProps } from './types';

const IntroOutroProvider: React.FC<IntroOutroProviderProps> = ({
  children,
  variant,
}) => {
  const { data: edgeAssetId } = useEdgeAssetId(variant);
  const { data: status } = useEdgeAssetStatus(variant);

  // loading should start as soon as upload flow is initiated
  const [isAddingAsset, setIsAddingAsset] = useState(false);

  usePollForEdgeAssetStatus(edgeAssetId);

  const { mutateAsync: addAsset } = useAddEdgeAssetFromMedia({
    onError: () => {
      notifyError({ heading: 'Something went wrong. Try your upload again.' });
      // terminate loading if there is an error
      setIsAddingAsset(false);
    },
  });

  const { mutateAsync: addAssetFromIntegrator } = useAddEdgeAssetFromIntegrator(
    {
      onError: () => {
        notifyError({
          heading: 'Something went wrong. Try your upload again.',
        });
        // terminate loading if there is an error
        setIsAddingAsset(false);
      },
    },
  );

  const handleAddIntroOutro = useCallback(
    async (file: File) => {
      // start loading as soon as upload is initiated
      setIsAddingAsset(true);
      await addAsset({
        file,
        assetType: variant,
      });
    },
    [variant, addAsset],
  );

  const handleAddIntroOutroFromIntegrator = useCallback(
    async (
      file: GoogleDriveFile,
      googleDriveFileId: string,
      providerUserId: string,
    ) => {
      // start loading as soon as upload is initiated
      setIsAddingAsset(true);
      await addAssetFromIntegrator({
        asset: file,
        assetType: variant,
        providerUserId,
      });
    },
    [addAssetFromIntegrator, variant],
  );

  // terminate loading if it is in process and the asset status resolves to error
  // or completed
  if (isAddingAsset && (status === 'error' || status === 'completed')) {
    setIsAddingAsset(false);
  }

  return (
    <IntroOutroContext.Provider
      value={useMemo(
        () => ({
          isLoading: isAddingAsset,
          status,
          onAddIntroOutro: handleAddIntroOutro,
          onAddIntroOutroFromIntegrator: handleAddIntroOutroFromIntegrator,
          variant,
        }),
        [
          handleAddIntroOutro,
          handleAddIntroOutroFromIntegrator,
          isAddingAsset,
          status,
          variant,
        ],
      )}
    >
      {children}
    </IntroOutroContext.Provider>
  );
};

export default IntroOutroProvider;
