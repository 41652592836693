import WaveSurfer from 'wavesurfer.js';
import {
  PluginDefinition,
  PluginParams,
  WaveSurferPlugin,
} from 'wavesurfer.js/types/plugin';
import RegionResizeEventPlugin, {
  RegionResizeEventData,
} from '../RegionResizeEventPlugin';
import { getRegionHandleElements } from '../utils';

const PLUGIN_NAME = 'regionsegment';

/**
 * A wavesurfer plugin that keeps a region within the visible "window".
 */
export default class RegionSegmentPlugin implements WaveSurferPlugin {
  static create(params: PluginParams): PluginDefinition {
    return {
      params,
      deferInit: !!params.deferInit,
      name: PLUGIN_NAME,
      instance: RegionSegmentPlugin,
    };
  }

  private regionMaxLength = Infinity;

  constructor(private params: PluginParams, private wavesurfer: WaveSurfer) {}

  public init(): void {
    if (this.wavesurfer.isReady) {
      this.onReady();
    } else {
      this.wavesurfer.once('ready', this.onReady);
    }
  }

  public destroy(): void {
    this.removeEventListeners();
  }

  private setRegionMaxLength(val: number | undefined) {
    this.regionMaxLength = val === undefined ? Infinity : val;
  }

  private onReady = () => {
    this.addEventListeners();
  };

  private addEventListeners() {
    this.wavesurfer.on(
      RegionResizeEventPlugin.RESIZE_START_EVENT_NAME,
      this.onResizeStart,
    );
    this.wavesurfer.on(
      RegionResizeEventPlugin.RESIZE_END_EVENT_NAME,
      this.onResizeEnd,
    );
  }

  private removeEventListeners() {
    this.wavesurfer.un(
      RegionResizeEventPlugin.RESIZE_START_EVENT_NAME,
      this.onResizeStart,
    );
    this.wavesurfer.un(
      RegionResizeEventPlugin.RESIZE_END_EVENT_NAME,
      this.onResizeEnd,
    );
  }

  private onResizeStart = (data: RegionResizeEventData) => {
    const { handle, region } = data;
    const wsParams = this.wavesurfer.params;

    const { segmentStartSec, segmentEndSec } = this.getSegmentBoundsSec();

    const handleWidth = this.getHandleWidth(data);

    const regionDuration = region.end - region.start;
    const handleDuration =
      (handleWidth / wsParams.minPxPerSec) * wsParams.pixelRatio;

    if (handle === 'left') {
      const maxRegionDuration =
        regionDuration + (region.start - segmentStartSec) - handleDuration;

      if (!region.maxLength || maxRegionDuration < region.maxLength) {
        this.setRegionMaxLength(region.maxLength);
        region.update({ maxLength: maxRegionDuration });
      }
    } else {
      const maxRegionDuration =
        regionDuration + (segmentEndSec - region.end) - handleDuration;

      if (!region.maxLength || maxRegionDuration < region.maxLength) {
        this.setRegionMaxLength(region.maxLength);
        region.update({ maxLength: maxRegionDuration });
      }
    }
  };

  private getHandleWidth({
    region,
    handle: handleType,
  }: RegionResizeEventData) {
    const handles = getRegionHandleElements(region);
    const handle = handles[handleType];

    return handle.getBoundingClientRect().width;
  }

  private onResizeEnd = ({ region }: RegionResizeEventData) => {
    if (region.maxLength !== this.regionMaxLength) {
      region.update({ maxLength: this.regionMaxLength });
      this.setRegionMaxLength(undefined);
    }
  };

  private getSegmentBoundsSec() {
    const wsParams = this.wavesurfer.params;
    const drawer = this.wavesurfer.drawer;

    const widthSec = drawer.getWidth() / wsParams.minPxPerSec;

    const startSec =
      (drawer.wrapper.scrollLeft * wsParams.pixelRatio) / wsParams.minPxPerSec;
    const endSec = startSec + widthSec;

    return { segmentStartSec: startSec, segmentEndSec: endSec };
  }
}
