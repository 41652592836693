import Region from 'wavesurfer.js/src/plugin/regions';
import WaveSurfer from 'wavesurfer.js/src/wavesurfer';
import { PluginDefinition, WaveSurferPlugin } from 'wavesurfer.js/types/plugin';

import { getRegionHandleElements } from '../utils';
import { PLUGIN_NAME } from './constants';
import { RegionResizeEventPluginParams } from './types';

export default class RegionResizeEventPlugin implements WaveSurferPlugin {
  static RESIZE_START_EVENT_NAME = 'region-resize-start';

  static RESIZE_END_EVENT_NAME = 'region-resize-end';

  static create(params: RegionResizeEventPluginParams): PluginDefinition {
    return {
      params,
      deferInit: !!params.deferInit,
      name: PLUGIN_NAME,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      instance: RegionResizeEventPlugin,
    };
  }

  private activeResizeHandle: 'right' | 'left' | undefined;

  private activeRegion: Region | undefined;

  constructor(
    private params: RegionResizeEventPluginParams,
    private wavesurfer: WaveSurfer,
  ) {}

  init(): void {
    if (this.wavesurfer.isReady) {
      this.onReady();
    } else {
      this.wavesurfer.once('ready', this.onReady);
    }
  }

  destroy(): void {
    document.removeEventListener('mouseup', this.onMouseUp);
  }

  private onReady = () => {
    this.addResizeEventHandlers();
    this.addLifecycleEventHandlers();
  };

  private addResizeEventHandlers() {
    const regions = this.wavesurfer.regions.list;

    Object.keys(regions).forEach((key) => {
      const region = regions[key];
      this.addRegionResizeEventHandlers(region);
    });

    document.addEventListener('mouseup', this.onMouseUp);
  }

  private addLifecycleEventHandlers() {
    this.wavesurfer.on('region-created', this.addRegionResizeEventHandlers);
  }

  private addRegionResizeEventHandlers(region: Region) {
    const { left, right } = getRegionHandleElements(region);

    const leftHandler = this.createLeftHandleMouseDownHandler(region);
    const rightHandler = this.createRightHandleMouseDownHandler(region);

    left?.addEventListener('mousedown', leftHandler);
    right?.addEventListener('mousedown', rightHandler);

    region.once('remove', () => {
      left?.removeEventListener('mousedown', leftHandler);
      right?.removeEventListener('mousedown', rightHandler);
    });
  }

  private createLeftHandleMouseDownHandler = (region: Region) => () => {
    this.activeResizeHandle = 'left';
    this.activeRegion = region;
    this.fireResizeStartEvent();
  };

  private createRightHandleMouseDownHandler = (region: Region) => () => {
    this.activeResizeHandle = 'right';
    this.activeRegion = region;
    this.fireResizeStartEvent();
  };

  private onMouseUp = () => {
    if (this.activeRegion) {
      this.fireResizeEvent(RegionResizeEventPlugin.RESIZE_END_EVENT_NAME);
      this.activeResizeHandle = undefined;
      this.activeRegion = undefined;
    }
  };

  private fireResizeStartEvent() {
    this.fireResizeEvent(RegionResizeEventPlugin.RESIZE_START_EVENT_NAME);
  }

  private fireResizeEvent(eventName: string) {
    this.wavesurfer.fireEvent(eventName, {
      handle: this.activeResizeHandle,
      region: this.activeRegion,
    });
  }
}
