import { useEffect, useRef, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useIsClipSuggestionsEnabled } from 'state/displayPref';
import { useEventTracking } from 'state/eventTracking';
import { useEddyPrefsOverride } from 'state/userPref';
import { useSuggestionCount, useSuggestionsStatus } from '../state';
import { SOURCE_PARAM_NAME } from './constants';

export default function useTrackTranscriptPageLoaded() {
  const isTrackedRef = useRef(false);
  const { pathname } = useLocation();
  const [params] = useSearchParams();
  const [prevPathname, setPrevPathname] = useState(pathname);
  const { data: suggestedClipsCount = 0 } = useSuggestionCount();
  const { data: isClipSuggestionsEnabled } = useIsClipSuggestionsEnabled();
  const { data: suggestionsStatus, status: suggestionsQueryStatus } =
    useSuggestionsStatus();
  const { trackTranscriptPageLoaded } = useEventTracking();
  const { data: eddyPrefs } = useEddyPrefsOverride();

  if (pathname !== prevPathname) {
    setPrevPathname(pathname);
    isTrackedRef.current = false;
  }

  useEffect(() => {
    const isSuggestionsStatusResolved =
      suggestionsStatus === 'completed' || suggestionsStatus === 'error';

    if (
      eddyPrefs &&
      !isTrackedRef.current &&
      (isClipSuggestionsEnabled === false ||
        isSuggestionsStatusResolved ||
        suggestionsQueryStatus === 'error')
    ) {
      isTrackedRef.current = true;

      trackTranscriptPageLoaded({
        suggestedClipsCount,
        autoGenerateChapters: eddyPrefs?.isAutoChaptersEnabled,
        autoGenerateTitle: eddyPrefs?.isAutoProjectNameEnabled,
        autoRemoveFillerWords: eddyPrefs?.shouldRemoveFillerWords,
        autoPodcastPersonalization: eddyPrefs.isPodcastPersonalizationEnabled,
        source: params.get(SOURCE_PARAM_NAME),
      });
    }
  }, [
    eddyPrefs,
    isClipSuggestionsEnabled,
    params,
    suggestedClipsCount,
    suggestionsQueryStatus,
    suggestionsStatus,
    trackTranscriptPageLoaded,
  ]);
}
