import {
  ButtonBase,
  FileDropzone,
  PulseLoader,
  Spacer,
  getThemeColor,
} from '@sparemin/blockhead';
import styled from 'styled-components';
import imagePlaceholder from 'assets/image-placeholder.svg';
import BaseModal from 'components/Modal';
import {
  ChapterArtButtonStyleProps,
  ChapterArtContainerStyleProps,
} from './types';

const chapterArtSize = 100;

export const Root = styled(BaseModal)`
  width: ${({ fullScreen }) => (fullScreen ? '100%' : '570px')};
`;

export const ChapterArtContainer = styled.div<ChapterArtContainerStyleProps>`
  text-align: start;

  ${({ $isDisabled }) =>
    $isDisabled &&
    `
      opacity: 0.5;
      pointer-events: none;
    `}
`;

export const LoaderContainer = styled(Spacer).attrs({
  align: 'center',
  justify: 'center',
})`
  width: 100%;
  height: ${chapterArtSize}px;
`;

export const Loader = styled(PulseLoader)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ChapterArtButton = styled(ButtonBase)<ChapterArtButtonStyleProps>`
  width: ${chapterArtSize}px;
  height: ${chapterArtSize}px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  background-color: transparent;
  background-image: ${({ $imageUrl }) =>
    `url(${$imageUrl ?? imagePlaceholder})`};
  background-size: cover;

  ${({ $imageUrl }) => `
    &,
    &:active:not([disabled]) {
      box-shadow: ${$imageUrl ? '0 2px 10px 0 rgba(0, 0, 0, 0.15)' : 'none'};
    }
  `};

  & + div {
    width: auto;
  }

  &[data-hovered='true'] {
    & > div {
      opacity: 1;
    }
  }
`;

export const Dropzone = styled(FileDropzone)`
  width: ${chapterArtSize}px;
  height: ${chapterArtSize}px;
  display: contents;
`;

export const TrashIconOverlay = styled.div`
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  opacity: 0;
  color: ${getThemeColor('white')};
  background-color: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(6.4px);
  transition: ${({ theme }) => theme.transitions.create('opacity', '300ms')};
`;

export const Contents = styled(Spacer).attrs({
  orientation: 'vertical',
  align: 'center',
  space: '32px',
})`
  text-align: start;
`;

export const DeleteButton = styled(ButtonBase)`
  padding-top: 16px;
  background-color: transparent;
  color: ${getThemeColor('danger')};

  &:active:not([disabled]) {
    box-shadow: none;
  }
`;
