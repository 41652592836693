import { Spacer, Thumbnail } from '@sparemin/blockhead';
import React from 'react';
import { EdgeAssetType } from 'api';
import { useEdgeAssetThumbnail } from 'pages/TranscriptEditorPage/state';
import DeleteAssetButton from '../DeleteAssetButton';
import * as S from './styles';

interface IntroOutroProps {
  variant: EdgeAssetType;
}

const IntroOutro: React.FC<IntroOutroProps> = ({ variant }) => {
  const icon = variant === 'intro' ? <S.IntroIcon /> : <S.OutroIcon />;
  const { data: thumbnail } = useEdgeAssetThumbnail(variant);
  const imageAspectRatio = thumbnail ? thumbnail.width / thumbnail.height : 1;
  return (
    <S.Container>
      <Spacer space="15px" align="center">
        {thumbnail?.imageUrl ? (
          <S.VideoThumbnailContainer>
            <Thumbnail
              src={thumbnail.imageUrl}
              alt=""
              ratio={imageAspectRatio}
              basis={imageAspectRatio < 1 ? 'height' : 'width'}
            />
          </S.VideoThumbnailContainer>
        ) : (
          icon
        )}
        <S.Text>{variant}</S.Text>
      </Spacer>

      <DeleteAssetButton variant={variant} />
    </S.Container>
  );
};
export default IntroOutro;
