import React from 'react';
import ConnectedModal from 'components/ConnectedModal';
import MediaUploadModalContents from './MediaUploadModalContents';

export interface MediaUploadModalProps {}

const MediaUploadModal: React.FC<MediaUploadModalProps> = () => (
  <ConnectedModal name="ProjectMediaUpload">
    <MediaUploadModalContents />
  </ConnectedModal>
);

export default MediaUploadModal;
