import { useCallback, useEffect, useRef, useState } from 'react';
import { MutateOptions, QueryStatus, useMutation } from 'react-query';
import {
  ExportVideo,
  VideoExport,
  ExportVideoArgs as ApiExportVideoArgs,
  exportVideo,
} from 'api';
import notifyError from 'components/notification';
import { queryStatusResolver } from 'state/utils';
import { downloadLink } from 'utils/download';
import useGetVideoExport from './useGetVideoExport';

type ErrorHandler = () => void;
type SuccessHandler = (data: VideoExport) => void;

type ReactQueryMutateOptions = MutateOptions<
  ExportVideo,
  unknown,
  ApiExportVideoArgs,
  unknown
>;
interface ExportVideoOptions
  extends Omit<ReactQueryMutateOptions, 'onError' | 'onSuccess'> {
  onError?: ErrorHandler;
  onSuccess?: SuccessHandler;
}

interface ExportOnlyVideoArgs extends ApiExportVideoArgs {
  download?: false;
}

interface ExportAndDownloadVideoArgs extends ApiExportVideoArgs {
  download: true;
  projectName: string;
}

type ExportVideoArgs = ExportOnlyVideoArgs | ExportAndDownloadVideoArgs;

const useExportVideo = () => {
  const onErrorRef = useRef<ErrorHandler>();
  const onSuccessRef = useRef<SuccessHandler>();
  const projectNameRef = useRef<string>();

  const [videoExportId, setVideoExportId] = useState<number>(-1);
  const [downloadHasStarted, setDownloadHasStarted] = useState(false);

  const { status: initiateExportStatus, mutate } = useMutation<
    ExportVideo,
    unknown,
    ApiExportVideoArgs,
    unknown
  >(exportVideo);

  const { data: videoExport, status: exportStatus } =
    useGetVideoExport(videoExportId);

  useEffect(() => {
    if (videoExportId >= 0) {
      if (exportStatus === 'error' || videoExport?.status === 'error') {
        onErrorRef.current?.();

        setVideoExportId(-1);
      } else if (
        exportStatus === 'success' &&
        videoExport?.status === 'completed'
      ) {
        onSuccessRef.current?.(videoExport);
        setVideoExportId(-1);

        if (projectNameRef.current) {
          downloadLink(
            projectNameRef.current,
            videoExport.videoUrl,
            () => setDownloadHasStarted(true),
            videoExport.fileExtension,
          );
        }
      }
    }
  }, [videoExport, videoExportId, exportStatus]);

  const handleExportVideo = useCallback(
    (args: ExportVideoArgs, opts?: ExportVideoOptions) => {
      const { projectCuid, selection } = args;
      const { onError, onSuccess, ...restOpts } = opts ?? {};

      projectNameRef.current = undefined;
      onErrorRef.current = onError;
      onSuccessRef.current = onSuccess;

      if (args.download) {
        projectNameRef.current = args.projectName;
      }

      mutate(
        { projectCuid, selection },
        {
          ...restOpts,
          onError() {
            onError?.();
            notifyError({
              heading: 'Error exporting video',
              errorCode: { code: 'ER005', type: 'video' },
            });
          },
          onSuccess(data, variables, context) {
            setVideoExportId(data.videoExportId);
          },
        },
      );
    },
    [mutate],
  );

  const isPolling = exportStatus !== 'idle';
  const statusList = [
    isPolling ? 'loading' : null,
    exportStatus,
    initiateExportStatus,
  ].filter(Boolean) as QueryStatus[];

  const status = queryStatusResolver(statusList);

  return {
    data: videoExport,
    exportVideo: handleExportVideo,
    status: status === 'success' && !downloadHasStarted ? 'loading' : status,
  };
};

export default useExportVideo;
