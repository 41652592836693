import {
  RegistrationProvider,
  SignUpCard,
  UserAuthResult,
} from '@sparemin/auth';
import { useCallback, useEffect } from 'react';
import useAuthSuccess from 'hooks/useAuthSuccess';
import { useEventTracking } from 'state/eventTracking';

export interface RegistrationCardProps {}

const RegistrationCard: React.FC<RegistrationCardProps> = () => {
  const { onAuthSuccess } = useAuthSuccess();
  const { trackSignUpPageLoaded, trackSignUpError } = useEventTracking();

  useEffect(() => {
    trackSignUpPageLoaded();
  }, [trackSignUpPageLoaded]);

  return (
    <SignUpCard
      onAuthenticateSuccess={useCallback(
        async (_: RegistrationProvider, result: UserAuthResult) => {
          onAuthSuccess(result);
        },
        [onAuthSuccess],
      )}
      onError={trackSignUpError}
    />
  );
};

export default RegistrationCard;
