import * as S from './styles';

export interface PageProps {
  children?: React.ReactNode;
  className?: string;
}
const Page: React.FC<PageProps> = ({ children, className }) => (
  <S.Root className={className}>{children}</S.Root>
);

export default Page;
