import { PromoPackAssetType } from 'api';
import { TrackRegenerateAIOutputProps } from 'state/eventTracking';
import {
  formalityFeedbackOptions,
  lengthFeedbackOptions,
  toneFeedbackOptions,
} from '../StructuredAdjustmentsBar/constants';

export const MIN_ACTIONS_BAR_HEIGHT = 140;
export const TOP_BAR_TRANSITION_DURATION_SEC = 0.3;
export const TAB_PANEL_MAX_WIDTH_PX = 850;

export const DEFAULT_STRUCTURED_ADJUSTMENTS_TRACK_PROPS: Partial<TrackRegenerateAIOutputProps> =
  {
    length: lengthFeedbackOptions[0].value,
    formality: formalityFeedbackOptions[0].value,
    tone: toneFeedbackOptions[0].value,
    chapters: 'Not Set',
    freeformInput: '',
    aspectRatio: 'Not Set',
    style: 'Not Set',
    colors: 'Not Set',
  };

export const DEFAULT_CUSTOM_FEEDBACK_TRACK_PROPS: Partial<TrackRegenerateAIOutputProps> =
  {
    length: 'Not Set',
    formality: 'Not Set',
    tone: 'Not Set',
    chapters: 'Not Set',
    freeformInput: '',
    aspectRatio: 'Not Set',
    style: 'Not Set',
    colors: 'Not Set',
  };

export const DEFAULT_TRACK_PROPS_BY_ASSET_TYPE: Record<
  PromoPackAssetType,
  Partial<TrackRegenerateAIOutputProps>
> = {
  showNotes: {
    ...DEFAULT_STRUCTURED_ADJUSTMENTS_TRACK_PROPS,
    chapters: false,
  },
  episodeArt: DEFAULT_CUSTOM_FEEDBACK_TRACK_PROPS,
  keywords: DEFAULT_CUSTOM_FEEDBACK_TRACK_PROPS,
  socialCaptions: DEFAULT_STRUCTURED_ADJUSTMENTS_TRACK_PROPS,
  quotes: DEFAULT_CUSTOM_FEEDBACK_TRACK_PROPS,
  references: DEFAULT_CUSTOM_FEEDBACK_TRACK_PROPS,
  newsletter: DEFAULT_STRUCTURED_ADJUSTMENTS_TRACK_PROPS,
  blogPost: DEFAULT_STRUCTURED_ADJUSTMENTS_TRACK_PROPS,
};
