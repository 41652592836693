import {
  ButtonBase,
  getThemeColor,
  TextArea as BaseTextArea,
} from '@sparemin/blockhead';
import styled from 'styled-components';

export const TextArea = styled(BaseTextArea)`
  min-width: auto;

  & textarea {
    background-color: transparent;
    text-transform: none;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    padding: 0;
    letter-spacing: inherit;
    line-height: normal;
  }
`;

export const Button = styled(ButtonBase)`
  display: inline-block;
  cursor: text;
  text-transform: none;
  background-color: transparent;
  font-family: inherit;
  font-size: inherit;
  letter-spacing: inherit;
  line-height: normal;
  max-width: inherit;
  overflow: inherit;
  text-overflow: inherit;
  white-space: pre-wrap;
  word-break: break-word;
  text-align: left;
  color: ${getThemeColor('black')};

  &:active:not([disabled]) {
    box-shadow: none;
  }
`;
