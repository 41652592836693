import { produce } from 'immer';
import { useCallback, useRef, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import {
  Project,
  setProjectRelatedPodcast,
  SetProjectRelatedPodcastRequest,
} from 'api';
import { useAuth } from 'state/auth';
import { eddyProjectsQueryKeys } from './queryKeys';
import { useProjectCuid } from './useEditorProject';

export default function useSetProjectRelatedPodcast() {
  const queryClient = useQueryClient();
  const { userId } = useAuth();
  const { data: projectCuid } = useProjectCuid();
  const [prevProjectCuid, setPrevProjectCuid] = useState(projectCuid);

  const projectQueryKey = eddyProjectsQueryKeys.myProject(userId, projectCuid);

  const requestSentForProject = useRef(false);

  if (projectCuid !== prevProjectCuid) {
    setPrevProjectCuid(projectCuid);
    requestSentForProject.current = false;
  }

  return useMutation({
    mutationFn: useCallback(
      async (args?: SetProjectRelatedPodcastRequest) => {
        if (!projectCuid || requestSentForProject.current) {
          return;
        }

        const { podcastId } = args ?? {};
        const result = await setProjectRelatedPodcast({
          projectId: projectCuid,
          podcastId,
        });

        requestSentForProject.current = true;

        return result;
      },
      [projectCuid],
    ),
    onMutate: async () => {
      await queryClient.cancelQueries({
        queryKey: projectQueryKey,
      });

      const project: Project | undefined =
        queryClient.getQueryData(projectQueryKey);

      if (!project) {
        return;
      }

      queryClient.setQueryData(
        projectQueryKey,
        produce(project, (draft) => {
          draft.hasSubmittedRelatedPodcast = true;
        }),
      );

      return { project };
    },
    onSettled: () => {
      queryClient.invalidateQueries(projectQueryKey, { exact: true });
    },
  });
}
