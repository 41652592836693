import { breakpointDown } from '@sparemin/blockhead';
import SkeletonComponent from 'react-loading-skeleton';
import styled from 'styled-components';
import BasePage from 'components/Page';
import ProjectsList from './ProjectsList';

export const Page = styled(BasePage)`
  padding-top: ${({ theme }) =>
    theme.headerHeightDesktop + theme.spacing.base * 8}px;

  ${breakpointDown('md')`
    padding-top: ${({ theme }) => theme.headerHeightDesktop}px;
  `}
`;

export const Projects = styled(ProjectsList)`
  margin-top: ${({ theme }) => theme.spacing.base * 6}px;

  ${breakpointDown('md')`
    margin-top: ${({ theme }) => theme.spacing.base * 2}px;
  `}
`;

export const Skeleton = styled(SkeletonComponent)`
  margin-top: ${({ theme }) => theme.spacing.base * 6}px;

  ${breakpointDown('md')`
    margin-top: ${({ theme }) => theme.spacing.base * 2}px;
  `}
`;
