import { useQuery } from 'react-query';
import { request, Waveform } from 'api';
import { useAuth } from 'state/auth';
import { QueryOptions } from 'types';
import { eddyWaveformQueryKeys } from './queryKeys';

type QueryKey = ReturnType<typeof eddyWaveformQueryKeys.assetWaveform>;

export interface UseWaveformDataOptions<TData = Waveform>
  extends QueryOptions<Waveform, TData, QueryKey> {}

export default function useWaveformData<TData = Waveform>(
  waveformUrl?: string,
  opts?: UseWaveformDataOptions<TData>,
) {
  const { userId } = useAuth();

  return useQuery<Waveform, unknown, TData, QueryKey>(
    eddyWaveformQueryKeys.assetWaveform(userId, waveformUrl),
    async ({ queryKey: [{ url }] }) => {
      const data = await request.get<Waveform>(url as string);
      return data.data;
    },
    {
      ...opts,
      enabled: !!waveformUrl,
      staleTime: Infinity,
    },
  );
}
