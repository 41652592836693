import WaveSurfer from 'wavesurfer.js/src/wavesurfer';
import {
  PluginDefinition,
  PluginParams,
  WaveSurferPlugin,
} from 'wavesurfer.js/types/plugin';
import { PLUGIN_NAME } from './constants';

import styles from './CursorIndicatorPlugin.module.css';
import { createCSSVar } from './utils';

export interface CursorIndicatorPluginParams extends PluginParams {
  className?: string;
}

/**
 * This plugin renders an indicator (triangle) above the cursor. In wavsurfer
 * terms, the cursor is the line on the waveform that indicates the current
 * playback position of the audio.
 *
 * WaveSurfer is designed to render the waveformon a series of canvases, the total
 * length of which is much larger than the scroll container.  Because of this, many
 * of wavesurfer's elements have overflow hidden.
 *
 * The indicator effect is achieved by modifying the css to make room for the playhead.
 * The implementation tries to take other plugins into account - for example, regions.
 * The region side has to be modified so that the height does not "overflow" and clip
 * the indicator.
 *
 * Right now only the regions plugin is accounted for considering it's the only
 * one we use.
 */
export default class CursorIndicator implements WaveSurferPlugin {
  public static INDICATOR_HEIGHT = 12;

  public static INDICATOR_WIDTH = 18;

  public static CSS_VAR_NAMES = {
    backgroundColor: createCSSVar('bg-color'),
    indicatorHeight: createCSSVar('height'),
    indicatorWidth: createCSSVar('width'),
  };

  private originalBgColor: string | undefined;

  private originalHeight: number | undefined;

  constructor(
    private params: CursorIndicatorPluginParams,
    private wavesurfer: WaveSurfer,
  ) {}

  static create(params: PluginParams = {}): PluginDefinition {
    return {
      params,
      deferInit: !!params.deferInit,
      name: PLUGIN_NAME,
      instance: CursorIndicator,
    };
  }

  private setClassNames() {
    const progressWave = this.wavesurfer.drawer.progressWave;

    if (!progressWave) {
      throw new Error(
        'CursorIndicatorPlugin is only compatible with renderers that have a ' +
          'progressWave, such as MultiCanvas',
      );
    }

    // add the playhead
    progressWave.classList.add(styles.progress);

    // add user defined className if applicable
    if (this.params.className) {
      progressWave.classList.add(this.params.className);
    }

    this.wavesurfer.container.classList.add(styles.container);
    this.wavesurfer.drawer.wrapper.classList.add(styles.contents);
  }

  private unsetClassNames() {
    const progressWave = this.wavesurfer.drawer.progressWave;

    progressWave.classList.remove(styles.progress);

    if (this.params.className) {
      progressWave.classList.remove(this.params.className);
    }

    this.wavesurfer.container.classList.remove(styles.container);
    this.wavesurfer.drawer.wrapper.classList.remove(styles.contents);
  }

  private setCSSVars() {
    this.wavesurfer.container.style.setProperty(
      CursorIndicator.CSS_VAR_NAMES.backgroundColor,
      this.originalBgColor ?? 'inherit',
    );
    this.wavesurfer.container.style.setProperty(
      CursorIndicator.CSS_VAR_NAMES.indicatorHeight,
      `${CursorIndicator.INDICATOR_HEIGHT}px`,
    );
    this.wavesurfer.container.style.setProperty(
      CursorIndicator.CSS_VAR_NAMES.indicatorWidth,
      `${CursorIndicator.INDICATOR_WIDTH}px`,
    );
  }

  private unsetCSSVars() {
    this.wavesurfer.container.style.removeProperty(
      CursorIndicator.CSS_VAR_NAMES.backgroundColor,
    );
    this.wavesurfer.container.style.removeProperty(
      CursorIndicator.CSS_VAR_NAMES.indicatorHeight,
    );
    this.wavesurfer.container.style.removeProperty(
      CursorIndicator.CSS_VAR_NAMES.indicatorWidth,
    );
  }

  private onReady = () => {
    const progressWave = this.wavesurfer.drawer.progressWave;

    if (!progressWave) {
      throw new Error(
        'CursorIndicatorPlugin is only compatible with renderers that have a ' +
          'progressWave, such as MultiCanvas',
      );
    }

    // set height to account for the indicator
    this.originalHeight = this.wavesurfer.getHeight();
    this.wavesurfer.setHeight(
      this.originalHeight + CursorIndicator.INDICATOR_HEIGHT,
    );

    this.setClassNames();

    // bg color has to move to an overlay that we can control the size of
    this.originalBgColor = this.wavesurfer.getBackgroundColor();
    this.wavesurfer.setBackgroundColor('inherit');

    this.setCSSVars();
  };

  init(): void {
    if (this.wavesurfer.isReady) {
      this.onReady();
    } else {
      this.wavesurfer.once('waveform-ready', this.onReady);
    }
  }

  destroy(): void {
    this.unsetClassNames();
    this.unsetCSSVars();

    // restore original bg color
    if (this.originalBgColor) {
      this.wavesurfer.setBackgroundColor(this.originalBgColor);
    }
  }
}
