import { DialogProps } from '@sparemin/blockhead';
import React from 'react';

import {
  ModalNames,
  useModalIsInStack,
  useModalIsOpen,
  useModalsActions,
} from 'state/modal';

import ConnectedModalComponent from './ConnectedModalComponent';

interface ConnectedModalContainerProps extends React.PropsWithChildren {
  className?: string;
  destroyOnHide?: boolean;
  name: ModalNames;
  position?: DialogProps['position'];
}

const ConnectedModalContainer: React.FunctionComponent<
  ConnectedModalContainerProps
> = (props) => {
  const {
    children,
    className,
    destroyOnHide = true,
    name,
    position = 'start',
  } = props;

  const isInStack = useModalIsInStack(name);
  const show = useModalIsOpen(name);

  const { hideModal, popModal } = useModalsActions();

  return (
    <ConnectedModalComponent
      className={className}
      destroyOnHide={destroyOnHide}
      isInStack={isInStack}
      onExited={popModal}
      onHide={hideModal}
      position={position}
      show={show}
    >
      {children}
    </ConnectedModalComponent>
  );
};

export default ConnectedModalContainer;
