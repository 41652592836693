import React, { useEffect } from 'react';

import ExportModalContents, {
  ShareExportingBody,
} from 'components/ExportModalContents';
import { ShareAudioModalConfig } from 'state/modal/modals';
import useDownloadClip from '../hooks/useDownloadClip';

interface ShareAudioModalContentsProps {
  params?: ShareAudioModalConfig['params'];
}

const ShareAudioModalContents: React.FunctionComponent<
  ShareAudioModalContentsProps
> = (props) => {
  const { params } = props;
  const { downloadClip, status } = useDownloadClip();

  useEffect(() => {
    if (status === 'idle' && params) {
      const { words } = params;

      downloadClip(words[0], words[words.length - 1]);
    }
  }, [downloadClip, params, status]);

  return (
    <ExportModalContents exportFormat={params?.projectType} status={status}>
      {status !== 'success' && <ShareExportingBody />}
    </ExportModalContents>
  );
};

export default ShareAudioModalContents;
