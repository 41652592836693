export interface AudioPlayer {
  currentTime: number;
  duration: number;
  paused: boolean;
  ready: boolean;
  sampleRate: number;
  isDisabled: boolean;

  pause(): void;

  play(): Promise<void> | undefined;

  seek(seconds: number): void;

  togglePlay(): Promise<void>;

  enablePlayer(): void;

  disablePlayer(): void;

  off(type: 'timeupdate', listener: (currentTime: number) => void): void;
  off<T extends keyof HTMLMediaElementEventMap>(
    type: T,
    listener: (this: HTMLMediaElement, ev: HTMLMediaElementEventMap[T]) => void,
    opts?: boolean | AddEventListenerOptions,
  ): void;

  on(type: 'timeupdate', listener: (currentTime: number) => void): void;
  on<T extends keyof HTMLMediaElementEventMap>(
    type: T,
    listener: (this: HTMLMediaElement, ev: HTMLMediaElementEventMap[T]) => void,
    opts?: boolean | AddEventListenerOptions,
  ): void;
}

// https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/readyState
export enum AudioReadyState {
  HAVE_NOTING = 0,
  HAVE_METADATA = 1,
  HAVE_CURRENT_DATA = 2,
  HAVE_FUTURE_DATA = 3,
  HAVE_ENOUGH_DATA = 4,
}
