import { create } from 'zustand';
import {
  actionsSelector,
  aiNameAppliedProjectCuidSelector,
  isAIOptOutShownSelector,
} from './selectors';
import { AIOptionsState, AIOptionsStore } from './types';

const defaultState: AIOptionsState = {
  aiNameAppliedProjectCuid: '',
  aiOptOutShown: undefined,
};

const useAIOptionsStore = create<AIOptionsStore>()((set) => ({
  ...defaultState,
  actions: {
    bypassAIOptOut: () =>
      set((s) =>
        s.aiOptOutShown !== undefined
          ? s
          : {
              aiOptOutShown: false,
              isCheckingAIOptions: false,
            },
      ),
    checkAIOptions: () =>
      set({
        aiOptOutShown: true,
      }),
    clearAINameAppliedProjectCuid: () =>
      set({ aiNameAppliedProjectCuid: undefined }),
    reset: () => set(defaultState),
    setAINameAppliedProjectCuid: (projectCuid: string) =>
      set({ aiNameAppliedProjectCuid: projectCuid }),
  },
}));

export const useAIOptionsActions = () => useAIOptionsStore(actionsSelector);

export const useIsAIOptOutShown = () =>
  useAIOptionsStore(isAIOptOutShownSelector);

export const useGetAiNameAppliedProjectCuid = () =>
  useAIOptionsStore(aiNameAppliedProjectCuidSelector);
