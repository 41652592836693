import { Button, Copy, Text } from '@sparemin/blockhead';
import { useCallback } from 'react';
import { notifySuccess } from 'components/notification/showNotification';
import { CopyEddyProjectURLAsset, useEventTracking } from 'state/eventTracking';
import { copyToClipboard } from 'utils/clipboard';
import * as S from './styles';

export interface URLDisplayProps {
  label: string;
  url?: string;
  asset: CopyEddyProjectURLAsset;
}

const URLDisplay: React.FC<URLDisplayProps> = ({ label, url, asset }) => {
  const { trackCopyEddyProjectURL } = useEventTracking();

  const handleCopy = useCallback((): void => {
    if (!url) {
      return;
    }

    copyToClipboard(url);
    trackCopyEddyProjectURL(asset);
    notifySuccess({
      heading: 'Link copied to clipboard!',
      toastId: 'copy-eddy-project-url',
    });
  }, [asset, trackCopyEddyProjectURL, url]);

  return (
    <S.URLDisplay>
      <Text variant="label" weight="bold">
        {label}
      </Text>

      <S.URLPlate>
        <S.URL>{url}</S.URL>

        <S.CopyButtonContainer>
          <Button
            size="small"
            startIcon={<Copy width="11px" height="12px" />}
            isDisabled={!url}
            onPress={handleCopy}
          >
            Copy
          </Button>
        </S.CopyButtonContainer>
      </S.URLPlate>
    </S.URLDisplay>
  );
};

export default URLDisplay;
