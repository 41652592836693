import { useHelpButtonOverride } from 'state/displayPref';
import HelpMenuContents from './HelpMenuContents';
import { HelpMenuProps } from './types';

const HelpMenu: React.FC<HelpMenuProps> = (props) => {
  const { data: helpButtonEnabled } = useHelpButtonOverride();

  if (!helpButtonEnabled) {
    return null;
  }

  return <HelpMenuContents {...props} />;
};

export default HelpMenu;
