import React from 'react';
import Helmet, { FaviconHelmet } from 'components/Helmet';
import { useFaviconOverride, useTitleOverride } from 'state/displayPref';

export interface ThemedPageMetadataProps {}

const ThemedPageMetadata: React.FC<ThemedPageMetadataProps> = () => {
  const { data: title } = useTitleOverride();
  const { data: favicon } = useFaviconOverride();

  return (
    <>
      <Helmet>{title && <title>{title}</title>}</Helmet>
      {favicon && <FaviconHelmet url={favicon} />}
    </>
  );
};

export default ThemedPageMetadata;
