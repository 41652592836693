import {
  updateProjectTranscriptChapter,
  UpdateProjectTranscriptChapterArgs,
} from 'api/services';
import { placeholderChapterIds } from './placeholderIds';
import { useTranscriptId } from './useEditorTranscript';
import useUpdateTranscript from './useUpdateTranscript';

export type UseUpdateTranscriptChapterVariables = Pick<
  UpdateProjectTranscriptChapterArgs,
  'chapterId' | 'title' | 'url' | 'imageUrl'
>;

export default function useUpdateTranscriptChapter() {
  const { data: transcriptId } = useTranscriptId();

  return useUpdateTranscript<void, UseUpdateTranscriptChapterVariables>({
    mutationFn: ({ chapterId, title, url, imageUrl }) => {
      if (!transcriptId) {
        throw new Error('Error updating chapter');
      }

      const actualChapterId = placeholderChapterIds.getActualId(chapterId);

      return updateProjectTranscriptChapter({
        transcriptId,
        chapterId: actualChapterId,
        title,
        url,
        imageUrl,
      });
    },
  });
}
