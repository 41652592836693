import { usePlaybackState } from 'pages/TranscriptEditorPage/hooks';
import { useCanDeleteSegment } from 'pages/TranscriptEditorPage/state';

export type UseShowDeleteSpeakerButtonConfig = {
  segmentId?: number;
};

export default function useShowDeleteSpeakerButton({
  segmentId,
}: UseShowDeleteSpeakerButtonConfig) {
  const { paused } = usePlaybackState();
  const { data: canDeleteSegment } = useCanDeleteSegment(segmentId);

  return {
    isDeleteButtonVisible: canDeleteSegment && paused,
  };
}
