import { useCallback } from 'react';
import { Query, useQuery, useQueryClient } from 'react-query';

import { GetEdgeAssetStatusResult, getEdgeAssetStatus } from 'api/services';
import { DEFAULT_POLLING_INTERVAL_MILLIS } from 'config';
import { useAuth } from 'state/auth';
import { QueryOptions } from 'types';

import { eddyProjectsQueryKeys, edgeAssetQueryKeys } from './queryKeys';
import { useProjectCuid } from './useEditorProject';

type QueryKey = ReturnType<typeof edgeAssetQueryKeys.asset>;

export interface UseGetEdgeAssetStatusQuery<TData = GetEdgeAssetStatusResult>
  extends QueryOptions<GetEdgeAssetStatusResult, TData, QueryKey> {
  pollingInterval?: number;
}

function useGetEdgeAssetStatus<TData = GetEdgeAssetStatusResult>(
  edgeAssetId?: number,
  opts?: UseGetEdgeAssetStatusQuery<TData>,
) {
  const { data: cuid } = useProjectCuid();

  const {
    enabled = true,
    pollingInterval = DEFAULT_POLLING_INTERVAL_MILLIS,
    ...queryOptions
  } = opts ?? {};

  const refetchInterval = useCallback(
    (
      _: TData | undefined,
      query: Query<
        GetEdgeAssetStatusResult,
        unknown,
        GetEdgeAssetStatusResult,
        QueryKey
      >,
    ) => {
      return query?.state?.data?.status === 'processing'
        ? pollingInterval
        : false;
    },
    [pollingInterval],
  );
  return useQuery<GetEdgeAssetStatusResult, unknown, TData, QueryKey>(
    edgeAssetQueryKeys.asset(edgeAssetId, cuid),
    async ({ queryKey: [{ edgeAssetJobId, projectCuid }] }) => {
      if (!projectCuid || !edgeAssetJobId) {
        throw new Error('Error getting edge asset status');
      }

      const response = await getEdgeAssetStatus({
        projectCuid,
        edgeAssetId: edgeAssetJobId,
      });

      return response;
    },
    {
      enabled: !!edgeAssetId && enabled,
      refetchInterval,
      ...queryOptions,
    },
  );
}

export const usePollForEdgeAssetStatus = (edgeAssetId?: number) => {
  const { data: projectCuid } = useProjectCuid();
  const { userId } = useAuth();

  const queryClient = useQueryClient();

  return useGetEdgeAssetStatus(edgeAssetId, {
    enabled: !!edgeAssetId,
    onSuccess: (response) => {
      if (!response || response.status !== 'completed') {
        return;
      }

      if (response.status === 'completed') {
        queryClient.invalidateQueries(
          eddyProjectsQueryKeys.myProject(userId, projectCuid),
        );
      }
    },
  });
};
