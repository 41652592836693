import { useContext } from 'react';
import ClipSuggestionContext from './ClipSuggestionContext';

export default function useClipSuggestionContext() {
  const context = useContext(ClipSuggestionContext);

  if (context === undefined) {
    throw new Error(
      'useClipSuggestionContext must be used within ClipSuggestionProvider',
    );
  }

  return context;
}
