import React from 'react';
import faviconSmall from 'assets/favicon-16x16.png';
import faviconLarge from 'assets/favicon-32x32.png';
import Helmet, { FaviconHelmet } from 'components/Helmet';

export interface PageMetadataProps {}

const PageMetadata: React.FC<PageMetadataProps> = () => (
  <>
    <Helmet>
      <title>Eddy</title>
    </Helmet>
    <FaviconHelmet
      largeIcon={{ url: faviconLarge, type: 'image/png' }}
      smallIcon={{ url: faviconSmall, type: 'image/png' }}
    />
  </>
);

export default PageMetadata;
