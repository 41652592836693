import { css } from 'styled-components';
import { TransitionProps } from 'styles/types';
import { getTransitionDuration } from './utils';

interface FadeTransitionConfig {
  delayMillis?: number;
  durationMillis?: number;
  easingFunction?: string;
}

const fadeTransitionStates = {
  entering: 'opacity: 1',
  entered: 'opacity: 1',
  exiting: 'opacity: 0',
  exited: 'opacity: 0',
  unmounted: '',
};

export default function fade(opts?: FadeTransitionConfig) {
  const {
    delayMillis = 0,
    durationMillis,
    easingFunction = 'ease-in-out',
  } = opts ?? {};

  return css<TransitionProps>`
    transition: opacity ${getTransitionDuration(durationMillis)}
      ${easingFunction} ${delayMillis}ms;
    opacity: 0;

    ${({ status }) => fadeTransitionStates[status]};
  `;
}
