import { Chip, Spacer } from '@sparemin/blockhead';

import { FillerWordsProps } from './types';

const FillerWords: React.FC<FillerWordsProps> = (props) => {
  const { onClose } = props;

  return (
    <Spacer align="center">
      <Chip onClose={onClose}>Filler words</Chip>
    </Spacer>
  );
};

export default FillerWords;
