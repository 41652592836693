import { useFileDropzone } from '@sparemin/blockhead';
import React from 'react';
import * as S from './styles';
import UploadButton from './UploadButton';

interface ComputerUploadButtonProps {}

const ComputerUploadButton: React.FC<ComputerUploadButtonProps> = () => {
  const { openFileDialog } = useFileDropzone();

  return (
    <UploadButton
      aria-label="Upload files from your computer"
      label="Your computer"
      logo={<S.UploadIcon />}
      onPress={openFileDialog}
    />
  );
};

export default ComputerUploadButton;
