import { useContext } from 'react';
import PromoPackModalContext from './PromoPackModalContext';

export default function usePromoPackModal() {
  const context = useContext(PromoPackModalContext);

  if (context === undefined) {
    throw new Error(
      'usePromoPackModal must be used within PromoPackModalProvider',
    );
  }

  return context;
}
