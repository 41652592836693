import { useEventTracking } from 'state/eventTracking';

const useTrackReplaceTitleWithSuggestion = () => {
  const { trackReplaceTitleWithSuggestion } = useEventTracking();

  return {
    trackReplaceTitleWithSuggestion,
  };
};

export default useTrackReplaceTitleWithSuggestion;
