import { produce } from 'immer';
import { ProjectTranscript, ProjectTranscriptChapter } from 'api';
import { generatePlaceholderId } from '../../placeholderIds';
import {
  chaptersSelector,
  wordDictionarySelector,
  wordListSelector,
} from '../../selectors';
import { DEFAULT_NEW_CHAPTER_TITLE } from './constants';

function findIndexOfChapterContainingWord(
  wordId: number,
  transcript: ProjectTranscript,
) {
  const wordsById = wordDictionarySelector(transcript);
  const chapters = chaptersSelector(transcript);

  const targetWordIndex = wordsById[wordId]?.index;

  if (targetWordIndex === undefined) {
    return -1;
  }

  return chapters.findIndex((c) => {
    const startWordIndex = wordsById[c.startWordId]?.index;
    const endWordIndex = wordsById[c.endWordId]?.index;

    return startWordIndex <= targetWordIndex && targetWordIndex <= endWordIndex;
  });
}

function findIndexOfFirstChapterAfterNonChapterWord(
  wordId: number,
  transcript: ProjectTranscript,
) {
  const wordsById = wordDictionarySelector(transcript);
  const chapters = chaptersSelector(transcript);

  const word = wordsById[wordId];

  // chapters is sorted, so the correct chapter has been found when starting from
  // the beginning of the chapter list and finding a chapter with a start time
  // that occurs after the target word's end time.
  for (let i = 0; i < chapters.length; i += 1) {
    const chapter = chapters[i];

    if (chapter.startMillis > word.endMillis) {
      return i;
    }
  }

  return -1;
}

function createFromChapterWord(
  transcript: ProjectTranscript,
  chapterIndex: number,
  newChapterStartWordId: number,
  newChapterId: number,
  newChapterTitle: string,
  newChapterUrl: string,
  newChapterImageUrl: string,
): ProjectTranscript {
  const wordsById = wordDictionarySelector(transcript);
  const wordList = wordListSelector(transcript);
  const chapters = chaptersSelector(transcript);

  if (chapters[chapterIndex].startWordId === newChapterStartWordId) {
    throw new Error(
      'Cannot insert a new chapter before the first word of an existing chapter',
    );
  }

  const newChapterStartWordIndex = wordsById[newChapterStartWordId].index;
  const prevWordIndex = newChapterStartWordIndex - 1;
  const prevWord = wordList[prevWordIndex];

  return produce(transcript, (draft) => {
    const draftChapters = draft.chapters;
    const chapterToSplit = draftChapters[chapterIndex];

    const newChapter: ProjectTranscriptChapter = {
      id: newChapterId,
      endMillis: chapterToSplit.endMillis,
      endWordId: chapterToSplit.endWordId,
      startMillis: wordsById[newChapterStartWordId].startMillis,
      startWordId: newChapterStartWordId,
      title: newChapterTitle,
      url: newChapterUrl,
      imageUrl: newChapterImageUrl,
    };

    chapterToSplit.endWordId = prevWord.id;
    chapterToSplit.endMillis = prevWord.endMillis;

    draftChapters.push(newChapter);
  });
}

function createFromNonChapterWord(
  transcript: ProjectTranscript,
  newChapterStartWordId: number,
  newChapterId: number,
  newChapterTitle: string,
  newChapterUrl: string,
  newChapterImageUrl: string,
) {
  const wordList = wordListSelector(transcript);
  const wordsById = wordDictionarySelector(transcript);
  const chapters = chaptersSelector(transcript);

  const newChapterStartWord = wordsById[newChapterStartWordId];

  const indexOfChapterAfterWord = findIndexOfFirstChapterAfterNonChapterWord(
    newChapterStartWordId,
    transcript,
  );

  if (indexOfChapterAfterWord >= 0) {
    const chapter = chapters[indexOfChapterAfterWord];
    const chapterStartWordIndex = wordsById[chapter.startWordId].index;
    const lastWordBeforeChapterStart = wordList[chapterStartWordIndex - 1];

    return produce(transcript, (draft) => {
      draft.chapters.push({
        id: newChapterId,
        endMillis: lastWordBeforeChapterStart.endMillis,
        endWordId: lastWordBeforeChapterStart.id,
        startMillis: newChapterStartWord.startMillis,
        startWordId: newChapterStartWordId,
        title: newChapterTitle,
        url: newChapterUrl,
        imageUrl: newChapterImageUrl,
      });
    });
  }

  const transcriptLastWord = wordList[wordList.length - 1];

  return produce(transcript, (draft) => {
    draft.chapters.push({
      id: newChapterId,
      endMillis: transcriptLastWord.endMillis,
      endWordId: transcriptLastWord.id,
      startMillis: newChapterStartWord.startMillis,
      startWordId: newChapterStartWordId,
      title: newChapterTitle,
      url: newChapterUrl,
      imageUrl: newChapterImageUrl,
    });
  });
}

export function createChapter(
  transcript: ProjectTranscript,
  newChapterStartWordId: number,
  newChapterTitle = DEFAULT_NEW_CHAPTER_TITLE,
  newChapterUrl = '',
  newChapterImageUrl = '',
): [ProjectTranscript, number] {
  const newChapterId = generatePlaceholderId();

  const indexOfChapterToSplit = findIndexOfChapterContainingWord(
    newChapterStartWordId,
    transcript,
  );

  const newChapter =
    indexOfChapterToSplit < 0
      ? createFromNonChapterWord(
          transcript,
          newChapterStartWordId,
          newChapterId,
          newChapterTitle,
          newChapterUrl,
          newChapterImageUrl,
        )
      : createFromChapterWord(
          transcript,
          indexOfChapterToSplit,
          newChapterStartWordId,
          newChapterId,
          newChapterTitle,
          newChapterUrl,
          newChapterImageUrl,
        );

  return [newChapter, newChapterId];
}

export function deleteChapter(
  transcript: ProjectTranscript,
  chapterId: number,
): [ProjectTranscript, number, string, string, string] {
  const chapters = chaptersSelector(transcript);
  const targetChapterIndex = chapters.findIndex((c) => c.id === chapterId);
  const prevChapterIndex = targetChapterIndex - 1;

  const {
    title: originalChapterTitle,
    url: originalChapterUrl = '',
    imageUrl: originalChapterImageUrl = '',
    startWordId: targetChapterStartWordId,
  } = chapters[targetChapterIndex];

  const newTranscript = produce(transcript, (draft) => {
    if (prevChapterIndex < 0) {
      draft.chapters = [];
    } else {
      const chapter = draft.chapters[targetChapterIndex];
      const prevChapter = draft.chapters[prevChapterIndex];

      prevChapter.endWordId = chapter.endWordId;
      prevChapter.endMillis = chapter.endMillis;

      draft.chapters.splice(targetChapterIndex, 1);
    }
  });

  return [
    newTranscript,
    targetChapterStartWordId,
    originalChapterTitle,
    originalChapterUrl,
    originalChapterImageUrl,
  ];
}
