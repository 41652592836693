import { GoogleDriveVideoFileDetail } from '@sparemin/api-sdk';
import {
  GOOGLE_DRIVE_PLUGIN_ID,
  MediaImportedEvent,
  MediaRejectedEvent,
  ZOOM_PLUGIN_ID,
} from '@sparemin/media-import';
import { useCallback } from 'react';
import { FileInfo } from 'components/MediaUploader/types';
import { useModalsActions } from 'state/modal';
import { useIsBasicOrFreeTier } from 'state/pricing';
import { millisToSec } from 'utils/time';
import useTrackFileUploadErrors from '../useTrackFileUploadErrors';
import { notifyFileRejectionError } from '../utils';

export interface UseHandleMediaRejectedConfig {
  onMediaAccepted: (args: MediaImportedEvent) => void;
}

export interface UseHandleMediaRejectedResult {
  onMediaRejected: (args: MediaRejectedEvent) => void;
}

export default function useHandleMediaRejected({
  onMediaAccepted,
}: UseHandleMediaRejectedConfig): UseHandleMediaRejectedResult {
  const { data: isBasicOrFreeTier } = useIsBasicOrFreeTier();
  const { pushModal, hideModal } = useModalsActions();
  const { trackFileErrors } = useTrackFileUploadErrors();

  const handleGetMediaValidationInfo = useCallback(
    (event: MediaRejectedEvent): FileInfo | undefined => {
      switch (event.pluginId) {
        case GOOGLE_DRIVE_PLUGIN_ID: {
          const { file } = event.payload.media;
          const { size, videoMediaMetadata } =
            file as GoogleDriveVideoFileDetail;

          return {
            durationSec: millisToSec(videoMediaMetadata.durationMillis),
            sizeMb: size,
          };
        }

        case ZOOM_PLUGIN_ID: {
          const {
            recording: { durationMillis, fileSize },
          } = event.payload.media;

          return {
            durationSec: millisToSec(durationMillis),
            sizeMb: fileSize,
          };
        }

        default: {
          return undefined;
        }
      }
    },
    [],
  );

  const handleMediaRejected = useCallback(
    (args: MediaRejectedEvent): void => {
      const { reason } = args.payload;

      notifyFileRejectionError({ reason });

      if (isBasicOrFreeTier && ['TOO_LARGE', 'TOO_LONG'].includes(reason)) {
        pushModal({
          name: 'Upsell',
          params: {
            context: 'uploadLimitsError',
            onSuccess: () => {
              onMediaAccepted({
                payload: args.payload.media,
                pluginId: args.pluginId,
              } as MediaImportedEvent);
            },
          },
        });
      }

      const { sizeMb, durationSec } = handleGetMediaValidationInfo(args) || {};

      if (sizeMb && durationSec) {
        trackFileErrors({
          sizeMb,
          durationSec,
        });
      }

      hideModal();
    },
    [
      handleGetMediaValidationInfo,
      hideModal,
      isBasicOrFreeTier,
      onMediaAccepted,
      pushModal,
      trackFileErrors,
    ],
  );

  return {
    onMediaRejected: handleMediaRejected,
  };
}
