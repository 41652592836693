import { Copy, Download } from '@sparemin/blockhead';
import { useCallback, useState } from 'react';
import { PromoPackAssetType } from 'api';
import { notifySuccess } from 'components/notification/showNotification';
import { useEventListener } from 'hooks/useEventListener';
import { usePromoPackAsset } from 'pages/TranscriptEditorPage/state/useGetPromoPackContents';
import { useEventTracking } from 'state/eventTracking';
import { copyToClipboard } from 'utils/clipboard';
import BaseTabPanel from '../BaseTabPanel';
import useDownloadPromoPackSpecificAsset from '../useDownloadPromoPackSpecificAsset';
import * as S from './styles';
import useTextContent from './useTextContent';

export interface TextTabPanelProps {
  assetType?: PromoPackAssetType;
}

const TextTabPanel: React.FC<TextTabPanelProps> = ({ assetType }) => {
  const [textContainerElement, textContainerRef] =
    useState<HTMLDivElement | null>(null);

  const { downloadAsset, isLoading: isDownloadingPromoPack } =
    useDownloadPromoPackSpecificAsset();
  const { textToCopy, textToDisplay } = useTextContent({ assetType });
  const { trackPromoPackModalAction } = useEventTracking();
  const { data: asset } = usePromoPackAsset(assetType);
  const isAssetNotCompleted = asset?.status !== 'completed';

  useEventListener(
    'copy',
    () => {
      if (!assetType) {
        return;
      }

      trackPromoPackModalAction('CopyTextHighlight', assetType);
    },
    {
      target: textContainerElement ?? undefined,
    },
  );

  const handleCopyToClipboard = useCallback(() => {
    if (!assetType) {
      return;
    }

    trackPromoPackModalAction('CopyTextAsset', assetType);

    copyToClipboard(textToCopy);

    notifySuccess({
      heading: 'Copied to clipboard!',
      toastId: 'promo-pack-text-asset-copied',
    });
  }, [assetType, textToCopy, trackPromoPackModalAction]);

  const handleDownloadPromoPack = useCallback((): void => {
    if (!assetType) {
      return;
    }

    trackPromoPackModalAction('DownloadSpecificAsset', assetType);
    downloadAsset(assetType);
  }, [assetType, downloadAsset, trackPromoPackModalAction]);

  const handleTextClick = useCallback((event: MouseEvent) => {
    if (event.target instanceof HTMLAnchorElement) {
      event.preventDefault();
      window.open(event.target.href, '_blank');
    }
  }, []);

  return (
    <BaseTabPanel
      assetType={assetType}
      actionButtons={[
        {
          key: 'copy',
          label: 'Copy',
          startIcon: <Copy />,
          isDisabled: isAssetNotCompleted,
          onPress: handleCopyToClipboard,
        },
        {
          key: 'download',
          label: 'Download',
          startIcon: <Download />,
          isDisabled: isDownloadingPromoPack || isAssetNotCompleted,
          onPress: handleDownloadPromoPack,
        },
      ]}
    >
      {textToDisplay && (
        <S.TextContainer ref={textContainerRef}>
          <S.TextContent>
            {textToDisplay.split('\n').map((text, index) => (
              // It's not ideal to use an index as a key, but
              // there's not much else to do in this scenario.
              <S.Paragraph
                key={index}
                dangerouslySetInnerHTML={{ __html: text }}
                onClick={handleTextClick}
              />
            ))}
          </S.TextContent>
        </S.TextContainer>
      )}
    </BaseTabPanel>
  );
};

export default TextTabPanel;
