import { produce } from 'immer';
import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { FavoritePodcastsResponse, Podcast } from 'api';
import { useAuth } from 'state/auth';
import { podcastQueryKeys } from './queryKeys';

/**
 * In some cases, favorite podcasts are updated externally  (e.g. by the
 * @sparemin/media-import library).  We can't add optimistic updates directly into
 * the requests made from these libraries, so this hook helps make the optimistic
 * update outside of the query hook.
 */
export default function useAddFavoritePodcast() {
  const { userId } = useAuth();
  const queryClient = useQueryClient();

  const queryKey = podcastQueryKeys.myFavoritePodcasts(userId);

  return useCallback(
    (podcast: Podcast) => {
      queryClient.cancelQueries(queryKey);

      const data: FavoritePodcastsResponse = queryClient.getQueryData(
        queryKey,
      ) ?? { podcasts: [] };

      const updatedData = produce(data, (draft) => {
        const draftPodcasts = draft.podcasts;

        const podcastIndex = draftPodcasts.findIndex(
          (p) => p.podcastId === podcast.podcastId,
        );

        if (podcastIndex >= 0) {
          draftPodcasts.splice(podcastIndex, 1);
        }
        draftPodcasts.unshift(podcast);
      });

      queryClient.setQueryData(queryKey, updatedData);
    },
    [queryClient, queryKey],
  );
}
