import { usePlanUploadMaxValues } from 'state/pricing';
import { MAX_FILE_SIZE_MB } from './constants';

export default function useProjectFileUploadLimits() {
  const { data: uploadLimits } = usePlanUploadMaxValues();

  const maxMediaDurationHr = uploadLimits?.maxMediaDurationHr;
  const maxSizeMB = uploadLimits?.maxUploadSizeMB ?? MAX_FILE_SIZE_MB;

  return {
    maxMediaDurationHr,
    maxSizeMB,
  };
}
