import { Button } from '@sparemin/blockhead';
import React, { useCallback } from 'react';
import { useModalsActions } from 'state/modal';
import { DeleteChapterModalConfig } from 'state/modal/modals';
import { useEditTranscript } from '../state';
import * as S from './styles';

interface DeleteChapterModalContentsProps {
  params?: DeleteChapterModalConfig['params'];
}

const DeleteChapterModalContents: React.FunctionComponent<
  DeleteChapterModalContentsProps
> = ({ params }) => {
  const { chapterId, onCancel } = params || {};

  const { deleteChapter } = useEditTranscript();
  const { hideModal } = useModalsActions();

  const handleConfirm = useCallback((): void => {
    if (!chapterId) {
      return;
    }

    deleteChapter(chapterId);
    hideModal();
  }, [chapterId, deleteChapter, hideModal]);

  return (
    <S.Root
      icon={<S.AlertIcon />}
      title="Delete chapter"
      subtitle="This action cannot be undone, are you sure you want to delete it?"
    >
      <S.Contents>
        <Button fluid variant="solid" color="danger" onPress={handleConfirm}>
          confirm deletion
        </Button>

        <Button fluid variant="ghost" onPress={onCancel}>
          cancel
        </Button>
      </S.Contents>
    </S.Root>
  );
};

export default DeleteChapterModalContents;
