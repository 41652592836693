import { List } from '@sparemin/blockhead';
import { range } from 'lodash-es';
import ProjectRow from './ProjectRow';

interface SkeletonProjectsListProps {
  className?: string;
  rows?: number;
}

const SkeletonProjectsList: React.FC<SkeletonProjectsListProps> = ({
  className,
  rows = 5,
}) => {
  const disabledKeys: string[] = [];

  return (
    <List
      aria-label="skeleton-list"
      className={className}
      disabledKeys={disabledKeys}
    >
      {range(rows).map((i) => {
        disabledKeys.push(String(i));
        return (
          <List.Item key={i} textValue="loading projects...">
            <ProjectRow />
          </List.Item>
        );
      })}
    </List>
  );
};

export default SkeletonProjectsList;
