import { useCallback } from 'react';
import { useModalsActions } from 'state/modal';
import { useHasDefaultPodcast } from 'state/podcast';

type UseOnPodcastPersonalizationChange = {
  onChange?: (isSelected: boolean) => void;
};

export default function useOnPodcastPersonalizationChange({
  onChange,
}: UseOnPodcastPersonalizationChange) {
  const { data: hasDefaultPodcast } = useHasDefaultPodcast();
  const { pushModal } = useModalsActions();

  const handleChange = useCallback(
    (isSelected: boolean) => {
      if (isSelected && !hasDefaultPodcast) {
        pushModal({
          name: 'AIPodcastSearch',
          params: {
            onSelectPodcast() {
              onChange?.(true);
            },
          },
        });
      } else {
        onChange?.(isSelected);
      }
    },
    [hasDefaultPodcast, onChange, pushModal],
  );

  return { onChange: handleChange };
}
