import {
  Button,
  Globe,
  GlobeGradient,
  InputAdornment,
  PulseLoader,
  Select,
  Spacer,
} from '@sparemin/blockhead';
import React, { useCallback, useState } from 'react';
import Modal from 'components/Modal';
import { DEFAULT_LANGUAGE } from 'config';
import { useAIOptionsActions } from 'state/aiOptions';
import { ProjectLanguageSelectorModalConfig } from 'state/modal/modals';
import { useSupportedLanguages } from '../state';
import * as S from './styles';

interface ProjectLanguageSelectorModalContentsProps {
  params?: ProjectLanguageSelectorModalConfig['params'];
}

const ProjectLanguageSelectorModalContents: React.FunctionComponent<
  ProjectLanguageSelectorModalContentsProps
> = ({ params }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { data: supportedLanguages = [] } = useSupportedLanguages();

  const { onLanguageSelected } = params || {};
  const { reset: resetAIOptionsSettings } = useAIOptionsActions();
  const [selectedLanguage, setSelectedLanguage] =
    useState<string>(DEFAULT_LANGUAGE);

  const handleLanguageChange = useCallback((newLanguage: React.Key): void => {
    setSelectedLanguage(newLanguage as string);
  }, []);

  const handleContinue = useCallback((): void => {
    setIsLoading(true);
    resetAIOptionsSettings();
    onLanguageSelected?.(selectedLanguage);
  }, [onLanguageSelected, resetAIOptionsSettings, selectedLanguage]);

  return (
    <S.Root
      icon={<GlobeGradient width="100px" height="100px" />}
      title="What language is your file?"
      subtitle="This ensures our transcription is as accurate as possible."
    >
      <Modal.Body as={Spacer} space="32px" orientation="vertical">
        {isLoading ? (
          <S.Loader>
            <PulseLoader />
          </S.Loader>
        ) : (
          <>
            <Select
              fluid
              startAdornment={
                <InputAdornment position="start">
                  <Globe />
                </InputAdornment>
              }
              aria-label="supported languages list"
              selectedKey={selectedLanguage}
              onSelectionChange={handleLanguageChange}
            >
              {supportedLanguages?.map(({ languageCode, name }) => (
                <Select.Option key={languageCode} textValue={name}>
                  {name}
                </Select.Option>
              ))}
            </Select>
            <Button fluid onPress={handleContinue}>
              continue with this language
            </Button>
          </>
        )}
      </Modal.Body>
    </S.Root>
  );
};

export default ProjectLanguageSelectorModalContents;
