import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { TimeInterval } from 'types';

dayjs.extend(duration);

const DEFAULT_DATE_FORMAT = 'MMM D, YYYY';
export const DEFAULT_DURATION_FORMAT = 'H:mm:ss';

export function formatDate(
  epochMillis: number,
  format: string = DEFAULT_DATE_FORMAT,
) {
  return dayjs(epochMillis).format(format);
}

export function secToMillis(sec: number) {
  return sec * 1000;
}

export function millisToSec(millis: number) {
  return millis / 1000;
}

export function secToMin(sec: number) {
  return sec / 60;
}

export function hoursToSec(hours?: number) {
  if (!hours) return undefined;
  return hours * 60 * 60;
}

export function hoursToMillis(hours?: number) {
  if (!hours) return undefined;

  const sec = hoursToSec(hours);

  if (!sec) return undefined;

  return secToMillis(sec);
}

export function formatDuration(
  millis: number,
  format: string = DEFAULT_DURATION_FORMAT,
) {
  return dayjs.duration(millis).format(format);
}

export function getIntervalDuration(interval: TimeInterval) {
  return interval.endMillis - interval.startMillis;
}
