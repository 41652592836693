import { getThemeColor, Spacer, Heading } from '@sparemin/blockhead';
import styled from 'styled-components';
import intro from 'assets/intro.png';
import intro2x from 'assets/intro@2x.png';
import intro3x from 'assets/intro@3x.png';

export const IntroIcon = styled('img').attrs({
  src: intro,
  srcSet: `${intro} 1x, ${intro2x} 2x, ${intro3x} 3x`,
  alt: '',
})`
  width: 45px;
  height: 45px;
`;

export const OutroIcon = styled(IntroIcon)`
  transform: scaleX(-1);
`;

export const Container = styled(Spacer).attrs({
  justify: 'space-between',
  align: 'center',
})`
  padding: 20px;
  border-radius: 24px;
  border: solid 2px ${getThemeColor('l4')};
  width: 100%;
`;

export const Text = styled(Heading).attrs({ level: 3 })`
  text-transform: capitalize;
`;

export const VideoThumbnailContainer = styled.div`
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
