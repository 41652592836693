import { Popover, PopoverProps } from '@sparemin/blockhead';
import React from 'react';
import SuggestedClipDislikePopoverContents from './SuggestedClipDislikePopoverContents';

export interface SuggestedClipDislikePopoverProps
  extends Pick<PopoverProps, 'isOpen' | 'triggerElement'> {}

const SuggestedClipDislikePopover: React.FC<
  SuggestedClipDislikePopoverProps
> = ({ isOpen, triggerElement }) => {
  return (
    <Popover
      {...{ isOpen, triggerElement }}
      placement="bottom-start"
      size="large"
      offset={8}
    >
      <SuggestedClipDislikePopoverContents />
    </Popover>
  );
};

export default SuggestedClipDislikePopover;
