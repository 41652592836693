import Region from 'wavesurfer.js/src/plugin/regions';

export function createPluginCSSVar(pluginName: string, variableName: string) {
  return `--ws-${pluginName}-plg-${variableName}`;
}

export function getRegionHandleElements(region: Region) {
  // the types are wrong - handleLeftEl and handleRightEl are not
  // HTMLElement, they are proxies created by  util.withOrientation.  to access
  // the underlying element, `domElement` must be used.

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const left = region?.handleLeftEl?.domElement as HTMLElement;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const right = region?.handleRightEl?.domElement as HTMLElement;

  return { left, right };
}
