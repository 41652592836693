import { WelcomeTabPanelCard } from './types';

export const welcomeTabPanelCards: WelcomeTabPanelCard[] = [
  {
    key: 'showNotes',
    backgroundImage:
      'https://static.sparemin.com/static/eddy/promo-pack/promo-pack-card-image-show-notes.jpeg',
    content: '💬 Show Notes',
  },
  {
    key: 'episodeArt',
    backgroundImage:
      'https://static.sparemin.com/static/eddy/promo-pack/promo-pack-card-image-episode-art.jpeg',
    content: '🎨 Episode art',
  },
  {
    key: 'keywords',
    backgroundImage:
      'https://static.sparemin.com/static/eddy/promo-pack/promo-pack-card-image-keywords.jpeg',
    content: '🔑 Keywords',
  },
  {
    key: 'socialCaptions',
    backgroundImage:
      'https://static.sparemin.com/static/eddy/promo-pack/promo-pack-card-image-social-captions.jpeg',
    content: '📣 Social captions',
  },
  {
    key: 'quotes',
    backgroundImage:
      'https://static.sparemin.com/static/eddy/promo-pack/promo-pack-card-image-quotes.jpeg',
    content: '🗣️ Quotes',
  },
  {
    key: 'references',
    backgroundImage:
      'https://static.sparemin.com/static/eddy/promo-pack/promo-pack-card-image-references.jpeg',
    content: '✍️ References',
  },
  {
    key: 'newsletter',
    backgroundImage:
      'https://static.sparemin.com/static/eddy/promo-pack/promo-pack-card-image-newsletter.jpeg',
    content: '✉️ Newsletter',
  },
  {
    key: 'blogPost',
    backgroundImage:
      'https://static.sparemin.com/static/eddy/promo-pack/promo-pack-card-image-blog.jpeg',
    content: '📝 Blog Post',
  },
];
