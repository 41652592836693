import { useEffect, useState } from 'react';
import { useAudioPlayer } from '../AudioPlayerContext';

/**
 * Various states related to the player.  Since the player reference is stable
 * and won't change unless the audio source changes, no changes to the player will
 * cause re-renders.  This hook converts some of the players properties into state
 * variables so that components can use the variables and subscribe to state changes
 */
export default function usePlaybackState() {
  const { player } = useAudioPlayer();
  const [paused, setPaused] = useState(player.paused);
  const [duration, setDuration] = useState(player.duration);
  const [ready, setReady] = useState(player.ready);

  useEffect(() => {
    const handlePlay = () => setPaused(false);
    const handlePause = () => setPaused(true);
    const handleDurationChange = () => setDuration(player.duration);
    const handleCanPlay = () => setReady(true);

    player.on('play', handlePlay);
    player.on('pause', handlePause);
    // TODO what if player is already ready and durationchange doesn't fire?
    player.on('durationchange', handleDurationChange);
    player.on('canplay', handleCanPlay);

    return () => {
      player.off('play', handlePlay);
      player.off('pause', handlePause);
      player.off('durationchange', handleDurationChange);
      player.off('canplay', handleCanPlay);
    };
  }, [player]);

  return { duration, paused, ready };
}
