import { useScript, ScriptStatus } from '@sparemin/blockhead';
import { useCallback } from 'react';
import notifyError from 'components/notification';
import {
  DISCO_DISPLAY_VARIANT_ID,
  DISCO_SDK_URL,
  DISCO_WIDGET_ID,
} from 'config';

export interface UseDiscoConfig {
  injectionPoint: HTMLElement | string | null;
  onError?: (err: Error) => void;
}

export default function useDisco({ injectionPoint, onError }: UseDiscoConfig) {
  useScript({
    onStatusChange: useCallback(
      (status: ScriptStatus) => {
        if (status === 'error') {
          const error = new Error('Error loading clips');
          onError?.(error);
          notifyError({ heading: error.message });
        } else if (status === 'ready') {
          if (window.disco && injectionPoint) {
            window.disco.render(injectionPoint, {
              displayVariantId: DISCO_DISPLAY_VARIANT_ID,
              title: undefined,
              url: undefined,
              widgetId: DISCO_WIDGET_ID,
            });
          }
        }
      },
      [injectionPoint, onError],
    ),
    src: injectionPoint ? DISCO_SDK_URL : '',
  });
}
