import { Spacer } from '@sparemin/blockhead';
import styled from 'styled-components';

export const MobileComingSoonContainer = styled(Spacer).attrs({
  orientation: 'vertical',
  align: 'center',
  justify: 'center',
  space: '30px',
})`
  width: 100%;
  height: 100vh;
  padding: 16px;
`;

export const MobileComingSoonHeader = styled(Spacer).attrs({
  orientation: 'vertical',
  align: 'center',
  justify: 'center',
  space: '13px',
})`
  max-width: 343px;
  text-align: center;
`;
