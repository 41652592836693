import { useQuery } from 'react-query';
import { getUserProfile, GetUserProfileResult } from 'api/services/user';
import { QueryOptions } from 'types';
import { userQueryKeys } from './queryKeys';

type QueryKey = ReturnType<typeof userQueryKeys.profile>;

export type UseGetUserProfileOptions<TData = GetUserProfileResult> =
  QueryOptions<GetUserProfileResult, TData, QueryKey>;

export default function useGetUserProfile<TData = GetUserProfileResult>(
  userId?: number,
  opts?: UseGetUserProfileOptions<TData>,
) {
  return useQuery(
    userQueryKeys.profile(userId),
    ({ queryKey: [{ userId: id }] }) =>
      getUserProfile({ userId: id as number }),
    {
      ...opts,
      enabled: !!userId && (opts?.enabled ?? true),
      meta: {
        errorMessage: 'Error loading user information.',
      },
    },
  );
}
