import { useCallback, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useEventTracking } from 'state/eventTracking';
import { userQueryKey } from '../queryKeyUtils';

import { AuthContextType, AuthState } from './types';
import {
  createAuthStateFromToken,
  deleteToken,
  persistToken,
  rehydrateToken,
} from './utils';

export default function useAuthState(): AuthContextType {
  const queryClient = useQueryClient();
  const { clearUserTrackingInfo } = useEventTracking();

  const [state, setState] = useState<AuthState | undefined>(() => {
    const storedToken = rehydrateToken();

    if (!storedToken) {
      return undefined;
    }

    return createAuthStateFromToken(storedToken);
  });

  const userId = state?.userId;

  const login = useCallback(
    (data: AuthState) => {
      if (data !== state) {
        setState(data);
        persistToken(data.accessToken);
      }
    },
    [state],
  );

  const handleReplaceToken = useCallback(
    (newToken: string) => {
      login(createAuthStateFromToken(newToken));
    },
    [login],
  );

  const logout = useCallback(() => {
    setState(undefined);

    if (userId) {
      // on logout clear all queries associated with the authenticated user
      queryClient.invalidateQueries({ queryKey: [userQueryKey(userId)] });
    }

    deleteToken();
    clearUserTrackingInfo();
  }, [queryClient, userId, clearUserTrackingInfo]);

  return useMemo(
    () => ({
      login,
      logout,
      accessToken: state?.accessToken,
      isAuthenticated: !!state,
      userId: state?.userId,
      replaceToken: handleReplaceToken,
    }),
    [login, logout, state, handleReplaceToken],
  );
}
