import { Button } from '@sparemin/blockhead';
import React, { useCallback } from 'react';
import useDefaultUploadLimitMessage from 'hooks/useDefaultUploadLimitMessage';
import { useModalsActions } from 'state/modal';
import BaseUpsellModal from '../BaseUpsellModal';
import BaseUpsellModalFooter from '../BaseUpsellModalFooter';
import { BaseUpsellModalProps } from '../types';
import * as S from './styles';

interface UploadLimitsErrorContentsProps extends BaseUpsellModalProps {}

const UploadLimitsErrorContents: React.FC<UploadLimitsErrorContentsProps> = ({
  onSuccess,
}) => {
  const { hideModal } = useModalsActions();
  const defaultUploadLimitMessage = useDefaultUploadLimitMessage();

  const handleOnSuccess = useCallback((): void => {
    hideModal();
    onSuccess?.();
  }, [hideModal, onSuccess]);

  return (
    <BaseUpsellModal
      title="This file is too big for your tier"
      subtitle={defaultUploadLimitMessage}
      icon={<S.AlertIcon />}
      footer={
        <BaseUpsellModalFooter
          modalName="EddyUploadLimitError"
          onSuccess={handleOnSuccess}
        >
          <Button fluid variant="ghost" onPress={hideModal}>
            choose another file
          </Button>
        </BaseUpsellModalFooter>
      }
    />
  );
};

export default UploadLimitsErrorContents;
