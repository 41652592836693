import { useEffect } from 'react';
import Region from 'wavesurfer.js/src/plugin/regions';
import WaveSurfer from 'wavesurfer.js/src/wavesurfer';
import { secToMillis } from 'utils/time';

export interface UseWaveSurferRegionConfig {
  onChangeEnd?: (startMillis: number, endMillis: number) => void;
  wavesurfer: WaveSurfer | undefined;
}

export default function useWaveSurferRegion({
  onChangeEnd,
  wavesurfer,
}: UseWaveSurferRegionConfig) {
  useEffect(() => {
    if (!wavesurfer) {
      return;
    }

    const handleChange = (region: Region) => {
      onChangeEnd?.(secToMillis(region.start), secToMillis(region.end));
    };

    wavesurfer.on('region-update-end', handleChange);

    return () => {
      wavesurfer.un('region-update-end', handleChange);
    };
  }, [onChangeEnd, wavesurfer]);
}
