import {
  ColorPaletteColors,
  ColorPaletteState,
  useColorPaletteState,
} from '@sparemin/blockhead';
import { useCallback, useEffect, useState } from 'react';
import usePrevious from 'hooks/usePrevious';
import { useHexColorPaletteList } from 'pages/TranscriptEditorPage/state/useGetColorPalette';
import { useEpisodeArtAspectRatioName } from 'pages/TranscriptEditorPage/state/useGetPromoPackContents';
import {
  imageTypeFeedbackOptions,
  aspectRatioFeedbackOptions,
} from '../EpisodeArtTabPanel/constants';
import { AdjustmentValues } from './types';

export interface UseAdjustPromoAssetsOutputResult {
  adjustmentValues: AdjustmentValues;
  colorPaletteState: ColorPaletteState;
  onAdjustmentValueChange: (newValues: AdjustmentValues) => void;
}

export default function useAdjustPromoAssetsOutput(): UseAdjustPromoAssetsOutputResult {
  const { data: episodeArtAspectRatioName } = useEpisodeArtAspectRatioName();

  const [adjustmentValues, setAdjustmentValues] = useState<AdjustmentValues>({
    aspectRatio:
      episodeArtAspectRatioName ?? aspectRatioFeedbackOptions[0].value,
    imageType: imageTypeFeedbackOptions[0].value,
  });

  const prevAspectRatio = usePrevious(episodeArtAspectRatioName);
  const { data: colorPalette = [] } = useHexColorPaletteList();

  const colorPaletteState = useColorPaletteState({
    defaultValue: colorPalette as ColorPaletteColors,
  });

  const handleAdjustmentsValueChange = useCallback(
    (newValues: AdjustmentValues): void => {
      setAdjustmentValues({
        ...adjustmentValues,
        ...newValues,
      });
    },
    [adjustmentValues],
  );

  useEffect(() => {
    if (!prevAspectRatio && !!episodeArtAspectRatioName) {
      handleAdjustmentsValueChange({
        aspectRatio: episodeArtAspectRatioName,
      });
    }
  }, [
    episodeArtAspectRatioName,
    handleAdjustmentsValueChange,
    prevAspectRatio,
  ]);

  return {
    adjustmentValues,
    colorPaletteState,
    onAdjustmentValueChange: handleAdjustmentsValueChange,
  };
}
