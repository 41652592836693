import React from 'react';
import { Outlet } from 'react-router-dom';
import { useThemeOverride } from 'state/displayPref';

export interface ThemedRouteProps {
  children?: React.ReactNode;
}

/**
 * Blocks the route from loading until the user theme is loaded, that way the
 * user doesn't see a flash of the default theme
 */
const ThemedRoute: React.FC<ThemedRouteProps> = ({ children }) => {
  const { status } = useThemeOverride();

  if (status === 'error' || status === 'success') {
    return <>{children ?? <Outlet />}</>;
  }

  return null;
};

export default ThemedRoute;
