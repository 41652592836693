import { useMemo } from 'react';
import TopBarContext from './TopBarContext';
import { TopBarProviderProps } from './types';

const TopBarProvider: React.FC<TopBarProviderProps> = ({
  assetType,
  onRegenerate,
  onCancel,
  children,
}) => (
  <TopBarContext.Provider
    value={useMemo(
      () => ({ assetType, onRegenerate, onCancel }),
      [assetType, onRegenerate, onCancel],
    )}
  >
    {children}
  </TopBarContext.Provider>
);

export default TopBarProvider;
