import { Spacer, getThemeColor } from '@sparemin/blockhead';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { TAB_PANEL_MAX_WIDTH_PX } from '../BaseTabPanel/constants';

export const Root = styled(motion(Spacer)).attrs({
  orientation: 'vertical',
  align: 'center',
  justify: 'center',
  gap: '24px',
})`
  width: ${TAB_PANEL_MAX_WIDTH_PX}px;
  height: 362px;
  position: absolute;
  left: 0;
  top: 40px;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  border-radius: 24px;
  background-color: ${getThemeColor('white')};
`;
