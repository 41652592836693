import { formatDuration } from 'utils/time';

const UNKNOWN_TIMECODE_PLACEHOLDER = '-:--:--';

export function formatOutput(
  currentTimeMillis: number,
  durationMillis: number | undefined,
) {
  const currentTimecode = isFinite(currentTimeMillis)
    ? formatDuration(currentTimeMillis)
    : UNKNOWN_TIMECODE_PLACEHOLDER;

  if (durationMillis === undefined) {
    return currentTimecode;
  }

  const durationTimecode = isFinite(durationMillis)
    ? formatDuration(durationMillis)
    : UNKNOWN_TIMECODE_PLACEHOLDER;

  return `${currentTimecode} | ${durationTimecode}`;
}
