import { useCallback, useEffect, useRef } from 'react';
import { create } from 'zustand';
import { useAudioPlayer } from 'pages/TranscriptEditorPage/AudioPlayerContext';
import {
  actionsSelector,
  activeWordIdSelector,
  showDeletedWordsSelector,
} from './selectors';
import { EditorUiState, EditorUiStore } from './types';

const defaultState: EditorUiState = {
  activeWordId: undefined,
  isScrolling: false,
  selectedWordIds: [],
  showDeletedWords: true,
  scrollingSegmentWordId: undefined,
  scrollToWordId: undefined,
  wordSyncDisabled: false,
};

const useEditorUiStore = create<EditorUiStore>()((set, get) => ({
  ...defaultState,
  actions: {
    activateWord: (activeWordId) => {
      set((s) => ({
        activeWordId,
        // don't set scrollToWord if scrolling is currently in progress.  Scrolling
        // gets real janky if we try scrolling an element into view when a scrolling
        // operation is already in progr0ess
        scrollToWordId: s.isScrolling ? s.scrollToWordId : activeWordId,
      }));
    },
    toggleDeletedWordsVisibility: (isVisible) => {
      set((s) => ({ showDeletedWords: isVisible ?? !s.showDeletedWords }));
    },
    toggleIsScrolling: (isScrolling) => {
      set((s) => ({
        isScrolling,
        scrollingSegmentWordId: isScrolling
          ? s.scrollingSegmentWordId
          : undefined,
      }));
    },
    reset: () => {
      set(defaultState);
    },
    selectWords: (selectedWordIds) => {
      set({ selectedWordIds });
    },
    scrollToSegment: (wordId) => set({ scrollingSegmentWordId: wordId }),
    toggleWordSync: (enabled) => {
      set({ wordSyncDisabled: !enabled });
    },
  },
}));

export const useEditorUiActions = () => useEditorUiStore(actionsSelector);

export function useEditorUiSubscription(
  cb: (current: EditorUiStore, prev: EditorUiStore) => void,
) {
  useEffect(() => useEditorUiStore.subscribe(cb), [cb]);
}

export const useActiveWordId = () => useEditorUiStore(activeWordIdSelector);

export const useIsWordActive = (wordId: number) =>
  useEditorUiStore(
    useCallback((s) => activeWordIdSelector(s) === wordId, [wordId]),
  );

export const useIsWordScrollTarget = (wordId: number) =>
  useEditorUiStore(useCallback((s) => s.scrollToWordId === wordId, [wordId]));

export const useIsWordSelected = (wordId: number) =>
  useEditorUiStore(
    useCallback((s) => !!s.selectedWordIds?.includes(wordId), [wordId]),
  );

export const useShowDeletedWords = () =>
  useEditorUiStore(showDeletedWordsSelector);

export const useIsWordVisible = (isDeleted: boolean) =>
  useEditorUiStore(
    useCallback((s) => showDeletedWordsSelector(s) && isDeleted, [isDeleted]),
  );

type Callback = () => void;

export const useWithDisabledWordSync = () => {
  const { toggleWordSync } = useEditorUiActions();
  const { player } = useAudioPlayer();
  const fnRef = useRef<Callback>();
  const fnCompletedRef = useRef(false);

  const handleSeeked = useCallback(() => {
    if (fnCompletedRef.current) {
      toggleWordSync(true);
      player.off('seeked', handleSeeked);
    }
  }, [player, toggleWordSync]);

  useEffect(() => handleSeeked, [handleSeeked]);

  return useCallback(
    (fn: () => void) => {
      fnRef.current = fn;
      fnCompletedRef.current = false;

      toggleWordSync(false);
      player.on('seeked', handleSeeked);
      fn();
      fnCompletedRef.current = true;
    },
    [handleSeeked, player, toggleWordSync],
  );
};
