import { QueryStatus } from 'react-query';
import { ExportFormat } from 'types';
import { EXPORT_MODAL_TITLES_BY_STATUS } from './constants';

export function getModalTitleByStatus(
  status: QueryStatus,
  exportType?: ExportFormat,
) {
  if (status === 'loading' && exportType) {
    return `${EXPORT_MODAL_TITLES_BY_STATUS.loading} ${exportType}`;
  }

  return EXPORT_MODAL_TITLES_BY_STATUS[status];
}
