import { Toast } from '@sparemin/blockhead';
import React from 'react';
import { ActionableToastProps } from './types';

const ActionableToast: React.FC<ActionableToastProps> = ({
  actionLabel,
  closeToast,
  heading,
  onAction,
}) => (
  <Toast
    accentColor="primary"
    {...{ actionLabel, heading }}
    onAction={() => {
      onAction();
      closeToast?.();
    }}
  />
);

export default ActionableToast;
