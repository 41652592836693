import styled from 'styled-components';
import Modal from 'components/Modal';

export const Body = styled(Modal.Body)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 30px 0;
`;

export const Content = styled.div`
  width: 100%;
`;

export const ActionsGrid = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
`;

export const ActionsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 36px;
`;

export const IconWrapper = styled.div<{ paddingTop?: number; size: number }>`
  && {
    padding-top: ${({ paddingTop = 0 }) => paddingTop}px;
    height: ${({ size }) => size}px;
    width: ${({ size }) => size}px;
  }
`;

export const NotesContainer = styled.div`
  margin-top: 40px;
`;
