import {
  createChapter,
  CreateChapterArgs,
  TranscriptCreateChapterResponse,
} from 'api';
import { useTranscriptId } from './useEditorTranscript';
import useUpdateTranscript from './useUpdateTranscript';

export type UseCreateTranscriptChapterVariables = Omit<
  CreateChapterArgs,
  'transcriptId'
>;

export default function useCreateTranscriptChapter() {
  const { data: transcriptId } = useTranscriptId();

  return useUpdateTranscript<
    TranscriptCreateChapterResponse,
    UseCreateTranscriptChapterVariables
  >({
    mutationFn: ({
      newChapterStartWordId,
      newChapterTitle,
      newChapterUrl,
      newChapterImageUrl,
    }) => {
      if (!transcriptId) {
        throw new Error('Error creating chapter');
      }

      return createChapter({
        newChapterStartWordId,
        newChapterTitle,
        newChapterUrl,
        newChapterImageUrl,
        transcriptId,
      });
    },
  });
}
