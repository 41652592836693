import { DefaultTheme, ThemedStyledProps } from 'styled-components';
import { TransitionProps } from 'styles/types';

export const getTransitionDuration =
  (durationMillis: number | undefined) =>
  (props: ThemedStyledProps<TransitionProps, DefaultTheme>) => {
    return durationMillis !== undefined
      ? `${durationMillis}ms`
      : props.theme.transitions.duration.default;
  };
