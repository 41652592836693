import { ExternalLink } from '@sparemin/blockhead';
import { useEffect, useRef } from 'react';

import ExportModalContents, {
  ShareExportingBody,
} from 'components/ExportModalContents';
import Modal from 'components/Modal';
import useBulkExport from 'pages/TranscriptEditorPage/state/useBulkExport';
import { useModalsActions } from 'state/modal';
import { useIntegratorDisplayName } from 'state/user';
import { IntegratorExportModalContentsProps } from './types';

const IntegratorExportModalContents: React.FC<
  IntegratorExportModalContentsProps
> = ({
  exportAudio,
  projectCuid,
  projectName,
  redirectUrl,
  transcriptFormats,
}) => {
  const { data: integratorName } = useIntegratorDisplayName();
  const { bulkExport } = useBulkExport();
  const exportInitiatedRef = useRef(false);
  const { hideModal } = useModalsActions();

  useEffect(() => {
    if (!exportInitiatedRef.current) {
      exportInitiatedRef.current = true;

      bulkExport({
        projectCuid,
        exportAudio,
        transcriptFormats,
        onError() {
          hideModal();
        },
        onSuccess() {
          window.location.href = redirectUrl;
        },
      });
    }
  }, [
    bulkExport,
    exportAudio,
    hideModal,
    projectCuid,
    projectName,
    redirectUrl,
    transcriptFormats,
  ]);

  return (
    <ExportModalContents
      icon={<Modal.Icon>{<ExternalLink width="40px" />}</Modal.Icon>}
      status="loading"
      title={'Exporting' + (integratorName ? ` to ${integratorName}` : '')}
    >
      <ShareExportingBody />
    </ExportModalContents>
  );
};

export default IntegratorExportModalContents;
