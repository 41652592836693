import {
  updateProjectTranscriptSpeaker,
  UpdateProjectTranscriptSpeakerArgs,
} from 'api/services';
import { useTranscriptId } from './useEditorTranscript';
import useUpdateTranscript from './useUpdateTranscript';
import { placeholderSegmentIds } from '.';

export type UseUpdateTranscriptSpeakerVariables = Pick<
  UpdateProjectTranscriptSpeakerArgs,
  'name' | 'segmentId' | 'speakerId'
>;

export default function useUpdateTranscriptSpeaker() {
  const { data: transcriptId } = useTranscriptId();

  return useUpdateTranscript<void, UseUpdateTranscriptSpeakerVariables>({
    mutationFn: ({ name, segmentId, speakerId }) => {
      const actualSegmentId = !segmentId
        ? undefined
        : placeholderSegmentIds.getActualId(segmentId);

      return updateProjectTranscriptSpeaker({
        name,
        segmentId: actualSegmentId,
        speakerId,
        transcriptId: transcriptId as number,
      });
    },
  });
}
