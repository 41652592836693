import { ProjectTranscript } from 'api';
import notifyError from 'components/notification';
import TranscriptOriginator from './TranscriptOriginator';
import { AsyncUndoableCommand, CommandOptions, CommandAction } from './types';

type ExecuteResult<TResult = undefined> =
  | undefined
  | (TResult extends undefined
      ? ProjectTranscript
      : [ProjectTranscript, TResult]);

export default abstract class AsyncUndoableTranscriptCommand<
  TExecuteResult = undefined,
  TUnexecuteResult = undefined,
> implements AsyncUndoableCommand
{
  private static defaultErrorHandler(action: CommandAction) {
    notifyError({
      heading: 'Error updating transcript',
    });
  }

  constructor(
    protected originator: TranscriptOriginator,
    protected opts?: CommandOptions<TExecuteResult, TUnexecuteResult>,
  ) {}

  /**
   * Called internally by `execute()` to apply the command to current version of
   * the transcript.
   *
   * @param transcript current snapshot of the transcript
   * @returns the new transcript
   */
  protected abstract executeTranscript(
    transcript: ProjectTranscript,
  ): ExecuteResult<TExecuteResult>;

  /**
   * Called internally by `unexecute()` to undo the command from the current version
   * of the transcript. This transcript snapshot will include changes made by
   * an earlier call to `executeTranscript()`
   *
   * @param transcript current snapshot of the transcript
   * @returns the new transcript
   */
  protected abstract unexecuteTranscript(
    transcript: ProjectTranscript,
  ): ExecuteResult<TUnexecuteResult>;

  public abstract commit(): Promise<void>;

  public abstract uncommit(): Promise<void>;

  protected onError(action: CommandAction): void {
    if (this.opts?.onCommitError) {
      this.opts.onCommitError(action);
    } else {
      AsyncUndoableTranscriptCommand.defaultErrorHandler(action);
    }
  }

  public execute() {
    const transcript = this.originator.createMemento();

    if (!transcript) {
      return;
    }

    const executeResult = this.executeTranscript(transcript);

    if (executeResult) {
      const [nextTranscript, result] = Array.isArray(executeResult)
        ? executeResult
        : [executeResult, undefined as TExecuteResult];

      this.originator.setMemento(nextTranscript);
      this.opts?.onExecuteSuccess?.(result);
    }
  }

  public unexecute() {
    const transcript = this.originator.createMemento();

    if (!transcript) {
      return;
    }

    const unexecuteResult = this.unexecuteTranscript(transcript);

    if (unexecuteResult) {
      const [nextTranscript, result] = Array.isArray(unexecuteResult)
        ? unexecuteResult
        : [unexecuteResult, undefined as TUnexecuteResult];

      this.originator.setMemento(nextTranscript);
      this.opts?.onUnexecuteSuccess?.(result);
    }
  }
}
