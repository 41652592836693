import {
  Card,
  CardHeader,
  IconButton,
  Spacer,
  breakpointDown,
  getThemeColor,
} from '@sparemin/blockhead';
import styled from 'styled-components';
import { IconWrapperStyleProps, ModalContainerProps } from './types';

const MODAL_HEADER_Z_INDEX = 2;

function getGutterSettings({
  $gutter = 0,
}: Pick<ModalContainerProps, '$gutter'>) {
  if (!$gutter) {
    return;
  }

  const gutterAsPixels = `${$gutter}px`;

  return `
    width: auto;
    height: auto;
    inset: ${gutterAsPixels};
    border-radius: 30px;
  `;
}
export const ModalContainer = styled(Card)<ModalContainerProps>`
  ${getGutterSettings};
`;

export const ModalHeader = styled(CardHeader)`
  padding-top: 45px;
  z-index: ${MODAL_HEADER_Z_INDEX};
`;

export const ModalIcon = styled(Spacer)<IconWrapperStyleProps>`
  width: 100px;
  height: 100px;
  border-radius: 100%;
  background-color: ${({ $backgroundColor = getThemeColor('actionPrimary') }) =>
    $backgroundColor};
  color: ${({ $color = getThemeColor('white') }) => $color};
`;

export const BackButton = styled(IconButton)`
  position: absolute;
  top: 55px;
  left: 55px;
  color: ${({ $color = getThemeColor('actionPrimary') }) => $color};
  z-index: ${MODAL_HEADER_Z_INDEX};

  ${breakpointDown('md')`
    top: 15px;
    left: 15px;
  `}
`;
