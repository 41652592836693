import { Spacer } from '@sparemin/blockhead';
import styled from 'styled-components';

export const Container = styled(Spacer).attrs({
  orientation: 'horizontal',
  align: 'center',
  justify: 'space-between',
  onMouseUp: (e: MouseEvent) => e.stopPropagation(),
})`
  width: 100%;
  padding: 14px 16px;
`;
