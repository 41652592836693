import { useCallback, useState } from 'react';

export default function useToggle(defaultValue = false) {
  const [value, setValue] = useState(defaultValue);

  const toggle = useCallback((nextValue?: boolean) => {
    setValue((v) => nextValue ?? !v);
  }, []);

  const toggleOn = useCallback(() => toggle(true), [toggle]);
  const toggleOff = useCallback(() => toggle(false), [toggle]);

  return { toggle, toggleOff, toggleOn, value };
}
