import { Button, Spacer } from '@sparemin/blockhead';
import React from 'react';
import Modal from 'components/Modal';
import { UpsellModal } from 'state/eventTracking';
import useUpsellModalContentsConfigProps from './useUpsellModalContentsConfig';

interface BaseUpsellModalFooterProps {
  modalName: UpsellModal;
  className?: string;
  children?: React.ReactNode;
  onSuccess?: () => void;
  cta?: string;
}

const BaseUpsellModalFooter: React.FC<BaseUpsellModalFooterProps> = ({
  modalName,
  className,
  children,
  cta = 'upgrade now',
  onSuccess,
}) => {
  const { anchorProps } = useUpsellModalContentsConfigProps({
    modalName,
    onSuccess,
  });

  return (
    <Modal.Footer className={className}>
      <Spacer orientation="vertical" space={2}>
        <Button as="a" variant="special" fluid {...anchorProps}>
          {cta}
        </Button>

        {children}
      </Spacer>
    </Modal.Footer>
  );
};

export default BaseUpsellModalFooter;
