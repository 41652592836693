import { Plus, getThemeColor, Heading, AlertCircle } from '@sparemin/blockhead';
import styled from 'styled-components';
import TextButton from 'components/TextButton';

export const PlusIcon = styled(Plus)`
  width: 12px;
  height: 12px;
  color: ${getThemeColor('actionPrimary')};
`;

export const Text = styled.span`
  color: ${getThemeColor('actionPrimary')};
`;

export const Button = styled(TextButton)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding: 16px;
`;

export const ErrorText = styled(Heading).attrs({
  level: 2,
  textTransform: 'none',
})`
  color: ${getThemeColor('d1')};
`;

export const AlertIcon = styled(AlertCircle)`
  width: 12px;
  height: 12px;
  color: ${({ theme }) => theme.palette.danger};
`;
