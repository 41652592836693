import { Link } from '@sparemin/blockhead';
import React from 'react';
import { DISCO_BROCHURE_URL } from 'config';
import useProjectLoaderFreeTierAnimation from '../useProjectLoaderFreeTierAnimation';
import * as S from './styles';

export interface PromoteMessageProps {}

const PromoteMessage: React.FC<PromoteMessageProps> = () => {
  const { promoteMessage } = useProjectLoaderFreeTierAnimation();

  return (
    <S.PromoteMessage {...promoteMessage}>
      These clips keep Headliner free & support the podcast community (
      <Link
        href={DISCO_BROCHURE_URL}
        target="_blank"
        rel="noopener"
        typography="text"
        variant="p"
      >
        promote your episode here
      </Link>
      ).
    </S.PromoteMessage>
  );
};

export default PromoteMessage;
