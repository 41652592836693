import { Spacer, UnloadingAudio } from '@sparemin/blockhead';
import { useCallback, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import FadingFooter from 'components/FadingFooter';
import { AudioPlayer, MediaElementAudioPlayer } from 'utils/audio';
import { AudioPlayerProvider } from '../AudioPlayerContext';
import { TIMEUPDATE_POLLING_INTERVAL_MILLIS } from '../constants';
import EditorOrchestrator from '../EditorOrchestrator';
import Playbar from '../Playbar';
import PromoPopper from '../PromoPopper';
import { useProjectAssetUrl, useProjectMediaType } from '../state';
import { EditTranscriptProvider } from '../state/commands';
import {
  useHasPromoPacks,
  usePromoPackStatus,
} from '../state/useGetPromoPacks';
import VideoPreview from '../VideoPreview';
import Modals from './Modals';
import * as S from './styles';
import TranscriptExplorerBar from './TranscriptExplorerBar';
import useTrackTranscriptPageLoaded from './useTrackTranscriptPageLoaded';

export interface TranscriptEditorProps {}

const TranscriptEditor: React.FC<TranscriptEditorProps> = () => {
  const [player, setPlayer] = useState<AudioPlayer>();
  const { data: assetUrl } = useProjectAssetUrl();
  const { data: projectMediaType } = useProjectMediaType();
  const { data: hasPromoPacks } = useHasPromoPacks();
  const { data: promoPackStatus } = usePromoPackStatus();

  useTrackTranscriptPageLoaded();

  const { ref, inView } = useInView({
    threshold: 1,
  });

  const handleAudioRef = useCallback((el: HTMLAudioElement) => {
    if (el) {
      setPlayer(
        new MediaElementAudioPlayer(el, TIMEUPDATE_POLLING_INTERVAL_MILLIS),
      );
      el.load();
    } else {
      setPlayer(undefined);
    }
  }, []);

  // TODO: figure out what to do if assetUrl is not defined
  return (
    <S.Container ref={ref}>
      {player && (
        <AudioPlayerProvider player={player}>
          <EditTranscriptProvider>
            <TranscriptExplorerBar />
            <EditorOrchestrator />
            <Spacer orientation="horizontal">
              <S.TranscriptContainer>
                <S.Transcript />
              </S.TranscriptContainer>

              {projectMediaType === 'video' && (
                <S.VideoContainer>
                  <VideoPreview />
                </S.VideoContainer>
              )}
            </Spacer>

            {hasPromoPacks && promoPackStatus === 'completed' && (
              <PromoPopper />
            )}

            <Playbar />
            <Modals />
            {!inView && <FadingFooter />}
          </EditTranscriptProvider>
        </AudioPlayerProvider>
      )}
      {assetUrl && <UnloadingAudio src={assetUrl} ref={handleAudioRef} />}
    </S.Container>
  );
};

export default TranscriptEditor;
