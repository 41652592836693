import { PulseLoader } from '@sparemin/blockhead';
import React from 'react';
import ProjectLoaderTakeoverView from './ProjectLoaderTakeoverView';

export interface ProjectLoaderSpinnerProps {
  className?: string;
}

const ProjectLoaderSpinner = React.forwardRef<
  HTMLDivElement,
  ProjectLoaderSpinnerProps
>(({ className }, ref) => (
  <ProjectLoaderTakeoverView className={className} justify="center" ref={ref}>
    <PulseLoader />
  </ProjectLoaderTakeoverView>
));

export default ProjectLoaderSpinner;
