import { Heading } from '@sparemin/blockhead';
import SpinningSparklyHeady from 'assets/spinning-sparkly-heady.gif';
import { regeneratingViewAnimationProps } from './animation';
import * as S from './styles';

export interface RegeneratingViewProps {}

const RegeneratingView: React.FC<RegeneratingViewProps> = () => {
  return (
    <S.Root {...regeneratingViewAnimationProps}>
      <img
        src={SpinningSparklyHeady}
        alt="sparkly heady"
        width="134px"
        height="134px"
      />

      <Heading level={1}>Regenerating your assets</Heading>

      <Heading level={2}>
        We’ll remember this feedback to improve the quality next time!
      </Heading>
    </S.Root>
  );
};

export default RegeneratingView;
