import { PLAN_SERVICE_URL } from 'config';
import usePostMessageHandler from 'hooks/usePostMessageHandler';
import { isCheckoutMessage } from './utils';

export interface UseCheckoutPostMessageConfig {
  onSuccess?: (isSubscriptionPurchased: boolean) => void;
}

export default function useCheckoutPostMessage({
  onSuccess,
}: UseCheckoutPostMessageConfig) {
  return usePostMessageHandler({
    origin: PLAN_SERVICE_URL,
    validator: isCheckoutMessage,
    onMessageReceived: (event) =>
      onSuccess?.(event.data.data.isSubscriptionPurchased),
  });
}
