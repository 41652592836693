import { useCallback, useEffect } from 'react';
import { create } from 'zustand';
import {
  lastSuccessfulActionSelector,
  wordsContextMenuConfigSelector,
} from './selectors';
import { ContextMenuState, ContextMenuStore } from './types';
import { isSpeakerContextMenuConfig } from './utils';

const defaultState: ContextMenuState = {
  canOpenMenu: true,
  config: undefined,
  isOpen: false,
  lastSuccessfulAction: undefined,
  target: undefined,
};

const useContextMenuStore = create<ContextMenuStore>()((set) => ({
  ...defaultState,
  actions: {
    blockContextMenu: () => {
      set({
        config: undefined,
        isOpen: false,
        canOpenMenu: false,
        target: undefined,
      });
    },
    closeContextMenu: () => {
      set({ isOpen: false, target: undefined, config: undefined });
    },
    onAction: (completedAction) => {
      set({ lastSuccessfulAction: completedAction });
    },
    openContextMenu: (trigger, config) => {
      set((s) =>
        s.canOpenMenu ? { isOpen: true, target: trigger, config } : s,
      );
    },
    reset: () => {
      set(defaultState);
    },
    unblockContextMenu: () => {
      set({ canOpenMenu: true });
    },
  },
}));

export const useContextMenuActions = () =>
  useContextMenuStore((s) => s.actions);

export function useContextMenuSubscription(
  cb: (current: ContextMenuStore, prev: ContextMenuStore) => void,
) {
  useEffect(() => useContextMenuStore.subscribe(cb), [cb]);
}

export const useContextMenuConfig = () => useContextMenuStore((s) => s.config);

export const useWordsContextMenuConfig = () =>
  useContextMenuStore(wordsContextMenuConfigSelector);

export const useSpeakerContextMenuConfig = () =>
  useContextMenuStore((s) =>
    isSpeakerContextMenuConfig(s.config) ? s.config : undefined,
  );

export const useContextMenuIsOpen = () => useContextMenuStore((s) => s.isOpen);

export const useContextMenuTarget = () => useContextMenuStore((s) => s.target);

export const useContextMenuLastSuccessfulAction = () =>
  useContextMenuStore(lastSuccessfulActionSelector);

// gets the lastSuccessfulAction in a non-reactive way
export const useGetLastSuccessfulAction = () => {
  return useCallback(() => {
    return lastSuccessfulActionSelector(useContextMenuStore.getState());
  }, []);
};
