import { Button, Heading, HeadySparkle } from '@sparemin/blockhead';
import { useCallback } from 'react';
import notifyError from 'components/notification';
import useDownloadPromoPack from 'pages/TranscriptEditorPage/state/useDownloadPromoPack';
import { useEventTracking } from 'state/eventTracking';
import * as S from './styles';

export interface WelcomeTabPanelBannerProps {}

const WelcomeTabPanelBanner: React.FC<WelcomeTabPanelBannerProps> = () => {
  const { mutate: downloadPromoPack, status } = useDownloadPromoPack();
  const { trackPromoPackModalAction } = useEventTracking();

  const handleDownloadPromoPack = useCallback((): void => {
    trackPromoPackModalAction('DownloadAllAssets', 'all');

    downloadPromoPack(
      {},
      {
        onError: () => {
          notifyError();
        },
      },
    );
  }, [downloadPromoPack, trackPromoPackModalAction]);

  return (
    <S.Banner>
      <HeadySparkle width={151} height={118} />

      <S.BannerContent>
        <Heading level={1}>Welcome to your Promo Pack!</Heading>

        <Heading level={2}>
          It contains everything you need to improve your podcast
          discoverability.
        </Heading>
      </S.BannerContent>

      <Button
        onPress={handleDownloadPromoPack}
        isDisabled={status === 'loading'}
      >
        Download all assets
      </Button>
    </S.Banner>
  );
};

export default WelcomeTabPanelBanner;
