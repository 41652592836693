import { GoogleDriveFile } from '@sparemin/api-sdk';
import { MediaImportedEvent } from '@sparemin/media-import';
import { useCallback } from 'react';
import { useModalsActions } from 'state/modal';

export interface UseHandleMediaAcceptedResult {
  onMediaAccepted?: (args: MediaImportedEvent) => void;
}

export default function useHandleMediaAccepted(
  onMediaAccepted?: (
    file: GoogleDriveFile,
    googleDriveFileId: string,
    providerUserId: string,
  ) => void,
): UseHandleMediaAcceptedResult {
  const { hideModal } = useModalsActions();

  const handleMediaAccepted = useCallback(
    (args: MediaImportedEvent): void => {
      const { pluginId, payload } = args;
      if (pluginId === 'googleDrive') {
        const { file, user } = payload;

        onMediaAccepted?.(file, file.id, user.providerUserId);
        hideModal();
      }
    },
    [hideModal, onMediaAccepted],
  );

  return {
    onMediaAccepted: handleMediaAccepted,
  };
}
