import { getThemeColor, Link, whileHovering } from '@sparemin/blockhead';
import styled from 'styled-components';

const TextButton = styled(Link).attrs({
  forwardedAs: 'button',
  elementType: 'button',
  typography: 'text',
  variant: 'button',
})`
  color: ${getThemeColor('actionTertiary')};
  display: block;

  ${whileHovering`
    color: ${getThemeColor('actionPrimary')};
  `};
`;

export default TextButton;
