import { produce } from 'immer';
import { useMutation, useQueryClient } from 'react-query';
import {
  UpdateEddyPrefsArgs,
  updateEddyPrefs,
  UserPreferences,
} from 'api/services/headlinerUser';
import { useAuth } from 'state/auth';
import { MutationOptions } from 'types';

import { userPrefQueryKeys } from './queryKeys';

interface UseUpdateEddyPrefsContext {
  previousData: UserPreferences;
}

/**
 * Optimistic update is used here because some components can't wait for the
 * network request to complete before getting the new values
 */
export default function useUpdateEddyPrefs(
  opts?: Omit<
    MutationOptions<void, UpdateEddyPrefsArgs>,
    'onMutate' | 'onError' | 'onSettled'
  >,
) {
  const queryClient = useQueryClient();
  const { userId } = useAuth();
  const queryKey = userPrefQueryKeys.myUserPrefs(userId);

  return useMutation<
    void,
    unknown,
    UpdateEddyPrefsArgs,
    UseUpdateEddyPrefsContext
  >({
    mutationFn: updateEddyPrefs,
    onMutate: async (args) => {
      await queryClient.cancelQueries(queryKey);

      const previousData = queryClient.getQueryData<UserPreferences>(
        queryKey,
      ) ?? { eddyPrefs: {} };

      const newState = produce(previousData, (d) => {
        const prefKeys = Object.keys(args) as Array<keyof UpdateEddyPrefsArgs>;

        prefKeys.forEach((prefKey) => {
          const newValue = args[prefKey];

          if (newValue !== undefined) {
            d.eddyPrefs[prefKey] = {
              default: newValue,
              valueType: 'boolean',
            };
          }
        });
      });

      queryClient.setQueryData(queryKey, newState);

      return { previousData };
    },
    onError: (_, __, ctx) => {
      if (ctx?.previousData) {
        queryClient.setQueryData(queryKey, ctx.previousData);
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(queryKey);
    },
    ...opts,
  });
}
