import { Draft } from 'immer';
import {
  ProjectTranscript,
  TranscriptEdit,
  TranscriptUpdateTextEdit,
  TranscriptWord,
} from 'api';
import { DELETION_TIME_SHIFT_MILLIS } from '../../constants';
import { getWordMinStartMillis } from '../../utils';
import {
  TranscriptWordWithIndex,
  wordDictionarySelector,
  wordListSelector,
  WordsDictionary,
} from '../selectors';

export function getDraftWord(
  wordId: number,
  transcript: Draft<ProjectTranscript>,
  wordsById: WordsDictionary | undefined = {},
) {
  const word = wordsById[wordId];

  if (word) {
    const segment = transcript.segments.find((s) => s.id === word.segmentId);
    return segment?.words.find((w) => w.id === word.id);
  }

  for (let i = 0; i < transcript.segments.length; i += 1) {
    for (let j = 0; j < transcript.segments[i].words.length; j += 1) {
      const draftWord = transcript.segments[i].words[j];
      if (draftWord.id === wordId) {
        return draftWord;
      }
    }
  }
}

export function adjustDeletionStartTime(
  deletedWord: TranscriptWord,
  prevWord: TranscriptWord | undefined,
) {
  const minStartMillis = getWordMinStartMillis(prevWord);
  return Math.max(
    minStartMillis,
    deletedWord.startMillis - DELETION_TIME_SHIFT_MILLIS,
  );
}

export function createInverseEdits(
  edits: TranscriptEdit[],
  transcript: ProjectTranscript | undefined,
): TranscriptEdit[] {
  if (edits.length === 0 || !transcript) {
    return [];
  }

  const wordsById = wordDictionarySelector(transcript);

  const inverseEdits = [...edits.reverse()].map((edit) => {
    switch (edit.action) {
      case 'delete': {
        return {
          action: 'undelete',
          actionDetail: {},
          wordId: edit.wordId,
        };
      }

      case 'undelete': {
        return {
          action: 'delete',
          actionDetail: {},
          wordId: edit.wordId,
        };
      }

      case 'update': {
        const modifiedProperties = Object.keys(edit.actionDetail) as Array<
          keyof TranscriptUpdateTextEdit['actionDetail']
        >;
        const currentWord = wordsById[edit.wordId];
        const actionDetail = modifiedProperties.reduce(
          <T extends keyof TranscriptUpdateTextEdit['actionDetail']>(
            acc: TranscriptUpdateTextEdit['actionDetail'],
            prop: T,
          ) => {
            acc[prop] = currentWord[prop];
            return acc;
          },
          {},
        );

        return {
          actionDetail,
          action: 'update',
          wordId: edit.wordId,
        };
      }

      default: {
        if (process.env.NODE_ENV === 'development') {
          throw new Error(
            `Cannot calculate inverse of "${edit.action}" action`,
          );
        }
        return undefined;
      }
    }
  });

  return inverseEdits.filter(Boolean) as TranscriptEdit[];
}

export function createWordsDeletionEdits(
  transcript: ProjectTranscript,
  deletionWordIds: number[],
) {
  const wordsById = wordDictionarySelector(transcript);
  const words = wordListSelector(transcript);

  // make sure deletions are processed in-order since the start time of a
  // deleted word is constrained by the start time of the word before it -
  // which might not be the word's original start time if the previous word
  // was also deleted and shifted
  const sortedDeletions = deletionWordIds.reduce((acc, id) => {
    const word = wordsById?.[id];

    if (word) {
      acc.push(word);
    }

    return acc;
  }, [] as TranscriptWordWithIndex[]);

  sortedDeletions.sort((w1, w2) => w1.startMillis - w2.startMillis);

  // create list of edits.
  // delete word and shift the start time
  return sortedDeletions.reduce((acc, word) => {
    if (!word.isDeleted) {
      acc.push({ action: 'delete', wordId: word.id, actionDetail: {} });

      const prevWord = wordsById[words[word.index - 1]?.id];

      if (prevWord) {
        const startMillis = adjustDeletionStartTime(word, prevWord);
        acc.push({
          action: 'update',
          wordId: word.id,
          actionDetail: { startMillis },
        });
      }
    }

    return acc;
  }, [] as TranscriptEdit[]);
}
