import { useCallback } from 'react';
import useEmailAddress from 'state/user/useEmailAddress';
import { showZendeskWidget } from './zendeskWidget';

export default function useZendesk() {
  const { data: emailAddress } = useEmailAddress();

  return {
    openZendeskWidget: useCallback(() => {
      showZendeskWidget(emailAddress);
    }, [emailAddress]),
  };
}
