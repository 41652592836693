import React, { useEffect } from 'react';
import { useAuth } from 'state/auth';
import { setApiToken, configure } from '../http';

export interface AxiosGlobalConfigurationProps {}

/**
 * The axios instance contains interceptors which need the sparemin access token.
 * This component simply listens for `accessToken` changes and then uses it to
 * sync axios's state.
 *
 * This functionality is implemented as a component that returns null, as opposed
 * to a hook, so that it can be used directly within the AuthContext without having
 * to wrap the hook in its own component.  For example:
 *
 * ```
 * const App = () => (
 *  <AuthContext>
 *    // if this were a hook, we could not use the hook directly inside of App
 *    // because we need access to the auth context, so we'd have to wrap the hook
 *    // in a component and render it here
 *    <AxiosGlobalConfiguration />
 *  </AuthContext>
 * )
 * ```
 */
const AxiosGlobalConfiguration: React.FC<
  AxiosGlobalConfigurationProps
> = () => {
  const { accessToken, logout, replaceToken } = useAuth();

  useEffect(() => {
    configure(logout);
  }, [logout]);

  useEffect(() => {
    setApiToken({ token: accessToken, onTokenRenewed: replaceToken });
  }, [accessToken, replaceToken]);

  return null;
};

export default AxiosGlobalConfiguration;
