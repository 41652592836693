import { useIsBasicOrFreeTier } from 'state/pricing';
import BaseAdjustmentsBar, {
  BaseAdjustmentsBarProps,
} from '../BaseAdjustmentsBar/BaseAdjustmentsBar';
import { useTabPanel } from '../BaseTabPanel';
import * as S from './styles';

export interface CustomFeedbackAdjustmentsBarProps
  extends Omit<BaseAdjustmentsBarProps, 'children'> {
  placeholder?: string;
}

const CustomFeedbackAdjustmentsBar: React.FC<
  CustomFeedbackAdjustmentsBarProps
> = ({ placeholder = 'What would you like to change?', ...props }) => {
  const { adjustmentValues, onAdjustmentValueChange } = useTabPanel();
  const { data: isBasicOrFreeTier } = useIsBasicOrFreeTier();

  return (
    <BaseAdjustmentsBar {...props}>
      <S.FeebackInputContainer>
        <S.CustomFeedbackInput
          value={adjustmentValues?.customFeedback || ''}
          aria-label={placeholder}
          placeholder={placeholder}
          onChange={(value) =>
            onAdjustmentValueChange({ customFeedback: value })
          }
          isDisabled={isBasicOrFreeTier}
        />
      </S.FeebackInputContainer>
    </BaseAdjustmentsBar>
  );
};

export default CustomFeedbackAdjustmentsBar;
