import { QueryStatus } from 'react-query';

export const EXPORT_MODAL_TITLES_BY_STATUS: Record<QueryStatus, string> = {
  idle: 'Export your project',
  error: 'Export your project',
  loading: 'Exporting',
  success: 'Export complete',
};

export const EXPORT_MODAL_SUBTITLES_BY_STATUS: Record<QueryStatus, string> = {
  idle: '',
  error: '',
  loading:
    'Important: Please keep this dialog open. Closing will halt your export.',
  success: 'Your download should begin automatically.',
};
