import { useEffect, useRef } from 'react';
import notifyError from 'components/notification';

import { useAIOptionsActions, useIsAIOptOutShown } from 'state/aiOptions';
import {
  useHasSubmittedRelatedPodcast,
  useIsNameSuggestionApplied,
  useProjectCuid,
  useProjectStatus,
} from '../useEditorProject';
import useUpdateProjectAISettings from '../useUpdateProjectAISettings';

/**
 * Applies AI Options from the AI Opt Out Screen, specifically those which need
 * to wait for the transcript to complete before applying.
 *
 * There are a few conditions to handle here -
 *
 * If the user sees the opt-out screen, we must honor the selections they made on
 * that screen, however if they don't see the screen (they're an integrator, refreshed
 * the page during transcription, etc.), then we must use the values from the API.
 *
 * In the end we have to wait for all of the following to be completed before
 * deciding whether or not to take action:
 *  - fetch the user's preferences
 *  - wait for transcription to finish
 *  - wait for the user to complete the opt-out screen, if applicable
 *
 * TODO - might be better to add opt-out values to the zustand store
 */
export default function useApplyNameSuggestion(onSuccess: () => void) {
  const { setAINameAppliedProjectCuid } = useAIOptionsActions();
  const { data: hasSubmittedRelatedPodcast } = useHasSubmittedRelatedPodcast();
  const isAIOptOutShown = useIsAIOptOutShown();
  const isUpdatedRef = useRef(false);

  const {
    data: isNameSuggestionApplied,
    status: isNameSuggestionAppliedStatus,
  } = useIsNameSuggestionApplied();

  const { data: projectStatus } = useProjectStatus();

  const { data: projectCuid = '' } = useProjectCuid();
  const { mutateAsync: updateProjectAISettings } = useUpdateProjectAISettings();

  useEffect(() => {
    const execute = async () => {
      if (
        isUpdatedRef.current ||
        hasSubmittedRelatedPodcast === undefined ||
        isNameSuggestionAppliedStatus !== 'success' ||
        projectStatus !== 'completed'
      ) {
        return;
      }

      isUpdatedRef.current = true;

      // name update is only performed when isNameSuggestionApplied is strictly false
      // if it is null/undefined it means the project is legacy and the flag was never set
      // if it is true it means the suggestions has been already applied
      if (isNameSuggestionApplied === false) {
        try {
          await updateProjectAISettings({ isNameSuggestionApplied: true });
          // saves the project cuid for which the name was updated to the ai store.
          setAINameAppliedProjectCuid(projectCuid);
        } catch {
          notifyError({ heading: 'Error applying AI features to project' });
        } finally {
          onSuccess();
        }
      } else {
        onSuccess();
      }
    };

    execute();
  }, [
    hasSubmittedRelatedPodcast,
    isAIOptOutShown,
    isNameSuggestionApplied,
    isNameSuggestionAppliedStatus,
    onSuccess,
    projectCuid,
    projectStatus,
    setAINameAppliedProjectCuid,
    updateProjectAISettings,
  ]);
}
