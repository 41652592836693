import React from 'react';

import ConnectedModal from 'components/ConnectedModal';

import { useModalParams } from 'state/modal';
import ProjectLanguageSelectorModalContents from './ProjectLanguageSelectorModalContents';

const ProjectLanguageSelectorModal: React.FunctionComponent = () => {
  const params = useModalParams('ProjectLanguageSelector');

  return (
    <ConnectedModal name="ProjectLanguageSelector">
      <ProjectLanguageSelectorModalContents params={params} />
    </ConnectedModal>
  );
};

export default ProjectLanguageSelectorModal;
