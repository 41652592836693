import { useEffect } from 'react';
import { useHasVisibleModal } from 'state/modal';
import { useAudioPlayer } from '../AudioPlayerContext';

export default function useModalOrchestration() {
  const hasVisibleModal = useHasVisibleModal();
  const { player } = useAudioPlayer();

  useEffect(() => {
    // Audio player should be disabled when a modal is opened.
    if (hasVisibleModal && !player.isDisabled) {
      return player.disablePlayer();
    } else {
      player.enablePlayer();
    }
  }, [hasVisibleModal, player]);
}
