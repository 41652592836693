import { Edgebar, Tooltip } from '@sparemin/blockhead';
import React, { Key, useCallback, useState } from 'react';
import { promoPackModalTabsOrder, promoPackTabs } from './constants';
import { PromoPackModalProvider } from './PromoPackModalContext';
import * as S from './styles';
import { PromoPackTabType } from './types';

export interface PromoPackModalContentsProps {}

const PromoPackModalContents: React.FC<PromoPackModalContentsProps> = () => {
  const [selectedKey, setSelectedKey] = useState<PromoPackTabType>('welcome');

  const handleSelectionChange = useCallback((key: Key): void => {
    const newKey = key as PromoPackTabType;

    setSelectedKey(newKey);
  }, []);

  return (
    <PromoPackModalProvider promoPackTabs={promoPackTabs}>
      <S.Root fullScreen gutter={24}>
        <Edgebar
          aria-label="Promo Pack assets"
          orientation="vertical"
          position="left"
          selectedKey={selectedKey}
          onSelectionChange={handleSelectionChange}
        >
          {promoPackModalTabsOrder.map((tab) => {
            const { title, icon, content } = promoPackTabs[tab];

            return (
              <Edgebar.Item
                key={tab}
                textValue={title}
                title={
                  // React-aria's Tooltip component callbacks stop event propagation,
                  // which interferes with the click handling for the list items.
                  // Adding an unstyled button as a wrapper for the list item and
                  // manually handling the click events resolves the issue.
                  <Tooltip contents={title} placement="right" strategy="fixed">
                    <S.IconButton onPress={() => handleSelectionChange(tab)}>
                      <S.EdgebarIconContainer $type={tab}>
                        {icon}
                      </S.EdgebarIconContainer>
                    </S.IconButton>
                  </Tooltip>
                }
              >
                {content}
              </Edgebar.Item>
            );
          })}
        </Edgebar>
      </S.Root>
    </PromoPackModalProvider>
  );
};

export default PromoPackModalContents;
