import { useTheme } from '@sparemin/blockhead';

export interface UseActionButtonsColorsResult {
  VIDEO_BTN_COLOR: string;
  AUDIO_BTN_COLOR: string;
  DOC_BTN_COLOR: string;
  VTT_BTN_COLOR: string;
  SRT_BTN_COLOR: string;
  PDF_BTN_COLOR: string;
  URLS_BTN_COLOR: string;
}

export default function useActionButtonsColors(): UseActionButtonsColorsResult {
  const theme = useTheme();

  return {
    VIDEO_BTN_COLOR: theme.palette.actionPrimary.main,
    AUDIO_BTN_COLOR: theme.palette.actionSecondary.main,
    DOC_BTN_COLOR: theme.palette.s2,
    VTT_BTN_COLOR: theme.palette.s5,
    SRT_BTN_COLOR: theme.palette.s3,
    PDF_BTN_COLOR: theme.palette.actionTertiary.main,
    URLS_BTN_COLOR: theme.palette.s3,
  };
}
