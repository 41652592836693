import { AxiosError } from 'axios';
import { useCallback } from 'react';
import { UseQueryResult } from 'react-query';
import { useParams } from 'react-router-dom';
import { EdgeAssetThumbnail, EdgeAssetType, Project } from 'api/services';
import {
  edgeAssetSelector,
  hasSubmittedRelatedPodcastSelector,
  isFillerWordsRemovedSelector,
  isNameSuggestionAppliedSelector,
  projectAssetUrlSelector,
  projectCreationProgressSelector,
  projectCuidSelector,
  projectMediaTypeSelector,
  projectNameSelector,
  projectStatusSelector,
  projectTranscriptSelector,
  projectUrlTraceIdSelector,
  projectWaveformUrlSelector,
  edgeAssetThumbnailSelector,
  hasEdgeAssetSelector,
  edgeAssetStatusSelector,
  edgeAssetIdSelector,
} from './selectors';
import useGetProject, { UseGetProjectOptions } from './useGetProject';

export interface TranscriptEditorPageParams {
  projectCuid: string;
  [key: string]: string;
}

export type UseEditorProjectSelectorOptions<TData> = Pick<
  UseGetProjectOptions<TData>,
  'select' | 'waitForAssets'
>;

/**
 * A wraper around the base project query hook which injects the project cuid
 * read from the page's query params
 */
export default function useEditorProject<TData = Project>(
  opts?: UseEditorProjectSelectorOptions<TData>,
): UseQueryResult<TData, AxiosError> {
  const { projectCuid } = useParams<TranscriptEditorPageParams>();

  return useGetProject<TData>(projectCuid, {
    ...opts,
    staleTime: Infinity,
  });
}

export const useProjectCreationProgress = () =>
  useEditorProject({ select: projectCreationProgressSelector });

export const useProjectMediaType = () =>
  useEditorProject({ select: projectMediaTypeSelector });

export const useProjectCuid = () =>
  useEditorProject({ select: projectCuidSelector });

export const useProjectName = () =>
  useEditorProject({ select: projectNameSelector });

export const useProjectAssetUrl = () =>
  useEditorProject({ select: projectAssetUrlSelector });

export const useProjectWaveformUrl = () =>
  useEditorProject({ select: projectWaveformUrlSelector });

export const useProjectUrlTraceId = () =>
  useEditorProject({ select: projectUrlTraceIdSelector });

export const useProjectStatus = () =>
  useEditorProject({ select: projectStatusSelector });

export const useEditorProjectTranscript = () =>
  useEditorProject({ select: projectTranscriptSelector });

export const useIsNameSuggestionApplied = () =>
  useEditorProject({ select: isNameSuggestionAppliedSelector });

export const useIsFillerWordsRemoved = () =>
  useEditorProject({ select: isFillerWordsRemovedSelector });

export const useHasSubmittedRelatedPodcast = () =>
  useEditorProject({
    select: hasSubmittedRelatedPodcastSelector,
  });

export const useEdgeAsset = (edgeAssetType: EdgeAssetType) =>
  useEditorProject({
    select: useCallback(
      (data: Project) => edgeAssetSelector(data, edgeAssetType),
      [edgeAssetType],
    ),
  });

export const useEdgeAssetThumbnail = (variant: EdgeAssetType) =>
  useEditorProject<EdgeAssetThumbnail | undefined>({
    select: useCallback(
      (data: Project) => edgeAssetThumbnailSelector(data, variant),
      [variant],
    ),
  });

export const useHasEdgeAsset = (edgeAssetType: EdgeAssetType) =>
  useEditorProject({
    select: useCallback(
      (data: Project) => hasEdgeAssetSelector(data, edgeAssetType),
      [edgeAssetType],
    ),
  });

export const useCanOpenProject = () => {
  const result = useEditorProject();
  const { isSuccess, isError, error } = result;

  const isAuthorized =
    !isSuccess && !isError ? undefined : error?.response?.status !== 403;

  return { ...result, data: isAuthorized };
};

export const useEdgeAssetStatus = (edgeAssetType: EdgeAssetType) =>
  useEditorProject({
    select: useCallback(
      (data: Project) => edgeAssetStatusSelector(data, edgeAssetType),
      [edgeAssetType],
    ),
  });

export const useEdgeAssetId = (edgeAssetType: EdgeAssetType) =>
  useEditorProject({
    select: useCallback(
      (data: Project) => edgeAssetIdSelector(data, edgeAssetType),
      [edgeAssetType],
    ),
  });
