import { PressEvent } from '@react-types/shared';
import { ButtonBase } from '@sparemin/blockhead';
import { useCallback, useEffect, useRef } from 'react';
import { useSegmentSpeaker } from 'pages/TranscriptEditorPage/state';
import Tooltip from 'pages/TranscriptEditorPage/Tooltip';
import { useEventTracking } from 'state/eventTracking';
import {
  useContextMenuActions,
  useGetLastSuccessfulAction,
} from '../../state/contextMenu';
import * as S from './styles';

export interface SpeakerBadgeProps {
  className?: string;
  segmentId: number;
}

const SpeakerBadge: React.FC<SpeakerBadgeProps> = ({
  className,
  segmentId,
}) => {
  const { data: speaker } = useSegmentSpeaker(segmentId);
  const { openContextMenu } = useContextMenuActions();
  const { trackSpeakerLabelClick } = useEventTracking();
  const getLastSucessfulAction = useGetLastSuccessfulAction();

  const badgeRef = useRef<HTMLButtonElement>(null);

  // on mount, check if the last successful action was an add-segment action and
  // if so, open the edit-speaker menu.
  // note that the useGetLastSuccessfulAction callback is a stable reference
  useEffect(() => {
    const completedAction = getLastSucessfulAction();

    if (
      completedAction?.type === 'add-segment' &&
      completedAction.newSegmentId === segmentId &&
      badgeRef.current
    ) {
      openContextMenu(badgeRef.current, {
        defaultUpdateAll: false,
        segmentId: completedAction.newSegmentId,
      });
    }
  }, [getLastSucessfulAction, openContextMenu, segmentId]);

  // generally all click, highlight, etc. events are handled by useTextSelection,
  // however react-aria's button swallows events by calling preventDefault, meaning
  // that clicking on this button won't bubble up to the global mouseup handler and so
  // cannot be handled by useTextSelection
  const handlePress = useCallback(
    (e: PressEvent) => {
      const { target } = e;

      if (speaker?.id !== undefined) {
        trackSpeakerLabelClick();
        openContextMenu(target, { segmentId });
      }
    },
    [openContextMenu, segmentId, speaker?.id, trackSpeakerLabelClick],
  );

  return !speaker?.id ? null : (
    <S.Root className={className}>
      <Tooltip contents="Edit speaker">
        <S.Badge
          color="primary"
          forwardedAs={ButtonBase}
          onPress={handlePress}
          ref={badgeRef}
        >
          {speaker.name}
        </S.Badge>
      </Tooltip>
    </S.Root>
  );
};

export default SpeakerBadge;
