import { produce } from 'immer';
import { useQuery } from 'react-query';
import { getProjectTranscript, ProjectTranscript } from 'api/services';
import { useAuth } from 'state/auth';
import { QueryOptions } from 'types/react-query';
import { eddyTranscriptQueryKeys } from './queryKeys';
import { placeholderChapterIds, placeholderSegmentIds } from '.';

type QueryKey = ReturnType<typeof eddyTranscriptQueryKeys.transcript>;

export type UseGetTranscriptOptions<TData = ProjectTranscript> = QueryOptions<
  ProjectTranscript,
  TData,
  QueryKey
>;

async function queryFn(transcriptId: number) {
  const transcript = await getProjectTranscript(transcriptId);
  return produce(transcript, (draft) => {
    draft.segments.forEach((seg) => {
      const placeholderId = placeholderSegmentIds.getPlaceholderId(seg.id);

      if (placeholderId) {
        seg.id = placeholderId;
      }
    });

    draft.chapters.forEach((chapter) => {
      const placeholderId = placeholderChapterIds.getPlaceholderId(chapter.id);

      if (placeholderId) {
        chapter.id = placeholderId;
      }
    });
  });
}

export default function useGetTranscript<TData = ProjectTranscript>(
  transcriptId: number,
  opts?: UseGetTranscriptOptions<TData>,
) {
  const { userId } = useAuth();

  return useQuery<ProjectTranscript, unknown, TData, QueryKey>(
    eddyTranscriptQueryKeys.transcript(userId, transcriptId),
    ({ queryKey: [{ transcriptId: id }] }) => queryFn(id as number),
    {
      ...opts,
      enabled: !!transcriptId && (opts?.enabled ?? true),
      meta: {
        errorMessage: 'Error loading transcript',
      },
    },
  );
}
