import { Heading, Spacer } from '@sparemin/blockhead';
import * as S from './styles';

export interface EddyIntroductionProps {}

const EddyIntroduction: React.FC<EddyIntroductionProps> = () => {
  return (
    <S.Root>
      <S.Icon />

      <Spacer
        orientation="vertical"
        align="flex-start"
        justify="center"
        space={1.5}
      >
        <Heading level={1}>
          Hi! I’m Eddy, your personal podcast assistant.
        </Heading>

        <Heading level={2}>
          I can ✍️{' '}
          <S.WordHighlight $color="actionPrimary">transcribe</S.WordHighlight>{' '}
          your podcast while generating 💬{' '}
          <S.WordHighlight $color="actionSecondary">show notes</S.WordHighlight>
          , 🎨 <S.WordHighlight $color="s2">episode art</S.WordHighlight>, 🔑{' '}
          <S.WordHighlight $color="s5">keywords</S.WordHighlight> and more.
        </Heading>
      </Spacer>
    </S.Root>
  );
};

export default EddyIntroduction;
