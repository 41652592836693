import { useIsBasicOrFreeTier } from 'state/pricing';
import BaseAdjustmentsBar, {
  BaseAdjustmentsBarProps,
} from '../BaseAdjustmentsBar/BaseAdjustmentsBar';
import { useTabPanel } from '../BaseTabPanel';
import DefaultRegenerationOptions from './DefaultRegenerationOptions';
import * as S from './styles';

export interface StructuredAdjustmentsBarProps
  extends Omit<BaseAdjustmentsBarProps, 'children'> {
  regenerationOptions?: React.ReactNode;
  showIncludeChapters?: boolean;
  placeholder?: string;
}

const StructuredAdjustmentsBar: React.FC<StructuredAdjustmentsBarProps> = ({
  regenerationOptions = <DefaultRegenerationOptions />,
  showIncludeChapters = false,
  placeholder = 'Add any additional instructions here (optional)',
  ...props
}) => {
  const { adjustmentValues, onAdjustmentValueChange } = useTabPanel();
  const { data: isBasicOrFreeTier } = useIsBasicOrFreeTier();

  return (
    <BaseAdjustmentsBar {...props}>
      <S.Root>
        {regenerationOptions && (
          <S.RegenerationOptionsContainer>
            {regenerationOptions}
          </S.RegenerationOptionsContainer>
        )}

        <S.BottomOptions>
          <S.CustomFeedbackInput
            value={adjustmentValues?.customFeedback || ''}
            onChange={(value) =>
              onAdjustmentValueChange({ customFeedback: value })
            }
            aria-label={placeholder}
            placeholder={placeholder}
            $fullWidth={!showIncludeChapters}
            isDisabled={isBasicOrFreeTier}
          />

          {showIncludeChapters && (
            <S.IncludeChaptersCheckbox
              isSelected={adjustmentValues?.includeChapters}
              onChange={(value) =>
                onAdjustmentValueChange({ includeChapters: value })
              }
            >
              Include episode chapters
            </S.IncludeChaptersCheckbox>
          )}
        </S.BottomOptions>
      </S.Root>
    </BaseAdjustmentsBar>
  );
};

export default StructuredAdjustmentsBar;
