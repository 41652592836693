import { Button, Checkbox, Heading } from '@sparemin/blockhead';
import * as S from './styles';

export interface EditContentContainerProps {
  title?: string;
  /**
   * Button placed in the top-right corner of the card.
   */
  actionButton?: React.ReactNode;
  showUpdateAllButton?: boolean;
  updateAllText?: string;
  updateAll?: boolean;
  submitButtonText?: string;
  children?: React.ReactNode;
  onUpdateAll?: (isSelected: boolean) => void;
}

const EditContentContainer: React.FC<EditContentContainerProps> = ({
  title,
  actionButton,
  showUpdateAllButton,
  updateAllText = 'Update all instances',
  updateAll,
  submitButtonText = 'Correct',
  children,
  onUpdateAll,
}) => {
  return (
    <>
      <S.Container>
        <Heading level={3}>{title}</Heading>

        {actionButton}
      </S.Container>

      {children}

      <S.Container>
        {showUpdateAllButton && (
          <Checkbox onChange={onUpdateAll} isSelected={updateAll}>
            {updateAllText}
          </Checkbox>
        )}

        <Button size="small" type="submit">
          {submitButtonText}
        </Button>
      </S.Container>
    </>
  );
};

export default EditContentContainer;
