import { KeyboardEvent } from '@react-types/shared';
import { InlineTextFieldProps } from '@sparemin/blockhead';
import React, { useCallback } from 'react';
import { useContextMenuActions } from '../../state/contextMenu';
import * as S from './styles';

export interface ContextMenuInputProps extends InlineTextFieldProps {}

const ContextMenuInput = React.forwardRef<
  HTMLInputElement,
  ContextMenuInputProps
>(({ onKeyDown, ...props }, ref) => {
  const { closeContextMenu } = useContextMenuActions();

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        closeContextMenu();
      } else {
        onKeyDown?.(e);
      }
    },
    [closeContextMenu, onKeyDown],
  );

  return <S.Input {...props} onKeyDown={handleKeyDown} ref={ref} />;
});

export default ContextMenuInput;
