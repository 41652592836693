import cn from 'classnames';
import React from 'react';
import useIsSuggestionActive from 'pages/TranscriptEditorPage/hooks/useIsSuggestionActive';
import { ACTIVE_SUGGESTION_CLASSNAME } from '../../constants';
import { ClipSuggestionProvider } from './ClipSuggestionContext';
import { ClipSuggestionContextType } from './ClipSuggestionContext';
import { SUGGESTION_ID_DATA_ATTRIBUTE } from './constants';
import * as S from './styles';
import { SuggestedClipTooltip } from './SuggestedClipTooltip';

export type SuggestionContainerProps = React.PropsWithChildren<
  Pick<ClipSuggestionContextType, 'suggestionId'>
>;

const SuggestionContainer: React.FC<SuggestionContainerProps> = ({
  children,
  suggestionId,
}) => {
  const isSuggestionActive = useIsSuggestionActive(suggestionId);

  return (
    <ClipSuggestionProvider suggestionId={suggestionId}>
      <SuggestedClipTooltip>
        {({ ref, ...props }) => (
          <S.SuggestionContainer
            className={cn({
              [ACTIVE_SUGGESTION_CLASSNAME]: isSuggestionActive,
            })}
            ref={ref}
            {...props}
            {...{
              [SUGGESTION_ID_DATA_ATTRIBUTE]: suggestionId,
            }}
          >
            {children}
          </S.SuggestionContainer>
        )}
      </SuggestedClipTooltip>
    </ClipSuggestionProvider>
  );
};

export default SuggestionContainer;
