import { UrlGeneratorTrace } from 'api';
import { postExportRedirectUrlSelector } from 'state/urlTrace';
import useUrlTrace, { UseUrlTraceOptions } from 'state/urlTrace/useUrlTrace';
import { useProjectUrlTraceId } from './useEditorProject';

export default function useProjectUrlTrace<TData = UrlGeneratorTrace>(
  opts?: UseUrlTraceOptions<TData>,
) {
  const { data: traceId } = useProjectUrlTraceId();

  return useUrlTrace(traceId, opts);
}

export const usePostExportRedirectUrl = () =>
  useProjectUrlTrace({
    select: postExportRedirectUrlSelector,
  });
