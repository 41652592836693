import React from 'react';

import ConnectedModal from 'components/ConnectedModal';
import { useModalParams } from 'state/modal';

import ChapterInfoModalContents from './ChapterInfoModalContents';

const ChapterInfoModal: React.FunctionComponent = () => {
  const params = useModalParams('ChapterInfo');

  return (
    <ConnectedModal name="ChapterInfo">
      <ChapterInfoModalContents params={params} />
    </ConnectedModal>
  );
};

export default ChapterInfoModal;
