import { Spacer } from '@sparemin/blockhead';
import AppHeader from 'components/AppHeader';

export interface HeaderProps {}

const Header: React.FC<HeaderProps> = () => (
  <AppHeader>
    <AppHeader.LogoItem />
    <AppHeader.Item align="center" as={Spacer} pull="right" space={2}>
      <AppHeader.HelpMenu />
      <AppHeader.AccountMenu />
    </AppHeader.Item>
  </AppHeader>
);

export default Header;
