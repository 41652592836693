import React from 'react';
import * as S from './styles';
import { PlaybackTimeProps } from './types';
import { formatOutput } from './utils';

function PlaybackTime<As extends React.ElementType = 'span'>({
  currentTimeMillis,
  durationMillis,
  ...props
}: PlaybackTimeProps<As>): React.ReactElement | null {
  return (
    <S.PlaybackTime {...props}>
      {formatOutput(currentTimeMillis, durationMillis)}
    </S.PlaybackTime>
  );
}

export default PlaybackTime;
