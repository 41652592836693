import AddIntroOutroModal from 'pages/TranscriptEditorPage/AddIntroOutroModal';
import AdjustSelectionModal from '../AdjustSelectionModal';
import AudiogramExportModal from '../AudiogramExportModal';
import ChapterInfoModal from '../ChapterInfoModal';
import DeleteChapterModal from '../DeleteChapterModal';
import ExportTranscriptModal from '../ExportTranscriptModal';
import ExportURLsModal from '../ExportURLsModal';
import IntegratorExportModal from '../IntegratorExportModal/IntegratorExportModal';
import IntroOutroMediaUploadModal from '../IntroOutroMediaUploadModal';
import PromoPackModal from '../PromoPackModal';
import ShareAudioModal from '../ShareAudioModal';
import ShareProjectModal from '../ShareProjectModal';

export interface ModalsProps {}

const Modals: React.FC<ModalsProps> = () => (
  <>
    <AdjustSelectionModal />
    <ShareProjectModal />
    <ShareAudioModal />
    <IntegratorExportModal />
    <AudiogramExportModal />
    <ExportTranscriptModal />
    <PromoPackModal />
    <AddIntroOutroModal />
    <ChapterInfoModal />
    <DeleteChapterModal />
    <ExportURLsModal />
    <IntroOutroMediaUploadModal />
  </>
);

export default Modals;
