import { useCallback, useState } from 'react';
import { useEventTracking } from 'state/eventTracking';
import { useEditTranscript, useSegmentsById } from '../../state';
import {
  useContextMenuActions,
  useSpeakerContextMenuConfig,
} from '../../state/contextMenu';
import EditContentContainer from '../EditContentContainer';
import DeleteSpeakerButton from './DeleteSpeakerButton';
import useShowDeleteSpeakerButton from './DeleteSpeakerButton/useShowDeleteSpeakerButton';
import SpeakersList from './SpeakersList';
import * as S from './styles';

export interface SpeakerContextMenuProps {}

const SpeakerContextMenu: React.FC<SpeakerContextMenuProps> = () => {
  const config = useSpeakerContextMenuConfig();

  const { trackSpeakerLabelSave } = useEventTracking();
  const { onAction } = useContextMenuActions();
  const { updateSpeaker } = useEditTranscript();
  const { data: segmentsById } = useSegmentsById();
  const { isDeleteButtonVisible } = useShowDeleteSpeakerButton({
    segmentId: config?.segmentId,
  });

  const [updateAll, setUpdateAll] = useState(config?.defaultUpdateAll ?? false);
  const [value, setValue] = useState(() =>
    !segmentsById || !config
      ? ''
      : segmentsById[config.segmentId].speaker?.name ?? '',
  );

  const handleSubmit = useCallback(() => {
    if (!config || !segmentsById) {
      return;
    }

    const speaker = segmentsById[config.segmentId].speaker;

    if (!speaker) {
      return;
    }

    trackSpeakerLabelSave({ updateAll });

    updateSpeaker(value, speaker.id, updateAll ? undefined : config.segmentId);

    onAction({ type: 'edit-speaker', config });
  }, [
    config,
    onAction,
    segmentsById,
    trackSpeakerLabelSave,
    updateAll,
    updateSpeaker,
    value,
  ]);

  return (
    <S.Root size="medium">
      <form onSubmit={handleSubmit}>
        <EditContentContainer
          title="Edit speaker"
          actionButton={
            isDeleteButtonVisible && (
              <DeleteSpeakerButton segmentId={config?.segmentId} />
            )
          }
          showUpdateAllButton
          updateAllText="Update all instances"
          updateAll={updateAll}
          submitButtonText="save"
          onUpdateAll={setUpdateAll}
        >
          <SpeakersList value={value} onChange={setValue} />
        </EditContentContainer>
      </form>
    </S.Root>
  );
};

export default SpeakerContextMenu;
