import { Spacer, Tooltip, UnstyledButton } from '@sparemin/blockhead';
import React, { useCallback } from 'react';
import { Option, OptionProps } from 'components/OptionsList';
import { useHasDefaultPodcast } from 'state/podcast';
import AIOptionsAddPodcast from './AIOptionsAddPodcast';
import AIOptionsDefaultPodcast from './AIOptionsDefaultPodcast';

export interface AIOptionsPodcastRowProps {
  optionProps: OptionProps;
}

const AIOptionsPodcastRow: React.FC<AIOptionsPodcastRowProps> = ({
  optionProps: { isSelected, onChange, tooltipContents, ...restOptionProps },
}) => {
  const { data: hasDefaultPodcast } = useHasDefaultPodcast();

  const handlePress = useCallback(() => {
    onChange?.(!isSelected);
  }, [isSelected, onChange]);

  return (
    // The tooltip component really messes with click handling inside of
    // the List.Item component.  Ideally, clicking anything in the row would trigger
    // the Lists's onAction callback, but that won't work for anything wrapped in
    // Tooltip because react-aria's Tooltip callbacks stop event propagation.
    //
    // To get around this, most of the row is wrapped in an unstyled button and
    // the click on the button is handled as a click on the row
    <Tooltip arrow contents={tooltipContents} isDisabled={!tooltipContents}>
      <div>
        <UnstyledButton
          as={Spacer}
          align="stretch"
          onPress={hasDefaultPodcast ? undefined : handlePress}
          orientation="vertical"
          space={2}
        >
          <Option
            isSelected={isSelected}
            onChange={onChange}
            {...restOptionProps}
          />
          {hasDefaultPodcast ? (
            <AIOptionsDefaultPodcast isDisabled={!isSelected} />
          ) : (
            <AIOptionsAddPodcast />
          )}
        </UnstyledButton>
      </div>
    </Tooltip>
  );
};

export default AIOptionsPodcastRow;
