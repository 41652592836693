import { Select } from '@sparemin/blockhead';
import { useCallback } from 'react';
import { TranscriptFormat } from 'api/services/transcript';
import { transcriptFormatList } from './constants';

export interface FilesTypeListProps {
  format: TranscriptFormat;
  onChange: (value: TranscriptFormat) => void;
}

const FilesTypeList: React.FC<FilesTypeListProps> = ({ format, onChange }) => {
  const handleFormatChange = useCallback(
    (newFormat: React.Key): void => {
      onChange(newFormat as TranscriptFormat);
    },
    [onChange],
  );

  return (
    <Select
      fluid
      label="Select a file type"
      aria-label="File types list"
      selectedKey={format}
      onSelectionChange={handleFormatChange}
    >
      {transcriptFormatList.map(({ value, label }) => (
        <Select.Option key={value} textValue={label}>
          {label.toUpperCase()}
        </Select.Option>
      ))}
    </Select>
  );
};

export default FilesTypeList;
