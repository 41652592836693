import React, { useEffect } from 'react';

import useOnCreateProject from 'pages/ProjectsPage/useOnCreateProject';

import useCreateProjectFromTrace from './state/useCreateProjectFromTrace';
import useQueryParams from './useQueryParams';

export interface CreateProjectPageProps {}

const CreateProjectPage: React.FC<CreateProjectPageProps> = () => {
  const { mutate: createProject, isIdle } = useCreateProjectFromTrace();
  const { onSuccess, onError } = useOnCreateProject({ replace: true });

  const { traceId } = useQueryParams({
    onError,
  });

  useEffect(() => {
    if (traceId && isIdle) {
      createProject(
        { traceId },
        {
          onError,
          onSuccess: (data) => {
            onSuccess(data);
          },
        },
      );
    }
  }, [createProject, isIdle, onError, onSuccess, traceId]);

  // NB: given this always renders null, the component could just be a hook,
  // but it needs to be mapped to a route and so the hook would have to live
  // somewhere anyway.
  return null;
};

export default CreateProjectPage;
