import {
  ContextMenuConfig,
  SpeakerContextMenuConfig,
  WordsContextMenuConfig,
} from './types';

export function isWordsContextMenuConfig(
  config: ContextMenuConfig | undefined,
): config is WordsContextMenuConfig {
  return (config as WordsContextMenuConfig)?.words !== undefined;
}

export function isSpeakerContextMenuConfig(
  config: ContextMenuConfig | undefined,
): config is SpeakerContextMenuConfig {
  return (config as SpeakerContextMenuConfig)?.segmentId !== undefined;
}
