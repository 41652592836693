import { ComboBox, PulseLoader } from '@sparemin/blockhead';
import { useGetSpeakersNamesList } from 'pages/TranscriptEditorPage/state/useGetSpeakers';
import { getFirstLetter } from 'utils/string';
import * as S from './styles';

export interface SpeakersListProps {
  value: string;
  onChange: (value: string) => void;
}

const SpeakersList: React.FC<SpeakersListProps> = ({ value, onChange }) => {
  const { data: speakers = [], isLoading } = useGetSpeakersNamesList();

  return (
    <div
      onMouseUp={(e) => {
        e.stopPropagation();
      }}
    >
      {isLoading ? (
        <S.LoaderContainer>
          <PulseLoader />
        </S.LoaderContainer>
      ) : (
        <ComboBox
          allowsCustomValue
          aria-label="speakers-suggestions"
          inputValue={value}
          customComboBoxMenu={S.CustomList}
          onInputChange={onChange}
        >
          {speakers.map((speaker) => (
            <ComboBox.Option
              key={speaker}
              icon={<S.SpeakerIcon>{getFirstLetter(speaker)}</S.SpeakerIcon>}
            >
              {speaker}
            </ComboBox.Option>
          ))}
        </ComboBox>
      )}
    </div>
  );
};

export default SpeakersList;
