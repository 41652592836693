import { useStaticCallback } from '@sparemin/blockhead';
import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { TRACE_ID_PARAM_NAME } from './constants';

export interface UseQueryParamsConfig {
  onError?: () => void;
  onSuccess?: (traceId: string) => void;
}

export default function useQueryParams(config?: UseQueryParamsConfig) {
  const { onError, onSuccess } = config ?? {};

  const staticOnError = useStaticCallback(onError);
  const staticOnSuccess = useStaticCallback(onSuccess);

  const [searchParams] = useSearchParams();
  const [traceId, setTraceId] = useState<string>();

  const lastTraceIdRef = useRef<string | null>();

  useEffect(() => {
    const queryTraceId = searchParams.get(TRACE_ID_PARAM_NAME);

    if (lastTraceIdRef.current === queryTraceId) {
      return;
    }

    lastTraceIdRef.current = queryTraceId;

    if (typeof queryTraceId === 'string' && queryTraceId !== '') {
      setTraceId(queryTraceId);
      staticOnSuccess?.(queryTraceId);
    } else {
      staticOnError?.();
    }
  }, [searchParams, staticOnError, staticOnSuccess]);

  return { traceId };
}
