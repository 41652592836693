import { produce } from 'immer';
import { QueryStringAuthAction, QueryStringAuthState } from './types';

export default function reducer(
  state: QueryStringAuthState,
  action: QueryStringAuthAction,
) {
  switch (action.type) {
    case 'AUTH_BEGIN': {
      return produce(state, (d) => {
        d.status = 'loading';
      });
    }

    case 'AUTH_FAILURE': {
      const { payload: error } = action;
      return produce(state, (d) => {
        d.error = error;
        d.status = 'failure';
      });
    }

    case 'AUTH_SUCCESS': {
      return produce(state, (d) => {
        d.status = 'success';
      });
    }

    default:
      return state;
  }
}
