import { useWindowVirtualizer } from '@tanstack/react-virtual';
import { useCallback, useLayoutEffect, useRef } from 'react';
import { useTheme } from 'styled-components';

import {
  useChaptersBySegments,
  useSegmentIds,
  useSegmentsById,
} from '../../../state';
import useSmoothScroll from './useSmoothScroll';
import { estimateSegmentHeight } from './utils';

export interface UseVirtualizationConfig {
  listWidth: number | undefined;
}

export default function useVirtualization({
  listWidth,
}: UseVirtualizationConfig) {
  const listRef = useRef<HTMLDivElement>(null);
  const listOffsetRef = useRef(0);

  const theme = useTheme();

  const { data: segmentIds } = useSegmentIds();
  const { data: segmentsById } = useSegmentsById();
  const { data: chaptersBySegments } = useChaptersBySegments();

  const { scrollToFn } = useSmoothScroll();

  useLayoutEffect(() => {
    listOffsetRef.current = listRef.current?.offsetTop ?? 0;
  }, []);

  const virtualizer = useWindowVirtualizer({
    // default length of 4 will create a placeholder of 4 skeleton elements if data
    // is not ready
    count: segmentIds?.length ?? 4,
    estimateSize: useCallback(
      (index: number) => {
        if (!segmentIds || !segmentsById || !listWidth || !chaptersBySegments) {
          return 0;
        }

        return estimateSegmentHeight(
          segmentsById?.[segmentIds[index]],
          chaptersBySegments?.[segmentIds[index]],
          listWidth,
          theme,
        );
      },
      [chaptersBySegments, listWidth, segmentIds, segmentsById, theme],
    ),
    getItemKey: useCallback(
      (index: number) => segmentIds?.[index] ?? index,
      [segmentIds],
    ),
    overscan: 5,
    scrollMargin: listOffsetRef.current,
    scrollToFn,
  });

  return { ref: listRef, virtualizer };
}
