import Article from 'components/Article';
import EddyIntroduction from './EddyIntroduction';
import Header from './Header';
import Modals from './Modals';
import MediaUploader from './ProjectMediaUploader';
import ProjectsPageBanner from './ProjectsPageBanner';
import * as S from './styles';

export interface ProjectsPageProps {}

const ProjectsPage: React.FC<ProjectsPageProps> = () => (
  <S.Page>
    <Header />

    <Article width={1345}>
      <EddyIntroduction />
      <ProjectsPageBanner />

      <MediaUploader />

      <S.Projects />
    </Article>

    <Modals />
  </S.Page>
);

export default ProjectsPage;
