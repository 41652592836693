import React from 'react';

import ConnectedModal from 'components/ConnectedModal';
import { useModalParams } from 'state/modal';

import DeleteChapterModalContents from './DeleteChapterContents';

const DeleteChapterModal: React.FunctionComponent = () => {
  const params = useModalParams('DeleteChapter');

  return (
    <ConnectedModal name="DeleteChapter">
      <DeleteChapterModalContents params={params} />
    </ConnectedModal>
  );
};

export default DeleteChapterModal;
