import { useEdgebar } from '@sparemin/blockhead';
import { welcomeTabPanelCards } from './constants';
import * as S from './styles';
import WelcomeTabPanelBanner from './WelcomeTabPanelBanner';

export interface WelcomeTabPanelProps {}

const WelcomeTabPanel: React.FC<WelcomeTabPanelProps> = () => {
  const { setSelectedKey } = useEdgebar();

  return (
    <S.Root>
      <WelcomeTabPanelBanner />

      <S.CardsContainer>
        {welcomeTabPanelCards.map(({ key, backgroundImage, content }) => (
          <S.Card
            key={key}
            $backgroundImage={backgroundImage}
            onPress={() => {
              setSelectedKey?.(key);
            }}
          >
            {content}
          </S.Card>
        ))}
      </S.CardsContainer>
    </S.Root>
  );
};

export default WelcomeTabPanel;
