import { useInfiniteQuery } from 'react-query';
import { getMyProjects, ProjectListResult } from 'api/services';
import { useAuth } from 'state/auth';
import { InfiniteQueryOptions } from 'types';
import { projectsQueryKeys } from './queryKeys';

type QueryKey = ReturnType<typeof projectsQueryKeys.myProjects>;

export type UseGetMyProjectsOptions<TData = ProjectListResult> =
  InfiniteQueryOptions<ProjectListResult, TData, QueryKey>;

export default function useGetMyProjects<TData = ProjectListResult>(
  opts?: UseGetMyProjectsOptions<TData>,
) {
  const { userId } = useAuth();
  return useInfiniteQuery(
    projectsQueryKeys.myProjects(userId),
    ({ pageParam }) =>
      getMyProjects({
        page: pageParam?.page,
        size: pageParam?.size,
      }),
    {
      ...opts,
      getNextPageParam: ({ page }) => {
        const nextPage = page.number + 1;

        if (nextPage > page.totalPages) {
          return undefined;
        }

        return { page: nextPage, size: page.size };
      },
      meta: {
        errorMessage: 'Error loading project list',
      },
    },
  );
}
