import { IconButtonProps, Tooltip } from '@sparemin/blockhead';

import * as S from './styles';

export interface MediaControlButtonProps
  extends Omit<IconButtonProps, 'variant'> {
  children?: React.ReactNode;
  tooltip?: string;
}

const MediaControlButton: React.FC<MediaControlButtonProps> = ({
  children,
  tooltip,
  ...props
}) => (
  <Tooltip arrow contents={tooltip} isDisabled={!tooltip}>
    <S.ButtonContainer>
      <S.Button {...props}>{children}</S.Button>
    </S.ButtonContainer>
  </Tooltip>
);

export default MediaControlButton;
