import ConnectedModal from 'components/ConnectedModal';
import AdjustSelectionModalContents from './AdjustSelectionModalContents';

export interface AdjustSelectionModalProps {}

const AdjustSelectionModal: React.FC<AdjustSelectionModalProps> = () => {
  return (
    <ConnectedModal name="AdjustSelection">
      <AdjustSelectionModalContents />
    </ConnectedModal>
  );
};

export default AdjustSelectionModal;
