import { PressEvent } from '@react-types/shared';
import { Delete, Edit, PopoverContainer } from '@sparemin/blockhead';
import { useState } from 'react';
import ActionButton from 'pages/TranscriptEditorPage/EditorContextMenu/ActionButton';
import { ContextMenuAction } from 'pages/TranscriptEditorPage/state/contextMenu';
import { searchActionMenuSizeByAction } from './constants';
import DeleteResultsMenuContents from './DeleteResultsMenuContents';
import { SearchActionMenuProvider } from './SearchActionMenuContext';
import * as S from './styles';
import UpdateResultsMenuContents from './UpdateResultsMenuContents/UpdateResultsMenuContents';

export interface SearchActionMenuProps {
  foundWords: number[][];
  activeWordIndex: number;
  onDelete: () => void;
  onUpdate: (newQuery: string, foundWordGroups: number[][]) => void;
}

const SearchActionMenu: React.FC<SearchActionMenuProps> = ({
  foundWords,
  activeWordIndex,
  onDelete,
  onUpdate,
}) => {
  const [currentTarget, setCurrentTarget] = useState<Element | undefined>();
  const [action, setAction] = useState<ContextMenuAction | undefined>();

  const createHandleActionClick =
    (selectedAction: ContextMenuAction) => (e: PressEvent) => {
      setAction(selectedAction);
      setCurrentTarget(e.target);
    };

  return (
    <SearchActionMenuProvider
      {...{ foundWords, activeWordIndex, onDelete, onUpdate }}
    >
      <S.Container>
        <S.ButtonContainer orientation="horizontal">
          <ActionButton onPress={createHandleActionClick('delete-word')}>
            <ActionButton.Icon as={Delete} />
          </ActionButton>

          <ActionButton onPress={createHandleActionClick('edit-word')}>
            <ActionButton.Icon as={Edit} />
          </ActionButton>
        </S.ButtonContainer>

        <PopoverContainer
          placement="bottom"
          isOpen={!!action}
          triggerElement={currentTarget}
          offset={24}
        >
          <S.OverlayContainer allowClickthrough>
            <S.Menu
              size={searchActionMenuSizeByAction[action || 'delete-word']}
            >
              {action === 'delete-word' && <DeleteResultsMenuContents />}
              {action === 'edit-word' && <UpdateResultsMenuContents />}
            </S.Menu>
          </S.OverlayContainer>
        </PopoverContainer>
      </S.Container>
    </SearchActionMenuProvider>
  );
};

export default SearchActionMenu;
