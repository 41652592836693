import { useStaticCallback } from '@sparemin/blockhead';
import { debounce } from 'lodash-es';
import { useEffect, useMemo, useRef } from 'react';

type Callback<TArgs extends Array<unknown>, TResult> = (
  ...args: TArgs
) => TResult;

export interface UseDebouncedCallbackConfig {
  timeoutMillis: number;
}

export default function useDebouncedCallback<
  TArgs extends Array<unknown>,
  TResult,
>(callback: Callback<TArgs, TResult>, opts: UseDebouncedCallbackConfig) {
  const { timeoutMillis } = opts;
  const staticCallback = useStaticCallback(callback);
  const cancelRef = useRef<() => void>();

  const debouncedCallback = useMemo(() => {
    const cb = debounce((...args: TArgs) => {
      cancelRef.current = undefined;
      return staticCallback(...args);
    }, timeoutMillis);

    cancelRef.current = cb.cancel;

    return cb;
  }, [staticCallback, timeoutMillis]);

  useEffect(
    () => () => {
      cancelRef.current?.();
    },
    [],
  );

  return debouncedCallback;
}
