import { Link } from '@sparemin/blockhead';
import * as React from 'react';
import { MAIL_TO_SUPPORT_LINK } from 'config';

export interface MailToSupportProps {
  children?: React.ReactNode;
}

const MailToSupport: React.FunctionComponent<MailToSupportProps> = ({
  children = 'contact support',
}) => <Link href={`mailto: ${MAIL_TO_SUPPORT_LINK}`}>{children}</Link>;

export default MailToSupport;
