import { CollectionElement } from '@react-types/shared';
import { List } from '@sparemin/blockhead';
import { useNavigate } from 'react-router-dom';
import paths from 'paths';
import { useEventTracking } from 'state/eventTracking';
import useGetMyProjects from '../state/useGetMyProjects';
import ProjectRow from './ProjectRow';
import SkeletonProjectsList from './SkeletonProjectsList';
import { getProjectNameFromCuid } from './utils';

export interface ProjectsListProps {
  className?: string;
}

interface ProcessedProjectData {
  disabledKeys: string[];
  items: CollectionElement<object>[];
}

const ProjectsList: React.FC<ProjectsListProps> = ({ className }) => {
  const navigate = useNavigate();
  const { data, isFetchingNextPage, hasNextPage, fetchNextPage, isLoading } =
    useGetMyProjects();
  const { trackOpenProject } = useEventTracking();

  const { disabledKeys, items } = (data?.pages ?? [])
    .flatMap((page) => page.projects)
    .reduce(
      (acc, project) => {
        const item = (
          <List.Item key={project.projectCuid} textValue={project.name}>
            <ProjectRow key={project.projectCuid} project={project} />
          </List.Item>
        );

        // modeled as an array so that it can be spread into disabledKeys array
        // without having to worry about removing undefined values later
        const disabledKey =
          project.status === 'error' ? [project.projectCuid] : [];

        return {
          disabledKeys: [...acc.disabledKeys, ...disabledKey],
          items: [...acc.items, item],
        };
      },
      { disabledKeys: [], items: [] } as ProcessedProjectData,
    );

  const handleAction = (cuid: string | number) => {
    if (typeof cuid !== 'string') return;

    const projectName = getProjectNameFromCuid(cuid, data?.pages);
    if (projectName) {
      trackOpenProject(projectName);
    }

    navigate(paths.editor.create(cuid));
  };

  if (isLoading) return <SkeletonProjectsList className={className} />;

  if (items.length === 0) return null;

  return (
    <List
      aria-label="projects list"
      className={className}
      disabledKeys={disabledKeys}
      hasMore={hasNextPage}
      onAction={handleAction}
      onLoadMore={!isFetchingNextPage ? fetchNextPage : undefined}
    >
      {items}
    </List>
  );
};

export default ProjectsList;
