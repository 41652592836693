import {
  Button,
  Container,
  PulseLoader,
  Spacer,
  Text,
  breakpointDown,
} from '@sparemin/blockhead';
import styled from 'styled-components';

export const Root = styled(Container).attrs({
  borderWeight: 2,
})`
  height: 268px;
  margin: 0 10px;
  padding: 46px;
  background-color: ${({ theme }) => theme.backgroundColorMain};

  ${breakpointDown('md')`
    height: 100%;
  `}
`;

export const IconContainer = styled.div`
  width: 100px;
  height: 78px;
`;

export const Contents = styled(Spacer).attrs({
  align: 'center',
  space: 2,
})`
  ${breakpointDown('sm')`
    flex-direction: column;
    gap: 16px;
  `}
`;

export const TextContainer = styled(Spacer).attrs({
  orientation: 'vertical',
  space: 0.5,
})`
  text-align: start;
`;

export const Description = styled(Text).attrs({
  variant: 'p',
})`
  font-size: 14px;
  font-weight: 500;

  > span {
    font-weight: 700;
    color: ${({ theme }) => theme.palette.actionSecondary.main};
  }
`;

export const Loader = styled(PulseLoader)`
  margin-top: 48px;
`;

export const DownloadButton = styled(Button).attrs({
  variant: 'special',
  fluid: true,
})`
  margin-top: 24px;
`;
