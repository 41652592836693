import { CardHeaderProps, DialogCloseButton } from '@sparemin/blockhead';
import React from 'react';
import { useModalsActions } from 'state/modal';
import * as S from './styles';

export interface ModalHeaderProps extends CardHeaderProps {}

const ModalHeader: React.FC<ModalHeaderProps> = (props) => {
  const { hideModal } = useModalsActions();

  return (
    <S.ModalHeader
      actionButton={<DialogCloseButton onPress={hideModal} />}
      {...props}
    />
  );
};

export default ModalHeader;
