import { useMemo } from 'react';
import ClipSuggestionContext from './ClipSuggestionContext';
import { ClipSuggestionProviderProps } from './types';

const ClipSuggestionProvider: React.FC<ClipSuggestionProviderProps> = ({
  children,
  suggestionId,
}) => (
  <ClipSuggestionContext.Provider
    value={useMemo(() => ({ suggestionId }), [suggestionId])}
  >
    {children}
  </ClipSuggestionContext.Provider>
);

export default ClipSuggestionProvider;
