import { Tooltip, useHover } from '@sparemin/blockhead';
import React, { useCallback } from 'react';

import * as S from './styles';

interface SuggestionItemProps {
  onClick: (suggestion: string) => void;
  tooltipText: string;
  suggestion: string;
}

const SuggestionItem: React.FunctionComponent<SuggestionItemProps> = (
  props,
) => {
  const { onClick, suggestion } = props;

  const { hoverProps } = useHover({});

  const handleItemClick = useCallback(() => {
    onClick(suggestion);
  }, [onClick, suggestion]);

  return (
    <Tooltip arrow contents="Replace current title with this one">
      <S.ListItemWrapper {...hoverProps}>
        <S.ListItem onClick={handleItemClick}>
          <S.ListText level={5}>{suggestion}</S.ListText>
        </S.ListItem>
      </S.ListItemWrapper>
    </Tooltip>
  );
};

export default SuggestionItem;
