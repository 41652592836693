import cn from 'classnames';
import EditableProjectTitle from '../EditableProjectTitle';
import { useShowDeletedWords } from '../state/editorUi';
import { HIDE_DELETED_WORDS_CLASSNAME } from './constants';
import Segments from './Segments';
import * as S from './styles';

export interface TranscriptProps {
  className?: string;
}

const Transcript: React.FC<TranscriptProps> = ({ className }) => {
  const showDeletedWords = useShowDeletedWords();

  // we don't want every segment and therefore every word re-rendering because
  // showDeletedWords has changed, so segments are rendered in a dedicated
  // memoized component
  return (
    <S.Container
      className={cn(className, {
        [HIDE_DELETED_WORDS_CLASSNAME]: !showDeletedWords,
      })}
    >
      <S.ProjectTitleContainer>
        <EditableProjectTitle />
      </S.ProjectTitleContainer>

      <S.IntroOutro variant="intro" />

      <Segments />

      <S.IntroOutro variant="outro" />
    </S.Container>
  );
};

export default Transcript;
