import { AxiosError } from 'axios';
import { useCallback } from 'react';
import { Query, useQuery, UseQueryResult } from 'react-query';
import { getProject, Project } from 'api/services';
import { DEFAULT_POLLING_INTERVAL_MILLIS } from 'config';
import { useAuth } from 'state/auth';
import { QueryOptions, QueryPollingOptions } from 'types';
import { eddyProjectsQueryKeys } from './queryKeys';

type QueryKey = ReturnType<typeof eddyProjectsQueryKeys.myProject>;

export type UseGetProjectOptions<TData = Project> = QueryOptions<
  Project,
  TData,
  QueryKey,
  AxiosError
> &
  QueryPollingOptions;

export default function useGetProject<TData = Project>(
  cuid?: string,
  opts?: UseGetProjectOptions<TData>,
): UseQueryResult<TData, AxiosError> {
  const {
    waitForAssets,
    pollingInterval = DEFAULT_POLLING_INTERVAL_MILLIS,
    ...queryOptions
  } = opts ?? {};

  const { userId } = useAuth();

  const refetchInterval = useCallback(
    (
      _: TData | undefined,
      query: Query<Project, AxiosError, Project, QueryKey>,
    ) => {
      const isProcessing = query.state.data?.status === 'processing';
      const isWaveformProcessing = query.state.data?.associatedMedias.some(
        (media) => media.waveformData?.status === 'processing',
      );

      return isProcessing || isWaveformProcessing ? pollingInterval : false;
    },
    [pollingInterval],
  );

  return useQuery<Project, AxiosError, TData, QueryKey>(
    eddyProjectsQueryKeys.myProject(userId, cuid),
    ({ queryKey: [{ projectCuid }] }) => getProject(projectCuid as string),

    {
      ...queryOptions,
      // backend will return a status of 403 if user is not the creator of a project
      // this error is handled separately and should not show an error toast
      meta: {
        errorMessage: (err: AxiosError) => {
          if (err.response?.status === 403) {
            return false;
          } else return 'Error loading project';
        },
      },
      retry: (_, err: AxiosError) => err.response?.status !== 403,
      enabled: !!userId && !!cuid && (opts?.enabled ?? true),
      refetchInterval: waitForAssets ? refetchInterval : undefined,
    },
  );
}
