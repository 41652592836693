import {
  Download,
  Heading,
  HeadyGradient,
  Like,
  Spacer,
  Text,
  Tooltip,
  Video,
} from '@sparemin/blockhead';
import React, { useCallback, useState } from 'react';
import { QueryStatus } from 'react-query';
import useToggle from 'hooks/useToggle';
import { useModalsActions } from 'state/modal';
import { formatDuration, getIntervalDuration } from 'utils/time';
import { useProjectCuid } from '../../../../state';
import * as S from './styles';
import SuggestedClipDislikePopover from './SuggestedClipDislikePopover';
import useSuggestionPopoverTracking from './useSuggestionPopoverTracking';
import useWordsInSuggestion from './useWordsInSuggestion';

export interface SuggestedClipPopoverProps {
  startMillis: number;
  endMillis: number;
  downloadClipStatus: QueryStatus;
  className?: string;
  style?: React.CSSProperties;
  onDownloadClip: () => void;
}

const SuggestedClipPopover: React.FC<SuggestedClipPopoverProps> = ({
  startMillis,
  endMillis,
  downloadClipStatus,
  className,
  style,
  onDownloadClip,
}) => {
  const { pushModal } = useModalsActions();
  const { data: projectCuid } = useProjectCuid();
  const words = useWordsInSuggestion();
  const { onCreateClip } = useSuggestionPopoverTracking();
  const { toggle: toggleIsOpen, value: isOpen } = useToggle(false);
  const isDownloadingClip = downloadClipStatus === 'loading';
  const [button, setButton] = useState<HTMLButtonElement | null>(null);

  const handleDislikeClick = useCallback(() => {
    toggleIsOpen();
  }, [toggleIsOpen]);

  const handleCreateClip = useCallback(() => {
    if (words && projectCuid) {
      onCreateClip();
      pushModal({
        name: 'AudiogramExport',
        params: {
          words,
        },
      });
    }
  }, [onCreateClip, projectCuid, pushModal, words]);

  return (
    <>
      <S.PopoverRoot {...{ className, style }}>
        <Spacer space="small">
          <HeadyGradient style={{ height: 32, width: 32 }} />
          <Spacer orientation="vertical" space="3px">
            <Text variant="p">Headliner AI</Text>
            <Text variant="mono">
              {formatDuration(
                getIntervalDuration({
                  startMillis,
                  endMillis,
                }),
                'm[m] s[s]',
              )}
            </Text>
          </Spacer>
        </Spacer>
        <Heading level={2}>
          We think this segment could be a good promo clip for your episode.
        </Heading>
        <S.Footer>
          <S.CreateClipButton onPress={handleCreateClip} startIcon={<Video />}>
            create clip
          </S.CreateClipButton>

          <Tooltip
            contents="Clip download will be ready soon"
            isDisabled={!isDownloadingClip}
          >
            <S.DownloadButton
              onPress={onDownloadClip}
              isDisabled={isDownloadingClip}
            >
              <Download />
            </S.DownloadButton>
          </Tooltip>

          <S.Dismiss ref={setButton} onPress={handleDislikeClick}>
            <Like />
          </S.Dismiss>
        </S.Footer>
      </S.PopoverRoot>
      <SuggestedClipDislikePopover isOpen={isOpen} triggerElement={button} />
    </>
  );
};

export default SuggestedClipPopover;
