import React from 'react';
import { EditTranscriptContext } from './EditTranscriptContext';
import { EditTranscriptProviderProps } from './types';
import useEditTranscriptContextValue from './useEditTranscriptContextValue';

const EditTranscriptProvider: React.FC<EditTranscriptProviderProps> = ({
  children,
}) => (
  <EditTranscriptContext.Provider value={useEditTranscriptContextValue()}>
    {children}
  </EditTranscriptContext.Provider>
);

export default EditTranscriptProvider;
