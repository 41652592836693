export type ValueType = 'integer' | 'string' | 'boolean';

type ValueTypeMap = {
  integer: number;
  boolean: boolean;
  string: string;
};

export interface AdjustmentConfig<V extends keyof ValueTypeMap> {
  default?: ValueTypeMap[V];
  hide?: boolean;
  hideOptions?: Array<ValueTypeMap[V]>;
  overrideOptions?: Array<ValueTypeMap[V]>;
  readonly?: boolean;
  showOptions?: Array<ValueTypeMap[V]>;
  valueType: V;
}

export type StringAdjustmentConfig = AdjustmentConfig<'string'>;
export type IntegerAdjustmentConfig = AdjustmentConfig<'integer'>;
export type BooleanAdjustmentConfig = AdjustmentConfig<'boolean'>;

export interface ExpressExportConfig {
  assets?: StringAdjustmentConfig;
}

export interface EnabledConfig {
  enabled?: BooleanAdjustmentConfig;
}

export interface EddyPrefs {
  isAutoChaptersEnabled?: BooleanAdjustmentConfig;
  isAutoProjectNameEnabled?: BooleanAdjustmentConfig;
  shouldRemoveFillerWords?: BooleanAdjustmentConfig;
  isPodcastPersonalizationEnabled?: BooleanAdjustmentConfig;
}

export type EddyPrefType = keyof EddyPrefs;

export type HelpButtonConfig = EnabledConfig;

export interface NavigationConfig {
  target?: StringAdjustmentConfig;
}

export const EddyFeaturesKeyList = [
  'autoChapters',
  'autoProjectName',
  'clipSuggestions',
  'showNotesSuggestions',
  'showProjectNameSuggestions',
] as const;

export type EddyFeaturesConfigKeys = (typeof EddyFeaturesKeyList)[number];

export type EddyFeaturesConfig = Record<
  EddyFeaturesConfigKeys,
  EnabledConfig | undefined
>;

export interface EddyAdjustmentModel {
  buttonColor?: StringAdjustmentConfig;
  expressExport?: ExpressExportConfig;
  favicon?: StringAdjustmentConfig;
  features?: EddyFeaturesConfig;
  logo?: StringAdjustmentConfig;
  navigation?: NavigationConfig;
  tabTitle?: StringAdjustmentConfig;
  helpButton?: HelpButtonConfig;
}

export interface DisplayAdjustment {
  eddy: EddyAdjustmentModel;
}

export interface DisplayPreferences {
  displayAdjustment: DisplayAdjustment;
}

export interface UserPreferences {
  eddyPrefs: EddyPrefs;
}

export interface Language {
  name: string;
  languageCode: string;
}
export interface EddyProjectLanguageResult {
  data: Language[];
}
