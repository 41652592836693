import { Button, ExternalLink } from '@sparemin/blockhead';
import React from 'react';
import Modal from 'components/Modal/Modal';
import { CreateAudiogramModalConfig } from 'state/modal/modals';

interface CreateAudiogramModalContentsProps {
  params?: CreateAudiogramModalConfig['params'];
}

const CreateAudiogramModalContents: React.FunctionComponent<
  CreateAudiogramModalContentsProps
> = (props) => {
  const { params } = props;
  const { isClip } = params || {};
  const title = isClip ? 'clip' : 'audiogram';

  return (
    <Modal
      icon={<Modal.Icon>{<ExternalLink width="40px" />}</Modal.Icon>}
      style={{ width: '570px' }}
      title={`Create your ${title}`}
      subtitle={`We’ll open a new tab where you can create your ${title}. If your pop-up blocker blocked our new tab, simply tap the button below.`}
    >
      <Modal.Body>
        <Button as="a" href={params?.url} target="__blank" fluid>
          Get started
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default CreateAudiogramModalContents;
