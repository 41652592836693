import { isEqual } from 'lodash-es';
import { useCallback, useState } from 'react';
import { Podcast } from 'api';
import { useMyDefaultPodcast } from 'state/podcast';
import { useEddyPrefsOverride } from 'state/userPref';
import { EddyPref } from 'state/userPref/types';
import useUpdateEddyPrefs from 'state/userPref/useUpdateEddyPrefs';
import { useUpdateProjectSettings } from '../state';
import useSetProjectRelatedPodcast from '../state/useSetProjectRelatedPodcast';

export interface UseUpdateAIOptions {
  isLoading: boolean;
  defaultPodcast: Podcast | undefined;
  prefs: EddyPref | undefined;
  onChange: (prefs: EddyPref) => void;
  onSubmit: () => Promise<void>;
}

export default function useUpdateAIOptions(): UseUpdateAIOptions {
  const { data: prefs, isFetched: isEddyPrefsFetched } = useEddyPrefsOverride();
  const { data: defaultPodcast, isFetched: isDefaultPodcastFetched } =
    useMyDefaultPodcast();

  const [draftPrefs, setDraftPrefs] = useState(prefs);

  const { mutateAsync: updateEddyPrefs } = useUpdateEddyPrefs();
  const { mutateAsync: updateProjectSettings } = useUpdateProjectSettings({
    awaitInvalidation: true,
  });
  const { mutateAsync: setProjectRelatedPodcast } =
    useSetProjectRelatedPodcast();

  const defaultPodcastId = defaultPodcast?.podcastId;

  const handleOnSubmit = useCallback(async (): Promise<void> => {
    if (!draftPrefs) {
      return;
    }

    await setProjectRelatedPodcast({
      podcastId: !draftPrefs.isPodcastPersonalizationEnabled
        ? undefined
        : defaultPodcastId,
    });

    if (!isEqual(draftPrefs, prefs)) {
      await updateEddyPrefs(draftPrefs);
    }

    await updateProjectSettings({
      isAutoChaptersEnabled: draftPrefs.isAutoChaptersEnabled,
      // when creating the project name suggestion applied is set to false when
      // the pref is set to true. In this way only projects that are created with this
      // set to true will later have their title replaced with a suggestion.
      isNameSuggestionApplied: !draftPrefs.isAutoProjectNameEnabled,
    });
  }, [
    defaultPodcastId,
    draftPrefs,
    prefs,
    setProjectRelatedPodcast,
    updateEddyPrefs,
    updateProjectSettings,
  ]);

  if (!draftPrefs && !isEqual(draftPrefs, prefs)) {
    setDraftPrefs(prefs);
  }

  return {
    defaultPodcast,
    isLoading: !isEddyPrefsFetched || !isDefaultPodcastFetched,
    onChange: setDraftPrefs,
    onSubmit: handleOnSubmit,
    prefs: draftPrefs,
  };
}
