import { PauseCircleOutline, PlayCircleOutline } from '@sparemin/blockhead';
import MediaControlButton, {
  MediaControlButtonProps,
} from './MediaControlButton';

export interface PlayButtonProps extends MediaControlButtonProps {
  paused?: boolean;
}

const PlayButton: React.FC<PlayButtonProps> = ({ paused, ...props }) => (
  <MediaControlButton {...props}>
    {paused ? (
      <PlayCircleOutline height="100%" width="100%" />
    ) : (
      <PauseCircleOutline height="100%" width="100%" />
    )}
  </MediaControlButton>
);

export default PlayButton;
