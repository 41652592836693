import { GoogleDriveFile } from '@sparemin/api-sdk';
import { useMutation, useQueryClient } from 'react-query';
import {
  EdgeAssetType,
  updateEdgeAsset,
  UpdateProjectEdgeAssetResult,
} from 'api';
import { useAuth } from 'state/auth';
import { MutationOptions } from 'types';
import { getMediaTypeFromFileType } from 'utils/fileUpload';
import { eddyProjectsQueryKeys } from './queryKeys';
import { useProjectCuid } from './useEditorProject';

export interface AddEdgeAssetFromIntegratorProps {
  asset: GoogleDriveFile;
  assetType: EdgeAssetType;
  projectCuid?: string;
  providerUserId: string;
}

async function addEdgeAssetFromIntegrator({
  asset,
  assetType,
  projectCuid,
  providerUserId,
}: AddEdgeAssetFromIntegratorProps): Promise<UpdateProjectEdgeAssetResult> {
  const { mimeType, id } = asset;
  const mediaType = getMediaTypeFromFileType(mimeType);

  if (projectCuid && mediaType) {
    return updateEdgeAsset({
      edgeAssetType: assetType,
      projectCuid,
      mediaType,
      googleDriveFileId: id,
      providerUserId,
    });
  }
  throw Error(`Error uploading ${assetType}`);
}

export default function useAddEdgeAssetFromIntegrator(
  opts?: MutationOptions<
    UpdateProjectEdgeAssetResult,
    AddEdgeAssetFromIntegratorProps
  >,
) {
  const { data: projectCuid } = useProjectCuid();
  const queryClient = useQueryClient();
  const { userId } = useAuth();

  return useMutation<
    UpdateProjectEdgeAssetResult,
    unknown,
    Omit<AddEdgeAssetFromIntegratorProps, 'projectCuid'>,
    unknown
  >(
    (args) => {
      if (!projectCuid) {
        throw new Error('Error submitting asset');
      }
      return addEdgeAssetFromIntegrator({
        ...args,
        projectCuid,
      });
    },
    {
      ...opts,

      onSettled: (data, error, variables, context) => {
        queryClient.invalidateQueries(
          eddyProjectsQueryKeys.myProject(userId, projectCuid),
        );
        if (opts?.onSettled) {
          opts.onSettled(data, error, variables, context);
        }
      },
    },
  );
}
