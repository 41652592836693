import { AuthLinkProps } from '@sparemin/auth';
import React from 'react';
import { Link } from 'react-router-dom';

const AuthLink = React.forwardRef<HTMLAnchorElement, AuthLinkProps>(
  ({ children, className, href, onPress }, ref) => (
    <Link className={className} to={href} onClick={onPress} ref={ref}>
      {children}
    </Link>
  ),
);

export default AuthLink;
