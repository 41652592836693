import { Button } from '@sparemin/blockhead';
import { useCallback, useState } from 'react';
import { DownloadFormatting, TranscriptFormat } from 'api/services/transcript';
import { EXPORT_MODAL_SUBTITLES_BY_STATUS } from 'components/ExportModalContents/constants';
import { getModalTitleByStatus } from 'components/ExportModalContents/utils';
import Modal from 'components/Modal';
import { ExportFormatProps, useEventTracking } from 'state/eventTracking';
import { useModalsActions } from 'state/modal';
import { ExportTranscriptModalConfig } from 'state/modal/modals';
import useExportTranscript from '../state/useExportTranscript';
import FilesTypeList from './FilesTypeList';
import FormattingOptionsList from './FormattingOptionsList';
import * as S from './styles';
import { getTranscriptFormatLabelByValue } from './utils';
import { checkIsFileTypeUnsupportedForYouTubeFormatting } from './utils';

export interface ExportTranscriptModalContentsProps {
  params?: ExportTranscriptModalConfig['params'];
}

const ExportTranscriptModalContents: React.FC<
  ExportTranscriptModalContentsProps
> = ({ params }) => {
  const {
    projectCuid = '',
    projectName = '',
    projectMediaType = 'audio',
    selection,
  } = params || {};

  const [format, setFormat] = useState<TranscriptFormat>('txt');
  const [downloadFormatting, setDownloadFormatting] =
    useState<DownloadFormatting>({
      includeSpeakerLabels: true,
      shouldFormatForYoutube: true,
      includeStaticTimeMarkers: true,
    });

  const { pushModal } = useModalsActions();
  const { trackExport } = useEventTracking();
  const { exportTranscript, status, isIdle, isLoading } = useExportTranscript();

  const handleFileFormatChange = useCallback(
    (newFormat: TranscriptFormat) => {
      setFormat(newFormat);

      if (checkIsFileTypeUnsupportedForYouTubeFormatting(newFormat)) {
        setDownloadFormatting({
          ...downloadFormatting,
          shouldFormatForYoutube: false,
        });
      }
    },
    [downloadFormatting],
  );

  const handleBackClick = useCallback(() => {
    if (!params) {
      return;
    }

    pushModal({
      name: 'ShareProject',
      params: {
        projectCuid,
        projectName,
        projectMediaType,
      },
    });
  }, [params, projectCuid, projectMediaType, projectName, pushModal]);

  const handleExport = useCallback(() => {
    if (!params) {
      return;
    }
    trackExport({
      buttonClick: getTranscriptFormatLabelByValue(format),
    } as ExportFormatProps);
    exportTranscript({
      ...downloadFormatting,
      format,
      projectCuid,
      projectName,
      selection,
      download: true,
    });
  }, [
    exportTranscript,
    format,
    downloadFormatting,
    params,
    projectCuid,
    projectName,
    selection,
    trackExport,
  ]);

  return (
    <S.Modal
      title={
        isIdle ? 'Transcript export' : getModalTitleByStatus(status, format)
      }
      subtitle={
        isIdle
          ? 'Select how you would like to format your transcript.'
          : EXPORT_MODAL_SUBTITLES_BY_STATUS[status]
      }
      icon={
        isIdle && (
          <Modal.Icon backgroundColor="#e77ac0">
            <S.Icon />
          </Modal.Icon>
        )
      }
      showBackButton
      onBack={handleBackClick}
    >
      {isLoading && <S.Loader />}

      {isIdle && (
        <S.Body>
          <FilesTypeList format={format} onChange={handleFileFormatChange} />
          <FormattingOptionsList
            {...{ format, downloadFormatting }}
            onChange={setDownloadFormatting}
          />
          <Button fluid onPress={handleExport}>
            Export your transcript
          </Button>{' '}
        </S.Body>
      )}
    </S.Modal>
  );
};

export default ExportTranscriptModalContents;
