import { ProjectTranscript, TranscriptEdit } from 'api';
import { omitNil } from 'utils/objects';
import TranscriptOriginator from '../TranscriptOriginator';
import { CommandOptions, Committer, WordEdit } from '../types';
import AsyncUndoableWordCommand, {
  WordCommitterArgs,
} from './AsyncUndoableWordCommand';

export default class UpdateWordsCommand extends AsyncUndoableWordCommand {
  constructor(
    private wordEdits: WordEdit[],
    originator: TranscriptOriginator,
    committer: Committer<WordCommitterArgs>,
    opts?: CommandOptions,
  ) {
    super(originator, committer, opts);
  }

  protected onError() {}

  protected createEdits(_: ProjectTranscript | undefined): TranscriptEdit[] {
    return this.wordEdits.map(({ endMillis, id, startMillis, text }) => ({
      action: 'update',
      actionDetail: omitNil({ endMillis, startMillis, text }),
      wordId: id,
    }));
  }
}
