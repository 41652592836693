import { GradientText } from '@sparemin/blockhead';
import React, { useCallback } from 'react';
import { useModalsActions } from 'state/modal';
import * as S from './styles';

export interface UpgradeButtonProps {
  className?: string;
}

const UpgradeButton: React.FC<UpgradeButtonProps> = () => {
  const { pushModal } = useModalsActions();

  const handleUpgradeClick = useCallback(() => {
    pushModal({ name: 'Upsell' });
  }, [pushModal]);

  return (
    <GradientText as={S.LinkButton} onPress={handleUpgradeClick}>
      upgrade to remove clips
    </GradientText>
  );
};

export default UpgradeButton;
