import * as S from './styles';

interface ProjectRowSkeletonProps {}

const ProjectRowSkeleton: React.FC<ProjectRowSkeletonProps> = () => (
  <S.Root>
    <S.ImageSkeleton />
    <S.Description>
      <S.SkeletonContainer>
        <S.ProjectRowSkeleton width="800px" />
        <S.ProjectRowSkeleton width="300px" />
      </S.SkeletonContainer>
    </S.Description>
  </S.Root>
);
export default ProjectRowSkeleton;
