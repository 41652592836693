import { useMemo } from 'react';
import {
  useSegmentInitialWordIndex,
  useSegmentWords,
} from 'pages/TranscriptEditorPage/state';
import { IndexedClipSuggestion } from 'pages/TranscriptEditorPage/state/types';
import { useClipSuggestions } from 'pages/TranscriptEditorPage/state/useClipSuggestionGroup';

export default function useSegmentSuggestions(segmentId: number) {
  const { data: wordIds } = useSegmentWords(segmentId);
  const { data: suggestions } = useClipSuggestions();
  const { data: segmentWordOffset } = useSegmentInitialWordIndex(segmentId);

  return useMemo(() => {
    if (!wordIds || segmentWordOffset === undefined) {
      return [];
    }

    const segStartWordIndex = segmentWordOffset;
    const segEndWordIndex = segStartWordIndex + wordIds.length - 1;

    return suggestions?.reduce((acc, suggestion) => {
      if (
        suggestion.startWordIndex <= segEndWordIndex &&
        suggestion.endWordIndex >= segStartWordIndex
      ) {
        acc.push(suggestion);
      }

      return acc;
    }, [] as IndexedClipSuggestion[]);
  }, [segmentWordOffset, suggestions, wordIds]);
}
