import { PlusThin } from '@sparemin/blockhead';
import React, { useCallback, useState } from 'react';
import useToggle from 'hooks/useToggle';
import ActionButton from '../../../ActionButton';
import InsertElementPopover from './InsertElementPopover';

export interface InsertElementActionButtonProps {}

const InsertElementActionButton: React.FC<
  InsertElementActionButtonProps
> = () => {
  const [button, setButton] = useState<HTMLButtonElement | null>(null);
  const { toggle: toggleIsOpen, value: isOpen } = useToggle();

  const handleActionButtonPress = useCallback(() => {
    toggleIsOpen();
  }, [toggleIsOpen]);

  return (
    <>
      <ActionButton
        aria-label="insert element"
        onPress={handleActionButtonPress}
        ref={setButton}
        tooltip="Insert an element"
      >
        <ActionButton.Icon as={PlusThin} />
      </ActionButton>
      <InsertElementPopover isOpen={isOpen} triggerElement={button} />
    </>
  );
};

export default InsertElementActionButton;
