import React, { useCallback, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { BaseProject } from 'api/services';
import EditableInput from 'components/EditableInput';
import notifyError from 'components/notification';
import { useAuth } from 'state/auth';
import { useEddyFeaturesDisplayPrefOverride } from 'state/displayPref';
import {
  eddyProjectsQueryKeys,
  TranscriptEditorPageParams,
  useProjectName,
  useUpdateProjectSettings,
} from '../state';
import { useGetFirstNProjectNameSuggestions } from '../state/useGetProjectNameSuggestion';
import { UseUpdateProjectSettingsArgs } from '../state/useUpdateProjectSettings';
import { SUGGESTED_PROJECT_NAMES_MAX_AMOUNT } from './constants';
import * as S from './styles';
import useProjectTitileAutofocus from './useProjectTitleAutofocus';
import useTrackReplaceTitleWithSuggestion from './useTrackReplaceTitleWithSuggestion';

interface EditableProjectTitleProps {}

interface UpdateProjectSettingsContext {
  previousData: BaseProject;
}

const SUGGESTIONS_CLASSNAME = 'suggestions-popover';
const clickOutsideIgnoreSelector = `.${SUGGESTIONS_CLASSNAME}`;

const EditableProjectTitle: React.FC<EditableProjectTitleProps> = () => {
  const { data: projectName, isLoading } = useProjectName();

  const { shouldAutoFocus } = useProjectTitileAutofocus();

  // TODO this shouldn't come from params
  const { projectCuid } = useParams<TranscriptEditorPageParams>();
  const queryClient = useQueryClient();
  const { userId } = useAuth();
  const { data: eddyFeaturesDisplayPrefOverride } =
    useEddyFeaturesDisplayPrefOverride();

  const showProjectNameSuggestionsEnabled =
    eddyFeaturesDisplayPrefOverride?.showProjectNameSuggestions;
  const { data: suggestions } = useGetFirstNProjectNameSuggestions(
    SUGGESTED_PROJECT_NAMES_MAX_AMOUNT,
    projectCuid,
    showProjectNameSuggestionsEnabled,
  );
  const { trackReplaceTitleWithSuggestion } =
    useTrackReplaceTitleWithSuggestion();

  const [draftProjectName, setDraftProjectName] = useState<string>();

  // TODO this hook probably shouldn't be defined inside of a component
  const { mutate: saveTitle } = useUpdateProjectSettings<
    UseUpdateProjectSettingsArgs,
    UpdateProjectSettingsContext
  >({
    onMutate: async ({ name }) => {
      await queryClient.cancelQueries({
        queryKey: eddyProjectsQueryKeys.myProject(userId, projectCuid),
      });

      const previousData: BaseProject | undefined = queryClient.getQueryData(
        eddyProjectsQueryKeys.myProject(userId, projectCuid),
      );

      if (!previousData) {
        return;
      }

      const updatedProject = {
        ...previousData,
        name: name,
      };

      queryClient.setQueryData(
        eddyProjectsQueryKeys.myProject(userId, projectCuid),
        updatedProject,
      );

      return { previousData };
    },
    onError: (err, variables, context) => {
      queryClient.setQueryData(
        eddyProjectsQueryKeys.myProject(userId, projectCuid),
        context?.previousData,
      );
    },
  });

  const handleSubmit = useCallback(
    (updatedProjectName: string) => {
      saveTitle(
        { name: updatedProjectName },
        {
          onError(_, __, context) {
            notifyError({
              heading: 'Error updating project title',
              errorCode: 'ER004',
            });
            setDraftProjectName(context?.previousData.name);
          },
        },
      );
    },
    [saveTitle],
  );

  const handleInputSubmit = useCallback(() => {
    if (draftProjectName && projectCuid) {
      handleSubmit(draftProjectName);
    }
  }, [draftProjectName, handleSubmit, projectCuid]);

  const handleSuggestionSubmit = useCallback(
    (suggestion: string) => {
      trackReplaceTitleWithSuggestion();
      setDraftProjectName(suggestion);
      if (projectCuid) {
        handleSubmit(suggestion);
      }
    },
    [handleSubmit, projectCuid, trackReplaceTitleWithSuggestion],
  );

  const handleCancel = () => {
    setDraftProjectName(undefined);
  };

  const handleEdit = useCallback(() => {
    setDraftProjectName(projectName);
  }, [projectName]);

  return (
    <>
      {isLoading ? (
        <Skeleton width="200px" />
      ) : (
        <S.Container level={3} letterSpacing="normal" lineHeight={1}>
          <EditableInput
            autoFocus={shouldAutoFocus}
            aria-label="project title"
            onCancel={handleCancel}
            onChange={(e: React.FormEvent<HTMLTextAreaElement>) =>
              setDraftProjectName(e.currentTarget.value)
            }
            clickOutsideIgnoreSelector={
              showProjectNameSuggestionsEnabled
                ? clickOutsideIgnoreSelector
                : undefined
            }
            onEdit={handleEdit}
            onSelectSuggestion={handleSuggestionSubmit}
            onSubmit={handleInputSubmit}
            renderSuggestionsPopover={
              showProjectNameSuggestionsEnabled
                ? (popoverProps) => (
                    <EditableInput.SuggestionsPopover
                      suggestions={suggestions}
                      className={SUGGESTIONS_CLASSNAME}
                      {...popoverProps}
                    />
                  )
                : undefined
            }
            value={draftProjectName ?? projectName}
          />
        </S.Container>
      )}
    </>
  );
};
export default EditableProjectTitle;
