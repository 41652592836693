import React from 'react';
import { useRegisterKeybinding } from 'state/hotkey';
import { useModalsActions } from 'state/modal';
interface ContentsWrapperProps {
  children: React.ReactNode;
}
const ContentsWrapper: React.FC<ContentsWrapperProps> = ({ children }) => {
  const { hideModal } = useModalsActions();

  useRegisterKeybinding('Escape', hideModal);
  return <>{children}</>;
};
export default ContentsWrapper;
