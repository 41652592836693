import { useCallback, useRef } from 'react';
import { useProjectAssetUrl } from '../state';
import * as S from './styles';
import useSyncVideoWithAudio from './useSyncVideoWithAudio';

export interface VideoPreviewProps {}

const VideoPreview: React.FC<VideoPreviewProps> = () => {
  const { data: assetUrl } = useProjectAssetUrl();
  const videoRef = useRef<HTMLVideoElement>();

  useSyncVideoWithAudio({ videoRef });

  const videoCallbackRef = useCallback((element: HTMLVideoElement) => {
    videoRef.current = element;
  }, []);

  return (
    <S.Wrapper>
      <S.Video src={assetUrl} ref={videoCallbackRef} muted />
    </S.Wrapper>
  );
};

export default VideoPreview;
