import { useCallback } from 'react';
import { useEventTracking } from 'state/eventTracking';
import { getDuration } from 'utils/audio';

export default function useTrackUploadStatus() {
  const { trackUploadComplete, trackUploadStart } = useEventTracking();

  const onUploadStart = useCallback(
    (file: File) => {
      getDuration(file).then((duration) =>
        trackUploadStart({
          fileName: file.name,
          fileSize: file.size,
          fileType: file.type,
          fileLength: duration,
        }),
      );
    },
    [trackUploadStart],
  );

  const onUploadComplete = useCallback(
    (file: File, status: 'success' | 'failure') => {
      getDuration(file).then((duration) =>
        trackUploadComplete({
          status,
          fileName: file.name,
          fileSize: file.size,
          fileType: file.type,
          fileLength: duration,
        }),
      );
    },
    [trackUploadComplete],
  );

  return { onUploadStart, onUploadComplete };
}
