import { useQuery } from 'react-query';
import { ColorPaletteResult, getColorPalette } from 'api';
import { QueryOptions } from 'types';
import { colorPaletteQueryKeys } from './queryKeys';
import { hexColorPaletteListSelector } from './selectors';
import { useProjectCuid } from './useEditorProject';

type QueryKey = ReturnType<typeof colorPaletteQueryKeys.project>;

export interface UseGetColorPaletteOptions<TData = ColorPaletteResult>
  extends QueryOptions<ColorPaletteResult, TData, QueryKey> {}

function useGetColorPalette<TData = ColorPaletteResult>(
  opts?: UseGetColorPaletteOptions<TData>,
) {
  const { data: cuid } = useProjectCuid();

  return useQuery(
    colorPaletteQueryKeys.project(cuid),
    ({ queryKey: [{ projectCuid }] }) =>
      getColorPalette({
        projectCuid: projectCuid as string,
      }),
    opts,
  );
}

export const useHexColorPaletteList = () =>
  useGetColorPalette({
    select: hexColorPaletteListSelector,
  });
