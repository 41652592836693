import { Switch, Text } from '@sparemin/blockhead';
import styled from 'styled-components';

export const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const Option = styled(Switch).attrs({
  labelPosition: 'before',
})`
  display: flex;
  justify-content: space-between;
  position: relative;
`;

export const OptionText = styled(Text).attrs({
  variant: 'p',
})`
  font-size: 14px;
`;
