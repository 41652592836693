import {
  AspectRatio,
  Spacer,
  UnstyledButton,
  getThemeColor,
} from '@sparemin/blockhead';
import styled from 'styled-components';
import { MIN_ACTIONS_BAR_HEIGHT } from '../BaseTabPanel/constants';
import { ImageButtonStyleProps } from './types';

export const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: ${MIN_ACTIONS_BAR_HEIGHT}px;
`;

export const ImagesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 40px;
  text-align: initial;
`;

export const ImageButton = styled(UnstyledButton)<ImageButtonStyleProps>`
  position: relative;
  border-radius: 24px;
  border: 2px solid ${getThemeColor('l4')};
  background-image: ${({ $imageUrl }) => `url(${$imageUrl})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: ${({ theme }) => theme.transitions.create('all', '300ms')};

  & > div {
    opacity: 0;
  }

  &[data-hovered='true'] {
    border: 2px solid ${getThemeColor('l5')};
    cursor: pointer;

    & > div {
      opacity: 1;
    }
  }
`;

export const ImageContainer = styled(AspectRatio)<ImageButtonStyleProps>`
  height: auto;

  ${({ $basis = 'width' }) => `
    width: ${$basis === 'width' ? '405px' : '228px'};
  `}
`;

export const DownloadIconOverlay = styled.div`
  width: 88px;
  height: 88px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  inset: 0;
  margin: auto;
  border-radius: 50%;
  font-size: 32px;
  color: ${getThemeColor('white')};
  background-color: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);
  transition: ${({ theme }) => theme.transitions.create('opacity', '300ms')};
`;

export const ColorPaletteContainer = styled(Spacer)`
  width: 100%;
  text-align: left;
`;
