import { Checkbox, InlineTextField, Spacer } from '@sparemin/blockhead';
import styled from 'styled-components';
import Plate from 'components/Plate';
import { ChaptersStyleProps } from './types';

export const Root = styled(Plate)`
  width: 100%;
  gap: 24px;
  flex-direction: column;
  padding: 24px;
  border-radius: 20px;
  box-shadow: none;

  & > :not(:first-child) {
    margin-left: 0;
  }
`;

export const RegenerationOptionsContainer = styled(Spacer).attrs({
  orientation: 'horizontal',
  align: 'center',
  justify: 'center',
  space: '16px',
})`
  width: 100%;

  & > * {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const BottomOptions = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
`;

export const CustomFeedbackInput = styled(InlineTextField)<ChaptersStyleProps>`
  width: 100%;
  grid-area: ${({ $fullWidth }) =>
    $fullWidth ? '1 / 1 / 2 / 4' : '1 / 1 / 2 / 3'};
  padding: 18px 17px;
  font-size: 14px;
  font-weight: normal;
`;

export const IncludeChaptersCheckbox = styled(Checkbox)`
  grid-area: 1 / 3 / 2 / 4;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
`;
