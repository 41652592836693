import { MotionProps } from 'framer-motion';
import { ACTIONS_BAR_INITIAL_Y_AXIS_POSITION } from '../ActionsBar/constants';
import { TOP_BAR_TRANSITION_DURATION_SEC } from './constants';

export const tabPanelContentsWrapperAnimationProps: MotionProps = {
  transition: {
    duration: TOP_BAR_TRANSITION_DURATION_SEC,
  },
  initial: {
    y: 0,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
  },
  exit: {
    y: ACTIONS_BAR_INITIAL_Y_AXIS_POSITION,
    opacity: 0,
  },
};
