import { MutationFunction, useMutation, useQueryClient } from 'react-query';
import { useAuth } from 'state/auth';
import { eddyTranscriptQueryKeys } from './queryKeys';
import { useTranscriptId } from './useEditorTranscript';

export interface UseUpdateTranscriptOptions<TData, TVariables> {
  mutationFn: MutationFunction<TData, TVariables>;
}

export default function useUpdateTranscript<
  TData = unknown,
  TVariables = unknown,
>({ mutationFn }: UseUpdateTranscriptOptions<TData, TVariables>) {
  const queryClient = useQueryClient();
  const { userId } = useAuth();
  const { data: transcriptId } = useTranscriptId();
  const queryKey = eddyTranscriptQueryKeys.transcript(userId, transcriptId);

  return useMutation<TData, unknown, TVariables>({
    mutationFn,
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey });
    },
    onSettled: () => {
      queryClient.invalidateQueries(queryKey, { exact: true });
    },
  });
}
