import {
  UpdateProjectTranscriptTextArgs,
  updateProjectTranscriptText,
} from 'api/services';
import { useTranscriptId } from './useEditorTranscript';
import useUpdateTranscript from './useUpdateTranscript';

export type UseUpdateTranscriptTextVariables = Pick<
  UpdateProjectTranscriptTextArgs,
  'edits' | 'isFillerWordsRemoval'
>;

export default function useUpdateTranscriptText() {
  const { data: transcriptId } = useTranscriptId();

  return useUpdateTranscript<void, UseUpdateTranscriptTextVariables>({
    mutationFn: ({ edits, isFillerWordsRemoval }) => {
      return updateProjectTranscriptText({
        // TODO why do we have to pass this?  can't updateProjectTranscript read from query?
        transcriptId: transcriptId as number,
        edits,
        isFillerWordsRemoval,
      });
    },
  });
}
