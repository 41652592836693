import {
  parseAuthHeader,
  shouldRenewToken,
  replaceHeaderToken,
} from '@sparemin/auth';
import { InternalAxiosRequestConfig } from 'axios';
import { merge } from 'lodash-es';
import { renewToken } from 'api/services/user';
import { TOKEN_CHECK_INTERVAL_MILLIS } from 'config';
import { Interceptor } from './types';

export default function tokenRenewalRequestInterceptor(
  onTokenRenewed: (token: string) => void,
): Interceptor<InternalAxiosRequestConfig> {
  let checkToken = true;
  let renewingToken = false;

  const scheduleNextTokenCheck = () => {
    checkToken = false;
    window.setTimeout(() => {
      checkToken = true;
    }, TOKEN_CHECK_INTERVAL_MILLIS);
  };
  const onFulfilled = (config: InternalAxiosRequestConfig) => {
    if (!checkToken || renewingToken) {
      return config;
    }

    renewingToken = true;

    const bail = () => {
      renewingToken = false;
      return config;
    };

    if (!config.headers) {
      return bail();
    }

    const authHeader = config.headers.Authorization;

    if (authHeader !== 'string' || !authHeader) {
      return bail();
    }

    const token = parseAuthHeader(authHeader);
    if (!token || !shouldRenewToken(token)) {
      return bail();
    }

    const newConfig = renewToken()
      .then((result) => {
        if (result.spareminToken) {
          onTokenRenewed?.(result.spareminToken);
          scheduleNextTokenCheck();
          // patch new token into header in case current token has expired
          // while fetching the new one
          return merge(config, {
            headers: {
              Authorization: replaceHeaderToken(
                authHeader,
                result.spareminToken,
              ),
            },
          });
        } else return bail();
      })
      .catch(() => config)
      .finally(() => {
        renewingToken = false;
      });
    return newConfig;
  };

  const onRejected = Promise.reject;

  return [onFulfilled, onRejected];
}
