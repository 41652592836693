import { produce } from 'immer';
import { flowRight, map } from 'lodash-es';
import { useCallback, useEffect, useMemo } from 'react';
import { create } from 'zustand';
import { shallow } from 'zustand/shallow';
import {
  actionsSelector,
  closeDelayTimerIdSelector,
  isAnyTooltipOpenSelector,
  openDelayTimerIdsSelector,
  openDelayTimerIdSelector,
  suggestionTooltipSelector,
  tooltipsSelector,
  closeDelayTimerIdsSelector,
  publicActionsSelector,
  isTooltipOpenSelector,
  nextTooltipIdSelector,
} from './selectors';
import {
  SuggestedClipTooltipState,
  SuggestedClipTooltipStore,
  UseSuggestedClipTooltipConfig,
} from './types';

const SUGGESTED_CLIP_TOOLTIP_SHOW_DELAY_MILLIS = 100;
const SUGGESTED_CLIP_TOOLTIP_HIDE_DELAY_MILLIS = 500;

const defaultState: SuggestedClipTooltipState = {
  closeDelayIds: {},
  nextTooltipId: 0,
  openDelayIds: {},
  tooltips: {},
};

type StoreProduce = (
  recipe: (store: SuggestedClipTooltipStore) => void,
) => SuggestedClipTooltipStore;

const useSuggestedClipTooltipStore = create<SuggestedClipTooltipStore>()(
  (zSet, get) => {
    const set = flowRight(zSet, produce as StoreProduce);

    return {
      ...defaultState,
      actions: {
        _cancelAllTimers(tooltipId) {
          const actions = actionsSelector(get());
          actions._cancelDelayedClose(tooltipId);
          actions._cancelDelayedOpen(tooltipId);
        },
        _cancelDelayedClose(tooltipId) {
          const timerId = closeDelayTimerIdSelector(get(), tooltipId);
          window.clearTimeout(timerId);
          set((store) => {
            const timerIds = closeDelayTimerIdsSelector(store);
            delete timerIds[tooltipId];
          });
        },
        _cancelDelayedOpen(tooltipId) {
          const timerId = openDelayTimerIdSelector(get(), tooltipId);
          window.clearTimeout(timerId);
          set((store) => {
            const timerIds = openDelayTimerIdsSelector(store);
            delete timerIds[tooltipId];
          });
        },
        _closeAllTooltips() {
          const actions = actionsSelector(get());
          const tooltips = tooltipsSelector(get());

          map(tooltips, (val) => {
            actions._cancelAllTimers(val.tooltipId);
          });

          set((store) => {
            store.tooltips = defaultState.tooltips;
          });
        },
        _closeTooltip(tooltipId) {
          const actions = actionsSelector(get());
          actions._cancelAllTimers(tooltipId);
          set((store) => {
            const tooltips = tooltipsSelector(store);
            delete tooltips[tooltipId];
          });
        },
        _delayedClose(tooltipId) {
          const timerId = window.setTimeout(() => {
            const actions = actionsSelector(get());
            actions._closeTooltip(tooltipId);
          }, SUGGESTED_CLIP_TOOLTIP_HIDE_DELAY_MILLIS);

          set((store) => {
            const timerIds = closeDelayTimerIdsSelector(store);
            timerIds[tooltipId] = timerId;
          });
        },
        _delayedOpen(tooltipId, suggestionId) {
          const timerId = window.setTimeout(() => {
            const actions = actionsSelector(get());
            actions._openTooltip(tooltipId, suggestionId);
          }, SUGGESTED_CLIP_TOOLTIP_SHOW_DELAY_MILLIS);

          set((store) => {
            const timerIds = openDelayTimerIdsSelector(store);
            timerIds[tooltipId] = timerId;
          });
        },
        _openTooltip(tooltipId, suggestionId) {
          set((store) => {
            const tooltips = tooltipsSelector(store);
            tooltips[tooltipId] = { tooltipId, suggestionId };
          });
        },
        closeTooltip(tooltipId, suggestionId, immediate) {
          const currentTooltip = suggestionTooltipSelector(get(), suggestionId);
          const actions = actionsSelector(get());

          if (currentTooltip && currentTooltip.tooltipId !== tooltipId) {
            actions.closeTooltip(
              currentTooltip.tooltipId,
              currentTooltip.suggestionId,
            );
          } else {
            actions._cancelDelayedOpen(tooltipId);

            if (immediate) {
              actions._closeTooltip(tooltipId);
            } else {
              actions._delayedClose(tooltipId);
            }
          }
        },
        identify() {
          const id = nextTooltipIdSelector(get());
          set((store) => {
            store.nextTooltipId += 1;
          });
          return id;
        },
        openTooltip(tooltipId, suggestionId) {
          const currentTooltip = suggestionTooltipSelector(get(), suggestionId);
          const actions = actionsSelector(get());

          if (currentTooltip && currentTooltip.tooltipId !== tooltipId) {
            actions._cancelDelayedClose(currentTooltip.tooltipId);
          } else {
            actions._cancelDelayedClose(tooltipId);

            if (!isAnyTooltipOpenSelector(get())) {
              actions._delayedOpen(tooltipId, suggestionId);
            } else {
              actions._closeAllTooltips();
              actions._openTooltip(tooltipId, suggestionId);
            }
          }
        },
        removeTooltip(tooltipId) {
          const actions = actionsSelector(get());

          actions._cancelAllTimers(tooltipId);
          actions._closeTooltip(tooltipId);
        },
      },
    };
  },
);

// NB: `shallow` is needed because this store has private and public actions
// inside of the `actions` key.  selecting only the public actions creates a new
// object every time the selector is run.  `shallow` will avoid returning a new
// actions object every time and will avoid some unnecessary re-renders
export const useSuggestedClipTooltipActions = () =>
  useSuggestedClipTooltipStore(publicActionsSelector, shallow);

const useTooltipId = () => {
  const { identify } = useSuggestedClipTooltipActions();
  return useMemo(() => identify(), [identify]);
};

export const useIsTooltipOpen = (tooltipId: number) =>
  useSuggestedClipTooltipStore(
    useCallback(
      (store) => isTooltipOpenSelector(store, tooltipId),
      [tooltipId],
    ),
  );

export const useSuggestedClipTooltip = ({
  suggestionId,
}: UseSuggestedClipTooltipConfig) => {
  const tooltipId = useTooltipId();
  const actions = useSuggestedClipTooltipActions();
  const isOpen = useIsTooltipOpen(tooltipId);

  const { closeTooltip, openTooltip, removeTooltip } = actions;

  useEffect(
    () => () => {
      removeTooltip(tooltipId);
    },
    [removeTooltip, tooltipId],
  );

  const boundActions = useMemo(
    () => ({
      closeTooltip: (immediate?: boolean) =>
        closeTooltip(tooltipId, suggestionId, immediate),
      openTooltip: () => openTooltip(tooltipId, suggestionId),
    }),
    [closeTooltip, openTooltip, suggestionId, tooltipId],
  );

  return useMemo(() => ({ isOpen, ...boundActions }), [boundActions, isOpen]);
};
