import { Spacer, getThemeColor } from '@sparemin/blockhead';
import styled from 'styled-components';
import { TAB_PANEL_MAX_WIDTH_PX } from '../BaseTabPanel/constants';

export const Root = styled(Spacer).attrs({
  orientation: 'vertical',
  align: 'center',
  justify: 'center',
  space: '30px',
})`
  width: ${TAB_PANEL_MAX_WIDTH_PX}px;
  max-width: ${TAB_PANEL_MAX_WIDTH_PX}px;
  height: 400px;
  border-radius: 24px;
  background-color: ${getThemeColor('white')};
`;
