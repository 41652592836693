import { useHotkeyManager } from '../../../state/hotkey';
import EditorContextMenu from '../EditorContextMenu';
import useContextMenuOrchestration from './useContextMenuOrchestration';
import useModalOrchestration from './useModalOrchestration';
import useScrollSync from './useScrollSync';
import useSyncTextWithAudio from './useSyncTextWithAudio';
import useTextSelection from './useTextSelection';
import useUndoRedo from './useUndoRedo';

export interface EditorOrchestratorProps {}

const EditorOrchestrator: React.FC<EditorOrchestratorProps> = () => {
  useContextMenuOrchestration();
  useModalOrchestration();
  useSyncTextWithAudio();
  useScrollSync();
  useTextSelection();
  useHotkeyManager();
  useUndoRedo();

  return <EditorContextMenu />;
};

export default EditorOrchestrator;
