import { css } from 'styled-components';
import { TransitionProps } from 'styles/types';
import { getTransitionDuration } from './utils';

export interface SlideConfig {
  delayMillis?: number;
  durationMillis?: number;
  easingFunction?: string;
  startPosition: {
    x?: string;
    y?: string;
  };
  endPosition: {
    x?: string;
    y?: string;
  };
}

export default function slide(config?: SlideConfig) {
  const {
    delayMillis = 0,
    durationMillis,
    easingFunction = 'ease-in-out',
    startPosition,
    endPosition,
  } = config ?? {};

  const { x: startX = 0, y: startY = 0 } = startPosition ?? {};
  const { x: endX = 0, y: endY = 0 } = endPosition ?? {};

  return css<TransitionProps>`
    transition: transform ${getTransitionDuration(durationMillis)}
      ${easingFunction} ${delayMillis}ms;

    transform: translate(${startX}, ${startY});

    ${({ status }) => {
      if (status === 'entered' || status === 'entering') {
        return `transform: translate(${endX}, ${endY});`;
      }

      if (status === 'exiting' || status === 'exited') {
        return `transform: translate(${startX}, ${startY});`;
      }
    }}
  `;
}
