import { useCallback } from 'react';
import { FileInfo } from 'components/MediaUploader';
import { useEventTracking } from 'state/eventTracking';
import { usePlanUploadMaxValues } from 'state/pricing';
import {
  MAX_FILE_DURATION_HR,
  MAX_FILE_DURATION_SEC,
  MAX_FILE_SIZE_MB,
} from './constants';
import { getErrorByReason } from './ProjectMediaUploader/utils';

export interface UseTrackFileUploadErrorsResult {
  trackFileErrors: (fileInfo: FileInfo) => void;
}

export default function useTrackFileUploadErrors(): UseTrackFileUploadErrorsResult {
  const { data: uploadLimits } = usePlanUploadMaxValues();
  const { trackErrorNotification } = useEventTracking();

  const maxMediaDurationHr = uploadLimits?.maxMediaDurationHr;
  const maxSizeMB = uploadLimits?.maxUploadSizeMB;

  const handleGetSizeError = useCallback(
    (sizeMb: number) => {
      if (!maxMediaDurationHr || !maxSizeMB) {
        return null;
      }

      if (sizeMb > MAX_FILE_SIZE_MB) {
        return getErrorByReason(MAX_FILE_SIZE_MB, maxMediaDurationHr).TOO_LARGE;
      }

      if (sizeMb >= maxSizeMB) {
        return getErrorByReason(maxSizeMB, maxMediaDurationHr).TOO_LARGE;
      }

      return null;
    },
    [maxMediaDurationHr, maxSizeMB],
  );

  const handleGetDurationError = useCallback(
    (durationSec: number) => {
      if (!maxMediaDurationHr || !maxSizeMB) {
        return null;
      }

      if (durationSec > MAX_FILE_DURATION_SEC) {
        return getErrorByReason(MAX_FILE_DURATION_HR, maxMediaDurationHr)
          .TOO_LONG;
      }

      if (durationSec >= maxMediaDurationHr * 3600) {
        return getErrorByReason(maxSizeMB, maxMediaDurationHr).TOO_LONG;
      }

      return null;
    },
    [maxMediaDurationHr, maxSizeMB],
  );

  const handleTrackFileErrors = useCallback(
    ({ sizeMb, durationSec }: FileInfo): void => {
      if (!maxSizeMB || !maxMediaDurationHr) {
        return;
      }

      const errors = [
        handleGetSizeError(sizeMb),
        handleGetDurationError(durationSec),
      ];

      errors.forEach((error) => {
        if (error && error.errorCode) {
          trackErrorNotification(error.errorCode, error.heading);
        }
      });
    },
    [
      handleGetDurationError,
      handleGetSizeError,
      maxMediaDurationHr,
      maxSizeMB,
      trackErrorNotification,
    ],
  );

  return {
    trackFileErrors: handleTrackFileErrors,
  };
}
