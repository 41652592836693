import { DeleteOutline, PopoverMenu } from '@sparemin/blockhead';
import styled from 'styled-components';
import TextButton from 'components/TextButton';

export const Menu = styled(PopoverMenu)`
  display: flex;
  pointer-events: auto;
`;

export const DeleteIcon = styled(DeleteOutline)`
  width: 24px;
  height: 16px;
  margin: 0 8px 0 0;
`;

export const Button = styled(TextButton)`
  padding: 12px;
`;
