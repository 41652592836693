import React, { useCallback } from 'react';
import { useEditTranscript } from 'pages/TranscriptEditorPage/state';
import { useContextMenuActions } from 'pages/TranscriptEditorPage/state/contextMenu';
import * as S from './styles';

export interface DeleteSpeakerButtonProps {
  className?: string;
  segmentId?: number;
}

const DeleteSpeakerButton = React.forwardRef<
  HTMLButtonElement,
  DeleteSpeakerButtonProps
>(({ className, segmentId }, ref) => {
  const { deleteSegment } = useEditTranscript();
  const { closeContextMenu } = useContextMenuActions();

  const handlePress = useCallback(() => {
    if (!segmentId) {
      return;
    }

    deleteSegment(segmentId);
    closeContextMenu();
  }, [closeContextMenu, deleteSegment, segmentId]);

  return (
    <S.IconButton
      aria-label="delete speaker"
      className={className}
      onPress={handlePress}
      ref={ref}
    >
      <S.Icon />
    </S.IconButton>
  );
});

export default DeleteSpeakerButton;
