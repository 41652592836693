import { Timeline } from '@sparemin/blockhead';
import React, { useCallback } from 'react';
import {
  isWordsContextMenuConfig,
  useContextMenuConfig,
} from 'pages/TranscriptEditorPage/state/contextMenu';
import { useModalsActions } from 'state/modal';

import ActionButton from '../../ActionButton';

export interface AdjustSelectionActionButtonProps {}

const AdjustSelectionActionButton: React.FC<
  AdjustSelectionActionButtonProps
> = () => {
  const config = useContextMenuConfig();
  const { pushModal } = useModalsActions();

  const handlePress = useCallback(() => {
    if (!isWordsContextMenuConfig(config)) {
      return;
    }

    pushModal({
      name: 'AdjustSelection',
      params: {
        wordId: config.words[0],
      },
    });
  }, [config, pushModal]);

  return (
    <ActionButton
      aria-label="trim selection"
      onPress={handlePress}
      tooltip="Trim selection"
    >
      <ActionButton.Icon as={Timeline} />
    </ActionButton>
  );
};

export default AdjustSelectionActionButton;
