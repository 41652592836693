import { useContext } from 'react';
import { IntroOutroContext } from './IntroOutroContext';

export default function useIntroOutro() {
  const context = useContext(IntroOutroContext);

  if (context === undefined) {
    throw new Error(
      'useIntroOutroContext must be used within IntroOutrotProvider',
    );
  }

  return context;
}
