import { useCallback, useMemo, useState } from 'react';
import { useWordList, useWordsById } from '../../state';
import { useContextMenuConfig } from '../../state/contextMenu';
import * as S from '../styles';
import ActionMenuContents from './ActionMenuContents';
import TextEditMenuContents from './TextEditMenuContents/TextEditMenuContents';
import { useTrackWordContextMenu } from './useTrackWordContextMenu';
import { getContextMenuActions } from './utils';

export interface WordContextMenuProps {}

const WordContextMenu: React.FC<WordContextMenuProps> = () => {
  const [showWordEditMenu, setShowWordEditMenu] = useState(false);

  const config = useContextMenuConfig();

  const { data: wordsById } = useWordsById();
  const { data: wordList } = useWordList();

  const actions = useMemo(
    () => getContextMenuActions(config, wordsById, wordList),
    [config, wordList, wordsById],
  );

  useTrackWordContextMenu(wordsById, config);

  const handleEditPress = useCallback(() => {
    setShowWordEditMenu(true);
  }, []);

  const handleEditSubmit = useCallback(() => {
    setShowWordEditMenu(false);
  }, []);

  return (
    <S.Menu
      key={`${showWordEditMenu}-${actions}`}
      size={showWordEditMenu ? 'medium' : 'large'}
    >
      {showWordEditMenu ? (
        <TextEditMenuContents onSubmit={handleEditSubmit} />
      ) : (
        <ActionMenuContents actions={actions} onEditPress={handleEditPress} />
      )}
    </S.Menu>
  );
};

export default WordContextMenu;
