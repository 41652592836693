import { ContainerProps, Spacer } from '@sparemin/blockhead';
import React from 'react';
import * as S from './styles';

export interface PlateProps extends ContainerProps<typeof Spacer> {}

const Plate = React.forwardRef<HTMLDivElement, PlateProps>(
  (
    {
      align = 'center',
      cornerRadius = '100px',
      gutter = 2,
      justify = 'center',
      ...props
    },
    ref,
  ) => (
    <S.Root
      {...{ align, cornerRadius, gutter, justify }}
      {...props}
      ref={ref}
    />
  ),
);

export default Plate;
