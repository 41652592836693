import axios from 'axios';

export const downloadLink = async (
  filename: string,
  url: string,
  onDownloadStart?: () => void,
  fileExtension?: string,
): Promise<void> => {
  const response = await axios.get<Blob>(url, {
    responseType: 'blob',
  });

  const modifiedFilename = fileExtension
    ? `${filename}.${fileExtension}`
    : filename;

  return downloadBlob(modifiedFilename, response.data, onDownloadStart);
};

export const downloadBlob = (
  filename: string,
  blob: Blob,
  onDownloadStart?: () => void,
): void => {
  const href = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = href;

  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);

  onDownloadStart?.();
};
