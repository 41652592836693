import styled from 'styled-components';
import intro from 'assets/intro.png';
import intro2x from 'assets/intro@2x.png';
import intro3x from 'assets/intro@3x.png';
import BaseIntroOutroUploader from 'pages/TranscriptEditorPage/IntroOutroUploader';

export const IntroIcon = styled('img').attrs({
  src: intro,
  srcSet: `${intro} 1x, ${intro2x} 2x, ${intro3x} 3x`,
  alt: '',
})`
  width: 100px;
  height: 100px;
`;

export const OutroIcon = styled(IntroIcon)`
  transform: scaleX(-1);
`;

export const IntroOutroUploader = styled(BaseIntroOutroUploader)`
  padding: 48px 65px 50px;
`;
