import { useIsIntegrator } from 'state/user';
import * as S from './styles';

export interface ShareInitialActionButtonsLayoutProps {
  buttons: React.ReactNode[];
}

const ShareInitialActionButtonsLayout = ({
  buttons,
}: ShareInitialActionButtonsLayoutProps) => {
  const { data: isIntegrator } = useIsIntegrator();

  const middleIndex = Math.floor(buttons.length / 2);

  return !isIntegrator ? (
    <S.ActionsRow>{buttons}</S.ActionsRow>
  ) : (
    <>
      {/* First line will always have less or the same amount of items compared to the second line.  */}
      <S.ActionsRow>
        {buttons.slice(0, middleIndex).map((button) => button)}
      </S.ActionsRow>

      <S.ActionsRow>
        {buttons.slice(middleIndex, buttons.length).map((button) => button)}
      </S.ActionsRow>
    </>
  );
};

export default ShareInitialActionButtonsLayout;
