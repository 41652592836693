import { debounce } from 'lodash-es';
import { useEffect } from 'react';
import { useEditorUiActions } from '../state/editorUi';

/**
 * Synchronizes the scrolling state with the editorUiStore
 */
export default function useScrollSync() {
  const { toggleIsScrolling } = useEditorUiActions();

  // this effect handles setting the `scrolling` value in the store.  the hook
  // waits until scrolling stops for a certain amount of time before it determines
  // that scrolling is finished
  useEffect(() => {
    const clearScroll = debounce(() => {
      toggleIsScrolling(false);
    }, 250);

    const handleScroll = () => {
      toggleIsScrolling(true);
      clearScroll();
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [toggleIsScrolling]);
}
