import {
  PRO_TIER_MAX_MEDIA_DURATION_HOURS,
  PRO_TIER_MAX_MEDIA_SIZE_GB,
} from 'config';
import { usePlanUploadMaxValues } from 'state/pricing';

export default function useDefaultUploadLimitMessage(): string {
  const { data: uploadLimits } = usePlanUploadMaxValues();

  const maxMediaDurationHr = uploadLimits?.maxMediaDurationHr;
  const maxSizeMB = uploadLimits?.maxUploadSizeMB;

  return `Your current plan only permits files up to ${maxSizeMB}MB and ${maxMediaDurationHr} hours long. Upgrade today to upload files up to ${PRO_TIER_MAX_MEDIA_SIZE_GB}GB and ${PRO_TIER_MAX_MEDIA_DURATION_HOURS} hours long.`;
}
