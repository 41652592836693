import { FormEvent, useCallback, useRef, useState } from 'react';
import { notifyError } from 'components/notification/showNotification';
import TextCorrectionInput from 'pages/TranscriptEditorPage/EditorContextMenu/TextCorrectionInput';
import {
  useEditTranscript,
  useWordsById,
} from 'pages/TranscriptEditorPage/state';
import { createFoundWordsEdits } from 'pages/TranscriptEditorPage/utils';
import { useSearchActionMenu } from '../SearchActionMenuContext';

export interface UpdateResultsMenuContentsProps {}

const UpdateResultsMenuContents: React.FC<
  UpdateResultsMenuContentsProps
> = () => {
  const [updateAll, setUpdateAll] = useState(true);
  const [value, setValue] = useState('');

  const { foundWords, activeWordIndex, onUpdate } = useSearchActionMenu();
  const { updateSearchResults } = useEditTranscript();
  const { data: wordsById } = useWordsById();

  const inputRef = useRef<HTMLInputElement>(null);

  const handleSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();

      if (!wordsById) {
        return;
      }

      const foundWordGroups = createFoundWordsEdits(
        updateAll ? foundWords : [foundWords[activeWordIndex]],
        wordsById,
        value,
      );

      updateSearchResults(foundWordGroups, () =>
        notifyError({ heading: 'Error correcting search results' }),
      );

      onUpdate(value, foundWords);
    },
    [
      activeWordIndex,
      foundWords,
      onUpdate,
      updateAll,
      updateSearchResults,
      value,
      wordsById,
    ],
  );

  return (
    <TextCorrectionInput
      label="update text"
      placeholder="Replace with..."
      value={value}
      showUpdateAllButton={true}
      updateAll={updateAll}
      onChange={setValue}
      onUpdateAll={setUpdateAll}
      onSubmit={handleSubmit}
      width={382}
      ref={inputRef}
    />
  );
};

export default UpdateResultsMenuContents;
