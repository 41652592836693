import { useMutation } from 'react-query';
import { request } from 'api/http';
import { CreateProjectResult, directUpload } from 'api/services';
import { createProject } from 'api/services';
import { DirectUploadResult } from 'api/services/mediaUpload/types';
import { EddyPref } from 'state/userPref/types';
import { MutationOptions } from 'types';
import { getMediaTypeFromFileType } from 'utils/fileUpload';
import { range } from 'utils/math';
import { removeFileExt } from 'utils/string';
import { getEddyUploadFileType } from './utils';

export interface CreateProjectFromMediaArgs {
  files: File[];
  language?: string;
  onUploadProgress?: (progress: number) => void;
  projectName?: string;
  userEddyPrefs?: EddyPref;
  onUpload?: (file: File) => void;
  onUploadComplete?: (file: File, status: 'success' | 'failure') => void;
}

async function createProjectFromMedia({
  files,
  language,
  onUploadProgress,
  projectName,
  userEddyPrefs,
  onUpload,
  onUploadComplete,
}: CreateProjectFromMediaArgs) {
  // prepare files for upload sequentially
  const uploadResults = await files.reduce(async (promise, file) => {
    const resultSet = await promise;
    onUpload?.(file);
    try {
      const result = await directUpload({
        contentType: file.type,
        filename: file.name,
        fileType: getEddyUploadFileType(file.type),
      });
      return [...resultSet, result];
    } catch (err) {
      onUploadComplete?.(file, 'failure');
      throw err;
    }
  }, Promise.resolve<DirectUploadResult[]>([]));

  // use uploadResults to upload each individual file to s3.  this also happens
  // sequentially.  the overall upload progress reported by this hook takes into
  // account all files that are being uploaded
  await uploadResults.reduce(async (promise, result, index) => {
    const url = result.upload?.url;

    if (!url) {
      return promise;
    }

    try {
      const uploaded = await request.postForm(
        url,
        {
          ...result.upload?.fields,
          file: files[index],
        },
        {
          disableAuthHeaderInterceptor: true,
          onUploadProgress(e) {
            if (!onUploadProgress) {
              return;
            }

            // e.progress is in the range from [0, 1].  When handling multiple files,
            // the entire upload progress for all files combined uses the range
            // [0, 100] divided evenly by the number of files.  When uploading 2
            // files for example, the progress of the first file corresponds to the
            // progress range [0, 50] and the second file corresponds to the range
            // [50, 100].
            const fileUploadProgress = e.progress ?? 0;
            const progressIntervalSize = 100 / files.length;
            const progressIntervalStart = progressIntervalSize * index;
            const progressIntervalEnd =
              progressIntervalStart + progressIntervalSize;

            onUploadProgress(
              range(
                0,
                1,
                progressIntervalStart,
                progressIntervalEnd,
                fileUploadProgress,
              ),
            );
          },
        },
      );
      if (uploaded.status === 204) {
        onUploadComplete?.(files[index], 'success');
      } else {
        throw Error();
      }
    } catch (err) {
      onUploadComplete?.(files[index], 'failure');
      throw err;
    }
  }, Promise.resolve());

  // create the project
  return createProject({
    associatedMedias: uploadResults.map((r, i) => ({
      mediaType: getMediaTypeFromFileType(files[i].type) || 'audio',
      directUploadBucket: r.bucket,
      directUploadKey: r.key,
      originalFileName: files[i].name,
      language,
    })),
    name: projectName ?? removeFileExt(files[0].name),
    ...userEddyPrefs,
  });
}

/*
 * Eddy creates a project as a byproduct of uploading media.  This hook contains
 * all of the logic for going from a series of files to a project.
 */
export default function useCreateProjectFromMedia(
  opts?: MutationOptions<CreateProjectResult, CreateProjectFromMediaArgs>,
) {
  return useMutation<
    CreateProjectResult,
    unknown,
    CreateProjectFromMediaArgs,
    unknown
  >(createProjectFromMedia, opts);
}
