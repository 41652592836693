import { isNumber } from 'lodash-es';
import { useCallback } from 'react';
import { Query, useQuery } from 'react-query';

import { AudioExport, getAudioExport } from 'api/services';
import { DEFAULT_POLLING_INTERVAL_MILLIS } from 'config';
import { QueryOptions } from 'types';

import { eddyAudioExportQueryKeys } from './queryKeys';

type QueryKey = ReturnType<typeof eddyAudioExportQueryKeys.audioDownload>;

export interface UseGetAudioExportQuery<TData = AudioExport>
  extends QueryOptions<AudioExport, TData, QueryKey> {
  pollingInterval?: number;
}

const useGetAudioExport = <TData = AudioExport>(
  exportId: number,
  opts?: UseGetAudioExportQuery<TData>,
) => {
  const { pollingInterval = DEFAULT_POLLING_INTERVAL_MILLIS, ...queryOptions } =
    opts ?? {};

  const refetchInterval = useCallback(
    (
      _: TData | undefined,
      query: Query<AudioExport, unknown, AudioExport, QueryKey>,
    ) => {
      return query.state.data?.status === 'processing'
        ? pollingInterval
        : false;
    },
    [pollingInterval],
  );

  const enabled =
    isNumber(exportId) && exportId !== -1 && (opts?.enabled ?? true);

  return useQuery<AudioExport, unknown, TData, QueryKey>(
    eddyAudioExportQueryKeys.audioDownload(exportId),
    ({ queryKey: [{ audioExportId }] }) => getAudioExport({ audioExportId }),
    {
      enabled,
      meta: {
        errorMessage: 'Error exporting audio',
      },
      refetchInterval: refetchInterval,
      ...queryOptions,
    },
  );
};

export default useGetAudioExport;
