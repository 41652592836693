import { useHover, useMergedRefs, usePopover } from '@sparemin/blockhead';
import React, { useCallback, useRef } from 'react';
import { createPortal } from 'react-dom';
import { Transition } from 'react-transition-group';
import useDownloadClip from 'pages/TranscriptEditorPage/hooks/useDownloadClip';
import SuggestedClipPopover from '../SuggestedClipPopover';
import useClipSuggestionWords from '../useClipSuggestionWords';
import useSuggestionPopoverTracking from '../useSuggestionPopoverTracking';
import {
  SUGGESTED_CLIP_TOOLTIP_CLASSNAME,
  SUGGESTED_CLIP_TOOLTIP_TRANSITION_DURATION_MILLIS,
} from './constants';
import * as S from './styles';

export type SuggestedClipTooltipContentsProps = {
  isOpen: boolean;
  onHoverEnd?: () => void;
  onHoverStart?: () => void;
};

const SuggestedClipTooltipContents: React.FC<
  SuggestedClipTooltipContentsProps
> = ({ isOpen, onHoverEnd, onHoverStart }) => {
  const transitionRef = useRef<HTMLDivElement>(null);
  const { popoverAttributes, popoverRef, popoverStyle } = usePopover();
  const { downloadClip, status: downloadClipStatus } = useDownloadClip();
  const { onDownloadClip } = useSuggestionPopoverTracking();
  const [startWord, endWord] = useClipSuggestionWords();

  const { hoverProps } = useHover({ onHoverStart, onHoverEnd });
  const mergedRef = useMergedRefs([transitionRef, popoverRef]);

  const handleDownloadClip = useCallback(() => {
    onDownloadClip();
    downloadClip(startWord.id, endWord.id);
  }, [downloadClip, endWord.id, onDownloadClip, startWord.id]);

  return (
    <Transition
      in={isOpen}
      mountOnEnter
      nodeRef={transitionRef}
      timeout={SUGGESTED_CLIP_TOOLTIP_TRANSITION_DURATION_MILLIS}
      unmountOnExit
    >
      {(status) =>
        createPortal(
          <S.Popover
            {...popoverAttributes}
            {...hoverProps}
            className={SUGGESTED_CLIP_TOOLTIP_CLASSNAME}
            ref={mergedRef}
            status={status}
            style={popoverStyle}
            role="tooltip"
          >
            <SuggestedClipPopover
              startMillis={startWord.startMillis}
              endMillis={endWord.endMillis}
              downloadClipStatus={downloadClipStatus}
              onDownloadClip={handleDownloadClip}
            />
          </S.Popover>,
          document.body,
        )
      }
    </Transition>
  );
};

export default SuggestedClipTooltipContents;
