import { Heading, Reply } from '@sparemin/blockhead';
import { motion } from 'framer-motion';
import React, { useRef } from 'react';
import { SwitchTransition, Transition } from 'react-transition-group';
import useIsMobileViewport from 'hooks/useIsMobileViewport';
import { useEditorLoadingStatus } from '../../../state/useEditorLoadingStatus';
import { TRANSITION_DURATION_MILLIS } from '../../transition';
import useProjectLoaderFreeTierAnimation from '../useProjectLoaderFreeTierAnimation';
import PromoteMessage from './PromoteMessage';
import * as S from './styles';

export interface ProgressSectionProps {
  className?: string;
  onLoadingComplete?: () => void;
}

const ProgressSection = React.forwardRef<HTMLElement, ProgressSectionProps>(
  ({ className, onLoadingComplete }, ref) => {
    const viewRef = useRef<HTMLDivElement>(null);

    const { progressRing, progressMessage } =
      useProjectLoaderFreeTierAnimation();
    const isMobile = useIsMobileViewport();
    const editorStatus = useEditorLoadingStatus();

    const viewId = editorStatus === 'processing' ? 'progress' : 'cta';

    return (
      <S.Root className={className} ref={ref}>
        <SwitchTransition>
          <Transition
            appear={viewId !== 'progress'}
            entry={viewId !== 'progress'}
            key={viewId}
            mountOnEnter
            nodeRef={viewRef}
            unmountOnExit
            timeout={TRANSITION_DURATION_MILLIS}
          >
            {(progressTransitionStatus) =>
              viewId === 'progress' ? (
                <S.Progress ref={viewRef} status={progressTransitionStatus}>
                  {isMobile && <PromoteMessage />}

                  <motion.div {...progressRing}>
                    <S.ProgressRing />
                  </motion.div>

                  <S.ProgressMessage {...progressMessage} />
                </S.Progress>
              ) : (
                <S.CTA ref={viewRef} status={progressTransitionStatus}>
                  {!isMobile && (
                    <Heading level={1}>Your project is ready!</Heading>
                  )}

                  <S.GoToProjectButton
                    onClick={onLoadingComplete}
                    startIcon={<Reply />}
                  >
                    go to project
                  </S.GoToProjectButton>
                </S.CTA>
              )
            }
          </Transition>
        </SwitchTransition>
      </S.Root>
    );
  },
);

export default ProgressSection;
