import WaveSurfer from 'wavesurfer.js';
import { WaveSurferParams } from 'wavesurfer.js/types/params';
import { range } from 'utils/math';

/**
 * Custom WaveSurfer implementation that only renders the waveform for the visible
 * segment.
 *
 * To render a segment, first set the segment range with `wavesurfer.setRange()`.
 * Calling this method will render the segment to fill all available space.
 * As a further performance enhancement, rendering is blocked until
 * `wavesurfer.setRange()` is called for the first time, otherwise WaveSurfer will
 * waste cycles rendering the waveform from 0 before `setRange` is called.
 *
 * Note that there are still some inefficiencies here, mainly that wavesurfer will
 * still render tens, if not hundreds or even thousands of canvas elements, most
 * of which are largely unused.  Changing this implementation detail is difficult
 * since many wavesurfer features and pugins depend on the wave's scroll position.
 */
export default class SegmentWaveSurfer extends WaveSurfer {
  private rangeStart = 0;

  private rangeEnd = 0;

  /**
   * @override
   *
   * This method has to be overridden otherwise the underlying `create` will
   * create an instance of WaveSurfer instead of SegmentWaveSurfer
   */
  static create(params: WaveSurferParams) {
    const wavesurfer = new SegmentWaveSurfer(params);
    return wavesurfer.init();
  }

  /**
   * @override
   */
  public init() {
    super.init();
    return this;
  }

  /**
   * @override
   *
   * Custom drawBuffer implementation to draw only the visible waveform
   */
  public drawBuffer() {
    if (this.rangeStart === 0 && this.rangeEnd === 0) {
      return;
    }

    const pixelScale = this.params.minPxPerSec * this.params.pixelRatio;
    const widthInSec = this.drawer.getWidth() / this.params.minPxPerSec;

    const startSec = this.rangeStart;
    const endSec = this.rangeEnd;

    // when providing pre-rendered peaks (which is generally how we use use
    // wavesurfer), the parameters passed to this function have no effect on
    // what is returned -
    // https://github.com/wavesurfer-js/wavesurfer.js/blob/2ae31ce9ade32c07392c593186a82e3669686864/src/mediaelement.js#L356
    const peaks = this.backend.getPeaks(widthInSec, startSec, endSec);

    this.drawer.drawPeaks(
      peaks,
      this.getDuration() * this.params.minPxPerSec,
      startSec * this.params.minPxPerSec,
      endSec * this.params.minPxPerSec,
    );

    this.fireEvent('redraw', peaks, this.getDuration() * pixelScale);
  }

  public setRange(start: number, end: number) {
    this.rangeStart = start;
    this.rangeEnd = end;

    const rangeDuration = end - start;
    const rangeMid = (start + end) / 2;

    this.seekAndCenter(range(0, this.getDuration(), 0, 1, rangeMid));
    this.zoom(Math.ceil(this.drawer.getWidth() / rangeDuration));
  }

  /**
   * Gets pixels per second based on the minPxPerSec and pixe ratio.
   * This method should be called after the range has been set.
   * @returns {number} Pixels per second value (px/sec)
   */
  public getPixelsPerSecond(): number {
    return this.params.minPxPerSec / this.params.pixelRatio;
  }
}
