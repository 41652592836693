import { useEventListener } from 'hooks/useEventListener';
import { useRegisterKeybinding } from 'state/hotkey';

interface UseRegisterSearchKeyBindingsProps {
  isOpen: boolean;
  onToggleOn: () => void;
  onToggleOff: () => void;
  onNextClick: () => void;
  onPreviousClick: () => void;
}

/**
 * Handles register all the key bindings for the Search Bar controls.
 */
export function useRegisterSearchKeyBindings(
  props: UseRegisterSearchKeyBindingsProps,
) {
  const { isOpen, onToggleOn, onToggleOff, onNextClick, onPreviousClick } =
    props;

  useRegisterKeybinding('/', onToggleOn);

  // The following listeners needs to be created globally because
  // 'react-aria' (which is used by the search component) already
  // handles the interactions with the 'Escape' and 'Enter' keys and
  // stops the progation for the events that they fire so it's not possible
  // to use 'useRegisterKeybinding' as it never gets called.
  // By intercepting these keyboard events with 'capture' we are able to
  // handle these events before 'react-aria' does.
  useEventListener(
    'keydown',
    (event) => {
      const key = event?.key;

      switch (key) {
        case 'Escape':
          return onToggleOff();

        case 'Enter': {
          if (event?.getModifierState('Shift')) {
            return onPreviousClick();
          }

          return onNextClick();
        }
      }
    },
    {
      disabled: !isOpen,
      options: true,
    },
  );
}

export default useRegisterSearchKeyBindings;
