import { QueryStatus } from 'react-query';

export function queryStatusResolver(statusList: QueryStatus[]): QueryStatus {
  if (statusList.includes('error')) {
    return 'error';
  }

  if (statusList.includes('loading')) {
    return 'loading';
  }

  if (statusList.every((status) => status === 'idle')) {
    return 'idle';
  }

  if (
    statusList.every((status) => status === 'success') ||
    (statusList.includes('idle') && statusList.includes('success'))
  ) {
    return 'success';
  }

  return 'idle';
}
