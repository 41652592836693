import { Heading } from '@sparemin/blockhead';
import React from 'react';
import * as S from './styles';

export interface ProjectLoaderMessageProps {
  className?: string;
}

const ProjectLoaderMessage = React.forwardRef<
  HTMLDivElement,
  ProjectLoaderMessageProps
>(({ ...props }, ref) => (
  <S.Container
    ref={ref}
    orientation="vertical"
    align="center"
    space="13px"
    {...props}
  >
    <Heading level={1}>We're transcribing your project</Heading>
    <Heading level={2}>
      Transcription & speaker separation take ~1/3 the length of the audio.
    </Heading>
  </S.Container>
));

export default ProjectLoaderMessage;
