import { useCallback, useMemo } from 'react';
import { DownloadFormatting, TranscriptFormat } from 'api/services/transcript';
import OptionsList from 'components/OptionsList';
import { OptionItem } from 'components/OptionsList/types';
import {
  checkIsFileTypeSupportedForIncludeTimestamps,
  checkIsFileTypeUnsupportedForYouTubeFormatting,
} from './utils';

export interface FormattingOptionsListProps {
  format: TranscriptFormat;
  downloadFormatting: DownloadFormatting;
  onChange: (value: DownloadFormatting) => void;
}

const FormattingOptionsList: React.FC<FormattingOptionsListProps> = ({
  format,
  downloadFormatting,
  onChange,
}) => {
  const {
    includeSpeakerLabels,
    shouldFormatForYoutube,
    includeStaticTimeMarkers,
  } = downloadFormatting;
  const isSpeakerLabelsIncluded = includeSpeakerLabels;
  const isUnsupportedFormat =
    checkIsFileTypeUnsupportedForYouTubeFormatting(format);

  const isTimestampsSupported =
    checkIsFileTypeSupportedForIncludeTimestamps(format);

  const labelFormattingItems = useMemo((): OptionItem[] => {
    return [
      {
        key: 'includeSpeakerLabels',
        value: includeSpeakerLabels,
        label: 'Include speaker labels',
      },
      {
        key: 'shouldFormatForYoutube',
        value: includeSpeakerLabels && shouldFormatForYoutube,
        label: 'Format for YouTube (adds “>>” before labels)',
        tooltipContents: !isSpeakerLabelsIncluded
          ? 'This option is not available if speaker labels are not included'
          : isUnsupportedFormat
          ? 'This option is not available for the selected file type'
          : null,
        isDisabled: !isSpeakerLabelsIncluded || isUnsupportedFormat,
      },
      {
        key: 'includeStaticTimeMarkers',
        value: includeStaticTimeMarkers,
        label: 'Include timestamps (adds “HH:MM:SS” every 5 minutes)',
        tooltipContents: !isTimestampsSupported
          ? 'This option is not available for the selected file type'
          : null,
        isDisabled: !isTimestampsSupported,
      },
    ];
  }, [
    includeSpeakerLabels,
    isSpeakerLabelsIncluded,
    isUnsupportedFormat,
    shouldFormatForYoutube,
    includeStaticTimeMarkers,
    isTimestampsSupported,
  ]);

  const createHandleLabelFormattingChange = useCallback(
    (key: string, isSelected: boolean) => {
      onChange({ ...downloadFormatting, [key]: isSelected });
    },
    [downloadFormatting, onChange],
  );

  return (
    <OptionsList
      label="Speaker label formatting"
      items={labelFormattingItems}
      onChange={createHandleLabelFormattingChange}
    />
  );
};

export default FormattingOptionsList;
