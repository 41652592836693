import { Spacer, SpacerProps } from '@sparemin/blockhead';
import React from 'react';
import TakeoverView from 'components/TakeoverView';

export interface ProjectLoaderTakeoverViewProps extends SpacerProps {}

const ProjectLoaderTakeoverView = React.forwardRef<
  HTMLDivElement,
  ProjectLoaderTakeoverViewProps
>((props, ref) => (
  <TakeoverView
    align="center"
    as={Spacer}
    orientation="vertical"
    ref={ref}
    {...props}
  />
));

export default ProjectLoaderTakeoverView;
