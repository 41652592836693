import { useEffect } from 'react';
import { useHasModal } from 'state/modal';
import { useAudioPlayer } from '../AudioPlayerContext';
import {
  useContextMenuActions,
  useContextMenuIsOpen,
} from '../state/contextMenu';
import { useActiveExplorer } from '../state/explorerBar';

export default function useContextMenuOrchestration() {
  const { closeContextMenu, blockContextMenu, unblockContextMenu } =
    useContextMenuActions();
  const contextMenuIsOpen = useContextMenuIsOpen();
  const { player } = useAudioPlayer();

  const hasModal = useHasModal();
  const activeExplorer = useActiveExplorer();
  const isMenuOpen = activeExplorer !== undefined || hasModal;

  useEffect(() => {
    // Context menu should be closed and not allowed to open during playback.
    const handlePlay = () => blockContextMenu();
    // Context menu should be allowed to open while not during playback.
    const handlePause = () => unblockContextMenu();

    player.on('play', handlePlay);
    player.on('pause', handlePause);

    return () => {
      player.off('play', handlePlay);
      player.off('pause', handlePause);
    };
  }, [blockContextMenu, player, unblockContextMenu]);

  useEffect(() => {
    // Context menu should be closed when a modal is opened.  Generally, useRangeSelection
    // will close the context menu when the user clicks something that is not a word
    // or range of words, however some menus and modals are opened without a click event
    // (e.g. programmatically or via shortcut keys).  This block handles those cases and
    // closes the menu.
    if (contextMenuIsOpen && isMenuOpen) {
      closeContextMenu();
    }
  }, [closeContextMenu, contextMenuIsOpen, isMenuOpen]);
}
