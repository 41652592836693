import { StatusTagColor, Trash, useListItem } from '@sparemin/blockhead';
import { useCallback, useMemo } from 'react';
import { BaseProject, ProjectStatus } from 'api/services';
import { ONE_HOUR_IN_MILLIS } from 'config';
import useDeleteProject from 'pages/ProjectsPage/state/useDeleteProject';
import {
  DEFAULT_DURATION_FORMAT,
  formatDate,
  formatDuration,
} from 'utils/time';
import ProjectRowSkeleton from './ProjectRowSkeleton';
import * as S from './styles';

export interface ProjectRowProps {
  project?: BaseProject;
}

const STATUS_COLOR_MAP: Record<
  Exclude<ProjectStatus, 'completed'>,
  StatusTagColor
> = {
  error: 'danger',
  processing: 'secondary',
};

const ProjectRow: React.FC<ProjectRowProps> = ({ project }) => {
  const { isDisabled } = useListItem();
  const { mutate: deleteProject } = useDeleteProject();

  const thumbnailAspectRatio = project
    ? project.thumbnail.width / project.thumbnail.height
    : 1;

  const handleDeleteProject = useCallback(() => {
    if (project?.projectCuid) {
      deleteProject(project.projectCuid);
    }
  }, [deleteProject, project?.projectCuid]);

  const mediaDuration = useMemo<string>(() => {
    const duration = project?.mediaDurationMillis || 0;

    const durationFormat =
      duration < ONE_HOUR_IN_MILLIS ? 'mm:ss' : DEFAULT_DURATION_FORMAT;

    return formatDuration(duration, durationFormat);
  }, [project?.mediaDurationMillis]);

  return (
    <>
      {!project ? (
        <ProjectRowSkeleton />
      ) : (
        <S.Root data-disabled={isDisabled}>
          <S.ImageContainer>
            <S.Image
              alt="project artwork"
              ratio={thumbnailAspectRatio}
              src={project?.thumbnail.imageUrl}
              basis={thumbnailAspectRatio < 1 ? 'height' : 'width'}
            />
          </S.ImageContainer>
          <S.Description>
            <S.ProjectTitle>{project.name}</S.ProjectTitle>
            <S.Meta>
              {formatDate(project.createdAt, 'MMM D, YYYY')}
              {' • '}
              {mediaDuration}
            </S.Meta>
          </S.Description>
          {project.status !== 'completed' && (
            <S.Status color={STATUS_COLOR_MAP[project.status]}>
              {project.status}
            </S.Status>
          )}
          <S.ActionButton
            isDisabled={project.status === 'processing'}
            onPress={handleDeleteProject}
          >
            <Trash />
          </S.ActionButton>
        </S.Root>
      )}
    </>
  );
};

export default ProjectRow;
