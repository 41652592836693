import { random } from 'lodash-es';
import React, { memo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { SPEAKER_SEGMENT_SPACING_MULTIPLIER } from './constants';
import SegmentChapters from './SegmentChapters';
import * as S from './styles';

export interface SpeakerSegmentProps {
  className?: string;
  'data-index'?: number;
  /**
   * passing undefined will render the segment as a skeleton
   */
  segmentId: number | undefined;
}

const SpeakerSegment = memo(
  React.forwardRef<HTMLDivElement, SpeakerSegmentProps>(
    ({ className, 'data-index': dataIndex, segmentId }, ref) => {
      return (
        <S.Root
          className={className}
          data-index={dataIndex}
          data-segment-id={segmentId}
          orientation="vertical"
          ref={ref}
          space={SPEAKER_SEGMENT_SPACING_MULTIPLIER}
        >
          {segmentId === undefined ? (
            <S.SkeletonContainer>
              <Skeleton count={random(1, 4, true)} />
            </S.SkeletonContainer>
          ) : (
            <SegmentChapters segmentId={segmentId} />
          )}
        </S.Root>
      );
    },
  ),
);

export default SpeakerSegment;
