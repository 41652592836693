import { FileDropzoneAccept, FileDropzoneProps } from '@sparemin/blockhead';
import { DROPZONE_ACCEPT } from './constants';
import DefaultMediaUploaderBody from './DefaultMediaUploaderBody';
import MediaUploaderBodyContainer from './MediaUploaderBodyContainer';
import * as S from './styles';

export type MediaUploaderProps = Omit<
  FileDropzoneProps,
  'resetInputOnReselect' | 'accept' | 'as'
> & {
  children?: React.ReactElement;
  accept?: FileDropzoneAccept;
};

export interface MediaUploaderComponent extends React.FC<MediaUploaderProps> {
  DefaultBody: typeof DefaultMediaUploaderBody;
  Contents: typeof MediaUploaderBodyContainer;
}

const MediaUploader: MediaUploaderComponent = ({
  children,
  accept = DROPZONE_ACCEPT,
  ...props
}) => (
  <S.Root accept={accept} resetInputOnReselect {...props}>
    {children}
  </S.Root>
);
MediaUploader.DefaultBody = DefaultMediaUploaderBody;
MediaUploader.Contents = MediaUploaderBodyContainer;
export default MediaUploader;
