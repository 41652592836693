import { WordEdit } from '../../../state';

export function createWordEdits(
  originalWordIds: number[],
  replacement: string,
): WordEdit[] {
  const edits: WordEdit[] = [];

  const newWords = replacement.split(/\s+/);

  // if there are more new words than old words, shove all of the "extra" new words
  // (tail) into the last time slot of the old words
  // for example -
  // original ['a', 'b']
  // replacements ['c', 'd', 'e']
  // final ['c', 'd e'] <-- notice "d e" is one string and the final array is of length 2.
  //                        "d e" effectively takes the place of "b"
  if (newWords.length > originalWordIds.length) {
    const tail = newWords.slice(originalWordIds.length - 1).join(' ');
    newWords.splice(originalWordIds.length - 1, Infinity, tail);
  }

  for (let i = 0; i < originalWordIds.length; i += 1) {
    const id = originalWordIds[i];
    const newWord = newWords[i];

    edits.push({ id, text: newWord });
  }
  return edits;
}
