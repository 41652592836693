import { breakpointDown } from '@sparemin/blockhead';
import styled from 'styled-components';

export const Root = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.base * 6}px;

  ${breakpointDown('md')`
    margin-bottom: ${({ theme }) => theme.spacing.base * 2}px;
  `}
`;
