import { Heading, HeadySparkle } from '@sparemin/blockhead';
import { useCallback } from 'react';
import useDownloadPromoPack from 'pages/TranscriptEditorPage/state/useDownloadPromoPack';
import { usePromoPackStatus } from 'pages/TranscriptEditorPage/state/useGetPromoPacks';
import { useEventTracking } from 'state/eventTracking';
import { useModalsActions } from 'state/modal';
import * as S from './styles';

const PromoPack: React.FunctionComponent = () => {
  const { trackDownloadPromoPack } = useEventTracking();
  const { status } = useDownloadPromoPack();
  const { data: promoPackStatus, isLoading: promoPackIsLoading } =
    usePromoPackStatus();
  const { pushModal } = useModalsActions();

  const isLoading = promoPackIsLoading || promoPackStatus === 'processing';

  const handleOpenPromoPackModal = useCallback((): void => {
    trackDownloadPromoPack();

    pushModal({
      name: 'PromoPack',
    });
  }, [pushModal, trackDownloadPromoPack]);

  return (
    <S.Root>
      <S.Contents>
        <S.IconContainer>
          <HeadySparkle width={100} height={78} />
        </S.IconContainer>

        <S.TextContainer>
          <Heading level={3}>
            {isLoading
              ? "We're making a promo pack for this episode!"
              : 'We made you a promo pack for this episode!'}
          </Heading>

          <S.Description>
            Headliner AI {isLoading ? 'is writing' : 'generated'}{' '}
            <span>show notes</span>, <span>keywords</span>, &{' '}
            <span>much more</span> for you ✨
          </S.Description>
        </S.TextContainer>
      </S.Contents>

      {isLoading ? (
        <S.Loader />
      ) : (
        <S.DownloadButton
          onPress={handleOpenPromoPackModal}
          isDisabled={status === 'loading'}
        >
          view your promo pack
        </S.DownloadButton>
      )}
    </S.Root>
  );
};

export default PromoPack;
