import * as S from './styles';

interface ModalIconProps {
  children: React.ReactNode;
  backgroundColor?: string;
  color?: string;
}

const ModalIcon: React.FunctionComponent<ModalIconProps> = (props) => {
  const { children, backgroundColor, color } = props;

  return (
    <S.ModalIcon
      align="center"
      justify="center"
      $backgroundColor={backgroundColor}
      $color={color}
    >
      {children}
    </S.ModalIcon>
  );
};

export default ModalIcon;
