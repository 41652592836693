import {
  getThemeColor,
  Link,
  Overlay,
  PopoverMenu,
  Spacer,
} from '@sparemin/blockhead';
import styled from 'styled-components';

export const Container = styled(Spacer).attrs({
  align: 'center',
  space: 3,
})`
  position: relative;
`;

export const ButtonContainer = styled(Spacer).attrs({
  orientation: 'horizontal',
  space: 2,
})`
  button {
    margin: 0;
    padding: 0;
    padding-top: 4px;
  }
`;

export const OverlayContainer = styled(Overlay)`
  z-index: -1;
`;

export const Menu = styled(PopoverMenu)`
  display: flex;
  pointer-events: auto;
`;

export const DeleteMenuContainer = styled(Spacer).attrs({
  orientation: 'vertical',
  space: 2,
})`
  padding: 10px;
`;

export const DeleteMenuItem = styled(Link).attrs({
  typography: 'text',
  variant: 'button',
})`
  color: ${getThemeColor('actionTertiary')};
`;
