import {
  Button,
  getThemeColor,
  Heading as BaseHeading,
  Link as BaseLink,
  Text,
  breakpointDown,
} from '@sparemin/blockhead';
import styled from 'styled-components';

export const Root = styled.section`
  align-items: center;
  background-color: ${getThemeColor('white')};
  display: flex;
  flex-grow: 1;
  justify-content: center;
  width: 100%;

  ${breakpointDown('md')`
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  `}
`;

export const Contents = styled.div`
  color: ${getThemeColor('d1')};
  display: flex;
  flex-direction: column;
  margin-bottom: 120px;
  margin-top: 60px;
  text-align: center;

  ${breakpointDown('md')`
    width: 100%;
    margin-top: 0;
  `}
`;

export const Heading = styled(BaseHeading).attrs({
  level: 3,
})`
  line-height: 1.44;
  margin-bottom: 3px;
`;

export const Subheading = styled(Text).attrs({
  variant: 'p',
})`
  line-height: 1.5;
  margin-bottom: 8px;
`;

export const Link = styled(BaseLink).attrs({
  typography: 'text',
  variant: 'p',
})`
  letter-spacing: normal;
  line-height: 1;
  padding: 0;
  text-transform: uppercase;
`;

export const LinkButton = styled(Link).attrs({
  as: Button,
})`
  &:active:not([disabled]) {
    box-shadow: none;
  }

  ${breakpointDown('md')`
    margin-top: 16px;
  `}
`;

export const LinksDivider = styled.span.attrs({
  children: '|',
})`
  margin: 0 8px;
`;

export const WidgetContainer = styled.div`
  align-items: center;
  min-height: 420px;
  justify-content: center;
  margin-top: 25px;
  width: 725px;

  // Breakpoint based on Disco's mobile breakpoint.
  // This component automatically adds a 20px padding on each side
  // when the viewport is smaller than 568px. This ensures that we
  // maintain the same padding on larger screens.
  @media (min-width: 568px) {
    padding: 0 20px;
  }

  ${breakpointDown('md')`
    width: 100%;
    min-height: auto;
    margin-top: 20px;
    flex-direction: column;
  `}
`;
