import {
  PopoverContainer,
  Overlay,
  PopoverContainerProps,
} from '@sparemin/blockhead';
import React from 'react';

export interface PopoverProps
  extends Pick<
    PopoverContainerProps,
    | 'isOpen'
    | 'offset'
    | 'triggerElement'
    | 'children'
    | 'placement'
    | 'updatePositionOnTriggerResize'
  > {}

const Popover: React.FC<PopoverProps> = ({
  isOpen,
  offset,
  triggerElement,
  placement,
  children,
  updatePositionOnTriggerResize,
}) => {
  return (
    <PopoverContainer
      isOpen={isOpen}
      offset={offset}
      triggerElement={triggerElement}
      placement={placement}
      updatePositionOnTriggerResize={updatePositionOnTriggerResize}
    >
      <Overlay allowClickthrough preventScrolling>
        {children}
      </Overlay>
    </PopoverContainer>
  );
};
export default Popover;
