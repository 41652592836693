import { useWordsById } from 'pages/TranscriptEditorPage/state';
import { useClipSuggestion } from '../../../../state/useClipSuggestionGroup';
import { useClipSuggestionContext } from '../ClipSuggestionContext';

export interface UseClipSuggestionWordsConfig {}

export default function useClipSuggestionWords() {
  const { suggestionId } = useClipSuggestionContext();
  const { data: suggestion } = useClipSuggestion(suggestionId);
  const { data: wordsById } = useWordsById();

  if (!suggestion || !wordsById) {
    return [];
  }

  const startWord = wordsById[suggestion.startWordId];
  const endWord = wordsById[suggestion.endWordId];

  return [startWord, endWord];
}
