import React from 'react';
import Helmet from 'components/Helmet';

export interface PageMetadataProps {
  title: string | undefined;
}

const PageMetadata: React.FC<PageMetadataProps> = ({ title = 'Eddy' }) => {
  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
};

export default PageMetadata;
