import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import {
  eddyTranscriptQueryKeys,
  eddyWaveformQueryKeys,
  placeholderChapterIds,
  placeholderSegmentIds,
} from './state';
import { useContextMenuActions } from './state/contextMenu';
import { useEditorUiActions } from './state/editorUi';

export default function useOnUnmountEditor() {
  const queryClient = useQueryClient();

  const { reset: resetContextMenuStore } = useContextMenuActions();
  const { reset: resetUiStore } = useEditorUiActions();

  // remove the transcript from cache when the editor unmounts. there's no reason
  // to keep it and transcripts can be huge.
  useEffect(() => {
    return () => {
      queryClient.removeQueries(eddyTranscriptQueryKeys.all);
      queryClient.removeQueries(eddyWaveformQueryKeys.all);
      resetContextMenuStore();
      resetUiStore();
      placeholderSegmentIds.clear();
      placeholderChapterIds.clear();
    };
  }, [queryClient, resetContextMenuStore, resetUiStore]);
}
