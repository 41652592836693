import React, { useCallback } from 'react';
import {
  useActiveExplorer,
  useExplorerBarActions,
} from 'pages/TranscriptEditorPage/state/explorerBar';
import { useSuggestionCount } from '../../state';
import * as S from './styles';

export interface TranscriptExplorerBarProps {}

const TranscriptExplorerBar: React.FC<TranscriptExplorerBarProps> = () => {
  const { data: clipCount } = useSuggestionCount();
  const activeExplorer = useActiveExplorer();
  const { openExplorer, closeExplorer } = useExplorerBarActions();

  const handleOpenSearch = useCallback(() => {
    openExplorer('search');
  }, [openExplorer]);

  const handleOpenClips = useCallback(() => {
    openExplorer('clips');
  }, [openExplorer]);

  return (
    <S.TranscriptExplorerBar>
      <S.Container>
        <S.SearchBar
          onClose={closeExplorer}
          onOpen={handleOpenSearch}
          $isHidden={activeExplorer === 'clips'}
          $hasClips={!!clipCount}
          isOpen={activeExplorer === 'search'}
        />
        {!!clipCount && (
          <S.ClipsExplorer
            onClose={closeExplorer}
            onOpen={handleOpenClips}
            $isHidden={activeExplorer === 'search'}
            isOpen={activeExplorer === 'clips'}
          />
        )}
      </S.Container>
    </S.TranscriptExplorerBar>
  );
};

export default TranscriptExplorerBar;
