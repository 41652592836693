import { PromoPackImageType } from 'api';
import { PromoPackArtStyle } from 'state/eventTracking';

export function getPromoPackArtStyle(
  imageType?: PromoPackImageType | '',
): PromoPackArtStyle | undefined {
  if (!imageType) {
    return undefined;
  }

  const lowerCaseImageType = imageType.toLowerCase();

  if (lowerCaseImageType.includes('cinematic')) {
    return 'cinematic';
  }

  if (lowerCaseImageType.includes('isometric')) {
    return 'isometric';
  }

  if (lowerCaseImageType.includes('digitalart')) {
    return 'digitalArt';
  }

  if (lowerCaseImageType.includes('photographic')) {
    return 'photographic';
  }

  return undefined;
}
