import { Spacer } from '@sparemin/blockhead';
import styled from 'styled-components';
import ExplorerBar from 'components/ExplorerBar';
import { ContainerVisibilityStyle, SearchbarStyleProps } from './types';

export const Root = styled(ExplorerBar)`
  height: 50px;
  padding: 0 14px;
  width: 200px;
`;

export const SearchField = styled(ExplorerBar.SearchField)`
  position: relative;
`;

export const SearchFieldWrapper = styled(Spacer).attrs({
  align: 'center',
  space: 3,
})`
  width: 100%;
`;

const closeInputStyle = `
  width: 142px;
  overflow: 'hidden';
`;

const openInputStyle = `
  flex: 1;

  & > * {
    width: 100%;
  }
`;

const containerVisibleStyle = `
  display: block;
  flex: 1;
`;

const containerHiddenStyle = `
  display: none;
`;

export const InputContainer = styled.div<SearchbarStyleProps>`
  ${({ $isOpen }) => ($isOpen ? openInputStyle : closeInputStyle)};
  ${({ $show }) => ($show ? containerVisibleStyle : containerHiddenStyle)};
`;

export const FillerWordsContainer = styled.div<ContainerVisibilityStyle>`
  ${({ $show }) => ($show ? containerVisibleStyle : containerHiddenStyle)};
`;

export const Controllers = styled(Spacer).attrs({
  align: 'center',
  space: 2,
})`
  position: relative;
  left: 4px;
`;
