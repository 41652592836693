import { isEmpty } from 'lodash-es';
import { useCallback } from 'react';
import { useChaptersById } from 'pages/TranscriptEditorPage/state';
import { useEventTracking } from 'state/eventTracking';

const useTrackCopyShowNotes = () => {
  const { data: chapters } = useChaptersById();
  const { trackCopyShowNotes: trackMpEvent } = useEventTracking();

  const hasChapters = !isEmpty(chapters);

  const trackCopyShowNotes = useCallback(() => {
    trackMpEvent(hasChapters);
  }, [hasChapters, trackMpEvent]);

  return {
    trackCopyShowNotes,
  };
};

export default useTrackCopyShowNotes;
