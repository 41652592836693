import { EDDY_SERVICE_URL } from 'config';

export const EDDY_API_V1_URL = `${EDDY_SERVICE_URL}/api/v1/eddy`;

export const EDDY_API_V1_MEDIA_EXPORT_URL = `${EDDY_API_V1_URL}/media-export`;
export const EDDY_API_V1_AUDIO_EXPORT_URL = `${EDDY_API_V1_MEDIA_EXPORT_URL}/audio-export`;
export const EDDY_API_V1_VIDEO_EXPORT_URL = `${EDDY_API_V1_MEDIA_EXPORT_URL}/video-export`;

export const EDDY_API_V1_PROJECT_URL = `${EDDY_API_V1_URL}/project`;

export const EDDY_API_V1_TRANSCRIPT_URL = `${EDDY_API_V1_URL}/project-transcript`;

export const EDDY_API_V1_SPEAKER_URL = `${EDDY_API_V1_URL}/speaker`;
