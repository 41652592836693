import { ModifierKey } from './types';

export function formatKeys(key: string): string;
export function formatKeys(key: string[]): string[];
export function formatKeys(key: string | string[]) {
  if (Array.isArray(key)) {
    return key.map((k) => k.toLowerCase());
  }

  return key.toLowerCase();
}

export function getModifierKey(event: KeyboardEvent): ModifierKey | undefined {
  if (event.altKey) {
    return 'alt';
  }

  if (event.ctrlKey) {
    return 'ctrl';
  }

  if (event.metaKey) {
    return 'meta';
  }

  if (event.shiftKey) {
    return 'shift';
  }

  return undefined;
}

export function createHotkeyIdentifier(event: KeyboardEvent) {
  const modifier = getModifierKey(event);

  if (modifier) {
    return formatKeys(`${modifier}+${event.key}`);
  }

  return formatKeys(event.key.toLowerCase());
}
