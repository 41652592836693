import { AxiosResponse } from 'axios';
import qs from 'query-string';

import { request } from 'api/http';

import { TRANSCRIPT_EXPORT_V1_URL } from './constants';
import {
  CreateTranscriptUrlArgs,
  TranscriptDownload,
  TranscriptDownloadArgs,
  TranscriptDownloadParams,
} from './types';

export const getTranscriptDownload = async (
  args: TranscriptDownloadArgs,
): Promise<TranscriptDownload> => {
  const {
    format,
    projectCuid,
    selection,
    includeSpeakerLabels,
    shouldFormatForYoutube,
    includeStaticTimeMarkers,
  } = args;
  const url = `${TRANSCRIPT_EXPORT_V1_URL}/${projectCuid}`;

  const params: TranscriptDownloadParams = {
    download: true,
    endWordId: selection?.endWordId,
    format,
    startWordId: selection?.startWordId,
    includeSpeakerLabels,
    shouldFormatForYoutube,
    includeStaticTimeMarkers,
  };

  const { data } = await request.get<
    TranscriptDownload,
    AxiosResponse<TranscriptDownload>
  >(url, {
    responseType: 'blob',
    params,
  });

  return data;
};

export const createTranscriptUrl = ({
  accessToken,
  endWordId,
  projectCuid,
  startWordId,
}: CreateTranscriptUrlArgs) => {
  return qs.stringifyUrl({
    url: `${TRANSCRIPT_EXPORT_V1_URL}/${projectCuid}`,
    query: {
      accessToken,
      endWordId,
      startWordId,
      format: 'json',
      download: false,
      skipCallbacks: true,
    },
  });
};
