import { ToastContainer as ToastifyContainer } from 'react-toastify';
import styled from 'styled-components';

// override react-toastify's built-in styles
export const ToastContainer = styled(ToastifyContainer).attrs({
  className: 'toast-container',
  toastClassName: 'toast',
  bodyClassName: 'body',
})`
  width: auto;
  padding: 0;
  border-radius: 10px;

  .toast {
    padding: 0;
    margin: 0;
    min-height: unset;
    border-radius: 10px;
  }

  .body {
    padding: 0;
    border-radius: 10px;
  }
`;
